import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const AddLabels = ({
  addLabelDialog,
  setAddLabelDialog,
  taskDetails,
  handleAddLabel,
}) => {
  const [labelName, setLabelName] = useState("");
  const [selectedColor, setSelectedColor] = useState();

  const colors = [
    "#174B35",
    "#533F04",
    "#702E01",
    "#5D1F1A",
    "#352C63",
    "#206D4D",
    "#7F5E02",
    "#A54700",
    "#AD2E24",
    "#5D4DB2",
    "#4BCD97",
    "#E1B205",
    "#FEA362",
    "#F77167",
    "#9E8FEF",
    "#154554",
    "#36471F",
    "#4F253F",
    "#454F59",
    "#0055CC",
    "#1F6982",
    "#4C6B1F",
    "#933D73",
    "#596773",
    "#579CFF",
    "#6BC3E0",
    "#94C747",
    "#E774BB",
    "#8C9BAA",
  ];
  return (
    <>
      <Dialog
        fullWidth
        open={addLabelDialog}
        onClose={() => {
          setAddLabelDialog(false);
          setSelectedColor();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Etiketlər</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Adı"
            type="text"
            onChange={(e) => setLabelName(e.target.value)}
            fullWidth
          />

          <Box sx={{ mt: 3 }}>
            <Typography>Rəngi seçin</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 2 }}>
              {colors.map((item, index) => (
                <Box
                  item
                  xs={2}
                  key={index}
                  sx={{
                    backgroundColor: item,
                    height: "25px",
                    borderRadius: "4px",
                    width: "102px",
                    border: selectedColor === item ? 2 : "none",
                    borderColor: selectedColor === item ? "white" : "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedColor(item);
                  }}
                ></Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddLabelDialog(false);
              setSelectedColor();
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setAddLabelDialog(false);
              setSelectedColor();
              handleAddLabel(labelName, selectedColor);
            }}
            color="primary"
            disabled={labelName?.trim() === "" || !selectedColor}
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddLabels;
