import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { Grid, Card as MuiCard, Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import VacationHeader from "../../layouts/vacations/header/VacationHeader";
import { useSelector } from "react-redux";
import CardTotals from "../../layouts/vacations/cards/CardTotals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApproverReportingForm from "../../components/vacations/approver/ApproverReportingForm";
import {
  getApproverVacationRequest,
  getApproverVacationWithFilter,
  updateEmployeeRequest,
} from "../../api/employeeRequests/employeeRequests";
import { SystemRequestCardItem } from "../../components/cards/SystemRequestCardItem";
import ContractDialog from "../../components/dialogs/documentDialog/ContractDialog";
import { getEmployeeRequestManager } from "../../services/vacation/VacationRequest";
import RequestDialog from "../../components/dialogs/documentDialog/RequestDialog";
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const typeOfDialog = "request";

function SystemApprovements() {
  const { currentEmployee } = useSelector((state) => state.commonThings);

  const headerTitle = "Sistem Təsdiqləri";
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setdialogTitle] = useState("");
  const [dialogColor, setDialogColor] = useState();
  const [dialogContent, setDialogContent] = useState();
  const [totalRequests, setTotalRequests] = useState();
  const [handleVacationDialog, setHandleVacationDialog] = useState(false);
  const [id, setId] = useState("");
  const [requests, setRequests] = useState({
    pending: "",
    approved: "",
    rejected: "",
    reRequested: "",
  });

  const limit = 5;
  const offset = 0;
  const [pagination, setPagination] = useState({
    pending: 1,
    approved: 1,
    rejected: 1,
    reRequested: 1,
  });
  const [submitted, setSubmitted] = useState(false);

  // const requestType = { value: "VacationRequest", name: "Məzuniyyət Sorğusu" };
  // const approver = true;
  const handleDialogId = (id) => {
    setId(id);
    setHandleVacationDialog(true);
  };

  useEffect(() => {
    const getVacations = async () => {
      try {
        const response = await getApproverVacationRequest(limit, offset);
        if (response && response.data) {
          setTotalRequests({
            approved: response.data.approved.total,
            pending: response.data.pending.total,
            rejected: response.data.rejected.total,
            rerequested: response.data.reRequested.total,
          });
          setRequests(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getVacations();
  }, [submitted]);

  const handleUpdateVacation = async (id, status, desc) => {
    try {
      const response = await updateEmployeeRequest(id, status, desc);
      setSubmitted(!submitted);

      if (response) {
        setPagination({
          ...pagination,
          pending: 1,
          approved: 1,
          rejected: 1,
          reRequested: 1,
        });
        setHandleVacationDialog(false);
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  const handlePagination = async (value, status) => {
    const newOffset = (value - 1) * 5;
    try {
      const response = await getApproverVacationWithFilter(
        newOffset,
        limit,
        status
      );

      if (response && response.data) {
        if (status === "pending") {
          setRequests((prevState) => ({
            ...prevState,
            pending: response.data,
          }));
        } else if (status === "approved") {
          setRequests((prevState) => ({
            ...prevState,
            approved: response.data,
          }));
        } else if (status === "rejected") {
          setRequests((prevState) => ({
            ...prevState,
            rejected: response.data,
          }));
        } else {
          setRequests((prevState) => ({
            ...prevState,
            reRequested: response.data,
          }));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <VacationHeader headerTitle={headerTitle} employee={currentEmployee} />

      <ToastContainer />

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid>
            <Grid mb={7} sx={{ alignItems: "center", gap: 5 }}>
              <Grid>
                <CardTotals totalRequests={totalRequests} />
              </Grid>
              <Grid sx={{ width: "100%", mt: 6 }}>
                <Card px={4} py={3}>
                  <ApproverReportingForm />
                </Card>
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              {Object.keys(requests)?.map((k) => (
                <Grid item xs={6} lg={3}>
                  <SystemRequestCardItem
                    data={requests[k]?.records}
                    total={requests[k]?.total}
                    limit={limit}
                    approver
                    type={k}
                    dialogHandler={handleDialogId}
                    setdialogTitle={setdialogTitle}
                    setDialogColor={setDialogColor}
                    setDialogContent={setDialogContent}
                    handlePagination={handlePagination}
                    setPagination={setPagination}
                    pagination={{ set: setPagination, state: pagination }}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    submit={handleUpdateVacation}
                    setShow={setShowDialog}
                  />
                </Grid>
              ))}
              {handleVacationDialog && (
                // <RequestShowDialog
                //   open={handleVacationDialog}
                //   onClose={() => setHandleVacationDialog(false)}
                //   updateRequestHandle={(id, status, desc) =>
                //     handleUpdateVacation(id, status, desc)
                //   }
                //   id={id}
                //   typeOfDialog={typeOfDialog}
                // />
                <RequestDialog
                  open={handleVacationDialog}
                  onClose={() => setHandleVacationDialog(false)}
                  id={dialogContent._id}
                  // id={id}
                  // fetchHandler={getVacationRequest}
                  handlerUpdateReq={handleUpdateVacation}
                  // fetchHandler={isFromEmp && getEmployeeRequest}
                />
              )}
              {/* {id && (
                <ContractDialog
                  id={id}
                  open={showDialog}
                  onClose={() => {
                    setShowDialog(false);
                    setId();
                  }}
                  // disableSigning={true}
                  // fetchHandler={getEmployeeRequestManager}
                />
              )} */}
              {/* <VacationCards
          requests={requests}
          totalRequests={totalRequests}
          limit={limit}
          cardTitles={cardTitles}
          approver={approver}
          handlePagination={handlePagination}
          submitted={submitted}
          setSubmitted={setSubmitted}
          typeOfDialog={typeOfDialog}
        /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SystemApprovements;
