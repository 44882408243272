import React, { useEffect, useState } from "react";
import { Field, FieldArray, ErrorMessage, useFormikContext } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  FormHelperText,
} from "@mui/material";
import { getCommonData } from "../../api/api";
import { checkUniqueness } from "../../utils/checkUniquenessForEmployee";

const DeductionsWages = ({
  values,
  setSelectedType,
  setOpenAddDialog,
  submitted,
}) => {
  const [deductionsName, setDeductionsName] = useState([]);
  const { errors, setFieldValue, setErrors } = useFormikContext();

  const handleWageSelect = (item) => {
    const selectWage = deductionsName.find((wage) => {
      return wage.name === item;
    });
    return selectWage;
  };

  useEffect(() => {
    const handleDeductions = async () => {
      const response = await getCommonData("deductionWageGeneral");
      setDeductionsName(response.data);
    };
    handleDeductions();
  }, [submitted]);
  return (
    <>
      <FieldArray name="deductionWages">
        {({ push, remove }) => (
          <div>
            {values?.deductionWages &&
              values?.deductionWages.map((form, index) => (
                <div key={index}>
                  <>
                    <Typography mt={3} mb={3} variant="h6">
                      {index + 1})Əməkhaqqından tutulmalar
                    </Typography>

                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.deductionWages?.[index]?.name &&
                            Boolean(errors?.deductionWages?.[index]?.name)
                          }
                          fullWidth
                        >
                          <InputLabel id={`deductionWages[${index}].name`}>
                            Əməkhaqqından tutulmaların səbəbi *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`deductionWages[${index}].name`}
                            labelId={`deductionWages[${index}].name`}
                            label="Əməkhaqqından tutulmaların səbəbi *"
                            id={`deductionWages[${index}].name`}
                            onChange={(event) => {
                              checkUniqueness(
                                values.deductionWages,
                                "name",
                                event,
                                setFieldValue,
                                `deductionWages[${index}]`,
                                "əməkhaqqına tutulma səbəbi",
                                handleWageSelect
                              );
                              if (errors?.deductionWages?.[index]?.name) {
                                setErrors({
                                  ...errors,
                                  deductionWages: [
                                    ...errors.deductionWages.slice(0, index),
                                    {
                                      ...errors.deductionWages[index],
                                      name: "",
                                    },
                                    ...errors.deductionWages.slice(index + 1),
                                  ],
                                });
                              }
                            }}
                            error={
                              errors?.deductionWages?.[index]?.name &&
                              Boolean(errors?.deductionWages?.[index]?.name)
                            }
                            fullWidth
                          >
                            {deductionsName &&
                              deductionsName
                                .sort((a, b) =>
                                  a?.name?.localeCompare(b?.name, "az")
                                )
                                .map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                            <Button
                              sx={{
                                mt: 3,
                                ml: 3,
                              }}
                              variant="outlined"
                              onClick={() => {
                                setOpenAddDialog(true);
                                setSelectedType([
                                  "deductionWageGeneral",
                                  { name: "Ümumi əlavələr" },
                                ]);
                              }}
                            >
                              Yeni əməkhaqqına tutulma səbəbi əlavə et
                            </Button>
                          </Field>
                        </FormControl>
                        {errors?.deductionWages?.[index]?.name &&
                          errors?.deductionWages?.[index]?.name && (
                            <FormHelperText error>
                              {errors?.deductionWages?.[index]?.name}
                            </FormHelperText>
                          )}
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="number"
                          as={TextField}
                          onChange={(e) => {
                            setFieldValue(
                              `deductionWages[${index}].value`,
                              e.target.value
                            );
                            if (errors?.deductionWages?.[index]?.value) {
                              setErrors({
                                ...errors,
                                deductionWages: [
                                  ...errors.deductionWages.slice(0, index),
                                  {
                                    ...errors.deductionWages[index],
                                    value: "",
                                  },
                                  ...errors.deductionWages.slice(index + 1),
                                ],
                              });
                            }
                          }}
                          name={`deductionWages[${index}].value`}
                          label="Əməkhaqqından tutulmaların dəyəri"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </>

                  <Button
                    sx={{ mt: 3 }}
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    Əməkhaqqına tutulmanı sil
                  </Button>
                </div>
              ))}
            <Button
              sx={{ mt: 3 }}
              variant="contained"
              type="button"
              onClick={() => push({ name: "", value: "" })}
            >
              Əməkhaqqına tutulmanı əlavə et
            </Button>
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default DeductionsWages;
