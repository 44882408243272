import React, { useEffect, useState } from "react";
import { Field, FieldArray, ErrorMessage, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getCommonData } from "../../../api/api";
import CreateIcon from "@mui/icons-material/Create";
import { reprimandRewardValidation } from "./Validations";
import { sendPersonalInfoChanges } from "../../../services/employeeCabinet/employeeCabinet";

const MCViewRewardReprimands = ({ employeeById }) => {
  const [rewardOrganizations, setRewardOrganizations] = useState([]);
  const [formCorrection, setFormCorrection] = useState(false);
  const [requestButton, setRequestButton] = useState(false);

  const onClickCB = () => {
    setFormCorrection(!formCorrection);
  };

  useEffect(() => {
    const handleOrganizations = async () => {
      const response = await getCommonData("organizations");
      setRewardOrganizations(response.data);
    };
    handleOrganizations();
  }, []);

  const initialValues = {
    reprimandsAndRewards:
      employeeById.reprimandsAndRewards.map((item, index) => ({
        ...item,
        id: index,
      })) || [],
  };

  const [dataIndex, setDataIndex] = useState(
    initialValues.reprimandsAndRewards.at(-1).id
  );

  // form change request methods

  function updateData(newObject, oldObject) {
    let updateData = {
      index: newObject.id,
      operation: "update",
      data: {},
    };

    const keys = Object.keys(newObject);
    for (const key of keys) {
      if (newObject[key] !== oldObject[key]) {
        updateData.data[key] = {
          old: newObject[key],
          new: oldObject[key],
        };
      }
    }
    const a = Object.keys(updateData.data);
    if (a.length) {
      return updateData;
    }
  }

  function makeChangesData(formValue, oldValue) {
    let changesData = {
      section: "reprimand-and-reward",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.reprimandsAndRewards.forEach((obj1) => {
      const obj2 = oldValue.reprimandsAndRewards.find(
        (item) => item.id === obj1.id
      );

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.reprimandsAndRewards.forEach((obj2) => {
      const obj1 = formValue.reprimandsAndRewards.find(
        (item) => item.id === obj2.id
      );

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }
    sendPersonalInfoChanges(changesData);
  }

  function handleSubmit(data) {
    makeChangesData(data, initialValues);
  }

  // form controller for submitting

  function controlFormForSubmitting(data) {
    controllData(data, initialValues);
  }

  function controllData(formValue, oldValue) {
    let changesData = {
      section: "reprimand-and-reward",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.reprimandsAndRewards.forEach((obj1) => {
      const obj2 = oldValue.reprimandsAndRewards.find(
        (item) => item.id === obj1.id
      );

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.reprimandsAndRewards.forEach((obj2) => {
      const obj1 = formValue.reprimandsAndRewards.find(
        (item) => item.id === obj2.id
      );

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }

    if (!changesData.changes.length) {
      setRequestButton(false);
    }
  }

  return (
    <>
      {employeeById ? (
        <Grid>
          <Button
            style={
              formCorrection
                ? { backgroundColor: "rgba(121,46,46, 0.9)", marginTop: "10px" }
                : { marginTop: "10px" }
            }
            onClick={onClickCB}
            variant="contained"
            color="secondary"
          >
            {formCorrection ? "Ləğv et" : "Dəyişiklik et"}

            <CreateIcon sx={{ marginLeft: 5 }} />
          </Button>
          <Formik
            validationSchema={reprimandRewardValidation}
            onSubmit={handleSubmit}
            initialValues={{
              reprimandsAndRewards: initialValues.reprimandsAndRewards || [], // Set the initial laborActivity values from employeeById
            }}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
            }) => (
              <Form
                style={
                  !formCorrection
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <FieldArray name="reprimandsAndRewards">
                  {({ push, remove }) => (
                    <div>
                      {values.reprimandsAndRewards.map((form, index) => (
                        <div key={index}>
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                              }}
                            >
                              <Typography mt={5} variant="h6">
                                {index + 1 + ")"}Mükafat, təltif və tənbehləri
                              </Typography>
                            </div>

                            {/* Rewards/ Reprimands and  description */}
                            <Grid
                              sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}
                            >
                              <Grid sx={{ width: "100%" }}>
                                <FormControl fullWidth>
                                  <InputLabel
                                    id={`reprimandsAndRewards[${index}].rType`}
                                  >
                                    Tənbeh / Mükafat / Təltif
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={`reprimandsAndRewards[${index}].rType`}
                                    labelId={`rreprimandsAndRewards[${index}].rType`}
                                    label="Mükafat / Təltif / Tənbeh"
                                    id={`reprimandsAndRewards[${index}].rType`}
                                    onChange={(e) => {
                                      setRequestButton(true);
                                      setFieldValue(
                                        `reprimandsAndRewards[${index}].rType`,
                                        e.target.value
                                      );
                                    }}
                                    fullWidth
                                    error={
                                      errors?.reprimandsAndRewards?.[index]
                                        ?.rType &&
                                      Boolean(
                                        errors?.reprimandsAndRewards?.[index]
                                          ?.rType
                                      )
                                    }
                                  >
                                    <MenuItem value="prize">Mükafat</MenuItem>
                                    <MenuItem value="reprimand">
                                      Tənbeh
                                    </MenuItem>
                                    <MenuItem value="reward">Təltif</MenuItem>
                                  </Field>
                                  {errors?.reprimandsAndRewards?.[index]
                                    ?.rType &&
                                    errors?.reprimandsAndRewards?.[index]
                                      ?.rType && (
                                      <FormHelperText error>
                                        {
                                          errors?.reprimandsAndRewards?.[index]
                                            ?.rType
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              <Grid sx={{ width: "100%" }}>
                                <Field
                                  type="text"
                                  name={`reprimandsAndRewards[${index}].description`}
                                  as={TextField}
                                  fullWidth
                                  label="Mükafat / Təltif / Tənbehin məzmunu"
                                  onChange={(e) => {
                                    setRequestButton(true);
                                    setFieldValue(
                                      `reprimandsAndRewards[${index}].description`,
                                      e.target.value
                                    );
                                  }}
                                  error={
                                    errors?.reprimandsAndRewards?.[index]
                                      ?.description &&
                                    Boolean(
                                      errors?.reprimandsAndRewards?.[index]
                                        ?.description
                                    )
                                  }
                                />
                                {errors?.reprimandsAndRewards?.[index]
                                  ?.description &&
                                  errors?.reprimandsAndRewards?.[index]
                                    ?.description && (
                                    <FormHelperText error>
                                      {
                                        errors?.reprimandsAndRewards?.[index]
                                          ?.description
                                      }
                                    </FormHelperText>
                                  )}
                              </Grid>
                            </Grid>

                            {/* start date and company */}
                            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                              <Grid sx={{ width: "100%" }}>
                                <Field
                                  name={`reprimandsAndRewards[${index}].date`}
                                >
                                  {({ field, form }) => (
                                    <DatePicker
                                      {...field}
                                      label="Mükafat / Təltif / Tənbehin qüvvəyə mindiyi tarix"
                                      inputFormat="dd/MM/yyyy"
                                      value={field.value}
                                      onChange={(date) => {
                                        setRequestButton(true);
                                        if (
                                          date instanceof Date &&
                                          !isNaN(date)
                                        ) {
                                          form.setFieldValue(
                                            `reprimandsAndRewards[${index}].date`,
                                            date
                                          );
                                        } else {
                                          form.setFieldValue(
                                            `reprimandsAndRewards[${index}].date`,
                                            ""
                                          );
                                        }
                                      }}
                                      maxDate={new Date()}
                                      minDate={new Date(1940, 0, 1)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={
                                            form.errors?.reprimandsAndRewards?.[
                                              index
                                            ]?.date
                                          }
                                          error={Boolean(
                                            form.errors?.reprimandsAndRewards?.[
                                              index
                                            ]?.date
                                          )}
                                          fullWidth
                                        />
                                      )}
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid sx={{ width: "100%" }}>
                                <FormControl
                                  error={
                                    errors?.reprimandsAndRewards?.[index]
                                      ?.organization &&
                                    Boolean(
                                      errors?.reprimandsAndRewards?.[index]
                                        ?.organization
                                    )
                                  }
                                  fullWidth
                                >
                                  <InputLabel
                                    id={`reprimandsAndRewards[${index}].organization`}
                                  >
                                    Mükafat / Təltif / Tənbehin təqdim olunduğu
                                    müəssisə *
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={`reprimandsAndRewards[${index}].organization`}
                                    labelId={`reprimandsAndRewards[${index}].organization`}
                                    label="Mükafat / Təltif / Tənbehin təqdim olunduğu müəssisə *"
                                    id={`reprimandsAndRewards[${index}].organization`}
                                    defaultValue=""
                                    fullWidth
                                    onChange={(e) => {
                                      setRequestButton(true);
                                      setFieldValue(
                                        `reprimandsAndRewards[${index}].organization`,
                                        e.target.value
                                      );
                                    }}
                                    error={
                                      errors?.reprimandsAndRewards?.[index]
                                        ?.organization &&
                                      Boolean(
                                        errors?.reprimandsAndRewards?.[index]
                                          ?.organization
                                      )
                                    }
                                  >
                                    {rewardOrganizations.map((item, index) => (
                                      <MenuItem value={item} key={index}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                  {errors?.reprimandsAndRewards?.[index]
                                    ?.organization &&
                                    errors?.reprimandsAndRewards?.[index]
                                      ?.organization && (
                                      <FormHelperText error>
                                        {
                                          errors?.reprimandsAndRewards?.[index]
                                            ?.organization
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                            </Grid>
                          </>
                          <Button
                            sx={
                              formCorrection
                                ? { visibility: "visible" }
                                : { visibility: "hidden" }
                            }
                            variant="contained"
                            color="error"
                            type="button"
                            style={{
                              marginBottom: "10px",
                            }}
                            onClick={() => {
                              setRequestButton(true);
                              remove(index);

                              const updatedArray =
                                values.reprimandsAndRewards.filter(
                                  (item) => item.id !== index
                                );
                              controlFormForSubmitting({
                                reprimandsAndRewards: updatedArray,
                              });
                            }}
                          >
                            Ləğv et
                          </Button>
                        </div>
                      ))}
                      <Button
                        sx={
                          formCorrection
                            ? { visibility: "visible" }
                            : { visibility: "hidden" }
                        }
                        variant="contained"
                        color="secondary"
                        style={{ marginBottom: "10px", marginTop: "20px" }}
                        onClick={() => {
                          let newId = dataIndex + 1;
                          setDataIndex(newId);
                          push({
                            id: newId,
                          });
                        }}
                      >
                        Əlavə et
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <Grid
                  style={
                    formCorrection
                      ? { visibility: "visible", marginTop: "10px" }
                      : { visibility: "hidden" }
                  }
                >
                  <Button
                    type="submit"
                    style={
                      !requestButton
                        ? { pointerEvents: "none" }
                        : {
                            backgroundColor: "#28358a",
                            pointerEvents: "auto",
                          }
                    }
                    variant="contained"
                    color="secondary"
                  >
                    Dəyişikliklər sorğusu göndər
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default MCViewRewardReprimands;
