import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

function EditPersonalInformation({ employeeById }) {
  const [oldNameCheckbox, setOldNameCheckbox] = useState(false);

  const countries = [
    "Albaniya",
    "Almaniya",
    "Anqola",
    "Antiqua və Barbuda",
    "Argentina",
    "Avstraliya",
    "Avstriya",
    "Azərbaycan",
    "Bahamalar",
    "Bahrayn",
    "Banqladeş",
    "Barbados",
    "Belarus",
    "Belçika",
    "Boliviya",
    "Bosniya və Herseqovina",
    "Botswana",
    "Braziliya",
    "Böyük Britaniya",
    "Büyükdəniz adası",
    "Bəhreyn",
    "Bəruney",
    "Cənubi Afrika",
    "Cənubi Sudan",
    "Cənubi Koreya",
    "Çad",
    "Çexiya",
    "Çili",
    "Çin",
    "Çernoqoriya",
    "Çexoslavakiya",
    "Danemarka",
    "Dominika",
    "Dominikan Respublikası",
    "Ekvador",
    "Ekvatorial Qvineya",
    "Eritreya",
    "Estoniya",
    "Əfqanıstan",
    "Fici",
    "Filippin",
    "Finlandiya",
    "Fransa",
    "Fransız Qvineyası",
    "Fələstin",
    "Gana",
    "Qabon",
    "Qambiya",
    "Qazaxıstan",
    "Qatar",
    "Qrenada",
    "Qvineya",
    "Qvineya-Bisau",
    "Gürcüstan",
    "Haiti",
    "Hollandiya",
    "Honduras",
    "Honkonq",
    "Hindistan",
    "Horvatiya",
    "Indoneziya",
    "İngiltərə",
    "İordaniya",
    "İraq",
    "İran",
    "İrlandiya",
    "İslandiya",
    "İspaniya",
    "İsrail",
    "İsveç",
    "İsveçrə",
    "İtaliya",
    "Kamboca",
    "Kamerun",
    "Kanada",
    "Keniya",
    "Kipr",
    "Kiribati",
    "Kolumbiya",
    "Komor Adaları",
    "Kongo",
    "Kosta Rika",
    "Kotd’İvor",
    "Kuba",
    "Kuveyt",
    "Kırqızıstan",
    "Latviya",
    "Lesoto",
    "Livan",
    "Liberiya",
    "Litva",
    "Lixtenşteyn",
    "Liviya",
    "Lüksemburq",
    "Makedoniya",
    "Malavi",
    "Malayziya",
    "Maldiv adaları",
    "Malta",
    "Maroq",
    "Marşal adaları",
    "Mauritaniya",
    "Mauritius",
    "Meksika",
    "Moldova",
    "Monako",
    "Monqolustan",
    "Monteneqro",
    "Morokko",
    "Mozambik",
    "Myanma",
    "Mərakeş",
    "Nambiya",
    "Nauru",
    "Nepal",
    "Niderland",
    "Niger",
    "Nigeriya",
    "Nikaraqua",
    "Norveç",
    "Pakistan",
    "Palau",
    "Panama",
    "Paraqvay",
    "Portuqaliya",
    "Polşa",
    "Rumıniya",
    "Rusiya",
    "Ruanda",
    "San-Marino",
    "Svazilend",
    "Salvador",
    "Samoa",
    "San-Tome və Prinsip",
    "Seneqal",
    "Seyşel adaları",
    "Sinhapur",
    "Slovakiya",
    "Sloveniya",
    "Solomon adaları",
    "Somali",
    "Sudan",
    "Surinam",
    "Suriya",
    "Svədistan",
    "Səudiyyə Ərəbistanı",
    "Sərbiya",
    "Tacikistan",
    "Tanzaniya",
    "Tailand",
    "Tayvan",
    "Toga",
    "Tonqa",
    "Trinidad və Tobaqo",
    "Tunis",
    "Turkmənistan",
    "Tuvalu",
    "Türkiyə",
    "Ukrayna",
    "Urugvay",
    "Vanuatu",
    "Vatikan",
    "Venesuela",
    "Vyetnam",
    "Yamayka",
    "Yaponiya",
    "Yeni Zelandiya",
    "Yunanıstan",
    "Yəmən",
    "Zambiya",
    "Zimbabve",
  ];

  const initialValues = {
    isForeigner: employeeById.isForeigner ? true : null,
    firstname: employeeById?.firstname ? employeeById.firstname : "",
    lastname:
      employeeById.lastname +
        (employeeById.oldLastName ? ` ( ${employeeById?.oldLastName} )` : "") ||
      "",
    fathername: employeeById.fathername || "",
    gender: employeeById.gender || "",
    dob: employeeById.dob || null,
    placeOfBirth: employeeById.placeOfBirth || "",
    pin: employeeById.pin || "",
    tRPNumber: employeeById.tRPNumber || "",
    idCardIssuanceDate: employeeById.idCardIssuanceDate || null,
    nationality: employeeById.nationality || "",
    idCardAuthority: employeeById.idCardAuthority || "",
    idCardNumber: employeeById.isForeigner
      ? { number: employeeById.idCardNumber }
      : {
          code: employeeById?.idCardNumber?.startsWith("AA")
            ? employeeById.idCardNumber.slice(0, 2)
            : employeeById?.idCardNumber?.startsWith("AZE")
            ? employeeById?.idCardNumber.slice(0, 3)
            : "",
          number: employeeById?.idCardNumber?.startsWith("AA")
            ? employeeById.idCardNumber.slice(2)
            : employeeById?.idCardNumber?.startsWith("AZE")
            ? employeeById?.idCardNumber.slice(3)
            : "",
        },
    idCardValidityPeriod: employeeById.idCardValidityPeriod || null,
    ssn: employeeById.ssn || "",
    citizenship: employeeById.citizenship || "",
    workPermitStartDate: employeeById.workPermitStartDate || null,
    workPermitEndDate: employeeById.workPermitEndDate || null,
    residencePermitStartDate: employeeById.residencePermitStartDate || null,
    residencePermitEndDate: employeeById.residencePermitEndDate || null,
    maritalStatus: employeeById.maritalStatus || "",
  };
  return (
    <>
      {employeeById ? (
        <Formik initialValues={initialValues} enableReinitialize={true}>
          {({
            errors,
            isValid,
            touched,
            isSubmitting,
            setFieldValue,
            values,
          }) => {
            return (
              <Form style={{ pointerEvents: "none" }}>
                <>
                  <Typography variant="h6">Şəxsi məlumatlar</Typography>

                  {/* Name and surname */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name="firstname"
                        as={TextField}
                        value={employeeById?.firstname}
                        fullWidth
                        label="Ad"
                        error={errors.firstname && Boolean(errors.firstname)}
                        helpertext={errors.firstname && errors.firstname}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        width: "100%",
                        display: "flex",
                        gap: 3,
                        alignItems: "center",
                      }}
                    >
                      <Field
                        type="text"
                        name="lastname"
                        as={TextField}
                        value={employeeById?.lastname}
                        label="Soyad"
                        error={errors.lastname && Boolean(errors.lastname)}
                        helpertext={errors.lastname && errors.lastname}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  {/* Father name and gender */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name={employeeById?.fathername}
                        as={TextField}
                        fullWidth
                        value={employeeById?.fathername}
                        label="Ata adı"
                        error={errors.fathername && Boolean(errors.fathername)}
                        helpertext={errors.fathername && errors.fathername}
                      />
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={errors.gender && errors.gender}
                        fullWidth
                      >
                        <InputLabel id="gender">Cinsi</InputLabel>
                        <Field
                          as={Select}
                          name="gender"
                          labelId="gender"
                          label="Cinsi"
                          id="gender-select"
                          fullWidth
                          error={errors.gender && Boolean(errors.gender)}
                        >
                          <MenuItem value="male">Kişi</MenuItem>
                          <MenuItem value="female">Qadın</MenuItem>
                        </Field>
                        {errors.gender && errors.gender && (
                          <FormHelperText error>{errors.gender}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Birthday and place of birth */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Field name="dob">
                      {({ field, form }) => (
                        <DatePicker
                          {...field}
                          label="Doğum tarixi"
                          inputFormat="dd/MM/yyyy"
                          value={employeeById?.dob}
                          maxDate={new Date()}
                          minDate={new Date(1940, 0, 1)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helpertext={form.errors.dob}
                              error={Boolean(form.errors.dob)}
                              fullWidth
                            />
                          )}
                        />
                      )}
                    </Field>

                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name="placeOfBirth"
                        as={TextField}
                        fullWidth
                        label="Doğulduğu yer"
                        value={employeeById?.placeOfBirth}
                        error={
                          errors.placeOfBirth && Boolean(errors.placeOfBirth)
                        }
                        helpertext={errors.placeOfBirth && errors.placeOfBirth}
                      />
                    </Grid>
                  </Grid>

                  {/* Nationality and idCardAuthority */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                      <FormControl
                        error={errors.nationality && errors.nationality}
                        fullWidth
                      >
                        <InputLabel id="nationality">Milliyyət</InputLabel>
                        <Field
                          as={Select}
                          name="nationality"
                          labelId="nationality"
                          label="nationality"
                          id="nationality-select"
                          fullWidth
                          error={
                            errors.nationality && Boolean(errors.nationality)
                          }
                        >
                          {countries.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={employeeById?.nationality}
                            >
                              {employeeById?.nationality}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                      {employeeById.isForeigner === true ? (
                        <FormControl
                          error={errors.citizenship && errors.citizenship}
                          fullWidth
                        >
                          <InputLabel id="citizenship">Vətəndaşlığı</InputLabel>
                          <Field
                            as={Select}
                            name="citizenship"
                            labelId="citizenship"
                            label="citizenship"
                            id="citizenship-select"
                            fullWidth
                            error={
                              errors.citizenship && Boolean(errors.citizenship)
                            }
                            required
                          >
                            {countries.map((item, index) => (
                              <MenuItem
                                key={index}
                                value={employeeById?.citizenship}
                              >
                                {employeeById?.citizenship}
                              </MenuItem>
                            ))}
                          </Field>
                          {errors.nationality && errors.nationality && (
                            <FormHelperText error>
                              {errors.nationality}
                            </FormHelperText>
                          )}
                        </FormControl>
                      ) : null}
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name="idCardAuthority"
                        as={TextField}
                        fullWidth
                        value={employeeById?.idCardAuthority}
                        label={
                          employeeById.isForeigner === true
                            ? "MYİ-ni verən təşkilat"
                            : "Şəxsiyyət vəsiqəsini verən təşkilat"
                        }
                        error={
                          errors.idCardAuthority &&
                          Boolean(errors.idCardAuthority)
                        }
                        helpertext={
                          errors.idCardAuthority && errors.idCardAuthority
                        }
                      />
                    </Grid>
                  </Grid>

                  {/* National ID and PIN */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                      {employeeById.isForeigner === true ? null : (
                        <Grid sx={{ width: 1 / 5 }}>
                          <Field
                            as={Select}
                            name={`idCardNumber.code`}
                            labelId={`idCardNumber.code`}
                            label="*"
                            id={`idCardNumber.code`}
                            defaultValue=""
                            fullWidth
                            error={
                              errors.idCardNumber?.code &&
                              Boolean(errors.idCardNumber?.code)
                            }
                            helpertext={
                              errors.idCardNumber?.code &&
                              errors.idCardNumber?.code
                            }
                            required
                          >
                            <MenuItem value="AA">AA</MenuItem>
                            <MenuItem value="AZE">AZE</MenuItem>
                          </Field>
                        </Grid>
                      )}

                      <Grid
                        sx={{
                          width:
                            employeeById.isForeigner === true ? "100%" : 4 / 5,
                        }}
                      >
                        <Field
                          type="text"
                          name="idCardNumber.number"
                          as={TextField}
                          fullWidth
                          label={
                            employeeById.isForeigner === true
                              ? "Passport №"
                              : "Ş.V/Seriya №"
                          }
                          error={
                            errors.idCardNumber?.number &&
                            Boolean(errors.idCardNumber?.number)
                          }
                          helpertext={
                            errors.idCardNumber?.number &&
                            errors.idCardNumber?.number
                          }
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                      <Field
                        type="text"
                        name="pin"
                        as={TextField}
                        fullWidth
                        value={employeeById?.pin}
                        label="Fərdi identifikasiya Nömrəsi (FİN)"
                        error={errors.pin && Boolean(errors.pin)}
                        helpertext={errors.pin && errors.pin}
                      />
                      {employeeById.isForeigner === true ? (
                        <Field
                          type="text"
                          name="tRPNumber"
                          value={employeeById?.tRPNumber}
                          as={TextField}
                          fullWidth
                          label="MYİ seriya Nömrəsi"
                          error={errors.tRPNumber && Boolean(errors.tRPNumber)}
                          helpertext={errors.tRPNumber && errors.tRPNumber}
                          required
                        />
                      ) : null}
                    </Grid>
                  </Grid>

                  {/* National id given date and validity period */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Field name="idCardIssuanceDate">
                      {({ field, form }) => (
                        <DatePicker
                          {...field}
                          label={
                            employeeById.isForeigner === true
                              ? "Passport verilmə tarixi"
                              : "Ş.V. verilmə tarixi"
                          }
                          inputFormat="dd/MM/yyyy"
                          value={employeeById?.idCardIssuanceDate}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helpertext={form.errors.idCardIssuanceDate}
                              error={Boolean(form.errors.idCardIssuanceDate)}
                              fullWidth
                            />
                          )}
                        />
                      )}
                    </Field>
                    <Field name="idCardValidityPeriod">
                      {({ field, form }) => (
                        <DatePicker
                          {...field}
                          label={
                            employeeById.isForeigner === true
                              ? "Passport etibarlılıq müddəti"
                              : "Ş.V. etibarlılıq Müddəti"
                          }
                          inputFormat="dd/MM/yyyy"
                          value={employeeById?.idCardValidityPeriod}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helpertext={form.errors.idCardValidityPeriod} // Display the validation error message
                              error={Boolean(form.errors.idCardValidityPeriod)} // Set error state based on the validation error
                              fullWidth
                            />
                          )}
                        />
                      )}
                    </Field>
                  </Grid>

                  {/* For foreign employeers */}
                  {employeeById.isForeigner === true ? (
                    <>
                      {/* Living permission start and end date */}
                      <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                        <Field name="residencePermitStartDate">
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="Yaşamaq icazəsi başlama tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={
                                employeeById?.residencePermitStartDate || null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helpertext={
                                    form.errors.residencePermitStartDate
                                  } // Display the validation error message
                                  error={Boolean(
                                    form.errors.residencePermitStartDate
                                  )} // Set error state based on the validation error
                                  fullWidth
                                />
                              )}
                              required
                            />
                          )}
                        </Field>
                        <Field name="residencePermitEndDate">
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="Yaşamaq icazəsi bitmə tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={
                                employeeById?.residencePermitEndDate || null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helpertext={
                                    form.errors.residencePermitEndDate
                                  } // Display the validation error message
                                  error={Boolean(
                                    form.errors.residencePermitEndDate
                                  )} // Set error state based on the validation error
                                  fullWidth
                                />
                              )}
                              required
                            />
                          )}
                        </Field>
                      </Grid>

                      {/* Work permission start and end date */}
                      <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                        <Field name="workPermitStartDate">
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="İş icazəsi başlama tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={employeeById?.workPermitStartDate || null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helpertext={form.errors.workPermitStartDate}
                                  error={Boolean(
                                    form.errors.workPermitStartDate
                                  )}
                                  fullWidth
                                />
                              )}
                              required
                            />
                          )}
                        </Field>
                        <Field name="workPermitEndDate">
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="İş icazəsi bitmə tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={employeeById?.workPermitEndDate || null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helpertext={form.errors.workPermitEndDate}
                                  error={Boolean(form.errors.workPermitEndDate)}
                                  fullWidth
                                />
                              )}
                              required
                            />
                          )}
                        </Field>
                      </Grid>
                    </>
                  ) : null}

                  {/* SSN */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name="ssn"
                        as={TextField}
                        value={employeeById?.ssn}
                        fullWidth
                        label="SSN"
                        error={errors.ssn && Boolean(errors.ssn)}
                        helpertext={errors.ssn && errors.ssn}
                      />
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.maritalStatus &&
                          Boolean(errors?.maritalStatus)
                        }
                        fullWidth
                      >
                        <InputLabel id={`maritalStatus`}>
                          Ailə vəziyyəti *
                        </InputLabel>
                        <Field
                          as={Select}
                          name={`maritalStatus`}
                          labelId={`maritalStatus`}
                          label="Ailə vəziyyəti *"
                          id={`maritalStatus`}
                          defaultValue=""
                          fullWidth
                          error={
                            errors?.maritalStatus &&
                            Boolean(errors?.maritalStatus)
                          }
                        >
                          <MenuItem value="married">Evli</MenuItem>
                          <MenuItem value="single">Subay</MenuItem>
                        </Field>
                        {errors?.maritalStatus && errors?.maritalStatus && (
                          <FormHelperText error>
                            {errors?.maritalStatus}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default EditPersonalInformation;
