import { useState } from "react";

const useDialog = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const toggleDialog = () => setIsOpen((prev) => !prev);

  const setDialog = (open) => setIsOpen(open);

  const closeDialog = () => setIsOpen(false);

  const openDialog = () => setIsOpen(true);

  return {
    isOpen,
    setDialog,
    toggleDialog,
    openDialog,
    closeDialog,
  };
};

export default useDialog;
