import { toast } from "react-toastify";
import { api } from "../../api/axiosInterceptor";

export const sendPersonalInfoChanges = async (payload) => {
  try {
    const response = await api.post(`employee/cabinet/change-request`, payload);
    toast.success("Sorğu göndərildi", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
    throw error;
  }
};
