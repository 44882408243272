import { createSlice } from "@reduxjs/toolkit";

export const initialStateEmp = {
  activeStep: 1,
  data: {
    firstname: "",
    lastname: "",
    gender: "",
    nationality: "",
    placeOfBirth: "",
    ssn: "",
    idCardNumber: "",
    idCardValidityPeriod: null,
    idCardAuthority: "",
    // workingHours: "9-5",
    martialStatus: "",
    // personalCategory: "Personal category",
    laborActivity: [],
    relationshipInfo: [],
    sameAddress: false,
    bankInfos: [],
    actualAddress: {
      address: "",
      district: "",
      city: "",
      homeNumber: [""],
    },
    registeredAddress: {
      address: "",
      city: "",
      district: "",
    },
    mobile: [{ code: "", number: "" }],
    phoneCode: [""],
    email: [""],
    education: [],
    certifications: [],
    reprimandsAndRewards: [],
    socialStatus: [],
    inventories: [],
    documents: [],
    supplementalWages: [],
    deductionWages: [],
    academicDegrees: [],
    transportVehicles: [],
    outOfStaffUnit: false,
    militaryService: {
      hasMilitaryService: "",
      issuerName: "",
      troopType: "",
      militaryRank: "",
      serviceStartDate: "",
      serviceEndDate: "",
      reason: "",
      articleNumber: [],
      note: "",
    },
    driverLicense: {
      dob: undefined,
      livingAddress: undefined,
      licenseIssuer: "",
      licenseIssuanceDate: "",
      licenseExpirationDate: "",
      licenseNumber: "",
      licenseCategory: [],
    },
  },
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState: initialStateEmp,
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setEmployees: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setEmployees, setActiveStep } = employeeSlice.actions;

export default employeeSlice.reducer;
