import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Box,
  CssBaseline,
  IconButton,
  Drawer,
  Paper as MuiPaper,
} from "@mui/material";
import { spacing } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import AuthGuard from "../components/guards/AuthGuard";
import useDashboard from "../hooks/useDashboard";
import {
  initialStateEmp,
  setActiveStep,
  setEmployees,
} from "../redux/features/slices/employee.slice";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: space-between;
`;

// const Drawer = styled.div`
//   ${(props) => props.theme.breakpoints.up("md")} {
//     width: ${drawerWidth}px;
//     flex-shrink: 0;
//   }
// `;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  /* Changed from 100% to calculate for removing sidebar width */
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const {
    isLgUp,
    anchorEl,
    mobileOpen,
    handleOpen,
    handleClose,
    handleDrawerToggle,
  } = useDashboard();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/employees/add-employee") {
      dispatch(setActiveStep(1));
      dispatch(setEmployees(initialStateEmp.data));
    }
  }, [location.pathname, dispatch]);
  return (
    <AuthGuard>
      {" "}
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={dashboardItems}
            />
          </Box>
        </Drawer>
        <Box
          sx={{
            top: "0",
            width: "50px",
            height: "50px",
            display: "flex",
            position: "sticky",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {anchorEl === true && (
            <IconButton color="inherit" aria-label="menu" onClick={handleClose}>
              <MenuIcon />
            </IconButton>
          )}
        </Box>
        {anchorEl === false && (
          <Box
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            <Sidebar
              PaperProps={{
                style: {
                  width: drawerWidth,
                },
              }}
              items={dashboardItems}
              anchorEl={anchorEl}
              handleOpen={handleOpen}
            />
          </Box>
        )}
        <AppContent
          style={{
            maxWidth:
              anchorEl === false ? "calc(100% - 258px)" : "calc(100% - 50px)",
          }}
        >
          <Navbar onDrawerToggle={handleDrawerToggle} />
          <MainContent p={isLgUp ? 12 : 5}>
            {children}
            <Outlet />
          </MainContent>
          <Footer />
        </AppContent>
        <Settings />
      </Root>
    </AuthGuard>
  );
};

export default Dashboard;
