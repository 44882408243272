import React from "react";
// Material UI
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
// Utilities
import { TYPES_ENUM } from "../../../constants";
import ResizableTextField from "../input/ResizableTextField";

const TableWithVacationFields = ({ data, headers }) => {
  const resultData = data ? Object.entries(data) : [];

  const createDynamicInputsByType = (type, label, value) => {
    let dynamicInput = "";
    switch (type) {
      case TYPES_ENUM.DATE:
        dynamicInput = (
          <DatePicker
            label={label}
            value={value}
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => <TextField {...params} />}
            disabled
          />
        );
        break;
      default:
        dynamicInput = (
          <>
            {type !== TYPES_ENUM.NUMBER || value.length < 15 ? (
              <ResizableTextField
                type={type}
                id="outlined-basic"
                label={label}
                value={value}
                variant="outlined"
                disabled
              />
            ) : (
              <TextField
                type={type}
                id="outlined-basic"
                label={label}
                value={value}
                variant="outlined"
                disabled
              />
            )}
          </>
        );
        break;
    }
    return dynamicInput;
  };

  return (
    <Paper
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Table style={{ overflow: "hidden" }}>
        <TableHead>
          <TableRow>
            {headers?.length > 0
              ? headers.map((header, index) => (
                  <TableCell
                    key={index + 1}
                    align={index > 0 ? "center" : "left"}
                  >
                    {header}
                  </TableCell>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {resultData?.length > 0
            ? resultData?.map(([key, row], index) => (
                <TableRow key={index + 1}>
                  <TableCell component="th" scope="row">
                    {row.label}
                  </TableCell>

                  <TableCell style={{ opacity: "0" }}>{key}</TableCell>

                  <TableCell align="start">
                    {createDynamicInputsByType(
                      row?.type,
                      row?.label,
                      row?.value
                    )}
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TableWithVacationFields;
