import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentEmployee: "",
  allEmployees: { employees: [], total: "" },
  refreshTrigger: false,
  empStaticData: [],
};

export const commonThingsSlice = createSlice({
  name: "commonThings",
  initialState,
  reducers: {
    setCurrentEmployee: (state, action) => {
      state.currentEmployee = action.payload;
    },
    setAllEmployee: (state, action) => {
      state.allEmployees = action.payload;
    },
    setTriggingRefresh: (state, action) => {
      state.refreshTrigger = action.payload;
    },
    setEmpStaticData: (state, action) => {
      state.empStaticData = action.payload;
    },
  },
});

export const {
  setCurrentEmployee,
  setAllEmployee,
  setTriggingRefresh,
  setEmpStaticData,
} = commonThingsSlice.actions;

export default commonThingsSlice.reducer;
