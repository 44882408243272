import React, { useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  transition: border-color 0.5s;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "#1B2635")};
  overflow: hidden;
  border-radius: 5px;
`;

const InputComponent = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  background: transparent;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
  transition: border-color 0.5s;
  width: 100%;
  border-color: ${({ $isFocused, $hoverBorderColor, $borderColor }) =>
    $isFocused
      ? $hoverBorderColor
        ? $hoverBorderColor
        : "#fff"
      : $borderColor
      ? $borderColor
      : "#333"};

  &:hover {
    border-color: ${({ $hoverBorderColor }) =>
      $hoverBorderColor ? $hoverBorderColor : "#fff"};
  }
`;
const InputEl = styled.input`
  outline: none;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  color: #fff;
  border-radius: 5px;
`;

const LabelContainer = styled.div`
  opacity: 0.8;
  font-size: 10px;
  white-space: nowrap;
  padding: 0px 2px;
  will-change: transform;
`;

export default function Input({ styleContainer, bgColor, label, ...props }) {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  return (
    <Container
      $bgColor={bgColor}
      style={styleContainer}
      onClick={() => inputRef.current.focus()}
    >
      <InputComponent $isFocused={isFocused}>
        {label && (
          <LabelContainer>
            <span>{label}</span>
          </LabelContainer>
        )}
        <InputEl
          ref={inputRef}
          {...props}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </InputComponent>
    </Container>
  );
}
