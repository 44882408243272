import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import NestedSelect from "../../../../../utils/NestedSelect";
import useOrders from "../../../../../hooks/useOrders";

const Divider = styled(MuiDivider)(spacing);

const ExtensionOrder = () => {
  const {
    data,
    employee,
    navigate,
    setStaffId,
    isFormValid,
    structureId,
    commandData,
    contractData,
    handleSubmit,
    setPositionId,
    allStructures,
    setStructureId,
    nestedChildren,
    setContractName,
    handleInputChange,
    emptyPositionDatas,
    currentContractData,
    setContractPosition,
    handleEmployeeChange,
    handleCurrentContract,
    createFillingVacantPositionOrder,
  } = useOrders();
  useEffect(() => {
    setContractName("transfer-order");
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            Uzadılma əmri
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ width: "50%" }}>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Əməkdaş(A.S.A)">Əməkdaş(A.S.A)</InputLabel>
                <Select
                  name="Əməkdaş(A.S.A)"
                  labelId="Əməkdaş(A.S.A)"
                  label="Əməkdaş(A.S.A)"
                  onChange={(e) => handleEmployeeChange(e.target.value)}
                >
                  {data &&
                    data.map((d, i) => (
                      <MenuItem key={i} value={d.id}>
                        {`${d?.firstname} ${d?.lastname} ${d?.fathername}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Grid
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "25px",
                  marginBottom: "16px",
                }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="contract_number">Müqavilə nömrəsi</InputLabel>
                  <Select
                    name="contract_number"
                    labelId="Müqavilə nömrəsi"
                    label="Müqavilə nömrəsi"
                    fullWidth
                    variant="outlined"
                    onChange={(e) => handleCurrentContract(e.target.value)}
                    disabled={!employee.employeeId}
                  >
                    {contractData &&
                      contractData?.map((d, i) => (
                        <MenuItem key={i} value={d?.contract?.staffs?.[0]?._id}>
                          {d?.contract?.data?.docNumber}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <DatePicker
                  my={2}
                  sx={{ width: "100%" }}
                  inputFormat="dd/MM/yyyy"
                  name="startWorkYear"
                  value={emptyPositionDatas?.startWorkYear}
                  label="Müqavilənin tarixi"
                  onChange={(date) => handleInputChange("startWorkYear", date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                value={employee?.tabelNumber}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
              />
              <FormControl
                my={2}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Təşkilat">Təşkilat</InputLabel>
                <Select
                  name="Təşkilat"
                  labelId="Təşkilat"
                  label="Təşkilat"
                  fullWidth
                  variant="outlined"
                  value={
                    currentContractData?.structure?.organization?.name || ""
                  }
                >
                  <MenuItem
                    value={currentContractData?.structure?.organization?.name}
                  >
                    {currentContractData?.structure?.organization?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                my={2}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Struktur">Struktur</InputLabel>
                <Select
                  name="Struktur"
                  labelId="Struktur"
                  label="Struktur"
                  fullWidth
                  variant="outlined"
                  value={
                    currentContractData?.contract?.topPartOfStructures?.[0]
                      ?.source?.name || ""
                  }
                >
                  <MenuItem
                    value={
                      currentContractData?.contract?.topPartOfStructures?.[0]
                        ?.source?.name
                    }
                  >
                    {
                      currentContractData?.contract?.topPartOfStructures?.[0]
                        ?.source?.name
                    }
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                my={2}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Substruktur">Substruktur</InputLabel>
                <Select
                  name="Substruktur"
                  labelId="Substruktur"
                  label="Substruktur"
                  fullWidth
                  variant="outlined"
                  value={currentContractData?.part?.parent?.source?.name || ""}
                >
                  <MenuItem
                    value={currentContractData?.part?.parent?.source?.name}
                  >
                    {currentContractData?.part?.parent?.source?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                my={2}
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Vəzifə">Vəzifə</InputLabel>
                <Select
                  name="Vəzifə"
                  labelId="Vəzifə"
                  label="Vəzifə"
                  fullWidth
                  variant="outlined"
                  value={currentContractData?.part?.source.name || ""}
                >
                  <MenuItem value={currentContractData?.part?.source.name}>
                    {currentContractData &&
                      currentContractData?.part?.source.name}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "50%" }}>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="organization"> Uzadılma növü</InputLabel>
                <Select
                  name="organization"
                  labelId="Uzadılma növü"
                  label="Uzadılma növü"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="ssds">Məzuniyyətdən geri çağırma</MenuItem>
                  <MenuItem value="ssds">Ezamiyyədən geri çağırma</MenuItem>
                </Select>
              </FormControl>
              <TextField
                name="positionLevel"
                label="Uzadılmanın səbəbi"
                sx={{ width: "100%", marginBottom: "16px" }}
                variant="outlined"
                my={2}
              />
              <Grid
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "25px",
                  marginBottom: "16px",
                }}
              >
                <TextField
                  sx={{ width: "50%" }}
                  name="personalCategory"
                  label="Əmrin nömrəsi"
                  variant="outlined"
                  my={2}
                />
                <TextField
                  name="positionLevel"
                  label="Əmrin imzalanma tarixi"
                  sx={{ width: "50%" }}
                  variant="outlined"
                  onChange={(e) =>
                    handleInputChange("positionLevel", e.target.value)
                  }
                  my={2}
                />
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  gap: "25px",
                  width: "100%",
                  marginBottom: "16px",
                }}
              >
                <DatePicker
                  my={2}
                  sx={{ width: "50%" }}
                  inputFormat="dd/MM/yyyy"
                  name="startWorkYear"
                  value={emptyPositionDatas?.startWorkYear}
                  label=" Mez/Ez başlama Tarixi "
                  onChange={(date) => handleInputChange("startWorkYear", date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  sx={{ width: "50%" }}
                  inputFormat="dd/MM/yyyy"
                  name="endWorkYear"
                  value={emptyPositionDatas?.endWorkYear}
                  onChange={(date) => handleInputChange("endWorkYear", date)}
                  label="Bitmə tarixi"
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  gap: "25px",
                  width: "100%",
                  marginBottom: "16px",
                }}
              >
                <DatePicker
                  my={2}
                  sx={{ width: "50%" }}
                  inputFormat="dd/MM/yyyy"
                  name="startWorkYear"
                  value={emptyPositionDatas?.startWorkYear}
                  label="İşə çıxma tarixi"
                  onChange={(date) => handleInputChange("startWorkYear", date)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  sx={{ width: "50%" }}
                  inputFormat="dd/MM/yyyy"
                  name="endWorkYear"
                  value={emptyPositionDatas?.endWorkYear}
                  onChange={(date) => handleInputChange("endWorkYear", date)}
                  label="Yeni işə çıxma tarixi"
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <TextField
                sx={{ width: "100%" }}
                name="newContractNumber"
                label="Gün"
                variant="outlined"
                my={2}
              />
            </Grid>
          </Grid>
          <Grid mb={10}>
            <TextField
              name="Qeyd"
              label="Qeyd"
              fullWidth
              variant="outlined"
              my={2}
            />
          </Grid>
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "25px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => navigate("/documentation/orders")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              title="Kənarlaşdırma yarat"
              variant="contained"
              sx={{ height: "100%" }}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Təsdiq et
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default ExtensionOrder;
