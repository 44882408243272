// React
import { useState } from "react";

// Material UI
import { Grid } from "@mui/material";

import Table from "../../../components/table/Table";
import Column from "../../../models/table/Column";

export default function MCSickLeaveCertificateTable() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState([]);

  // const handleOpenPopover = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClosePopover = () => {
  //   setAnchorEl(null);
  // };

  // const handleOptionClick = (option) => {
  //   handleClosePopover();
  // };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const columns = [
    new Column({
      path: "name",
      headerName: "Ad Soyad",
    }),
    new Column({
      path: "fathername",
      headerName: "Ata adı",
    }),
    new Column({
      path: "FIN",
      headerName: "Fin",
    }),
    new Column({
      path: "startdate",
      headerName: "Başlama tarixi",
    }),
    new Column({
      path: "enddate",
      headerName: "Bitmə tarixi",
    }),
    new Column({
      path: "content",
      headerName: "Məzmun",
    }),
    new Column({
      path: "approver",
      headerName: "Təsdiq edən",
    }),
    new Column({
      path: "documents",
      headerName: "Sənədlər",
    }),
  ];

  const rows = [
    {
      id: 1,
      name: "Tariyel Hümbətov",
      fathername: "Telman",
      FIN: "5LI9O6H",
      startdate: "09/10/2023",
      enddate: "15/10/2023",
      content: "ƏQMİ",
      approver: "Mehdi Məmmədzadə",
    },
    {
      id: 2,
      name: "Tariyel Hümbətov",
      fathername: "Telman",
      FIN: "5LI9O6H",
      startdate: "01/01/2023",
      enddate: "04/01/2023",
      content: "Xəstəlik",
      approver: "Mehdi Məmmədzadə",
    },
    {
      id: 3,
      name: "Tariyel Hümbətov",
      fathername: "Telman",
      FIN: "5LI9O6H",
      startdate: "23/02/2022",
      enddate: "30/02/2022",
      content: "Xəstəlik",
      approver: "Mehdi Məmmədzadə",
    },
  ];

  return (
    <>
      <Grid sx={{ width: "100%" }}>
        <Table
          columns={columns}
          data={rows}
          totalRows={3}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
        />
      </Grid>
    </>
  );
}
