import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Avatar, Box, Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import useAuth from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";

import LogoWithoutBorder from "./../../assets/logo/logoWithoutBorder.svg";
import Presentation from "../../layouts/Presentation";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const Container = styled.div`
  margin: auto;
  width: 500px;
`;

const BigAvatar = styled(Avatar)`
  width: 92px;
  height: 92px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
`;

function SignIn() {
  const { user, isAuthenticated } = useAuth();

  return (
    <React.Fragment>
      {isAuthenticated ? (
        <Navigate to="/private" replace={true} />
      ) : (
        <>
          <Wrapper>
            <Container>
              <Helmet title="Sign In" />

              <div
                style={{
                  width: "500px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={LogoWithoutBorder}
                  sx={{
                    width: "50%",
                    objectFit: "fit",
                    marginBottom: "15px",
                  }}
                />
              </div>
              {/* <Typography
              component="h2"
              variant="body1"
              align="center"
              mb={"15px"}
            >
              Sign in to your account to continue
            </Typography> */}

              <SignInComponent />
            </Container>
          </Wrapper>
        </>
      )}
    </React.Fragment>
  );
}

export default SignIn;
