import React from "react";
import { Field, FieldArray, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { sortedBanksList } from "../../constants/generally/BankInfos";

import { updateEmployee } from "../../api/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { bankInfoValidation } from "../employees/Validations";
import CustomAccordion from "../ui/Accordion/Accordion";

const EditBankInfo = ({ employeeById, submitted, setSubmitted }) => {
  const handleSubmit = async (
    values,
    { setSubmitting, validateForm, resetForm }
  ) => {
    setSubmitting(true);

    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      // const hasDuplicate = checkUniquenessForNext(
      //   values.bankInfos,
      //   "iban",
      //   "IBAN"
      // );

      const modifiedValues = {
        ...values,
        bankInfos:
          values?.bankInfos &&
          values?.bankInfos.map((item) => ({
            ...item,
            bankCode: parseInt(item.bankCode),
            accountNumber: item.accountNumber.trim(),
            iban: item.iban.trim(),
            cardExpirationDate: item?.cardExpirationDate
              ? item?.cardExpirationDate
              : `${item?.month}/${item?.year}`,
          })),
      };
      await updateEmployee(employeeById._id, modifiedValues);
      setSubmitting(false);
      setSubmitted(!submitted);
      resetForm({ values });
    } else {
      setSubmitting(false);
    }
  };

  const handleBankSelect = (item) =>
    sortedBanksList.find((bank) => bank.name === item);

  const arrayMonth = Array.from({ length: 12 }, (_, index) => {
    const month = index + 1;
    return month < 10 ? `0${month}` : `${month}`;
  });
  const years = Array.from({ length: 2099 - 2024 + 1 }, (_, index) => {
    const year = 2024 + index;
    return `${year}`;
  });

  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Bank məlumatları</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              bankInfos: employeeById.bankInfos || [], // Set the initial laborActivity values from employeeById
            }}
            validationSchema={bankInfoValidation(employeeById)}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isValid,
              errors,
              isSubmitting,
              handleChange,
              setFieldValue,
              validateForm,
              setErrors,
              touched,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="bankInfos">
                    {({ push, remove }) => (
                      <div>
                        {values?.bankInfos &&
                          values?.bankInfos.map((form, index) => (
                            <div key={index}>
                              <>
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1})Bank məlumatları
                                </Typography>

                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        errors?.bankName &&
                                        touched?.bankInfos?.bankName &&
                                        errors?.bankInfos[index]?.bankName
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`bankInfos[${index}].bankName`}
                                      >
                                        Banklar
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`bankInfos[${index}].bankName`}
                                        labelId={`bankInfos[${index}].bankName`}
                                        label="Banklar"
                                        id="bankName-select"
                                        onChange={(e) => {
                                          setFieldValue(
                                            `bankInfos[${index}].bankName`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `bankInfos[${index}].bankCode`,
                                            handleBankSelect(e.target.value)
                                              .code
                                          );
                                          setFieldValue(
                                            `bankInfos[${index}].bankVoen`,
                                            handleBankSelect(e.target.value)
                                              .voen
                                          );
                                        }}
                                        fullWidth
                                        error={
                                          errors?.bankInfos?.[index]
                                            ?.bankName &&
                                          touched?.bankInfos?.[index]
                                            ?.bankName &&
                                          Boolean(
                                            errors?.bankInfos?.[index]?.bankName
                                          )
                                        }
                                      >
                                        {sortedBanksList.map((item, index) => (
                                          <MenuItem
                                            key={index}
                                            value={item.name}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Field>

                                      {/* {errors?.bankInfos[index]?.bankName &&
                                  errors?.bankInfos[index]?.bankName && (
                                    <FormHelperText error>
                                      {errors?.bankInfos[index]?.bankName}
                                    </FormHelperText>
                                  )} */}
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="number"
                                      name={`bankInfos[${index}].bankCode`}
                                      as={TextField}
                                      label="Bank kod"
                                      value={values.bankInfos[index].bankCode}
                                      defaultValue={
                                        values.bankInfos[index].bankCode
                                      }
                                      fullWidth
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Grid>
                                </Grid>

                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`bankInfos[${index}].bankVoen`}
                                      as={TextField}
                                      label="Bank VÖEN"
                                      value={values.bankInfos[index].bankVoen}
                                      defaultValue={
                                        values.bankInfos[index].bankVoen
                                      }
                                      fullWidth
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`bankInfos[${index}].accountNumber`}
                                      as={TextField}
                                      label="Müxbir Hesab nömrəsi *"
                                      error={
                                        errors?.bankInfos?.[index]
                                          ?.accountNumber &&
                                        touched?.bankInfos?.[index]
                                          ?.accountNumber &&
                                        Boolean(
                                          errors?.bankInfos?.[index]
                                            ?.accountNumber
                                        )
                                      }
                                      helperText={
                                        errors?.bankInfos?.[index]
                                          ?.accountNumber &&
                                        touched?.bankInfos?.[index]
                                          ?.accountNumber &&
                                        errors?.bankInfos?.[index]
                                          ?.accountNumber
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>

                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`bankInfos[${index}].iban`}
                                      as={TextField}
                                      label="IBAN"
                                      error={
                                        errors?.bankInfos?.[index]?.iban &&
                                        touched?.bankInfos?.[index]?.iban &&
                                        Boolean(
                                          errors?.bankInfos?.[index]?.iban
                                        )
                                      }
                                      helperText={
                                        errors?.bankInfos?.[index]?.iban &&
                                        touched?.bankInfos?.[index]?.iban &&
                                        errors?.bankInfos?.[index]?.iban
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      gap: "10px",
                                    }}
                                  >
                                    <FormControl
                                      sx={{
                                        width: "20%",
                                      }}
                                    >
                                      <Field
                                        as={Select}
                                        name={`bankInfos[${index}].month`}
                                        defaultValue={
                                          values?.bankInfos?.[index]
                                            ?.cardExpirationDate
                                            ? values.bankInfos[
                                                index
                                              ].cardExpirationDate.split("/")[0]
                                            : ""
                                        }
                                        fullWidth
                                        onChange={async (e) => {
                                          setFieldValue(
                                            `bankInfos[${index}].month`,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        {arrayMonth &&
                                          arrayMonth.map((item, index) => (
                                            <MenuItem value={item} key={index}>
                                              {item}
                                            </MenuItem>
                                          ))}
                                      </Field>
                                    </FormControl>
                                    <FormControl
                                      sx={{
                                        width: "80%",
                                      }}
                                    >
                                      <InputLabel>
                                        Kartın etibarlıq müddəti
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        label="Kartın etibarlıq müddəti"
                                        name={`bankInfos[${index}].year`}
                                        defaultValue={
                                          values?.bankInfos?.[index]
                                            ?.cardExpirationDate
                                            ? values.bankInfos[
                                                index
                                              ].cardExpirationDate.split("/")[1]
                                            : ""
                                        }
                                        fullWidth
                                        onChange={async (e) => {
                                          setFieldValue(
                                            `bankInfos[${index}].year`,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        {years.map((item, index) => (
                                          <MenuItem value={item} key={index}>
                                            {item}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => remove(index)}
                              >
                                Bank məlumatını sil
                              </Button>
                            </div>
                          ))}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() =>
                            push({ bankCode: "", bankVoen: "", iban: "" })
                          }
                        >
                          Bank məlumatları əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>

                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditBankInfo;
