/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";
// Material UI
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
// Services
import * as staffServices from "../../../services/staff/StaffServices";
// Helpers
import { STAFF_TIME } from "../../../constants";
import {
  blockInvalidChar,
  IconTextField,
} from "../../../utils/helperFunctions";
import { toast } from "react-toastify";
import { enqueueSnackbar } from "notistack";
import { getCommonData } from "../../../api/api";
import {Add} from "@mui/icons-material"

const StaffDialog = ({
  classes,
  open,
  item,
  onClose,
  setIsUpdated,
  isUpdated,
  setActivePositions,
  getStructure,
  handleAddItemClick,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [max_salary, set_max_salary] = useState(item?.max_salary);
  const [supplementalWageGeneral, set_supplementalWageGeneral] = useState([]);
  const [renderwage, set_renderwage] = useState(false);

  const validationSchema = Yup.object({
    staffUnit: Yup.string().required("Bu xana məcburidir"),
    positionSalary: Yup.number()
      .required("Bu xana məcburidir")
      // .min(300, "Minimum əmək haqqı 300 AZN")
      .max(max_salary, `Maksimum əmək haqqı ${max_salary} AZN`),
    count: Yup.number()
      .required("Bu xana məcburidir")
      .min(1, "Minimum say 1 ola bilər"),
  });
  const initialValues = {
    staffUnit: 0.25,
    positionSalary: 300,
    position: item?.part?._id,
    structure: id,
    part: item?._id,
    count: 1,
  }


  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,

    onSubmit: async (data) => {
      try {
        const payload = {
          ...data,
          position: item?.part?._id,
          structure: id,
          part: item?._id,
        };
        const resp = await staffServices.addStaff(payload, enqueueSnackbar);
        if (resp && resp.length > 0) {
          toast.success("Yeni ştat uğurla əlavə olundu!");
          formik?.resetForm(initialValues);
          getStructure();
          onClose();
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },
  });

  function renderWage(renderwage) {
    set_renderwage(!renderwage);
    if (!renderwage) {
      formik.setFieldValue("supplementalWage", {
        name: "",
        value: ""
      });
    } else {
      formik.setFieldValue("supplementalWage", undefined);
    }
  }

  // useEffect(() => {
  //   let arr = [];
  //   for (let i = 0; i < formik.values.count; i++) {
  //     arr.push({
  //       name: null,
  //       value: 0
  //     })
  //   }
  //   formik.setFieldValue("supplementalWages", arr);
  // }, [formik.values.count])

  const getSpecialFoodSupplements = async () => {
    try {
      const response = await getCommonData("supplementalWageGeneral");
      set_supplementalWageGeneral(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSpecialFoodSupplements()
  }, []);

  useEffect(() => {
    let max_salary = formik.values.staffUnit * item?.max_salary;
    set_max_salary(max_salary)
  }, [formik, item?.max_salary]);

  const staffTime = STAFF_TIME;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      style={{ backgroundColor: "#ffffff21" }}
    >
      <DialogTitle
        id="form-dialog-title"
        className={`${classes.fs_23} ${classes.mb_2} text-center`}
      >
        Ştat əlavə edin
      </DialogTitle>
      <DialogContent style={{ minWidth: "600px" }}>
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-lg-12 mt-4">
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  htmlFor="max-width"
                  id="demo-simple-select-standard-label"
                  style={
                    formik.touched?.staffUnit && formik.errors?.staffUnit
                      ? { color: "#f44336" }
                      : null
                  }
                >
                  Ştatı vahidi seçin*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name="staffUnit"
                  label={`Ştatı vahidi seçin*`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched?.staffUnit && formik.errors?.staffUnit
                  )}
                  value={formik.values?.staffUnit}
                  className={
                    Boolean(
                      formik.touched?.staffUnit && formik.errors?.staffUnit
                    )
                      ? "error"
                      : ""
                  }
                >
                  {staffTime.length &&
                    staffTime.map((time, index) => (
                      <MenuItem key={index + 1} value={time.timeId}>
                        {time.content}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched?.staffUnit && (
                  <FormHelperText>
                    <span
                      style={
                        formik.touched?.staffUnit ? { color: "#f44336" } : null
                      }
                    >
                      {formik.errors?.staffUnit}
                    </span>
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-lg-12 mt-4">
              <IconTextField
                id="standard-basic"
                label="Ştat üzrə əmək haqqı*"
                type="number"
                name="positionSalary"
                variant="standard"
                className={classes.input}
                iconEnd={
                  <span
                    className={classes.staffSalary}
                    style={
                      Boolean(
                        formik.touched?.positionSalary &&
                        formik.errors?.positionSalary
                      )
                        ? { color: "#f44336" }
                        : undefined
                    }
                  >
                    ₼
                  </span>
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyDown={blockInvalidChar}
                error={Boolean(
                  formik.touched?.positionSalary &&
                  formik.errors?.positionSalary
                )}
                value={formik.values?.positionSalary}
                helperText={
                  formik.touched?.positionSalary &&
                  formik.errors?.positionSalary
                }
                fullWidth
              />
            </div>
            <div className="col-lg-12 mt-4">
              <TextField
                id="standard-basic"
                variant="standard"
                name="count"
                label={`Ştat sayı`}
                type="number"
                inputProps={{ maxLength: item?.max_salary }}
                className={classes.input}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onKeyDown={blockInvalidChar}
                value={formik.values.count}
                defaultValue={1}
                error={Boolean(formik.touched?.count && formik.errors?.count)}
                helperText={formik.touched?.count && formik.errors?.count}
                fullWidth
              />
            </div>
            {/* { */}
            {/* // formik.values.supplementalWages?.map((c, i) => ( */}
            {
              renderwage &&
              <div className="col-lg-12 mt-4 d-flex">
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    htmlFor="max-width"
                    id="demo-simple-select-standard-label"
                  >
                    Əməkhaqqına əlavənin səbəbi
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name={`supplementalWage.name`}
                    label={`Əməkhaqqına əlavənin səbəbi`}
                    value={formik?.values?.supplementalWage?.name}
                    onChange={formik.handleChange}
                  >
                    {supplementalWageGeneral?.length &&
                      supplementalWageGeneral?.map((item, i) => (
                        <MenuItem key={i} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl variant="standard" fullWidth className="ms-2">
                  <IconTextField
                    id="standard-basic"
                    label="Əməkhaqqına əlavə"
                    type="number"
                    name={'supplementalWage.value'}
                    variant="standard"
                    className={classes.input}
                    iconEnd={
                      <span
                        className={classes.staffSalary}
                      >
                        ₼
                      </span>
                    }
                    onChange={formik.handleChange}
                    // error={Boolean(
                    //   formik?.values?.supplementalWages[i]?.value &&
                    //   formik?.values?.supplementalWages[i]?.value
                    // )}
                    value={formik?.values?.supplementalWage?.value || ""}
                    fullWidth
                  />
                </FormControl>
              </div>
            }
            {/* <Button variant="contained" onClick={() => renderWage(renderwage)} className="mt-2">{!renderwage ? <div>Əməkhaqqına əlavə et <Add className="ms-2"/></div> : "Ləğv et"}</Button> */}
            <div className="col-12 d-flex justify-content-end align-items-center mt-5">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formik.isValid}
              >
                <span className="text-disabled">Təsdiqlə</span>
              </Button>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default StaffDialog;
