import { Button, Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getStaffsById } from "../../../services/staff/StaffServices";
import { verifyStructureAndRecruiter } from "../../../services/structure/StructureServices";
import DialogHeader from "../../dialogs/documentDialog/dialogParts/DialogHeader";
import LoadingTable from "../../table/parts/LoadingTable";

export default function ApproveDialog({
  open,
  onClose,
  structureId,
  onSuccess,
}) {
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const [staffId, setStaffId] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!structureId) return;
    fetchStaffs(structureId);
  }, [structureId]);

  const fetchStaffs = async (id) => {
    try {
      setLoading(true);
      const resp = await getStaffsById({ id: structureId, limit, offset });
      const isEmpty =
        resp && resp.data?.staffs?.every((item) => !item.employee.length > 0);
      if (isEmpty) setIsEmpty(true);
      resp && setData(resp.data);
    } catch (error) {
      console.log("err", error);
    } finally {
      setLoading(false);
    }
  };

  const approveRecruiter = async () => {
    try {
      const resp = await verifyStructureAndRecruiter(staffId, structureId);
      resp && onSuccess();
      console.log(staffId, structureId);
    } catch (error) {
      console.log("err", error);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: { width: "50vw", height: "50vh" },
        }}
      >
        {loading ? (
          <LoadingTable />
        ) : (
          <div style={{ width: "100%", height: "100%" }}>
            <DialogHeader onClose={onClose} title={"İşə götürən təyini"} />
            {!isEmpty ? (
              <>
                <div
                  style={{
                    height: "80%",
                    width: "100%",
                    border: "1px solid rgba(255,255,255, 0.1)",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    overflow: "scroll",
                  }}
                >
                  {data?.staffs?.map(
                    (item) =>
                      item.employee?.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            marginBottom: "5px", // Corrected from "margintBottom" to "marginBottom"
                            borderBottom: "1px solid rgba(255,255,255, 0.1)",
                          }}
                          onClick={() => setStaffId(item._id)}
                        >
                          <input
                            type="checkbox"
                            id={`staff-${item._id}`} // Ensure unique id for each radio button
                            name="staff" // Ensure same name for all radio buttons in the group
                            value={item._id}
                            style={{ width: "2.5%" }}
                            onChange={() => setStaffId(item._id)}
                            checked={staffId === item._id}
                          />
                          <label
                            style={{ marginLeft: "5px", width: "80%" }}
                            htmlFor={`staff-${item._id}`} // Match the id of the radio button
                          >
                            {`${item.employee[0]?.firstname} ${item.employee[0]?.lastname} ${item.employee[0]?.fathername} - ${item.position?.name}`}
                          </label>
                        </div>
                      )
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "4px",
                  }}
                >
                  <Button
                    style={{
                      padding: "10px",
                      marginRight: "10px",
                    }}
                    disabled={!staffId}
                    color="success"
                    onClick={approveRecruiter}
                  >
                    Təsdiqlə
                  </Button>
                </div>
              </>
            ) : (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Hazırda ştatda əməkdaş yoxdur.</span>
              </div>
            )}
          </div>
        )}
      </Dialog>
    </>
  );
}
