import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Chat from "./TicketingChat";
import * as ticketApi from "../../api/ticket";

import { format } from "date-fns";
import { az } from "date-fns/locale";

// import { AppConfig } from "../../config";

import {
  CardContent,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, display } from "@mui/system";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(display);

const TicketingDetails = () => {
  const { id } = useParams();
  const [ticketState, setTicketState] = useState();
  const [refreshState] = useState(true);

  useEffect(() => {
    const getTicket = async () => {
      const ticketData = await ticketApi.getTicketById(id);

      setTicketState(ticketData);
    };

    getTicket();
  }, [id, refreshState]);

  const months = [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "İyun",
    "İyul",
    "Avqust",
    "Sentyabr",
    "Oktyabr",
    "Noyabr",
    "Dekabr",
  ];

  const formatDate = (date) => {
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  return (
    <React.Fragment>
      <Helmet title="Sorğunun Detalları" />

      <Typography variant="h3" gutterBottom display="inline">
        {ticketState?.ticketId}
      </Typography>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={20}>
          <Shadow>
            <Card px={6} pt={6}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                      İzah :{ticketState?.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">
                      Sorğu kodu : {ticketState?.ticketId}
                    </Typography>
                    <Typography variant="body2">
                      Status :{ticketState?.status}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      Yaradılma tarixi :
                    </Typography>

                    <Typography variant="body2" align="right">
                      {formatDate(new Date(ticketState?.createdAt))}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {ticketState?.attachments.map((attachment) => (
                        <React.Fragment key={attachment._id}>
                          {/* <strong>Attachment:</strong>{" "} */}
                          {/* <Link
                            to={`${AppConfig.attachmentsUrl}/${attachment}`}
                          >
                            {attachment}
                          </Link>
                          <span
                            onClick={() =>
                              window.open(
                                `${AppConfig.attachmentsUrl}/${attachment._id}`,
                                "_blank"
                              )
                            }
                            className="cursor-pointer"
                          >
                            {attachment.email}
                          </span> */}
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
      <Chat replies={ticketState?.replies} />
    </React.Fragment>
  );
};

export default TicketingDetails;
