import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";

const LoadingPopup = ({ state, dispatch }) => {
  return (
    <Backdrop open={state?.loadingDialog} sx={{ zIndex: 9999 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingPopup;
