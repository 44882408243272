import { api } from "../../api/axiosInterceptor";

// GET: employee/naming -> Get Structure Namings
export const getCompanyStructures = async () => {
  try {
    const response = await api.get(`/employee/naming`);
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
