import { api } from "./axiosInterceptor";

// ⇩ ⇩ ⇩  Download Requests ⇩ ⇩ ⇩

export const downloadWorkTimeAccountTable_API = async (
  filter,
  month,
  year,
  structureId
) => {
  const url = `employee/time-table/download-excel?filter=${filter}&month=${month}&year=${year}&structure=${structureId}`;

  return await api.get(url, { responseType: "blob" });
};

// ⇧ ⇧ ⇧ Download Requests ⇧ ⇧ ⇧;
