export const downloadFile = (response, fileName = "report.csv", mime) => {
  const url = window.URL.createObjectURL(
    new Blob([response?.data], mime && { type: mime })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
