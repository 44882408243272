import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

export default function ExportCSVButton({
  exportData,
  //csvHeader must be, then will render
  csvHeader,
  handleExport,
  fileName,
  data,
}) {
  const [exportStatus, setExportStatus] = useState("initial");

  useEffect(() => {
    if (exportStatus === "preparing") {
      const timeout = setTimeout(() => {
        setExportStatus("finished");
      }, 2000); // 2 seconds

      return () => clearTimeout(timeout); // Clear the timeout if component unmounts or status changes
    }
  }, [exportStatus]);

  //example of handleExport
  //   let forExcel = true;
  //   const handleExport = async () => {
  //     try {
  //       const response = await getAllEmployees(limit, filters, forExcel);
  //       const formattedUsers = response.data.employees.map((user) => ({
  //         ...user,
  //         dob: moment(user.dob).format("DD/MM/yyyy"), // Format the date
  //         idCardValidityPeriod: moment(user.idCardValidityPeriod).format(
  //           "DD/MM/yyyy"
  //         ),
  //         idCardIssuanceDate: moment(user.idCardIssuanceDate).format(
  //           "DD/MM/yyyy"
  //         ),
  //       }));
  //       setExportData(formattedUsers);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  return (
    <Grid>
      {!data || !csvHeader ? null : (
        <CSVLink
          style={{}}
          onClick={(event, done) => {
            if (exportStatus === "initial") {
              handleExport();
              done(false);
              setExportStatus("preparing");
            } else if (exportStatus === "finished") {
              done(true);
            } else {
              done(false);
            }
          }}
          asyncOnClick={true}
          filename={fileName}
          data={exportData && exportData}
          headers={csvHeader}
        >
          <Button variant="contained" color="secondary" sx={{ height: "100%" }}>
            {exportStatus === "initial"
              ? "Excel faylını düzəlt"
              : exportStatus === "preparing"
              ? "Məlumatlar alınır..."
              : exportStatus === "finished"
              ? "Yükləmək üçün klik edin"
              : null}
          </Button>
        </CSVLink>
      )}
    </Grid>
  );
}
