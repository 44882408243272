import { Add as AddIcon, Person as PersonIcon } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Trash } from "react-feather";
import { addGroupUsers, deleteGroupUser, makeGroupAdmin } from "../../api/api";

const CreateGroupChat = ({ user, state, dispatch }) => {
  const { users, groupChat, openGroupChatUsers, chatId, submitted } = state;
  const [newUsersDialog, setNewUsersDialog] = useState(false);
  const [deleteUserDialog, setDeleteUserDialog] = useState(false);
  const [userId, setUserId] = useState();
  const [adminRole, setAdminRole] = useState([]);

  // Checked users
  const [checked, setChecked] = useState([]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAddGroupUsers = async (chatId, checked) => {
    await addGroupUsers(chatId, checked);
    dispatch({
      type: "SET_SUBMITTED",
      payload: !submitted,
    });
    setChecked([]);
  };

  const handleDeleteUserFromGroup = async (chatId, userId) => {
    await deleteGroupUser(chatId, userId);
    dispatch({
      type: "SET_SUBMITTED",
      payload: !submitted,
    });
    setUserId();
  };

  let adminUsers =
    groupChat && groupChat.members
      ? groupChat.members.filter((user) => user.role === "admin")
      : [];

  const handleGroupAdmins = async (chatId, content) => {
    await makeGroupAdmin(chatId, content);
    dispatch({
      type: "SET_SUBMITTED",
      payload: !submitted,
    });
    adminUsers = groupChat.members.filter((user) => user.role === "admin");
  };

  const [chatPicture, setChatPicture] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Dialog
        onClose={() => {
          dispatch({
            type: "SET_OPEN_GROUP_CHAT_USERS",
            payload: false,
          });
        }}
        aria-labelledby="simple-dialog-title"
        open={openGroupChatUsers}
        fullWidth
      >
        <DialogTitle id="simple-dialog-title">Qrup şəkli</DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <ListItemIcon
            onClick={() =>
              dispatch({
                type: "SET_OPEN_CHAT_IMAGE",
                payload: true,
              })
            }
          >
            <Tooltip title="Klik edərək yeni şəkil əlavə edin.">
              <Avatar
                sx={{ width: 100, height: 100, cursor: "pointer" }}
                alt={groupChat.name}
                src={groupChat.image}
              />
            </Tooltip>
          </ListItemIcon>
        </DialogContent>

        <DialogTitle id="simple-dialog-title">
          Qrupda olan istifadəçilər
        </DialogTitle>

        <DialogContent sx={{ overflow: "hidden" }}>
          <List
            dense
            fullWidth
            sx={{
              maxHeight: "40vh",
              bgcolor: "background.paper",
              overflow: "auto",
            }}
          >
            {groupChat.members &&
              groupChat.members.map((item) => {
                if (item.status !== "removed") {
                  return (
                    <>
                      {/* {user.id !== item.user.id ? ( */}
                      <ListItem button key={item.user._id}>
                        <ListItemAvatar>
                          <Avatar>
                            <PersonIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${item.user.firstname} ${item.user.lastname} ${item.user.fathername}`}
                        />
                        {item.role !== "admin" ? (
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => {
                              setDeleteUserDialog(true);
                              setUserId(item.user._id);
                            }}
                          >
                            <Trash size="20px" />
                          </IconButton>
                        ) : null}

                        <ListItemText
                          sx={{
                            display: item.role === "admin" ? "flex" : "none",
                            textAlign: "end",
                            justifyContent: "flex-end",
                            color: "#64b5f6",
                          }}
                          primary={item.role === "admin" ? "Admin" : ""}
                        />

                        {/* Delete and make admin icon for admins */}
                        {adminUsers.map((adminItem) => {
                          if (adminItem.user._id === user.id) {
                            return (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    gap: 1,
                                  }}
                                >
                                  <ListItemText
                                    onClick={() => {
                                      handleGroupAdmins(chatId, {
                                        userId: item.user._id,
                                        role: "admin",
                                      });
                                    }}
                                    sx={{
                                      display: "flex",
                                      textAlign: "end",
                                      justifyContent: "flex-end",
                                      ":hover": {
                                        color: "#64b5f6",
                                      },
                                    }}
                                    primary={
                                      item.role !== "admin" ? "Make admin" : ""
                                    }
                                  />
                                  <IconButton
                                    color="error"
                                    size="small"
                                    onClick={() => {
                                      setDeleteUserDialog(true);
                                      setUserId(item.user._id);
                                    }}
                                  >
                                    <Trash size="20px" />
                                  </IconButton>
                                </Box>
                              </>
                            );
                          }
                        })}
                      </ListItem>
                      {/* ) : null} */}
                    </>
                  );
                }
              })}
          </List>

          {/* Add group member and delete group */}
          {adminUsers.map((adminItem) => {
            if (adminItem.user._id === user.id) {
              return (
                <Box
                  key={adminItem.user._id}
                  sx={{ display: "flex", justifyContent: "center", gap: 5 }}
                  fullWidth
                >
                  <Button
                    onClick={() => setNewUsersDialog(true)}
                    mr={2}
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ width: "200px" }}
                  >
                    Qrupa üzv əlavə et
                    <AddIcon />
                  </Button>

                  {/*  */}
                  <Button
                    onClick={() =>
                      dispatch({
                        type: "SET_DELETE_CHAT_DIALOG",
                        payload: true,
                      })
                    }
                    mr={2}
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{ width: "200px" }}
                  >
                    Qrupu sil
                  </Button>
                </Box>
              );
            }
          })}
        </DialogContent>
      </Dialog>

      {/* Delete user from group chat dialog */}
      <Dialog
        open={deleteUserDialog}
        onClose={() => {
          setDeleteUserDialog(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Silmək istədiyinizdən əminsiniz ?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteUserDialog(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleDeleteUserFromGroup(chatId, userId);
              setDeleteUserDialog(false);
            }}
          >
            Bəli
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add new users to group chat Dialog */}
      <Dialog
        fullWidth
        open={newUsersDialog}
        onClose={() => {
          setNewUsersDialog(false);
          setChecked([]);
          dispatch({
            type: "SET_SEARCH_USERNAME",
            payload: "",
          });
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          {/* User Search */}
          <Box my={3}>
            <TextField
              label="İstifadəçi axtar"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                dispatch({
                  type: "SET_SEARCH_USERNAME",
                  payload: e.target.value,
                });
              }}
            />
          </Box>

          {/* User list */}
          <List
            dense
            fullWidth
            sx={{
              maxHeight: "40vh",
              bgcolor: "background.paper",
              overflow: "auto",
            }}
          >
            {users.map((item, index) => {
              const labelId = `checkbox-list-secondary-label-${item.username}`;
              if (item._id !== user.id) {
                return (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(item._id)}
                        checked={checked.indexOf(item._id) !== -1}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          alt={`Avatar n°${index + 1}`}
                          src={`/static/img/avatars/user.png`}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        id={item._id}
                        primary={`${item?.firstname} ${item?.lastname} ${item?.fathername}`}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              }
            })}
          </List>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setNewUsersDialog(false);
              dispatch({
                type: "SET_SEARCH_USERNAME",
                payload: "",
              });
              setChecked([]);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              handleAddGroupUsers(chatId, checked);
              setNewUsersDialog(false);
              dispatch({
                type: "SET_SEARCH_USERNAME",
                payload: "",
              });
            }}
            color="primary"
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>

      {/* Loading dialog */}
      {loading && (
        <Dialog open={true} fullWidth>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
              <Typography variant="body1" ml={2}>
                Uploading...
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CreateGroupChat;
