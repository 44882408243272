import { getEmployeeById } from "../api/api";
import {
  createFillingVacantPositionOrder_API,
  getCommand_API,
  getEmployeesForRecallData,
  getEmployeeWithDetails,
  getFillingVacantPositionOrder_API,
  updateVacantOrder_API,
} from "../api/ordersRequests";

export const postFillingVacantPositionOrder_SERVICE = async (
  contractName,
  payload
) => {
  try {
    const response = await createFillingVacantPositionOrder_API(
      contractName,
      payload
    );
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
export const getFillingVacantPositionOrder_SERVICE = async (filter) => {
  try {
    const response = await getFillingVacantPositionOrder_API(filter);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
export const getEmployeeId_API_SERVICE = async (id) => {
  try {
    const response = await getEmployeeById(id);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
export const getCommand_API_SERVICE = async (id, contractName) => {
  try {
    const response = await getCommand_API(id, contractName);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};

export const putVacantOrder_SERVICE = async (contractName, id, payload) => {
  try {
    const response = await updateVacantOrder_API(contractName, id, payload);
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};

export const employeesForRecall_SERVICE = async () => {
  try {
    const response = await getEmployeesForRecallData();
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
export const getEmployeeWithDetails_SERVICE = async () => {
  try {
    const response = await getEmployeeWithDetails();
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
