import {
  COLORS_STATUS,
  CONTRACTS_TYPE_DOC_MODELS_ENUMS,
  REQUESTS_STATUS_TYPE,
} from "../../../constants";

export const contractGeneratorHandler = (data, fromWhichPage, loggedUser) => {
  const ch = contractHandlers;

  const relatedDocs = ch.generateNavList(data);
  const fields = data.data;
  const comments = ch.generateComments(data);
  const status = ch.generateStatus(data);
  const bgColor = ch.generateBgColor(data);
  const headerText = ch.generateHeader(data);
  const recordNo = ch.generateRecordNo(data);

  return {
    fields: fields,
    relatedDocs: relatedDocs,
    comments: comments,
    status: status,
    bgColor: bgColor,
    headerText: headerText,
    recordNo: recordNo,
  };
};

export const requestGeneratorHandler = (data, fromWhichPage, loggedUser) => {
  const rh = requestHandlers;

  //   const relatedDocs = ch.generateNavList(data);
  const fields = data.data;
  const comments = rh.generateComments(data);
  const status = rh.generateStatus(data, loggedUser);
  const bgColor = rh.generateBgColor(data);
  const headerText = rh.generateHeader(data, fromWhichPage);
  const recordNo = rh.generateRecordNo(data);

  return {
    status: status,
    comments: comments,
    fields: fields,
    bgColor: bgColor,
    headerText: headerText,
    recordNo: recordNo,
  };
};

const contractHandlers = {
  generateNavList(data) {
    const list = [];

    data?.relatedContracts.map((item, index) => {
      const obj = {
        label: this.normalizeDocName(item.contractType),
        value: item.docId,
      };
      list.push(obj);
    });
    return list;
  },
  normalizeDocName(key) {
    switch (key) {
      case "employment-application":
        return "Ərizə";
      case "labour-contract":
        return "Müqavilə";
      case "labour-order":
        return "Əmr";
      case "vacation-order":
        return "Məzuniyyət əmri";
      case "vacation-application":
        return "Məzuniyyət ərizəsi";
      default:
        return key;
    }
  },
  generateComments(data) {
    const commentsOBJ = data.verifiers;

    const comments = [];
    const labelMsg = `Şərh: `;
    const status = (status) => {
      switch (status) {
        case "approved":
          return "təsdiq edirəm";
        case "rejected":
          return "imtina edirəm";
        case "pending":
          return "qərar gözlənilir";
        default:
          return "...";
      }
    };

    if (commentsOBJ.length === 0) return;
    commentsOBJ.map((item) => {
      comments.push({
        label:
          labelMsg +
          `${item.employee.firstname} ${item.employee.lastname} (${
            item.employee.position?.name
          }) - ${status(item.status)}`,
        value: item.description,
      });
    });

    if (data.hrNotes?.length > 0) {
      data.hrNotes.map((item) => {
        comments.push({
          label: "Şərh" + "Yönləndirilmə səbəbi",
          value: item.description,
        });
      });
    }

    return comments;
  },
  generateStatus(data) {
    return data.status;
  },
  generateBgColor(data) {
    switch (data.status) {
      case "pending":
        return COLORS_STATUS.BLUE;
      case "approved":
        return COLORS_STATUS.GREEN;
      case "rejected":
        return COLORS_STATUS.RED;
      default:
        return COLORS_STATUS.GREY;
    }
  },
  generateHeader(data) {
    const docType = this.findDocType(data.contractType?.type);

    switch (this.generateStatus(data)) {
      case "pending":
        return `İmza gözləyən ${docType}`;
      case "approved":
        return `Qüvvədə olan ${docType}`;
      case "rejected":
        return `İmtina edilmiş ${docType}`;
      default:
        return "Sorğu";
    }
  },
  findDocType(type) {
    switch (type) {
      case CONTRACTS_TYPE_DOC_MODELS_ENUMS.AGREEMENT:
        return "müqavilə";
      case CONTRACTS_TYPE_DOC_MODELS_ENUMS.APPLICATION:
        return "ərizə";
      case CONTRACTS_TYPE_DOC_MODELS_ENUMS.ORDER:
        return "əmr";
      case CONTRACTS_TYPE_DOC_MODELS_ENUMS.SERVICE:
        return "xidməti yazı";
      case CONTRACTS_TYPE_DOC_MODELS_ENUMS.Explanation:
        return "izahat";
      default:
        return "";
    }
  },
  generateRecordNo(data) {
    const docNo = data
      ? validateValue(
          data?.data?.contract_general_info?.map((item) => {
            if (item.key === "docNumber") return item.value;
          })
        )
      : validateValue(
          data?.data?.document_general_info?.map((item) => {
            if (item.key === "docNumber") {
              return item.value;
            }
          })
        );
    return docNo;
  },
};

const requestHandlers = {
  generateStatus(data, loggedUser) {
    const isRejected = this.isRejectedHandler(data);
    if (isRejected) {
      return isRejected;
    }

    const status = this.checkLoggedUser(data, loggedUser);
    return status ? status : data.contract.status;
  },
  checkLoggedUser(data, loggedUser) {
    const isIt = data?.approvementChain?.find(
      (item) => item.staff.employee[0].id === loggedUser?.id
    );

    if (isIt) return isIt.status;
    else return false;
  },
  isRejectedHandler(data) {
    const isRejected = data?.approvementChain?.find(
      (item) => item.status === "rejected"
    );
    if (isRejected) return isRejected.status;
    else return false;
  },
  generateComments(data) {
    if (data?.docModel === "StructureRequest") {
      return this.generateCommentsOfStructureReq(data);
    }

    const arr = [];
    const status = (status) => {
      switch (status) {
        case "approved":
          return "təsdiq edirəm";
        case "rejected":
          return "imtina edirəm";
        default:
          return "";
      }
    };
    const msg = "Şərh: ";

    data.approvementChain?.map((approver) => {
      const { firstname, lastname, position } = approver.staff.employee[0];
      if (approver.status === "pending") return;
      arr.push({
        label:
          msg +
          `${firstname} ${lastname}(${position?.name}) - ${status(
            approver.status
          )}`,
        value: approver.description,
      });
    });

    if (data.hrNotes?.length > 0) {
      data.hrNotes.map((item) => {
        arr.push({
          label: msg + "Yönləndirilmə səbəbi",
          value: item.description,
        });
      });
    }

    return arr;
  },
  generateCommentsOfStructureReq(data) {
    const arr = [];

    // data?.approvementChain?.map((item) =>
    //   arr.push({
    //     label: `Təsdiqləyici -${" "}
    //     ${
    //       item?.staff.employee[0]?.firstname +
    //       " " +
    //       item?.staff.employee[0]?.lastname
    //     }`,
    //     value: item?.description,
    //   })
    // );

    data?.originalRequest?.message?.map((item) =>
      arr.push({
        label: `Struktur -${" "}
        ${item?.key}`,
        value: item.value,
      })
    );

    if (data.hrNotes?.length > 0) {
      data.hrNotes.map((item) => {
        arr.push({
          label: "Şərh" + "Yönləndirilmə səbəbi",
          value: item.description,
        });
      });
    }

    return arr;
  },
  generateBgColor(data) {
    const status = this.generateStatus(data);

    switch (data.status) {
      case "pending":
        return COLORS_STATUS.BLUE;
      case "approved":
        return COLORS_STATUS.GREEN;
      case "rejected":
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.ReRequestedToCreator:
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.CancelledByHR:
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.PendingChainApprove:
        return COLORS_STATUS.BLUE;
      case REQUESTS_STATUS_TYPE.PendingOrder:
        return COLORS_STATUS.BLUE;
      case REQUESTS_STATUS_TYPE.PendingOrderApprove: // ????????????????????
        return COLORS_STATUS.GREEN;
      case REQUESTS_STATUS_TYPE.ReRequestedToManager:
        return COLORS_STATUS.ORANGE;
      case REQUESTS_STATUS_TYPE.RejectedByChainUser:
        return COLORS_STATUS.RED;
      case REQUESTS_STATUS_TYPE.UpdatedByCreator:
        return COLORS_STATUS.RED;
      default:
        return COLORS_STATUS.GREY;
    }
  },
  generateHeader(data, fromWhichPage) {
    // const docType = findDocType(contractData?.contractType?.type);
    let title;
    switch (data.status) {
      case "pending":
        title = "Təsdiq gözləyən sorğu";
        return !fromWhichPage
          ? title
          : chooseHeaderTextFormatHandler(fromWhichPage, title);
      case "approved":
        title = "Təsdiqlənmiş sorğu";
        return !fromWhichPage
          ? title
          : chooseHeaderTextFormatHandler(fromWhichPage, title);
      case "rejected":
        title = "İmtina edilmiş sorğu";
        return !fromWhichPage
          ? title
          : chooseHeaderTextFormatHandler(fromWhichPage, title);
      case REQUESTS_STATUS_TYPE.ReRequestedToCreator:
        title = "Əməkdaşa yönləndirilmiş sorğu";
        return !fromWhichPage
          ? title
          : chooseHeaderTextFormatHandler(fromWhichPage, title);
      case REQUESTS_STATUS_TYPE.CancelledByHR:
        title = "HR tərəfindən imtina edilmiş sorğu";
        return !fromWhichPage
          ? title
          : chooseHeaderTextFormatHandler(fromWhichPage, title);
      case REQUESTS_STATUS_TYPE.PendingChainApprove:
        title = "Təsdiq gözləyən sorğu";
        return !fromWhichPage
          ? title
          : chooseHeaderTextFormatHandler(fromWhichPage, title);
      case REQUESTS_STATUS_TYPE.PendingOrder:
        title = "Əmri gözlənən sorğu";
        return !fromWhichPage
          ? title
          : chooseHeaderTextFormatHandler(fromWhichPage, title);
      case REQUESTS_STATUS_TYPE.PendingOrderApprove:
        title = "";
        return "Təsdiqlənmiş sorğu"; // ??????????
      case REQUESTS_STATUS_TYPE.ReRequestedToManager:
        title = "Yenidən yönləndirilmiş sorğu";
        return !fromWhichPage
          ? title
          : chooseHeaderTextFormatHandler(fromWhichPage, title);
      case REQUESTS_STATUS_TYPE.RejectedByChainUser:
        title = "İmtina edilmiş sorğu";
        return !fromWhichPage
          ? title
          : chooseHeaderTextFormatHandler(fromWhichPage, title);
      case REQUESTS_STATUS_TYPE.UpdatedByCreator:
        title = "Əməkdaş tərəfindən yenilənmiş sorğu";
        return !fromWhichPage
          ? title
          : chooseHeaderTextFormatHandler(fromWhichPage, title);
      default:
        return "Sorğu";
    }
  },
  generateRecordNo(data) {
    return data.contract?.data?.docNumber || "-";
  },
  generateVersionOptions(data) {},
};

const validateValue = (value) => {
  return value && value.length > 0 ? value : "";
};

const chooseHeaderTextFormatHandler = (pageName, text) => {
  // "hr-vacation" - hr-vacation-requests/detailed
  switch (pageName) {
    case "hr-vacation":
      return changeLastWord(text, "ərizə(sorğu)");
    default:
      return "sorğu";
  }
};

const changeLastWord = (text, willLastWord) => {
  const words = text.split(" ");

  if (words.length > 0) {
    words[words.length - 1] = willLastWord;
  } else {
    return willLastWord;
  }

  const modifiedText = words.join(" ");
  return modifiedText;
};
