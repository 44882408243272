import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay } from "date-fns";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCommonData } from "../../api/api";
import useStaticData from "../../hooks/useStaticData/useStaticData";

const ChildContainer = styled.div`
  width: 100%;
`;

const LabelContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const ViewDriveLicenseInfo = ({ employeeById }) => {
  const [licenseTypes, setLicenseTypes] = useState([]);
  const [issuers, setIssuers] = useState();

  const today = endOfDay(new Date());

  const [empStaticData] = useStaticData();

  useEffect(() => {
    const fetchOptions = async () => {
      const issuersList = await getCommonData("identityCardissuerList");
      setIssuers(issuersList.data);
    };
    fetchOptions();
  }, []);

  useEffect(() => {
    setLicenseTypes(empStaticData["licenceCategories"]?.data);
  }, [empStaticData]);

  const renderTextField = ({ name, label }) => (
    <LabelContainer>
      <Field
        type="text"
        name={`driverLicense.${name}`}
        value={employeeById?.driverLicense?.[name]}
        as={TextField}
        fullWidth
        label={`${label}`}
      />
    </LabelContainer>
  );

  const renderDateField = ({ name, label, maxDate, minDate, values }) => (
    <LabelContainer>
      <Field
        name={`driverLicense.${name}`}
        render={({ field, form }) => (
          <DatePicker
            {...field}
            fullWidth
            label={`${label}`}
            inputFormat="dd/MM/yyyy"
            value={
              employeeById?.driverLicense?.[name]
                ? new Date(employeeById?.driverLicense?.[name])
                : null
            }
            maxDate={maxDate}
            minDate={minDate ? minDate : today}
            onChange={(date) => {
              form.setFieldValue(`driverLicense.${name}`, date);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        )}
      />
    </LabelContainer>
  );

  //   const renderSelectField = ({
  //     name,
  //     label,
  //     items,
  //     errors,
  //     values,
  //     handleChange,
  //   }) => (
  //     <LabelContainer>
  //       <FormControl error={Boolean(errors.driverLicense?.[name])} fullWidth>
  //         <InputLabel id={`driverLicense.${name}`}>{`${label} *`}</InputLabel>
  //         <Field
  //           as={Select}
  //           name={`driverLicense.${name}`}
  //           labelId={`driverLicense.${name}`}
  //           label={`${label}`}
  //           fullWidth
  //           defaultValue=""
  //           error={Boolean(errors.driverLicense?.[name])}
  //         >
  //           <MenuItem hidden value=""></MenuItem>
  //           {items.map((item, index) => (
  //             <MenuItem key={`${item} ${index}`} value={item}>
  //               {item}
  //             </MenuItem>
  //           ))}
  //         </Field>
  //         {errors.driverLicense?.[name] && (
  //           <FormHelperText error>{errors.driverLicense?.[name]}</FormHelperText>
  //         )}
  //       </FormControl>
  //     </LabelContainer>
  //   );

  const renderMultipleSelectField = ({ name, label, options }) => (
    <LabelContainer>
      <FormControl fullWidth>
        <InputLabel id={`driverLicense.${name}`}>{`${label} *`}</InputLabel>
        <Field
          as={Select}
          name={`driverLicense.${name}`}
          labelId={`driverLicense.${name}`}
          label={`${label}`}
          fullWidth
          defaultValue={[]}
          multiple
        >
          {employeeById.driverLicense.licenseCategory &&
            employeeById.driverLicense.licenseCategory.map((option, index) => (
              <MenuItem key={`${option} ${index}`} value={option}>
                {option}
              </MenuItem>
            ))}
        </Field>
      </FormControl>
    </LabelContainer>
  );
  const initialValues = {
    driverLicense: employeeById.driverLicense || {},
  };
  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            driverLicense: initialValues.driverLicense || {},
          }}
        >
          {({ values, errors, isValid, isSubmitting }) => {
            return (
              <Form style={{ pointerEvents: "none" }}>
                {employeeById && (
                  <>
                    <Grid>
                      <ChildContainer>
                        <LabelContainer>
                          <FormControl
                            error={
                              errors?.driverLicense?.licenseIssuer &&
                              errors.driverLicense.licenseIssuer
                            }
                            fullWidth
                          >
                            <InputLabel id="driverLicense.licenseIssuer">
                              Sənədi verən orqan *
                            </InputLabel>

                            <Field
                              as={Select}
                              name="driverLicense.licenseIssuer"
                              labelId="driverLicense.licenseIssuer"
                              label="Sənədi verən orqan"
                              id="licenseIssuer"
                              defaultValue=""
                              fullWidth
                              error={
                                errors.driverLicense?.licenseIssuer &&
                                Boolean(errors.driverLicense.licenseIssuer)
                              }
                            >
                              <MenuItem hidden value=""></MenuItem>
                              {issuers &&
                                issuers?.map((item, index) => (
                                  <MenuItem
                                    key={`${item} ${index}`}
                                    value={
                                      employeeById?.driverLicense?.licenseIssuer
                                    }
                                  >
                                    {employeeById?.driverLicense?.licenseIssuer}
                                  </MenuItem>
                                ))}
                            </Field>
                            {errors.driverLicense?.licenseIssuer && (
                              <FormHelperText error>
                                {errors.driverLicense.licenseIssuer}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </LabelContainer>
                        {renderDateField({
                          name: "licenseIssuanceDate",
                          label: "Vəsiqənin verilmə tarixi *",
                          maxDate: values.driverLicense.licenseExpirationDate
                            ? values.driverLicense.licenseExpirationDate
                            : today,
                          minDate: new Date(1940, 0, 1),
                          values: values,
                          errors: errors,
                        })}
                        {renderDateField({
                          name: "licenseExpirationDate",
                          label: "Vəsiqənən etibarlıq müddəti *",
                          values: values,
                          errors: errors,
                        })}
                        {renderTextField({
                          name: "licenseNumber",
                          label: "Vəsiqənin seriya nömrəsi *",
                          values: values,
                          errors: errors,
                        })}
                        {renderMultipleSelectField({
                          name: "licenseCategory",
                          label: "Kateqoriya",
                          options: licenseTypes,
                          values: values,
                          errors: errors,
                        })}
                      </ChildContainer>
                    </Grid>
                  </>
                )}
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewDriveLicenseInfo;
