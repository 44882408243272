import React, { useState } from "react";
import { ToastContainer } from "react-toastify";

// Components
import Header from "../../components/layouts/header/Header";

// Material UI
import { Divider, Grid } from "@mui/material";

// CSS
import style from "./myProfileStyle.module.css";
import EmptyCard from "../vacation/empPage/VacationEmployeePage";
import Navbar from "./Navbar";

export default function Vacations() {
  const [navbarStatus, setNavbarStatus] = useState(false);

  // Header data
  const headerData = {
    title: "Məzuniyyətlər",
    variant: "h3",
  };

  const navbarStatusHandler = (currentValue) => {
    setNavbarStatus(currentValue);
  };

  return (
    <>
      <Grid
        className={style.navbarPlace}
        // onMouseEnter={showHandler}
        // onMouseLeave={showHandler}
      >
        {/* {navbarStatus === false ? (
          <Grid className={style.navbarProfile}>
            <BeachAccessIcon sx={{ width: "100%", height: "100%" }} />
          </Grid>
        ) : (
          <MyCabinetNavbar />
        )} */}
        <Navbar navbarStatus={navbarStatusHandler} />
      </Grid>
      <React.Fragment>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
          <ToastContainer />
          <Divider sx={{ marginTop: 5 }} />
          <Grid
            className={
              style.infoSection +
              " " +
              (navbarStatus ? style.animation2 : style.animation1)
            }
          >
            <Grid className={style.vacationSecondSection}>
              <EmptyCard />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    </>
  );
}
