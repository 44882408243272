import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import useOrders from "../../../../../hooks/useOrders";
import useTemporaryReplacement from "../../../../../hooks/useTemporaryReplacement";

const Divider = styled(MuiDivider)(spacing);

const TemporaryReplacement = () => {
  const navigate = useNavigate();
  const {
    data,
    employee,
    contractData,
    currentContractData,
    handleEmployeeChange,
    handleCurrentContract,
  } = useOrders();
  const {
    errors,
    persent,
    setPersent,
    commandData,
    isFormValid,
    replacedData,
    setContractName,
    replacedEmployee,
    updateVacantorder,
    handleInputChange,
    emptyPositionDatas,
    setStaffId,
    replacedContractData,
    replacedCurrentContractData,
    handleReplacedEmployeeChange,
    handleReplacedCurrentContract,
    createFillingVacantPositionOrder,
  } = useTemporaryReplacement(employee?.empStaffId);
  useEffect(() => {
    setContractName("temporary-replacement-order");
  }, []);
  console.log(employee);

  return (
    <form>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            Müvəqqəti əvəzetmə
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                Əməkdaş Məlumatları
              </Typography>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Əməkdaş">Əməkdaş(A.S.A)</InputLabel>
                <Select
                  name="Əməkdaş(A.S.A)"
                  labelId="Əməkdaş(A.S.A)"
                  label="Əməkdaş(A.S.A)"
                  value={employee.employeeId || ""}
                  onChange={(e) => handleEmployeeChange(e.target.value)}
                  fullWidth
                  variant="outlined"
                >
                  {data &&
                    data.map((d, i) => (
                      <MenuItem key={i} value={d?._id}>
                        {`${d?.firstname} ${d?.lastname} ${d?.fathername}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Grid sx={{ display: "flex", gap: "25px" }}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel id="Müqavilə nömrəsi">
                    Müqavilə nömrəsi
                  </InputLabel>
                  <Select
                    name="Müqavilə nömrəsi"
                    labelId="Müqavilə nömrəsi"
                    label="Müqavilə nömrəsi"
                    fullWidth
                    variant="outlined"
                    value={
                      employee?.empStaffId?.value
                        ? employee?.empStaffId?.value || ""
                        : employee?.empStaffId || ""
                    }
                    onChange={(e) => handleCurrentContract(e.target.value)}
                    disabled={!employee.employeeId}
                  >
                    {contractData &&
                      contractData?.map((d, i) => (
                        <MenuItem key={i} value={d?.contract?.staffs?.[0]?._id}>
                          {d?.contract?.data?.docNumber}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={currentContractData?.contract?.decisionDate}
                    label="Müqavilənin tarixi"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
                value={employee?.tabelNumber || ""}
              />
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Təşkilat">Təşkilat</InputLabel>
                <Select
                  name="Təşkilat"
                  labelId="Təşkilat"
                  label="Təşkilat"
                  fullWidth
                  variant="outlined"
                  value={
                    currentContractData?.structure?.organization?.name || ""
                  }
                >
                  <MenuItem
                    value={currentContractData?.structure?.organization?.name}
                  >
                    {currentContractData?.structure?.organization?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Struktur">Struktur</InputLabel>
                <Select
                  name="Substruktur"
                  labelId="Substruktur"
                  label="Substruktur"
                  fullWidth
                  variant="outlined"
                  value={currentContractData?.part?.parent?.source?.name || ""}
                >
                  <MenuItem
                    value={currentContractData?.part?.parent?.source?.name}
                  >
                    {currentContractData?.part?.parent?.source?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Substruktur">Substruktur</InputLabel>
                <Select
                  name="Substruktur"
                  labelId="Substruktur"
                  label="Substruktur"
                  fullWidth
                  variant="outlined"
                  value={currentContractData?.part?.parent?.source?.name || ""}
                >
                  <MenuItem
                    value={currentContractData?.part?.parent?.source?.name}
                  >
                    {currentContractData?.part?.parent?.source?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Vəzifə">Vəzifə</InputLabel>
                <Select
                  name="Vəzifə"
                  labelId="Vəzifə"
                  label="Vəzifə"
                  fullWidth
                  variant="outlined"
                  value={currentContractData?.part?.source.name || ""}
                >
                  <MenuItem value={currentContractData?.part?.source.name}>
                    {currentContractData &&
                      currentContractData?.part?.source.name}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                Əvəz edilənin məlumatları
              </Typography>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="replaced-employee">Əməkdaş(A.S.A)</InputLabel>
                <Select
                  name="replaced-employee"
                  labelId="Əməkdaş(A.S.A)"
                  label="Əməkdaş(A.S.A)"
                  fullWidth
                  value={replacedEmployee.employeeId || ""}
                  onChange={(e) => handleReplacedEmployeeChange(e.target.value)}
                  variant="outlined"
                >
                  {replacedData &&
                    replacedData.map((d, i) => (
                      <MenuItem key={i} value={d?._id}>
                        {`${d?.firstname} ${d?.lastname} ${d?.fathername}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Grid
                sx={{
                  display: "flex",
                  gap: "25px",
                  width: "100%",
                  paddingRight: "8px",
                }}
              >
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel>Müqavilə nömrəsi</InputLabel>
                  <Select
                    name="Müqavilə nömrəsi"
                    label="Müqavilə nömrəsi"
                    fullWidth
                    variant="outlined"
                    value={
                      replacedEmployee?.vacantStaffId?.value
                        ? replacedEmployee?.vacantStaffId?.value || ""
                        : replacedEmployee?.vacantStaffId || ""
                    }
                    onChange={(e) => {
                      handleReplacedCurrentContract(e.target.value);
                      setStaffId(e.target.value);
                    }}
                    disabled={!replacedEmployee.employeeId}
                  >
                    {replacedContractData &&
                      replacedContractData?.map((d, i) => (
                        <MenuItem key={i} value={d?.contract?.staffs?.[0]?._id}>
                          {d?.contract?.data?.docNumber}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={
                      emptyPositionDatas?.newContractDate?.value
                        ? emptyPositionDatas?.newContractDate?.value
                        : emptyPositionDatas?.newContractDate
                    }
                    label="Müqavilənin tarixi"
                    name="newContractDate"
                    onChange={(date) =>
                      handleInputChange("newContractDate", date)
                    }
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
                value={replacedEmployee?.tabelNumber || ""}
              />
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Təşkilat">Təşkilat</InputLabel>
                <Select
                  name="Təşkilat"
                  labelId="Təşkilat"
                  label="Təşkilat"
                  fullWidth
                  variant="outlined"
                  value={
                    replacedCurrentContractData?.structure?.organization
                      ?.name || ""
                  }
                >
                  <MenuItem
                    value={
                      replacedCurrentContractData?.structure?.organization?.name
                    }
                  >
                    {replacedCurrentContractData?.structure?.organization?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Struktur">Struktur</InputLabel>
                <Select
                  name="Substruktur"
                  labelId="Substruktur"
                  label="Substruktur"
                  fullWidth
                  variant="outlined"
                  value={
                    replacedCurrentContractData?.part?.parent?.source?.name ||
                    ""
                  }
                >
                  <MenuItem
                    value={
                      replacedCurrentContractData?.part?.parent?.source?.name
                    }
                  >
                    {replacedCurrentContractData?.part?.parent?.source?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Substruktur">Substruktur</InputLabel>
                <Select
                  name="Substruktur"
                  labelId="Substruktur"
                  label="Substruktur"
                  fullWidth
                  variant="outlined"
                  value={
                    replacedCurrentContractData?.part?.parent?.source?.name ||
                    ""
                  }
                >
                  <MenuItem
                    value={
                      replacedCurrentContractData?.part?.parent?.source?.name
                    }
                  >
                    {replacedCurrentContractData?.part?.parent?.source?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Vəzifə">Vəzifə</InputLabel>
                <Select
                  name="Vəzifə"
                  labelId="Vəzifə"
                  label="Vəzifə"
                  fullWidth
                  variant="outlined"
                  value={replacedCurrentContractData?.part?.source.name || ""}
                >
                  <MenuItem
                    value={replacedCurrentContractData?.part?.source.name}
                  >
                    {replacedCurrentContractData &&
                      replacedCurrentContractData?.part?.source.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <Grid
                sx={{
                  display: "flex",
                  gap: "25px",
                  width: "100%",
                  paddingRight: "8px",
                }}
              >
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Başlama tarixi"
                    name="startDate"
                    value={
                      emptyPositionDatas?.startDate?.value
                        ? emptyPositionDatas?.startDate?.value
                        : emptyPositionDatas?.startDate
                    }
                    onChange={(date) => handleInputChange("startDate", date)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    label="Bitmə tarixi"
                    name="endDate"
                    value={
                      emptyPositionDatas?.endDate?.value
                        ? emptyPositionDatas?.endDate?.value
                        : emptyPositionDatas?.endDate
                    }
                    onChange={(date) => handleInputChange("endDate", date)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  paddingRight: "8px",
                }}
              >
                <InputLabel id="Müqavilə nömrəsi">Ödəniş növü</InputLabel>
                <Select
                  name="paymentType"
                  labelId="Ödənişin növü"
                  label="Ödənişin növü"
                  fullWidth
                  variant="outlined"
                  value={
                    emptyPositionDatas?.paymentType?.value
                      ? emptyPositionDatas?.paymentType?.value
                      : emptyPositionDatas?.paymentType
                  }
                  onChange={(e) => {
                    handleInputChange("paymentType", e.target.value);
                    if (e.target.value === "percent") {
                      setPersent(false);
                    } else {
                      setPersent(true);
                    }
                  }}
                >
                  <MenuItem value="amount">Nağd</MenuItem>
                  <MenuItem value="percent">Faiz</MenuItem>
                </Select>
              </FormControl>
              <Grid
                sx={{
                  display: "flex",
                  gap: "25px",
                  width: "100%",
                  paddingRight: "8px",
                }}
              >
                <TextField
                  label="Əmək haqqına əlavə"
                  name="additionToSalary"
                  value={
                    emptyPositionDatas?.additionToSalary?.value
                      ? emptyPositionDatas?.additionToSalary?.value || ""
                      : emptyPositionDatas?.additionToSalary || ""
                  }
                  onChange={(e) =>
                    handleInputChange("additionToSalary", e.target.value)
                  }
                  sx={{ width: persent ? "70%" : "100%" }}
                  variant="outlined"
                  my={2}
                  type="number"
                />
                {persent && (
                  <FormControl
                    error={!!errors.currency}
                    sx={{ width: "30%", pointerEvents: "none" }}
                  >
                    <InputLabel id="currency">Valyuta</InputLabel>
                    <Select
                      name="currency"
                      // value={
                      //   emptyPositionDatas?.currency?.value
                      //     ? emptyPositionDatas?.currency?.value
                      //     : emptyPositionDatas?.currency
                      // }
                      value={"AZN"}
                      onChange={(e) =>
                        handleInputChange("currency", e.target.value)
                      }
                      labelId="Valyuta"
                      label="Valyuta"
                      fullWidth
                      variant="outlined"
                    >
                      <MenuItem value="AZN">AZN</MenuItem>
                    </Select>
                    {/* {errors.currency && (
                      <FormHelperText>{errors.currency}</FormHelperText>
                    )} */}
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => navigate("/documentation/orders")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              title="Kənarlaşdırma yarat"
              variant="contained"
              // disabled={commandData?._id ? false : isFormValid()}
              onClick={
                commandData?._id
                  ? updateVacantorder
                  : createFillingVacantPositionOrder
              }
              sx={{ height: "100%" }}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Təsdiq et
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default TemporaryReplacement;
