import React, { useEffect, useReducer, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import * as api from "../../api/ticket";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import OutlinedInput from "@mui/material/OutlinedInput";

import {
  Alert,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  TextField,
  Typography,
  Input,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  FormControl as MuiFormControl,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Formik } from "formik";

import * as ticketApi from "../../api/ticket";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues = {
  category: "",
  description: "",
  contacts: "",
  fileData: null,
  email: "",
};

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const validationSchema = Yup.object().shape({
  category: Yup.string().required(),
  description: Yup.string().required("Sorğunun səbəbin qeyd edin"),
  contacts: Yup.number().required("Əlaqə nömrəsin qeyd edin"),
  email: Yup.string().required("Email qeyd edin"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function NewTicket() {
  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [categories, setCategories] = useState([]);
  const [filesData, setFilesData] = useState([]);

  const navigate = useNavigate();

  const handleFileChange = async (event) => {
    const files = event.target.files;

    for (const file of files) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        setFilesData((prevFiles) => [...prevFiles, reader.result]);
      };

      reader.onerror = (err) => {
        console.log("error");
      };
    }

    // reader.readAsDataURL(file);

    // reader.onload = function () {
    //   console.log(reader.result);
    // };

    // reader.onerror = function (error) {
    //   console.log("Error: ", error);
    // };
  };
  const getCats = async () => {
    try {
      const resp = await ticketApi.getCategory();

      setCategories(resp.categories);
    } catch (error) {
      // Handle timeout or other errors
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    getCats();
  }, []);
  const handleSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        attachments: filesData,
      };

      const resp = await ticketApi.createTicket(payload);

      navigate("/ticketing");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                [DEMO] Your data has been submitted successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              // navigate("/ticketing")
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6} mb={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <InputLabel id="categoryId">Kategoriyalar</InputLabel>
                      <Select
                        name="category"
                        label="Kategoriyalar"
                        labelId="category"
                        value={values.category}
                        onChange={handleChange}
                        sx={{ width: "65rem" }}
                        // input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {categories?.map((catItem) => [
                          <MenuItem disabled>{catItem.name}</MenuItem>,
                          catItem.subCategories.map((catItem) => (
                            <MenuItem key={catItem._id} value={catItem._id}>
                              {catItem.name}
                            </MenuItem>
                          )),
                        ])}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={6} mb={3}>
                  <Grid item xs={20} md={6}>
                    <TextField
                      name="description"
                      label="Sorğunun səbəbi"
                      value={values.description}
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <CloudUploadIcon
                      //         onClick={() => {
                      //           document.getElementById("file-upload").click();
                      //         }}
                      //         style={{ cursor: "pointer" }}
                      //       />
                      //     </InputAdornment>
                      //   ),
                      // }}
                    />

                    {/* <input
                      id="file-upload"
                      type="file"
                      hidden
                      onChange={handleFileUpload}
                    /> */}
                  </Grid>

                  <Grid item md={6}>
                    <label htmlFor="file-upload">
                      <Button
                        sx={{ marginTop: "0.5rem" }}
                        variant="contained"
                        component="span"
                        color={filesData.length > 0 ? "success" : "secondary"}
                        style={{
                          backgroundColor:
                            filesData.length > 0 ? "" : undefined,
                          color: filesData.length > 0 ? "white" : undefined,
                        }}
                      >
                        {filesData.length > 0 ? "Fayl Yükləndi" : "Fayl Yüklə"}
                      </Button>
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      hidden
                      multiple
                      onChange={handleFileChange}
                      on
                    />
                    {filesData && (
                      <Typography variant="caption" sx={{ mt: 1 }}>
                        {filesData.name}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={6} mb={3}>
                  <Grid item md={6}>
                    <TextField
                      name="contacts"
                      label="Əlaqə nömrəsi"
                      value={values.contacts}
                      error={Boolean(touched.contacts && errors.contacts)}
                      fullWidth
                      helperText={touched.contacts && errors.contacts}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      sx={{ mb: 3 }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6} mb={3}>
                  <Grid item md={6}>
                    <TextField
                      type="email"
                      name="email"
                      label="Email"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      sx={{ mb: 3 }}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    Yarat
                  </Button>
                </Grid>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}
function Blank() {
  return (
    <React.Fragment>
      <Helmet title="Yeni Sorğu" />
      <Typography variant="h3" gutterBottom display="inline">
        Yeni Sorğu
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <NewTicket />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Blank;
