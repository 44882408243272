import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { Italic } from "react-feather";

// icons
import pdf from "./pdf.svg";
import excel from "./excel.svg";

// CSS
import style from "./myProfileStyle.module.css";

// Components
import Header from "../../components/layouts/header/Header";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";
import MyCabinetNavbar from "./MyCabinetNavbar";

// Material UI components
import { Divider, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import Navbar from "./Navbar";

export default function MyWages() {
  const [navbarStatus, setNavbarStatus] = useState(false);
  const [selectedDate, setSelectedDate] = useState(Date);

  const time = [
    {
      label: "Faktiki işlənmiş gün",
      value: [12, 20],
    },
    {
      label: "Faktiki işlənmiş saat",
      value: 96,
    },
    {
      label: "Tarif Vəzifə Maaşı",
      value: 780,
    },
  ];

  const maas = [
    {
      label: "Faktiki Tarif Vəzifə Maaşı",
      value: 620,
    },
    {
      label: "Məzuniyyət",
      value: 180,
    },
    {
      label: "Xəstəlik",
      value: 50,
    },
    {
      label: "Mükafat",
      value: 200,
    },
    {
      label: "Əməkhaqqına əlavə",
      value: 130,
    },
    {
      label: "Gross",
      value: 1200,
    },
  ];

  const tutulmalar = [
    {
      label: "Gəlir Vergisi",
      value: ["14%", 25],
    },
    {
      label: "İşsizlikdən siğorta",
      value: ["2%", 3],
    },
    {
      label: "Telefon pulu",
      value: [false, 50],
    },
    {
      label: "Məhkəmə icrası",
      value: ["25%", 200],
    },
    {
      label: "DSMF",
      value: [false, false],
    },
    {
      label: "İcbari tibbi siğorta",
      value: [false, false],
    },
    {
      label: "Cəmi tutulma",
      value: 278,
    },
  ];

  const [workDays, setWorkDays] = useState(time);
  const [wages, setWages] = useState(maas);
  const [deductions, setDeductions] = useState(tutulmalar);

  // const showHandler = () => setNavbarStatus(!navbarStatus);

  const handleDateChange = (year) => {
    setSelectedDate(year);
  };

  //  Change month to  next button
  const goToNextMonth = () => {
    const nextMonth = new Date(selectedDate);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setSelectedDate(nextMonth);
  };

  //  Change month to  previous button

  const goToPreviousMonth = () => {
    const nextMonth = new Date(selectedDate);
    nextMonth.setMonth(nextMonth.getMonth() - 1);
    setSelectedDate(nextMonth);
  };
  const headerData = {
    title: "Əməkhaqqlarım",
    variant: "h3",
  };

  const navbarStatusHandler = (currentValue) => {
    setNavbarStatus(currentValue);
  };

  return (
    <>
      <Grid
        className={style.navbarPlace}
        // onMouseEnter={showHandler}
        // onMouseLeave={showHandler}
      >
        {/* {navbarStatus === false ? (
          <Grid className={style.navbarProfile}>
            <LocalAtmIcon sx={{ width: "100%", height: "100%" }} />
          </Grid>
        ) : (
          <MyCabinetNavbar />
        )} */}
        <Navbar navbarStatus={navbarStatusHandler} />
      </Grid>
      <React.Fragment>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>
          <ToastContainer />
          <Divider sx={{ marginTop: 5 }} />
          <Grid
            className={
              style.infoSection +
              " " +
              (navbarStatus ? style.animation2 : style.animation1)
            }
          >
            <Grid className={style.wagesDateSection}>
              <Grid className={style.wagesDatePicker}>
                <ArrowBackIosIcon
                  onClick={goToPreviousMonth}
                  sx={{ cursor: "pointer" }}
                />
                <DatePicker
                  views={["year", "month"]}
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Tarix seçimi" />
                  )}
                />
                <ArrowForwardIosIcon
                  onClick={goToNextMonth}
                  sx={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <Grid className={style.wagesTableSection}>
              <Grid className={style.wagesTableBox}>
                <Grid className={style.wagesTableFirstRow}>
                  <Grid className={style.wagesIconsBox}></Grid>
                  <Grid className={style.wagesTableHeaderBox}>
                    Əmək haqqı vərəqəsi fevral 2018-ci il
                  </Grid>
                  <Grid className={style.wagesTableFilesBox}>
                    <img src={excel} />
                    <img src={pdf} />
                  </Grid>
                </Grid>
                <Grid className={style.wagesTableSecondRow}>
                  <Grid
                    className={style.wagesTableSecondRowCells}
                    sx={{ width: "66%" }}
                  >
                    Hesablanmış əmək haqqı
                  </Grid>
                  <Grid
                    className={style.wagesTableSecondRowCells}
                    sx={{ width: "34%" }}
                  >
                    Tutulmalar
                  </Grid>
                </Grid>
                <Grid className={style.wagesTableThirdRow}>
                  <Grid
                    sx={{
                      width: "33%",
                      boxSizing: "border-box",
                    }}
                  >
                    <table className={style.wagesTable}>
                      {workDays.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className={style.wagestd}
                              style={{ width: "80%" }}
                            >
                              {item.label}
                            </td>
                            <td
                              className={style.wagestd}
                              style={{ width: "20%" }}
                            >
                              {item.value.length === 2
                                ? item.value[0] + "/" + item.value[1]
                                : item.value}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </Grid>
                  <Grid sx={{ width: "33%", boxSizing: "border-box" }}>
                    <table className={style.wagesTable}>
                      {wages.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className={style.wagestd}
                              style={
                                item.label === "Gross"
                                  ? {
                                      fontStyle: Italic,
                                      color: "#5C96F1",
                                      width: "80%",
                                    }
                                  : { width: "80%" }
                              }
                            >
                              {item.label}
                            </td>
                            <td
                              className={style.wagestd}
                              style={
                                item.label === "Gross"
                                  ? {
                                      fontStyle: Italic,
                                      color: "#5C96F1",
                                      width: "20%",
                                    }
                                  : { width: "20%" }
                              }
                            >
                              {item.value}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </Grid>
                  <Grid sx={{ width: "34%", boxSizing: "border-box" }}>
                    <table className={style.wagesTable}>
                      {deductions.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className={style.wagestd}
                              style={
                                item.label === "Cəmi tutulma"
                                  ? {
                                      fontStyle: Italic,
                                      color: "#D2CC38",
                                      width: "60%",
                                    }
                                  : { width: "60%" }
                              }
                            >
                              {item.label}
                            </td>
                            <td
                              className={style.wagestd}
                              style={
                                item.label === "Cəmi tutulma"
                                  ? {
                                      fontStyle: Italic,
                                      color: "#D2CC38",
                                      width: "20%",
                                    }
                                  : { width: "20%" }
                              }
                            >
                              {item.value.length
                                ? item.value[0]
                                  ? item.value[0]
                                  : ""
                                : item.value}
                            </td>
                            <td
                              className={style.wagestd}
                              style={{ width: "20%" }}
                            >
                              {item.value[1] ? item.value[1] : ""}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </Grid>
                </Grid>
                <Grid className={style.wagesTableForthRow}></Grid>
                <Grid className={style.wagesTableFifthRow}>
                  <Grid
                    sx={{
                      paddingTop: "10px",
                      border: "1px solid #8c95a3",
                      width: "66%",
                    }}
                  >
                    Ələ çatacaq məbləğ (Net)
                  </Grid>
                  <Grid
                    sx={{
                      paddingTop: "10px",
                      border: "1px solid #8c95a3",
                      width: "34%",
                    }}
                  >
                    922 manat
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    </>
  );
}
