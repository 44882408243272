import styled from "styled-components";

export const GENERAL_BLOCK = styled.div`
  position: ${(props) => props?.poss};
  top: ${(props) => props?.topp};
  left: ${(props) => props?.leftt};
  bottom: ${(props) => props?.bottomm};
  right: ${(props) => props?.rightt};
  z-index: ${(props) => props?.zIndex};
  // border-bottom:  ${(props) =>
    props?.bord ? props?.bord : "2px solid #004EC2"};
  // font-family: raleway_bold;
  text-transform: ${(props) => props?.textTrans};
  color: ${(props) => props?.clr};
  margin: ${(props) => props?.marg};
  font-size: ${(props) => props?.f_size};
  font-weight: ${(props) => props?.wei};
  font-family: ${(props) => props?.f_family};
  display: ${(props) => props?.d_play};
  grid-template-columns: ${(props) => props?.d_play == "grid" && props?.gtc};
  grid-gap: ${(props) => props?.gap};
  padding: ${(props) => props?.pad};
  border-radius: ${(props) => props?.brad};
  background-color: ${(props) =>
    props?.dis ? props?.dis?.color : props?.bg_color};
  border: ${(props) => (props?.bord == "0" ? 0 : props?.bord)};
  border-bottom: ${(props) => props?.bordBot};
  border-top: ${(props) => props?.bordTop};
  border-left: ${(props) => props?.bordLeft};
  min-width: ${(props) => props?.m_wid};
  width: ${(props) => props?.wid};
  height: ${(props) => props?.hei};
  cursor: ${(props) => props?.pointer};
  overflow: ${(props) => props?.o_flow_y};
  &:hover {
    border: ${(props) => props?.dis && "1px solid #fff"};
  }
`;
