import React from "react";
import styled, { keyframes } from "styled-components";

const moveGradient = keyframes`
0% {
    transform: skewX(45deg) translateX(-150%);
  }
  50% {
    transform: skewX(45deg) translateX(300%);
  }
  75% {
    transform: skewX(45deg) translateX(300%);
  }
  100% {
    transform: skewX(45deg) translateX(300%);
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 5px 0px;
`;
const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px 15px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(250, 52, 52, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 10;
  border-radius: 5px;
  font-weight: 400;
  text-decoration: none;
  transition: 0.3s;
  overflow: hidden;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.109),
      transparent
    );
    transform: skewX(45deg) translateX(-150%);
    transition: 0.5s;
    animation: ${moveGradient} 2s ease-in-out infinite;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
`;

export default function LoadingTable() {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <Container>
              <Loading></Loading>
            </Container>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
