/* eslint-disable prettier/prettier */
import { toast } from "react-toastify";
import { api } from "../../api/axiosInterceptor";

// POST: /employee/contract -> Create Contract and Command
export const createContract = async (payload) => {
  // try {
  const response = await api.post("/employee/contract", payload);
  return await response.data;
  // } catch (error) {
  //   console.log("error: ", error);
  // }
};

export const getContracts = async (payload) => {
  try {
    const response = await api.get(`/employee/contract`, {
      params: {
        limit: payload.limit,
        offset: payload.offset,
        type: payload.type || "",
        exclude: payload.exclude || [],
        filters: payload.filters,
      },
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

export const getContractsWithFilters = async (payload) => {
  try {
    const response = await api.get("employee/contract/filter", {
      params: {
        limit: payload.limit,
        offset: payload.offset,
        filter: payload.filters,
      },
    });
    return response.data;
  } catch (error) {
    console.log("getContractsWithFilters", error);
  }
};
export const getPagesData = async (payload) => {
  try {
    const response = await api.get(`/employee/common/pagesData`, {
      // params: {
      //     limit: payload.limit,
      //     offset: payload.skip,
      //     name: payload.name || "",
      //     onlyUnused: payload.notUsed || false,
      //     parentRel: payload.parentRel || undefined,
      // },
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
export const labourContractLabels = async (name) => {
  try {
    const response = await api.get(
      `/employee/contract/type/name/labour-contract`,
      {
        // params: {
        //     limit: payload.limit,
        //     offset: payload.skip,
        //     name: payload.name || "",
        //     onlyUnused: payload.notUsed || false,
        //     parentRel: payload.parentRel || undefined,
        // },
      }
    );
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};

// export const postLabourContract = async (payload) => {
//   try {
//       const response = await api.post(`/employee/contract/static/labour-contract`, payload);
//       return await response.data;
//   } catch (error) {
//       console.log("error: ", error);
//       return  error;
//   }
// }

export async function postLabourContract(payload) {
  return (await api.post(`/employee/contract/static/labour-contract`, payload))
    .data;
}
export async function putLabourContract(payload, contract_id) {
  return (await api.put(`/employee/contract/static/labour-contract/${contract_id}`, payload))
    .data;
}
// GET: PREVIEW STATIC CONTRACT:
export const getPreviewStaticContract = async (
  employeeRequestId,
  contractName
) => {
  try {
    const response = await api.get(
      `/employee/contract/static/${contractName}`,
      {
        params: { employeeRequestId },
      }
    );
    return await response.data;
  } catch (error) {
    error?.response?.data?.errors?.map((item) => {
          return toast.error(item.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
  }
};

// POST: CREATE VACATION ORDER:
export const createVacationOrder = async (employeeRequestId, contractName) => {
  try {
    const response = await api.post(
      `/employee/contract/static/${contractName}`,
      {},
      {
        params: { employeeRequestId },
      }
    );
    return await response.data;
  } catch (error) {
    error?.response?.data?.errors?.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getContract = async (id, isGroup) => {
  return await api.get(`/employee/contract/${id}?groupBy=${isGroup}`);
};

export const getDocuments = async (type) => {
  try {
    return await api.get(
      `/employee/contract/type/allWithStatistics?type=${type}`
    );
  } catch (error) {
    console.log("getDocuments", error);
  }
};
