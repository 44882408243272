import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Field,
  FieldArray,
  ErrorMessage,
  useFormikContext,
  Formik,
  Form,
} from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  FormHelperText,
  Typography,
  Menu,
  Fade,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Step4Schema } from "../employees/Validations";
import moment from "moment";
import { updateEmployee } from "../../api/api";
import { Add as AddIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { removeEmptyStrings } from "../../utils/removeEmptyString";
import CustomAccordion from "../ui/Accordion/Accordion";

const educationType = [
  { value: "primary", name: "Ümumi təhsil - İbtidati təhsil" },
  { value: "bachelor", name: "Ali təhsil - bakalavriat" },
  { value: "master", name: "Ali təhsil - magistratura" },
  { value: "doctoral", name: "Ali təhsil - doktorantura" },
  { value: "generalSecondary", name: "Ümumi təhsil - ümumi orta təhsil" },
  { value: "completeSecondary", name: "Ümumi təhsil - tam orta təhsil" },
  { value: "firstVocational", name: "İlk peşə-ixtisas təhsili" },
  { value: "secondarySpecial", name: "Orta ixtisas təhsili" },
  {
    value: "secBasisOfGeneralSec",
    name: "Ümumi orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "secBasisOfCompleteSec",
    name: "Tam orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "incompleteHigherTill1993",
    name: "Ali təhsil - 1993 cü ilə qədər natamam ali təhsil",
  },
  {
    value: "completeHigherTill1993",
    name: "Ali təhsil - 1997 ci ilə qədər tam ali təhsil",
  },
];

const countries = [
  "Albaniya",
  "Almaniya",
  "Anqola",
  "Antiqua və Barbuda",
  "Argentina",
  "Avstraliya",
  "Avstriya",
  "Azərbaycan",
  "Bahamalar",
  "Bahrayn",
  "Banqladeş",
  "Barbados",
  "Belarus",
  "Belçika",
  "Boliviya",
  "Bosniya və Herseqovina",
  "Botswana",
  "Braziliya",
  "Böyük Britaniya",
  "Büyükdəniz adası",
  "Bəhreyn",
  "Bəruney",
  "Cənubi Afrika",
  "Cənubi Sudan",
  "Cənubi Koreya",
  "Çad",
  "Çexiya",
  "Çili",
  "Çin",
  "Çernoqoriya",
  "Çexoslavakiya",
  "Danemarka",
  "Dominika",
  "Dominikan Respublikası",
  "Ekvador",
  "Ekvatorial Qvineya",
  "Eritreya",
  "Estoniya",
  "Əfqanıstan",
  "Fici",
  "Filippin",
  "Finlandiya",
  "Fransa",
  "Fransız Qvineyası",
  "Fələstin",
  "Gana",
  "Qabon",
  "Qambiya",
  "Qazaxıstan",
  "Qatar",
  "Qrenada",
  "Qvineya",
  "Qvineya-Bisau",
  "Gürcüstan",
  "Haiti",
  "Hollandiya",
  "Honduras",
  "Honkonq",
  "Hindistan",
  "Horvatiya",
  "Indoneziya",
  "İngiltərə",
  "İordaniya",
  "İraq",
  "İran",
  "İrlandiya",
  "İslandiya",
  "İspaniya",
  "İsrail",
  "İsveç",
  "İsveçrə",
  "İtaliya",
  "Kamboca",
  "Kamerun",
  "Kanada",
  "Keniya",
  "Kipr",
  "Kiribati",
  "Kolumbiya",
  "Komor Adaları",
  "Kongo",
  "Kosta Rika",
  "Kotd’İvor",
  "Kuba",
  "Kuveyt",
  "Kırqızıstan",
  "Latviya",
  "Lesoto",
  "Livan",
  "Liberiya",
  "Litva",
  "Lixtenşteyn",
  "Liviya",
  "Lüksemburq",
  "Makedoniya",
  "Malavi",
  "Malayziya",
  "Maldiv adaları",
  "Malta",
  "Maroq",
  "Marşal adaları",
  "Mauritaniya",
  "Mauritius",
  "Meksika",
  "Moldova",
  "Monako",
  "Monqolustan",
  "Monteneqro",
  "Morokko",
  "Mozambik",
  "Myanma",
  "Mərakeş",
  "Nambiya",
  "Nauru",
  "Nepal",
  "Niderland",
  "Niger",
  "Nigeriya",
  "Nikaraqua",
  "Norveç",
  "Pakistan",
  "Palau",
  "Panama",
  "Paraqvay",
  "Portuqaliya",
  "Polşa",
  "Rumıniya",
  "Rusiya",
  "Ruanda",
  "San-Marino",
  "Svazilend",
  "Salvador",
  "Samoa",
  "San-Tome və Prinsip",
  "Seneqal",
  "Seyşel adaları",
  "Sinhapur",
  "Slovakiya",
  "Sloveniya",
  "Solomon adaları",
  "Somali",
  "Sudan",
  "Surinam",
  "Suriya",
  "Svədistan",
  "Səudiyyə Ərəbistanı",
  "Sərbiya",
  "Tacikistan",
  "Tanzaniya",
  "Tailand",
  "Tayvan",
  "Toga",
  "Tonqa",
  "Trinidad və Tobaqo",
  "Tunis",
  "Turkmənistan",
  "Tuvalu",
  "Türkiyə",
  "Ukrayna",
  "Urugvay",
  "Vanuatu",
  "Vatikan",
  "Venesuela",
  "Vyetnam",
  "Yamayka",
  "Yaponiya",
  "Yeni Zelandiya",
  "Yunanıstan",
  "Yəmən",
  "Zambiya",
  "Zimbabve",
];

const EditRelationShipInfo = ({ employeeById, submitted, setSubmitted }) => {
  const [isDate, setIsDate] = useState(false);
  // Handle Submit
  const handleSubmit = async (
    values,
    { setSubmitting, validateForm, resetForm }
  ) => {
    setSubmitting(true);
    const errors = await validateForm();
    if (Object.keys(errors).length === 0) {
      const modifiedValues = {
        ...values,
        relationshipInfo:
          values?.relationshipInfo &&
          values?.relationshipInfo.map((item) => ({
            ...item,
            fullname: item.fullname.trim(),
            pin: item?.pin ? item?.pin.trim() : "",
            tRPNumber:
              item.isForeigner && item.isMyiExist ? item.tRPNumber : "",
            placeOfWork: item.placeOfWork ? item.placeOfWork.trim() : null,
            dob: item.dob ? moment(item.dob).format("MM/DD/yyyy") : "",
            education: item.education ? item.education.trim() : null,
            idCardNumber: item.isForeigner
              ? item.idCardNumber && item.idCardNumber.number
                ? item.idCardNumber.number
                : ""
              : item.idCardNumber &&
                item.idCardNumber.code &&
                item.idCardNumber.number
              ? item.idCardNumber.code + item.idCardNumber.number
              : "",
          })),
      };
      // Handle form submission
      let result = removeEmptyStrings(modifiedValues);
      await updateEmployee(employeeById._id, result);
      setIsDate(false);
      setSubmitting(false);
      setSubmitted(!submitted);
      resetForm({ values });
    } else {
      setSubmitting(false);
    }
  };

  //   Initial values
  const initialValues = {
    relationshipInfo: employeeById.relationshipInfo || [],
  };

  // Update the initialValues object with parsed dates
  const modifiedValues = {
    ...initialValues,
    relationshipInfo:
      initialValues?.relationshipInfo &&
      initialValues?.relationshipInfo.map((item) => ({
        ...item,
        idCardNumber:
          item.isForeigner === true
            ? { number: item.idCardNumber }
            : {
                code: item?.idCardNumber?.startsWith("AA")
                  ? item.idCardNumber.slice(0, 2)
                  : item?.idCardNumber?.startsWith("AZE")
                  ? item.idCardNumber.slice(0, 3)
                  : "",
                number: item?.idCardNumber?.startsWith("AA")
                  ? item.idCardNumber.slice(2)
                  : item?.idCardNumber?.startsWith("AZE")
                  ? item.idCardNumber.slice(3)
                  : "",
              },
      })),
  };

  const [tRPNumberCheckbox, setTrpCheckbox] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsDate(false);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const isUnderSixYearsOld = (dob) => {
    if (!dob) return true; // Disable if dob is not set

    const dobDate = new Date(dob); // Convert dob to a Date object
    const today = new Date();
    const ageDifference = today.getFullYear() - dobDate.getFullYear();

    return (
      ageDifference < 5 ||
      (ageDifference === 5 &&
        today < new Date(dobDate.setFullYear(dobDate.getFullYear() + 5)))
    );
  };
  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Qohumluq əlaqəsi</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              relationshipInfo: modifiedValues.relationshipInfo || [], // Set the initial laborActivity values from employeeById
            }}
            // validationSchema={Step3Schema}
            validationSchema={Step4Schema(employeeById)}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="relationshipInfo">
                    {({ push, remove }) => (
                      <div>
                        {values.relationshipInfo &&
                          values.relationshipInfo.map((form, index) => (
                            <div key={`${employeeById?._id}${index}`}>
                              <>
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1})Qohumluq məlumatı{" "}
                                </Typography>

                                {/* Relation type and full name */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.relationshipInfo?.[index]
                                          ?.relationType &&
                                        errors?.relationshipInfo?.[index]
                                          ?.relationType &&
                                        Boolean(
                                          errors?.relationshipInfo?.[index]
                                            ?.relationType
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`relationshipInfo[${index}].relationType`}
                                      >
                                        Qohumluq əlaqəsi növü *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`relationshipInfo[${index}].relationType`}
                                        labelId={`relationshipInfo[${index}].relationType`}
                                        label="Qohumluq əlaqəsi növü *"
                                        id={`relationshipInfo[${index}].relationType-select`}
                                        defaultValue=""
                                        onChange={(event) => {
                                          const selectedValue =
                                            event.target.value;

                                          setFieldValue(
                                            `relationshipInfo[${index}].relationType`,
                                            selectedValue
                                          );
                                        }}
                                        fullWidth
                                        error={
                                          touched?.relationshipInfo?.[index]
                                            ?.relationType &&
                                          errors?.relationshipInfo?.[index]
                                            ?.relationType &&
                                          Boolean(
                                            errors?.relationshipInfo?.[index]
                                              ?.relationType
                                          )
                                        }
                                      >
                                        <MenuItem value="father">Atam</MenuItem>
                                        <MenuItem value="mother">
                                          Anam{" "}
                                        </MenuItem>
                                        <MenuItem value="brother">
                                          Qardaşım{" "}
                                        </MenuItem>
                                        <MenuItem value="sister">
                                          Bacım{" "}
                                        </MenuItem>
                                        {/* <MenuItem value="grandmother">
                                      Nənəm{" "}
                                    </MenuItem>
                                    <MenuItem value="grandfather">
                                      Babam{" "}
                                    </MenuItem> */}
                                        <MenuItem value="husband">
                                          Yoldaşım{" "}
                                        </MenuItem>
                                        <MenuItem value="father-in-law">
                                          Qaynatam{" "}
                                        </MenuItem>
                                        <MenuItem value="mother-in-law">
                                          Qaynanam{" "}
                                        </MenuItem>
                                        <MenuItem value="son">Oğlum </MenuItem>
                                        <MenuItem value="daughter">
                                          Qızım{" "}
                                        </MenuItem>
                                        <MenuItem value="grandchild">
                                          Nəvəm{" "}
                                        </MenuItem>
                                      </Field>
                                      {touched?.relationshipInfo?.[index]
                                        ?.relationType &&
                                        errors.relationshipInfo?.[index]
                                          ?.relationType &&
                                        errors.relationshipInfo?.[index]
                                          ?.relationType && (
                                          <FormHelperText error>
                                            {
                                              errors.relationshipInfo?.[index]
                                                ?.relationType
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`relationshipInfo[${index}].fullname`}
                                      as={TextField}
                                      label="Soyadı Adı Atasının adı *"
                                      fullWidth
                                      error={
                                        touched?.relationshipInfo?.[index]
                                          ?.fullname &&
                                        errors?.relationshipInfo?.[index]
                                          ?.fullname &&
                                        Boolean(
                                          errors?.relationshipInfo?.[index]
                                            ?.fullname
                                        )
                                      }
                                      helperText={
                                        touched?.relationshipInfo?.[index]
                                          ?.fullname &&
                                        errors?.relationshipInfo?.[index]
                                          ?.fullname &&
                                        errors?.relationshipInfo?.[index]
                                          ?.fullname
                                      }
                                    />
                                  </Grid>
                                </Grid>

                                {/* fin and national id card number */}
                                <Grid
                                  sx={{
                                    display: "flex",
                                    gap: 3,
                                    mb: 3,
                                  }}
                                >
                                  {(values.relationshipInfo[index]
                                    ?.isMyiExist &&
                                    values.relationshipInfo[index]
                                      .isForeigner) ||
                                  !values.relationshipInfo[index]
                                    .isForeigner ? (
                                    <Grid sx={{ width: "100%" }}>
                                      <Field
                                        type="text"
                                        name={`relationshipInfo[${index}].pin`}
                                        as={TextField}
                                        fullWidth
                                        label="FIN"
                                        onChange={(event) => {
                                          const uppercaseValue =
                                            event.target.value.toUpperCase();
                                          setFieldValue(
                                            `relationshipInfo[${index}].pin`,
                                            uppercaseValue
                                          );
                                        }}
                                        error={
                                          touched?.relationshipInfo?.[index]
                                            ?.pin &&
                                          errors?.relationshipInfo?.[index]
                                            ?.pin &&
                                          Boolean(
                                            errors?.relationshipInfo?.[index]
                                              ?.pin
                                          )
                                        }
                                        helperText={
                                          touched?.relationshipInfo?.[index]
                                            ?.pin &&
                                          errors?.relationshipInfo?.[index]
                                            ?.pin &&
                                          errors?.relationshipInfo?.[index]?.pin
                                        }
                                      />
                                    </Grid>
                                  ) : null}

                                  <Grid
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      gap: 3,
                                    }}
                                  >
                                    {values.relationshipInfo[index]
                                      .isForeigner ? null : (
                                      <Grid sx={{ width: 1 / 5 }}>
                                        <Field
                                          as={Select}
                                          name={`relationshipInfo[${index}].idCardNumber.code`}
                                          labelId={`relationshipInfo[${index}].idCardNumber.code`}
                                          label=""
                                          id={`relationshipInfo[${index}].idCardNumber.code`}
                                          defaultValue=""
                                          fullWidth
                                          error={
                                            touched?.relationshipInfo?.[index]
                                              ?.idCardNumber?.code &&
                                            errors?.relationshipInfo?.[index]
                                              ?.idCardNumber?.code &&
                                            Boolean(
                                              errors?.relationshipInfo?.[index]
                                                ?.idCardNumber?.code
                                            )
                                          }
                                          helperText={
                                            touched?.relationshipInfo?.[index]
                                              ?.idCardNumber?.code &&
                                            errors?.relationshipInfo?.[index]
                                              ?.idCardNumber?.code &&
                                            errors?.relationshipInfo?.[index]
                                              ?.idCardNumber?.code
                                          }
                                        >
                                          <MenuItem value="AA">AA</MenuItem>
                                          <MenuItem value="AZE">AZE</MenuItem>
                                        </Field>
                                      </Grid>
                                    )}

                                    <Grid
                                      sx={{
                                        width:
                                          values.relationshipInfo[index]
                                            .isForeigner &&
                                          !values.relationshipInfo[index]
                                            ?.isMyiExist
                                            ? "50%"
                                            : values.relationshipInfo[index]
                                                .isForeigner
                                            ? "100%"
                                            : 4 / 5,
                                      }}
                                    >
                                      <Field
                                        type="text"
                                        name={`relationshipInfo[${index}].idCardNumber.number`}
                                        as={TextField}
                                        fullWidth
                                        label={
                                          values.relationshipInfo[index]
                                            .isForeigner
                                            ? "Passportun nömrəsi"
                                            : "Vəsiqəsinin nömrəsi"
                                        }
                                        error={
                                          touched?.relationshipInfo?.[index]
                                            ?.idCardNumber?.code &&
                                          errors?.relationshipInfo?.[index]
                                            ?.idCardNumber?.number &&
                                          Boolean(
                                            errors?.relationshipInfo?.[index]
                                              ?.idCardNumber?.number
                                          )
                                        }
                                        helperText={
                                          touched?.relationshipInfo?.[index]
                                            ?.idCardNumber?.code &&
                                          errors?.relationshipInfo?.[index]
                                            ?.idCardNumber?.number &&
                                          errors?.relationshipInfo?.[index]
                                            ?.idCardNumber?.number
                                        }
                                      />
                                    </Grid>

                                    {values.relationshipInfo[index]
                                      ?.isMyiExist &&
                                      values.relationshipInfo[index]
                                        .isForeigner && (
                                        <Field
                                          type="text"
                                          name={`relationshipInfo[${index}].tRPNumber`}
                                          as={TextField}
                                          fullWidth
                                          label="MYİ seriya Nömrəsi"
                                          error={
                                            touched?.relationshipInfo?.[index]
                                              ?.tRPNumber &&
                                            errors?.relationshipInfo?.[index]
                                              ?.tRPNumber &&
                                            Boolean(
                                              errors?.relationshipInfo?.[index]
                                                ?.tRPNumber
                                            )
                                          }
                                          helperText={
                                            touched?.relationshipInfo?.[index]
                                              ?.tRPNumber &&
                                            errors?.relationshipInfo?.[index]
                                              ?.tRPNumber &&
                                            errors?.relationshipInfo?.[index]
                                              ?.tRPNumber
                                          }
                                        />
                                      )}
                                    {values.relationshipInfo[index]
                                      .isForeigner && (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.relationshipInfo[index]
                                                ?.isMyiExist || false
                                            }
                                            onChange={handleChange}
                                            name={`relationshipInfo[${index}].isMyiExist`}
                                            color="primary"
                                          />
                                        }
                                        label="Müvəqqəti yaşayış icazəm var"
                                      />
                                    )}
                                  </Grid>
                                </Grid>

                                {/* DOB and education info */}
                                <Grid
                                  onClick={() => setIsDate(true)}
                                  sx={{ display: "flex", gap: 3, mb: 3 }}
                                >
                                  <Field
                                    name={`relationshipInfo[${index}].dob`}
                                  >
                                    {({ field, form }) => (
                                      <>
                                        <DatePicker
                                          {...field}
                                          label="Doğum tarixi"
                                          inputFormat="dd/MM/yyyy"
                                          value={field.value || null}
                                          onChange={(date) => {
                                            if (
                                              date instanceof Date &&
                                              !isNaN(date)
                                            ) {
                                              form.setFieldValue(
                                                `relationshipInfo[${index}].dob`,
                                                date
                                              );
                                            } else {
                                              form.setFieldValue(
                                                `relationshipInfo[${index}].dob`,
                                                ""
                                              );
                                            }
                                          }}
                                          maxDate={new Date()}
                                          minDate={new Date(1940, 0, 1)}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={
                                                isDate &&
                                                form.errors?.relationshipInfo?.[
                                                  index
                                                ]?.dob
                                              }
                                              error={
                                                isDate &&
                                                Boolean(
                                                  form.errors
                                                    ?.relationshipInfo?.[index]
                                                    ?.dob
                                                )
                                              }
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </>
                                    )}
                                  </Field>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        errors?.relationshipInfo?.[index]
                                          ?.education &&
                                        Boolean(
                                          errors?.relationshipInfo?.[index]
                                            ?.education
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`relationshipInfo[${index}].education`}
                                      >
                                        Təhsili
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`relationshipInfo[${index}].education`}
                                        labelId={`relationshipInfo[${index}].education`}
                                        label="Təhsili"
                                        id={`relationshipInfo[${index}].education-select`}
                                        defaultValue=""
                                        disabled={isUnderSixYearsOld(
                                          values.relationshipInfo[index].dob
                                        )}
                                        fullWidth
                                        error={
                                          errors?.relationshipInfo?.[index]
                                            ?.education &&
                                          Boolean(
                                            errors?.relationshipInfo?.[index]
                                              ?.education
                                          )
                                        }
                                      >
                                        {educationType &&
                                          educationType
                                            .sort((a, b) =>
                                              a?.name.localeCompare(
                                                b?.name,
                                                "az"
                                              )
                                            )
                                            .map((item, index) => (
                                              <MenuItem value={item.value}>
                                                {item.name}
                                              </MenuItem>
                                            ))}
                                      </Field>
                                      {errors.relationshipInfo?.[index]
                                        ?.education &&
                                        errors.relationshipInfo?.[index]
                                          ?.education && (
                                          <FormHelperText error>
                                            {
                                              errors.relationshipInfo?.[index]
                                                ?.education
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                </Grid>

                                {/* Service period and same workplace */}
                                <Grid
                                  sx={{
                                    display: "flex",
                                    gap: 3,
                                    alignItems: "center",
                                  }}
                                >
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`relationshipInfo[${index}].placeOfWork`}
                                      as={TextField}
                                      fullWidth
                                      label="İş yeri (vəzifəsi)"
                                      error={
                                        errors?.relationshipInfo?.[index]
                                          ?.placeOfWork &&
                                        Boolean(
                                          errors?.relationshipInfo?.[index]
                                            ?.placeOfWork
                                        )
                                      }
                                      helperText={
                                        errors?.relationshipInfo?.[index]
                                          ?.placeOfWork &&
                                        errors?.relationshipInfo?.[index]
                                          ?.placeOfWork
                                      }
                                    />
                                  </Grid>
                                  {values.relationshipInfo[index]
                                    .isForeigner ? (
                                    <FormControl
                                      error={
                                        errors.citizenship && errors.citizenship
                                      }
                                      fullWidth
                                    >
                                      <InputLabel id="citizenship">
                                        Vətəndaşlığı
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`relationshipInfo[${index}].citizenship`}
                                        labelId="citizenship"
                                        label="Vətəndaşlığı"
                                        id="citizenship-select"
                                        fullWidth
                                        error={
                                          errors?.relationshipInfo?.[index]
                                            ?.citizenship &&
                                          Boolean(
                                            errors?.relationshipInfo?.[index]
                                              ?.citizenship
                                          )
                                        }
                                      >
                                        {countries &&
                                          countries
                                            .sort((a, b) =>
                                              a.localeCompare(b, "az")
                                            )
                                            .map((item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item}
                                              >
                                                {item}
                                              </MenuItem>
                                            ))}
                                      </Field>
                                      {errors?.relationshipInfo?.[index]
                                        ?.citizenship &&
                                        errors?.relationshipInfo?.[index]
                                          ?.citizenship && (
                                          <FormHelperText error>
                                            {
                                              errors?.relationshipInfo?.[index]
                                                ?.citizenship
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  ) : null}
                                </Grid>
                                <Grid sx={{ marginTop: "20px", width: "50%" }}>
                                  <FieldArray
                                    name={`relationshipInfo[${index}].mobile`}
                                  >
                                    {({ push, remove }) => (
                                      <>
                                        {values?.relationshipInfo?.[index]
                                          ?.mobile &&
                                          values?.relationshipInfo?.[
                                            index
                                          ]?.mobile.map((mobile, ind) => (
                                            <Grid
                                              mb={3}
                                              key={ind}
                                              container
                                              spacing={2}
                                              alignItems="center"
                                            >
                                              <Grid item xs={9}>
                                                <Field
                                                  type="tel"
                                                  name={`relationshipInfo[${index}].mobile[${ind}]`}
                                                  as={TextField}
                                                  fullWidth
                                                  label={`Mobil ${ind + 1}`}
                                                />
                                                <ErrorMessage
                                                  name={`relationshipInfo[${index}].mobil[${ind}]`}
                                                  component="div"
                                                />
                                              </Grid>
                                              <Grid item xs={3}>
                                                {ind ===
                                                  values.relationshipInfo[index]
                                                    .mobile.length -
                                                    1 && (
                                                  <Button
                                                    type="button"
                                                    onClick={() => push("")}
                                                  >
                                                    + Mobil əlavə et
                                                  </Button>
                                                )}
                                                {ind > 0 && (
                                                  <Button
                                                    type="button"
                                                    onClick={() => remove(ind)}
                                                  >
                                                    - Mobil sil
                                                  </Button>
                                                )}
                                              </Grid>
                                            </Grid>
                                          ))}
                                      </>
                                    )}
                                  </FieldArray>
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => {
                                  remove(index);
                                  setIsDate(false);
                                }}
                              >
                                Qohumluq məlumatı sil
                              </Button>
                            </div>
                          ))}
                        <div>
                          <Button
                            sx={{ mt: 3 }}
                            aria-owns={anchorEl ? "simple-menu" : undefined}
                            aria-haspopup="true"
                            onClick={handleFilterClick}
                            variant="contained"
                            color="primary"
                          >
                            <AddIcon />
                            Qohumluq məlumatı əlavə et
                          </Button>

                          <Menu
                            id="nationality-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleFilterClose}
                            TransitionComponent={Fade}
                          >
                            <MenuItem
                              onClick={(e) => {
                                push({
                                  isForeigner: false,
                                  isMyiExist: false,
                                  relationType: "",
                                  mobile: [""],
                                });
                                handleFilterClose();
                              }}
                            >
                              Azərbaycan Vətəndaşı
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                push({
                                  isForeigner: true,
                                  isMyiExist: false,
                                  relationType: "",
                                  mobile: [""],
                                });
                                handleFilterClose();
                              }}
                            >
                              Xarici Vətəndaşı
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                  {/* Submit button */}
                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditRelationShipInfo;
