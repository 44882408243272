import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const AwardRecognition = () => {
  const navigate = useNavigate();
  return (
    <form>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            Mükafatın/təltif verilməsi
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Əməkdaş">Əməkdaş(A.S.A)</InputLabel>
              <Select
                name="Əməkdaş(A.S.A)"
                labelId="fieldOfActivity"
                label="Fəaliyyət Sahəsi"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Əməkdaş(A.S.A) 1</MenuItem>
                <MenuItem value="employee2">Əməkdaş(A.S.A) 2</MenuItem>
                <MenuItem value="employee3">Əməkdaş(A.S.A) 3</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Əməkdaş">Əmrin növü</InputLabel>
              <Select
                name="Əmrin növü"
                labelId="Əmrin növü"
                label="Əmrin növü"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Əmrin növü 1</MenuItem>
                <MenuItem value="employee2">Əmrin növü 2</MenuItem>
                <MenuItem value="employee3">Əməkdaş(A.S.A) 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ display: "flex", gap: "25px", width: "50%" }}>
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="Əməkdaş">Müqavilə nömrəsi</InputLabel>
                <Select
                  name="Müqavilə nömrəsi"
                  labelId="Müqavilə nömrəsi"
                  label="Müqavilə nömrəsi"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="employee1">Müqavilə nömrəsi 1</MenuItem>
                  <MenuItem value="employee2">Müqavilə nömrəsi 2</MenuItem>
                  <MenuItem value="employee3">Müqavilə nömrəsi 3</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "50%" }}>
                <DatePicker
                  my={2}
                  inputFormat="dd/MM/yyyy"
                  label="Müqavilənin tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </FormControl>
            </Grid>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Əməkdaş">Mükafatın növü</InputLabel>
              <Select
                name="Mükafatın növü"
                labelId="Mükafatın növü"
                label="Mükafatın növü"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Mükafatın növü 1</MenuItem>
                <MenuItem value="employee2">Mükafatın növü 2</MenuItem>
                <MenuItem value="employee3">Mükafatın növü 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <TextField
              sx={{ width: "50%" }}
              name="Tabel nömrəsi"
              label="Tabel nömrəsi"
              variant="outlined"
              my={2}
            />
            <Grid sx={{ display: "flex", gap: "25px", width: "50%" }}>
              <FormControl sx={{ width: "50%" }}>
                <DatePicker
                  my={2}
                  inputFormat="dd/MM/yyyy"
                  label="Başlama tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </FormControl>
              <FormControl sx={{ width: "50%" }}>
                <DatePicker
                  my={2}
                  inputFormat="dd/MM/yyyy"
                  label="Bitmə tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Əməkdaş">Təşkilat</InputLabel>
              <Select
                name="Təşkilat"
                labelId="Təşkilat"
                label="Təşkilat"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Təşkilat 1</MenuItem>
                <MenuItem value="employee2">Təşkilat 2</MenuItem>
                <MenuItem value="employee3">Təşkilat 3</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "50%" }}
              name="Səbəb/Qeyd"
              label="Səbəb/Qeyd"
              variant="outlined"
              my={2}
            />
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Əməkdaş">Struktur</InputLabel>
              <Select
                name="Struktur"
                labelId="Struktur"
                label="Struktur"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Struktur 1</MenuItem>
                <MenuItem value="employee2">Struktur 2</MenuItem>
                <MenuItem value="employee3">Struktur 3</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Əməkdaş">Ödəniş növü</InputLabel>
              <Select
                name="Ödəniş növü"
                labelId="Ödəniş növü"
                label="Ödəniş növü"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Ödəniş növü 1</MenuItem>
                <MenuItem value="employee2">Ödəniş növü 2</MenuItem>
                <MenuItem value="employee3">Ödəniş növü 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Əməkdaş">Substruktur</InputLabel>
              <Select
                name="Substruktur"
                labelId="Substruktur"
                label="Substruktur"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Substruktur 1</MenuItem>
                <MenuItem value="employee2">Substruktur 2</MenuItem>
                <MenuItem value="employee3">Substruktur 3</MenuItem>
              </Select>
            </FormControl>
            <Grid sx={{ display: "flex", gap: "25px", width: "50%" }}>
              <TextField
                sx={{ width: "50%" }}
                name="Məbləğ"
                label="Məbləğ"
                variant="outlined"
                my={2}
              />
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="Əməkdaş">Valyuta</InputLabel>
                <Select
                  name="Valyuta"
                  labelId="Valyuta"
                  label="Valyuta"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="employee1">Valyuta 1</MenuItem>
                  <MenuItem value="employee2">Valyuta 2</MenuItem>
                  <MenuItem value="employee3">Valyuta 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            sx={{ display: "flex", gap: "25px", paddingRight: "18px" }}
            mb={4}
          >
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Əməkdaş">Vəzifə</InputLabel>
              <Select
                name="Vəzifə"
                labelId="Vəzifə"
                label="Vəzifə"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Vəzifə 1</MenuItem>
                <MenuItem value="employee2">Vəzifə 2</MenuItem>
                <MenuItem value="employee3">Vəzifə 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "25px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => navigate("/documentation/orders")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              type="submit"
              title="Kənarlaşdırma yarat"
              variant="contained"
              sx={{ height: "100%" }}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Yadda saxla
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default AwardRecognition;
