import React from "react";
import Chip from "@mui/material/Chip";

const Chips = ({ data, onDelete, styles = undefined }) => {
  return (
    <div>
      {data.map(({ id, key }) => (
        <Chip
          key={id}
          label={key}
          onDelete={() => onDelete(id)}
          style={styles}
        />
      ))}
    </div>
  );
};

export default Chips;
