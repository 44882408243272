import { Button, Grid } from "@mui/material";
import React from "react";

export default function DialogFooter({
  hide,
  onSubmit,
  onReject,
  titleSubmit = "İmzala",
  titleReject = "İmtina et",
}) {
  return (
    <>
      {!hide ? (
        <Grid sx={styles.container}>
          <Grid
            sx={{
              width: "45%",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              alignItems: "center",
              marginTop: "10px",
              marginRight: "27px",
            }}
          >
            <Button
              color="success"
              variant="contained"
              sx={styles.button}
              onClick={onSubmit}
            >
              {titleSubmit}
            </Button>
            <Button
              color="error"
              variant="contained"
              sx={styles.button}
              onClick={onReject}
            >
              {titleReject}
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

const styles = {
  container: {
    width: "100%",
    justifyContent: "end",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  button: {
    width: 100,
    height: 40,
  },
  paperStyle: {
    borderRadius: "27px",
    width: "1000px",
    height: "600px",
  },
};
