import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { getCommonData } from "../../api/api";

const ViewSocialStatus = ({ employeeById }) => {
  const [socialTypes, setSocialTypes] = useState([]);

  useEffect(() => {
    const handleSocialType = async () => {
      const response = await getCommonData("socialStatusTypes");
      setSocialTypes(response.data);
    };
    handleSocialType();
  }, []);

  const initialValues = {
    socialStatus: employeeById.socialStatus || [],
  };

  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            socialStatus: initialValues.socialStatus || [], // Set the initial laborActivity values from employeeById
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            handleChange,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="socialStatus">
                {({ push, remove }) => (
                  <div>
                    {values.socialStatus.map((form, index) => (
                      <div key={index}>
                        <>
                          <Typography mt={5} variant="h6">
                            {`${index + 1})Sosial Status`}
                          </Typography>

                          {/* status type and date */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl fullWidth>
                                <InputLabel
                                  id={`socialStatus[${index}].statusType`}
                                >
                                  Sosial Status növü
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`socialStatus[${index}].statusType`}
                                  labelId={`rsocialStatus[${index}].statusType`}
                                  label="Sosial Status növü"
                                  id={`socialStatus[${index}].statusType`}
                                  defaultValue=""
                                  fullWidth
                                >
                                  <MenuItem
                                    selected
                                    value={
                                      employeeById?.socialStatus[index]
                                        ?.statusType
                                    }
                                    hidden
                                  >
                                    {
                                      employeeById?.socialStatus[index]
                                        ?.statusType
                                    }
                                  </MenuItem>
                                </Field>
                              </FormControl>
                              <ErrorMessage
                                name={`socialStatus[${index}].statusType`}
                                component="div"
                              />
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <Field name={`socialStatus[${index}].date`}>
                                {({ field, form }) => (
                                  <DatePicker
                                    {...field}
                                    label="Sosial Statusun qüvvəyə minmə tarixi"
                                    inputFormat="dd/MM/yyyy"
                                    value={
                                      employeeById?.socialStatus[index]?.date ||
                                      null
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        helperText={
                                          form.errors?.socialStatus?.[index]
                                            ?.date
                                        }
                                        error={Boolean(
                                          form.errors?.socialStatus?.[index]
                                            ?.date
                                        )}
                                        fullWidth
                                      />
                                    )}
                                  />
                                )}
                              </Field>
                            </Grid>
                          </Grid>
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                value={
                                  employeeById?.socialStatus?.[index]?.value
                                }
                                as={TextField}
                                name={`socialStatus[${index}].value`}
                                label="Sosial Statusun dəyəri"
                                fullWidth
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "19px",
                                      }}
                                    >
                                      &#8380;
                                    </span>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewSocialStatus;
