import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay } from "date-fns";
import { Field, FieldArray, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { getCommonData } from "../../api/api";

const educationType = [
  { value: "primary", name: "Ümumi təhsil - İbtidati təhsil" },
  { value: "bachelor", name: "Ali təhsil - bakalavriat" },
  { value: "master", name: "Ali təhsil - magistratura" },
  { value: "doctoral", name: "Ali təhsil - doktorantura" },
  { value: "generalSecondary", name: "Ümumi təhsil - ümumi orta təhsil" },
  { value: "completeSecondary", name: "Ümumi təhsil - tam orta təhsil" },
  { value: "firstVocational", name: "İlk peşə-ixtisas təhsili" },
  { value: "secondarySpecial", name: "Orta ixtisas təhsili" },
  {
    value: "secBasisOfGeneralSec",
    name: "Ümumi orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "secBasisOfCompleteSec",
    name: "Tam orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "incompleteHigherTill1993",
    name: "Ali təhsil - 1993 cü ilə qədər natamam ali təhsil",
  },
  {
    value: "completeHigherTill1993",
    name: "Ali təhsil - 1997 ci ilə qədər tam ali təhsil",
  },
].sort((a, b) => a.name.localeCompare(b.name));

const Education = ({
  values,
  setSelectedType,
  setOpenAddDialog,
  submitted,
}) => {
  const [universities, setUniversities] = useState([]);
  const [schooles, setSchooles] = useState([]);
  const [vocationalSchooles, setVocationalSchooles] = useState([]);
  const [otherInstitutiones, setOtherInstitutionses] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const { setErrors, errors, setFieldValue } = useFormikContext();
  const [key, setKey] = useState([]);
  const today = endOfDay(new Date());

  useEffect(() => {
    if (typeof key === "object" && Object.keys(key).length > 0) {
      Object.entries(key).forEach(([index, entry]) => {
        if (entry) getData(entry);
      });
    }
  }, [key, submitted]);
  useEffect(() => {
    if (values?.education?.length > 0) {
      values.education.forEach((education, index) => {
        const [keyword, hasFaculties] = foundKey(education.edType);
        getData([keyword, hasFaculties]);
      });
    }
  }, [values.education]);

  const getData = async (key) => {
    const [keyword, hasFaculties] = key;
    // setKey(keyword);

    const { data } = await getCommonData(keyword);
    if (keyword === "universityList") {
      data &&
        setUniversities((prevUniversities) => ({
          ...prevUniversities,
          [keyword]: data || [],
        }));
      if (!hasFaculties) {
        setFaculties([]);
        return;
      }
      const facultyResponse = await getCommonData("facultyList");
      setFaculties(facultyResponse.data);
    } else if (keyword === "schoolList") {
      data &&
        setSchooles((prevSchooles) => ({
          ...prevSchooles,
          [keyword]: data || [],
        }));
    } else if (keyword === "vocationalSchoolList") {
      data &&
        setVocationalSchooles((prevVocationalSchooles) => ({
          ...prevVocationalSchooles,
          [keyword]: data || [],
        }));
      if (!hasFaculties) {
        setFaculties([]);
        return;
      }
      const facultyResponse = await getCommonData("facultyList");
      setFaculties(facultyResponse.data);
    } else if (keyword === "otherInstitutions") {
      data &&
        setOtherInstitutionses((prevOtherInstitutionss) => ({
          ...prevOtherInstitutionss,
          [keyword]: data || [],
        }));
      if (!hasFaculties) {
        setFaculties([]);
        return;
      }
      const facultyResponse = await getCommonData("facultyList");
      setFaculties(facultyResponse.data);
    }
  };

  const foundKey = (field) => {
    let keyword;
    let hasFaculties = false;
    switch (field) {
      case "bachelor":
      case "incompleteHigherTill1993":
      case "completeHigherTill1993":
      case "master":
      case "doctoral":
        keyword = "universityList";
        hasFaculties = true;
        break;
      case "primary":
      case "generalSecondary":
      case "completeSecondary":
        keyword = "schoolList";
        hasFaculties = false;
        break;
      case "firstVocational":
      case "secondarySpecial":
        keyword = "vocationalSchoolList";
        hasFaculties = true;
        break;
      case "secBasisOfGeneralSec":
      case "secBasisOfCompleteSec":
        keyword = "otherInstitutions";
        hasFaculties = true;
        break;
      default:
        keyword = "";
        hasFaculties = false;
        break;
    }

    return [keyword, hasFaculties];
  };
  const renderInstitutionOptions = (
    index,
    key,
    values,
    data,
    set,
    educationTypes
  ) => {
    const currentInstitution = values?.education?.[index]?.institution;

    return (
      (key?.[index]?.[0] === set ||
        educationTypes.includes(values?.education?.[index]?.edType)) &&
      (data?.[set] ?? []).length > 0 &&
      [...new Set(data?.[set])]
        .sort((a, b) => a.localeCompare(b, "az"))
        .map((item) => (
          <MenuItem key={item} value={item} selected={currentInstitution}>
            {item}
          </MenuItem>
        ))
    );
  };
  const handleAddEducationInstitution = (
    index,
    key,
    values,
    setSelectedType,
    setOpenAddDialog
  ) => {
    const obj = {
      collegeList: "Kolleclərin siyahısına",
      vocationalSchoolList: "Peşə məktəblərinin siyahısına",
      universityList: "Universitetlərin siyahısına",
      facultyList: "Fakultələrin siyahısına",
      schoolList: "Məktəblərin siyahısına",
      otherInstitutions: "Digər müəssisələrə",
    };

    const educationType = values?.education?.[index]?.edType;
    const k = key?.[index]?.[0];

    const getType = () => {
      if (
        ["secondarySpecial", "firstVocational"].includes(educationType) &&
        key.length === 0
      ) {
        return "vocationalSchoolList";
      }
      if (
        ["secBasisOfGeneralSec", "secBasisOfCompleteSec"].includes(
          educationType
        ) &&
        key.length === 0
      ) {
        return "otherInstitutions";
      }
      if (
        ["primary", "completeSecondary", "generalSecondary"].includes(
          educationType
        ) &&
        key.length === 0
      ) {
        return "schoolList";
      }
      if (
        [
          "bachelor",
          "incompleteHigherTill1993",
          "completeHigherTill1997",
          "master",
          "doctoral",
        ].includes(educationType) &&
        key.length === 0
      ) {
        return "universityList";
      }
      return k;
    };

    const getInstitutionName = (type) => obj?.[type] || obj?.[k];

    setSelectedType([
      getType(),
      {
        name: getInstitutionName(getType()),
        values: getType(),
      },
    ]);
    setOpenAddDialog(true);
  };
  const handleEdTypeChange = (index, value) => {
    setFieldValue(`education[${index}].edType`, value);
    if (errors?.education?.[index]?.edType) {
      setErrors({
        ...errors,
        education: [
          ...errors.education.slice(0, index),
          {
            ...errors.education[index],
            edType: "",
          },
          ...errors.education.slice(index + 1),
        ],
      });
    }
    // Update key as an array where each index holds the selected key pair
    const [keyword, hasFaculties] = foundKey(value);
    setKey((prevKey) => {
      const updatedKey = [...prevKey];
      updatedKey[index] = [keyword, hasFaculties];
      return updatedKey;
    });
  };

  return (
    <FieldArray name="education">
      {({ push, remove }) => (
        <div>
          {values.education &&
            values.education.map((form, index) => (
              <div key={index}>
                <>
                  <Typography mt={3} mb={3} variant="h6">
                    {index + 1})Təhsil məlumatları
                  </Typography>
                  {/* Education type and institution */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.education?.[index]?.edType &&
                          Boolean(errors?.education?.[index]?.edType)
                        }
                        fullWidth
                      >
                        <InputLabel id={`education[${index}].edType`}>
                          Təhsil növü *
                        </InputLabel>
                        <Field
                          as={Select}
                          required
                          name={`education[${index}].edType`}
                          labelId={`reducation[${index}].edType`}
                          label="Təhsil növü *"
                          id={`education[${index}].edType`}
                          defaultValue=""
                          fullWidth
                          onChange={(e) =>
                            handleEdTypeChange(index, e.target.value)
                          }
                          error={
                            errors?.education?.[index]?.edType &&
                            Boolean(errors?.education?.[index]?.edType)
                          }
                        >
                          {educationType &&
                            educationType
                              .sort((a, b) =>
                                a?.name.localeCompare(b?.name, "az")
                              )
                              .map((item, index) => (
                                <MenuItem value={item.value}>
                                  {item.name}
                                </MenuItem>
                              ))}
                        </Field>
                        {errors.education?.[index]?.edType &&
                          errors.education?.[index]?.edType && (
                            <FormHelperText error>
                              {errors.education?.[index]?.edType}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.education?.[index]?.institution &&
                          Boolean(errors?.education?.[index]?.institution)
                        }
                        fullWidth
                      >
                        <InputLabel id={`education[${index}].institution`}>
                          Təhsil müəssisəsi *
                        </InputLabel>
                        <Field
                          as={Select}
                          name={`education[${index}].institution`}
                          labelId={`education[${index}].institution`}
                          label="Təhsil müəssisəsi *"
                          id={`education[${index}].institution`}
                          fullWidth
                          error={
                            errors?.education?.[index]?.institution &&
                            Boolean(errors?.education?.[index]?.institution)
                          }
                          // disabled={!values?.education[index]?.edType}
                          onChange={(e) => {
                            setFieldValue(
                              `education[${index}].institution`,
                              e.target.value
                            );
                            if (errors?.education?.[index]?.institution) {
                              setErrors({
                                ...errors,
                                education: [
                                  ...errors.education.slice(0, index),
                                  {
                                    ...errors.education[index],
                                    institution: "",
                                  },
                                  ...errors.education.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        >
                          {renderInstitutionOptions(
                            index,
                            key,
                            values,
                            universities,
                            "universityList",
                            [
                              "bachelor",
                              "incompleteHigherTill1993",
                              "completeHigherTill1997",
                              "master",
                              "doctoral",
                            ]
                          )}

                          {renderInstitutionOptions(
                            index,
                            key,
                            values,
                            schooles,
                            "schoolList",
                            ["primary", "completeSecondary", "generalSecondary"]
                          )}

                          {renderInstitutionOptions(
                            index,
                            key,
                            values,
                            vocationalSchooles,
                            "vocationalSchoolList",
                            ["secondarySpecial", "firstVocational"]
                          )}

                          {renderInstitutionOptions(
                            index,
                            key,
                            values,
                            otherInstitutiones,
                            "otherInstitutions",
                            ["secBasisOfGeneralSec", "secBasisOfCompleteSec"]
                          )}
                          <Button
                            sx={{
                              mt: 3,
                              ml: 3,
                            }}
                            variant="outlined"
                            // onClick={() => {
                            //   setOpenAddDialog(true);
                            //   setSelectedType([
                            //     "universityList",
                            //     { name: "Universitetlərin siyahısı" },
                            //   ]);
                            // }}
                            onClick={() => {
                              handleAddEducationInstitution(
                                index,
                                key,
                                values,
                                setSelectedType,
                                setOpenAddDialog
                              );
                            }}
                          >
                            Yeni təhsil müəssisəsi əlavə et
                          </Button>
                        </Field>
                        {errors.education?.[index]?.institution &&
                          errors.education?.[index]?.institution && (
                            <FormHelperText error>
                              {errors.education?.[index]?.institution}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* education[${index}] field */}
                  {values.education[index].edType === "primary" ||
                  values.education[index].edType === "completeSecondary" ||
                  values.education[index].edType === "generalSecondary" ? (
                    <></>
                  ) : (
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.education?.[index]?.faculty &&
                            Boolean(errors?.education?.[index]?.faculty)
                          }
                          fullWidth
                        >
                          <InputLabel id={`education[${index}].faculty`}>
                            Təhsil sahəsi (fakultə/ixtisas) *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`education[${index}].faculty`}
                            labelId={`education[${index}].faculty`}
                            label="Təhsil sahəsi (fakultə) *"
                            id={`education[${index}].faculty`}
                            fullWidth
                            error={
                              errors?.education?.[index]?.faculty &&
                              Boolean(errors?.education?.[index]?.faculty)
                            }
                            // disabled={faculties?.length > 0 ? false : true}
                            onChange={(e) => {
                              setFieldValue(
                                `education[${index}].faculty`,
                                e.target.value
                              );
                              if (errors?.education?.[index]?.faculty) {
                                setErrors({
                                  ...errors,
                                  education: [
                                    ...errors.education.slice(0, index),
                                    {
                                      ...errors.education[index],
                                      faculty: "",
                                    },
                                    ...errors.education.slice(index + 1),
                                  ],
                                });
                              }
                            }}
                          >
                            {faculties &&
                              [...new Set(faculties)]
                                .sort((a, b) => a.localeCompare(b, "az"))
                                .map((item, index) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            <Button
                              sx={{
                                mt: 3,
                                ml: 3,
                              }}
                              variant="outlined"
                              onClick={() => {
                                setOpenAddDialog(true);
                                setSelectedType([
                                  "facultyList",
                                  { name: "Fakultələrin siyahısı" },
                                ]);
                              }}
                            >
                              Yeni təhsil sahəsi (fakultə/ixtisas) əlavə et
                            </Button>
                          </Field>
                          {errors.education?.[index]?.faculty &&
                            errors.education?.[index]?.faculty && (
                              <FormHelperText error>
                                {errors.education?.[index]?.faculty}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/*start date and end date */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field name={`education[${index}].startDate`}>
                        {({ field, form }) => {
                          const selectedEndDate =
                            form.values.education[index]?.endDate;

                          return (
                            <DatePicker
                              {...field}
                              label="Təhsilin başlama tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={field.value || null}
                              maxDate={
                                selectedEndDate
                                  ? new Date(selectedEndDate)
                                  : today
                              }
                              minDate={new Date(1940, 0, 1)}
                              onChange={(date) => {
                                form.setFieldValue(
                                  `education[${index}].startDate`,
                                  date
                                );
                                if (errors?.education?.[index]?.startDate) {
                                  setErrors({
                                    ...errors,
                                    education: [
                                      ...errors.education.slice(0, index),
                                      {
                                        ...errors.education[index],
                                        startDate: "",
                                      },
                                      ...errors.education.slice(index + 1),
                                    ],
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={
                                    form.errors?.education?.[index]?.startDate
                                  }
                                  error={Boolean(
                                    form.errors?.education?.[index]?.startDate
                                  )}
                                  fullWidth
                                />
                              )}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    {!values?.education?.[index]?.stillStudying && (
                      <Grid sx={{ width: "100%" }}>
                        <Field name={`education[${index}].endDate`}>
                          {({ field, form }) => {
                            const selectedStartDate =
                              form?.values?.education?.[index]?.startDate;
                            const stillStudying =
                              values?.education?.[index]?.stillStudying;
                            return (
                              <DatePicker
                                {...field}
                                label="Təhsilin bitmə tarixi *"
                                inputFormat="dd/MM/yyyy"
                                value={field.value || null}
                                minDate={
                                  selectedStartDate
                                    ? new Date(selectedStartDate)
                                    : undefined
                                }
                                onChange={(date) => {
                                  form.setFieldValue(
                                    `education[${index}].endDate`,
                                    date
                                  );
                                  if (errors?.education?.[index]?.endDate) {
                                    setErrors({
                                      ...errors,
                                      education: [
                                        ...errors.education.slice(0, index),
                                        {
                                          ...errors.education[index],
                                          endDate: "",
                                        },
                                        ...errors.education.slice(index + 1),
                                      ],
                                    });
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    helperText={
                                      stillStudying
                                        ? null
                                        : form.errors?.education?.[index]
                                            ?.endDate
                                    }
                                    error={Boolean(
                                      stillStudying
                                        ? null
                                        : form.errors?.education?.[index]
                                            ?.endDate
                                    )}
                                    fullWidth
                                  />
                                )}
                                maxDate={today}
                                disableFuture
                                clearable
                                clearText="Clear"
                                disabled={stillStudying}
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                    )}
                  </Grid>

                  <Grid>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            values.education[index]?.stillStudying || false
                          }
                          color="primary"
                          onChange={(e) => {
                            setFieldValue(
                              `education[${index}].stillStudying`,
                              e.target.checked
                            );
                          }}
                          name={`education[${index}].stillStudying`}
                        />
                      }
                      label="Təhsil davam edir"
                    />
                  </Grid>
                </>

                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={() => remove(index)}
                >
                  Təhsili sil
                </Button>
              </div>
            ))}
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            type="button"
            onClick={() => push({ stillStudying: false, edType: "" })}
          >
            Təhsil əlavə et
          </Button>
        </div>
      )}
    </FieldArray>
  );
};

export default Education;
