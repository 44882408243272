import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage, Form, FieldArray } from "formik";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CreateIcon from "@mui/icons-material/Create";

import { endOfDay, isAfter, isValid, parse } from "date-fns";
import moment from "moment";
import { editLaborActivity } from "../../../components/employees/Validations";
import { sendPersonalInfoChanges } from "../../../services/employeeCabinet/employeeCabinet";
import {
  getAllStructures,
  getCommonData,
  getPositions,
  getTopParts,
} from "../../../api/api";
import { laborActivityValidation } from "./Validations";

const today = endOfDay(new Date());

function LaborActivity({ employeeById }) {
  const [organizations, setOrganizations] = useState([]);
  const [positions, setPositions] = useState([]);
  const [otherStructures, setOtherStructures] = useState([]);
  const [mainOrganizations, setMainOrganizations] = useState([]);
  const [mainStructures, setMainStructures] = useState([]);
  const [mainPositions, setMainPositions] = useState([]);
  const [structuresIds, setStructuresIds] = useState([]);

  const [formCorrection, setFormCorrection] = useState(false);
  const [requestButton, setRequestButton] = useState(false);

  // Params for main structures api
  const [structuresParams, setStructuresParams] = useState({
    offset: 0,
    limit: 9999,
    structureId: "",
  });

  const onClickCB = () => {
    setFormCorrection(!formCorrection);
  };

  useEffect(() => {
    const handleApi = async () => {
      try {
        const organizationsResponse = await getCommonData("organizations");
        const positionResponse = await getCommonData("positions");
        const otherStructures = await getCommonData("structures");
        const mainOrganizationsResponse = await getAllStructures();

        setOtherStructures(otherStructures.data);
        setOrganizations(organizationsResponse.data);
        setPositions(positionResponse.data);
        setMainOrganizations(mainOrganizationsResponse.data?.structures);
      } catch (error) {
        console.log("err", error);
      }
    };
    handleApi();
  }, []);

  const getStructuresAndPositions = async () => {
    try {
      if (structuresIds) {
        for (let index = 0; index < structuresIds.length; index++) {
          try {
            const value = structuresIds[index];
            const payload = {
              offset: 0,
              limit: 9999,
              structureId: value,
            };

            const response = await getPositions(payload);
            const mainStructuresResponse = await getTopParts(payload);

            setMainPositions((prevItems) => {
              const updatedItems = [...prevItems];
              updatedItems[index] = response.data.parts;
              return updatedItems;
            });
            setMainStructures((prevItems) => {
              const updatedItems = [...prevItems];
              updatedItems[index] = mainStructuresResponse.data.parts;
              return updatedItems;
            });
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStructuresAndPositions();
  }, [structuresIds]);

  const initialValues = {
    laborActivity:
      employeeById?.laborActivity?.map((item, index) => ({
        ...item,
        id: index,
      })) || [],
  };

  const [dataIndex, setDataIndex] = useState(
    initialValues?.laborActivity.at(-1)?.id
  );

  // change request methods

  function updateData(newObject, oldObject) {
    let updateData = {
      index: newObject.id,
      operation: "update",
      data: {},
    };

    const keys = Object.keys(newObject);
    for (const key of keys) {
      if (newObject[key] !== oldObject[key]) {
        updateData.data[key] = {
          old: newObject[key],
          new: oldObject[key],
        };
      }
    }
    const a = Object.keys(updateData.data);
    if (a.length) {
      return updateData;
    }
  }

  function makeChangesData(formValue, oldValue) {
    let changesData = {
      section: "labor-activity",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.laborActivity.forEach((obj1) => {
      const obj2 = oldValue.laborActivity.find((item) => item.id === obj1.id);

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.laborActivity.forEach((obj2) => {
      const obj1 = formValue.laborActivity.find((item) => item.id === obj2.id);

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }
    sendPersonalInfoChanges(changesData);
  }

  function handleSubmit(data) {
    makeChangesData(data, initialValues);
  }

  // form controller for submitting

  function controlFormForSubmitting(data) {
    controllData(data, initialValues);
  }

  function controllData(formValue, oldValue) {
    let changesData = {
      section: "labor-activity",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.laborActivity.forEach((obj1) => {
      const obj2 = oldValue.laborActivity.find((item) => item.id === obj1.id);

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          index: obj1.id,
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.laborActivity.forEach((obj2) => {
      const obj1 = formValue.laborActivity.find((item) => item.id === obj2.id);

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }

    if (!changesData.changes.length) {
      setRequestButton(false);
    }
  }

  return (
    <>
      {employeeById ? (
        <Grid>
          <Button
            style={
              formCorrection
                ? { backgroundColor: "rgba(121,46,46, 0.9)", marginTop: "10px" }
                : { marginTop: "10px" }
            }
            onClick={onClickCB}
            variant="contained"
            color="secondary"
          >
            {formCorrection ? "Ləğv et" : "Dəyişiklik et"}

            <CreateIcon sx={{ marginLeft: 5 }} />
          </Button>
          <Formik
            initialValues={{
              laborActivity: initialValues.laborActivity || [],
            }}
            validationSchema={laborActivityValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
            }) => (
              <Form
                style={
                  !formCorrection
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <FieldArray name="laborActivity">
                  {({ push, remove }) => (
                    <div>
                      {values.laborActivity.map((form, index) => {
                        return (
                          <div key={index}>
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "10px",
                                }}
                              >
                                <Typography sx={{ mt: 5 }} variant="h6">
                                  {index + 1})Əmək fəaliyyəti
                                </Typography>
                              </div>
                              {/* Organization name and department */}
                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  {values.laborActivity[index].sameWorkplace ? (
                                    <FormControl
                                      error={
                                        errors?.laborActivity?.[index]
                                          .organizationName &&
                                        Boolean(
                                          errors?.laborActivity?.[index]
                                            .organizationName
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`laborActivity[${index}].organizationName`}
                                      >
                                        Təşkilatın adı *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`laborActivity[${index}].organizationName`}
                                        labelId={`laborActivity[${index}].organizationName`}
                                        label="Təşkilatın adı *"
                                        id={`laborActivity[${index}].organizationName`}
                                        fullWidth
                                        defaultValue=""
                                        error={
                                          errors?.laborActivity?.[index]
                                            .organizationName &&
                                          Boolean(
                                            errors?.laborActivity?.[index]
                                              .organizationName
                                          )
                                        }
                                        onChange={(e) => {
                                          setRequestButton(true);
                                          setFieldValue(
                                            `laborActivity[${index}].organizationName`,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        {mainOrganizations.map((item) => (
                                          <MenuItem
                                            key={item._id}
                                            value={item?.organization?.name}
                                            onClick={() => {
                                              const updatedStructureIds = [
                                                ...structuresIds,
                                              ];

                                              updatedStructureIds[index] =
                                                item._id;

                                              setStructuresIds(
                                                updatedStructureIds
                                              );
                                              setStructuresParams(
                                                (prevValues) => ({
                                                  ...prevValues,
                                                  structureId: item._id,
                                                })
                                              );
                                            }}
                                          >
                                            {item?.organization?.name}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                      {errors?.laborActivity?.[index]
                                        .organizationName &&
                                        errors?.laborActivity?.[index]
                                          .organizationName && (
                                          <FormHelperText error>
                                            {
                                              errors.laborActivity?.[index]
                                                .organizationName
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  ) : (
                                    <FormControl
                                      error={
                                        errors?.laborActivity?.[index]
                                          .organizationName &&
                                        Boolean(
                                          errors?.laborActivity?.[index]
                                            .organizationName
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`laborActivity[${index}].organizationName`}
                                      >
                                        Təşkilatın adı
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`laborActivity[${index}].organizationName`}
                                        labelId={`laborActivity[${index}].organizationName`}
                                        label="Təşkilatın adı"
                                        id={`laborActivity[${index}].organizationName`}
                                        // defaultValue=""
                                        error={
                                          errors?.laborActivity?.[index]
                                            .organizationName &&
                                          Boolean(
                                            errors?.laborActivity?.[index]
                                              .organizationName
                                          )
                                        }
                                        fullWidth
                                        onChange={(e) => {
                                          setRequestButton(true);
                                          setFieldValue(
                                            `laborActivity[${index}].organizationName`,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        {organizations?.map((item, index) => (
                                          <MenuItem
                                            key={index + item}
                                            value={item}
                                          >
                                            {item}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                      {errors.laborActivity?.[index]
                                        .organizationName &&
                                        errors.laborActivity?.[index]
                                          .organizationName && (
                                          <FormHelperText error>
                                            {
                                              errors.laborActivity?.[index]
                                                .organizationName
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  )}
                                </Grid>

                                <Grid sx={{ width: "100%" }}>
                                  {!values.laborActivity[index]
                                    .sameWorkplace ? (
                                    <FormControl
                                      error={
                                        errors?.laborActivity?.[index]
                                          .department &&
                                        Boolean(
                                          errors?.laborActivity?.[index]
                                            .department
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`laborActivity[${index}].department`}
                                      >
                                        Struktur bölmə *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`laborActivity[${index}].department`}
                                        labelId={`laborActivity[${index}].department`}
                                        label="Struktur bölmə *"
                                        id={`laborActivity[${index}].department`}
                                        defaultValue=""
                                        error={
                                          errors?.laborActivity?.[index]
                                            .department &&
                                          Boolean(
                                            errors?.laborActivity?.[index]
                                              .department
                                          )
                                        }
                                        fullWidth
                                        onChange={(e) => {
                                          setRequestButton(true);
                                          setFieldValue(
                                            `laborActivity[${index}].department`,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        {otherStructures.map((item, index) => (
                                          <MenuItem key={index} value={item}>
                                            {item}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                      {errors.laborActivity?.[index]
                                        .department &&
                                        errors.laborActivity?.[index]
                                          .department && (
                                          <FormHelperText error>
                                            {
                                              errors.laborActivity?.[index]
                                                .department
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  ) : (
                                    <FormControl
                                      error={
                                        errors?.laborActivity?.[index]
                                          .department &&
                                        Boolean(
                                          errors?.laborActivity?.[index]
                                            .department
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`laborActivity[${index}].department`}
                                      >
                                        {mainStructures[index]?.length === 0
                                          ? "Göstəriləcək struktur bölmə yoxdur *"
                                          : "Struktur bölmə *"}
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`laborActivity[${index}].department`}
                                        labelId={`laborActivity[${index}].department`}
                                        label={
                                          mainStructures[index]?.length === 0
                                            ? "Göstəriləcək struktur bölmə yoxdur *"
                                            : "Struktur bölmə *"
                                        }
                                        id={`laborActivity[${index}].department`}
                                        defaultValue=""
                                        disabled={
                                          mainStructures[index]?.length === 0
                                        }
                                        error={
                                          errors?.laborActivity?.[index]
                                            .department &&
                                          Boolean(
                                            errors?.laborActivity?.[index]
                                              .department
                                          )
                                        }
                                        fullWidth
                                      >
                                        {mainStructures[index] &&
                                          mainStructures[index].map(
                                            (item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item.source.name}
                                              >
                                                {item.source.name}
                                              </MenuItem>
                                            )
                                          )}
                                      </Field>
                                      {errors.laborActivity?.[index]
                                        .department &&
                                        errors.laborActivity?.[index]
                                          .department && (
                                          <FormHelperText error>
                                            {
                                              errors.laborActivity?.[index]
                                                .department
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  )}
                                </Grid>
                              </Grid>

                              {/* Position name and Civil Servant */}
                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  {!values.laborActivity[index]
                                    ?.sameWorkplace ? (
                                    <FormControl
                                      error={
                                        errors?.laborActivity?.[index]
                                          .position &&
                                        Boolean(
                                          errors?.laborActivity?.[index]
                                            .position
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`laborActivity[${index}].position`}
                                      >
                                        Vəzifə *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`laborActivity[${index}].position`}
                                        labelId={`laborActivity[${index}].position`}
                                        label="Vəzifə *"
                                        id={`laborActivity[${index}].position`}
                                        defaultValue=""
                                        fullWidth
                                        error={
                                          errors?.laborActivity?.[index]
                                            .position &&
                                          Boolean(
                                            errors?.laborActivity?.[index]
                                              .position
                                          )
                                        }
                                        onChange={(e) => {
                                          setRequestButton(true);
                                          setFieldValue(
                                            `laborActivity[${index}].position`,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        {positions.map((item, index) => (
                                          <MenuItem
                                            key={index + item}
                                            value={item}
                                          >
                                            {item}
                                          </MenuItem>
                                        ))}
                                      </Field>
                                      {errors.laborActivity?.[index].position &&
                                        errors.laborActivity?.[index]
                                          .position && (
                                          <FormHelperText error>
                                            {
                                              errors.laborActivity?.[index]
                                                .position
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  ) : (
                                    <FormControl
                                      error={
                                        errors?.laborActivity?.[index]
                                          .position &&
                                        Boolean(
                                          errors?.laborActivity?.[index]
                                            .position
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`laborActivity[${index}].position`}
                                      >
                                        {mainPositions[index]?.length === 0
                                          ? "Göstəriləcək vəzifə yoxdur *"
                                          : "Vəzifə *"}
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`laborActivity[${index}].position`}
                                        labelId={`laborActivity[${index}].position`}
                                        label="Vəzifə *"
                                        id={`laborActivity[${index}].position`}
                                        disabled={
                                          mainPositions[index].length === 0 &&
                                          !values.laborActivity[index].position
                                        }
                                        defaultValue=""
                                        fullWidth
                                        error={
                                          errors?.laborActivity?.[index]
                                            .position &&
                                          Boolean(
                                            errors?.laborActivity?.[index]
                                              .position
                                          )
                                        }
                                      >
                                        {mainPositions[index] &&
                                          mainPositions[index].map(
                                            (item, index) => (
                                              <MenuItem
                                                value={item.source.name}
                                              >
                                                {item.source.name}
                                              </MenuItem>
                                            )
                                          )}
                                      </Field>
                                      {errors.laborActivity?.[index].position &&
                                        errors.laborActivity?.[index]
                                          .position && (
                                          <FormHelperText error>
                                            {
                                              errors.laborActivity?.[index]
                                                .position
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  )}
                                </Grid>

                                <Grid sx={{ width: "100%" }}>
                                  <FormControl
                                    fullWidth
                                    error={
                                      errors?.laborActivity?.[index]
                                        .isCivilServant &&
                                      Boolean(
                                        errors?.laborActivity?.[index]
                                          .isCivilServant
                                      )
                                    }
                                  >
                                    <InputLabel
                                      id={`laborActivity[${index}].isCivilServant`}
                                    >
                                      Dövlət qulluqçusu *
                                    </InputLabel>
                                    <Field
                                      as={Select}
                                      name={`laborActivity[${index}].isCivilServant`}
                                      labelId={`laborActivity[${index}].isCivilServant`}
                                      label="Dövlət qulluqçusu *"
                                      id={`laborActivity[${index}].isCivilServant-select`}
                                      defaultValue=""
                                      fullWidth
                                      error={
                                        errors?.laborActivity?.[index]
                                          .isCivilServant &&
                                        Boolean(
                                          errors?.laborActivity?.[index]
                                            .isCivilServant
                                        )
                                      }
                                      onChange={(e) => {
                                        setRequestButton(true);
                                        setFieldValue(
                                          `laborActivity[${index}].isCivilServant`,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <MenuItem value={true}>Bəli</MenuItem>
                                      <MenuItem value={false}>Xeyr</MenuItem>
                                    </Field>
                                    {errors.laborActivity?.[index]
                                      .isCivilServant &&
                                      errors.laborActivity?.[index]
                                        .isCivilServant && (
                                        <FormHelperText error>
                                          {
                                            errors.laborActivity?.[index]
                                              .isCivilServant
                                          }
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                              </Grid>

                              {/* Job start and end date */}
                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Field
                                  name={`laborActivity[${index}].startDate`}
                                >
                                  {({ field, form }) => {
                                    const endDate =
                                      form.values.laborActivity[index].endDate;

                                    return (
                                      <DatePicker
                                        {...field}
                                        label="İşə başlama tarixi"
                                        inputFormat="dd/MM/yyyy"
                                        value={field.value}
                                        onChange={(date) => {
                                          setRequestButton(true);

                                          if (
                                            date instanceof Date &&
                                            !isNaN(date)
                                          ) {
                                            form.setFieldValue(
                                              `laborActivity[${index}].startDate`,
                                              date
                                            );
                                          } else {
                                            form.setFieldValue(
                                              `laborActivity[${index}].startDate`,
                                              ""
                                            );
                                          }
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            helperText={
                                              form.errors?.laborActivity?.[
                                                index
                                              ].startDate
                                            }
                                            error={Boolean(
                                              form.errors.laborActivity?.[index]
                                                .startDate
                                            )}
                                            fullWidth
                                          />
                                        )}
                                        maxDate={
                                          endDate ? new Date(endDate) : today
                                        }
                                        minDate={new Date(1940, 0, 1)}
                                        disableFuture
                                        clearable
                                        clearText="Clear"
                                      />
                                    );
                                  }}
                                </Field>

                                {values.laborActivity[index].sameWorkplace ? (
                                  <Field
                                    type="text"
                                    name="endDate"
                                    as={TextField}
                                    fullWidth
                                    label="İşin bitmə tarixi"
                                    value="---"
                                    error={
                                      errors.endDate && Boolean(errors.endDate)
                                    }
                                    helperText={
                                      errors.endDate && errors.endDate
                                    }
                                    required
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                ) : (
                                  <Field
                                    name={`laborActivity[${index}].endDate`}
                                  >
                                    {({ field, form }) => {
                                      const startDate =
                                        form.values.laborActivity[index]
                                          .startDate;

                                      return (
                                        <DatePicker
                                          {...field}
                                          label="İşin bitmə tarixi"
                                          inputFormat="dd/MM/yyyy"
                                          value={field.value}
                                          onChange={(date) => {
                                            setRequestButton(true);

                                            if (
                                              date instanceof Date &&
                                              !isNaN(date)
                                            ) {
                                              form.setFieldValue(
                                                `laborActivity[${index}].endDate`,
                                                date
                                              );
                                            } else {
                                              form.setFieldValue(
                                                `laborActivity[${index}].endDate`,
                                                ""
                                              );
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={
                                                form.errors?.laborActivity?.[
                                                  index
                                                ].endDate
                                              }
                                              error={Boolean(
                                                form.errors.laborActivity?.[
                                                  index
                                                ].endDate
                                              )}
                                              fullWidth
                                            />
                                          )}
                                          maxDate={today}
                                          disableFuture
                                          clearable
                                          clearText="Clear"
                                          minDate={
                                            startDate
                                              ? new Date(startDate)
                                              : undefined
                                          }
                                          minDateMessage="End date should not be before the start date"
                                        />
                                      );
                                    }}
                                  </Field>
                                )}
                              </Grid>

                              {/* Service period and same workplace */}
                              <Grid
                                sx={{
                                  display: "flex",
                                  gap: 3,
                                  alignItems: "center",
                                }}
                              >
                                <Grid
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    ml: 2,
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values.laborActivity[index]
                                            .sameWorkplace || false
                                        }
                                        // disabled={
                                        //   values.laborActivity[index]
                                        //     ?.sameWorkplace
                                        // }
                                        onChange={handleChange}
                                        name={`laborActivity[${index}].sameWorkplace`}
                                        color="primary"
                                      />
                                    }
                                    label="Bu təşkilatda"
                                  />
                                  <ErrorMessage
                                    name={`laborActivity[${index}].sameWorkplace`}
                                    component="div"
                                  />
                                </Grid>
                              </Grid>
                            </>

                            <Button
                              sx={
                                formCorrection
                                  ? { visibility: "visible", mt: 3 }
                                  : { visibility: "hidden" }
                              }
                              variant="contained"
                              color="error"
                              type="button"
                              onClick={() => {
                                setRequestButton(true);

                                remove(index);

                                const updatedArray =
                                  values.laborActivity.filter(
                                    (item) => item.id !== index
                                  );
                                controlFormForSubmitting({
                                  laborActivity: updatedArray,
                                });
                              }}
                            >
                              Ləğv et
                            </Button>
                          </div>
                        );
                      })}
                      <Button
                        sx={
                          formCorrection
                            ? { visibility: "visible", mt: 3 }
                            : { visibility: "hidden" }
                        }
                        variant="contained"
                        type="button"
                        onClick={() => {
                          let newId = dataIndex + 1;
                          setDataIndex(newId);
                          push({
                            sameWorkplace: false,
                            id: newId,
                          });
                        }}
                      >
                        Əlavə et
                      </Button>
                    </div>
                  )}
                </FieldArray>

                {/* Submit button */}
                <Grid
                  style={
                    formCorrection
                      ? { visibility: "visible", marginTop: "10px" }
                      : { visibility: "hidden" }
                  }
                >
                  <Button
                    type="submit"
                    style={
                      !requestButton
                        ? { pointerEvents: "none" }
                        : {
                            backgroundColor: "#28358a",
                            pointerEvents: "auto",
                          }
                    }
                    variant="contained"
                    color="secondary"
                  >
                    Dəyişiklik sorğusu göndər
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default LaborActivity;
