import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

const DeleteList = ({
  openDeleteList,
  setOpenDeleteList,
  handleDeleteList,
  columnId,
}) => {
  return (
    <>
      <Dialog
        open={openDeleteList}
        onClose={() => {
          setOpenDeleteList(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Silmək istədiyinizdən əminsiniz ?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteList(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setOpenDeleteList(false);
              handleDeleteList(columnId);
            }}
          >
            Bəli
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteList;
