import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useState } from "react";
import AddChecklistItem from "../dialogs/checklist/AddChecklistItem";
import {
  addChecklistItem,
  editChecklist,
  removeChecklist,
  removeChecklistItem,
  toggleCompletedStatus,
  updateChecklistItem,
} from "../../../api/tasks/taskRequests";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditChecklist from "../dialogs/checklist/EditChecklist";
import EditChecklistItem from "../dialogs/checklist/EditChecklistItem";

const Checklist = ({ checklists, submitted, setSubmitted }) => {
  // ** Checklist item dialogs
  const [addItemDialog, setAddItemDialog] = useState(false);
  const [selectedChecklistId, setSelectedChecklistId] = useState();
  const [selectedChecklist, setSelectedChecklist] = useState();

  // ** Check list item
  const [selectedCheckListItem, setSelectedCheckListItem] = useState();

  // ** Dialogs
  const [editChecklistDialog, setEditChecklistDialog] = useState(false);
  const [checklistItemDialog, setChecklistItemDialog] = useState(false);

  const handleRemoveChecklist = async (id) => {
    await removeChecklist(id);
    setSubmitted(!submitted);
  };

  const handleEditChecklist = async (id, title) => {
    let data = {
      title: title,
    };
    await editChecklist(id, data);
    setSubmitted(!submitted);
  };

  const handleAddChecklistItem = async (title) => {
    let data = {
      title: title,
      checklistId: selectedChecklistId,
    };
    await addChecklistItem(data);
    setSubmitted(!submitted);
  };

  const handleDeleteCheckListItem = async (id) => {
    await removeChecklistItem(id);
    setSubmitted(!submitted);
  };

  const handleEditChecklistItem = async (id, title) => {
    let data = {
      title: title,
    };
    await updateChecklistItem(id, data);
    setSubmitted(!submitted);
  };

  const handleToggleChecklistItem = async (id, status) => {
    let data = {
      isCompleted: status,
    };

    await toggleCompletedStatus(id, data);
  };

  return (
    <>
      {checklists.map((checklist) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
          key={checklist._id}
        >
          {/* Title and edit, remove checklist */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 5,
              backgroundColor: "#1B2635",
              px: 3,
              py: 2,
              borderRadius: "4px",
            }}
          >
            <Box sx={{ display: "flex", gap: 5 }}>
              <CheckBoxIcon />
              <Typography variant="h4">{checklist.title}</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
              }}
            >
              <IconButton
                onClick={() => {
                  setEditChecklistDialog(true);
                  setSelectedChecklist({
                    _id: checklist._id,
                    title: checklist.title,
                  });
                }}
                size="small"
              >
                <EditIcon sx={{ fontSize: "16px", cursor: "pointer" }} />
              </IconButton>

              <IconButton
                onClick={() => {
                  handleRemoveChecklist(checklist._id);
                }}
                size="small"
              >
                <DeleteIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Box>
          </Box>

          <Box>
            {checklist.items.map((item) => (
              <Box
                key={item._id}
                sx={{
                  display: "flex",
                  gap: 3,
                  borderBottom: 2,
                  borderRight: 2,
                  borderLeft: 2,
                  pr: 3,
                  pl: 1,
                  py: 2,
                  borderRadius: "4px",
                  borderColor: "#1B2635",
                }}
              >
                <Box>
                  <Checkbox
                    onChange={(e) => {
                      handleToggleChecklistItem(item._id, e.target.checked);
                    }}
                    defaultChecked={item.isCompleted}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box mt={2}>
                    <Typography>{item.title}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                    }}
                  >
                    <Box>
                      <IconButton
                        onClick={() => {
                          setChecklistItemDialog(true);
                          setSelectedCheckListItem({
                            title: item.title,
                            _id: item._id,
                          });
                        }}
                        size="small"
                      >
                        <EditIcon
                          sx={{ fontSize: "16px", cursor: "pointer" }}
                        />
                      </IconButton>
                    </Box>

                    <Box>
                      <IconButton
                        onClick={() => {
                          handleDeleteCheckListItem(item._id);
                        }}
                        size="small"
                      >
                        <DeleteIcon sx={{ fontSize: "16px" }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={() => {
                setAddItemDialog(true);
                setSelectedChecklistId(checklist._id);
              }}
              variant="outlined"
            >
              Əlavə et
            </Button>
          </Box>
        </Box>
      ))}

      {addItemDialog && (
        <AddChecklistItem
          addItemDialog={addItemDialog}
          setAddItemDialog={setAddItemDialog}
          handleAddChecklistItem={handleAddChecklistItem}
        />
      )}

      {editChecklistDialog && (
        <EditChecklist
          editChecklistDialog={editChecklistDialog}
          setEditChecklistDialog={setEditChecklistDialog}
          selectedChecklist={selectedChecklist}
          handleEditChecklist={handleEditChecklist}
        />
      )}

      {checklistItemDialog && (
        <EditChecklistItem
          checklistItemDialog={checklistItemDialog}
          setChecklistItemDialog={setChecklistItemDialog}
          selectedCheckListItem={selectedCheckListItem}
          handleEditChecklistItem={handleEditChecklistItem}
        />
      )}
    </>
  );
};

export default Checklist;
