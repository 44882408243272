import React, { useEffect, useState } from "react";
import Table from "../../../components/table/Table";
import {
  vacationTrackingRequest,
  getDownloadvacationTrackingRequest,
} from "../../../api/employeeRequests/employeeRequests";
import { Typography } from "@mui/material";
import VacationTrackingForm from "./VacationTrackingForm";
import { columnsVacation } from "./vacationModel";
import { downloadFile } from "../../../utils/downloadCSV";

const VacationTracking = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [submited, setSubmited] = useState(false);
  const [filters, setFilters] = useState([]);
  const [total, setTotal] = useState();
  const getVacation = async (filters) => {
    if (!filters) {
      // eslint-disable-next-line no-const-assign
      filters = [];
    }
    // filters.push({
    //   column: "docModel",
    //   operator: "equalsTo",
    //   value: "VacationTracking",
    // });

    try {
      setLoading(true);
      const response = await vacationTrackingRequest(filters, limit, offset);
      if (response && response?.data) {
        const vacationTrackingData = response?.data?.records;
        setData(vacationTrackingData);
        setTotal(response?.data?.total);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVacation(filters);
  }, [filters, offset, limit]);
  const downloadHandler = async (columnsForExport) => {
    try {
      const resp = await getDownloadvacationTrackingRequest(
        filters,
        limit,
        offset
      );
      if (resp.status === 200) {
        const contentDisposition = resp.headers["content-disposition"];

        const fileName = contentDisposition
          ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "")
          : "emp_datas.xlsx";

        const blob = resp.data;

        if (fileName.endsWith(".xlsx")) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        }
      } else {
        throw new Error("File could not be created");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div className="vacation_tracking_main">
      <Typography variant="h3" gutterBottom display="inline">
        Əmək Məzuniyyətinin İzlənməsi
      </Typography>
      <VacationTrackingForm
        submited={submited}
        getData={getVacation}
        setSubmited={setSubmited}
      />
      <Table
        data={data}
        limit={limit}
        offset={offset}
        totalRows={total}
        columns={columnsVacation}
        isLoading={loading}
        setLimit={(value) => setLimit(value)}
        setOffset={(value) => setOffset(value)}
        cancelAppliedFilters={() => setFilters([])}
        applyFiltersHandler={(filters) => setFilters(filters)}
        downloadHandler={(cols) => downloadHandler(cols)}
      />
    </div>
  );
};

export default VacationTracking;
