import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, IconButton, Dialog } from "@mui/material";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  DOCUMENT_NAME,
  SIGNING_CARD_TYPES,
  templateTypes,
} from "../../../../constants";
import { formatDateTime } from "../../../../utils/helperFunctions";
import { styles } from "./styles";
import DocumentDialog from "../../../../components/dialogs/documentDialog/DocumentDialog";
import { getContractforSigningDocs } from "../../../../services/documentations/signingContractServices";
import { toast } from "react-toastify";
import ContractDialog from "../../../../components/dialogs/documentDialog/ContractDialog";
import { getContract } from "../../../../services/documentations/ContractServices";

const CardItem = ({ refreshPage, type, data, modalStatus }) => {
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  if (type === SIGNING_CARD_TYPES.PENDING) {
  }

  // const [status, setStatus] = useState();
  const cardTitles = (key) => {
    switch (key) {
      case "employment-application":
        return "Ərizə";
      case "labour-contract":
        return "Müqavilə";
      case "labour-order":
        return "Əmr";
      case "vacation-order":
        return "Məzuniyyət əmri";
      case "vacation-application":
        return "Məzuniyyət ərizəsi";
      case "labour-termination-order":
        return "Xitam əmri";
      default:
        return key;
    }
  };

  const bgColor = () => {
    switch (type) {
      case SIGNING_CARD_TYPES.PENDING:
        return "rgba(15, 76, 117, 0.5)";
      case SIGNING_CARD_TYPES.SIGNED:
        return "rgba(27, 94, 32, 0.5)";
      case SIGNING_CARD_TYPES.CANCELED:
        return "rgba(198, 40, 40, 0.5)";
      default:
        break;
    }
  };

  // const bgColorDialog = () => {
  //   switch (type) {
  //     case SIGNING_CARD_TYPES.PENDING:
  //       return "rgba(95,154,226, 0.95)";
  //     case SIGNING_CARD_TYPES.CANCELED:
  //       return "rgba(208, 130, 130, 0.95)";
  //     case SIGNING_CARD_TYPES.SIGNED:
  //       return "rgba(141,181,160,0.95)";
  //     default:
  //       break;
  //   }
  // };

  const cardDescHandler = () => {
    // const typeCont = data?.contractType.name;
    const validate = (value) => (value?.toString().length > 0 ? value : "");

    const { firstname, lastname, position } = data.creator
      ? data.creator
      : {
          firstname: "_",
          lastname: "_",
          position: "_",
        };
    const obj = {
      contNumber: "",
      contCreator: validate(
        `${firstname} ${lastname} ${
          position?.name ? " - " + position?.name : ""
        }`
      ),
      contDepartment: validate(data.topPartOfStructures[0]?.source.name),
      contType:
        validate(data.contractType.type) === "agreement"
          ? "Müqavilə"
          : validate(data.contractType.type) === "service"
          ? "Sorğu"
          : "Əmr",
      contName:
        validate(data.contractType.name) &&
        DOCUMENT_NAME[data.contractType.name]
          ? DOCUMENT_NAME[data.contractType.name]
          : data.contractType.name,
      contCreateDate: Boolean(formatDateTime(validate(data.createdAt)))
        ? formatDateTime(data.createdAt)
        : "",
    };

    if (
      data?.data?.docNumber &&
      templateTypes.includes(data?.contractType.type)
    ) {
      obj.contNumber = validate(data?.data?.docNumber?.value);
    }

    // if (
    //   data?.data?.contractNumber &&
    //   templateTypes.includes(data?.contractType.type)
    // ) {
    //   obj.contNumber = validate(data?.data?.contractNumber?.value);
    // }

    // if (typeCont === CONTRACT_TYPE.LABOUR) {
    //   obj.contNumber = validate(data?.data?.contractNumber?.value);
    // } else if (typeCont === CONTRACT_TYPE.ORDER) {
    //   obj.contNumber = validate(data?.data?.orderNumber?.value);
    // }

    return obj;
  };

  const onCloseHandler = () => {
    setIsShow(false);
  };
  return (
    <Card
      sx={[
        styles.container,
        {
          backgroundColor: bgColor(),
          border: `1px solid ${bgColor()}`,
          borderRadius: "4px",
        },
      ]}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 5,
        }}
      >
        <Grid
          mb={1}
          sx={{
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              textAlign: "center",
              borderBottom: "1px solid rgba(255,255,255, 0.1)",
              fontSize: "14px ",
            }}
          >
            {cardTitles(data?.contractType?.label)}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.fieldContainer}>
        <Typography fontSize={14} sx={styles.fieldNameText}>
          Sənəd No:
        </Typography>
        <Typography
          fontSize={14}
          sx={[styles.fieldValueText, { fontWeight: "600" }]}
        >
          {cardDescHandler().contNumber}
        </Typography>
      </Grid>

      <Typography sx={styles.fieldNameText}>
        Sənəd Sorğusunu Yaradan:{"  "}
        <Typography sx={styles.fieldValueText}>
          {cardDescHandler().contCreator}
        </Typography>
      </Typography>
      <Typography sx={styles.fieldNameText}>
        Struktur Departement:{"  "}
        <Typography sx={styles.fieldValueText}>
          {cardDescHandler().contDepartment}
        </Typography>
      </Typography>
      <Typography sx={styles.fieldNameText}>
        Sənədin növü: {"  "}
        <Typography sx={styles.fieldValueText}>
          {cardDescHandler().contName} {" ( "} {cardDescHandler().contType}
          {" )"}
        </Typography>
      </Typography>
      <Grid style={{ width: "90%" }}>
        <Typography sx={[styles.fieldNameText]}>
          Sənədin Yaradılma/Sorğu tarixi:{"  "}
          <Typography sx={styles.fieldValueText}>
            {cardDescHandler().contCreateDate}
          </Typography>
        </Typography>
      </Grid>
      <Grid sx={styles.buttonContainer}>
        <IconButton
          onClick={() => setIsShow(true)}
          sx={{ position: "absolute", bottom: 5 }}
        >
          <EyeIcon htmlColor="#000" />
        </IconButton>
      </Grid>
      {/* <Grid sx={styles.buttonContainer}>
        <DocumentDialog
          refreshPage={refreshPage}
          documentId={data._id}
          modalStatus={modalStatus}
          fetchHandler={getContractforSigningDocs}
        />
      </Grid> */}
      {isShow && (
        <ContractDialog
          refreshPage={refreshPage}
          id={data._id}
          onClose={onCloseHandler}
          open={isShow}
          fetchHandler={getContract}
        />
      )}
    </Card>
  );
};

export default CardItem;
