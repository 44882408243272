import React, { useRef, useState } from "react";
import { Formik, Form, FieldArray, Field } from "formik";

import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import { banksList } from "../../../constants/generally/BankInfos";
import CreateIcon from "@mui/icons-material/Create";
import { sendPersonalInfoChanges } from "../../../services/employeeCabinet/employeeCabinet";
import { bankInfoValidation } from "./Validations";

const MCViewBankInfo = ({ employeeById }) => {
  const initialValues = {
    bankInfos:
      employeeById?.bankInfos?.map((item, index) => ({ ...item, id: index })) ||
      [],
  };

  const [dataIndex, setDataIndex] = useState(
    initialValues?.bankInfos?.at(-1)?.id
  );

  const [formCorrection, setFormCorrection] = useState(false);
  const [requestButton, setRequestButton] = useState(false);
  const formikRef = useRef();

  const onClickCB = () => {
    setFormCorrection(!formCorrection);
  };

  // form change request methods

  function updateData(newObject, oldObject) {
    let updateData = {
      index: newObject.id,
      operation: "update",
      data: {},
    };

    const keys = Object.keys(newObject);
    for (const key of keys) {
      if (newObject[key] !== oldObject[key]) {
        updateData.data[key] = {
          old: newObject[key],
          new: oldObject[key],
        };
      }
    }
    const a = Object.keys(updateData.data);
    if (a.length) {
      return updateData;
    }
  }

  function makeChangesData(formValue, oldValue) {
    let changesData = {
      section: "bank-info",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.bankInfos.forEach((obj1) => {
      const obj2 = oldValue.bankInfos.find((item) => item.id === obj1.id);

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.bankInfos.forEach((obj2) => {
      const obj1 = formValue.bankInfos.find((item) => item.id === obj2.id);

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }
    sendPersonalInfoChanges(changesData);
  }

  function handleSubmit(formValue) {
    makeChangesData(formValue, initialValues);
  }

  // ----------------

  const handleBankSelect = (item) =>
    banksList.find((bank) => bank.name === item);

  // form controller for submitting

  function controlFormForSubmitting(data) {
    controllData(data, initialValues);
  }

  function controllData(formValue, oldValue) {
    let changesData = {
      section: "bank-info",
      changes: [],
    };
    let firstArray = [];
    let secondArray = [];

    formValue.bankInfos.forEach((obj1) => {
      const obj2 = oldValue.bankInfos.find((item) => item.id === obj1.id);

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          data: { ...obj1 },
        });
      } else {
        firstArray.push(obj2);
      }
    });

    oldValue.bankInfos.forEach((obj2) => {
      const obj1 = formValue.bankInfos.find((item) => item.id === obj2.id);

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      } else {
        secondArray.push(obj1);
      }
    });

    for (let i = 0; i < firstArray.length; i++) {
      const data = updateData(firstArray[i], secondArray[i]);
      data && changesData.changes.push(data);
    }
    if (!changesData.changes.length) {
      setRequestButton(false);
    }
  }

  return (
    <>
      {employeeById ? (
        <Grid>
          <Button
            style={
              formCorrection
                ? { backgroundColor: "rgba(121,46,46, 0.9)", marginTop: "10px" }
                : { marginTop: "10px" }
            }
            onClick={onClickCB}
            variant="contained"
            color="secondary"
          >
            {formCorrection ? "Ləğv et" : "Dəyişiklik et"}
            <CreateIcon sx={{ marginLeft: 5 }} />
          </Button>
          <Formik
            initialValues={{
              bankInfos: initialValues.bankInfos || [],
            }}
            validationSchema={bankInfoValidation(employeeById)}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ handleChange, values, setFieldValue, errors }) => {
              return (
                <Form
                  style={
                    !formCorrection
                      ? { pointerEvents: "none" }
                      : { pointerEvents: "auto" }
                  }
                >
                  <FieldArray name="bankInfos">
                    {({ push, remove }) => (
                      <div>
                        {values.bankInfos.map((form, index) => (
                          <div key={index}>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "10px",
                                }}
                              >
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1 + ")"}Bank məlumatları
                                </Typography>
                              </div>
                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  <FormControl fullWidth>
                                    <InputLabel
                                      id={`bankInfos[${index}].bankName`}
                                    >
                                      Banklar
                                    </InputLabel>
                                    <Select
                                      value={values.bankInfos[index].bankName}
                                      onChange={(e) => {
                                        setRequestButton(true);
                                        setFieldValue(
                                          `bankInfos[${index}].bankName`,
                                          e.target.value
                                        );
                                        setFieldValue(
                                          `bankInfos[${index}].bankCode`,
                                          +handleBankSelect(e.target.value).code
                                        );
                                        setFieldValue(
                                          `bankInfos[${index}].bankVoen`,
                                          handleBankSelect(e.target.value).voen
                                        );
                                      }}
                                      name={`bankInfos[${index}].bankName`}
                                      labelId={`bankInfos[${index}].bankName`}
                                      label="Banklar"
                                      id="bankName-select"
                                      fullWidth
                                      error={
                                        errors?.bankInfos?.[index]?.bankName &&
                                        Boolean(
                                          errors?.bankInfos?.[index]?.bankName
                                        )
                                      }
                                    >
                                      {banksList.map((item, index) => (
                                        <MenuItem key={index} value={item.name}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    type="number"
                                    name={`bankInfos[${index}].bankCode`}
                                    as={TextField}
                                    // label="Bank kod"
                                    value={values.bankInfos[index].bankCode}
                                    defaultValue={
                                      values.bankInfos[index].bankCode
                                    }
                                    fullWidth
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Grid>
                              </Grid>

                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    type="text"
                                    name={`bankInfos[${index}].bankVoen`}
                                    as={TextField}
                                    // label="Bank VÖEN"
                                    value={values.bankInfos[index].bankVoen}
                                    defaultValue={
                                      values.bankInfos[index].bankVoen
                                    }
                                    fullWidth
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Grid>
                                <Grid sx={{ width: "100%" }}>
                                  <TextField
                                    onChange={(e) => {
                                      handleChange(e);
                                      setRequestButton(true);
                                    }}
                                    value={
                                      values.bankInfos[index].accountNumber
                                    }
                                    type="text"
                                    name={`bankInfos[${index}].accountNumber`}
                                    label="Hesab nömrəsi"
                                    fullWidth
                                    error={
                                      errors?.bankInfos?.[index]
                                        ?.accountNumber &&
                                      Boolean(
                                        errors?.bankInfos?.[index]
                                          ?.accountNumber
                                      )
                                    }
                                    helperText={
                                      errors?.bankInfos?.[index]
                                        ?.accountNumber &&
                                      errors?.bankInfos?.[index]?.accountNumber
                                    }
                                  />
                                </Grid>
                              </Grid>

                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  <TextField
                                    onChange={(e) => {
                                      handleChange(e);
                                      setRequestButton(true);
                                    }}
                                    value={values.bankInfos[index].iban}
                                    type="text"
                                    name={`bankInfos[${index}].iban`}
                                    label="IBAN"
                                    fullWidth
                                    error={
                                      errors?.bankInfos?.[index]?.iban &&
                                      Boolean(errors?.bankInfos?.[index]?.iban)
                                    }
                                    helperText={
                                      errors?.bankInfos?.[index]?.iban &&
                                      errors?.bankInfos?.[index]?.iban
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <Button
                              sx={
                                formCorrection
                                  ? { visibility: "visible" }
                                  : { visibility: "hidden" }
                              }
                              variant="contained"
                              color="error"
                              type="button"
                              style={{
                                marginBottom: "10px",
                              }}
                              onClick={() => {
                                setRequestButton(true);
                                remove(index);
                                const updatedArray = values.bankInfos.filter(
                                  (item) => item.id !== index
                                );
                                controlFormForSubmitting({
                                  bankInfos: updatedArray,
                                });
                              }}
                            >
                              Ləğv et
                            </Button>
                          </div>
                        ))}

                        <Button
                          sx={
                            formCorrection
                              ? { visibility: "visible" }
                              : { visibility: "hidden" }
                          }
                          variant="contained"
                          color="secondary"
                          style={{ marginBottom: "10px", marginTop: "20px" }}
                          onClick={() => {
                            let newId = dataIndex + 1;
                            setDataIndex(newId);
                            push({
                              id: newId,
                              bankName: "",
                              bankCode: 0,
                              bankVoen: "",
                              accountNumber: "",
                              iban: "",
                            });
                          }}
                        >
                          Əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>

                  <Grid
                    style={
                      formCorrection
                        ? { visibility: "visible" }
                        : { visibility: "hidden" }
                    }
                  >
                    <Button
                      style={
                        !requestButton
                          ? { pointerEvents: "none" }
                          : {
                              backgroundColor: "#28358a",
                              pointerEvents: "auto",
                            }
                      }
                      variant="contained"
                      color="secondary"
                      type="submit"
                    >
                      Dəyişikliklər sorğusu göndər
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default MCViewBankInfo;
