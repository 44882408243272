/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
// Components
// Material UI
import { Add as AddIcon, Edit, PeopleAlt } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
// Images
import CurrencySVG from "../../../vendor/currency.svg";
// Constants
import { useDispatch, useSelector } from "react-redux";
import {
  docModelTypes,
  structureNamingsEnum,
  tempLogoUrl,
} from "../../../constants";
import { GENERAL_BLOCK } from "../../../styled";
import { findPositionById } from "../../../utils/helperFunctions";
import CheckboxInput from "../../layouts/checkbox/Checkbox";
import "./style.css";
import StructureSelectCard from "../structureSelectCard";
import * as companyPartServices from "../../../services/companyPart/CompanyPartServices";
import * as managementServices from "../../../services/management/ManagementServices";
import { updateStructureChild } from "../../../services/structure/StructureServices";
import swal from "sweetalert";
import { setTriggingRefresh } from "../../../redux/features/slices/commonThings.slice";

const BaseLevelAccordion = ({
  classes,
  item,
  createSubStructure,
  createPosition,
  handleViewPositions,
  isClickChangeApproveRequest,
  handleChangeStructureCheckbox,
  handleExportCheckbox,
  structure,
  structureById,
  activePositions,
  checkedItems,
  checkedExportItems
}) => {
  const { isClickExportRequest } = useSelector(
    (state) => state.structure
  );
  const dispatch = useDispatch();
  const common = useSelector((state) => state.commonThings);

  const [isEdit, setIsEdit] = useState(false);

  const makeTitleNameForConditions = (item) => {
    return item?.docModel === docModelTypes.COMPANYPART
      ? item?.name
      : item?.employee
        ? item?.employee?.firstname +
        " " +
        item?.employee?.lastname +
        " " +
        item?.employee?.fathername +
        " - " +
        item?.title
        : item?.title;
  };

  const bgColor = "#E0E0E0";
  const values = [[item?.child?.details?.percentage || 0, "#1565C9"]];

  let totalSalary = item?.child?.details?.totalSalary?.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [structure_btn, set_structure_btn] = useState(0);

  let what_is_contained = {
    staff_table: findPositionById(item?.children)?.length > 0,
    new_position:
      (item?.child?.docModel === docModelTypes.COMPANYPART &&
        item?.child?.editable) ??
      false,
    new_sub_structure: item?.child?.editable ?? false,
    willChange: isClickChangeApproveRequest && !structure?.pendingChange,
  };

  useEffect(() => {
    set_structure_btn(
      Object.keys(what_is_contained).filter(
        (wic) => what_is_contained[wic] === true
      )?.length
    );
  }, [item, isClickChangeApproveRequest, structure]);

  const markedForDeletion_Style = {
    textDecoration: "line-through",
    textDecorationColor: "red",
    textDecorationThickness: "4px",
  };

  const loadOptionsForCompanyParts = async (
    _search,
    loadedOptions,
    item,
    isOnlyUnused,
    isBaseLevel,
    childData
  ) => {
    const _start = loadedOptions.length;

    if (item?.expectedType?.name !== structureNamingsEnum.MANAGEMENT) {
      const { parts, total } = await companyPartServices.getCompanyStructures({
        limit: 10,
        skip: _start,
        name: _search,
        type: item?.expectedType?._id,
        notUsed: isOnlyUnused,
        parentRel: structureById?.organization?._id,
      });
      return {
        options: parts.map((item) => ({
          value: item._id,
          // label: item.name + " - " + generateCapatilazeWord(item?.type?.name),
          label: item.name,
        })),
        hasMore: _start < total - 1,
      };
    } else {
      const { managements, total } = await managementServices.getManagements({
        limit: 10,
        skip: _start,
        title: _search,
        notUsed: isOnlyUnused,
        parentRel: isBaseLevel
          ? structureById?.organization?._id
          : childData?._id,
      });
      return {
        options: managements.map((item) => ({
          value: item._id,
          label: item.employee
            ? item.employee?.firstname +
            " " +
            item.employee?.lastname +
            " " +
            item.employee?.fathername +
            " - " +
            item?.title
            : item?.title,
        })),
        hasMore: _start < total - 1,
      };
    }
  };

  const handleOnChangeStructure = async (partId) => {
    try {
      const { data } = await updateStructureChild({
        structureId: structure._id,
        payload: { relId: item?.child?._id, partId: partId },
      });
      data && setIsEdit(false);
      data && dispatch(setTriggingRefresh(!common.refreshTrigger));
    } catch (error) {
      console.log("err", error);
      swal("Xəta baş verdi", { title: "Xəta" });
    }
  };

  const EditButton = (
    <>
      <IconButton onClick={() => setIsEdit(true)}>
        <Edit sx={{ width: "24px", height: "24px" }} />
      </IconButton>
    </>
  );

  return (
    <>
      <div className="content_wrapper d-flex align-items-center justify-content-between w-100">
        <GENERAL_BLOCK
          // wid={structure?.recruiterStaff || item?.child?.editable ? "50%" : ""}
          className={`w-50 ${(isClickChangeApproveRequest || isClickExportRequest) &&
            "depart-change"
            } d-flex align-center aic`}
        >
          <div className="w-50 d-flex aic">

            {findPositionById(item?.children)?.length > 0 &&
              isClickExportRequest ? (
              <span
                className={`d-inline-block m-1`}
              // ${structure_btn === 1 && "ms-2"}
              >
                <Tooltip title="Eksport üçün seçin">
                  <Checkbox 
                    name={makeTitleNameForConditions(item?.child)}
                    className="p-0"
                    id={item?.child?._id}
                    onChange={handleExportCheckbox}
                    checked={checkedExportItems.some(
                      (checkedItem) => checkedItem === item?.child?._id
                    )} />
                </Tooltip>
              </span>
            ) : null}
            {isClickChangeApproveRequest &&
              (!structure?.pendingChange ? (
                <span className={`d-inline-block me-1`}>
                  <Tooltip title="Dəyişiklik etmək üçün seçin">
                    <Checkbox
                      className="p-0"
                      name={makeTitleNameForConditions(item?.child)}
                      id={item?.child?._id}
                      onChange={handleChangeStructureCheckbox}
                      checked={checkedItems.some(
                        (checkedItem) => checkedItem?.id === item?.child?._id
                      )} />
                  </Tooltip>
                </span>
              ) : (
                <div
                  className="mt-1"
                  style={{ color: "#9ce99c", fontSize: "16px" }}
                >
                  {item?.child?.requestedUser && (
                    <>
                      {item?.child?.requestedUser} hal hazırda dəyişiklik edir
                    </>
                  )}
                </div>
              ))}
            {(!isEdit && item?.child?.editable && !item?.child?.markedForDeletion) && EditButton}
            <GENERAL_BLOCK
              style={
                item?.child?.markedForDeletion ? markedForDeletion_Style : null
              }
              as="h2"
              marg="0"
              className="card_style"
              clr={`${item?.child?.type?.level?.style?.font?.color ?? "#fff"
                } !important`}
              f_size={`${`${item?.child?.type?.level?.style?.font?.size}px` ?? "2rem"
                } !important`}
              f_family={`${item?.child?.type?.level?.style?.font?.name ?? "Arial"
                } !important`}
            >
              {isEdit ? (
                <StructureSelectCard
                  classes={classes}
                  item={item}
                  isExistLeft={true}
                  loadOptionsForCompanyParts={loadOptionsForCompanyParts}
                  handleSaveCompanyParts={(id) => handleOnChangeStructure(id)}
                  // childData={item?.child}
                  // isContentLeft={true}
                  // structure_id={structureById?._id}
                  isEditMode={true}
                  onCancel={() => setIsEdit(false)}
                  bgColor={item?.child?.type?.level?.style?.backgroundColor}
                />
              ) : (
                makeTitleNameForConditions(item?.child)
              )}
            </GENERAL_BLOCK>
          </div>
          <GENERAL_BLOCK
            d_play="grid"
            gtc={`repeat(${structure_btn > 1 ? structure_btn - 1 : (structure_btn && isClickExportRequest) ? 2 : structure_btn
              }, 60%)`}
            gap={structure_btn > 1 ? "10px" : ""}
            className="action_btns ms-3 w-50"
          >
            <>
              {item?.child?.editable && !item?.child?.markedForDeletion ? (
                <Button
                  disabled={isEdit}
                  variant="contained"
                  color="primary"
                  className={`${item?.child?.docModel !== docModelTypes.COMPANYPART
                    ? classes.managament_create_sub_structure_button
                    : ""
                    }`}
                  onClick={(e) => createSubStructure(e, item?.child?._id)}
                >
                  <AddIcon />
                  Yeni Sub-struktur yarat
                </Button>
              ) : null}

              {item?.child?.docModel === docModelTypes.COMPANYPART &&
                item?.child?.editable &&
                !item?.child?.markedForDeletion ? (
                <Button
                  disabled={isEdit}
                  variant="contained"
                  color="primary"
                  onClick={(e) => createPosition(e, item?.child?._id)}
                >
                  <AddIcon />
                  Yeni Vəzifə yarat
                </Button>
              ) : null}

              {findPositionById(item?.children)?.length > 0 ? (
                <Button
                  disabled={isEdit}
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    handleViewPositions(
                      e,
                      findPositionById(item?.children),
                      item
                    );
                  }}
                >
                  <VisibilityIcon className="mx-1" />
                  Ştat cədvəlinə bax
                </Button>
              ) : null}




            </>
          </GENERAL_BLOCK>
        </GENERAL_BLOCK>
        <div className="report d-flex align-items-center">
          {item?.child?.docModel === docModelTypes.COMPANYPART ? (
            <>
              <div className="currency d-flex mx-2">
                <div className="currency_left">
                  <GENERAL_BLOCK f_size="12px" as="span">
                    Aylıq Ə/H Büdcəsi
                  </GENERAL_BLOCK>
                  <Typography variant="h3" className="mb-2">
                    {totalSalary || 0}
                  </Typography>
                </div>

                <GENERAL_BLOCK className="mx-3" f_size="35px" clr="#5e71d1">
                  {" "}
                  <img src={CurrencySVG} width="65%" alt="Currency Image" />
                </GENERAL_BLOCK>
              </div>
              <div className="user d-flex align-items-center mx-2">
                <div className="user_left">
                  <GENERAL_BLOCK f_size="12px" as="span">
                    Ştat Sayı
                  </GENERAL_BLOCK>
                  <Typography variant="h3" className="mb-2">
                    {item?.child?.details?.staffCount || 0}
                  </Typography>
                </div>
                <div className="user_right mx-3">
                  {/* <img src={UserSVG} alt="User Image" /> */}
                  <PeopleAlt color="primary" style={{ fontSize: "35px" }} />
                </div>
              </div>
              <div className="diagram align-items-center mx-2">
                <div>
                  <GENERAL_BLOCK f_size="12px" as="span">
                    Ümumi Ə/H büdcəsindəki payı
                  </GENERAL_BLOCK>
                  <Typography variant="h3" className="mb-2">
                    {item?.child?.details?.percentage || 0} %
                  </Typography>
                </div>
                <div className="ms-3">
                  <LinearProgress
                    variant="determinate"
                    value={item?.child?.details?.percentage || 0}
                  />
                  {/* <Donut
                    values={values}
                    bgColor={bgColor}
                    lineWidth={2.5}
                    height={60}
                    width={60}
                  /> */}
                </div>
              </div>
            </>
          ) : (
            <div className={classes.mr_35}>
              <Avatar alt="Remy Sharp" src={tempLogoUrl} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BaseLevelAccordion;
