import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";

const ReactSelect = ({
  cacheOptions,
  loadOptions,
  placehodler,
  isMultiple = false,
  isSearchable = true,
  isDisabled = false,
  maxMenuHeight = undefined,
  _onChange,
  _isMenuOpen,
  _isMenuClose,
  defaultValue,
  isClearable,
  components,
  isOptionDisabled,
  className,
  value,
  clearValue,
}) => {
  return (
    <>
      <AsyncPaginate
        // defaultValue={defaultValue}
        escapeClearsValue={true}
        cacheOptions={cacheOptions}
        isClearable={isClearable}
        loadOptions={loadOptions}
        onChange={_onChange}
        className={className}
        onMenuOpen={_isMenuOpen}
        onMenuClose={_isMenuClose}
        placeholder={placehodler}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        isMulti={isMultiple}
        maxMenuHeight={maxMenuHeight}
        isOptionDisabled={isOptionDisabled}
        additional={{
          buttonProps: {
            style: {
              backgroundColor: "#3498db", // Background color
              color: "#fff", // Text color
              border: "none", // Border properties
              // Add more styles as needed
            },
          },
        }}
        defaultValue={defaultValue}
        value={value}
        // onInputChange={_onChange}
        // components={components}
        // defaultInputValue={defaultValue}
      />
    </>
  );
};

export default ReactSelect;
