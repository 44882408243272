import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HeaderDialog from "../../../../components/dialogs/HeaderDialog";
import styles from "./GraphicEditModal.module.css";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru";
// import renderErrorEditMessage from "./GraphicEditValidation";

const GraphicEditModal = ({
  handleClose,
  handleOpen,
  open,
  editData,
  handleChange,
  removeInputGroup,
  handleSubmit,
  handleChangeType,
  setNumGroups,
  handleCountInput,
  setEditData,
}) => {
  const [allInputsFilled, setAllInputsFilled] = useState(false);
  const handleChangeLabe = (event) => {
    const selectedValue = parseInt(event.target.value);
    if (selectedValue === 2 || selectedValue === 3 || selectedValue === 4) {
      setNumGroups(selectedValue);
      const newSchedules = Array.from({ length: selectedValue }, (_, index) => {
        if (editData.schedules[index]) {
          return editData.schedules[index];
        } else {
          return { jobStartTime: "", jobEndTime: "" };
        }
      });

      setEditData((prevEditData) => ({
        ...prevEditData,
        schedules: newSchedules,
        turnCount: selectedValue,
      }));
    }
  };

  useEffect(() => {
    let allFilled = false;
    if (editData.scheduleType === "flexible") {
      allFilled = editData.schedules.every(
        (schedule) =>
          schedule.isHoliday || (schedule.jobStartTime && schedule.jobEndTime)
      );
    } else if (editData.scheduleType === "shift") {
      allFilled =
        editData.schedules.every(
          (schedule) => schedule.jobStartTime && schedule.jobEndTime
        ) &&
        editData.workType &&
        editData.turnCount;
    } else if (editData.scheduleType === "standard") {
      allFilled = editData.schedules.every(
        (schedule) =>
          editData.scheduleType &&
          schedule.jobStartTime &&
          schedule.jobEndTime &&
          editData.workType
      );
    }
    setAllInputsFilled(allFilled);
  }, [
    editData.schedules,
    editData.scheduleType,
    editData.workType,
    editData.turnCount,
    editData.isHoliday,
  ]);
  // const hasErrors = () => {
  //   const fields = [
  //     "jobStartTime",
  //     "jobEndTime",
  //     "restStartTime",
  //     "restEndTime",
  //   ];
  //   // for (let i = 0; i < editData.schedules.length; i++) {
  //   //   for (const field of fields) {
  //   //     if (renderErrorEditMessage(i, field, editData)) {
  //   //       return true;
  //   //     }
  //   //   }
  //   // }
  //   return false;
  // };
  const disableButton = !allInputsFilled;
  return (
    <div className={styles.GraphicModal}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.mainBox}>
          <HeaderDialog title={"İş qrafik dəyişiklik"} onClose={handleClose} />
          <Box className={styles.childBox}>
            <form
              onSubmit={(event) => handleSubmit(event)}
              className={styles.graphicForm}
            >
              <div className={styles.graphicFormTop}>
                <FormControl className={styles.graphicFormLeft}>
                  <div className={styles.graphicFormLeftSelect}>
                    <div>
                      <InputLabel id="demo-simple-select-label">
                        Graphic
                      </InputLabel>
                      <Select
                        className={styles.leftSelect}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="scheduleType"
                        value={editData.scheduleType}
                        label="Graphic"
                        onChange={handleChangeType}
                      >
                        <MenuItem value="standard">Standart</MenuItem>
                        <MenuItem value="shift">Növbəli</MenuItem>
                        <MenuItem value="flexible">Sürüşkən</MenuItem>
                      </Select>
                    </div>

                    {editData.scheduleType !== "flexible" && (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel id={`workType_label`}>
                          İş günləri
                        </InputLabel>
                        <Select
                          value={editData.workType}
                          label="İş günləri"
                          name={`workType`}
                          labelId={`workType_label`}
                          id={`workType`}
                          onChange={(event) => handleChange(event)}
                        >
                          <MenuItem value={5}>5 Günlük</MenuItem>
                          <MenuItem value={6}>6 Günlük</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    {editData.scheduleType === "shift" && (
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          Növbələr
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={editData.turnCount}
                          onChange={(event) => handleChangeLabe(event)}
                        >
                          {[2, 3, 4].map((num) => (
                            <FormControlLabel
                              key={num}
                              value={num}
                              control={<Radio />}
                              label={`${num} növbəli`}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </div>
                </FormControl>
                <div className={styles.edit}>
                  {editData &&
                    editData.schedules &&
                    editData.schedules.map((_, index) => (
                      <>
                        <div
                          key={editData._id}
                          className={styles.graphicFormRightMain}
                        >
                          <div className={styles.topInputGroup}>
                            {editData.scheduleType === "shift" && (
                              <label htmlFor="">{`${index + 1} - ${
                                index === 0 || index === 1 ? "ci" : "cü"
                              } növbə`}</label>
                            )}
                            <div className={styles.inptGroup}>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={ruLocale}
                              >
                                <TimePicker
                                  label="Başlama saatı"
                                  value={
                                    editData?.schedules?.[index]?.jobStartTime
                                      ? new Date(
                                          `1970-01-01T${editData.schedules[index].jobStartTime}:00`
                                        )
                                      : null
                                  }
                                  onChange={(newValue) => {
                                    const formattedTime = newValue
                                      ? newValue.toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hourCycle: "h23",
                                        })
                                      : "";
                                    handleChange(
                                      {
                                        target: {
                                          name:
                                            editData.scheduleType === "standard"
                                              ? "jobStartTime"
                                              : `jobStartTime${index}`,
                                          value: formattedTime || "",
                                        },
                                      },
                                      index,
                                      "jobStartTime"
                                    );
                                  }}
                                  disabled={
                                    editData.schedules[index].isHoliday
                                      ? editData.schedules[index].isHoliday
                                      : false
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      disabled={
                                        editData.schedules[index].isHoliday
                                          ? editData.schedules[index].isHoliday
                                          : false
                                      }
                                      className={styles.inpt}
                                    />
                                  )}
                                />
                              </LocalizationProvider>

                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={ruLocale}
                              >
                                <TimePicker
                                  label="Bitmə saatı"
                                  value={
                                    editData?.schedules?.[index]?.jobEndTime
                                      ? new Date(
                                          `1970-01-01T${editData.schedules[index].jobEndTime}:00`
                                        )
                                      : null
                                  }
                                  onChange={(newValue) => {
                                    const formattedTime = newValue
                                      ? newValue.toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hourCycle: "h23",
                                        })
                                      : "";
                                    handleChange(
                                      {
                                        target: {
                                          name:
                                            editData.scheduleType === "standard"
                                              ? "jobEndTime"
                                              : `jobEndTime${index}`,
                                          value: formattedTime || "",
                                        },
                                      },
                                      index,
                                      "jobEndTime"
                                    );
                                  }}
                                  disabled={
                                    editData.schedules[index].isHoliday
                                      ? editData.schedules[index].isHoliday
                                      : false
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      disabled={
                                        (editData.schedules[index] &&
                                          editData.schedules[index]
                                            .isHoliday) ||
                                        false
                                      }
                                      className={styles.inpt}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                            {editData.scheduleType === "flexible" && (
                              <div className={styles.CheckboxBottom}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={
                                          (editData.schedules[index] &&
                                            editData.schedules[index]
                                              .isHoliday) ||
                                          false
                                        }
                                        checked={
                                          (editData.schedules[index] &&
                                            editData.schedules[index]
                                              .isHoliday) ||
                                          false
                                        }
                                        onChange={(event) =>
                                          handleChange(
                                            event,
                                            index,
                                            "isHoliday"
                                          )
                                        }
                                      />
                                    }
                                    label="İstirahət günü"
                                  />
                                </FormGroup>
                                {editData.schedules.length > 1 && index > 0 ? (
                                  <DeleteIcon
                                    onClick={() => removeInputGroup(index)}
                                    className={styles.deleteBtn}
                                  />
                                ) : null}
                              </div>
                            )}
                          </div>
                          <div className={styles.inptGroup}>
                            {editData.scheduleType === "standard" && (
                              <>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  adapterLocale={ruLocale}
                                >
                                  <TimePicker
                                    label="Nahar saatı başlama"
                                    value={
                                      editData?.schedules?.[index]
                                        ?.restStartTime
                                        ? new Date(
                                            `1970-01-01T${editData.schedules[index].restStartTime}:00`
                                          )
                                        : null
                                    }
                                    onChange={(newValue) => {
                                      const formattedTime = newValue
                                        ? newValue.toLocaleTimeString([], {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hourCycle: "h23",
                                          })
                                        : "";
                                      handleChange(
                                        {
                                          target: {
                                            name: "restStartTime",
                                            value: formattedTime || "",
                                          },
                                        },
                                        index,
                                        "restStartTime"
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className={styles.inpt}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  adapterLocale={ruLocale}
                                >
                                  <TimePicker
                                    label="Nahar saatı bitmə"
                                    value={
                                      editData?.schedules?.[index]?.restEndTime
                                        ? new Date(
                                            `1970-01-01T${editData.schedules[index].restEndTime}:00`
                                          )
                                        : null
                                    }
                                    onChange={(newValue) => {
                                      const formattedTime = newValue
                                        ? newValue.toLocaleTimeString([], {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hourCycle: "h23",
                                          })
                                        : "";
                                      handleChange(
                                        {
                                          target: {
                                            name: "restEndTime",
                                            value: formattedTime || "",
                                          },
                                        },
                                        index,
                                        "restEndTime"
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className={styles.inpt}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
              <div className={styles.graphicFormBottom}>
                <Button className={styles.hiddenBtn}></Button>

                {editData.scheduleType === "flexible" && (
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    className={styles.addBtn}
                    onClick={handleCountInput}
                  >
                    Əlavə et
                  </Button>
                )}

                <Button
                  variant="contained"
                  type="submit"
                  disabled={disableButton}
                >
                  Dəyişiklikləri əlavə et
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default GraphicEditModal;
