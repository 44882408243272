/**
 * @param type: 0 - standart button; 1 - with dropdown button
 * @param dropdownComponent: if type === 1 then need dropdown component
 */
class Operator {
  constructor({
    title,
    icon,
    onClick,
    type = 0,
    dropdownComponent,
    dropdownPositionRight,
    styleBtn,
    styleBtnContainer,
    styleContainer,
    hideCondition,
    tooltip = false,
  }) {
    this.title = title;
    this.onClick = onClick;
    this.icon = icon;
    this.tooltip = tooltip;
    /**
     * @param type: 0 - standart button; 1 - dropdown button
     */
    this.type = type;
    this.dropdownComponent = dropdownComponent;
    this.styleBtn = styleBtn;
    this.styleBtnContainer = styleBtnContainer;
    this.styleContainer = styleContainer;
    this.dropdownPositionRight = dropdownPositionRight;
    this.hideCondition = hideCondition;
  }
}

export default Operator;
