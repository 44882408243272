import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay } from "date-fns";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { getAllStructures, getCommonData } from "../../api/api";

const today = endOfDay(new Date());

function ViewLaborActivity({ employeeById }) {
  const [organizations, setOrganizations] = useState([]);
  const [positions, setPositions] = useState([]);
  const [otherStructures, setOtherStructures] = useState([]);
  const [mainStructures, setMainStructures] = useState([]);

  useEffect(() => {
    const handleApi = async () => {
      try {
        const organizationsResponse = await getCommonData("organizations");
        const positionResponse = await getCommonData("positions");
        const otherStructures = await getCommonData("structures");
        const mainStructures = await getAllStructures();

        setPositions(positionResponse.data);
        setOtherStructures(otherStructures.data);
        setOrganizations(organizationsResponse.data);
        setMainStructures(mainStructures.data?.structures);
      } catch (error) {
        console.log("err", error);
      }
    };
    handleApi();
  }, []);

  const initialValues = {
    laborActivity: employeeById.laborActivity || [],
  };

  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            laborActivity: initialValues.laborActivity || [], // Set the initial laborActivity values from employeeById
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            handleChange,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="laborActivity">
                {({ push, remove }) => (
                  <div>
                    {values.laborActivity.map((form, index) => {
                      return (
                        <div key={index}>
                          <>
                            <Typography sx={{ mt: 5 }} variant="h6">
                              {index + 1 + ")"}Əmək fəaliyyəti
                            </Typography>

                            {/* Organization name and department */}
                            <Grid
                              sx={{ display: "flex", gap: 3, mb: 3, mt: 3 }}
                            >
                              <Grid sx={{ width: "100%" }}>
                                {values.laborActivity[index]?.sameWorkplace ? (
                                  <FormControl
                                    error={
                                      errors?.laborActivity?.[index]
                                        ?.organizationName &&
                                      Boolean(
                                        errors?.laborActivity?.[index]
                                          ?.organizationName
                                      )
                                    }
                                    fullWidth
                                  >
                                    <InputLabel
                                      id={`laborActivity[${index}].organizationName`}
                                    >
                                      Təşkilatın adı *
                                    </InputLabel>
                                    <Field
                                      as={Select}
                                      name={`laborActivity[${index}].organizationName`}
                                      labelId={`laborActivity[${index}].organizationName`}
                                      label="Təşkilatın adı *"
                                      id={`laborActivity[${index}].organizationName`}
                                      fullWidth
                                    >
                                      <MenuItem
                                        selected
                                        value={
                                          employeeById?.laborActivity[index]
                                            ?.organizationName
                                        }
                                      >
                                        {
                                          employeeById?.laborActivity[index]
                                            ?.organizationName
                                        }
                                      </MenuItem>
                                      {mainStructures.map((item, index) => (
                                        <MenuItem
                                          value={
                                            employeeById?.laborActivity[index]
                                              ?.organizationName
                                          }
                                        >
                                          {
                                            employeeById?.laborActivity[index]
                                              ?.organizationName
                                          }
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </FormControl>
                                ) : (
                                  <FormControl
                                    error={
                                      errors?.laborActivity?.[index]
                                        ?.organizationName &&
                                      Boolean(
                                        errors?.laborActivity?.[index]
                                          ?.organizationName
                                      )
                                    }
                                    fullWidth
                                  >
                                    <InputLabel
                                      id={`laborActivity[${index}].organizationName`}
                                    >
                                      Təşkilatın adı *
                                    </InputLabel>
                                    <Field
                                      as={Select}
                                      name={`laborActivity[${index}].organizationName`}
                                      labelId={`laborActivity[${index}].organizationName`}
                                      label="Təşkilatın adı"
                                      id={`laborActivity[${index}].organizationName`}
                                      fullWidth
                                    >
                                      <MenuItem
                                        selected
                                        value={
                                          employeeById?.laborActivity[index]
                                            ?.organizationName
                                        }
                                      >
                                        {
                                          employeeById?.laborActivity[index]
                                            ?.organizationName
                                        }
                                      </MenuItem>
                                      {organizations.map((item, index) => (
                                        <MenuItem
                                          value={
                                            employeeById?.laborActivity[index]
                                              ?.organizationName
                                          }
                                        >
                                          {
                                            employeeById?.laborActivity[index]
                                              ?.organizationName
                                          }
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </FormControl>
                                )}
                              </Grid>

                              <Grid sx={{ width: "100%" }}>
                                <FormControl
                                  error={
                                    errors?.laborActivity?.[index]
                                      ?.department &&
                                    Boolean(
                                      errors?.laborActivity?.[index]?.department
                                    )
                                  }
                                  fullWidth
                                >
                                  <InputLabel
                                    id={`laborActivity[${index}].department`}
                                  >
                                    Struktur bölmə *
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={`laborActivity[${index}].department`}
                                    labelId={`laborActivity[${index}].department`}
                                    label="Struktur bölmə *"
                                    id={`laborActivity[${index}].department`}
                                    fullWidth
                                  >
                                    <MenuItem
                                      selected
                                      value={
                                        employeeById?.laborActivity[index]
                                          ?.department
                                      }
                                    >
                                      {
                                        employeeById?.laborActivity[index]
                                          ?.department
                                      }
                                    </MenuItem>
                                    {otherStructures.map((item, index) => (
                                      <MenuItem
                                        value={
                                          employeeById?.laborActivity[index]
                                            ?.department
                                        }
                                      >
                                        {
                                          employeeById?.laborActivity[index]
                                            ?.department
                                        }
                                      </MenuItem>
                                    ))}
                                  </Field>
                                </FormControl>
                              </Grid>
                            </Grid>

                            {/* Position name and Civil Servant */}
                            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                              <Grid sx={{ width: "100%" }}>
                                <FormControl
                                  error={
                                    errors?.laborActivity?.[index]?.position &&
                                    Boolean(
                                      errors?.laborActivity?.[index]?.position
                                    )
                                  }
                                  fullWidth
                                >
                                  <InputLabel
                                    id={`laborActivity[${index}].position`}
                                  >
                                    Vəzifə
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={`laborActivity[${index}].position`}
                                    labelId={`laborActivity[${index}].position`}
                                    label="Vəzifə"
                                    id={`laborActivity[${index}].position`}
                                    defaultValue=""
                                    fullWidth
                                  >
                                    <MenuItem
                                      selected
                                      value={
                                        employeeById?.laborActivity[index]
                                          ?.position
                                      }
                                    >
                                      {
                                        employeeById?.laborActivity[index]
                                          ?.position
                                      }
                                    </MenuItem>
                                    {positions.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={
                                          employeeById?.laborActivity[index]
                                            ?.position
                                        }
                                      >
                                        {
                                          employeeById?.laborActivity[index]
                                            ?.position
                                        }
                                      </MenuItem>
                                    ))}
                                  </Field>
                                </FormControl>
                              </Grid>

                              <Grid sx={{ width: "100%" }}>
                                <FormControl
                                  fullWidth
                                  error={
                                    errors?.laborActivity?.[index]
                                      ?.isCivilServant &&
                                    Boolean(
                                      errors?.laborActivity?.[index]
                                        ?.isCivilServant
                                    )
                                  }
                                >
                                  <InputLabel
                                    id={`laborActivity[${index}].isCivilServant`}
                                  >
                                    Dövlət qulluqçusu
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={`laborActivity[${index}].isCivilServant`}
                                    labelId={`laborActivity[${index}].isCivilServant`}
                                    label="Dövlət qulluqçusu"
                                    value={
                                      employeeById?.laborActivity[index]
                                        ?.isCivilServant
                                    }
                                    id={`laborActivity[${index}].isCivilServant-select`}
                                    defaultValue=""
                                    fullWidth
                                    error={
                                      errors?.laborActivity?.[index]
                                        ?.isCivilServant &&
                                      Boolean(
                                        errors?.laborActivity?.[index]
                                          ?.isCivilServant
                                      )
                                    }
                                  >
                                    <MenuItem value={true}>Bəli</MenuItem>
                                    <MenuItem value={false}>Xeyr</MenuItem>
                                  </Field>
                                  {errors.laborActivity?.[index]
                                    ?.isCivilServant &&
                                    errors.laborActivity?.[index]
                                      ?.isCivilServant && (
                                      <FormHelperText error>
                                        {
                                          errors.laborActivity?.[index]
                                            ?.isCivilServant
                                        }
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                            </Grid>

                            {/* Job start and end date */}
                            <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                              <Field name={`laborActivity[${index}].startDate`}>
                                {({ field, form }) => {
                                  const endDate =
                                    form.values.laborActivity[index]?.endDate;

                                  return (
                                    <DatePicker
                                      {...field}
                                      label="İşə başlama tarixi"
                                      inputFormat="dd/MM/yyyy"
                                      value={field.value}
                                      onChange={(date) => {
                                        if (
                                          date instanceof Date &&
                                          !isNaN(date)
                                        ) {
                                          form.setFieldValue(
                                            `laborActivity[${index}].startDate`,
                                            date
                                          );
                                        } else {
                                          form.setFieldValue(
                                            `laborActivity[${index}].startDate`,
                                            ""
                                          );
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          helperText={
                                            form.errors?.laborActivity?.[index]
                                              ?.startDate
                                          }
                                          error={Boolean(
                                            form.errors.laborActivity?.[index]
                                              ?.startDate
                                          )}
                                          fullWidth
                                        />
                                      )}
                                      maxDate={
                                        endDate ? new Date(endDate) : today
                                      }
                                      minDate={new Date(1940, 0, 1)}
                                      disableFuture
                                      clearable
                                      clearText="Clear"
                                    />
                                  );
                                }}
                              </Field>

                              {values.laborActivity[index]?.sameWorkplace ? (
                                <Field
                                  type="text"
                                  name="endDate"
                                  as={TextField}
                                  fullWidth
                                  label="İşin bitmə tarixi"
                                  value="---"
                                  error={
                                    errors.endDate && Boolean(errors.endDate)
                                  }
                                  helperText={errors.endDate && errors.endDate}
                                  required
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              ) : (
                                <Field name={`laborActivity[${index}].endDate`}>
                                  {({ field, form }) => {
                                    const startDate =
                                      form.values.laborActivity[index]
                                        ?.startDate;

                                    return (
                                      <DatePicker
                                        {...field}
                                        label="İşin bitmə tarixi"
                                        inputFormat="dd/MM/yyyy"
                                        value={field.value}
                                        onChange={(date) => {
                                          if (
                                            date instanceof Date &&
                                            !isNaN(date)
                                          ) {
                                            form.setFieldValue(
                                              `laborActivity[${index}].endDate`,
                                              date
                                            );
                                          } else {
                                            form.setFieldValue(
                                              `laborActivity[${index}].endDate`,
                                              ""
                                            );
                                          }
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            helperText={
                                              form.errors?.laborActivity?.[
                                                index
                                              ]?.endDate
                                            }
                                            error={Boolean(
                                              form.errors.laborActivity?.[index]
                                                ?.endDate
                                            )}
                                            fullWidth
                                          />
                                        )}
                                        maxDate={today}
                                        disableFuture
                                        clearable
                                        clearText="Clear"
                                        minDate={
                                          startDate
                                            ? new Date(startDate)
                                            : undefined
                                        }
                                        minDateMessage="End date should not be before the start date"
                                      />
                                    );
                                  }}
                                </Field>
                              )}
                            </Grid>

                            {/* Service period and same workplace */}
                            <Grid
                              sx={{
                                display: "flex",
                                gap: 3,
                                alignItems: "center",
                              }}
                            >
                              <Grid
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  ml: 2,
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        values.laborActivity[index]
                                          ?.sameWorkplace || false
                                      }
                                      onChange={handleChange}
                                      name={`laborActivity[${index}].sameWorkplace`}
                                      color="primary"
                                    />
                                  }
                                  label="Bu təşkilatda"
                                />
                                <ErrorMessage
                                  name={`laborActivity[${index}].sameWorkplace`}
                                  component="div"
                                />
                              </Grid>
                            </Grid>
                          </>
                        </div>
                      );
                    })}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default ViewLaborActivity;
