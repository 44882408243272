import React from "react";
import styled from "@emotion/styled";
import { MoreVertical } from "react-feather";

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

// Data
let id = 0;
function createData(name, start, end, state, assignee) {
  id += 1;
  return { id, name, start, end, state, assignee };
}

const rows = [
  createData(
    "Tahir Şıxlarov",
    "24/06/2024",
    "31/06/2024",
    <Chip label="Təsdiq" color="success" />,
    "Emil Tacıyev"
  ),
  createData(
    "İbrahim Məmmədov",
    "08/07/2024",
    "14/07/2024",
    <Chip label="Gözlənilir" color="warning" />,
    "Emil Tacıyev"
  ),
  createData(
    "Tariyel Hümbətov",
    "01/08/2024",
    "20/08/2024",
    <Chip label="Təsdiq" color="success" />,
    "Elgün Abbasquliyev"
  ),
  createData(
    "Mehdi Məmmədzadə",
    "20/08/2024",
    "05/09/2024",
    <Chip label="İmtina" color="error" />,
    "Tamerlan Fərzullalı"
  ),
  createData(
    "Əli Ramazanov",
    "10/08/2024",
    "24/08/2024",
    <Chip label="Təsdiq" color="success" />,
    "Elgün Abbasquliyev"
  ),
  createData(
    "Nihat Babayev",
    "15/06/2024",
    "23/06/2024",
    <Chip label="Təsdiq" color="success" />,
    "Tamerlan Fərzullalı"
  ),
];

const DashboardTable = () => (
  <Card mb={6}>
    <CardHeader title="Məzuniyyət sorğuları" />
    <Paper>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ad Soyad</TableCell>
              <TableCell>Başlama Tarixi</TableCell>
              <TableCell>Bitmə Tarixi</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Təsdiq Edən</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.start}</TableCell>
                <TableCell>{row.end}</TableCell>
                <TableCell>{row.state}</TableCell>
                <TableCell>{row.assignee}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </Paper>
  </Card>
);

export default DashboardTable;
