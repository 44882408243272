import { api } from "../../api/axiosInterceptor";

// GET: // employee/p/:type?limit=10&offset=0 -> Get Company Structure
export const getCompanyStructures = async (payload) => {
  try {
    const response = await api.get(`/employee/p/${payload.type}`, {
      params: payload.includedId
        ? {
            limit: payload.limit,
            offset: payload.skip,
            name: payload.name || "",
            onlyUnused: payload.notUsed || false,
            parentRel: payload.parentRel || undefined,
            includedId: payload.includedId,
          }
        : {
            limit: payload.limit,
            offset: payload.skip,
            name: payload.name || "",
            onlyUnused: payload.notUsed || false,
            parentRel: payload.parentRel || undefined,
          },
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
