import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const checkUniqueness = (
  data,
  selectedItem,
  event,
  setFieldValue,
  fieldName,
  errorName,
  handleValueSelect
) => {
  if (event.target.value) {
    const selectedType = event.target.value;
    const selectedArray = data.map((item) => item[selectedItem]);
    const uniqueSelectedArray = new Set(selectedArray);

    if (!uniqueSelectedArray.has(selectedType)) {
      setFieldValue(`${fieldName}.${selectedItem}`, selectedType);
      if (handleValueSelect) {
        setFieldValue(
          `${fieldName}.value`,
          handleValueSelect(selectedType)?.value
        );
        if (errorName === "əməkhaqqına əlavənin səbəbi") {
          setFieldValue(
            `${fieldName}.valueType`,
            handleValueSelect(selectedType)?.valueType
          );
        }
      }
    } else {
      // Display an error message if the statusType is a duplicate
      setFieldValue(`${fieldName}`, "");
      if (handleValueSelect) {
        setFieldValue(`${fieldName}.value`, "");
        if (errorName === "əməkhaqqına əlavənin səbəbi") {
          setFieldValue(`${fieldName}.valueType`, "");
        }
      }
      toast.error(`Eyni ${errorName} əlavə oluna bilməz.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }
};

export const checkUniquenessForNext = (
  data,
  selectedItems,
  errorNames,
  validateForm,
  handleNext
) => {
  let hasDuplicates = false;
  if (Array.isArray(selectedItems)) {
    hasDuplicates = selectedItems.some((selectedItem, index) => {
      const uniqueArray = new Set();
      for (let i = 0; i < data.length; i++) {
        const item = data[i][selectedItem];
        if (item && uniqueArray.has(item)) {
          toast.error(
            `Bu ${errorNames[index]} artıq istifadə edilib və ya eyni ${errorNames[index]} yalnız bir inventarda istifadə edilə bilər.`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          return true;
        } else {
          uniqueArray.add(item);
        }
      }
      return false;
    });
  } else {
    const uniqueArray = new Set();
    for (let i = 0; i < data.length; i++) {
      const item = data[i][selectedItems];
      if (item && uniqueArray.has(item)) {
        toast.error(`Eyni ${errorNames} əlavə oluna bilməz.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        hasDuplicates = true;
        break;
      } else {
        uniqueArray.add(item);
      }
    }
  }

  if (!hasDuplicates) {
    if (validateForm) {
      validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          handleNext();
        }
      });
    }
  }
  return hasDuplicates;
};
