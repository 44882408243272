import { Card, CardContent, Grid, Pagination, Typography } from "@mui/material";
import React from "react";
import { EMPLOYEE_REQUEST_TYPE_NORMALIZED_NAME } from "../../constants";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DocumentDialog from "../dialogs/documentDialog/DocumentDialog";
import { getEmployeeRequestManager } from "../../services/vacation/VacationRequest";
import moment from "moment";
import { yellow } from "@mui/material/colors";
import {
  CheckCircle,
  DoNotDisturbOn,
  Error,
  ReplayCircleFilled,
} from "@mui/icons-material";

export const SystemRequestCardItem = (props) => {
  const {
    limit,
    handlePagination,
    setdialogTitle,
    setDialogColor,
    setDialogContent,
    dialogHandler,
    pagination,
    approver,
    data,
    total,
    type, // pending, approved, rejected, reRequested
    submit,
    setShow,
  } = props;

  const findStatus = (item, requestStatus) => {
    const findedRequest = {
      firstname: item.approvementChain.find(
        (employee) => employee.status === requestStatus
      )?.staff.employee[0].firstname,
      lastname: item.approvementChain.find(
        (employee) => employee.status === requestStatus
      )?.staff.employee[0].lastname,
      decisionDate: item.approvementChain.find(
        (employee) => employee.status === requestStatus
      )?.decisionDate,
    };

    return findedRequest;
  };

  const getEmployeeDetails = (arr) => {
    const foundedChainItem = arr
      .slice()
      .reverse()
      .find((item) => item.status === "approved");

    if (!foundedChainItem) return "";

    const { firstname, lastname } = foundedChainItem?.staff?.employee[0];
    const position = foundedChainItem?.staff?.position?.name;

    return `${firstname} ${lastname} (${position})`;
  };

  const cardTitles = {
    pending: "Yeni Sorğular",
    approved: "Təsdiq edilmiş sorğular",
    rejected: "İmtina edilmiş sorğular",
    reRequested: "Yenidən göndərilmiş sorğular",
  };

  const cardStatusIcon = {
    pending: <Error sx={{ width: 30, height: 30 }} color="primary" />,
    approved: <CheckCircle sx={{ width: 30, height: 30 }} color="success" />,
    rejected: <DoNotDisturbOn sx={{ width: 30, height: 30 }} color="error" />,
    reRequested: (
      <ReplayCircleFilled sx={{ width: 30, height: 30 }} color="warning" />
    ),
  };

  const dynamicPart = (item) => {
    return type === "pending"
      ? `İlk təsdiqləyici şəxs - ${getEmployeeDetails(item.approvementChain)}`
      : type === "approved"
      ? `Sorğunun Təsdiq Tarixi - 
          ${
            moment(item?.contract?.decisionDate).format("DD/MM/yyyy") ||
            "--/--/----"
          }`
      : type === "rejected"
      ? `Sorğunun imtina tarixi - ${moment(
          findStatus(item, type).decisionDate
        ).format("DD/MM/yyyy")}`
      : `Sorğuya düzəliş tarixi - ${moment(
          findStatus(item, type).decisionDate
        ).format("DD/MM/yyyy")}`;
  };

  const whenNoApprover = (item) => {
    getEmployeeDetails(item.approvementChain) && (
      <Typography>
        {type === "approved"
          ? "Sorğunu Təsdiq Edən -"
          : type === "rejected"
          ? "Sorğunun təsdiqindən imtina edən -"
          : "Yönləndirildiyi şəxs -"}{" "}
        {getEmployeeDetails(item.approvementChain)}
      </Typography>
    );
  };

  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 5,
            height: 60,
          }}
        >
          <Typography variant="h4">{cardTitles[type]}</Typography>

          {cardStatusIcon[type]}
        </Grid>
        <Grid>
          {data?.length === 0 && (
            <Typography color={yellow[500]} sx={{ mb: 8, mt: 10 }} variant="h6">
              Göstəriləcək sorğu yoxdur
            </Typography>
          )}
          {data?.map((item, index) => (
            <Grid
              sx={{
                p: 3,
                border: 1,
                borderRadius: 1,
                borderColor: "#66666600",
                mb: 5,
                backgroundColor:
                  type === "pending"
                    ? "rgba(15, 76, 117, 0.5)"
                    : type === "approved"
                    ? "rgba(27, 94, 32, 0.5)"
                    : type === "rejected"
                    ? "rgba(198, 40, 40, 0.5)"
                    : "rgba(249, 237, 105, 0.5)",
              }}
              key={index}
            >
              <Grid mb={1}>
                <Typography
                  sx={{
                    color: "#fff",
                    textAlign: "center",
                    borderBottom: "1px solid rgba(255,255,255, 0.1)",
                    fontSize: "14px ",
                  }}
                >
                  {EMPLOYEE_REQUEST_TYPE_NORMALIZED_NAME[item.docModel]}
                </Typography>
              </Grid>
              <Typography>Sorğu NO - {item?.requestNO}</Typography>
              <Typography>
                Sorğu Növü -{" "}
                {/* {item.docModel === "VacationRequest"
                  ? "Məzuniyyət / İşdən azadolma / İşə təyin olma"
                  : null} */}
                {EMPLOYEE_REQUEST_TYPE_NORMALIZED_NAME[item.docModel]}
              </Typography>
              <Typography>
                Sorğunun Yaradılma Tarixi -{" "}
                {moment(item?.originalRequest?.createdAt).format("DD/MM/yyyy")}
              </Typography>
              <Typography>{dynamicPart(item)}</Typography>
              {approver ? (
                <Grid>
                  <Typography>
                    Sorğu Yaradan -{" "}
                    {item.creator?.firstname +
                      " " +
                      item.creator?.lastname +
                      " ( " +
                      item.creator?.position?.name +
                      " ) "}
                  </Typography>
                  <Typography>
                    Subyektin adı soyadı -{" "}
                    {item.originalRequest?.employee?.firstname &&
                      item.originalRequest?.employee?.lastname &&
                      `${item.originalRequest.employee.firstname} ${item.originalRequest.employee.lastname}`}
                  </Typography>
                  <Typography>
                    Struktur -{" "}
                    {item.decisionChain
                      ? item.decisionChain.topCompanyPartRel.source.name
                      : ""}
                  </Typography>
                </Grid>
              ) : (
                <>{whenNoApprover(item)}</>
              )}
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 2,
                }}
              >
                <RemoveRedEyeIcon
                  onClick={(e) => {
                    setdialogTitle("Yenidən göndərdiyim sorğu");
                    setDialogColor("#8F8F59");
                    setDialogContent(item);
                    dialogHandler(item._id);
                    // setShow(true);
                  }}
                  sx={{
                    cursor: "pointer",
                    transition: "all 0.3s",
                    "&:hover": {
                      color: "#aaa",
                    },
                  }}
                />
                {/* <DocumentDialog
                  documentId={item._id}
                  fetchHandler={getEmployeeRequestManager}
                  submit={submit} // submit in comment dialog for approve/reject endpoint
                /> */}
              </Grid>
            </Grid>
          ))}
        </Grid>
        {data?.length > 0 && (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              marginTop: 2,
            }}
          >
            <Pagination
              size="small"
              count={Math.ceil(total / limit)}
              variant="outlined"
              shape="rounded"
              color="secondary"
              page={pagination?.[type]} // ex: pagination?.approved
              onChange={(event, value) => {
                pagination?.set((pagination) => ({
                  ...pagination[type],
                  value,
                }));
                handlePagination(value, "approved");
              }}
            />
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
