import { api } from "../axiosInterceptor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeEmptyStrings } from "../../utils/removeEmptyString";

export const getAllBoards = async () => {
  try {
    const response = await api.get("task/board");

    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getBoardData = async (id) => {
  try {
    const response = await api.get(`task/board/${id}`);
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// ** Board APIS

export const createBoard = async (data) => {
  try {
    const response = await api.post(`task/board`, data);
    toast.success("Successfully added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const editBoard = async (id, data) => {
  try {
    const response = await api.put(`task/board/${id}`, data);
    toast.success("Successfully edited", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteBoard = async (id) => {
  try {
    const response = await api.delete(`task/board/${id}`);
    toast.success("Successfully deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};
export const deleteLabel = async (id) => {
  try {
    const response = await api.delete(`task/label/${id}`);
    toast.success("Successfully deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// ** List APIS
export const createList = async (data) => {
  try {
    const response = await api.post(`task/list`, data);
    toast.success("Successfully added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err?.response?.data?.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const editList = async (id, data) => {
  try {
    const response = await api.put(`task/list/${id}`, data);
    toast.success("Successfully edited", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteList = async (id) => {
  try {
    const response = await api.delete(`task/list/${id}`);
    toast.success("Successfully deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const moveList = async (listId, data) => {
  try {
    const response = await api.put(`task/list/${listId}/move`, data);
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// ** Card APIS

export const getCardDetails = async (id) => {
  try {
    const response = await api.get(`task/card/${id}`);
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const createListCard = async (data) => {
  try {
    const response = await api.post(`task/card`, data);
    toast.success("Successfully added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const moveBoardData = async (cardId, data) => {
  try {
    const response = await api.put(`task/card/${cardId}/move`, data);
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const assignMembers = async (cardId, data) => {
  try {
    const response = await api.put(`task/card/${cardId}/updateMembers`, data);
    toast.success("Members added successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const updateCard = async (cardId, data) => {
  try {
    const response = await api.put(`task/card/${cardId}`, data);
    toast.success("Card updated successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const addCommentToCard = async (cardId, data) => {
  try {
    const response = await api.post(`task/card/${cardId}/comment`, data);
    toast.success("Successfully added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const editCardComment = async (cardId, commentId, data) => {
  try {
    const response = await api.put(
      `task/card/${cardId}/comment/${commentId}`,
      data
    );
    toast.success("Comment updated successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const removeComment = async (cardId, commentId) => {
  try {
    const response = await api.delete(
      `task/card/${cardId}/comment/${commentId}`
    );
    toast.success("Successfully deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// ** Checklist

export const addChecklist = async (data) => {
  try {
    const response = await api.post("task/checklist", data);
    toast.success("Successfully added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const editChecklist = async (id, data) => {
  try {
    const response = await api.put(`task/checklist/${id}`, data);
    toast.success("Successfully edited", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const removeChecklist = async (id) => {
  try {
    const response = await api.delete(`task/checklist/${id}`);
    toast.success("Successfully deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const addChecklistItem = async (data) => {
  try {
    const response = await api.post("task/checklistItem", data);
    toast.success("Successfully added", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const updateChecklistItem = async (id, data) => {
  try {
    const response = await api.put(`task/checklistItem/${id}`, data);
    toast.success("Successfully edited", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const toggleCompletedStatus = async (id, data) => {
  try {
    const response = await api.patch(
      `task/checklistItem/${id}/completed`,
      data
    );
    toast.success("Successfully operated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const removeChecklistItem = async (id) => {
  try {
    const response = await api.delete(`task/checklistItem/${id}`);
    toast.success("Successfully deleted", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// ** Label APIS
export const createLabel = async (data) => {
  try {
    const response = await api.post(`task/label`, data);
    toast.success("Label added successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getAllLabels = async (boardId) => {
  try {
    const response = await api.get(`task/label`, {
      params: { boardId: boardId },
    });

    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const toggleLabel = async (cardId, data) => {
  try {
    const response = await api.patch(`task/card/${cardId}/toggleLabel`, data);
    toast.success("Successfully assigned", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// ** Attachment APIS
export const uploadAttachment = async (cardId, data) => {
  try {
    const response = await api.post(`task/card/${cardId}/attachment/add`, data);
    toast.success("Attachment added successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const updateAttachment = async (cardId, attachmentId, data) => {
  try {
    const response = await api.put(
      `task/card/${cardId}/attachment/${attachmentId}`,
      data
    );
    toast.success("Attachment updated successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const removeAttachment = async (cardId, attachmentId) => {
  try {
    const response = await api.delete(
      `task/card/${cardId}/attachment/${attachmentId}`
    );
    toast.success("Attachment deleted successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};
