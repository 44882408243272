import React, { useEffect, useState } from "react";

import Table from "../../components/table/Table";
import Column from "../../models/table/Column";
import { getCurrentMonthRange } from "../../utils/helperFunctions";

import { Helmet } from "react-helmet-async";

import { spacing } from "@mui/system";
import { Typography, Divider as MuiDivider } from "@mui/material";
import styled from "@emotion/styled";
import { getAccountingTable_SERVICE } from "../../services/accountingTableServices";
import { getStaticFilterValues } from "../../utils/forFilterDropdown/getStructureUtils";
import {
  bgColorHandler,
  COLORS_STATUS,
  isLocked,
  OPERATORS_CONTAINING,
} from "../../constants";
import { GENERAL_BLOCK } from "../../styled";
import SelectInput from "../../components/ui/Select/SelectInput";
import { getCompanyStructures } from "../../services/structure/StructureServices";
import { downloadFile } from "../../utils/downloadCSV";
import { getDownloadTimesheet } from "./services";

const Divider = styled(MuiDivider)(spacing);

const TimesheetTable = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [data, setData] = useState([]);
  const [columns, set_columns] = useState([]);
  const currentMonthRange = getCurrentMonthRange();
  const [structureDatas, setStructureDatas] = useState({
    total: 0,
    structures: [],
  });
  const [currentStructure, setCurrentStructure] = useState();
  const [currentStructureId, setCurrentStructureId] = useState();
  let enums = {
    labourVacation: "Ə/M",
    unpaidVacation: "Ö/M",
    educationalAndCreativeVacation: "T/M",
    socialVacation: "S/M",
    temporaryLoss: "X/V",
    unknownReason: "N/S",
    training: "T/K",
    businessTrip: "E",
    restDay: "İ",
    holiday: "B",
    mourningDay: "H",
    nonWorkingDay: "Q",
    workDay: "",
  };

  useEffect(() => {
    const static_columns = [
      new Column({
        path: "staff.employee.tableNumber",
        headerName: "Tabel No.",
        widthOfColumn: 100,
        // renderCell: (row, col) => {
        //   // console.log(row);
        //   return row.staff?.employee[0]?.tableNumber;
        // },
        filterType: OPERATORS_CONTAINING.TEXT,
      }),
      new Column({
        path: "isLocked",
        headerName: "Status",
        widthOfColumn: 130,
        filterType: OPERATORS_CONTAINING.OPTION,
        filterValues: isLocked,
        dataGridName: "time-table",
        renderCell: (row) => {
          return (
            <div className="text-center">
              {row?.isLocked ? "Tabel bağlıdır" : "Tabel aktivdir"}
            </div>
          );
        },
      }),
      new Column({
        path: "staff.employee.fullname",
        headerName: "Əməkdaşın A.S.A.",
        widthOfColumn: 200,
        filterType: OPERATORS_CONTAINING.TEXT,
        dataGridName: "time-table",
        renderCell: (row, col) => {
          // console.log(row);
          return (
            <div className="text-center">{`${row.staff?.employee?.firstname} ${row.staff?.employee?.lastname} ${row.staff?.employee?.fathername}`}</div>
          );
        },
      }),
      new Column({
        path: "parentPart.source.name",
        headerName: "Əsas struktur",
        widthOfColumn: 200,
        filterColumnKey: "parentPart._id",
        dependencieKeysOfFilter: {
          structures: ["baseStructure._id"],
          topPartOfStructures: ["topPartOfStructure._id"],
        },
        dataGridName: "time-table",
        fetchFilterValuesV2: getStaticFilterValues,
        keyForResponseDataFromFilter: "topPartOfStructures",
        filterType: OPERATORS_CONTAINING.IN,
      }),
      new Column({
        path: "subStructure.source.name",
        headerName: "Sub-struktur",
        widthOfColumn: 200,
        filterColumnKey: "subStructure._id",
        dependencieKeysOfFilter: {
          structures: ["baseStructure._id"],
          topPartOfStructures: ["topPartOfStructure._id"],
        },
        dataGridName: "time-table",
        fetchFilterValuesV2: getStaticFilterValues,
        keyForResponseDataFromFilter: "topPartOfStructures",
        filterType: OPERATORS_CONTAINING.IN,
      }),
      new Column({
        path: "staff.position.name",
        headerName: "Vəzifə",
        widthOfColumn: 150,
        filterColumnKey: "staff.part._id",
        dependencieKeysOfFilter: {
          structures: ["baseStructure._id"],
          topPartOfStructures: ["topPartOfStructure._id"],
        },
        dataGridName: "time-table",
        fetchFilterValuesV2: getStaticFilterValues,
        keyForResponseDataFromFilter: "positions",
        filterType: OPERATORS_CONTAINING.IN,
      }),
      new Column({
        path: "workDays",
        headerName: "İşlədiyi gün",
        widthOfColumn: 200,
        filterType: OPERATORS_CONTAINING.NUMBER,
      }),
      new Column({
        path: "workHours",
        headerName: "İşlədiyi saat",
        widthOfColumn: 200,
        filterType: OPERATORS_CONTAINING.NUMBER,
      }),
      new Column({
        path: "totalWorkDays",
        headerName: "Norma günü",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "totalWorkHours",
        headerName: "Norma saatı",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "labourVacation",
        headerName: "Əmək məzuniyyəti",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "unpaidVacation",
        headerName: "Ödənişsiz Məzuniyyət",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "educationalAndCreativeVacation",
        headerName: "Təhsil və yaradıcılıq məzuniyyəti",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "socialVacation",
        headerName: "Sosial məzuniyyəti",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "training",
        headerName: "Təlim-tədris",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "temporaryLost",
        headerName: "Xəstəlik",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "unknownReason",
        headerName: "Naməlum səbəb",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "businessTrip",
        headerName: "Ezamiyyət",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "totalOffDays",
        headerName: "Qeyri iş günləri (istirahət/bayram/hüzn günləri)",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
      new Column({
        path: "calendarDays",
        headerName: "Təqvim günləri",
        disableColSearch: true,
        widthOfColumn: 150,
      }),
    ];

    let days = Array.from({ length: 31 }, (_, index) => {
      const dayNumber = index + 1;
      return new Column({
        path: `Day ${dayNumber}`,
        headerName: `${dayNumber}`,
        disableColSearch: true,
        widthOfColumn: 150,
        renderCell: (row) => {
          return (
            <div
              className="position-relative"
              style={{
                borderRadius: "5px",
                marginTop: "5px",
                width: "100%",
                textAlign: "center",
                border: "1px solid white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px",
                height: "25px",
              }}
            ></div>
          );
        },
      });
    });

    data.forEach((item) => {
      if (item?.tables?.length) {
        for (let i = 0; i < days.length; i++) {
          const columnDay = parseInt(days[i].headerName);

          days[i] = new Column({
            ...days[i],
            renderCell: (row) => {
              const firstTableItem = row?.tables?.[0];
              const startDay = firstTableItem?.day;

              if (!startDay || columnDay < startDay) {
                return (
                  <div
                    className="position-relative"
                    style={{
                      borderRadius: "5px",
                      marginTop: "5px",
                      width: "100%",
                      textAlign: "center",
                      border: "1px solid white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px",
                      height: "25px",
                    }}
                  >
                    X
                  </div>
                );
              }

              const dayData = row?.tables?.find(
                (table) => table?.day === columnDay
              );

              if (!dayData) {
                return (
                  <div
                    className="position-relative"
                    style={{
                      borderRadius: "5px",
                      marginTop: "5px",
                      width: "100%",
                      textAlign: "center",
                      border: "1px solid white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px",
                      height: "25px",
                    }}
                  >
                    X
                  </div>
                );
              }

              return (
                <div style={{ border: "1px solid transparent" }}>
                  {dayData?.dayType === "workDay" ? (
                    <GENERAL_BLOCK
                      className="position-relative"
                      style={{
                        borderRadius: "5px",
                        marginTop: "5px",
                        backgroundColor: COLORS_STATUS.GREEN,
                        width: "100%",
                        textAlign: "center",
                        border: "1px solid white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "5px",
                        height: "25px",
                      }}
                    >
                      {dayData?.dayHours} saat
                    </GENERAL_BLOCK>
                  ) : (
                    <div
                      className="position-relative"
                      style={{
                        borderRadius: "5px",
                        marginTop: "5px",
                        backgroundColor: bgColorHandler(dayData?.dayType),
                        width: "100%",
                        textAlign: "center",
                        border: "1px solid white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "5px",
                        height: "25px",
                      }}
                    >
                      <span>{enums[dayData?.dayType]}</span>
                    </div>
                  )}
                </div>
              );
            },
          });
        }
      }
    });

    static_columns.push(...days);
    set_columns([...static_columns]);
  }, [data]);

  useEffect(() => {
    let structure = structureDatas?.structures?.find(
      (s) => s?.organization?.name === currentStructure
    )?._id;

    filters.forEach((item) => {
      if (item.column === "isLocked") {
        item.value = item?.value === "true";
      }
    });

    async function getAccountingTable() {
      const filterParam =
        filters.length > 0
          ? `${encodeURIComponent(JSON.stringify(filters))}`
          : "";
      try {
        const response = await getAccountingTable_SERVICE({
          year: currentYear,
          month: currentMonth,
          structure,
          filter: filterParam,
          limit,
          skip: offset,
        });
        setData(response.data?.data?.items);
        setTotal(response.data?.data?.total);
      } catch (error) {
        console.error(error);
        return false;
      }
    }
    if (structure) getAccountingTable();
  }, [filters, currentYear, currentMonth, currentStructure]);

  useEffect(() => {
    const currMonth = new Date().getMonth();
    const currYear = new Date().getFullYear();
    setCurrentMonth(currMonth);
    setCurrentYear(currYear);
    fetchCompanyStructures();
  }, []);

  async function fetchCompanyStructures() {
    try {
      const data = await getCompanyStructures({
        limit: 100,
        skip: 0,
      });

      if (data && data.structures && data.structures.length > 0) {
        setStructureDatas(data);
        setCurrentStructure(data?.structures[0]?.organization?.name);
        setCurrentStructureId(data?.structures[0]?._id);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  const handleYearChange = (value) => {
    setCurrentYear(value);
  };

  const handleMonthChange = (value) => {
    const selectedMonth = months.find((month) => month.name === value);
    if (selectedMonth) {
      setCurrentMonth(selectedMonth.key);
    }
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const downloadHandler = async (columnsForExport) => {
    try {
      const colsExp = [];

      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;
        colsExp.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });
      const encodeColumns = encodeURIComponent(JSON.stringify(colsExp));
      const encodeFilters = encodeURIComponent(JSON.stringify([...filters]));
      const resp = await getDownloadTimesheet({
        // columns: encodeColumns,
        filter: encodeFilters,
        month: currentMonth,
        year: currentYear,
        structureId: currentStructureId ? currentStructureId : "",
      });
      if (resp) downloadFile(resp, "report.xlsx");
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Employees timesheet" />
      <Typography
        variant="h3"
        gutterBottom
        display="inline"
        sx={{ marginRight: 6 }}
      >
        Tabel cədvəli
      </Typography>
      <Typography variant="h4" gutterBottom display="inline">
        {currentMonthRange}
      </Typography>
      <Divider my={6} />
      <SelectDateContainer>
        <GENERAL_BLOCK className="text-center" f_size="23px" wid="80%">
          {months[currentMonth].name} {currentYear}
        </GENERAL_BLOCK>
        <div className="d-flex" style={{ width: "40%" }}>
          <SelectInput
            label={"Struktur: "}
            value={currentStructure || null}
            onChange={(value) => {
              setCurrentStructure(value);
              const selectedStructure = structureDatas.structures.find(
                (structure) => structure?.organization?.name === value
              );
              setCurrentStructureId(selectedStructure._id);
            }}
          >
            {structureDatas?.structures.map((structure) => (
              <option
                key={structure?.organization?.name}
                value={structure?.organization?.name}
              >
                {structure?.organization?.name}
              </option>
            ))}
          </SelectInput>
          <SelectInput
            styleOuterContainer={{ width: "130px" }}
            label={"İl: "}
            value={currentYear}
            onChange={handleYearChange}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </SelectInput>

          <SelectInput
            styleOuterContainer={{ width: "130px" }}
            label={`Ay: `}
            value={months[currentMonth].name}
            data={months}
            itemName={"name"}
            itemValue={"name"}
            onChange={handleMonthChange}
          >
            <option value={months[currentMonth].key}>
              {months[currentMonth].name}
            </option>
          </SelectInput>
        </div>
      </SelectDateContainer>
      {columns?.length > 0 || filters?.length > 0 ? (
        <Table
          columns={columns}
          data={data}
          totalRows={total}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={loading}
          downloadHandler={(cols) => downloadHandler(cols)}
        />
      ) : (
        <div style={{ textAlign: "center", padding: "30px" }}>
          Məlumat yoxdur
        </div>
      )}
    </React.Fragment>
  );
};

export default TimesheetTable;

const SelectDateContainer = styled.div`
  display: flex;
  justify-content: end;
  position: sticky;
  top: 64px;
  background-color: #1b2635;
  padding: 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  // width: max-content;
  z-index: 999;
`;

const months = [
  {
    name: "Yanvar",
    key: 0,
  },
  {
    name: "Fevral",
    key: 1,
  },
  {
    name: "Mart",
    key: 2,
  },
  {
    name: "Aprel",
    key: 3,
  },
  {
    name: "May",
    key: 4,
  },
  {
    name: "İyun",
    key: 5,
  },
  {
    name: "İyul",
    key: 6,
  },
  {
    name: "Avqust",
    key: 7,
  },
  {
    name: "Sentyabr",
    key: 8,
  },
  {
    name: "Oktyabr",
    key: 9,
  },
  {
    name: "Noyabr",
    key: 10,
  },
  {
    name: "Dekabr",
    key: 11,
  },
];
const years = Array.from({ length: 27 }, (_, index) => 2024 + index);
