import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";

const DonutChart = ({ data, colorHandler, total }) => {
  const labels = Object.keys(data);
  const values = Object.values(data);

  const backgroundColors = labels.map((label) => colorHandler(label));

  const chartData = {
    labels: [
      "Ərizəsi təsdiq gözləyən təyinatların sayı",
      "Müqavilə yaradılması gözləyən təyinatların sayı",
      "Müqaviləsi imza gözləyən təyinatların sayı",
      "Əmr yaradılmasını gözləyən təyinatların sayı",
      "Əmri təsdiq gözləyən təyinatların sayı",
    ],
    datasets: [
      {
        data: values,
        backgroundColor: backgroundColors,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide the legend, which contains the labels
      },
      title: {
        text: "a",
      },
    },
    cutoutPercentage: 60,
  };

  return (
    <div>
      <Doughnut data={chartData} options={options} />
      {/* <Doughnut
        data={chartData}
        options={{
          plugins: {
            
          },
        }}
      /> */}
    </div>
  );
};

export default DonutChart;
