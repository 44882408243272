import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import {
  getEmployeeWithDetails_SERVICE,
  postFillingVacantPositionOrder_SERVICE,
} from "../../../../../services/ordersServices";
import { enqueueSnackbar } from "notistack";
import moment from "moment";

const Divider = styled(MuiDivider)(spacing);

const RecallWork = () => {
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [data, setData] = useState([]);
  const [employee, setEmployee] = useState({
    employeeId: "",
    tableNumber: "",
    staffId: "",
    absenceInfo: "",
  });
  const [recallWorkData, setRecallWorkData] = useState({
    reasonOfRecall: "",
    dateOfRecallToWork: null,
  });
  //Əməkdaşları gətirmək üçün
  const getFillingVacantPositionOrder = async () => {
    try {
      const res = await getEmployeeWithDetails_SERVICE();
      if (res) {
        setEmployeeData(res?.data?.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Əmr yaratmaq
  const createFillingVacantPositionOrder = async () => {
    try {
      const payload = {
        staffId: employee.staffId,
        reasonOfRecall: recallWorkData.reasonOfRecall,
      };

      if (recallWorkData.dateOfRecallToWork) {
        payload.dateOfRecallToWork = moment(
          recallWorkData.dateOfRecallToWork
        ).format("MM/DD/yyyy");
      }
      let final = {
        docModel: "Employee",
        subjects: [employee.employeeId],
        data: {
          ...payload,
        },
      };
      let response = await postFillingVacantPositionOrder_SERVICE(
        "temporary-replacement-order",
        final
      );
      navigate("/documentation/orders");
      if (response) {
        enqueueSnackbar({ message: "Uğurla yaradıldı", variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar({ message: "Xəta baş verdi", variant: "error" });
      // validateForm();
    }
  };

  useEffect(() => {
    getFillingVacantPositionOrder();
  }, []);

  //Seçilən əməkdaşın datasını gətirmək
  const handleEmployeeChange = (value) => {
    const emp = employeeData.find((e) => e?.employee?._id === value);
    setData(emp);
    setEmployee({
      ...employee,
      employeeId: value || "",
      tableNumber: emp?.employee?.tableNumber || "",
      staffId: emp?.contracts?.[0]?.staff || "",
      absenceInfo: emp?.contracts?.[0]?.absenceInfo || "",
    });
  };

  const handleInputChange = (name, value) => {
    setRecallWorkData({
      ...recallWorkData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    await createFillingVacantPositionOrder();
  };
  console.log(employee);
  console.log("data", data);

  return (
    <form onSubmit={handleSubmit}>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            İşə geri çağırma Əmri
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                Əməkdaş Məlumatları
              </Typography>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel>Əməkdaş(A.S.A)</InputLabel>
                <Select
                  name="Əməkdaş(A.S.A)"
                  label="Əməkdaş(A.S.A)"
                  variant="outlined"
                  value={employee.employeeId || ""}
                  onChange={(e) => handleEmployeeChange(e.target.value)}
                >
                  {employeeData &&
                    employeeData.map((d, i) => (
                      <MenuItem key={i} value={d?.employee?._id}>
                        {`${d?.employee?.firstname} ${d?.employee?.lastname} ${d?.employee?.fathername}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Grid sx={{ display: "flex", gap: "25px" }}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel id="Müqavilə nömrəsi">
                    Müqavilə nömrəsi
                  </InputLabel>
                  <Select
                    name="Müqavilə nömrəsi"
                    labelId="Müqavilə nömrəsi"
                    label="Müqavilə nömrəsi"
                    fullWidth
                    variant="outlined"
                    value={
                      data?.contracts?.[0]?.labourContract?.contractNumber || ""
                    }
                  >
                    <MenuItem
                      value={
                        data?.contracts?.[0]?.labourContract?.contractNumber
                      }
                    >
                      {data?.contracts?.[0]?.labourContract?.contractNumber}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    // value={currentContractData?.contract?.decisionDate}
                    label="Müqavilənin tarixi"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
                value={employee?.tableNumber || ""}
              />
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel>Təşkilat</InputLabel>
                <Select
                  name="Təşkilat"
                  label="Təşkilat"
                  variant="outlined"
                  value={data?.contracts?.[0]?.structure?.name || ""}
                >
                  <MenuItem value={data?.contracts?.[0]?.structure?.name}>
                    {data?.contracts?.[0]?.structure?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel>Struktur</InputLabel>
                <Select
                  name="Struktur"
                  label="Struktur"
                  variant="outlined"
                  value={data?.contracts?.[0]?.topPartOfStructure?.name || ""}
                >
                  <MenuItem
                    value={data?.contracts?.[0]?.topPartOfStructure?.name}
                  >
                    {data?.contracts?.[0]?.topPartOfStructure?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel>Substruktur</InputLabel>
                <Select
                  name="Substruktur"
                  label="Substruktur"
                  variant="outlined"
                  value={data?.contracts?.[0]?.subStructure?.name || ""}
                >
                  <MenuItem value={data?.contracts?.[0]?.subStructure?.name}>
                    {data?.contracts?.[0]?.subStructure?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel>Vəzifə</InputLabel>
                <Select
                  name="Vəzifə"
                  label="Vəzifə"
                  variant="outlined"
                  value={data?.contracts?.[0]?.position?.name || ""}
                >
                  <MenuItem value={data?.contracts?.[0]?.position?.name}>
                    {data?.contracts?.[0]?.position?.name}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                İşə geri çağırma səbəbi
              </Typography>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel>İşə geri çağırma növü</InputLabel>
                <Select
                  name="organization"
                  label="İşə geri çağırma növü"
                  variant="outlined"
                >
                  <MenuItem value="organization1">
                    Məzuniyyətdən geri çağırma
                  </MenuItem>
                  <MenuItem value="organization2">
                    Ezamiyyədən geri çağırma
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <TextField
                  name="İşə geri çağırma səbəbi"
                  label="İşə geri çağırma səbəbi"
                  variant="outlined"
                  onChange={(e) =>
                    handleInputChange("reasonOfRecall", e.target.value)
                  }
                  my={2}
                />
              </FormControl>
              <Grid mb={4} sx={{ display: "flex", width: "100%", gap: "25px" }}>
                <TextField
                  name="Əmrin nömrəsi"
                  label="Əmrin nömrəsi"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  my={2}
                />
                <DatePicker
                  my={2}
                  inputFormat="dd/MM/yyyy"
                  name="dateOfRecallToWork"
                  label="Əmrin imzalanma tarixi"
                  onChange={(date) =>
                    handleInputChange("dateOfRecallToWork", date)
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid mb={4} sx={{ display: "flex", width: "100%", gap: "25px" }}>
                <DatePicker
                  my={2}
                  sx={{ width: "50%" }}
                  inputFormat="dd/MM/yyyy"
                  label="Məzuniyyətin /Ezamiyyənin başlama tarixi"
                  value={data?.contracts?.[0]?.absenceInfo?.startDate}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  my={2}
                  sx={{ width: "50%" }}
                  inputFormat="dd/MM/yyyy"
                  label="Məzuniyyətin /Ezamiyyənin bitmə tarixi"
                  value={data?.contracts?.[0]?.absenceInfo?.endDate}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid mb={4} sx={{ display: "flex", width: "100%", gap: "25px" }}>
                <DatePicker
                  my={2}
                  inputFormat="dd/MM/yyyy"
                  label="İşə çıxma tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  my={2}
                  inputFormat="dd/MM/yyyy"
                  label="Yeni işə çıxma tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <TextField
                name="Gün"
                label="Gün"
                value={data?.contracts?.[0]?.absenceInfo?.absenceDays || ""}
                sx={{ width: "100%" }}
                variant="outlined"
                my={2}
              />
            </Grid>
          </Grid>
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "25px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => console.log("salam")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              type="submit"
              title="Kənarlaşdırma yarat"
              variant="contained"
              sx={{ height: "100%" }}
              onClick={createFillingVacantPositionOrder}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Təsdiq et
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default RecallWork;
