import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Typography,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import {
  employeesForRecall_SERVICE,
  getEmployeeId_API_SERVICE,
} from "../../../../../services/ordersServices";
import useOrders from "../../../../../hooks/useOrders";

const Divider = styled(MuiDivider)(spacing);

const RecallWork = () => {
  const navigate = useNavigate();
  const {
    data,
    currentContractData,
    contractData,
    handleCurrentContract,
    handleEmployeeChange,
    employee,
  } = useOrders();
  return (
    <form>
      <Card mb={6} style={{ height: "100%", overflow: "scroll" }}>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            İşə geri çağırma Əmri
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                Əməkdaş Məlumatları
              </Typography>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel id="Əməkdaş(A.S.A)">Əməkdaş(A.S.A)</InputLabel>
                <Select
                  name="Əməkdaş(A.S.A)"
                  labelId="Əməkdaş(A.S.A)"
                  label="Əməkdaş(A.S.A)"
                  fullWidth
                  variant="outlined"
                  value={employee.employeeId || ""}
                  onChange={(e) => handleEmployeeChange(e.target.value)}
                >
                  {data &&
                    data.map((d, i) => (
                      <MenuItem key={i} value={d?._id}>
                        {`${d?.firstname} ${d?.lastname} ${d?.fathername}`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Grid sx={{ display: "flex", gap: "25px" }}>
                <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                  <InputLabel id="Müqavilə nömrəsi">
                    Müqavilə nömrəsi
                  </InputLabel>
                  <Select
                    name="Müqavilə nömrəsi"
                    labelId="Müqavilə nömrəsi"
                    label="Müqavilə nömrəsi"
                    fullWidth
                    variant="outlined"
                    value={
                      employee?.empStaffId?.value
                        ? employee?.empStaffId?.value || ""
                        : employee?.empStaffId || ""
                    }
                    onChange={(e) => handleCurrentContract(e.target.value)}
                    disabled={!employee.employeeId}
                  >
                    {contractData &&
                      contractData?.map((d, i) => (
                        <MenuItem key={i} value={d?.contract?.staffs?.[0]?._id}>
                          {d?.contract?.data?.docNumber}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    marginBottom: "16px",
                    pointerEvents: "none",
                  }}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={currentContractData?.contract?.decisionDate}
                    label="Müqavilənin tarixi"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </FormControl>
              </Grid>
              <TextField
                name="Tabel nömrəsi"
                label="Tabel nömrəsi"
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
                variant="outlined"
                my={2}
                value={employee?.tabelNumber || ""}
              />
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Təşkilat">Təşkilat</InputLabel>
                <Select
                  name="Təşkilat"
                  labelId="Təşkilat"
                  label="Təşkilat"
                  fullWidth
                  variant="outlined"
                  value={
                    currentContractData?.structure?.organization?.name || ""
                  }
                >
                  <MenuItem
                    value={currentContractData?.structure?.organization?.name}
                  >
                    {currentContractData?.structure?.organization?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Struktur">Struktur</InputLabel>
                <Select
                  name="Struktur"
                  labelId="Struktur"
                  label="Struktur"
                  fullWidth
                  variant="outlined"
                  value={
                    currentContractData?.contract?.topPartOfStructures?.[0]
                      ?.source?.name || ""
                  }
                >
                  <MenuItem
                    value={
                      currentContractData?.contract?.topPartOfStructures?.[0]
                        ?.source?.name
                    }
                  >
                    {
                      currentContractData?.contract?.topPartOfStructures?.[0]
                        ?.source?.name
                    }
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Substruktur">Substruktur</InputLabel>
                <Select
                  name="Substruktur"
                  labelId="Substruktur"
                  label="Substruktur"
                  fullWidth
                  variant="outlined"
                  value={currentContractData?.part?.parent?.source?.name || ""}
                >
                  <MenuItem
                    value={currentContractData?.part?.parent?.source?.name}
                  >
                    {currentContractData?.part?.parent?.source?.name}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: "16px",
                  pointerEvents: "none",
                }}
              >
                <InputLabel id="Vəzifə">Vəzifə</InputLabel>
                <Select
                  name="Vəzifə"
                  labelId="Vəzifə"
                  label="Vəzifə"
                  fullWidth
                  variant="outlined"
                  value={currentContractData?.part?.source.name || ""}
                >
                  <MenuItem value={currentContractData?.part?.source.name}>
                    {currentContractData &&
                      currentContractData?.part?.source.name}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "50%" }}>
              <Typography variant="h5" textAlign="center" mt={5} mb={7}>
                İşə geri çağırma səbəbi
              </Typography>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <InputLabel>İşə geri çağırma növü</InputLabel>
                <Select
                  name="organization"
                  labelId="İşə geri çağırma növü"
                  label="İşə geri çağırma növü"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="organization1">
                    İşə geri çağırma növü 1
                  </MenuItem>
                  <MenuItem value="organization2">
                    İşə geri çağırma növü 2
                  </MenuItem>
                  <MenuItem value="organization3">
                    İşə geri çağırma növü 3
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "100%", marginBottom: "16px" }}>
                <TextField
                  name="İşə geri çağırma səbəbi"
                  label="İşə geri çağırma səbəbi"
                  variant="outlined"
                  my={2}
                />
              </FormControl>
              <Grid mb={4} sx={{ display: "flex", width: "100%", gap: "25px" }}>
                <TextField
                  name="Əmrin nömrəsi"
                  label="Əmrin nömrəsi"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  my={2}
                />
                <TextField
                  name="Əmrin imzalanma tarixi"
                  label="Əmrin imzalanma tarixi"
                  sx={{ width: "100%" }}
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <Grid mb={4} sx={{ display: "flex", width: "100%", gap: "25px" }}>
                <DatePicker
                  my={2}
                  sx={{ width: "50%" }}
                  inputFormat="dd/MM/yyyy"
                  label="Məzuniyyətin /Ezamiyyənin başlama tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  my={2}
                  sx={{ width: "50%" }}
                  inputFormat="dd/MM/yyyy"
                  label="Məzuniyyətin /Ezamiyyənin bitmə tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid mb={4} sx={{ display: "flex", width: "100%", gap: "25px" }}>
                <DatePicker
                  my={2}
                  inputFormat="dd/MM/yyyy"
                  label="İşə çıxma tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                <DatePicker
                  my={2}
                  inputFormat="dd/MM/yyyy"
                  label="Yeni işə çıxma tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <TextField
                name="Gün"
                label="Gün"
                sx={{ width: "100%" }}
                variant="outlined"
                my={2}
              />
            </Grid>
          </Grid>
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "25px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => console.log("salam")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              type="submit"
              title="Kənarlaşdırma yarat"
              variant="contained"
              sx={{ height: "100%" }}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Təsdiq et
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default RecallWork;
