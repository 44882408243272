import {
  IconButton,
  Input,
  InputAdornment,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import ClearIcon from "@mui/icons-material/Clear";

function CustomInput({ onFocus, value, onChange, title }) {
  return (
    <TextField
      label={`${title} (tarix aralığı seçin)`}
      variant="outlined"
      onFocus={onFocus}
      value={value}
      onChange={onChange}
      fullWidth
      InputProps={{
        readOnly: true,
      }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton aria-label="toggle password visibility" edge="end">
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
}

const MultiDatePicker = ({ setRange, range, title, maxDate }) => {
  const handleDateChange = (date) => {
    let fromDate = `${date[0].month?.number}/${date[0].day}/${date[0].year}`;
    let toDate = `${date[1]?.month?.number}/${date[1]?.day}/${date[1]?.year}`;
    setRange((prevDate) => ({
      ...prevDate,
      fromDate: fromDate,
      toDate: toDate,
    }));
  };

  return (
    <DatePicker
      render={<CustomInput title={title} />}
      // value={values}
      onChange={handleDateChange}
      range
      format="DD/MM/YYYY"
      fullWidth
      fixMainPosition
      calendarPosition="bottom"
      className="bg-dark"
      style={{
        width: "100%",
      }}
      containerStyle={{
        width: "100%",
      }}
      maxDate={maxDate}
    />
  );
};

export default MultiDatePicker;
