import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { deleteGraphicSetting } from "../../../../api/graphicSettings";

const DeleteGraphicDialog = ({
  id,
  getData,
  deleteDialog,
  setDeleteDialog,
}) => {
  const handleDelete = async (id) => {
    await deleteGraphicSetting(id);
    getData();
  };
  return (
    <Dialog
      open={deleteDialog}
      onClose={() => setDeleteDialog(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Silmək istədiyinizdən əminsiniz ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Bu əməliyyata davam etsəniz qrafik silinəcək!
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setDeleteDialog(false)} color="primary">
          Ləğv et
        </Button>
        <Button
          onClick={() => {
            handleDelete(id);
            setDeleteDialog(false);
          }}
          color="primary"
        >
          Bəli
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGraphicDialog;
