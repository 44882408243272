import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { deleteCommonData } from "../../api/api";

const DeleteCommonDataDialog = ({
  openDeleteDialog,
  setOpenDeleteDialog,
  state,
  dispatch,
}) => {
  const { selectedType, selectedRow, submitted } = state;
  const handleDeleteData = async (field, value) => {
    await deleteCommonData(field, value);
    dispatch({ type: "SET_SUBMITTED", payload: !submitted });
  };

  return (
    <>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Silmək istədiyinizdən əminsiniz ?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setOpenDeleteDialog(false);
              handleDeleteData(
                selectedType[0],
                selectedType[0] === "recruiterList"
                  ? selectedRow.pin
                  : selectedRow.name
              );
            }}
          >
            Bəli
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteCommonDataDialog;
