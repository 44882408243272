import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay, isAfter } from "date-fns";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCommonData, updateEmployee } from "../../api/api";
import MilitaryReasonConst from "../../constants/employee/MilitaryReasonConst";
import useStaticData from "../../hooks/useStaticData/useStaticData";
import { removeEmptyStrings } from "../../utils/removeEmptyString";
import { militaryValidation } from "../employees/Validations";
import CustomAccordion from "../ui/Accordion/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const ChildContainer = styled.div`
  width: 100%;
`;

const LabelContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const customMenuStyle = {
  height: "280px",
  width: "500px",
};

export default function EditMilitaryInfo({
  employeeById,
  submitted,
  setSubmitted,
  setSelectedType,
  setOpenAddDialog,
}) {
  const [issuers, setIssuers] = useState([]);
  const [isDate, setIsDate] = useState(false);
  const [pastData, setPastData] = useState([]);
  // const [reasonLines, setReasonLines] = useState([]);
  const [lists, setLists] = useState({
    listOfDiseases: [],
    troopTypes: [],
    ranks: [],
  });

  const [empStaticData] = useStaticData();
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    let modifiedValues = {
      ...values,
      militaryService: {
        hasMilitaryService: values.militaryService?.hasMilitaryService,
        issuerName: values.militaryService?.issuerName?.trim(),
        troopType: values.militaryService?.troopType?.trim(),
        militaryRank: values.militaryService?.militaryRank?.trim(),
        serviceStartDate: values.militaryService?.serviceStartDate
          ? moment(values.militaryService?.serviceStartDate).format(
              "MM/DD/yyyy"
            )
          : null,
        serviceEndDate: values.militaryService?.serviceEndDate
          ? moment(values.militaryService?.serviceEndDate).format("MM/DD/yyyy")
          : null,
        reason: values.militaryService?.reason.trim(),
        articleNumber: values.militaryService?.articleNumber,
        note: values.militaryService?.note?.trim(),
      },
    };
    const result = removeEmptyStrings(modifiedValues);
    await updateEmployee(employeeById._id, result);
    setIsDate(false);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };
  //   const { setFieldValue } = useFormikContext();
  //   const endDate = values.militaryService.serviceEndDate;
  //   const startDate = values.militaryService.serviceStartDate;
  const today = endOfDay(new Date());
  useEffect(() => {
    setLists({
      listOfDiseases: empStaticData["listOfDiseases"]?.data,
      troopTypes: empStaticData["troopTypes"]?.data,
      ranks: empStaticData["militaryRanks"]?.data,
    });
  }, [empStaticData]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const issuersList = await getCommonData("identityCardissuerList");
        const issuerName = issuersList?.data || [];
        setIssuers(issuerName);

        const combinedData = [];
        if (issuerName.length) {
          combinedData.push({
            issuerName: [...issuerName],
          });
        }

        handlePastData(combinedData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    fetchOptions();
  }, [submitted]);
  const initialValues = {
    militaryService: employeeById.militaryService || [],
  };
  function handlePastData(data) {
    setPastData(data);
  }
  issuers && issuers.push(employeeById?.idCardAuthority);

  function handleHidden(name, data) {
    return pastData.every((past) => !past[name]?.includes(data));
  }

  const has = (errors, values, touched) => {
    const endDate = values.militaryService.serviceEndDate;
    const startDate = values.militaryService.serviceStartDate;

    return (
      <ChildContainer>
        <LabelContainer>
          <FormControl
            error={
              errors?.militaryService?.issuerName &&
              touched?.militaryService?.issuerName &&
              errors.militaryService.issuerName
            }
            fullWidth
          >
            <InputLabel id="militaryService.issuerName">
              Sənədi verən orqan *
            </InputLabel>

            <Field
              as={Select}
              name="militaryService.issuerName"
              labelId="militaryService.issuerName"
              label="Sənədi verən orqan"
              id="issuerName"
              defaultValue=""
              fullWidth
              error={
                errors.militaryService?.issuerName &&
                touched?.militaryService?.issuerName &&
                Boolean(errors.militaryService.issuerName)
              }
              required
            >
              <MenuItem hidden value=""></MenuItem>
              {issuers &&
                [...new Set(issuers)]
                  ?.sort((a, b) => a.localeCompare(b, "az"))
                  ?.map((item, index) => (
                    <MenuItem
                      hidden={handleHidden("issuerName", item)}
                      key={`${item} ${index}`}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
              <Button
                sx={{
                  mt: 3,
                  ml: 3,
                }}
                variant="outlined"
                onClick={() => {
                  setOpenAddDialog(true);
                  setSelectedType([
                    "identityCardissuerList",
                    { name: "Sənədi verən orqanlar" },
                  ]);
                }}
              >
                Yeni sənəd verən orqan əlavə et
              </Button>
            </Field>
            {errors.militaryService?.issuerName &&
              touched?.militaryService?.issuerName &&
              errors.militaryService?.issuerName && (
                <FormHelperText error>
                  {errors.militaryService?.issuerName}
                </FormHelperText>
              )}
          </FormControl>
        </LabelContainer>
        <LabelContainer>
          <FormControl
            error={
              errors.militaryService?.troopType &&
              touched?.militaryService?.troopType &&
              errors.militaryService.troopType
            }
            fullWidth
          >
            <InputLabel id="militaryService.troopType">Qoşun növü *</InputLabel>
            <Field
              as={Select}
              name="militaryService.troopType"
              labelId="militaryService.troopType"
              label="Qoşun növü *"
              id="troopType"
              fullWidth
              defaultValue=""
              error={
                errors.militaryService?.troopType &&
                touched?.militaryService?.troopType &&
                Boolean(errors.militaryService.troopType)
              }
              required
            >
              <MenuItem hidden value=""></MenuItem>
              {lists?.troopTypes &&
                [...lists?.troopTypes]
                  .sort((a, b) => a.localeCompare(b, "az"))
                  .map((item, index) => (
                    <MenuItem key={`${item} ${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
            </Field>

            {errors.militaryService?.troopType &&
              touched?.militaryService?.troopType &&
              errors.militaryService?.troopType && (
                <FormHelperText error>
                  {errors.militaryService?.troopType}
                </FormHelperText>
              )}
          </FormControl>
        </LabelContainer>
        <LabelContainer>
          <FormControl
            error={
              errors.militaryService?.militaryRank &&
              touched?.militaryService?.militaryRank &&
              errors.militaryService.militaryRank
            }
            fullWidth
          >
            <InputLabel id="militaryService.militaryRank">Rütbə *</InputLabel>

            <Field
              as={Select}
              name="militaryService.militaryRank"
              labelId="militaryService.militaryRank"
              label="Rütbə"
              id="militaryRank"
              fullWidth
              error={
                errors.militaryService?.militaryRank &&
                touched?.militaryService?.militaryRank &&
                Boolean(errors.militaryService.militaryRank)
              }
              required
            >
              {lists?.ranks &&
                [...lists?.ranks]
                  ?.sort((a, b) => a.localeCompare(b, "az"))
                  ?.map((item, index) => (
                    <MenuItem key={`${item} ${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
            </Field>
            {errors.militaryService?.militaryRank &&
              touched?.militaryService?.militaryRank &&
              errors.militaryService?.militaryRank && (
                <FormHelperText error>
                  {errors.militaryService?.militaryRank}
                </FormHelperText>
              )}
          </FormControl>
        </LabelContainer>
        <LabelContainer onClick={() => setIsDate(true)}>
          <Field name="militaryService.serviceStartDate">
            {({ field, form }) => (
              <DatePicker
                {...field}
                label="Xidmətə başlama tarixi *"
                inputFormat="dd/MM/yyyy"
                value={field.value || null}
                onChange={(date) => {
                  if (date && !isAfter(date, today)) {
                    form.setFieldValue(
                      "militaryService.serviceStartDate",
                      date
                    );
                    // form.setFieldTouched(
                    //   `militaryService.serviceStartDate`,
                    //   true
                    // );
                  } else {
                    // form.setFieldTouched(
                    //   `militaryService.serviceStartDate`,
                    //   true
                    // );
                    form.setFieldValue("militaryService.serviceStartDate", "");
                  }
                }}
                minDate={new Date(1940, 0, 1)}
                maxDate={endDate ? endDate : today}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      isDate && form.errors.militaryService?.serviceStartDate
                    }
                    error={
                      isDate &&
                      Boolean(form.errors.militaryService?.serviceStartDate)
                    }
                    fullWidth
                  />
                )}
                required
              />
            )}
          </Field>
        </LabelContainer>
        <LabelContainer onClick={() => setIsDate(true)}>
          <Field name="militaryService.serviceEndDate">
            {({ field, form }) => (
              <DatePicker
                {...field}
                label="Xidmətin bitmə tarixi *"
                inputFormat="dd/MM/yyyy"
                value={field.value || null}
                onChange={(date) => {
                  if (date && !isAfter(date, today)) {
                    form.setFieldValue("militaryService.serviceEndDate", date);
                  } else {
                    form.setFieldValue("militaryService.serviceEndDate", "");
                  }
                }}
                minDate={startDate ? new Date(startDate) : undefined}
                maxDate={today}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={
                      isDate &&
                      form.errors.militaryService?.serviceEndDate &&
                      form.errors.militaryService?.serviceEndDate
                    }
                    error={
                      isDate &&
                      form.errors.militaryService?.serviceEndDate &&
                      Boolean(form.errors.militaryService?.serviceEndDate)
                    }
                    fullWidth
                  />
                )}
                required
              />
            )}
          </Field>
        </LabelContainer>
      </ChildContainer>
    );
  };

  const hasnot = (errors, values, touched) => {
    return (
      <ChildContainer>
        {/* Səbəb */}
        <LabelContainer>
          <FormControl
            error={
              errors.militaryService?.reason &&
              touched?.militaryService?.reason &&
              errors.militaryService.reason
            }
            fullWidth
          >
            <InputLabel id="militaryService.reason">Səbəb *</InputLabel>
            <Field
              as={Select}
              name="militaryService.reason"
              labelId="militaryService.reason"
              label="Səbəb *"
              id="reason"
              fullWidth
              error={
                errors.militaryService?.reason &&
                touched?.militaryService?.reason &&
                Boolean(errors.militaryService.reason)
              }
              required
            >
              <MenuItem hidden value={undefined}>
                Səbəb seçin...
              </MenuItem>
              {MilitaryReasonConst &&
                MilitaryReasonConst?.sort((a, b) =>
                  a.localeCompare(b, "az")
                ).map((item, index) => (
                  <MenuItem key={`${index} ${item}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Field>
            {errors.militaryService?.reason &&
              touched?.militaryService?.reason &&
              errors.militaryService?.reason && (
                <FormHelperText error>
                  {errors.militaryService?.reason}
                </FormHelperText>
              )}
          </FormControl>
        </LabelContainer>

        {/* maddə bənd */}
        {values.militaryService.reason === MilitaryReasonConst[2] && (
          <LabelContainer>
            <FormControl
              error={
                touched?.militaryService?.articleNumber &&
                Boolean(errors.militaryService?.articleNumber)
              }
              fullWidth
            >
              <InputLabel id="militaryService.articleNumber">
                Maddə *
              </InputLabel>
              <Field
                as={Select}
                name="militaryService.articleNumber"
                labelId="militaryService.articleNumber"
                label="Maddə *"
                id="militaryService.articleNumber"
                fullWidth
                multiple
                value={values.militaryService.articleNumber}
                // onChange={(event) => {
                //   const newValue = event.target.value; // Directly use event.target.value
                //   setFieldValue(`militaryService.articleNumber`, newValue);
                // }}
                error={
                  touched?.militaryService?.articleNumber &&
                  Boolean(errors.militaryService?.articleNumber)
                }
                required
                renderValue={(selected) => selected.join(", ")}
              >
                {lists?.listOfDiseases &&
                  [...lists?.listOfDiseases]
                    ?.sort((a, b) => a.article.localeCompare(b.article, "az"))
                    .map((article, index) => {
                      if (article.paragraphs.length === 0) {
                        return (
                          <MenuItem
                            key={`${article.article} ${index}`}
                            value={article.article}
                          >
                            <b>
                              <em>{`${article.article}. ${article.name}`}</em>
                            </b>
                          </MenuItem>
                        );
                      } else {
                        return [
                          <MenuItem
                            key={`${article.article} ${index}`}
                            value={article.article}
                            disabled
                          >
                            <b>
                              <em>{`${article.article}. ${article.name}`}</em>
                            </b>
                          </MenuItem>,
                          ...article.paragraphs.map((item, index) => (
                            <MenuItem
                              key={`${item.key} ${index}`}
                              value={item.key}
                            >
                              {`${item.paragraph}. ${item.detail}`}
                            </MenuItem>
                          )),
                        ];
                      }
                    })}
              </Field>
              {touched?.militaryService?.articleNumber &&
                errors.militaryService?.articleNumber && (
                  <FormHelperText error>
                    {errors.militaryService.articleNumber}
                  </FormHelperText>
                )}
            </FormControl>
          </LabelContainer>
        )}

        {/* Qeyd */}
        <LabelContainer>
          <Field
            type="text"
            name="militaryService.note"
            as={TextField}
            fullWidth
            label="Qeyd"
            error={
              errors.militaryService?.note &&
              touched?.militaryService?.note &&
              Boolean(errors.militaryService?.note)
            }
            helperText={
              errors.militaryService?.note &&
              touched?.militaryService?.note &&
              errors.militaryService?.note
            }
          />
        </LabelContainer>
      </ChildContainer>
    );
  };

  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Hərbi mükəlləfiyyət</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              militaryService: initialValues.militaryService || [],
            }}
            validationSchema={militaryValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              isValid,
              isSubmitting,
              handleChange,
              touched,
              dirty,
            }) => {
              return (
                <>
                  <Form>
                    <LabelContainer>
                      <FormControl
                        error={
                          errors.militaryService?.hasMilitaryService &&
                          touched?.militaryService?.hasMilitaryService &&
                          errors.militaryService.hasMilitaryService
                        }
                        fullWidth
                      >
                        <InputLabel id="militaryService.hasMilitaryService">
                          Hərbi mükəlləfiyyət *
                        </InputLabel>
                        <Field
                          as={Select}
                          name="militaryService.hasMilitaryService"
                          labelId="militaryService.hasMilitaryService"
                          label="Hərbi mükəlləfiyyət"
                          id="hasMilitaryService"
                          fullWidth
                          error={
                            errors.militaryService?.hasMilitaryService &&
                            touched?.militaryService?.hasMilitaryService &&
                            Boolean(errors.militaryService.hasMilitaryService)
                          }
                          required
                        >
                          <MenuItem value={true}>Var</MenuItem>
                          <MenuItem
                            onClick={() => setIsDate(false)}
                            value={false}
                          >
                            Yoxdur
                          </MenuItem>
                        </Field>
                        {errors.militaryService?.hasMilitaryService &&
                          touched?.militaryService?.hasMilitaryService &&
                          errors.militaryService?.hasMilitaryService && (
                            <FormHelperText error>
                              {errors.militaryService?.hasMilitaryService}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </LabelContainer>

                    {values.militaryService.hasMilitaryService ===
                    undefined ? null : (
                      <Grid>
                        {values.militaryService.hasMilitaryService
                          ? has(errors, values, touched)
                          : hasnot(errors, values, touched)}
                        <style>
                          {`
              #menu-militaryService\\.articleNumber {
                ${Object.keys(customMenuStyle)
                  .map((key) => `${key}: ${customMenuStyle[key]};`)
                  .join("\n")}
              }
            `}
                        </style>
                      </Grid>
                    )}
                    <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!isValid || isSubmitting || !dirty}
                      >
                        Dəyişiklikləri yadda saxla
                      </Button>
                    </Grid>
                  </Form>
                </>
              );
            }}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
}
