import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { Field, Form, Formik } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay, isAfter } from "date-fns";
import { getCommonData } from "../../api/api";
import useStaticData from "../../hooks/useStaticData/useStaticData";
import MilitaryReasonConst from "../../constants/employee/MilitaryReasonConst";

const ChildContainer = styled.div`
  width: 100%;
`;

const LabelContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const customMenuStyle = {
  height: "280px",
  width: "500px",
};

const ViewMilitaryInfo = ({ employeeById }) => {
  const [issuers, setIssuers] = useState();
  const [lists, setLists] = useState({
    listOfDiseases: [],
    troopTypes: [],
    ranks: [],
  });
  const [empStaticData] = useStaticData();
  const today = endOfDay(new Date());
  useEffect(() => {
    setLists({
      listOfDiseases: empStaticData["listOfDiseases"]?.data,
      troopTypes: empStaticData["troopTypes"]?.data,
      ranks: empStaticData["militaryRanks"]?.data,
    });
  }, [empStaticData]);

  useEffect(() => {
    const fetchOptions = async () => {
      const issuersList = await getCommonData("identityCardissuerList");
      setIssuers(issuersList.data);
    };
    fetchOptions();
  }, []);
  const has = (errors, values) => {
    const endDate = values.militaryService.serviceEndDate;
    const startDate = values.militaryService.serviceStartDate;
    return (
      <ChildContainer>
        <LabelContainer>
          <FormControl
            error={
              errors?.militaryService?.issuerName &&
              errors.militaryService.issuerName
            }
            fullWidth
          >
            <InputLabel id="militaryService.issuerName">
              Sənədi verən orqan *
            </InputLabel>

            <Field
              as={Select}
              name="militaryService.issuerName"
              labelId="militaryService.issuerName"
              label="Sənədi verən orqan"
              id="issuerName"
              defaultValue=""
              fullWidth
              error={
                errors.militaryService?.issuerName &&
                Boolean(errors.militaryService.issuerName)
              }
              required
            >
              <MenuItem hidden value=""></MenuItem>
              {issuers?.map((item, index) => (
                <MenuItem
                  key={`${item} ${index}`}
                  value={employeeById?.militaryService?.issuerName}
                >
                  {employeeById?.militaryService?.issuerName}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </LabelContainer>
        <LabelContainer>
          <FormControl
            error={
              errors.militaryService?.troopType &&
              errors.militaryService.troopType
            }
            fullWidth
          >
            <InputLabel id="militaryService.troopType">Qoşun növü *</InputLabel>
            <Field
              as={Select}
              name="militaryService.troopType"
              labelId="militaryService.troopType"
              label="Hərbi mükəlləfiyyət"
              id="troopType"
              fullWidth
              defaultValue=""
              error={
                errors.militaryService?.troopType &&
                Boolean(errors.militaryService.troopType)
              }
              required
            >
              <MenuItem hidden value=""></MenuItem>
              {lists?.troopTypes?.map((item, index) => (
                <MenuItem
                  key={`${item} ${index}`}
                  value={employeeById?.militaryService?.troopType}
                >
                  {employeeById?.militaryService?.troopType}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </LabelContainer>
        <LabelContainer>
          <FormControl
            error={
              errors.militaryService?.militaryRank &&
              errors.militaryService.militaryRank
            }
            fullWidth
          >
            <InputLabel id="militaryService.militaryRank">Rütbə *</InputLabel>

            <Field
              as={Select}
              name="militaryService.militaryRank"
              labelId="militaryService.militaryRank"
              label="Rütbə"
              id="militaryRank"
              fullWidth
              error={
                errors.militaryService?.militaryRank &&
                Boolean(errors.militaryService.militaryRank)
              }
              required
            >
              {lists?.ranks?.map((item, index) => (
                <MenuItem
                  key={`${item} ${index}`}
                  value={employeeById?.militaryService?.militaryRank}
                >
                  {employeeById?.militaryService?.militaryRank}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </LabelContainer>
        <LabelContainer>
          <Field name="militaryService.serviceStartDate">
            {({ field, form }) => (
              <DatePicker
                {...field}
                label="Xidmətə başlama tarixi *"
                inputFormat="dd/MM/yyyy"
                value={employeeById?.militaryService?.serviceStartDate || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={form.errors.militaryService?.serviceStartDate}
                    error={Boolean(
                      form.errors.militaryService?.serviceStartDate
                    )}
                    fullWidth
                  />
                )}
                required
              />
            )}
          </Field>
        </LabelContainer>
        <LabelContainer>
          <Field name="militaryService.serviceEndDate">
            {({ field, form }) => (
              <DatePicker
                {...field}
                label="Xidmətin bitmə tarixi *"
                inputFormat="dd/MM/yyyy"
                value={employeeById?.militaryService?.serviceEndDate || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={form.errors.militaryService?.serviceEndDate}
                    error={Boolean(form.errors.militaryService?.serviceEndDate)}
                    fullWidth
                  />
                )}
                required
              />
            )}
          </Field>
        </LabelContainer>
      </ChildContainer>
    );
  };

  const hasnot = (errors, values) => {
    return (
      <ChildContainer>
        {/* Səbəb */}
        <LabelContainer>
          <FormControl
            error={
              errors.militaryService?.reason && errors.militaryService.reason
            }
            fullWidth
          >
            <InputLabel id="militaryService.reason">Səbəb *</InputLabel>
            <Field
              as={Select}
              name="militaryService.reason"
              labelId="militaryService.reason"
              label="Səbəb *"
              id="reason"
              fullWidth
              error={
                errors.militaryService?.reason &&
                Boolean(errors.militaryService.reason)
              }
              required
            >
              <MenuItem hidden value={undefined}>
                Səbəb seçin...
              </MenuItem>
              {MilitaryReasonConst.map((item, index) => (
                <MenuItem
                  key={`${index} ${item}`}
                  value={employeeById?.militaryService?.reason}
                >
                  {employeeById?.militaryService?.reason}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </LabelContainer>

        {/* maddə bənd */}
        {values.militaryService.reason === MilitaryReasonConst[2] && (
          <LabelContainer>
            <FormControl
              error={Boolean(errors.militaryService?.articleNumber)}
              fullWidth
            >
              <InputLabel id="militaryService.articleNumber">
                Maddə *
              </InputLabel>
              <Field
                as={Select}
                name="militaryService.articleNumber"
                labelId="militaryService.articleNumber"
                label="Maddə *"
                id="militaryService.articleNumber"
                fullWidth
                multiple
                value={employeeById?.militaryService?.articleNumber}
                // onChange={(event) => {
                //   const newValue = event.target.value; // Directly use event.target.value
                //   setFieldValue(`militaryService.articleNumber`, newValue);
                // }}
                error={Boolean(errors.militaryService?.articleNumber)}
                required
                renderValue={(selected) => selected.join(", ")}
              >
                {lists.listOfDiseases.map((article, index) => {
                  if (article.paragraphs.length === 0) {
                    return (
                      <MenuItem
                        key={`${article.article} ${index}`}
                        value={article.article}
                      >
                        <b>
                          <em>{`${article.article}. ${article.name}`}</em>
                        </b>
                      </MenuItem>
                    );
                  } else {
                    return [
                      <MenuItem
                        key={`${article.article} ${index}`}
                        value={article.article}
                        disabled
                      >
                        <b>
                          <em>{`${article.article}. ${article.name}`}</em>
                        </b>
                      </MenuItem>,
                      ...article.paragraphs.map((item, index) => (
                        <MenuItem key={`${item.key} ${index}`} value={item.key}>
                          {`${item.paragraph}. ${item.detail}`}
                        </MenuItem>
                      )),
                    ];
                  }
                })}
              </Field>
              {errors.militaryService?.articleNumber && (
                <FormHelperText error>
                  {errors.militaryService.articleNumber}
                </FormHelperText>
              )}
            </FormControl>
          </LabelContainer>
        )}

        {/* Qeyd */}
        <LabelContainer>
          <Field
            type="text"
            name="militaryService.note"
            as={TextField}
            value={employeeById?.militaryService?.note}
            fullWidth
            required
            label="Qeyd"
            error={
              errors.militaryService?.note &&
              Boolean(errors.militaryService?.note)
            }
            helperText={
              errors.militaryService?.note && errors.militaryService?.note
            }
          />
        </LabelContainer>
      </ChildContainer>
    );
  };
  const initialValues = {
    militaryService: employeeById.militaryService || [],
  };
  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            militaryService: initialValues.militaryService || [],
          }}
        >
          {({ values, errors }) => {
            return (
              <Form style={{ pointerEvents: "none" }}>
                <LabelContainer>
                  <FormControl
                    error={
                      errors.militaryService?.hasMilitaryService &&
                      errors.militaryService.hasMilitaryService
                    }
                    fullWidth
                  >
                    <InputLabel id="militaryService.hasMilitaryService">
                      Hərbi mükəlləfiyyət *
                    </InputLabel>
                    <Field
                      as={Select}
                      name="militaryService.hasMilitaryService"
                      labelId="militaryService.hasMilitaryService"
                      label="Hərbi mükəlləfiyyət"
                      id="hasMilitaryService"
                      fullWidth
                      error={
                        errors.militaryService?.hasMilitaryService &&
                        Boolean(errors.militaryService.hasMilitaryService)
                      }
                      required
                    >
                      <MenuItem value={true}>Var</MenuItem>
                      <MenuItem value={false}>Yoxdur</MenuItem>
                    </Field>
                    {errors.militaryService?.hasMilitaryService &&
                      errors.militaryService?.hasMilitaryService && (
                        <FormHelperText error>
                          {errors.militaryService?.hasMilitaryService}
                        </FormHelperText>
                      )}
                  </FormControl>
                </LabelContainer>

                {values.militaryService.hasMilitaryService ===
                undefined ? null : (
                  <Grid>
                    {values.militaryService.hasMilitaryService
                      ? has(errors, values)
                      : hasnot(errors, values)}
                    <style>
                      {`
          #menu-militaryService\\.articleNumber {
            ${Object.keys(customMenuStyle)
              .map((key) => `${key}: ${customMenuStyle[key]};`)
              .join("\n")}
          }
        `}
                    </style>
                  </Grid>
                )}
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewMilitaryInfo;
