import React from "react";

export default function DocSVG({ style }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="711.000000pt"
      height="739.000000pt"
      viewBox="0 0 711.000000 739.000000"
      preserveAspectRatio="xMidYMid meet"
      style={style}
    >
      <g
        transform="translate(0.000000,739.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M3393 6940 c0 -47 2 -65 4 -40 5 47 17 63 45 54 12 -4 16 1 15 15 -1
15 -4 17 -14 9 -18 -15 -41 -3 -46 24 -2 13 -4 -15 -4 -62z"
        />
        <path
          d="M3495 6980 c-4 -6 -7 -31 -7 -55 0 -47 7 -65 24 -65 7 0 7 4 -1 13
-6 8 -8 17 -4 20 3 4 0 13 -6 21 -9 10 -9 16 -1 21 6 4 9 13 6 20 -2 7 1 18 7
24 8 8 8 11 0 11 -7 0 -15 -5 -18 -10z"
        />
        <path
          d="M3748 6972 c9 -9 29 -32 43 -51 l27 -34 3 51 c3 34 0 52 -7 52 -7 0
-9 -11 -7 -27 l4 -28 -23 28 c-13 15 -31 27 -41 27 -16 0 -16 -1 1 -18z"
        />
        <path
          d="M4108 6960 c2 -16 9 -30 16 -30 8 0 8 -2 0 -8 -6 -4 -13 -19 -16 -35
-4 -21 -3 -25 8 -16 7 6 11 16 8 24 -3 7 0 16 6 20 8 5 8 10 0 20 -6 7 -10 17
-9 21 3 18 -1 34 -9 34 -5 0 -7 -13 -4 -30z"
        />
        <path
          d="M4150 6981 c0 -6 6 -11 13 -11 8 0 7 -4 -3 -11 -13 -9 -13 -10 0 -6
18 5 20 29 3 35 -7 2 -13 -1 -13 -7z"
        />
        <path d="M4193 6971 c0 -11 5 -17 9 -15 11 7 10 34 -1 34 -6 0 -9 -9 -8 -19z" />
        <path
          d="M3534 6946 c-10 -26 1 -51 22 -54 24 -3 38 20 29 49 -7 22 -43 25
-51 5z m36 -21 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0
10 -11 10 -25z"
        />
        <path
          d="M3605 6924 c0 -20 5 -34 13 -34 6 0 12 11 12 25 0 16 6 25 15 25 27
0 15 17 -12 18 -25 0 -28 -3 -28 -34z"
        />
        <path
          d="M3667 6945 c-4 -8 -2 -17 3 -20 6 -3 6 -15 0 -29 -5 -13 -6 -27 -3
-30 12 -11 52 -6 60 8 4 7 15 16 23 19 11 5 9 6 -5 6 -11 -1 -31 0 -45 0 -24
2 -24 3 -4 11 12 4 24 18 27 29 4 18 1 21 -23 21 -15 0 -30 -7 -33 -15z m33
-11 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m13 -61 c-7 -2
-19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"
        />
        <path
          d="M3844 6948 c-5 -8 -2 -9 9 -5 10 3 17 2 17 -2 0 -5 -7 -11 -15 -15
-27 -10 -17 -36 13 -36 26 0 28 2 24 35 -3 26 -9 35 -23 35 -10 0 -21 -5 -25
-12z"
        />
        <path
          d="M3915 6925 l0 -35 51 0 51 0 -6 33 c-6 30 -10 32 -51 35 l-45 3 0
-36z m32 -12 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m43 12 c0
-14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M4041 6946 c-18 -21 -4 -56 22 -56 20 0 20 0 1 15 -18 13 -18 14 -1
15 12 0 17 6 15 17 -4 22 -22 27 -37 9z m29 -6 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path
          d="M3690 6580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3720 6580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3679 6528 c5 -26 12 -34 34 -36 25 -3 27 -1 27 32 0 24 -5 36 -14
36 -9 0 -12 -8 -9 -24 3 -13 0 -27 -6 -30 -7 -5 -11 4 -11 23 0 20 -5 31 -14
31 -10 0 -12 -8 -7 -32z"
        />
        <path
          d="M3773 6553 c16 -4 16 -7 -4 -34 l-20 -29 30 0 c17 0 31 4 31 10 0 5
-7 7 -16 3 -15 -5 -15 -3 0 26 l17 31 -28 -1 c-17 -1 -21 -3 -10 -6z"
        />
        <path
          d="M3825 6524 c0 -20 5 -34 13 -34 6 0 12 11 12 25 0 16 6 25 15 25 27
0 15 17 -12 18 -25 0 -28 -3 -28 -34z"
        />
        <path
          d="M3906 6545 c18 -14 18 -14 -5 -15 -17 0 -22 -4 -17 -16 3 -9 6 -18 6
-20 0 -12 39 -1 44 12 10 27 -3 54 -27 54 -20 0 -20 0 -1 -15z m14 -35 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path
          d="M3236 6032 c-3 -5 4 -8 16 -8 37 2 32 -22 -6 -26 -34 -3 -35 -4 -23
-28 15 -30 48 -37 71 -15 20 21 21 49 0 69 -17 17 -50 21 -58 8z m42 -59 c-2
-10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z"
        />
        <path
          d="M3325 5990 c0 -29 5 -50 11 -50 6 0 9 14 7 33 l-3 32 16 -34 c11 -24
18 -31 23 -22 10 16 -25 91 -42 91 -8 0 -12 -17 -12 -50z"
        />
        <path
          d="M3408 5990 l-3 -50 46 0 c45 0 46 1 42 28 l-4 27 16 -27 c9 -16 23
-28 31 -28 13 0 12 5 -5 28 -18 25 -18 29 -4 38 30 19 4 34 -57 34 l-58 0 -4
-50z m62 0 c0 -33 -3 -40 -20 -40 -17 0 -20 7 -20 40 0 33 3 40 20 40 17 0 20
-7 20 -40z m42 25 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6
-2 11 -8 11 -13z"
        />
        <path
          d="M3608 6033 c6 -2 13 -21 13 -41 1 -28 3 -31 6 -12 5 24 5 24 24 -10
l18 -35 0 30 c0 35 -30 75 -56 74 -10 0 -13 -3 -5 -6z"
        />
        <path
          d="M3675 6030 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"
        />
        <path
          d="M3794 6025 c-3 -8 -3 -18 0 -24 4 -5 2 -12 -4 -16 -6 -4 -7 -12 -3
-18 4 -7 7 -21 8 -31 0 -11 5 -21 11 -23 7 -2 9 8 6 27 -2 16 -9 30 -16 30 -8
0 -8 2 0 8 14 9 24 62 12 62 -5 0 -11 -7 -14 -15z"
        />
        <path
          d="M4006 6026 c3 -8 -3 -16 -16 -19 -17 -4 -21 -12 -18 -34 2 -23 8 -29
33 -31 l30 -3 -3 51 c-3 34 -8 50 -18 50 -7 0 -11 -6 -8 -14z m4 -51 c0 -14
-4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z"
        />
        <path
          d="M4188 6022 c9 -9 29 -32 43 -51 l27 -34 3 51 c3 34 0 52 -7 52 -7 0
-9 -11 -7 -27 l4 -28 -23 28 c-13 15 -31 27 -41 27 -16 0 -16 -1 1 -18z"
        />
        <path
          d="M4359 6032 c8 -6 11 -17 8 -25 -3 -8 -1 -19 5 -25 6 -6 6 -12 -1 -16
-5 -4 -7 -14 -4 -22 3 -9 0 -20 -8 -26 -12 -8 -11 -9 1 -5 30 8 30 116 0 125
-13 3 -13 2 -1 -6z"
        />
        <path
          d="M3690 5985 c0 -28 24 -34 34 -9 8 20 -1 34 -20 34 -8 0 -14 -10 -14
-25z m17 -7 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"
        />
        <path
          d="M3841 5992 c-7 -14 -7 -24 3 -37 19 -27 48 -12 44 22 -4 33 -32 42
-47 15z m29 -19 c0 -20 -3 -24 -11 -16 -7 7 -9 19 -6 27 10 24 17 19 17 -11z"
        />
        <path
          d="M3910 5974 c0 -23 5 -34 14 -34 9 0 12 7 9 20 -3 12 1 22 11 26 24 9
19 22 -9 22 -22 0 -25 -5 -25 -34z"
        />
        <path
          d="M4054 5996 c-11 -27 1 -51 26 -54 24 -3 25 -3 5 12 -19 14 -19 15 -2
16 12 0 17 6 15 17 -4 23 -36 29 -44 9z m31 -6 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"
        />
        <path
          d="M4120 5974 c0 -23 5 -34 14 -34 9 0 12 7 9 20 -3 12 1 22 11 26 24 9
19 22 -9 22 -22 0 -25 -5 -25 -34z"
        />
        <path
          d="M4286 5992 c-10 -30 4 -53 28 -50 31 4 30 62 -1 66 -14 2 -23 -3 -27
-16z m34 -17 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0
10 -11 10 -25z"
        />
        <path
          d="M3590 5950 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"
        />
        <path d="M3698 5943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M4178 5943 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M795 5680 l0 -51 35 3 c28 2 36 8 38 26 2 12 -2 22 -9 22 -9 0 -9 3
1 15 17 21 4 35 -33 35 -32 0 -32 0 -32 -50z m47 25 c0 -5 -5 -11 -11 -13 -6
-2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m3 -45 c0 -7 -6 -15 -12
-17 -8 -3 -13 4 -13 17 0 13 5 20 13 18 6 -3 12 -11 12 -18z"
        />
        <path
          d="M958 5685 c3 -28 0 -45 -7 -45 -6 0 -11 11 -11 25 0 29 -26 44 -43
25 -9 -10 -9 -12 3 -8 12 4 16 -1 17 -21 0 -14 -1 -18 -4 -8 -5 20 -23 13 -23
-9 0 -10 13 -14 51 -14 31 0 48 4 44 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10 0
-5 15 -10 33 -10 32 0 33 1 29 35 -5 43 -22 47 -22 5 0 -33 -11 -38 -30 -15
-10 12 -10 18 0 30 10 12 9 15 -4 15 -10 0 -15 -4 -11 -9 3 -5 1 -12 -5 -16
-6 -4 -10 6 -10 24 0 18 -5 31 -13 31 -8 0 -11 -14 -9 -45z"
        />
        <path
          d="M1235 5680 c0 -50 0 -50 33 -50 29 0 32 3 32 31 0 22 -5 32 -20 36
-11 3 -20 12 -20 19 0 8 -6 14 -12 14 -9 0 -13 -17 -13 -50z m45 -15 c0 -14
-4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M1450 5720 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M5175 5720 c-4 -6 -7 -31 -7 -55 0 -47 7 -65 24 -65 7 0 7 4 -1 13
-6 8 -8 17 -4 20 3 4 0 13 -6 21 -9 10 -9 16 -1 21 6 4 9 13 6 20 -2 7 1 18 7
24 8 8 8 11 0 11 -7 0 -15 -5 -18 -10z"
        />
        <path
          d="M5386 5716 c3 -8 -3 -16 -16 -19 -17 -4 -21 -12 -18 -34 2 -23 8 -29
33 -31 l30 -3 -3 51 c-3 34 -8 50 -18 50 -7 0 -11 -6 -8 -14z m4 -57 c0 -10
-4 -19 -10 -19 -5 0 -10 12 -10 26 0 14 4 23 10 19 6 -3 10 -15 10 -26z"
        />
        <path
          d="M5555 5680 c0 -49 1 -50 31 -50 37 0 54 16 54 50 0 34 -17 50 -54 50
-30 0 -31 -1 -31 -50z m60 0 c0 -18 -6 -35 -12 -37 -9 -3 -13 8 -13 37 0 29 4
40 13 38 6 -3 12 -20 12 -38z"
        />
        <path
          d="M5848 5705 c2 -14 9 -28 14 -31 5 -3 5 -10 0 -17 -16 -20 -22 -59 -9
-54 25 8 26 116 1 124 -7 2 -10 -6 -6 -22z"
        />
        <path
          d="M5736 5703 c-10 -11 -7 -61 5 -69 6 -3 16 -1 22 6 9 10 9 11 0 6 -9
-5 -13 1 -13 18 0 13 5 26 12 28 8 3 6 6 -4 10 -9 4 -19 4 -22 1z"
        />
        <path
          d="M1120 5684 c0 -9 8 -23 18 -30 16 -13 16 -13 -2 -14 -14 0 -16 -3 -7
-13 6 -8 8 -17 4 -20 -3 -4 -1 -7 6 -7 7 0 11 6 8 14 -3 7 0 19 6 25 8 8 6 16
-7 30 -13 15 -14 20 -4 24 8 4 7 6 -4 6 -10 1 -18 -6 -18 -15z"
        />
        <path
          d="M1175 5691 c-3 -7 0 -8 9 -5 9 3 16 1 16 -5 0 -6 -7 -11 -16 -11 -9
0 -14 -7 -12 -17 2 -10 12 -18 23 -18 16 0 20 7 20 30 0 30 -26 47 -40 26z
m25 -41 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z"
        />
        <path
          d="M1336 5685 c18 -14 18 -14 -5 -15 -17 0 -22 -4 -17 -16 3 -9 6 -18 6
-20 0 -12 39 -1 44 12 10 27 -3 54 -27 54 -20 0 -20 0 -1 -15z m14 -35 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path
          d="M1380 5664 c0 -23 5 -34 14 -34 9 0 12 7 9 20 -3 12 1 22 11 26 24 9
19 22 -9 22 -22 0 -25 -5 -25 -34z"
        />
        <path
          d="M1445 5665 c0 -21 5 -35 13 -35 7 0 12 14 12 35 0 21 -5 35 -12 35
-8 0 -13 -14 -13 -35z"
        />
        <path
          d="M5214 5686 c-10 -26 1 -51 22 -54 24 -3 38 20 29 49 -7 22 -43 25
-51 5z m36 -21 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0
10 -11 10 -25z"
        />
        <path
          d="M5285 5664 c0 -25 4 -34 16 -34 12 0 15 7 11 25 -3 18 0 25 12 25 28
0 17 17 -11 18 -25 0 -28 -3 -28 -34z"
        />
        <path
          d="M5437 5694 c-4 -4 -7 -18 -7 -31 0 -27 26 -41 43 -24 9 9 8 11 -5 6
-11 -4 -18 -2 -18 4 0 6 7 11 16 11 9 0 14 7 12 17 -3 19 -29 29 -41 17z"
        />
        <path
          d="M5495 5664 c0 -20 5 -34 13 -34 6 0 12 11 12 25 0 16 6 25 15 25 27
0 15 17 -12 18 -25 0 -28 -3 -28 -34z"
        />
        <path
          d="M5664 5689 c-5 -8 -2 -11 7 -7 11 4 15 -2 16 -21 0 -14 -1 -18 -4 -8
-5 20 -23 13 -23 -9 0 -9 10 -14 29 -14 27 0 28 2 22 32 -7 34 -33 49 -47 27z"
        />
        <path
          d="M5787 5694 c-4 -4 -7 -18 -7 -31 0 -27 26 -41 43 -24 9 9 8 11 -5 6
-11 -4 -18 -2 -18 4 0 6 7 11 16 11 9 0 14 7 12 17 -3 19 -29 29 -41 17z"
        />
        <path
          d="M730 5360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M637 5333 c-10 -10 -8 -33 2 -33 6 0 9 9 8 20 -1 23 -1 22 -10 13z" />
        <path
          d="M667 5333 c-13 -12 -7 -33 8 -33 8 0 15 5 15 10 0 6 -5 10 -11 10 -5
0 -7 5 -4 10 7 11 2 13 -8 3z"
        />
        <path
          d="M716 5324 c-30 -30 -9 -84 33 -84 22 0 51 30 51 53 0 21 -27 47 -50
47 -10 0 -26 -7 -34 -16z m49 -35 c0 -23 -5 -35 -16 -37 -21 -4 -32 34 -18 60
15 29 34 16 34 -23z"
        />
        <path
          d="M915 5290 c0 -49 1 -50 30 -50 29 0 31 2 27 35 -3 21 -10 35 -18 35
-8 0 -14 7 -14 15 0 8 -6 15 -12 15 -9 0 -13 -17 -13 -50z m32 -27 c-3 -10 -5
-4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z"
        />
        <path
          d="M1188 5292 c4 -46 5 -49 30 -48 22 1 28 6 30 29 3 22 -1 30 -17 34
-12 3 -21 12 -21 19 0 8 -6 14 -12 14 -9 0 -12 -15 -10 -48z m42 -17 c0 -14
-4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z"
        />
        <path
          d="M2235 5290 c0 -49 1 -50 30 -50 29 0 31 2 27 35 -3 21 -10 35 -18 35
-8 0 -14 7 -14 15 0 8 -6 15 -12 15 -9 0 -13 -17 -13 -50z m32 -27 c-3 -10 -5
-4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z"
        />
        <path
          d="M2706 5325 c4 -9 0 -15 -9 -15 -9 0 -20 -8 -26 -17 -13 -25 5 -53 34
-53 23 0 25 4 25 50 0 37 -4 50 -15 50 -9 0 -12 -6 -9 -15z m0 -62 c-6 -14 -9
-15 -16 -4 -11 19 -1 46 13 32 5 -5 7 -18 3 -28z"
        />
        <path d="M2823 5321 c0 -11 5 -17 9 -15 11 7 10 34 -1 34 -6 0 -9 -9 -8 -19z" />
        <path
          d="M2850 5331 c0 -6 6 -11 13 -11 8 0 7 -4 -3 -11 -13 -9 -13 -10 0 -6
18 5 20 29 3 35 -7 2 -13 -1 -13 -7z"
        />
        <path
          d="M2154 5306 c-8 -21 -1 -59 12 -63 7 -2 10 6 6 23 -3 15 0 29 7 34 9
5 8 9 -4 13 -9 4 -18 1 -21 -7z"
        />
        <path
          d="M810 5300 c0 -5 7 -7 16 -3 15 5 15 3 0 -26 l-17 -31 28 0 c20 0 24
3 14 9 -10 6 -11 13 -2 29 15 29 14 32 -14 32 -14 0 -25 -4 -25 -10z"
        />
        <path
          d="M1001 5296 c-18 -21 -4 -56 22 -56 20 0 20 0 1 15 -18 13 -18 14 -1
15 12 0 17 6 15 17 -4 22 -22 27 -37 9z m29 -6 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path
          d="M1060 5298 c0 -6 8 -20 18 -31 16 -19 16 -20 0 -14 -10 4 -18 2 -18
-3 0 -13 27 -13 35 1 4 5 -1 20 -12 31 -16 20 -17 21 0 15 9 -4 17 -2 17 3 0
6 -9 10 -20 10 -11 0 -20 -5 -20 -12z"
        />
        <path
          d="M1114 5298 c-5 -8 -2 -9 9 -5 22 8 22 -2 0 -18 -20 -15 -9 -35 21
-35 19 0 21 4 18 35 -3 26 -9 35 -23 35 -10 0 -21 -5 -25 -12z"
        />
        <path
          d="M1265 5275 l0 -35 53 0 53 0 -3 33 c-3 28 -7 32 -34 35 -29 3 -30 1
-26 -27 2 -19 0 -31 -7 -31 -6 0 -11 14 -11 30 0 17 -5 30 -12 30 -8 0 -13
-14 -13 -35z m85 0 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25
6 0 10 -11 10 -25z"
        />
        <path
          d="M1390 5300 c0 -7 6 -10 13 -7 8 3 13 -4 14 -22 0 -14 -1 -18 -4 -8
-5 20 -23 13 -23 -9 0 -8 10 -14 26 -14 23 0 25 3 22 33 -2 24 -8 33 -25 35
-13 2 -23 -2 -23 -8z"
        />
        <path
          d="M1495 5275 l0 -35 51 0 51 0 -6 33 c-6 30 -10 32 -51 35 l-45 3 0
-36z m32 -12 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m43 12 c0
-14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M1615 5300 c-3 -6 1 -7 9 -4 9 3 16 1 16 -5 0 -6 -7 -11 -15 -11 -20
0 -19 -29 1 -37 24 -9 46 24 33 48 -10 20 -34 25 -44 9z m35 -34 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"
        />
        <path
          d="M1693 5303 c16 -4 16 -7 -4 -34 l-20 -29 30 0 c17 0 31 4 31 10 0 5
-7 7 -16 3 -15 -5 -15 -3 0 26 l17 31 -28 -1 c-17 -1 -21 -3 -10 -6z"
        />
        <path
          d="M1740 5291 c0 -36 12 -51 39 -51 25 0 27 3 23 35 -2 19 -10 35 -16
35 -8 0 -11 -11 -8 -30 3 -18 0 -30 -7 -30 -6 0 -11 14 -11 30 0 17 -4 30 -10
30 -5 0 -10 -9 -10 -19z"
        />
        <path
          d="M1820 5275 c0 -33 2 -35 33 -35 32 0 33 1 29 35 -4 31 -8 35 -33 35
-26 0 -29 -3 -29 -35z m40 0 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11
13 8 13 -18z"
        />
        <path
          d="M1905 5275 c0 -44 23 -47 27 -2 l4 32 13 -27 c16 -32 26 -35 35 -12
3 8 2 12 -4 9 -9 -6 -14 8 -11 28 0 4 -14 7 -31 7 -31 0 -33 -2 -33 -35z"
        />
        <path
          d="M1993 5303 c9 -2 25 -19 34 -36 15 -29 17 -30 23 -12 4 11 3 22 -1
23 -5 2 -9 10 -9 18 0 9 -11 14 -32 13 -18 -1 -25 -3 -15 -6z"
        />
        <path
          d="M2055 5300 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"
        />
        <path
          d="M2106 5295 c18 -14 18 -14 -5 -15 -17 0 -22 -4 -17 -16 3 -9 6 -18 6
-20 0 -12 39 -1 44 12 10 27 -3 54 -27 54 -20 0 -20 0 -1 -15z m14 -35 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path
          d="M2314 5298 c-5 -8 -2 -9 9 -5 22 8 22 -2 0 -18 -20 -15 -9 -35 21
-35 19 0 21 4 18 35 -3 26 -9 35 -23 35 -10 0 -21 -5 -25 -12z"
        />
        <path
          d="M2391 5292 c-11 -22 3 -52 26 -52 9 0 13 -6 9 -15 -3 -9 0 -15 9 -15
11 0 15 12 15 48 0 43 -2 48 -25 50 -15 1 -28 -5 -34 -16z m39 -17 c0 -14 -4
-25 -9 -25 -12 0 -19 21 -13 38 8 21 22 13 22 -13z"
        />
        <path
          d="M2471 5292 c-11 -22 3 -52 26 -52 9 0 13 -6 9 -15 -3 -9 0 -15 9 -15
11 0 15 12 15 48 0 43 -2 48 -25 50 -15 1 -28 -5 -34 -16z m39 -17 c0 -14 -4
-25 -9 -25 -12 0 -19 21 -13 38 8 21 22 13 22 -13z"
        />
        <path
          d="M2545 5275 l0 -35 53 0 53 0 -3 33 c-3 28 -7 32 -34 35 -29 3 -30 1
-26 -27 2 -19 0 -31 -7 -31 -6 0 -11 14 -11 30 0 17 -5 30 -12 30 -8 0 -13
-14 -13 -35z m85 0 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25
6 0 10 -11 10 -25z"
        />
        <path
          d="M2750 5300 c0 -7 6 -10 13 -7 8 3 13 -4 14 -22 0 -14 -1 -18 -4 -8
-5 20 -23 13 -23 -9 0 -8 10 -14 26 -14 23 0 25 3 22 33 -2 24 -8 33 -25 35
-13 2 -23 -2 -23 -8z"
        />
        <path
          d="M1940 5218 c0 -4 4 -8 9 -8 6 0 12 4 15 8 3 5 -1 9 -9 9 -8 0 -15 -4
-15 -9z"
        />
        <path
          d="M2010 5220 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"
        />
        <path
          d="M615 4840 c0 -47 2 -50 25 -49 14 0 19 3 13 6 -8 3 -13 23 -13 49 0
29 -4 44 -12 44 -9 0 -13 -17 -13 -50z"
        />
        <path
          d="M765 4880 c-4 -6 -7 -31 -7 -55 0 -24 3 -49 7 -55 12 -20 21 -10 17
20 -2 17 -9 30 -16 30 -8 0 -8 2 0 8 13 8 25 62 13 62 -4 0 -11 -5 -14 -10z"
        />
        <path
          d="M1097 4846 c23 -27 39 -39 41 -31 5 21 -39 75 -61 75 -15 0 -12 -8
20 -44z"
        />
        <path d="M1135 4880 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
        <path
          d="M1429 4882 c8 -6 11 -17 8 -25 -3 -8 -1 -19 5 -25 6 -6 6 -12 -1 -16
-5 -4 -7 -14 -4 -22 3 -9 0 -20 -8 -26 -12 -8 -11 -9 1 -5 30 8 30 116 0 125
-13 3 -13 2 -1 -6z"
        />
        <path
          d="M1665 4840 c0 -33 4 -50 13 -50 8 0 12 17 12 50 0 33 -4 50 -12 50
-9 0 -13 -17 -13 -50z"
        />
        <path
          d="M1792 4843 c-7 -25 -11 -47 -9 -50 2 -2 10 17 17 42 7 25 11 48 8 50
-2 2 -10 -16 -16 -42z"
        />
        <path
          d="M4104 4875 c-3 -8 -3 -18 0 -24 4 -5 2 -12 -4 -16 -6 -4 -7 -12 -3
-18 4 -7 7 -21 8 -31 0 -11 5 -21 11 -23 7 -2 9 8 6 27 -2 16 -9 30 -16 30 -8
0 -8 2 0 8 14 9 24 62 12 62 -5 0 -11 -7 -14 -15z"
        />
        <path
          d="M4387 4843 c27 -32 39 -41 41 -30 4 19 -45 77 -65 77 -9 0 1 -18 24
-47z"
        />
        <path d="M4415 4880 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
        <path
          d="M4718 4860 c2 -16 9 -30 16 -30 8 0 8 -2 0 -8 -15 -10 -25 -64 -10
-59 14 5 23 54 16 91 -8 40 -28 45 -22 6z"
        />
        <path
          d="M4924 4875 c3 -8 9 -21 12 -29 4 -10 -2 -16 -18 -19 -20 -4 -19 -4 6
-6 21 -1 27 -5 22 -16 -3 -10 1 -15 13 -15 18 0 18 1 0 37 -10 21 -19 43 -19
50 0 7 -5 13 -11 13 -6 0 -8 -7 -5 -15z"
        />
        <path
          d="M5169 4886 c-3 -3 -3 -25 0 -48 5 -34 10 -43 26 -43 16 0 20 7 20 30
0 20 -5 30 -15 30 -8 0 -17 8 -20 18 -3 10 -8 16 -11 13z m41 -61 c0 -16 -6
-25 -15 -25 -15 0 -21 31 -8 43 13 14 23 6 23 -18z"
        />
        <path
          d="M5695 4840 c0 -30 4 -50 11 -50 7 0 9 11 7 28 l-4 27 23 -27 c13 -16
29 -28 36 -28 7 0 3 9 -8 20 -23 23 -25 37 -8 42 22 8 -1 38 -29 38 -27 0 -28
-2 -28 -50z m45 25 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8
0 15 -7 15 -15z"
        />
        <path
          d="M6050 4845 c0 -49 18 -67 45 -45 22 18 19 51 -5 57 -11 3 -20 12 -20
19 0 8 -4 14 -10 14 -5 0 -10 -20 -10 -45z m45 -20 c0 -10 -6 -20 -12 -22 -8
-3 -13 5 -13 22 0 17 5 25 13 23 6 -3 12 -13 12 -23z"
        />
        <path d="M6131 4890 c-8 -21 -4 -100 4 -100 7 0 9 16 7 38 -4 42 -9 69 -11 62z" />
        <path
          d="M6211 4890 c-1 -3 -3 -26 -4 -53 -1 -31 2 -47 10 -47 7 0 10 7 7 18
-5 16 -5 16 9 0 23 -29 30 -21 9 10 -11 15 -22 38 -25 52 -2 14 -5 23 -6 20z"
        />
        <path d="M1618 4873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3818 4865 c-2 -3 -2 -20 0 -40 4 -34 5 -35 51 -35 44 0 47 2 46 27
0 14 6 28 14 31 10 3 13 -3 9 -27 -3 -20 0 -31 7 -31 9 0 10 10 6 33 -6 26
-12 32 -31 32 -21 0 -25 -4 -22 -28 2 -15 -1 -27 -7 -27 -6 0 -11 14 -11 30 0
17 -4 30 -10 30 -5 0 -10 -13 -10 -30 0 -20 -5 -30 -15 -30 -18 0 -21 46 -3
52 8 3 6 6 -4 10 -9 4 -18 5 -20 3z"
        />
        <path
          d="M811 4846 c-18 -21 -4 -56 22 -56 20 0 20 0 1 15 -18 13 -18 14 -1
15 12 0 17 6 15 17 -4 22 -22 27 -37 9z m29 -6 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path
          d="M865 4825 l0 -35 50 0 51 0 -4 35 c-5 45 -30 47 -26 3 l3 -33 -7 30
c-6 24 -13 31 -37 33 -28 3 -30 1 -30 -33z m45 0 c0 -14 -4 -25 -10 -25 -11 0
-14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M985 4810 c0 -41 3 -50 17 -50 11 0 15 5 12 14 -3 8 3 16 16 19 17 4
21 12 18 34 -2 23 -8 29 -33 31 -30 3 -30 2 -30 -48z m45 15 c0 -14 -4 -25
-10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z"
        />
        <path
          d="M1174 4848 c-5 -8 -2 -9 9 -5 10 3 17 2 17 -2 0 -5 -7 -11 -15 -15
-27 -10 -17 -36 13 -36 26 0 28 2 24 35 -3 26 -9 35 -23 35 -10 0 -21 -5 -25
-12z"
        />
        <path
          d="M1245 4825 l0 -35 51 0 51 0 -6 33 c-6 30 -10 32 -51 35 l-45 3 0
-36z m32 -12 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m43 12 c0
-14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M1371 4846 c-18 -21 -4 -56 22 -56 20 0 20 0 1 15 -18 13 -18 14 -1
15 12 0 17 6 15 17 -4 22 -22 27 -37 9z m29 -6 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path
          d="M1524 4846 c-10 -26 1 -51 22 -54 24 -3 38 20 29 49 -7 22 -43 25
-51 5z m36 -21 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0
10 -11 10 -25z"
        />
        <path
          d="M1593 4844 c-3 -8 0 -21 6 -27 14 -14 43 1 47 26 3 22 -44 23 -53 1z
m32 -3 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z"
        />
        <path
          d="M1708 4825 c4 -30 8 -35 31 -35 25 0 27 3 23 35 -5 44 -19 45 -25 3
l-4 -33 -1 33 c-1 19 -7 32 -15 32 -9 0 -12 -11 -9 -35z"
        />
        <path
          d="M1832 4848 c-19 -19 -14 -49 9 -55 12 -3 18 -11 15 -19 -3 -9 1 -14
12 -14 15 0 17 7 12 47 -5 50 -24 65 -48 41z m28 -23 c0 -14 -4 -25 -10 -25
-11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M1905 4825 c0 -21 5 -35 13 -35 7 0 12 14 12 35 0 21 -5 35 -12 35
-8 0 -13 -14 -13 -35z"
        />
        <path
          d="M1940 4850 c0 -5 7 -7 16 -3 15 5 15 3 0 -26 l-17 -31 28 0 c20 0 24
3 14 9 -10 6 -11 13 -2 29 15 29 14 32 -14 32 -14 0 -25 -4 -25 -10z"
        />
        <path
          d="M2010 4825 c0 -22 5 -35 13 -35 9 0 12 11 9 35 -2 19 -8 35 -13 35
-5 0 -9 -16 -9 -35z"
        />
        <path
          d="M3337 4853 c-4 -3 -7 -17 -7 -30 0 -26 26 -41 42 -25 8 8 5 9 -11 5
-18 -4 -21 -1 -21 22 0 22 3 25 21 20 15 -5 19 -4 15 4 -8 12 -29 15 -39 4z"
        />
        <path
          d="M3396 4852 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z"
        />
        <path
          d="M3459 4856 c-6 -9 -1 -66 6 -66 6 0 11 12 10 27 0 14 6 28 14 31 10
3 13 -3 9 -27 -3 -19 0 -31 7 -31 6 0 11 12 10 27 0 14 6 28 14 31 10 3 13 -3
9 -27 -3 -20 0 -31 7 -31 9 0 10 10 6 33 -6 30 -10 32 -49 33 -23 1 -42 1 -43
0z"
        />
        <path d="M3579 4858 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M3607 4848 c6 -7 15 -26 20 -43 9 -28 10 -28 15 -7 4 12 2 20 -3 16
-8 -4 -14 17 -10 39 0 4 -6 7 -15 7 -12 0 -14 -4 -7 -12z"
        />
        <path
          d="M3650 4851 c0 -4 11 -6 25 -3 14 2 25 6 25 8 0 2 -11 4 -25 4 -14 0
-25 -4 -25 -9z"
        />
        <path
          d="M3756 4852 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z"
        />
        <path
          d="M4146 4842 c-10 -30 4 -53 28 -50 31 4 30 62 -1 66 -14 2 -23 -3 -27
-16z m34 -17 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0
10 -11 10 -25z"
        />
        <path
          d="M4220 4824 c0 -23 5 -34 14 -34 9 0 12 7 9 20 -3 12 1 22 11 26 24 9
19 22 -9 22 -22 0 -25 -5 -25 -34z"
        />
        <path
          d="M4283 4844 c-3 -8 0 -21 6 -27 14 -14 43 1 47 26 3 22 -44 23 -53 1z
m32 -3 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z"
        />
        <path
          d="M4460 4850 c0 -7 6 -10 13 -7 8 3 13 -4 14 -22 0 -14 -1 -18 -4 -8
-5 20 -23 13 -23 -9 0 -8 10 -14 26 -14 23 0 25 3 22 33 -2 24 -8 33 -25 35
-13 2 -23 -2 -23 -8z"
        />
        <path
          d="M4525 4825 l0 -35 50 0 51 0 -4 35 c-5 45 -30 47 -26 3 l3 -33 -7 30
c-6 24 -13 30 -37 32 -28 2 -30 0 -30 -32z m45 0 c0 -14 -4 -25 -10 -25 -11 0
-14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M4657 4854 c-4 -4 -7 -18 -7 -31 0 -27 26 -41 43 -24 9 9 8 11 -5 6
-11 -4 -18 -2 -18 4 0 6 7 11 16 11 9 0 14 7 12 17 -3 19 -29 29 -41 17z"
        />
        <path
          d="M4990 4850 c0 -5 7 -7 15 -4 8 4 15 2 15 -4 0 -5 -7 -15 -15 -22 -23
-19 -18 -30 13 -29 21 0 23 2 9 7 -16 7 -16 9 -3 27 21 28 20 35 -9 35 -14 0
-25 -4 -25 -10z"
        />
        <path
          d="M5056 4852 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z"
        />
        <path
          d="M5119 4858 c-6 -22 -1 -68 8 -68 6 0 9 9 6 21 -4 15 0 27 12 35 18
13 18 14 -3 14 -12 0 -22 -1 -23 -2z"
        />
        <path
          d="M5240 4850 c0 -5 7 -7 15 -4 8 4 15 1 15 -5 0 -7 -7 -14 -15 -17 -24
-9 -18 -34 8 -34 20 0 23 4 19 35 -3 26 -9 35 -23 35 -10 0 -19 -4 -19 -10z
m30 -40 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"
        />
        <path d="M5298 4853 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M5375 4841 c-8 -28 5 -51 29 -50 20 0 20 1 -2 10 -27 10 -26 45 1 43
9 -1 17 2 17 7 0 17 -39 9 -45 -10z"
        />
        <path
          d="M5440 4850 c0 -5 7 -7 15 -4 8 4 15 1 15 -5 0 -7 -7 -14 -15 -17 -8
-4 -15 -12 -15 -20 0 -10 15 -14 58 -14 l58 0 -4 34 c-3 28 -8 34 -28 34 -20
0 -24 -6 -24 -29 0 -16 -4 -29 -10 -29 -5 0 -10 14 -10 30 0 23 -4 30 -20 30
-11 0 -20 -4 -20 -10z m100 -25 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11
11 13 8 13 -18z m-70 -15 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z"
        />
        <path
          d="M5797 4854 c-4 -4 -7 -18 -7 -31 0 -26 25 -42 42 -25 7 7 5 10 -7 9
-10 -1 -20 4 -22 11 -3 7 4 12 17 12 13 0 20 5 18 13 -5 14 -31 21 -41 11z"
        />
        <path
          d="M5854 4848 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 9 -10 20 -10 23 0 27 28 5 36 -8 4 -15 11 -15 16 0 6 7 7 16 4 8 -3
12 -2 9 4 -8 13 -22 13 -31 -2z"
        />
        <path
          d="M5909 4855 c0 0 -1 -21 -2 -48 -1 -31 2 -47 10 -47 7 0 10 7 7 15 -4
9 0 15 9 15 22 0 39 26 32 49 -4 14 -14 20 -31 19 -13 -1 -24 -3 -25 -3z m41
-30 c0 -26 -14 -33 -25 -15 -9 14 1 40 15 40 5 0 10 -11 10 -25z"
        />
        <path
          d="M5980 4838 c0 -32 14 -51 35 -46 10 1 18 6 17 11 -1 4 -2 18 -2 32 0
14 -4 25 -8 25 -5 0 -7 -13 -4 -30 3 -18 0 -30 -7 -30 -6 0 -11 14 -11 30 0
17 -4 30 -10 30 -5 0 -10 -10 -10 -22z"
        />
        <path d="M6169 4858 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path d="M6235 4850 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
        <path
          d="M6280 4850 c0 -5 7 -7 15 -4 23 9 18 -14 -5 -26 -25 -13 -17 -30 14
-30 19 0 21 4 18 35 -3 26 -9 35 -23 35 -10 0 -19 -4 -19 -10z m30 -40 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"
        />
        <path
          d="M6344 4848 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 9 -10 20 -10 23 0 27 28 5 36 -8 4 -15 11 -15 16 0 6 7 7 16 4 8 -3
12 -2 9 4 -8 13 -22 13 -31 -2z"
        />
        <path
          d="M6398 4825 c-4 -33 -3 -35 27 -35 27 0 31 3 30 27 0 14 6 28 14 31
10 3 13 -3 9 -27 -5 -31 -4 -31 42 -31 43 0 46 2 45 27 0 14 6 28 14 31 10 3
13 -3 9 -27 -3 -19 0 -31 7 -31 7 0 9 13 7 35 -4 32 -7 35 -31 32 -22 -3 -25
-8 -23 -30 2 -15 -1 -27 -7 -27 -6 0 -11 14 -11 31 0 16 -4 28 -9 25 -4 -3 -6
-17 -3 -31 3 -18 0 -25 -11 -25 -10 0 -17 11 -19 28 -2 21 -8 27 -28 27 -21 0
-25 -4 -22 -28 2 -15 -1 -27 -7 -27 -6 0 -11 14 -11 30 0 42 -17 38 -22 -5z"
        />
        <path
          d="M3690 4834 c0 -13 18 -39 24 -34 2 3 -2 14 -10 24 -8 11 -14 16 -14
10z"
        />
        <path
          d="M5310 4834 c0 -13 18 -39 24 -34 2 3 -2 14 -10 24 -8 11 -14 16 -14
10z"
        />
        <path
          d="M670 4800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M1065 4800 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"
        />
        <path
          d="M4355 4800 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"
        />
        <path
          d="M4885 4800 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"
        />
        <path
          d="M1596 4791 c5 -5 18 -12 29 -15 12 -3 9 -4 -7 -2 -16 2 -28 -1 -28
-6 0 -17 55 -4 58 15 3 13 -3 17 -29 17 -20 0 -29 -4 -23 -9z"
        />
        <path
          d="M4286 4791 c5 -5 18 -12 29 -15 12 -3 9 -4 -7 -2 -16 2 -28 -1 -28
-6 0 -17 55 -4 58 15 3 13 -3 17 -29 17 -20 0 -29 -4 -23 -9z"
        />
        <path d="M3690 4770 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path d="M5310 4770 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path d="M3608 4763 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3340 4688 c0 -4 12 -6 26 -5 15 1 29 -4 31 -10 3 -9 -7 -13 -31 -13
-28 0 -36 -4 -36 -18 0 -41 45 -55 73 -24 22 24 21 45 -1 65 -17 16 -62 20
-62 5z m60 -45 c0 -5 -5 -15 -10 -23 -7 -11 -14 -12 -27 -4 -30 18 -27 34 6
34 17 0 31 -3 31 -7z"
        />
        <path
          d="M3611 4700 c-1 -3 -3 -26 -4 -53 -1 -31 2 -47 10 -47 7 0 10 7 7 18
-5 16 -5 16 9 0 8 -10 20 -18 26 -18 8 0 7 5 -3 16 -21 20 -20 31 2 43 16 10
16 10 0 11 -10 0 -18 -4 -18 -10 0 -23 -19 -8 -23 18 -2 15 -5 25 -6 22z"
        />
        <path
          d="M3895 4650 c0 -29 5 -50 11 -50 6 0 9 14 7 33 l-4 32 19 -33 18 -33
12 26 c16 34 15 43 -1 21 -12 -16 -14 -14 -30 18 -9 20 -20 36 -24 36 -5 0 -8
-22 -8 -50z"
        />
        <path
          d="M3979 4693 c-6 -75 -3 -93 16 -92 11 0 14 3 8 6 -7 2 -13 22 -13 43
0 21 6 41 13 43 7 3 5 6 -5 6 -10 1 -18 -2 -19 -6z"
        />
        <path d="M4221 4700 c-8 -21 -4 -100 4 -100 7 0 9 16 7 38 -4 42 -9 69 -11 62z" />
        <path d="M4261 4700 c-8 -21 -4 -100 4 -100 7 0 9 16 7 38 -4 42 -9 69 -11 62z" />
        <path
          d="M4855 4690 c-3 -5 -1 -10 4 -10 15 0 14 -67 -1 -73 -7 -3 -4 -6 7 -6
18 -1 20 4 17 49 -4 47 -13 62 -27 40z"
        />
        <path
          d="M4915 4691 c-3 -7 0 -8 9 -5 11 4 19 -2 26 -17 l10 -24 -3 25 c-2 26
-30 39 -42 21z"
        />
        <path
          d="M4999 4698 c-1 -2 -3 -22 -4 -46 -2 -31 1 -45 12 -49 23 -9 40 14 26
36 -8 13 -8 22 -1 29 13 13 2 32 -18 32 -8 0 -15 -1 -15 -2z m22 -21 c-1 -12
-15 -9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m7 -55 c2 -7 -3 -12 -12 -12
-9 0 -16 7 -16 16 0 17 22 14 28 -4z"
        />
        <path
          d="M5640 4695 c-1 -5 -2 -13 -1 -17 0 -5 -6 -8 -14 -8 -22 0 -39 -38
-24 -56 12 -15 45 -19 51 -6 2 4 1 27 -3 52 -3 25 -7 41 -9 35z m-5 -60 c0
-10 -6 -20 -12 -22 -8 -3 -13 5 -13 22 0 17 5 25 13 23 6 -3 12 -13 12 -23z"
        />
        <path
          d="M5710 4695 c-1 -5 -2 -13 -1 -17 0 -5 -6 -8 -14 -8 -22 0 -39 -38
-24 -56 12 -15 45 -19 51 -6 2 4 1 27 -3 52 -3 25 -7 41 -9 35z m-5 -60 c0
-10 -6 -20 -12 -22 -8 -3 -13 5 -13 22 0 17 5 25 13 23 6 -3 12 -13 12 -23z"
        />
        <path
          d="M3439 4665 c0 0 -1 -15 -2 -33 -2 -32 -2 -32 43 -32 l46 0 -4 34 c-4
33 -6 34 -43 33 -21 -1 -39 -2 -40 -2z m31 -30 c0 -14 -4 -25 -10 -25 -11 0
-14 33 -3 43 11 11 13 8 13 -18z m40 0 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10
25 0 14 5 25 10 25 6 0 10 -11 10 -25z"
        />
        <path
          d="M3562 4663 c28 -7 31 -33 3 -33 -18 0 -23 -4 -19 -15 14 -35 61 -2
49 35 -4 13 -14 20 -28 19 -16 -1 -17 -3 -5 -6z"
        />
        <path
          d="M4025 4660 c-3 -5 0 -7 8 -4 8 3 17 -2 20 -10 5 -11 0 -16 -14 -16
-24 0 -21 -23 3 -28 18 -4 31 23 23 49 -7 20 -30 25 -40 9z"
        />
        <path
          d="M4084 4656 c-11 -28 1 -51 26 -53 l25 -3 -23 10 c-27 11 -26 46 1 44
9 -1 14 2 11 7 -8 14 -34 11 -40 -5z"
        />
        <path
          d="M4162 4663 c28 -7 31 -33 3 -33 -18 0 -23 -4 -19 -15 14 -35 61 -2
49 35 -4 13 -14 20 -28 19 -16 -1 -17 -3 -5 -6z"
        />
        <path
          d="M4296 4662 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z
m31 -48 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"
        />
        <path
          d="M4354 4658 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 9 -10 20 -10 25 0 26 28 3 47 -17 14 -17 14 0 9 11 -3 16 -2 12 4 -8
13 -22 13 -31 -2z"
        />
        <path
          d="M4421 4670 c-1 -3 -3 -20 -4 -38 -2 -32 -2 -32 43 -32 l46 0 -4 34
c-3 28 -8 34 -28 34 -20 0 -24 -6 -24 -29 0 -40 -18 -37 -23 4 -2 17 -5 30 -6
27z m69 -35 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M4531 4670 c-1 -3 -3 -20 -4 -38 -2 -32 -2 -32 43 -32 l46 0 -4 34
c-3 28 -8 34 -28 34 -20 0 -24 -6 -24 -29 0 -40 -18 -37 -23 4 -2 17 -5 30 -6
27z m69 -35 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M5111 4652 c-17 -31 25 -73 43 -43 4 5 -3 7 -14 4 -16 -4 -20 0 -20
22 0 20 4 26 15 21 8 -3 15 -1 15 4 0 17 -29 11 -39 -8z"
        />
        <path d="M5179 4668 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M5419 4666 c-6 -9 -1 -66 6 -66 6 0 11 12 10 27 0 14 6 28 14 31 10
3 13 -3 9 -27 -3 -19 0 -31 7 -31 6 0 11 12 10 27 0 14 6 28 14 31 10 3 13 -3
9 -27 -3 -20 0 -31 7 -31 9 0 10 10 6 33 -6 30 -10 32 -49 33 -23 1 -42 1 -43
0z"
        />
        <path
          d="M5535 4661 c-3 -6 0 -8 8 -5 10 4 15 -2 15 -20 0 -14 -5 -26 -10 -26
-5 0 -6 5 -3 10 3 6 1 10 -4 10 -6 0 -11 -7 -11 -15 0 -10 10 -15 29 -15 27 0
28 2 22 32 -7 33 -33 49 -46 29z"
        />
        <path
          d="M5745 4660 c-3 -5 0 -7 8 -4 8 3 17 -2 20 -10 5 -11 0 -16 -14 -16
-24 0 -21 -23 3 -28 18 -4 31 23 23 49 -7 20 -30 25 -40 9z"
        />
        <path
          d="M5804 4658 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 9 -10 20 -10 25 0 26 28 3 47 -17 14 -17 14 0 9 11 -3 16 -2 12 4 -8
13 -22 13 -31 -2z"
        />
        <path
          d="M5871 4670 c-1 -3 -3 -20 -4 -38 -2 -28 1 -32 23 -32 21 0 26 5 25
27 0 14 6 28 14 31 10 3 13 -3 9 -27 -3 -19 0 -31 7 -31 7 0 9 13 7 35 -4 32
-7 35 -31 32 -22 -3 -25 -8 -23 -30 2 -15 -1 -27 -7 -27 -5 0 -12 15 -14 33
-2 17 -5 30 -6 27z"
        />
        <path
          d="M5976 4662 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z
m31 -48 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"
        />
        <path
          d="M6246 4662 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z"
        />
        <path
          d="M6310 4653 c0 -9 8 -22 18 -30 16 -14 16 -14 0 -9 -10 3 -18 1 -18
-4 0 -14 27 -12 36 2 4 6 0 16 -9 23 -21 15 -22 26 -2 19 8 -4 15 -2 15 3 0 5
-9 9 -20 10 -12 1 -20 -4 -20 -14z"
        />
        <path
          d="M6364 4659 c-5 -9 -2 -10 9 -6 9 3 19 2 22 -3 3 -5 -4 -11 -15 -15
-31 -10 -25 -35 9 -35 27 0 28 2 22 32 -7 34 -33 49 -47 27z m36 -39 c0 -5 -7
-10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z"
        />
        <path
          d="M6430 4653 c0 -9 8 -22 18 -30 16 -14 16 -14 0 -9 -10 3 -18 1 -18
-4 0 -14 27 -12 36 2 4 6 0 16 -9 23 -21 15 -22 26 -2 19 8 -4 15 -2 15 3 0 5
-9 9 -20 10 -12 1 -20 -4 -20 -14z"
        />
        <path
          d="M6486 4662 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z"
        />
        <path
          d="M6549 4665 c-7 -3 -1 -65 6 -65 6 0 11 12 10 27 0 14 6 28 14 31 10
3 13 -3 9 -27 -3 -19 0 -31 7 -31 7 0 9 13 7 35 -4 29 -8 34 -28 33 -13 -1
-24 -3 -25 -3z"
        />
        <path
          d="M4925 4620 c-18 -20 -17 -20 13 -20 18 0 32 4 32 9 0 5 -10 7 -22 3
-21 -5 -22 -4 -9 11 19 24 8 21 -14 -3z"
        />
        <path d="M5068 4633 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M3355 4500 c-4 -6 -7 -31 -7 -55 0 -47 7 -65 24 -65 6 0 6 6 -2 15
-7 9 -10 18 -7 22 4 3 2 12 -4 19 -7 8 -7 14 1 19 6 3 8 11 4 16 -3 5 0 16 6
24 8 10 9 15 2 15 -6 0 -14 -5 -17 -10z"
        />
        <path
          d="M3648 4465 c3 -36 1 -45 -12 -45 -10 0 -16 9 -16 25 0 14 6 25 13 25
9 0 9 2 -1 9 -8 4 -28 5 -45 2 -28 -6 -32 -11 -29 -34 2 -15 -1 -27 -7 -27 -6
0 -11 11 -11 25 0 29 -26 44 -43 25 -10 -10 -8 -12 5 -7 10 3 20 2 23 -3 3 -5
-4 -11 -15 -15 -38 -12 -23 -35 23 -35 40 0 43 2 42 27 0 18 6 29 18 31 13 3
17 -3 17 -27 0 -30 1 -31 50 -31 42 0 52 4 66 25 15 23 15 27 0 50 -12 18 -25
25 -49 25 -32 0 -32 0 -29 -45z m66 13 c13 -21 -2 -51 -28 -56 -13 -3 -16 5
-16 38 0 33 3 41 16 38 9 -2 22 -11 28 -20z m-184 -48 c0 -5 -7 -10 -16 -10
-8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z"
        />
        <path
          d="M3929 4497 c7 -9 13 -32 13 -52 0 -20 -6 -43 -13 -52 -12 -14 -12
-16 1 -11 30 10 30 116 0 126 -13 5 -13 3 -1 -11z"
        />
        <path d="M4301 4510 c-8 -21 -4 -100 4 -100 7 0 9 16 7 38 -4 42 -9 69 -11 62z" />
        <path
          d="M4740 4505 c-1 -5 -2 -13 -1 -17 0 -5 -6 -8 -14 -8 -16 0 -31 -43
-20 -59 7 -13 42 -15 47 -3 2 4 1 27 -3 52 -3 25 -7 41 -9 35z m0 -60 c0 -26
-14 -33 -25 -15 -9 14 1 40 15 40 5 0 10 -11 10 -25z"
        />
        <path
          d="M5045 4500 c-4 -6 -7 -31 -7 -55 0 -47 7 -65 24 -65 7 0 6 5 -2 15
-6 8 -12 30 -12 50 0 20 6 42 12 50 8 10 9 15 2 15 -6 0 -14 -5 -17 -10z"
        />
        <path
          d="M5260 4505 c-1 -5 -2 -13 -1 -17 0 -5 -6 -8 -14 -8 -16 0 -31 -43
-20 -59 7 -13 42 -15 47 -3 2 4 1 27 -3 52 -3 25 -7 41 -9 35z m0 -60 c0 -26
-14 -33 -25 -15 -9 14 1 40 15 40 5 0 10 -11 10 -25z"
        />
        <path
          d="M5295 4460 c0 -49 1 -50 31 -50 37 0 54 16 54 50 0 34 -17 50 -54 50
-30 0 -31 -1 -31 -50z m65 20 c14 -26 -3 -60 -30 -60 -17 0 -20 7 -20 40 0 33
3 40 20 40 10 0 24 -9 30 -20z"
        />
        <path
          d="M5578 4497 c8 -9 11 -21 8 -26 -4 -5 -2 -13 4 -16 8 -5 8 -11 1 -19
-6 -7 -8 -16 -4 -19 3 -4 0 -14 -8 -23 -12 -15 -12 -17 1 -12 30 10 30 116 0
126 -13 4 -13 3 -2 -11z"
        />
        <path
          d="M5940 4465 c0 -25 -4 -45 -10 -45 -5 0 -10 14 -10 30 0 42 -17 38
-22 -5 -4 -34 -3 -35 32 -35 l35 0 -3 50 c-5 62 -22 66 -22 5z"
        />
        <path
          d="M6450 4505 c-1 -5 -2 -13 -1 -17 0 -5 -6 -8 -14 -8 -16 0 -31 -43
-20 -59 7 -13 42 -15 47 -3 2 4 1 27 -3 52 -3 25 -7 41 -9 35z m0 -60 c0 -26
-14 -33 -25 -15 -9 14 1 40 15 40 5 0 10 -11 10 -25z"
        />
        <path
          d="M6551 4510 c-1 -3 -3 -26 -4 -53 -1 -31 2 -47 10 -47 7 0 10 7 7 18
-5 16 -5 16 9 0 8 -10 20 -18 26 -18 8 0 7 5 -3 16 -21 20 -20 31 2 43 16 10
16 10 0 11 -10 0 -18 -4 -18 -10 0 -23 -19 -8 -23 18 -2 15 -5 25 -6 22z"
        />
        <path d="M3458 4490 c-5 -17 3 -80 11 -80 7 0 6 79 -2 86 -3 3 -7 0 -9 -6z" />
        <path d="M3818 4490 c-5 -17 3 -80 11 -80 7 0 6 79 -2 86 -3 3 -7 0 -9 -6z" />
        <path
          d="M5463 4489 c-12 -12 -2 -71 12 -76 9 -3 12 74 3 83 -2 2 -9 -1 -15
-7z"
        />
        <path d="M6088 4490 c-5 -17 3 -80 11 -80 7 0 6 79 -2 86 -3 3 -7 0 -9 -6z" />
        <path
          d="M4438 4485 c-2 -3 -2 -20 0 -38 l4 -34 63 -2 c62 -1 62 -1 57 25 -3
16 1 30 9 35 10 6 8 9 -8 9 -19 0 -23 -6 -23 -30 0 -16 -4 -30 -10 -30 -5 0
-10 14 -10 30 0 23 -4 30 -20 30 -11 0 -20 -4 -20 -10 0 -5 7 -7 15 -4 24 10
17 -12 -10 -30 -13 -9 -27 -16 -30 -16 -11 0 -5 48 7 52 8 3 6 6 -4 10 -9 4
-18 5 -20 3z m72 -55 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2
0 4 -4 4 -10z"
        />
        <path
          d="M3400 4463 c0 -9 8 -22 18 -30 16 -14 16 -14 0 -9 -10 3 -18 1 -18
-4 0 -14 27 -12 36 2 4 6 0 16 -9 23 -21 15 -22 26 -2 19 8 -4 15 -2 15 3 0 5
-9 9 -20 10 -12 1 -20 -4 -20 -14z"
        />
        <path
          d="M3755 4471 c-3 -6 0 -8 8 -5 10 4 15 -2 15 -20 0 -14 -5 -26 -10 -26
-5 0 -6 5 -3 10 3 6 1 10 -4 10 -6 0 -11 -7 -11 -15 0 -10 10 -15 29 -15 27 0
28 2 22 32 -7 33 -33 49 -46 29z"
        />
        <path
          d="M3875 4471 c19 -9 19 -10 -3 -10 -34 -1 -27 -45 8 -48 25 -3 25 -3 3
7 -29 12 -30 30 -2 30 26 0 20 24 -7 27 -18 3 -18 2 1 -6z"
        />
        <path
          d="M4041 4462 c-17 -31 25 -73 43 -43 4 5 -3 7 -14 4 -16 -4 -20 0 -20
22 0 20 4 26 15 21 8 -3 15 -1 15 4 0 17 -29 11 -39 -8z"
        />
        <path d="M4109 4478 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path d="M4259 4478 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M4601 4480 c-1 -3 -3 -20 -4 -38 -2 -27 2 -32 19 -32 12 0 25 7 28
16 3 8 10 12 16 9 6 -4 8 -11 5 -16 -4 -5 2 -9 11 -9 16 0 16 1 -1 20 -10 11
-15 20 -11 20 4 0 1 7 -8 15 -22 22 -43 19 -23 -3 13 -14 14 -21 5 -30 -19
-19 -26 -14 -31 21 -2 17 -5 30 -6 27z"
        />
        <path
          d="M4776 4472 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z"
        />
        <path
          d="M4839 4476 c-6 -10 -1 -66 6 -66 6 0 11 12 10 27 0 14 6 28 14 31 10
3 13 -3 9 -27 -3 -20 0 -31 7 -31 9 0 10 10 6 33 -5 24 -12 32 -29 33 -12 1
-22 1 -23 0z"
        />
        <path
          d="M5090 4459 c-9 -15 -8 -24 1 -35 13 -16 35 -19 43 -5 4 5 -3 7 -14 4
-14 -4 -20 0 -20 11 0 9 8 16 20 16 13 0 18 5 14 15 -9 21 -31 19 -44 -6z"
        />
        <path
          d="M5150 4444 c0 -31 3 -34 28 -34 26 0 28 3 24 34 -3 28 -8 34 -28 34
-21 0 -24 -5 -24 -34z m40 1 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11
13 8 13 -18z"
        />
        <path
          d="M5400 4470 c0 -5 7 -7 15 -4 8 4 15 1 15 -5 0 -7 -7 -14 -15 -17 -24
-9 -18 -34 8 -34 20 0 23 4 19 35 -3 26 -9 35 -23 35 -10 0 -19 -4 -19 -10z
m30 -40 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"
        />
        <path
          d="M5504 4466 c-11 -28 0 -51 26 -53 25 -3 25 -3 3 7 -29 12 -30 30 -2
30 12 0 19 5 17 13 -7 18 -37 21 -44 3z m33 -2 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z"
        />
        <path
          d="M5685 4461 c-8 -28 5 -51 29 -50 20 0 20 1 -2 10 -27 10 -26 45 1 43
9 -1 17 2 17 7 0 17 -39 9 -45 -10z"
        />
        <path
          d="M5748 4445 c-3 -27 0 -35 12 -35 12 0 15 8 12 35 -2 19 -8 35 -12 35
-4 0 -10 -16 -12 -35z"
        />
        <path
          d="M6124 4469 c-5 -9 -2 -10 9 -6 9 3 19 2 22 -3 3 -5 -4 -11 -15 -15
-39 -12 -23 -35 24 -35 40 0 44 2 39 21 -4 15 0 27 13 36 18 14 18 14 -6 11
-21 -2 -24 -8 -22 -30 2 -16 -1 -28 -7 -28 -5 0 -11 12 -13 27 -3 30 -31 43
-44 22z m36 -39 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9
-4 9 -10z"
        />
        <path
          d="M6238 4445 c-4 -32 -2 -35 20 -35 14 0 31 8 38 18 13 16 13 16 14 0
0 -10 7 -18 17 -18 14 0 14 2 -2 20 -10 11 -15 20 -11 20 4 0 1 7 -8 15 -22
22 -43 19 -23 -3 13 -14 14 -21 5 -30 -19 -19 -28 -14 -28 18 0 42 -17 38 -22
-5z"
        />
        <path
          d="M6355 4470 c-3 -5 0 -7 8 -4 8 3 17 -2 20 -10 5 -11 0 -16 -14 -16
-24 0 -21 -23 3 -28 18 -4 31 23 23 49 -7 20 -30 25 -40 9z"
        />
        <path
          d="M6486 4472 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z"
        />
        <path d="M3998 4443 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M5638 4443 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
        <path
          d="M3355 4310 c-4 -6 -7 -31 -7 -55 0 -47 7 -65 24 -65 6 0 6 6 -2 15
-7 9 -10 18 -7 22 4 3 2 12 -4 19 -7 8 -7 14 1 19 6 3 8 11 4 16 -3 5 0 16 6
24 8 10 9 15 2 15 -6 0 -14 -5 -17 -10z"
        />
        <path
          d="M3435 4311 c-3 -6 -3 -11 2 -12 4 0 -2 -4 -15 -10 -18 -7 -23 -15
-20 -36 2 -22 8 -28 28 -28 23 0 24 3 21 45 -3 50 -6 56 -16 41z m3 -60 c3
-16 -1 -22 -10 -19 -7 3 -15 15 -16 27 -3 16 1 22 10 19 7 -3 15 -15 16 -27z"
        />
        <path
          d="M3614 4295 c-15 -23 -15 -27 0 -50 19 -29 48 -32 67 -7 11 14 11 15
-1 5 -34 -31 -70 0 -55 47 7 23 17 25 48 9 21 -10 21 -10 3 5 -28 22 -43 20
-62 -9z"
        />
        <path
          d="M3969 4307 c7 -9 13 -32 13 -52 0 -20 -6 -43 -13 -52 -12 -14 -12
-16 1 -11 30 10 30 116 0 126 -13 5 -13 3 -1 -11z"
        />
        <path
          d="M5439 4319 c0 -2 -1 -25 -2 -52 -2 -46 -2 -47 29 -47 30 0 31 1 25
32 -9 41 -29 49 -23 8 2 -17 0 -30 -6 -30 -6 0 -10 18 -10 40 0 23 -2 44 -6
47 -3 4 -6 4 -7 2z"
        />
        <path
          d="M5690 4275 c0 -49 18 -67 45 -45 22 18 19 51 -5 57 -11 3 -20 12 -20
19 0 8 -4 14 -10 14 -5 0 -10 -20 -10 -45z m45 -20 c0 -10 -6 -20 -12 -22 -8
-3 -13 5 -13 22 0 17 5 25 13 23 6 -3 12 -13 12 -23z"
        />
        <path
          d="M6200 4315 c-1 -5 -2 -13 -1 -17 0 -5 -6 -8 -14 -8 -22 0 -39 -38
-24 -56 12 -15 45 -19 51 -6 2 4 1 27 -3 52 -3 25 -7 41 -9 35z m-5 -60 c0
-10 -6 -20 -12 -22 -8 -3 -13 5 -13 22 0 17 5 25 13 23 6 -3 12 -13 12 -23z"
        />
        <path d="M4178 4300 c-5 -17 3 -80 11 -80 7 0 6 79 -2 86 -3 3 -7 0 -9 -6z" />
        <path
          d="M3918 4295 c-2 -3 -2 -20 0 -40 3 -25 9 -35 21 -34 10 0 11 2 4 6
-16 6 -17 50 -1 55 8 3 6 6 -4 10 -9 4 -18 5 -20 3z"
        />
        <path
          d="M3475 4281 c-3 -6 0 -8 8 -5 10 4 15 -2 15 -20 0 -14 -5 -26 -10 -26
-5 0 -6 5 -3 10 3 6 1 10 -4 10 -6 0 -11 -7 -11 -15 0 -10 10 -15 29 -15 27 0
28 2 22 32 -7 33 -33 49 -46 29z"
        />
        <path
          d="M3543 4260 c6 -16 15 -30 18 -30 4 0 2 14 -4 30 -6 17 -15 30 -18 30
-4 0 -2 -13 4 -30z"
        />
        <path
          d="M3711 4276 c-19 -23 -4 -58 22 -54 17 2 22 10 22 33 0 34 -24 45 -44
21z m34 -21 c0 -10 -5 -20 -11 -22 -13 -4 -27 24 -18 38 9 15 29 4 29 -16z"
        />
        <path
          d="M3778 4255 c3 -29 8 -35 28 -35 21 0 24 5 24 35 0 22 -5 35 -13 35
-9 0 -12 -10 -9 -30 3 -18 0 -30 -7 -30 -6 0 -11 14 -11 30 0 17 -4 30 -8 30
-4 0 -6 -16 -4 -35z"
        />
        <path
          d="M3849 4286 c-6 -10 -1 -66 6 -66 6 0 11 12 10 27 0 14 6 28 14 31 10
3 13 -3 9 -27 -3 -20 0 -31 7 -31 9 0 10 10 6 33 -5 24 -12 32 -29 33 -12 1
-22 1 -23 0z"
        />
        <path
          d="M4216 4282 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z"
        />
        <path
          d="M4287 4283 c-16 -15 -7 -55 14 -60 12 -3 18 -11 15 -19 -3 -8 0 -14
7 -14 8 0 11 15 9 50 -3 39 -8 50 -21 50 -10 0 -21 -3 -24 -7z m33 -28 c0 -16
-6 -25 -15 -25 -9 0 -15 9 -15 25 0 16 6 25 15 25 9 0 15 -9 15 -25z"
        />
        <path
          d="M4358 4256 c9 -19 20 -32 25 -29 5 3 4 9 -2 13 -7 4 -13 17 -14 28
-1 12 -6 22 -13 22 -8 0 -7 -10 4 -34z"
        />
        <path
          d="M4431 4290 c-1 -3 -3 -20 -4 -38 -2 -32 -2 -32 43 -32 43 0 46 2 45
27 0 14 6 28 14 31 10 3 13 -3 9 -27 -3 -19 0 -31 7 -31 7 0 9 13 7 34 -4 33
-5 34 -48 33 -41 -1 -44 -2 -44 -29 0 -39 -18 -35 -23 5 -2 17 -5 30 -6 27z
m69 -35 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M4727 4283 c-3 -5 -5 -19 -5 -33 0 -14 -1 -32 -3 -40 -3 -11 5 -15
26 -17 20 -1 23 0 8 4 -31 6 -29 27 2 21 13 -3 22 -1 19 3 -3 5 -15 9 -27 10
l-22 0 21 9 c12 4 24 18 27 29 4 17 1 21 -18 21 -13 0 -26 -3 -28 -7z m31 -21
c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z"
        />
        <path
          d="M4790 4268 c0 -32 14 -51 35 -46 10 1 18 6 17 11 -1 4 -2 18 -2 32 0
14 -4 25 -8 25 -5 0 -7 -13 -4 -30 3 -18 0 -30 -7 -30 -6 0 -11 14 -11 30 0
17 -4 30 -10 30 -5 0 -10 -10 -10 -22z"
        />
        <path
          d="M4869 4286 c0 0 -1 -16 -2 -34 -2 -30 0 -32 29 -32 30 0 31 1 25 33
-5 24 -12 32 -29 33 -12 1 -22 1 -23 0z m31 -31 c0 -14 -4 -25 -10 -25 -11 0
-14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M4938 4255 c3 -28 8 -35 26 -35 30 0 33 5 28 40 -5 37 -18 40 -17 3
0 -14 -6 -28 -12 -30 -9 -3 -13 6 -13 26 0 17 -4 31 -8 31 -4 0 -6 -16 -4 -35z"
        />
        <path
          d="M5161 4276 c-19 -23 -4 -58 22 -54 17 2 22 10 22 33 0 34 -24 45 -44
21z m34 -21 c0 -10 -5 -20 -11 -22 -13 -4 -27 24 -18 38 9 15 29 4 29 -16z"
        />
        <path
          d="M5243 4283 c16 -4 16 -7 -4 -34 l-20 -29 30 0 c17 0 31 4 31 9 0 5
-10 7 -22 3 l-22 -5 23 31 22 32 -28 -1 c-17 -1 -21 -3 -10 -6z"
        />
        <path
          d="M5510 4269 c-9 -15 -8 -24 1 -35 13 -16 35 -19 43 -5 4 5 -3 7 -14 4
-14 -4 -20 0 -20 11 0 9 8 16 20 16 13 0 18 5 14 15 -9 21 -31 19 -44 -6z"
        />
        <path
          d="M5574 4278 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 9 -10 20 -10 23 0 27 28 5 36 -8 4 -15 11 -15 16 0 6 7 7 16 4 8 -3
12 -2 9 4 -8 13 -22 13 -31 -2z"
        />
        <path
          d="M5630 4280 c0 -5 7 -7 15 -4 23 9 18 -14 -5 -26 -25 -13 -17 -30 14
-30 19 0 21 4 18 35 -3 26 -9 35 -23 35 -10 0 -19 -4 -19 -10z m30 -40 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"
        />
        <path d="M5769 4288 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M5809 4286 c0 0 -1 -16 -2 -34 l-2 -32 61 0 61 0 -5 31 c-5 34 -27
48 -45 29 -10 -10 -8 -12 5 -7 10 3 20 2 23 -3 3 -5 -4 -11 -15 -15 -11 -3
-21 -12 -21 -18 -1 -7 -4 2 -8 18 -4 22 -12 30 -29 31 -12 1 -22 1 -23 0z m31
-31 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z m70 -15 c0
-5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z"
        />
        <path
          d="M6091 4276 c-19 -23 -4 -58 22 -54 17 2 22 10 22 33 0 34 -24 45 -44
21z m34 -21 c0 -10 -5 -20 -11 -22 -13 -4 -27 24 -18 38 9 15 29 4 29 -16z"
        />
        <path
          d="M6235 4280 c-3 -5 0 -7 8 -4 8 3 17 -2 20 -10 5 -11 0 -16 -14 -16
-24 0 -21 -23 3 -28 18 -4 31 23 23 49 -7 20 -30 25 -40 9z"
        />
        <path
          d="M6290 4254 c0 -31 3 -34 28 -34 26 0 28 3 24 34 -3 28 -8 34 -28 34
-21 0 -24 -5 -24 -34z m40 1 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11
13 8 13 -18z"
        />
        <path d="M6369 4288 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M6404 4278 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 5 -10 11 -10 6 0 8 -7 5 -16 -4 -10 -1 -14 6 -12 20 7 21 59 1 75 -17
14 -17 14 0 9 11 -3 16 -2 12 4 -8 13 -22 13 -31 -2z"
        />
        <path
          d="M6464 4278 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 9 -10 20 -10 23 0 27 28 5 36 -8 4 -15 11 -15 16 0 6 7 7 16 4 8 -3
12 -2 9 4 -8 13 -22 13 -31 -2z"
        />
        <path
          d="M6518 4255 l-4 -35 48 0 c26 0 48 4 48 9 0 5 -10 7 -22 3 l-22 -5 23
31 22 32 -28 -1 c-16 -1 -21 -3 -11 -6 16 -4 16 -6 2 -29 -22 -32 -34 -30 -34
6 0 42 -17 38 -22 -5z"
        />
        <path d="M3540 4200 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path d="M4271 4130 c-8 -21 -4 -100 4 -100 7 0 9 16 7 38 -4 42 -9 69 -11 62z" />
        <path
          d="M3963 4109 c-12 -12 -2 -71 12 -76 9 -3 12 74 3 83 -2 2 -9 -1 -15
-7z"
        />
        <path
          d="M3329 4096 c-6 -9 -1 -66 6 -66 6 0 11 12 10 27 0 14 6 28 14 31 10
3 13 -3 9 -27 -3 -19 0 -31 7 -31 6 0 11 12 10 27 0 14 6 28 14 31 10 3 13 -3
9 -27 -3 -20 0 -31 7 -31 9 0 10 10 6 33 -6 30 -10 32 -49 33 -23 1 -42 1 -43
0z"
        />
        <path
          d="M3446 4092 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z
m31 -48 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"
        />
        <path
          d="M3523 4093 c16 -4 16 -7 -4 -34 l-20 -29 30 0 c17 0 31 4 31 9 0 5
-10 7 -21 4 -21 -6 -21 -5 -4 21 22 33 35 33 35 1 0 -26 20 -41 43 -32 9 3 12
16 9 36 -5 38 -18 41 -17 4 0 -14 -6 -28 -12 -30 -9 -3 -13 6 -13 26 0 29 -2
31 -37 30 -21 -1 -30 -3 -20 -6z"
        />
        <path
          d="M3640 4064 c0 -31 3 -34 28 -34 26 0 28 3 24 34 -3 28 -8 34 -28 34
-21 0 -24 -5 -24 -34z m40 1 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11
13 8 13 -18z"
        />
        <path d="M3719 4098 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M3763 4070 c6 -16 15 -30 18 -30 4 0 2 14 -4 30 -6 17 -15 30 -18 30
-4 0 -2 -13 4 -30z"
        />
        <path
          d="M3837 4065 c9 -19 18 -33 21 -31 2 2 -1 14 -8 25 -6 12 -8 26 -5 31
3 6 -1 10 -9 10 -14 0 -14 -4 1 -35z"
        />
        <path
          d="M3905 4090 c-3 -5 0 -7 8 -4 8 3 17 -2 20 -10 5 -11 0 -16 -14 -16
-24 0 -21 -23 3 -28 18 -4 31 23 23 49 -7 20 -30 25 -40 9z"
        />
        <path
          d="M4048 4066 c9 -19 20 -32 25 -29 5 3 4 9 -2 13 -7 4 -13 17 -14 28
-1 12 -6 22 -13 22 -8 0 -7 -10 4 -34z"
        />
        <path
          d="M4117 4094 c-4 -4 -7 -18 -7 -31 0 -26 25 -42 42 -25 7 7 5 10 -7 9
-10 -1 -20 4 -22 11 -3 7 4 12 17 12 13 0 20 5 18 13 -5 14 -31 21 -41 11z"
        />
        <path
          d="M4179 4098 c-6 -21 -1 -68 7 -68 7 0 9 11 6 26 -3 16 1 30 9 35 11 7
10 9 -3 9 -10 0 -18 -1 -19 -2z"
        />
        <path d="M4229 4098 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M4304 4088 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 9 -10 20 -10 23 0 27 28 5 36 -8 4 -15 11 -15 16 0 6 7 7 16 4 8 -3
12 -2 9 4 -8 13 -22 13 -31 -2z"
        />
        <path
          d="M4358 4065 c-4 -33 -3 -35 27 -35 27 0 31 3 30 27 0 14 6 28 14 31
10 3 13 -3 9 -27 -3 -20 0 -31 7 -31 9 0 10 10 6 33 -6 26 -12 32 -31 32 -21
0 -25 -4 -22 -28 2 -15 -1 -27 -7 -27 -6 0 -11 14 -11 30 0 42 -17 38 -22 -5z"
        />
        <path d="M3760 4010 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path d="M3830 4010 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
        <path
          d="M3328 3813 c6 -2 12 -22 12 -43 0 -21 -6 -41 -12 -43 -7 -3 -4 -6 7
-6 17 -1 20 5 20 49 0 44 -3 50 -20 49 -11 0 -14 -3 -7 -6z"
        />
        <path
          d="M3604 3803 c-3 -10 -9 -25 -13 -33 -5 -8 -5 -22 0 -30 4 -8 10 -23
13 -33 4 -12 14 -17 28 -15 20 3 23 10 26 53 2 27 0 46 -4 42 -5 -4 -7 -26 -5
-47 2 -35 0 -40 -18 -40 -16 0 -21 6 -21 25 0 14 -6 25 -12 25 -9 0 -10 3 -2
8 6 4 13 19 16 35 6 30 0 37 -8 10z"
        />
        <path
          d="M3759 3816 c-3 -3 -3 -25 0 -48 5 -34 10 -43 26 -43 29 0 35 54 6 62
-11 3 -21 12 -23 20 -2 8 -6 12 -9 9z m38 -70 c-4 -9 -11 -16 -17 -16 -11 0
-14 33 -3 44 11 10 26 -11 20 -28z"
        />
        <path
          d="M3833 3803 c-4 -11 3 -24 22 -39 26 -21 27 -24 11 -30 -11 -4 -22 -1
-28 7 -8 12 -9 11 -5 -1 5 -22 46 -25 54 -4 4 12 -2 24 -20 38 -32 26 -35 40
-7 33 11 -3 18 -1 14 4 -9 15 -34 10 -41 -8z"
        />
        <path
          d="M4098 3813 c7 -2 12 -22 12 -44 0 -32 -3 -39 -20 -39 -15 0 -20 7
-20 25 0 14 6 25 13 25 9 0 9 2 -1 8 -7 5 -26 7 -42 4 -25 -4 -30 -9 -30 -33
0 -16 -4 -29 -10 -29 -5 0 -10 14 -10 30 0 23 -4 30 -20 30 -11 0 -20 -4 -20
-10 0 -5 7 -7 15 -4 24 10 17 -12 -10 -30 -32 -21 -35 -20 -35 9 0 14 6 25 13
25 8 0 7 3 -3 9 -21 14 -27 6 -22 -32 l4 -34 62 -2 63 -1 -5 30 c-3 23 0 30
12 30 11 0 16 -9 16 -30 0 -29 1 -30 48 -30 35 0 54 5 65 18 36 39 8 82 -51
81 -20 0 -31 -3 -24 -6z m66 -25 c13 -21 -2 -51 -28 -56 -13 -3 -16 5 -16 38
0 33 3 41 16 38 9 -2 22 -11 28 -20z m-184 -48 c0 -5 -5 -10 -11 -10 -5 0 -7
5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"
        />
        <path
          d="M4388 3789 c2 -16 9 -29 16 -29 8 0 8 -2 0 -8 -13 -8 -25 -64 -13
-56 5 3 9 13 9 23 0 9 5 22 11 28 8 8 8 13 0 17 -6 4 -11 16 -11 26 0 10 -4
21 -8 24 -5 3 -6 -8 -4 -25z"
        />
        <path d="M4681 3820 c-8 -21 -4 -100 4 -100 7 0 9 16 7 38 -4 42 -9 69 -11 62z" />
        <path
          d="M5155 3811 c-3 -6 -3 -11 2 -12 4 0 -2 -4 -15 -10 -18 -7 -23 -15
-20 -36 2 -22 8 -28 28 -28 23 0 24 3 21 45 -3 50 -6 56 -16 41z m3 -60 c3
-16 -1 -22 -10 -19 -7 3 -15 15 -16 27 -3 16 1 22 10 19 7 -3 15 -15 16 -27z"
        />
        <path
          d="M5489 3816 c-3 -3 -3 -25 0 -48 5 -34 10 -43 26 -43 16 0 20 7 20 30
0 20 -5 30 -15 30 -8 0 -17 8 -20 18 -3 10 -8 16 -11 13z m41 -61 c0 -16 -6
-25 -15 -25 -15 0 -21 31 -8 43 13 14 23 6 23 -18z"
        />
        <path d="M5691 3820 c-8 -21 -4 -100 4 -100 7 0 9 16 7 38 -4 42 -9 69 -11 62z" />
        <path
          d="M5971 3815 c-2 -5 -2 -27 -1 -47 1 -29 -2 -38 -14 -38 -11 0 -16 9
-16 30 0 23 -4 30 -20 30 -11 0 -20 -4 -20 -10 0 -5 7 -7 15 -4 23 9 18 -14
-6 -27 -30 -16 -10 -31 37 -27 l39 3 -6 50 c-3 28 -7 46 -8 40z m-41 -75 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"
        />
        <path
          d="M6075 3811 c-3 -6 -3 -11 2 -12 4 0 -2 -4 -15 -10 -18 -7 -23 -15
-20 -36 2 -22 8 -28 28 -28 23 0 24 3 21 45 -3 50 -6 56 -16 41z m3 -60 c3
-16 -1 -22 -10 -19 -7 3 -15 15 -16 27 -3 16 1 22 10 19 7 -3 15 -15 16 -27z"
        />
        <path d="M4748 3800 c-5 -17 3 -80 11 -80 7 0 6 79 -2 86 -3 3 -7 0 -9 -6z" />
        <path
          d="M4268 3795 c-2 -3 -2 -20 0 -40 3 -25 9 -35 21 -34 10 0 11 2 4 6
-16 6 -17 50 -1 55 8 3 6 6 -4 10 -9 4 -18 5 -20 3z"
        />
        <path
          d="M3380 3773 c0 -9 8 -22 18 -30 16 -14 16 -14 0 -9 -10 3 -18 1 -18
-4 0 -6 5 -10 11 -10 6 0 9 -7 5 -15 -3 -8 -2 -15 3 -15 16 0 20 54 5 66 -18
15 -18 25 1 18 8 -4 15 -2 15 3 0 5 -9 9 -20 10 -12 1 -20 -4 -20 -14z"
        />
        <path
          d="M3435 3770 c-3 -10 -1 -29 5 -40 6 -11 8 -25 4 -32 -4 -6 -1 -9 7 -6
8 3 13 11 12 17 -2 6 3 11 10 12 6 0 2 4 -11 10 -27 10 -26 45 1 43 9 -1 17 2
17 7 0 18 -39 9 -45 -11z"
        />
        <path d="M3509 3788 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M3691 3776 c-19 -23 -4 -58 22 -54 17 2 22 10 22 33 0 34 -24 45 -44
21z m34 -21 c0 -10 -5 -20 -11 -22 -13 -4 -27 24 -18 38 9 15 29 4 29 -16z"
        />
        <path
          d="M4204 3779 c-5 -9 -2 -10 9 -6 9 3 19 2 22 -3 3 -5 -4 -11 -15 -15
-31 -10 -25 -35 9 -35 27 0 28 2 22 32 -7 34 -33 49 -47 27z m36 -39 c0 -5 -7
-10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z"
        />
        <path
          d="M4310 3769 c-9 -15 -8 -24 1 -35 13 -16 35 -19 43 -5 4 5 -3 7 -14 4
-14 -4 -20 0 -20 11 0 9 8 16 20 16 13 0 18 5 14 15 -9 21 -31 19 -44 -6z"
        />
        <path
          d="M4497 3783 c-4 -3 -7 -17 -7 -30 0 -26 26 -41 42 -25 8 8 5 9 -11 5
-18 -4 -21 -1 -21 22 0 22 3 25 21 20 15 -5 19 -4 15 4 -8 12 -29 15 -39 4z"
        />
        <path d="M4559 3788 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path d="M4639 3788 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M4784 3779 c-5 -9 -2 -10 9 -6 9 3 19 2 22 -3 3 -5 -4 -11 -15 -15
-39 -12 -23 -35 24 -35 40 0 44 2 39 21 -4 15 0 27 13 36 18 14 18 14 -6 11
-21 -2 -24 -8 -22 -30 2 -16 -1 -28 -7 -28 -5 0 -11 12 -13 27 -3 30 -31 43
-44 22z m36 -39 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9
-4 9 -10z"
        />
        <path
          d="M4898 3755 c-4 -32 -2 -35 20 -35 14 0 31 8 38 18 13 16 13 16 14 0
0 -15 9 -18 48 -18 45 0 48 1 47 27 0 14 6 28 14 31 10 3 13 -3 9 -27 -3 -20
0 -31 7 -31 9 0 10 10 6 33 -6 26 -12 32 -31 32 -21 0 -25 -4 -22 -28 2 -15
-1 -27 -7 -27 -6 0 -11 14 -11 30 0 17 -4 30 -10 30 -5 0 -10 -13 -10 -30 0
-33 -11 -38 -30 -15 -7 8 -9 15 -6 15 4 0 1 7 -8 15 -22 22 -43 19 -23 -3 13
-14 14 -21 5 -30 -19 -19 -28 -14 -28 18 0 42 -17 38 -22 -5z"
        />
        <path
          d="M5196 3782 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z
m31 -48 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"
        />
        <path d="M5299 3788 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M5334 3778 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 5 -10 11 -10 6 0 8 -7 5 -16 -4 -10 -1 -14 6 -12 18 6 21 57 3 64 -8
3 -15 11 -15 16 0 6 7 7 16 4 8 -3 12 -2 9 4 -8 13 -22 13 -31 -2z"
        />
        <path
          d="M5402 3783 c28 -7 31 -33 3 -33 -18 0 -23 -4 -19 -15 14 -35 61 -2
49 35 -4 13 -14 20 -28 19 -16 -1 -17 -3 -5 -6z"
        />
        <path
          d="M5560 3780 c0 -5 7 -7 15 -4 8 4 15 1 15 -5 0 -7 -7 -14 -15 -17 -24
-9 -18 -34 8 -34 20 0 23 4 19 35 -3 26 -9 35 -23 35 -10 0 -19 -4 -19 -10z
m30 -40 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"
        />
        <path
          d="M5624 3778 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 5 -10 11 -10 6 0 8 -7 5 -16 -4 -10 -1 -14 6 -12 20 7 21 59 1 75 -17
14 -17 14 0 9 11 -3 16 -2 12 4 -8 13 -22 13 -31 -2z"
        />
        <path
          d="M5720 3780 c0 -5 7 -7 15 -4 8 4 15 1 15 -5 0 -7 -7 -14 -15 -17 -24
-9 -18 -34 8 -34 20 0 23 4 19 35 -3 26 -9 35 -23 35 -10 0 -19 -4 -19 -10z
m30 -40 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"
        />
        <path
          d="M5789 3785 c0 0 -1 -15 -2 -33 -2 -32 -2 -32 43 -32 l46 0 -4 34 c-4
33 -6 34 -43 33 -21 -1 -39 -2 -40 -2z m31 -30 c0 -14 -4 -25 -10 -25 -11 0
-14 33 -3 43 11 11 13 8 13 -18z m40 0 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10
25 0 14 5 25 10 25 6 0 10 -11 10 -25z"
        />
        <path d="M6009 3788 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M6121 3790 c-1 -3 -3 -20 -4 -38 -2 -30 0 -32 29 -32 28 0 31 2 26
26 -3 16 1 30 9 35 10 6 8 9 -8 9 -19 0 -23 -6 -23 -30 0 -40 -18 -38 -23 3
-2 17 -5 30 -6 27z"
        />
        <path d="M4448 3753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M640 3501 c0 -4 11 -6 25 -3 18 3 25 0 25 -12 0 -11 -9 -16 -30 -16
-23 0 -30 -5 -30 -19 0 -47 74 -53 86 -7 9 37 -12 66 -46 66 -17 0 -30 -4 -30
-9z m53 -61 c-7 -25 -36 -23 -41 3 -3 12 3 17 21 17 20 0 24 -4 20 -20z"
        />
        <path
          d="M998 3503 c6 -2 12 -22 12 -43 0 -21 -6 -41 -12 -43 -7 -3 -4 -6 7
-6 17 -1 20 5 20 49 0 44 -3 50 -20 49 -11 0 -14 -3 -7 -6z"
        />
        <path
          d="M2058 3485 c-2 -3 -2 -20 0 -40 3 -25 9 -35 21 -34 10 0 11 2 4 6
-16 6 -17 50 -1 55 8 3 6 6 -4 10 -9 4 -18 5 -20 3z"
        />
        <path
          d="M740 3461 c0 -10 8 -22 18 -26 16 -7 15 -8 -3 -15 -16 -7 -17 -9 -3
-9 25 -1 32 18 13 39 -13 14 -13 19 -3 23 8 4 7 6 -4 6 -11 1 -18 -6 -18 -18z"
        />
        <path
          d="M794 3468 c-5 -8 -2 -9 9 -5 10 3 17 2 17 -2 0 -5 -7 -11 -15 -15
-27 -10 -17 -36 13 -36 26 0 28 2 24 35 -3 26 -9 35 -23 35 -10 0 -21 -5 -25
-12z"
        />
        <path
          d="M864 3468 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15 -4
0 -6 9 -10 20 -10 25 0 26 28 3 47 -17 14 -17 14 0 9 9 -3 17 -1 17 4 0 14
-27 12 -36 -2z"
        />
        <path
          d="M1044 3468 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 5 -10 11 -10 6 0 8 -7 5 -16 -4 -10 -1 -14 6 -12 20 7 21 59 1 75 -17
14 -17 14 0 9 11 -3 16 -2 12 4 -8 13 -22 13 -31 -2z"
        />
        <path
          d="M1107 3473 c-11 -10 -8 -51 4 -58 6 -4 8 -14 5 -22 -4 -9 -1 -13 6
-10 7 2 12 10 11 16 -2 6 3 11 10 12 6 0 2 4 -11 10 -27 10 -26 45 1 43 9 -1
14 2 11 7 -6 11 -28 12 -37 2z"
        />
        <path d="M1169 3478 c-6 -20 -1 -68 6 -68 7 0 9 15 7 35 -3 29 -9 44 -13 33z" />
        <path
          d="M1209 3476 c0 0 -1 -16 -2 -34 l-2 -32 65 0 c65 0 66 0 65 27 0 14 6
28 14 31 10 3 13 -3 9 -27 -3 -20 0 -31 7 -31 9 0 10 10 6 33 -6 26 -12 32
-31 32 -21 0 -25 -4 -22 -28 2 -15 -1 -27 -7 -27 -6 0 -11 14 -11 30 0 17 -4
30 -10 30 -5 0 -10 -13 -10 -30 0 -38 -18 -40 -22 -2 -2 20 -9 27 -25 28 -13
1 -23 1 -24 0z m31 -31 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8
13 -18z"
        />
        <path
          d="M1429 3475 c0 0 -1 -15 -2 -33 -2 -32 -2 -32 43 -32 l46 0 -4 34 c-4
33 -6 34 -43 33 -21 -1 -39 -2 -40 -2z m31 -30 c0 -14 -4 -25 -10 -25 -11 0
-14 33 -3 43 11 11 13 8 13 -18z m40 0 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10
25 0 14 5 25 10 25 6 0 10 -11 10 -25z"
        />
        <path
          d="M1552 3473 c28 -7 31 -33 3 -33 -18 0 -23 -4 -19 -15 14 -35 61 -2
49 35 -4 13 -14 20 -28 19 -16 -1 -17 -3 -5 -6z"
        />
        <path
          d="M1600 3470 c0 -5 7 -6 17 -3 18 7 13 -7 -15 -39 -14 -17 -13 -18 19
-17 30 1 31 2 9 8 -17 5 -21 9 -12 14 6 5 17 17 22 28 9 16 7 19 -15 19 -14 0
-25 -4 -25 -10z"
        />
        <path
          d="M1668 3445 c3 -29 8 -35 28 -35 21 0 24 5 24 35 0 22 -5 35 -13 35
-9 0 -12 -10 -9 -30 3 -18 0 -30 -7 -30 -6 0 -11 14 -11 30 0 17 -4 30 -8 30
-4 0 -6 -16 -4 -35z"
        />
        <path
          d="M1739 3476 c-6 -10 -1 -66 6 -66 6 0 11 12 10 27 0 14 6 28 14 31 10
3 13 -3 9 -27 -4 -29 -3 -31 27 -31 30 0 31 2 27 35 -2 19 -8 33 -12 30 -4 -2
-5 -16 -2 -30 3 -18 0 -25 -11 -25 -10 0 -17 11 -19 28 -2 20 -9 27 -25 28
-13 1 -23 1 -24 0z"
        />
        <path
          d="M1847 3468 c6 -7 15 -26 20 -43 9 -28 10 -28 15 -7 4 12 2 20 -3 16
-8 -4 -14 17 -10 39 0 4 -6 7 -15 7 -12 0 -14 -4 -7 -12z"
        />
        <path
          d="M1890 3471 c0 -5 7 -8 16 -7 11 0 21 -12 29 -34 10 -31 12 -32 17
-12 4 12 2 20 -3 16 -8 -4 -14 17 -10 39 0 4 -10 7 -24 7 -14 0 -25 -4 -25 -9z"
        />
        <path
          d="M1960 3469 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
        />
        <path
          d="M1996 3472 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z
m31 -48 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"
        />
        <path
          d="M2136 3472 c-3 -6 2 -9 11 -8 9 0 18 -5 20 -11 3 -8 -4 -13 -17 -13
-13 0 -20 -5 -17 -12 2 -7 12 -13 23 -13 25 0 28 58 2 63 -9 2 -19 -1 -22 -6z
m31 -48 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"
        />
        <path
          d="M2199 3478 c-6 -21 -1 -68 6 -68 6 0 11 12 10 28 0 17 5 27 15 27 10
0 15 -10 15 -27 -1 -16 4 -28 10 -28 7 0 9 14 7 33 -3 28 -8 33 -33 35 -16 1
-29 1 -30 0z"
        />
        <path
          d="M2280 3470 c0 -5 7 -7 15 -4 19 8 19 -1 -1 -31 -16 -25 -16 -25 12
-24 22 0 25 2 11 8 -17 6 -17 8 3 34 l21 27 -30 0 c-17 0 -31 -4 -31 -10z"
        />
        <path
          d="M2355 3470 c-3 -5 0 -7 8 -4 8 3 17 -2 20 -10 5 -11 0 -16 -14 -16
-24 0 -21 -23 3 -28 18 -4 31 23 23 49 -7 20 -30 25 -40 9z"
        />
        <path
          d="M2414 3468 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 9 -10 20 -10 23 0 27 28 5 36 -8 4 -15 11 -15 16 0 6 7 7 16 4 8 -3
12 -2 9 4 -8 13 -22 13 -31 -2z"
        />
        <path
          d="M2468 3445 c-3 -27 0 -35 12 -35 12 0 15 8 12 35 -2 19 -8 35 -12 35
-4 0 -10 -16 -12 -35z"
        />
        <path d="M1848 3383 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path d="M1918 3383 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M671 3017 c-6 -8 -8 -17 -4 -20 12 -13 22 -7 20 13 -2 24 -1 24 -16
7z"
        />
        <path d="M707 3023 c-10 -10 -8 -33 2 -33 6 0 9 9 8 20 -1 23 -1 22 -10 13z" />
        <path
          d="M754 3015 c-3 -8 -3 -18 0 -24 4 -5 2 -12 -4 -16 -6 -4 -7 -12 -3
-18 4 -7 7 -21 8 -31 0 -11 5 -21 11 -23 7 -2 9 8 6 27 -2 16 -9 30 -16 30 -8
0 -8 2 0 8 14 9 24 62 12 62 -5 0 -11 -7 -14 -15z"
        />
        <path
          d="M1037 2983 c27 -32 39 -41 41 -30 4 19 -45 77 -65 77 -9 0 1 -18 24
-47z"
        />
        <path d="M1065 3020 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
        <path
          d="M1368 3000 c2 -16 9 -30 16 -30 8 0 8 -2 0 -8 -15 -10 -25 -64 -10
-59 14 5 23 54 16 91 -8 40 -28 45 -22 6z"
        />
        <path d="M1423 3011 c0 -11 5 -17 9 -15 11 7 10 34 -1 34 -6 0 -9 -9 -8 -19z" />
        <path
          d="M1450 3021 c0 -6 6 -11 13 -11 8 0 7 -4 -3 -11 -13 -9 -13 -10 0 -6
18 5 20 29 3 35 -7 2 -13 -1 -13 -7z"
        />
        <path
          d="M1532 3013 c8 -10 21 -33 28 -51 7 -18 17 -29 21 -25 5 5 2 28 -8 51
-11 30 -22 42 -36 42 -18 0 -19 -2 -5 -17z"
        />
        <path
          d="M1609 3018 c-5 -85 -4 -88 16 -88 17 0 20 7 20 50 0 41 -3 50 -17 50
-10 0 -18 -6 -19 -12z"
        />
        <path
          d="M1725 2980 c0 -50 0 -50 33 -50 29 0 32 3 32 31 0 22 -5 32 -20 36
-11 3 -20 12 -20 19 0 8 -6 14 -12 14 -9 0 -13 -17 -13 -50z m45 -15 c0 -14
-4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M1846 3015 c4 -9 0 -15 -9 -15 -9 0 -20 -8 -26 -17 -13 -25 5 -53 34
-53 23 0 25 4 25 50 0 37 -4 50 -15 50 -9 0 -12 -6 -9 -15z m0 -62 c-6 -14 -9
-15 -16 -4 -11 19 -1 46 13 32 5 -5 7 -18 3 -28z"
        />
        <path
          d="M2006 3015 c4 -9 0 -15 -9 -15 -9 0 -20 -8 -26 -17 -13 -25 5 -53 34
-53 23 0 25 4 25 50 0 37 -4 50 -15 50 -9 0 -12 -6 -9 -15z m0 -62 c-6 -14 -9
-15 -16 -4 -11 19 -1 46 13 32 5 -5 7 -18 3 -28z"
        />
        <path
          d="M2085 2980 c0 -29 5 -50 11 -50 6 0 9 14 7 33 l-3 32 16 -34 c11 -24
18 -31 23 -22 10 16 -25 91 -42 91 -8 0 -12 -17 -12 -50z"
        />
        <path
          d="M2168 2980 c-3 -48 -2 -50 22 -49 14 0 19 3 13 6 -7 2 -13 22 -13 43
0 21 6 41 13 43 6 3 2 6 -10 6 -18 1 -22 -5 -25 -49z"
        />
        <path
          d="M2420 2980 c0 -50 0 -50 33 -50 32 0 33 1 29 35 -5 43 -22 47 -22 5
0 -16 -4 -30 -10 -30 -5 0 -10 20 -10 45 0 25 -4 45 -10 45 -5 0 -10 -22 -10
-50z"
        />
        <path
          d="M2460 3020 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M2755 2980 c0 -33 4 -50 13 -50 8 0 12 17 12 50 0 33 -4 50 -12 50
-9 0 -13 -17 -13 -50z"
        />
        <path
          d="M2800 3020 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M2886 3014 c-20 -20 -20 -48 0 -68 17 -18 50 -21 58 -7 4 5 -3 7 -13
4 -26 -7 -46 29 -31 57 12 23 30 26 48 8 9 -9 12 -9 12 3 0 21 -54 23 -74 3z"
        />
        <path
          d="M3160 3020 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3460 3020 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M3580 3020 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path d="M2715 3003 c-14 -5 -6 -73 9 -73 6 0 11 15 10 35 -1 39 -4 44 -19 38z" />
        <path d="M3415 3003 c-14 -5 -6 -73 9 -73 6 0 11 15 10 35 -1 39 -4 44 -19 38z" />
        <path
          d="M796 2982 c-10 -30 4 -53 28 -50 31 4 30 62 -1 66 -14 2 -23 -3 -27
-16z m34 -17 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0
10 -11 10 -25z"
        />
        <path
          d="M870 2964 c0 -23 5 -34 14 -34 9 0 12 7 9 20 -3 12 1 22 11 26 24 9
19 22 -9 22 -22 0 -25 -5 -25 -34z"
        />
        <path
          d="M933 2984 c-3 -8 0 -21 6 -27 14 -14 43 1 47 26 3 22 -44 23 -53 1z
m32 -3 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z"
        />
        <path
          d="M1110 2990 c0 -7 6 -10 13 -7 8 3 13 -4 14 -22 0 -14 -1 -18 -4 -8
-5 20 -23 13 -23 -9 0 -8 10 -14 26 -14 23 0 25 3 22 33 -2 24 -8 33 -25 35
-13 2 -23 -2 -23 -8z"
        />
        <path
          d="M1175 2965 l0 -35 50 0 51 0 -4 35 c-5 45 -30 47 -26 3 l3 -33 -7 30
c-6 24 -13 30 -37 32 -28 2 -30 0 -30 -32z m45 0 c0 -14 -4 -25 -10 -25 -11 0
-14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M1307 2994 c-4 -4 -7 -18 -7 -31 0 -27 26 -41 43 -24 9 9 8 11 -5 6
-11 -4 -18 -2 -18 4 0 6 7 11 16 11 9 0 14 7 12 17 -3 19 -29 29 -41 17z"
        />
        <path
          d="M1665 2990 c-3 -6 1 -7 9 -4 9 3 16 1 16 -5 0 -6 -7 -11 -15 -11 -20
0 -19 -29 1 -37 24 -9 46 24 33 48 -10 20 -34 25 -44 9z m35 -34 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"
        />
        <path
          d="M1889 2968 c5 -26 12 -34 34 -36 25 -3 27 -1 27 32 0 24 -5 36 -14
36 -9 0 -12 -8 -9 -24 3 -13 0 -27 -6 -30 -7 -5 -11 4 -11 23 0 20 -5 31 -14
31 -10 0 -12 -8 -7 -32z"
        />
        <path
          d="M2224 2989 c-4 -7 -3 -9 4 -5 6 3 13 2 17 -4 3 -5 -3 -10 -13 -10
-15 0 -19 -5 -15 -21 4 -14 12 -19 27 -17 16 2 21 10 21 33 0 31 -26 47 -41
24z m26 -39 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z"
        />
        <path
          d="M2284 2988 c-4 -6 0 -16 9 -23 21 -15 22 -29 2 -21 -8 3 -15 1 -15
-4 0 -6 9 -10 20 -10 25 0 26 28 3 47 -17 14 -17 14 0 9 9 -3 17 -1 17 4 0 14
-27 12 -36 -2z"
        />
        <path
          d="M2339 2968 c5 -26 12 -34 34 -36 25 -3 27 -1 27 32 0 24 -5 36 -14
36 -9 0 -12 -8 -9 -24 3 -13 0 -27 -6 -30 -7 -5 -11 4 -11 23 0 20 -5 31 -14
31 -10 0 -12 -8 -7 -32z"
        />
        <path
          d="M2506 2973 c7 -16 14 -35 15 -43 0 -10 3 -8 8 5 5 11 5 27 0 36 -5 9
-5 19 -2 23 3 3 -3 6 -15 6 -20 0 -20 -2 -6 -27z"
        />
        <path
          d="M2557 2990 c12 -5 24 -18 28 -29 8 -25 21 -27 29 -5 3 8 2 12 -4 9
-9 -6 -14 8 -11 28 0 4 -14 7 -31 6 -29 0 -30 -1 -11 -9z"
        />
        <path
          d="M2645 2990 c-3 -6 1 -7 9 -4 9 3 16 1 16 -5 0 -6 -7 -11 -15 -11 -20
0 -19 -29 1 -37 24 -9 46 24 33 48 -10 20 -34 25 -44 9z m35 -34 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"
        />
        <path
          d="M2795 2965 c0 -21 5 -35 13 -35 7 0 12 14 12 35 0 21 -5 35 -12 35
-8 0 -13 -14 -13 -35z"
        />
        <path
          d="M2975 2990 c-3 -6 1 -7 9 -4 9 3 16 1 16 -5 0 -6 -7 -11 -15 -11 -20
0 -19 -29 1 -37 24 -9 46 24 33 48 -10 20 -34 25 -44 9z m35 -34 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"
        />
        <path
          d="M3035 2965 l0 -35 50 0 51 0 -4 35 c-5 45 -30 47 -26 3 l3 -33 -7 30
c-6 24 -13 30 -37 32 -28 2 -30 0 -30 -32z m45 0 c0 -14 -4 -25 -10 -25 -11 0
-14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M3160 2965 c0 -22 5 -35 13 -35 9 0 12 11 9 35 -2 19 -8 35 -13 35
-5 0 -9 -16 -9 -35z"
        />
        <path
          d="M3206 2973 c7 -16 14 -35 15 -43 0 -10 3 -8 8 5 5 11 5 27 0 36 -5 9
-5 19 -2 23 3 3 -3 6 -15 6 -20 0 -20 -2 -6 -27z"
        />
        <path
          d="M3257 2990 c12 -5 24 -18 28 -29 8 -25 21 -27 29 -5 3 8 2 12 -4 9
-9 -6 -14 8 -11 28 0 4 -14 7 -31 6 -29 0 -30 -1 -11 -9z"
        />
        <path
          d="M3345 2990 c-3 -6 1 -7 9 -4 9 3 16 1 16 -5 0 -6 -7 -11 -15 -11 -20
0 -19 -29 1 -37 24 -9 46 24 33 48 -10 20 -34 25 -44 9z m35 -34 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"
        />
        <path
          d="M3455 2965 c0 -21 5 -35 13 -35 7 0 12 14 12 35 0 21 -5 35 -12 35
-8 0 -13 -14 -13 -35z"
        />
        <path
          d="M3495 2965 c0 -32 2 -35 30 -35 29 0 31 2 27 35 -4 30 -8 35 -31 35
-23 0 -26 -4 -26 -35z m32 -12 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21
0 -30z"
        />
        <path
          d="M3575 2965 c0 -21 5 -35 13 -35 7 0 12 14 12 35 0 21 -5 35 -12 35
-8 0 -13 -14 -13 -35z"
        />
        <path
          d="M3615 2965 c0 -32 2 -35 30 -35 29 0 31 2 27 35 -4 30 -8 35 -31 35
-23 0 -26 -4 -26 -35z m32 -12 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21
0 -30z"
        />
        <path
          d="M1005 2940 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"
        />
        <path
          d="M1525 2940 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"
        />
        <path
          d="M936 2931 c5 -5 18 -12 29 -15 12 -3 9 -4 -7 -2 -16 2 -28 -1 -28 -6
0 -17 55 -4 58 15 3 13 -3 17 -29 17 -20 0 -29 -4 -23 -9z"
        />
        <path
          d="M2495 2910 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"
        />
        <path
          d="M2570 2908 c0 -4 4 -8 9 -8 6 0 12 4 15 8 3 5 -1 9 -9 9 -8 0 -15 -4
-15 -9z"
        />
        <path
          d="M3195 2910 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"
        />
        <path
          d="M3270 2908 c0 -4 4 -8 9 -8 6 0 12 4 15 8 3 5 -1 9 -9 9 -8 0 -15 -4
-15 -9z"
        />
        <path
          d="M628 2543 c6 -2 12 -22 12 -43 0 -21 -6 -41 -12 -43 -7 -3 4 -6 24
-6 29 -1 41 4 54 24 15 23 15 27 0 50 -13 20 -25 25 -54 24 -20 0 -31 -3 -24
-6z m57 -43 c0 -18 -6 -35 -12 -37 -9 -3 -13 8 -13 37 0 29 4 40 13 38 6 -3
12 -20 12 -38z"
        />
        <path
          d="M740 2540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M900 2500 c0 -39 4 -50 16 -50 8 0 12 5 9 10 -3 6 -1 10 4 10 6 0 11
-4 11 -10 0 -5 6 -10 14 -10 11 0 12 5 3 24 -6 13 -17 21 -24 19 -9 -4 -13 5
-13 26 0 17 -4 31 -10 31 -5 0 -10 -22 -10 -50z"
        />
        <path
          d="M4605 2540 c-4 -6 -7 -31 -7 -55 0 -24 3 -49 7 -55 12 -20 21 -10 17
20 -2 17 -9 30 -16 30 -8 0 -8 2 0 8 13 8 25 62 13 62 -4 0 -11 -5 -14 -10z"
        />
        <path
          d="M4676 2535 c4 -9 0 -15 -9 -15 -18 0 -30 -30 -21 -54 3 -9 17 -16 33
-16 25 0 26 2 23 50 -3 34 -8 50 -18 50 -8 0 -11 -6 -8 -15z m4 -51 c0 -14 -4
-23 -10 -19 -5 3 -10 15 -10 26 0 10 5 19 10 19 6 0 10 -12 10 -26z"
        />
        <path
          d="M4730 2540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M5148 2532 c9 -9 29 -32 43 -51 l27 -34 3 51 c3 34 0 52 -7 52 -7 0
-9 -11 -7 -27 l4 -28 -23 28 c-13 15 -31 27 -41 27 -16 0 -16 -1 1 -18z"
        />
        <path
          d="M5508 2520 c2 -16 9 -30 16 -30 8 0 8 -2 0 -8 -6 -4 -13 -19 -16 -35
-4 -21 -3 -25 8 -16 7 6 11 16 8 24 -3 7 0 16 6 20 8 5 8 10 0 20 -6 7 -10 17
-9 21 3 18 -1 34 -9 34 -5 0 -7 -13 -4 -30z"
        />
        <path d="M5695 2540 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z" />
        <path d="M5735 2500 c0 -38 4 -50 15 -50 12 0 14 11 12 50 -5 67 -27 67 -27 0z" />
        <path
          d="M4963 2528 c-12 -14 -6 -78 7 -78 10 0 18 67 10 81 -5 6 -10 5 -17
-3z"
        />
        <path
          d="M986 2523 c-10 -11 -7 -61 5 -69 6 -3 16 -1 22 6 9 10 9 11 0 6 -9
-5 -13 1 -13 18 0 13 5 26 12 28 8 3 6 6 -4 10 -9 4 -19 4 -22 1z"
        />
        <path
          d="M5862 2493 c-15 -48 -8 -55 8 -8 7 20 10 38 8 41 -3 2 -10 -13 -16
-33z"
        />
        <path
          d="M735 2485 c0 -21 5 -35 13 -35 7 0 12 14 12 35 0 21 -5 35 -12 35 -8
0 -13 -14 -13 -35z"
        />
        <path
          d="M775 2484 c0 -25 4 -34 16 -34 12 0 15 7 11 25 -3 18 0 25 12 25 28
0 17 17 -11 18 -25 0 -28 -3 -28 -34z"
        />
        <path
          d="M847 2514 c-4 -4 -7 -18 -7 -31 0 -27 26 -41 43 -24 9 9 8 11 -5 6
-11 -4 -18 -2 -18 4 0 6 7 11 16 11 9 0 14 7 12 17 -3 19 -29 29 -41 17z"
        />
        <path d="M948 2513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M1037 2513 c-13 -13 -7 -54 9 -60 24 -9 46 24 33 48 -10 19 -30 25
-42 12z m33 -28 c0 -8 -4 -17 -10 -20 -6 -4 -10 5 -10 20 0 15 4 24 10 20 6
-3 10 -12 10 -20z"
        />
        <path
          d="M1105 2484 c0 -21 5 -34 13 -34 6 0 12 11 12 24 0 15 7 26 19 29 16
4 21 -1 23 -22 2 -20 9 -27 31 -29 25 -3 27 -1 27 32 0 24 -5 36 -14 36 -9 0
-12 -8 -9 -24 3 -13 0 -27 -6 -30 -7 -5 -11 4 -11 23 0 29 -2 30 -42 29 -42 0
-43 -1 -43 -34z"
        />
        <path
          d="M4725 2485 c0 -21 5 -35 13 -35 7 0 12 14 12 35 0 21 -5 35 -12 35
-8 0 -13 -14 -13 -35z"
        />
        <path
          d="M4765 2484 c0 -25 4 -34 16 -34 12 0 15 7 11 25 -3 18 0 25 12 25 28
0 17 17 -11 18 -25 0 -28 -3 -28 -34z"
        />
        <path
          d="M4831 2502 c-17 -32 25 -73 44 -42 3 6 -1 7 -9 4 -9 -3 -16 -1 -16 5
0 6 7 11 16 11 11 0 14 5 10 16 -3 9 -6 18 -6 20 0 12 -31 1 -39 -14z m29 -2
c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"
        />
        <path
          d="M4894 2496 c-8 -32 2 -46 29 -46 20 1 20 2 5 11 -22 13 -25 51 -3 43
8 -4 15 -1 15 5 0 23 -40 11 -46 -13z"
        />
        <path
          d="M5006 2502 c-10 -30 4 -53 28 -50 31 4 30 62 -1 66 -14 2 -23 -3 -27
-16z m34 -17 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0
10 -11 10 -25z"
        />
        <path
          d="M5075 2484 c0 -25 4 -34 16 -34 12 0 15 7 11 25 -3 18 0 25 12 25 28
0 17 17 -11 18 -25 0 -28 -3 -28 -34z"
        />
        <path
          d="M5244 2508 c-5 -8 -2 -9 9 -5 22 8 22 -2 0 -18 -20 -15 -9 -35 21
-35 19 0 21 4 18 35 -3 26 -9 35 -23 35 -10 0 -21 -5 -25 -12z"
        />
        <path
          d="M5315 2485 l0 -35 51 0 51 0 -6 33 c-6 30 -10 32 -51 35 l-45 3 0
-36z m32 -12 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m43 12 c0
-14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M5441 2506 c-18 -21 -4 -56 22 -56 20 0 20 0 1 15 -18 13 -18 14 -1
15 12 0 17 6 15 17 -4 22 -22 27 -37 9z m29 -6 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path
          d="M5594 2506 c-10 -26 1 -51 22 -54 24 -3 38 20 29 49 -7 22 -43 25
-51 5z m36 -21 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0
10 -11 10 -25z"
        />
        <path
          d="M5667 2513 c-3 -5 -4 -26 -3 -48 2 -35 5 -40 27 -40 33 0 40 35 7 36
l-23 0 22 9 c30 12 27 50 -4 50 -12 0 -24 -3 -26 -7z m33 -18 c0 -8 -4 -15
-10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m3 -62 c-7 -2
-19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"
        />
        <path
          d="M5779 2488 c5 -26 12 -34 34 -36 25 -3 27 -1 27 32 0 24 -5 36 -14
36 -9 0 -12 -8 -9 -24 3 -13 0 -27 -6 -30 -7 -5 -11 4 -11 23 0 20 -5 31 -14
31 -10 0 -12 -8 -7 -32z"
        />
        <path
          d="M5901 2502 c-11 -22 3 -52 26 -52 9 0 13 -6 9 -15 -3 -9 0 -15 9 -15
11 0 15 12 15 48 0 43 -2 48 -25 50 -15 1 -28 -5 -34 -16z m39 -17 c0 -14 -4
-25 -9 -25 -12 0 -19 21 -13 38 8 21 22 13 22 -13z"
        />
        <path
          d="M5975 2485 l0 -35 48 0 c26 0 47 4 47 10 0 5 -7 7 -15 4 -19 -8 -19
1 1 31 16 25 16 25 -15 25 -17 0 -31 -4 -31 -10 0 -5 7 -7 15 -4 18 7 19 0 5
-27 -16 -28 -30 -23 -30 11 0 17 -5 30 -12 30 -8 0 -13 -14 -13 -35z"
        />
        <path
          d="M6080 2485 c0 -22 5 -35 13 -35 9 0 12 11 9 35 -2 19 -8 35 -13 35
-5 0 -9 -16 -9 -35z"
        />
        <path d="M5138 2453 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
        <path
          d="M640 1400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M628 1373 c6 -2 12 -22 12 -43 0 -21 -6 -41 -12 -43 -7 -3 -2 -6 12
-6 23 -1 25 2 25 49 0 47 -2 50 -25 49 -14 0 -19 -3 -12 -6z"
        />
        <path
          d="M810 1370 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
        />
        <path
          d="M896 1358 c-21 -29 -20 -61 2 -85 9 -10 14 -14 10 -9 -12 16 -9 91 4
104 7 7 9 12 6 12 -4 0 -14 -10 -22 -22z"
        />
        <path
          d="M1335 1330 c0 -33 4 -50 13 -50 8 0 12 17 12 50 0 33 -4 50 -12 50
-9 0 -13 -17 -13 -50z"
        />
        <path
          d="M1406 1365 c4 -9 0 -15 -9 -15 -18 0 -30 -30 -21 -54 3 -9 17 -16 33
-16 25 0 26 2 23 50 -3 34 -8 50 -18 50 -8 0 -11 -6 -8 -15z m4 -51 c0 -14 -4
-23 -10 -19 -5 3 -10 15 -10 26 0 10 5 19 10 19 6 0 10 -12 10 -26z"
        />
        <path
          d="M1650 1373 c0 -7 5 -15 10 -18 6 -3 10 -21 10 -40 0 -19 -4 -37 -10
-40 -5 -3 -10 -11 -10 -18 0 -6 7 -4 17 6 10 9 17 32 17 52 0 20 -7 43 -17 52
-10 10 -17 12 -17 6z"
        />
        <path
          d="M4565 1370 c-4 -6 -7 -31 -7 -55 0 -24 3 -49 7 -55 12 -20 21 -10 17
20 -2 17 -9 30 -16 30 -8 0 -8 2 0 8 13 8 25 62 13 62 -4 0 -11 -5 -14 -10z"
        />
        <path
          d="M4897 1336 c23 -27 39 -39 41 -31 5 21 -39 75 -61 75 -15 0 -12 -8
20 -44z"
        />
        <path d="M4935 1370 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z" />
        <path
          d="M5229 1372 c8 -6 11 -17 8 -25 -3 -8 -1 -19 5 -25 6 -6 6 -12 -1 -16
-5 -4 -7 -14 -4 -22 3 -9 0 -20 -8 -26 -12 -8 -11 -9 1 -5 30 8 30 116 0 125
-13 3 -13 2 -1 -6z"
        />
        <path d="M5450 1366 c7 -7 13 -7 20 0 6 6 3 10 -10 10 -13 0 -16 -4 -10 -10z" />
        <path d="M5500 1330 c0 -33 4 -50 13 -50 8 0 11 15 9 50 -5 65 -22 65 -22 0z" />
        <path
          d="M5640 1379 c0 -2 -7 -26 -15 -53 -8 -27 -12 -46 -8 -42 10 10 33 89
28 95 -3 2 -5 2 -5 0z"
        />
        <path
          d="M934 1346 c-8 -21 -1 -59 12 -63 7 -2 10 6 6 23 -3 15 0 29 7 34 9 5
8 9 -4 13 -9 4 -18 1 -21 -7z"
        />
        <path
          d="M690 1334 c0 -9 8 -23 18 -30 16 -13 16 -13 -2 -14 -14 0 -16 -3 -7
-13 6 -8 8 -17 4 -20 -3 -4 -1 -7 6 -7 7 0 11 6 8 14 -3 7 0 19 6 25 8 8 6 16
-7 30 -13 15 -14 20 -4 24 8 4 7 6 -4 6 -10 1 -18 -6 -18 -15z"
        />
        <path
          d="M744 1325 c-3 -14 -1 -32 6 -40 6 -8 8 -19 4 -26 -4 -7 -2 -10 7 -7
8 3 13 11 12 17 -2 6 3 11 10 11 6 0 4 5 -5 11 -22 13 -25 51 -3 43 8 -4 15
-1 15 5 0 23 -40 11 -46 -14z"
        />
        <path
          d="M805 1315 c0 -25 4 -35 15 -35 12 0 15 8 12 35 -2 19 -9 35 -16 35
-6 0 -11 -15 -11 -35z"
        />
        <path
          d="M984 1339 c-5 -8 -2 -11 7 -7 11 4 15 -2 16 -21 0 -14 -1 -18 -4 -8
-5 20 -23 13 -23 -9 0 -11 15 -14 66 -14 l65 0 -3 33 c-3 28 -7 32 -34 35 -29
3 -30 1 -26 -27 2 -18 0 -31 -6 -31 -6 0 -12 12 -14 27 -3 30 -31 43 -44 22z
m106 -24 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M1130 1315 c0 -22 5 -35 13 -35 9 0 12 11 9 35 -2 19 -8 35 -13 35
-5 0 -9 -16 -9 -35z"
        />
        <path
          d="M1170 1338 c0 -6 8 -20 18 -31 16 -19 16 -20 0 -14 -10 4 -18 2 -18
-3 0 -13 27 -13 35 1 4 5 -1 20 -12 31 -16 20 -17 21 0 15 9 -4 17 -2 17 3 0
6 -9 10 -20 10 -11 0 -20 -5 -20 -12z"
        />
        <path
          d="M1264 1336 c-10 -26 1 -51 22 -54 24 -3 38 20 29 49 -7 22 -43 25
-51 5z m36 -21 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0
10 -11 10 -25z"
        />
        <path
          d="M1458 1315 c4 -30 8 -35 31 -35 25 0 27 3 23 35 -5 44 -19 45 -25 3
l-4 -33 -1 33 c-1 19 -7 32 -15 32 -9 0 -12 -11 -9 -35z"
        />
        <path
          d="M1535 1315 l0 -35 51 0 51 0 -6 33 c-6 30 -10 32 -51 35 l-45 3 0
-36z m32 -12 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m43 12 c0
-14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M4611 1336 c-18 -21 -4 -56 22 -56 20 0 20 0 1 15 -18 13 -18 14 -1
15 12 0 17 6 15 17 -4 22 -22 27 -37 9z m29 -6 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path
          d="M4665 1315 l0 -35 50 0 51 0 -4 35 c-5 45 -30 47 -26 3 l3 -33 -7 30
c-6 24 -13 31 -37 33 -28 3 -30 1 -30 -33z m45 0 c0 -14 -4 -25 -10 -25 -11 0
-14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M4785 1300 c0 -41 3 -50 17 -50 11 0 15 5 12 14 -3 8 3 16 16 19 17
4 21 12 18 34 -2 23 -8 29 -33 31 -30 3 -30 2 -30 -48z m45 15 c0 -14 -4 -25
-10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z"
        />
        <path
          d="M4974 1338 c-5 -8 -2 -9 9 -5 10 3 17 2 17 -2 0 -5 -7 -11 -15 -15
-27 -10 -17 -36 13 -36 26 0 28 2 24 35 -3 26 -9 35 -23 35 -10 0 -21 -5 -25
-12z"
        />
        <path
          d="M5045 1315 l0 -35 51 0 51 0 -6 33 c-6 30 -10 32 -51 35 l-45 3 0
-36z m32 -12 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m43 12 c0
-14 -4 -25 -10 -25 -11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M5171 1336 c-18 -21 -4 -56 22 -56 20 0 20 0 1 15 -18 13 -18 14 -1
15 12 0 17 6 15 17 -4 22 -22 27 -37 9z m29 -6 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"
        />
        <path
          d="M5360 1335 c-17 -21 -2 -50 26 -50 14 0 19 7 19 30 0 34 -24 45 -45
20z m30 -22 c0 -20 -3 -24 -11 -16 -7 7 -9 19 -6 27 10 24 17 19 17 -11z"
        />
        <path
          d="M5427 1335 c-4 -8 -2 -17 3 -20 6 -3 5 -16 0 -31 -11 -27 3 -39 37
-32 27 5 22 31 -7 38 -24 6 -24 6 -4 12 12 3 23 15 27 27 4 18 1 21 -23 21
-15 0 -30 -7 -33 -15z m33 -11 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15
11 15 -7z m13 -61 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"
        />
        <path
          d="M5539 1318 c5 -26 12 -34 34 -36 25 -3 27 -1 27 32 0 24 -5 36 -14
36 -9 0 -12 -8 -9 -24 3 -13 0 -27 -6 -30 -7 -5 -11 4 -11 23 0 20 -5 31 -14
31 -10 0 -12 -8 -7 -32z"
        />
        <path
          d="M5666 1343 c-15 -15 -5 -55 15 -60 12 -3 18 -11 15 -19 -3 -9 1 -14
12 -14 14 0 16 8 14 48 -3 41 -7 48 -26 50 -13 1 -26 -1 -30 -5z m34 -28 c0
-14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z"
        />
        <path
          d="M5740 1315 c0 -35 0 -35 45 -35 25 0 45 4 45 10 0 5 -7 7 -16 3 -15
-5 -15 -3 0 26 l17 31 -28 -1 c-17 -1 -21 -3 -11 -6 16 -4 16 -6 2 -29 -22
-32 -34 -30 -34 6 0 17 -4 30 -10 30 -5 0 -10 -16 -10 -35z"
        />
        <path
          d="M5845 1315 c0 -21 5 -35 13 -35 7 0 12 14 12 35 0 21 -5 35 -12 35
-8 0 -13 -14 -13 -35z"
        />
        <path
          d="M4865 1290 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"
        />
        <path
          d="M1185 1260 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"
        />
        <path
          d="M2393 1253 c390 -2 1024 -2 1410 0 386 1 68 2 -708 2 -775 0 -1091
-1 -702 -2z"
        />
        <path
          d="M2976 1194 c-9 -8 -16 -31 -16 -49 0 -18 7 -41 16 -49 22 -23 25 -20
8 12 -7 15 -14 32 -14 37 0 6 7 22 14 38 17 31 14 34 -8 11z"
        />
        <path
          d="M3294 1190 c9 -13 16 -34 16 -45 0 -11 -7 -32 -16 -45 l-16 -25 21
24 c12 13 21 33 21 46 0 13 -9 33 -21 46 l-21 24 16 -25z"
        />
        <path
          d="M3008 1145 c-4 -33 -3 -35 27 -35 27 0 31 3 30 27 0 14 6 28 14 31
10 3 13 -3 9 -27 l-5 -31 66 1 c43 1 57 3 41 8 -17 5 -21 9 -12 14 6 5 17 17
22 28 9 16 7 19 -15 19 -14 0 -25 -4 -25 -10 0 -5 8 -6 18 -3 16 7 16 5 1 -17
-23 -34 -37 -35 -41 -3 -3 26 -6 28 -48 28 -43 0 -45 -1 -42 -28 2 -15 -1 -27
-7 -27 -6 0 -11 14 -11 30 0 42 -17 38 -22 -5z m112 0 c0 -14 -4 -25 -10 -25
-11 0 -14 33 -3 43 11 11 13 8 13 -18z"
        />
        <path
          d="M3224 1169 c-5 -9 -2 -10 9 -6 9 3 19 2 22 -3 3 -5 -4 -11 -15 -15
-31 -10 -25 -35 9 -35 27 0 28 2 22 32 -7 34 -33 49 -47 27z m36 -39 c0 -5 -7
-10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z"
        />
      </g>
    </svg>
  );
}
