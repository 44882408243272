import React from "react";
import {
  Button,
  Divider,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Info, Search } from "@mui/icons-material";
import { RemoveRedEye } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

// CSS
import style from "../documentRegister.module.css";

//custom components
import Header from "../../../components/layouts/header/Header";
import Breadcrumb from "../../../components/layouts/breadcrumb/Breadcrumb";
import { getDocuments } from "../../../services/documentations/ContractServices";

// .....................

export default function Contracts() {
  const navigate = useNavigate();
  const [contracts, setContracts] = useState([]);
  const [staticContracts, setStaticContracts] = useState([]);
  const [dynamicContracts, setDynamicContracts] = useState([]);
  const [filteredStaticContracts, setFilteredStaticContracts] = useState([]);
  const [filteredDynamicContracts, setFilteredDynamicContracts] = useState([]);

  useEffect(() => {
    const getDocs = async () => {
      try {
        const { data } = await getDocuments("agreement");
        setContracts(data.records);
        setStaticContracts(data.static.records);
        setDynamicContracts(data.dynamic.records);
        setFilteredStaticContracts(data.static.records);
        setFilteredDynamicContracts(data.dynamic.records);
      } catch (error) {}
    };
    getDocs();
  }, []);

  const headerData = {
    title: "Müqavilə məlumatları",
    variant: "h3",
  };

  //search

  const search = (data) => {
    const newStatic = staticContracts.filter((item) => {
      return item.label.toLowerCase().startsWith(data.toLowerCase());
    });
    setFilteredStaticContracts(newStatic);
    const newDynamic = dynamicContracts.filter((item) => {
      return item.label.toLowerCase().startsWith(data.toLowerCase());
    });
    setFilteredDynamicContracts(newDynamic);
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Header data={headerData} />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigate("/documentation/contract/register-of-contracts")
            }
          >
            Müqavilələrin reyestri
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
      <TextField
        variant="outlined"
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          search(event.target.value);
        }}
      />
      <Grid sx={{ marginBottom: "30px", textAlign: "center" }}>
        <Header data={{ title: "Statik müqavilə növləri", variant: "h4" }} />
      </Grid>
      {!filteredStaticContracts.length ? (
        <Grid
          sx={{
            width: "100%",
            fontSize: "30px",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "20px",
            backgroundColor: "#233044",
            borderRadius: "10px",
          }}
        >
          Heç bir məlumat tapılmadı
        </Grid>
      ) : (
        <></>
      )}
      <Grid className={style.orderList}>
        {filteredStaticContracts?.map((item, index) => {
          return (
            <React.Fragment key={`${index} ${item}`}>
              <Grid className={style.list} sx={{ transition: "0.3s" }}>
                <Grid className={style.infoSide}>
                  <Tooltip title={item.info}>
                    <IconButton
                      sx={{
                        padding: 0,
                      }}
                    >
                      <div className={style.info}>i</div>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid className={style.listLeftSide}>
                  <Grid className={style.docType}>{item.label}</Grid>
                </Grid>

                <Grid className={style.listRightSide}>
                  <Grid className={style.statistics}>
                    <Grid>
                      <Grid>{item.statistics.total}</Grid>
                      <Grid className={style.statisticType}>Cəm</Grid>
                    </Grid>
                    <div style={{ border: "1px solid black" }}></div>
                    <Grid>
                      <Grid>{item.statistics.pending}</Grid>
                      <Grid className={style.statisticType}>Gözləyən</Grid>
                    </Grid>
                    <Grid>
                      <Grid>{item.statistics.approved}</Grid>
                      <Grid className={style.statisticType}>İmzalanmış</Grid>
                    </Grid>

                    <Grid>
                      <Grid>{item.statistics.rejected}</Grid>
                      <Grid className={style.statisticType}>Ləğv edilmiş</Grid>
                    </Grid>
                  </Grid>
                  <div className={style.buttons}>
                    <Tooltip title="Müqavilə yarat">
                      {item.isContractCreatable ? (
                        <button
                          className={style.button}
                          onClick={() =>
                            navigate(
                              `/documentation/contract/create?value=${item.id}&label=${item.label}`
                            )
                          }
                        >
                          <AddIcon />
                        </button>
                      ) : (
                        <></>
                      )}
                    </Tooltip>
                    {item.statistics.total ? (
                      <Tooltip title="Müqavilələrə bax">
                        <button
                          onClick={() =>
                            navigate(
                              `/documentation/contract/viewtypeofcontracts?contractID=${item.id}&contractType=${item.type}`
                            )
                          }
                          className={style.button}
                        >
                          <RemoveRedEye />
                        </button>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>

      <Grid
        sx={{ marginBottom: "30px", marginTop: "50px", textAlign: "center" }}
      >
        <Header data={{ title: "Dinamik müqavilə növləri", variant: "h4" }} />
      </Grid>
      {!filteredDynamicContracts.length ? (
        <Grid
          sx={{
            width: "100%",
            fontSize: "30px",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "20px",
            backgroundColor: "#233044",
            borderRadius: "10px",
          }}
        >
          Heç bir məlumat tapılmadı
        </Grid>
      ) : (
        <></>
      )}
      <Grid className={style.orderList}>
        {filteredDynamicContracts?.map((item, index) => {
          return (
            <React.Fragment key={`${index} ${item}`}>
              <Grid className={style.list} sx={{ transition: "0.3s" }}>
                <Grid className={style.infoSide}>
                  <Tooltip title={item.info}>
                    <IconButton
                      sx={{
                        padding: 0,
                      }}
                    >
                      <div className={style.info}>i</div>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid className={style.listLeftSide}>
                  <Grid className={style.docType}>{item.label}</Grid>
                </Grid>

                <Grid className={style.listRightSide}>
                  <Grid className={style.statistics}>
                    <Grid>
                      <Grid>{item.statistics.total}</Grid>
                      <Grid className={style.statisticType}>Cəm</Grid>
                    </Grid>
                    <div style={{ border: "1px solid black" }}></div>
                    <Grid>
                      <Grid>{item.statistics.pending}</Grid>
                      <Grid className={style.statisticType}>Gözləyən</Grid>
                    </Grid>
                    <Grid>
                      <Grid>{item.statistics.approved}</Grid>
                      <Grid className={style.statisticType}>İmzalanmış</Grid>
                    </Grid>

                    <Grid>
                      <Grid>{item.statistics.rejected}</Grid>
                      <Grid className={style.statisticType}>Ləğv edilmiş</Grid>
                    </Grid>
                  </Grid>
                  <div className={style.buttons}>
                    <Tooltip title="Müqavilə yarat">
                      {item.isContractCreatable ? (
                        <button
                          className={style.button}
                          onClick={() =>
                            navigate(
                              `/documentation/contract/create?value=${item.id}&label=${item.label}`
                            )
                          }
                        >
                          <AddIcon />
                        </button>
                      ) : (
                        <></>
                      )}
                    </Tooltip>
                    {item.statistics.total ? (
                      <Tooltip title="Müqavilələrə bax">
                        <button
                          onClick={() =>
                            navigate(
                              `/documentation/contract/viewtypeofcontracts?contractID=${item.id}&contractType=${item.type}`
                            )
                          }
                          className={style.button}
                        >
                          <RemoveRedEye />
                        </button>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </>
  );
}
