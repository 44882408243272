import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getCommonData, updateEmployee } from "../../api/api";
import NewCommonDataDialog from "../employees/NewCommonDataDialog";
import { EducationValidation } from "../employees/Validations";
import { removeEmptyStrings } from "../../utils/removeEmptyString";
import CustomAccordion from "../ui/Accordion/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const educationType = [
  { value: "primary", name: "Ümumi təhsil - İbtidati təhsil" },
  { value: "bachelor", name: "Ali təhsil - bakalavriat" },
  { value: "master", name: "Ali təhsil - magistratura" },
  { value: "doctoral", name: "Ali təhsil - doktorantura" },
  { value: "generalSecondary", name: "Ümumi təhsil - ümumi orta təhsil" },
  { value: "completeSecondary", name: "Ümumi təhsil - tam orta təhsil" },
  { value: "firstVocational", name: "İlk peşə-ixtisas təhsili" },
  { value: "secondarySpecial", name: "Orta ixtisas təhsili" },
  {
    value: "secBasisOfGeneralSec",
    name: "Ümumi orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "secBasisOfCompleteSec",
    name: "Tam orta təhsil bazasında orta ixtisas təhsili",
  },
  {
    value: "incompleteHigherTill1993",
    name: "Ali təhsil - 1993 cü ilə qədər natamam ali təhsil",
  },
  {
    value: "completeHigherTill1997",
    name: "Ali təhsil - 1997 ci ilə qədər tam ali təhsil",
  },
];

const EditEducationInfo = ({ employeeById, submitted, setSubmitted }) => {
  const [universities, setUniversities] = useState([]);
  const [schooles, setSchooles] = useState([]);
  const [vocationalSchooles, setVocationalSchooles] = useState([]);
  const [otherInstitutiones, setOtherInstitutionses] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isDate, setIsDate] = useState(false);
  const [pastData, setPastData] = useState([]);
  const [delayedKey, setDelayedKey] = useState(null);
  const [selectedType, setSelectedType] = useState([
    "",
    {
      name: "",
      values: [],
    },
  ]);
  const [key, setKey] = useState([]);
  // Handle Submit
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    // Handle form submission
    const modifiedValues = {
      ...values,
      education:
        values?.education &&
        values?.education.map((item, index) => ({
          ...item,
          faculty:
            values.education[index].edType === "primary" ||
            values.education[index].edType === "completeSecondary" ||
            values.education[index].edType === "generalSecondary"
              ? ""
              : item?.faculty,
          startDate: item.startDate
            ? moment(item.startDate).format("MM/DD/yyyy")
            : "",
          endDate: item.endDate
            ? moment(item.endDate).format("MM/DD/yyyy")
            : "",
        })),
    };
    const cleanedValues = {
      ...modifiedValues,
      education: modifiedValues.education.map(({ id, ...rest }) => rest),
    };

    // Handle form submission
    const result = removeEmptyStrings(cleanedValues);

    await updateEmployee(employeeById._id, result);
    setIsDate(false);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };

  useEffect(() => {
    if (Object.keys(key).length > 0) {
      getData(key); // Pass the key object to getData
    }
  }, [key, submitted]);

  useEffect(() => {
    // Populate universities and faculties based on the initial education data
    if (employeeById?.education?.length > 0) {
      employeeById.education.forEach((education, index) => {
        const [keyword, hasFaculties] = foundKey(education.edType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setDelayedKey(keyword);

        getData([keyword, hasFaculties]);
      });
    }
  }, [employeeById]);

  const getData = async (keyObject) => {
    for (const index in keyObject) {
      const [keyword, hasFaculties] = Array.isArray(keyObject)
        ? keyObject
        : keyObject[index];
      if (!keyword) return;

      const { data } = await getCommonData(keyword);
      if (keyword === "universityList") {
        setUniversities((prevUniversities) => ({
          ...prevUniversities,
          [keyword]: data || [],
        }));
      } else if (keyword === "schoolList") {
        setSchooles((prevSchooles) => ({
          ...prevSchooles,
          [keyword]: data || [],
        }));
      } else if (keyword === "vocationalSchoolList") {
        setVocationalSchooles((prevVocationalSchooles) => ({
          ...prevVocationalSchooles,
          [keyword]: data || [],
        }));
      } else if (keyword === "otherInstitutions") {
        setOtherInstitutionses((prevOtherInstitutionss) => ({
          ...prevOtherInstitutionss,
          [keyword]: data || [],
        }));
      }

      // If faculties are needed, fetch faculties
      if (hasFaculties) {
        const facultyResponse = await getCommonData("facultyList");
        setFaculties(facultyResponse?.data || []);
      }
    }
  };

  // useEffect(() => {
  //   getData();
  // }, []);
  const foundKey = (field) => {
    let keyword;
    let hasFaculties = false;
    switch (field) {
      case "bachelor":
      case "incompleteHigherTill1993":
      case "completeHigherTill1997":
      case "master":
      case "doctoral":
        keyword = "universityList";
        hasFaculties = true;
        break;
      case "primary":
      case "generalSecondary":
      case "completeSecondary":
        keyword = "schoolList";
        hasFaculties = true;
        break;
      case "firstVocational":
      case "secondarySpecial":
        keyword = "vocationalSchoolList";
        hasFaculties = true;
        break;
      case "secBasisOfGeneralSec":
        keyword = "otherInstitutions";
        hasFaculties = true;
        break;
      case "secBasisOfCompleteSec":
        keyword = "otherInstitutions";
        hasFaculties = true;
        break;
      default:
        keyword = "";
        hasFaculties = false;
        break;
    }

    return [keyword, hasFaculties];
  };

  const initialValues = {
    education: employeeById.education || [],
  };
  function handlePastData(data) {
    setPastData(data);
  }
  // function handlePush(index) {
  //   universities[key[0]] &&
  //     key.length !== 0 &&
  //     universities[key[0]].push(employeeById?.education[index]?.institution);
  //   faculties && faculties.push(employeeById?.education[index]?.faculty);
  // }
  // function handleHidden(name, data) {
  //   return pastData.every((past) => {
  //     return !past[name]?.includes(data);
  //   });
  // }
  const handleAddEducationInstitution = (
    index,
    key,
    values,
    setSelectedType,
    setOpenAddDialog
  ) => {
    const obj = {
      collegeList: "Kolleclərin siyahısına",
      vocationalSchoolList: "Peşə məktəblərinin siyahısına",
      universityList: "Universitetlərin siyahısına",
      facultyList: "Fakultələrin siyahısına",
      schoolList: "Məktəblərin siyahısına",
      otherInstitutions: "Digər müəssisələrə",
    };

    const educationType = values?.education?.[index]?.edType;
    const k = key?.[index]?.[0];

    const getType = () => {
      if (
        ["secondarySpecial", "firstVocational"].includes(educationType) &&
        key.length === 0
      ) {
        return "vocationalSchoolList";
      }
      if (
        ["secBasisOfGeneralSec", "secBasisOfCompleteSec"].includes(
          educationType
        ) &&
        key.length === 0
      ) {
        return "otherInstitutions";
      }
      if (
        ["primary", "completeSecondary", "generalSecondary"].includes(
          educationType
        ) &&
        key.length === 0
      ) {
        return "schoolList";
      }
      if (
        [
          "bachelor",
          "incompleteHigherTill1993",
          "completeHigherTill1997",
          "master",
          "doctoral",
        ].includes(educationType) &&
        key.length === 0
      ) {
        return "universityList";
      }
      return k;
    };

    const getInstitutionName = (type) => obj?.[type] || obj?.[k];

    setSelectedType([
      getType(),
      {
        name: getInstitutionName(getType()),
        values: getType(),
      },
    ]);
    setOpenAddDialog(true);
  };
  const renderInstitutionOptions = (
    index,
    key,
    values,
    data,
    set,
    educationTypes
  ) => {
    return (
      (key?.[index]?.[0] === set ||
        educationTypes.includes(values?.education?.[index]?.edType)) &&
      (data?.[set] ?? []).length > 0 &&
      [...new Set(data?.[set])]
        .sort((a, b) => a.localeCompare(b, "az"))
        .map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))
    );
  };

  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Təhsil məlumatları</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              education: initialValues.education || [],
            }}
            validationSchema={EducationValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="education">
                    {({ push, remove }) => (
                      <div>
                        {values?.education &&
                          values?.education.map((form, index) => (
                            <div key={form.id || index}>
                              <>
                                {/* {handlePush(index)} */}
                                <Typography sx={{ mt: 5 }} variant="h6">
                                  {index + 1})Təhsil məlumatları
                                </Typography>

                                {/* Education type and institution */}
                                <Grid
                                  sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}
                                >
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.education?.[index]?.edType &&
                                        errors?.education?.[index]?.edType &&
                                        Boolean(
                                          errors?.education?.[index]?.edType
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`education[${index}].edType`}
                                      >
                                        Təhsil növü
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        required
                                        name={`education[${index}].edType`}
                                        labelId={`reducation[${index}].edType`}
                                        label="Təhsil növü"
                                        id={`education[${index}].edType`}
                                        defaultValue=""
                                        fullWidth
                                        error={
                                          touched?.education?.[index]?.edType &&
                                          errors?.education?.[index]?.edType &&
                                          Boolean(
                                            errors?.education?.[index]?.edType
                                          )
                                        }
                                        onChange={async (e) => {
                                          setFieldValue(
                                            `education[${index}].edType`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `education[${index}].institution`,
                                            ""
                                          );
                                          const [keyword, hasFaculties] =
                                            foundKey(e.target.value);

                                          // Ensure key is set as an array
                                          setKey((prevKey) => {
                                            const updatedKey = {
                                              ...prevKey,
                                              [index]: [keyword, hasFaculties],
                                            };
                                            return updatedKey;
                                          });
                                        }}
                                      >
                                        {educationType &&
                                          educationType
                                            .sort((a, b) =>
                                              a?.name.localeCompare(
                                                b?.name,
                                                "az"
                                              )
                                            )
                                            .map((item, index) => (
                                              <MenuItem value={item?.value}>
                                                {item?.name}
                                              </MenuItem>
                                            ))}
                                      </Field>
                                      {touched?.education?.[index]?.edType &&
                                        errors.education?.[index]?.edType &&
                                        errors.education?.[index]?.edType && (
                                          <FormHelperText error>
                                            {errors.education?.[index]?.edType}
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.education?.[index]
                                          ?.institution &&
                                        errors?.education?.[index]
                                          ?.institution &&
                                        Boolean(
                                          errors?.education?.[index]
                                            ?.institution
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`education[${index}].institution`}
                                      >
                                        Təhsil müəssisəsi
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`education[${index}].institution`}
                                        labelId={`education[${index}].institution`}
                                        label="Təhsil müəssisəsi"
                                        id={`education[${index}].institution`}
                                        defaultValue=""
                                        fullWidth
                                        error={
                                          touched?.education?.[index]
                                            ?.institution &&
                                          errors?.education?.[index]
                                            ?.institution &&
                                          Boolean(
                                            errors?.education?.[index]
                                              ?.institution
                                          )
                                        }
                                        disabled={
                                          !values?.education?.[index]?.edType
                                        }
                                      >
                                        {renderInstitutionOptions(
                                          index,
                                          key,
                                          values,
                                          universities,
                                          "universityList",
                                          [
                                            "bachelor",
                                            "incompleteHigherTill1993",
                                            "completeHigherTill1997",
                                            "master",
                                            "doctoral",
                                          ]
                                        )}

                                        {renderInstitutionOptions(
                                          index,
                                          key,
                                          values,
                                          schooles,
                                          "schoolList",
                                          [
                                            "primary",
                                            "completeSecondary",
                                            "generalSecondary",
                                          ]
                                        )}

                                        {renderInstitutionOptions(
                                          index,
                                          key,
                                          values,
                                          vocationalSchooles,
                                          "vocationalSchoolList",
                                          [
                                            "secondarySpecial",
                                            "firstVocational",
                                          ]
                                        )}

                                        {renderInstitutionOptions(
                                          index,
                                          key,
                                          values,
                                          otherInstitutiones,
                                          "otherInstitutions",
                                          [
                                            "secBasisOfGeneralSec",
                                            "secBasisOfCompleteSec",
                                          ]
                                        )}

                                        <Button
                                          sx={{ mt: 3, ml: 3 }}
                                          variant="outlined"
                                          onClick={() =>
                                            handleAddEducationInstitution(
                                              index,
                                              key,
                                              values,
                                              setSelectedType,
                                              setOpenAddDialog
                                            )
                                          }
                                        >
                                          Yeni təhsil müəssisəsi əlavə et
                                        </Button>
                                      </Field>
                                      {touched?.education?.[index]
                                        ?.institution &&
                                        errors.education?.[index]
                                          ?.institution && (
                                          <FormHelperText error>
                                            {
                                              errors.education?.[index]
                                                ?.institution
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                </Grid>

                                {/* education[${index}] field */}
                                {!(
                                  values.education[index].edType ===
                                    "primary" ||
                                  values.education[index].edType ===
                                    "completeSecondary" ||
                                  values.education[index].edType ===
                                    "generalSecondary"
                                ) && (
                                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                    <Grid sx={{ width: "100%" }}>
                                      <FormControl
                                        error={
                                          touched?.education?.[index]
                                            ?.faculty &&
                                          errors?.education?.[index]?.faculty &&
                                          Boolean(
                                            errors?.education?.[index]?.faculty
                                          )
                                        }
                                        fullWidth
                                      >
                                        <InputLabel
                                          id={`education[${index}].faculty`}
                                        >
                                          Təhsil sahəsi (fakultə)
                                        </InputLabel>
                                        <Field
                                          as={Select}
                                          name={`education[${index}].faculty`}
                                          labelId={`education[${index}].faculty`}
                                          label="Təhsil sahəsi (fakultə)"
                                          id={`education[${index}].faculty`}
                                          defaultValue=""
                                          fullWidth
                                          error={
                                            touched?.education?.[index]
                                              ?.faculty &&
                                            errors?.education?.[index]
                                              ?.faculty &&
                                            Boolean(
                                              errors?.education?.[index]
                                                ?.faculty
                                            )
                                          }
                                        >
                                          {faculties &&
                                            [...new Set(faculties)]
                                              .sort((a, b) =>
                                                a.localeCompare(b, "az")
                                              )
                                              .map((item) => (
                                                <MenuItem
                                                  // hidden={handleHidden(
                                                  //   "faculties",
                                                  //   item
                                                  // )}
                                                  key={item}
                                                  value={item}
                                                >
                                                  {item}
                                                </MenuItem>
                                              ))}
                                          <Button
                                            sx={{
                                              mt: 3,
                                              ml: 3,
                                            }}
                                            variant="outlined"
                                            onClick={() => {
                                              setOpenAddDialog(true);
                                              setSelectedType([
                                                "facultyList",
                                                { name: "Fakultələr" },
                                              ]);
                                            }}
                                          >
                                            Yeni fakultə əlavə et
                                          </Button>
                                        </Field>
                                        {touched?.education?.[index]?.faculty &&
                                          errors.education?.[index]
                                            ?.faculty && (
                                            <FormHelperText error>
                                              {
                                                errors.education?.[index]
                                                  ?.faculty
                                              }
                                            </FormHelperText>
                                          )}
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                )}

                                {/*start date and end date */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid
                                    onClick={() => setIsDate(true)}
                                    sx={{ width: "100%" }}
                                  >
                                    <Field
                                      name={`education[${index}].startDate`}
                                    >
                                      {({ field, form }) => {
                                        const selectedEndDate =
                                          form.values.education[index]?.endDate;

                                        return (
                                          <DatePicker
                                            {...field}
                                            label="Təhsilin başlama tarixi"
                                            inputFormat="dd/MM/yyyy"
                                            value={
                                              field.value ? field.value : ""
                                            }
                                            maxDate={
                                              selectedEndDate
                                                ? new Date(selectedEndDate)
                                                : undefined
                                            }
                                            minDate={new Date(1940, 0, 1)}
                                            onChange={(date) => {
                                              form.setFieldValue(
                                                `education[${index}].startDate`,
                                                date
                                              );
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                helperText={
                                                  isDate &&
                                                  form.errors?.education?.[
                                                    index
                                                  ]?.startDate
                                                }
                                                error={
                                                  isDate &&
                                                  Boolean(
                                                    form.errors?.education?.[
                                                      index
                                                    ]?.startDate
                                                  )
                                                }
                                                fullWidth
                                              />
                                            )}
                                          />
                                        );
                                      }}
                                    </Field>
                                  </Grid>
                                  {!values?.education?.[index]
                                    ?.stillStudying && (
                                    <Grid
                                      onClick={() => setIsDate(true)}
                                      sx={{ width: "100%" }}
                                    >
                                      <Field
                                        name={`education[${index}].endDate`}
                                      >
                                        {({ field, form }) => {
                                          const selectedStartDate =
                                            form.values.education[index]
                                              ?.startDate;
                                          const stillStudying =
                                            values?.education?.[index]
                                              ?.stillStudying;
                                          return (
                                            <DatePicker
                                              {...field}
                                              label="Təhsilin bitmə tarixi"
                                              inputFormat="dd/MM/yyyy"
                                              value={
                                                field.value ? field.value : ""
                                              }
                                              minDate={
                                                selectedStartDate
                                                  ? new Date(selectedStartDate)
                                                  : undefined
                                              }
                                              onChange={(date) => {
                                                form.setFieldValue(
                                                  `education[${index}].endDate`,
                                                  date
                                                );
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  helperText={
                                                    isDate && !stillStudying
                                                      ? form.errors
                                                          ?.education?.[index]
                                                          ?.endDate
                                                      : null
                                                  }
                                                  error={
                                                    isDate && !stillStudying
                                                      ? Boolean(
                                                          form.errors
                                                            ?.education?.[index]
                                                            ?.endDate
                                                        )
                                                      : null
                                                  }
                                                  fullWidth
                                                  disableFuture
                                                  clearable
                                                  clearText="Clear"
                                                  disabled={stillStudying}
                                                />
                                              )}
                                            />
                                          );
                                        }}
                                      </Field>
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values.education[index]
                                            ?.stillStudying || false
                                        }
                                        color="primary"
                                        onChange={(e) => {
                                          setFieldValue(
                                            `education[${index}].stillStudying`,
                                            e.target.checked
                                          );
                                        }}
                                        name={`education[${index}].stillStudying`}
                                      />
                                    }
                                    label="Təhsil davam edir"
                                  />
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => {
                                  remove(index);
                                  setIsDate(false);
                                }}
                              >
                                Təhsili sil
                              </Button>
                            </div>
                          ))}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() => {
                            push({
                              id: new Date().getTime(),
                              stillStudying: false,
                              edType: "",
                              institution: "",
                              faculty: "",
                              startDate: "",
                              endDate: "",
                            });
                            setIsDate(false);
                          }}
                        >
                          Təhsil əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                  {/* Submit button */}
                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
      <NewCommonDataDialog
        openAddDialog={openAddDialog}
        setOpenAddDialog={setOpenAddDialog}
        submitted={submitted}
        setSubmitted={setSubmitted}
        selectedType={selectedType}
      />
    </CustomAccordion>
  );
};

export default EditEducationInfo;
