import { Close } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import React from "react";

export default function HeaderDialog({
  title,
  onClose,
  containerStyle,
  closeBtnContStyle,
  btnStyle,
  headerContStyle,
  headerTextStyle,
}) {
  return (
    <Grid sx={[styles.container, containerStyle]}>
      <Grid sx={[styles.closeContainer, closeBtnContStyle]}>
        <Button onClick={onClose} sx={btnStyle}>
          <Close />
        </Button>
      </Grid>

      <Grid sx={[styles.headerContainer, headerContStyle]}>
        <Typography variant="h4" textAlign="center" sx={headerTextStyle}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    width: "100%",
  },
  closeContainer: {
    right: 2.5,
    position: "absolute",
    top: 2.5,
  },
  headerContainer: {
    width: "100%",
    margin: "auto",
    marginBottom: 5,
  },
};
