import { api } from "../../api/axiosInterceptor";

export const getStaticData = async (keywords) => {
  const encodedKeywords = encodeURIComponent(JSON.stringify(keywords));
  return await api.get(`/employee/static-data?keywords=${encodedKeywords}`);
};

export const getVersionStaticData = async () => {
  return await api.get(`/employee/static-data/versions`);
};
