import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

const DeleteBoard = ({
  openDeleteBoard,
  setOpenDeleteBoard,
  handleDeleteBoard,
}) => {
  return (
    <>
      <Dialog
        open={openDeleteBoard}
        onClose={() => {
          setOpenDeleteBoard(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Silmək istədiyinizdən əminsiniz ?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteBoard(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setOpenDeleteBoard(false);
              handleDeleteBoard();
            }}
          >
            Bəli
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteBoard;
