import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BASE_URL } from "../../../constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import DialogContent from "./DialogContent";
import CommentDialog from "./dialogParts/parts/CommentDialog";
import useDocumentDialogHook from "./useDocumentDialogHook";
import { GENERAL_BLOCK } from "./styled";
import {
  CloudDownload,
  CompareArrows,
  ExpandMore,
  IsoSharp,
  RemoveRedEye,
} from "@mui/icons-material";
import { GROUP_NAME } from "./dialogConstants";
import DocSVG from "../../../layouts/signing/dialog/docSVG/DocSVG";
import CommentInput from "./dialogParts/parts/CommentInput";
import { approveLabourContract } from "../../../api/contractRequests/contractRequests";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { translationMap } from "./helpers";
import CommentDialogWithButton from "./CommentDialogWithButton";
import ButtonCustom from "../../ui/Button/ButtonCustom";
import styled, { keyframes } from "styled-components";

export default function DocumentDialog({
  refreshPage,
  data,
  fetchHandler = undefined,
  documentId,
  disableSigning = false,
  navList = [],
  buttonGroup,
  setStatus,
  modalStatus,
  submit,
  documentType = undefined,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isShow, setIsShow] = useState(false);

  const {
    contractData,
    fields,
    status,
    docNumberHandler,
    versionHandler,
    navHandler,
    currentNav,
    set_currentNav,
    generateBgColor,
    generatedHeader,
    versionsList,
    currentVersion,
    relatedDocs,
    comments,
    styles,
    renderValue,
  } = useDocumentDialogHook(
    isShow,
    fetchHandler,
    documentId,
    { isLoading, setIsLoading },
    modalStatus
  );
  const location = useLocation().pathname;
  setStatus && setStatus(status);
  // const onCloseHandler = () => {
  //   onClose();
  //   setContractData(null);
  //   setRelatedDocs([]);
  // };
  let docNames = [
    "structure-request-order",
    "structure-request-service",
    "employment-application",
    "labour-contract",
    "labour-order",
    "labourContractUpdate",
    "vacation-order",
    "vacation-application",
  ];

  const onCloseDialog = (id) => {
    setIsShow(false);
  };

  const generateVersion = () => {
    if (versionsList?.length <= 1) {
      return <MenuItem value={0}>1</MenuItem>;
    }

    return versionsList?.map((item, index) => {
      return (
        <MenuItem
          key={index}
          value={item.value}
          sx={{ backgroundColor: "#0f0f0f" }}
        >
          {item.label}
        </MenuItem>
      );
    });
  };

  const handleNav = (i, documentId) => {
    set_currentNav(i);
    navHandler(documentId);
  };

  // the function below is used for approving the current LABOUR CONTRACT in Signing Page(Sənədlərin imzalanması)
  const appLabourContract = async () => {
    try {
      setIsLoading(true);
      const response = await approveLabourContract(documentId);
      if (response && response?.data) {
        setIsLoading(false);
        enqueueSnackbar("Əmək müqaviləsi təsdiqləndi", {
          variant: "success",
        });
        // paginationFetchingHandler();
        // setContId("");
        // setShowContDialog(false);
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi", {
        variant: "error",
      });
      console.log("err", error);
    }
  };
  return (
    <>
      <ButtonCustom
        onClick={() => setIsShow(true)}
        children={<RemoveRedEye />}
        // width={"33px"}
        style={{ height: "22px", background: "none" }}
        styleContainer={{ height: "auto" }}
      />
      {documentId && isShow && (
        <Dialog
          open={isShow}
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              width: "50%",
              borderRadius: "15px",
            },
          }}
          className="doc-dialog"
          onClose={onCloseDialog}
          // PaperProps={{
          //   sx: [
          //     styles.paper,
          //     {
          //       backgroundColor: !loading ? options.bgColor : "grey",
          //       opacity: loading && 0.9,
          //       display:
          //         (showCommDialog.isShow || showSignRequestDialog) && "none",
          //     },
          //   ],
          // }}
        >
          {/* {docNames?.includes(contractData?.contractType?.name) && ( */}
          <div>
            {isLoading ? (
              <div style={styles.dialogContainer}>
                <Container>
                  <Loading></Loading>
                </Container>
              </div>
            ) : (
              <>
                <div
                  style={{ backgroundColor: generateBgColor() }}
                  className="position-relative"
                >
                  {documentType && relatedDocs?.length > 0 && (
                    <div className="w-100 justify-content-end d-flex position-absolute">
                      {relatedDocs?.map((item, i) => (
                        <>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor:
                                currentNav === i && generateBgColor(),
                            }}
                            onClick={() =>
                              currentNav !== i && handleNav(i, item?.value)
                            }
                          >
                            {item.label}
                          </Button>
                        </>
                      ))}
                    </div>
                  )}
                  <div
                    className="text-center w-100"
                    style={{ fontSize: "20px" }}
                  >
                    <div className="pt-3">{generatedHeader()}</div>
                    {contractData && docNumberHandler() && (
                      <div className="p-3 d-flex aic">
                        {versionsList && (
                          <FormControl>
                            <div style={{ height: "100%", marginRight: "4px" }}>
                              V.{" "}
                              <Select
                                size="small"
                                disabled={versionsList.length <= 1}
                                value={currentVersion}
                                sx={{
                                  height: "20px",
                                }}
                                onChange={(e) => versionHandler(e.target.value)}
                              >
                                {generateVersion()}
                              </Select>
                            </div>
                          </FormControl>
                        )}

                        <Typography fontWeight={"bold"}>
                          {contractData && docNumberHandler()}
                        </Typography>
                      </div>
                    )}
                  </div>
                  <div className="p-3">
                    <div className="d-flex">
                      <GENERAL_BLOCK
                        wid="50%"
                        style={{ overflow: "scroll", height: "480px" }}
                      >
                        {Object.keys(fields)?.map((key) => {
                          if (key === "updates") {
                            return (
                              <>
                                <div
                                  className="text-center mt-4 mb-2"
                                  style={{ fontSize: "16px" }}
                                >
                                  Dəyişikliklər
                                </div>
                                {Object.keys(fields[key])?.map((k) => (
                                  <Accordion
                                    sx={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                                    // key={key}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMore />}
                                      aria-controls="panel1a-content"
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          color: "rgba(200,200,200,1)",
                                        }}
                                      >
                                        {GROUP_NAME[k]}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ marginX: "10px" }}>
                                      {fields[key][k]?.map((item) => (
                                        <div>
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              color: "text.secondary",
                                              fontSize: 14,
                                            }}
                                          >
                                            {item?.label}
                                          </Typography>
                                          <Typography
                                            variant="h6"
                                            component="div"
                                          >
                                            {renderValue(item, "old")}{" "}
                                            <CompareArrows />{" "}
                                            {renderValue(item, "new")}
                                          </Typography>
                                          <hr />
                                        </div>
                                      ))}
                                    </AccordionDetails>
                                  </Accordion>
                                ))}
                              </>
                            );
                          } else {
                            return (
                              <Accordion
                                sx={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                                // key={key}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-controls="panel1a-content"
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      color: "rgba(200,200,200,1)",
                                    }}
                                  >
                                    {GROUP_NAME[key]}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ marginX: "10px" }}>
                                  {key !== "updates" &&
                                    fields[key]?.map((item) => (
                                      <div>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            color: "text.secondary",
                                            fontSize: 14,
                                          }}
                                        >
                                          {item?.label}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                        >
                                          {renderValue(item)}
                                        </Typography>
                                        <hr />
                                      </div>
                                    ))}
                                </AccordionDetails>
                              </Accordion>
                            );
                          }
                        })}
                      </GENERAL_BLOCK>
                      <GENERAL_BLOCK wid="55%" className="ms-2">
                        <>
                          <div style={styles.container}>
                            <Grid sx={styles.docCont}>
                              <DocSVG style={styles.doc} />
                              <Grid sx={styles.downBtnCont}>
                                <IconButton
                                  sx={styles.downloadBtn}
                                  href={`${BASE_URL}/employee/contract/${contractData?._id}/download`}
                                  target="_blank"
                                >
                                  <CloudDownload
                                    sx={{ fontSize: 32, marginRight: 2 }}
                                  />
                                  <Typography>Sənədi yüklə</Typography>
                                </IconButton>
                              </Grid>
                            </Grid>
                          </div>
                        </>
                      </GENERAL_BLOCK>
                    </div>
                    <div className="d-flex mt-3">
                      <>
                        {comments?.length > 0 && (
                          <GENERAL_BLOCK wid="100%">
                            {comments?.map((item, index) => {
                              if (!item.label)
                                return <p key={item}>Melumat duzgun deyil</p>;
                              return (
                                <CommentInput
                                  key={index}
                                  label={item.label}
                                  value={item.value}
                                />
                              );
                            })}
                          </GENERAL_BLOCK>
                        )}
                      </>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      {location === "/labour-contract-register" ? (
                        <Button
                          onClick={appLabourContract}
                          color="success"
                          variant="contained"
                        >
                          Təsdiqlə
                        </Button>
                      ) : (
                        buttonGroup?.map((btn) => (
                          <Button
                            onClick={btn?.onClick}
                            className={`mt-2 ${btn?.gap}`}
                            fullWidth
                            variant="contained"
                            color={btn?.color}
                          >
                            {btn?.label}
                          </Button>
                        ))
                      )}
                    </div>
                    {status === "pending" &&
                      (location === "/signing" ||
                        location === "/approver-vacation-requests") && (
                        <CommentDialogWithButton
                          documentId={documentId}
                          submit={submit}
                        />
                      )}
                  </div>
                </div>
              </>
            )}
          </div>
        </Dialog>
      )}
    </>

    // {/* <Dialog
    // open={commmenDialog.isShow}
    // onClose={() => {
    //   renderCommmenDialog({ isShow: false, type: undefined });
    //   // setShowSignRequestDialog(false);
    // }}
    // PaperProps={{ style: commDialogPaperStyle }}
    // maxWidth={"1000px"}
    // >
    // <CommentDialog
    //   // setShowSignRequestDialog={() => setShowSignRequestDialog(true)}
    //   type={commmenDialog.type}
    //   documentId={documentId}
    //   onClose={() => {
    //     renderCommmenDialog({ isShow: false, type: undefined });
    //   }}
    //   closeSigningDialog={onClose}
    //   // onSubmitHandler={commentsProps.submitHandler}
    // />
    // </Dialog> */}
  );
}

const moveGradient = keyframes`
0% {
    transform: skewX(45deg) translateX(-150%);
  }
  50% {
    transform: skewX(45deg) translateX(300%);
  }
  75% {
    transform: skewX(45deg) translateX(300%);
  }
  100% {
    transform: skewX(45deg) translateX(300%);
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 5px 0px;
`;
const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px 15px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(250, 52, 52, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 10;
  border-radius: 5px;
  font-weight: 400;
  text-decoration: none;
  transition: 0.3s;
  overflow: hidden;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.109),
      transparent
    );
    transform: skewX(45deg) translateX(-150%);
    transition: 0.5s;
    animation: ${moveGradient} 2s ease-in-out infinite;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
`;
