import styled from "@emotion/styled";
import { Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Alert as MuiAlert,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { RemoveRedEye, VisibilityOff } from "@mui/icons-material";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [showPass, setShowPass] = useState(false);
  const formikRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (formikRef.current) {
          formikRef.current.handleSubmit();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Grid sx={{ width: "500px" }}>
      <Formik
        innerRef={formikRef}
        initialValues={{
          email: "",
          password: "",
          remember: true,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string()
            .max(255)
            .required("Password is required")
            .min(6, "Minimum 6 characters required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await signIn(values.email, values.password, values.remember);
            navigate("/private");
          } catch (error) {
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: "Email or password is incorrect" });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />
            <div style={{ position: "relative" }}>
              <TextField
                type={showPass ? "text" : "password"}
                name="password"
                label="Password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
              />
              <IconButton
                sx={{ position: "absolute", top: "22%", right: "2%" }}
                onClick={() => setShowPass((prev) => !prev)}
              >
                {!showPass ? <RemoveRedEye /> : <VisibilityOff />}
              </IconButton>
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  name="remember"
                  color="primary"
                  checked={values.remember}
                  onChange={(e) => setFieldValue("remember", e.target.checked)}
                />
              }
              label="Remember me"
              name="remember"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Sign in
            </Button>
            <Button
              component={Link}
              to="/auth/getotp"
              fullWidth
              color="primary"
            >
              Forgot password
            </Button>
          </form>
        )}
      </Formik>
    </Grid>
  );
}

export default SignIn;
