import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  checkBusinessTripDataList,
  createBusinessTrip,
  getEmployeeList,
  updateBusinessTrip,
} from "../pages/leaves/services";
import { removeEmptyStrings } from "../utils/removeEmptyString";
import dayjs from "dayjs";
import { getStaticData } from "../services/employees/StaticDataServices";
import moment from "moment";
import { getFitlersData } from "../services/filter/filterService";

export function useBusinessTrip(getBusinessTripData) {
  const [empData, setEmpData] = useState([]);
  const [country, setCountry] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [foreignCity, setForeignCity] = useState("");
  const [submited, setSubmited] = useState(false);
  const [show_Accordion, set_show_Accordion] = useState(false);
  const [disabledRanges, setDisabledRanges] = useState([]);
  const [disabledReasonMessage, setDisabledReasonMessage] = useState([]);
  const [leave_state, set_leave_state] = useState({
    staffId: "",
    staff: [],
    substituteStaff: [],
    employeeId: "",
    substituteId: "",
    substituteTabelNo: "",
    empTabelNo: "",
    substitute: [],
    isChecked: false,
    withinCounty: [],
    substituteStaffId: "",
    withinLocation: "",
    positionName: "",
    substitutePositionName: "",
    tripReason: "",
    tripStartDate: "",
    tripEndDate: "",
    returnToWorkDate: "",
    tripDuration: "",
    foreignTripDetails: "",
  });
  const filterModel = [
    {
      column: "status",
      operator: "equalsTo",
      value: "active",
    },
  ];
  const getData = async () => {
    try {
      const res = await getStaticData(["travelAllowances"]);
      const respons = await getEmployeeList();
      const resp = await getStaticData(["domesticTripLocations"]);
      setCountry(res?.data?.[0]?.data);
      setEmpData(respons.data);
      set_leave_state({ ...leave_state, withinCounty: resp?.data?.[0]?.data });
    } catch (err) {
      console.log(err);
    }
  };
  const getDateList = async () => {
    let es = [
      { employeeId: leave_state?.employeeId, staffId: leave_state?.staffId },
      {
        employeeId: leave_state?.substituteId,
        staffId: leave_state?.substituteStaffId,
      },
    ];
    if (leave_state?.employeeId && leave_state?.substituteId) {
      const dateList = await getFitlersData({
        es: es,
        dataGridName: "business-trip",
      });
      setDateList(dateList?.data);
    }
  };
  const processDisabledRangesAndMessages = () => {
    setDisabledRanges([]);
    setDisabledReasonMessage([]);
    for (let index = 0; index < dateList?.length; index++) {
      const element = dateList?.[index];

      if (element?.unactiveDates?.length > 0) {
        for (const item of element?.unactiveDates) {
          for (const key in item) {
            const range = {};
            if (item?.[key]?.startDate) {
              range.start = dayjs(item?.[key]?.startDate).toISOString();
            }
            if (item?.[key]?.endDate) {
              range.end = dayjs(item?.[key]?.endDate).toISOString();
            }

            if (Object.keys(range).length > 0) {
              setDisabledRanges((prevRanges) => [...prevRanges, range]);
            }
            setDisabledReasonMessage((prevMessages) => [
              ...prevMessages,
              item?.[key]?.message,
            ]);
          }
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    processDisabledRangesAndMessages();
  }, [dateList]);
  useEffect(() => {
    getDateList();
  }, [leave_state?.staffId, leave_state?.substituteStaffId]);
  //   const getEmployeeData = async () => {
  //     const filter = encodeURIComponent(JSON.stringify(filterModel));
  //     try {
  //       const response = await getEmployees({ filter: filter });

  //       setEmpData(response?.employees);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  const isLeaveStateEmpty = () => {
    return (
      !leave_state.staffId ||
      !leave_state.employeeId ||
      !leave_state?.substituteStaffId ||
      !leave_state?.substituteId ||
      !leave_state?.tripReason ||
      !leave_state?.tripStartDate ||
      !leave_state?.tripEndDate ||
      !leave_state?.tripDuration ||
      !leave_state?.returnToWorkDate
    );
  };
  async function handleSubmit(e) {
    e.preventDefault();
    setSubmited(true);
    const modifiedValues = {
      staff: leave_state?.staffId,
      employee: leave_state?.employeeId,
      substituteStaff: leave_state?.substituteStaffId,
      substituteEmployee: leave_state?.substituteId,
      tripReason: leave_state?.tripReason,
      isForeignCountry: leave_state?.isChecked,
      domesticTripDetails: !leave_state?.isChecked
        ? [
            {
              location: leave_state?.withinLocation,
            },
          ]
        : "",
      foreignTripDetails: leave_state?.isChecked
        ? [
            {
              name: leave_state?.foreignTripDetails?.name
                ? leave_state?.foreignTripDetails?.name
                : leave_state?.foreignTripDetails,
              cities: leave_state?.foreignTripDetails?.cities
                ? [
                    {
                      name: foreignCity,
                    },
                  ]
                : [],
            },
          ]
        : "",
      tripStartDate: moment(leave_state?.tripStartDate).format("MM/DD/YYYY"),
      tripEndDate: moment(leave_state?.tripEndDate).format("MM/DD/YYYY"),
      returnToWorkDate: moment(leave_state?.returnToWorkDate).format(
        "MM/DD/YYYY"
      ),
      tripDuration: Number(leave_state?.tripDuration),
    };

    const result = removeEmptyStrings(modifiedValues);

    try {
      await createBusinessTrip(result);
      toast.success("Yeni məlumat uğurla əlavə edildi!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      set_leave_state({
        staffId: "",
        staff: [],
        substituteStaff: [],
        employeeId: "",
        substituteId: "",
        substituteTabelNo: "",
        empTabelNo: "",
        substitute: [],
        isChecked: false,
        withinCounty: [],
        substituteStaffId: "",
        withinLocation: "",
        positionName: "",
        substitutePositionName: "",
        tripReason: "",
        tripStartDate: "",
        tripEndDate: "",
        returnToWorkDate: "",
        tripDuration: "",
        foreignTripDetails: "",
      });
      set_show_Accordion(false);
      setDisabledRanges([]);
      setDisabledReasonMessage([]);
      getBusinessTripData();
    } catch (err) {
      err?.response?.data?.errors.map((item) => {
        return toast.error(item?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
    setSubmited(false);
  }
  function calcTripDuration() {
    const tripStartDate = leave_state?.tripStartDate
      ? new Date(leave_state.tripStartDate)
      : null;
    const tripEndDate = leave_state?.tripEndDate
      ? new Date(leave_state.tripEndDate)
      : null;
    const returnToWorkDate = leave_state?.returnToWorkDate
      ? new Date(leave_state.returnToWorkDate)
      : null;
    if (
      !tripStartDate ||
      isNaN(tripStartDate) ||
      !tripEndDate ||
      isNaN(tripEndDate)
    ) {
      console.error("Invalid tripStartDate or tripEndDate");
      return;
    }

    let resultTripDuration =
      (tripEndDate - tripStartDate) / (1000 * 60 * 60 * 24);
    if (
      returnToWorkDate &&
      !isNaN(returnToWorkDate) &&
      moment(tripEndDate).format("DD/MM/YYYY") !==
        moment(returnToWorkDate).format("DD/MM/YYYY")
    ) {
      resultTripDuration += 1;
    }
    if (!isNaN(resultTripDuration) && resultTripDuration >= 0) {
      set_leave_state((prevState) => ({
        ...prevState,
        tripDuration: resultTripDuration,
      }));
    } else {
      console.error("Invalid trip duration calculation");
    }
  }

  useEffect(() => {
    calcTripDuration();
  }, [
    leave_state?.tripStartDate,
    leave_state?.tripEndDate,
    leave_state?.returnToWorkDate,
  ]);

  const shouldDisableDate = (date) => {
    const targetDate = new Date(date);
    const targetUTCDate = Date.UTC(
      targetDate.getUTCFullYear(),
      targetDate.getUTCMonth(),
      targetDate.getUTCDate()
    );

    return disabledRanges.some((range) => {
      // Handle ranges with both start and end dates
      if (range.start && range.end) {
        const startDate = new Date(range.start);
        const endDate = new Date(range.end);

        startDate.setUTCDate(startDate.getUTCDate() - 1); // Adjust start date (optional)
        endDate.setUTCDate(endDate.getUTCDate() - 1); // Adjust end date (optional)

        const startUTCDate = Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate()
        );
        const endUTCDate = Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate()
        );

        return targetUTCDate >= startUTCDate && targetUTCDate <= endUTCDate;
      }
      if (range.start) {
        const startDate = new Date(range.start);
        startDate.setUTCDate(startDate.getUTCDate() - 1);

        const startUTCDate = Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate()
        );

        return targetUTCDate >= startUTCDate;
      }
      if (range.end) {
        const endDate = new Date(range.end);
        endDate.setUTCDate(endDate.getUTCDate() - 1);

        const endUTCDate = Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate()
        );

        return targetUTCDate <= endUTCDate;
      }

      return false;
    });
  };
  return {
    country,
    empData,
    submited,
    leave_state,
    handleSubmit,
    setForeignCity,
    show_Accordion,
    set_leave_state,
    isLeaveStateEmpty,
    shouldDisableDate,
    set_show_Accordion,
    disabledReasonMessage,
  };
}

export function useEditBusinessTrip(data, getData, close) {
  const [loading, setLoading] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [disabledRanges, setDisabledRanges] = useState([]);
  const [disabledReasonMessage, setDisabledReasonMessage] = useState([]);

  const edit_state = {
    staffId: data?.staff?._id,
    staff: [],
    substituteStaff: [],
    employeeId: data?.employee?._id,
    substituteId: data?.substituteEmployee?._id,
    substituteTabelNo: data?.substituteEmployee?.tableNumber,
    empTabelNo: data?.employee?.tableNumber,
    substitute: [],
    isChecked: data?.isForeignCountry,
    withinCounty: [],
    substituteStaffId: data?.substituteStaff?._id,
    withinLocation: data?.domesticTripDetails?.[0]?.location || "",
    positionName: data?.position?.source?.name,
    substitutePositionName: data?.substitutePosition?.source?.name,
    tripReason: data?.tripReason,
    tripStartDate: data?.tripStartDate,
    tripEndDate: data?.tripEndDate,
    returnToWorkDate: data?.returnToWorkDate,
    tripDuration: data?.tripDuration,
    foreignTripDetails:
      data?.foreignTripDetails?.[0]?.cities?.length > 0
        ? {
            name: data?.foreignTripDetails?.[0]?.name,
            cities: [
              { name: data?.foreignTripDetails?.[0]?.cities?.[0]?.name },
            ],
          }
        : {
            name: data?.foreignTripDetails?.[0]?.name,
            cities: [],
          },
  };
  const handleLoading = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };
  const getDateList = async (leave_state) => {
    let es = [
      {
        employeeId:
          (edit_state?.employeeId &&
            edit_state?.employeeId === leave_state?.employeeId) ||
          !leave_state?.employeeId
            ? edit_state?.employeeId
            : leave_state?.employeeId,
        staffId:
          (edit_state?.staffId &&
            edit_state?.staffId === leave_state?.staffId) ||
          !leave_state?.staffId
            ? edit_state?.staffId
            : leave_state?.staffId,
      },
      {
        employeeId:
          edit_state?.substituteId === leave_state?.substituteId ||
          !leave_state?.substituteId
            ? edit_state?.substituteId
            : leave_state?.substituteId,
        staffId:
          edit_state?.substituteStaffId === leave_state?.substituteStaffId ||
          !leave_state?.substituteStaffId
            ? edit_state?.substituteStaffId
            : leave_state?.substituteStaffId,
      },
    ];
    if (
      (edit_state?.employeeId && edit_state?.substituteId) ||
      (leave_state?.employeeId && leave_state?.substituteId)
    ) {
      const dateList = await getFitlersData({
        es: es,
        dataGridName: "business-trip",
      });

      setDateList(dateList?.data);
    }
  };
  const processDisabledRangesAndMessages = () => {
    setDisabledRanges([]);
    setDisabledReasonMessage([]);
    for (let index = 0; index < dateList.length; index++) {
      const element = dateList[index];
      if (element?.unactiveDates?.length > 0) {
        for (const item of element.unactiveDates) {
          for (const key in item) {
            const range = {};
            if (item?.[key]?.startDate) {
              range.start = dayjs(item?.[key]?.startDate).toISOString();
            }
            if (item?.[key]?.endDate) {
              range.end = dayjs(item?.[key]?.endDate).toISOString();
            }

            if (Object.keys(range).length > 0) {
              setDisabledRanges((prevRanges) => [...prevRanges, range]);
            }
            setDisabledReasonMessage((prevMessages) => [
              ...prevMessages,
              item?.[key]?.message,
            ]);
          }
        }
      }
    }
  };
  useEffect(() => {
    processDisabledRangesAndMessages();
  }, [dateList]);
  useEffect(() => {
    handleLoading();
  }, []);
  useEffect(() => {
    getDateList();
  }, [edit_state?.employeeId, edit_state?.substituteId]);
  function calcTripDuration(leave_state, setFieldValue) {
    const tripStartDate = leave_state?.tripStartDate
      ? new Date(leave_state.tripStartDate)
      : null;
    const tripEndDate = leave_state?.tripEndDate
      ? new Date(leave_state.tripEndDate)
      : null;
    const returnToWorkDate = leave_state?.returnToWorkDate
      ? new Date(leave_state.returnToWorkDate)
      : null;
    if (
      !tripStartDate ||
      isNaN(tripStartDate) ||
      !tripEndDate ||
      isNaN(tripEndDate)
    ) {
      console.error("Invalid tripStartDate or tripEndDate");
      return;
    }

    let resultTripDuration =
      (tripEndDate - tripStartDate) / (1000 * 60 * 60 * 24);
    if (
      returnToWorkDate &&
      !isNaN(returnToWorkDate) &&
      moment(tripEndDate).format("DD/MM/YYYY") !==
        moment(returnToWorkDate).format("DD/MM/YYYY")
    ) {
      resultTripDuration += 1;
    }
    if (!isNaN(resultTripDuration) && resultTripDuration >= 0) {
      setFieldValue("tripDuration", Math.floor(resultTripDuration));
    } else {
      console.error("Invalid trip duration calculation");
    }
  }
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);

      const modifiedValues = {
        staff: edit_state?.staffId !== values?.staffId ? values?.staffId : "",
        employee:
          edit_state?.employeeId !== values?.employeeId
            ? values?.employeeId
            : "",
        substituteStaff:
          edit_state?.substituteStaffId !== values?.substituteStaffId
            ? values?.substituteStaffId
            : "",
        substituteEmployee:
          edit_state?.substituteId !== values?.substituteId
            ? values?.substituteId
            : "",
        tripReason:
          edit_state?.tripReason !== values?.tripReason
            ? values?.tripReason
            : "",
        isForeignCountry:
          edit_state?.isChecked !== values?.isChecked ? values?.isChecked : "",
        domesticTripDetails:
          !values?.isChecked &&
          edit_state?.withinLocation !== values?.withinLocation
            ? [
                {
                  location: values?.withinLocation,
                },
              ]
            : "",
        foreignTripDetails: values?.isChecked
          ? [
              {
                name: values?.foreignTripDetails?.name
                  ? values?.foreignTripDetails?.name
                  : values?.foreignTripDetails,
                cities: values?.foreignTripDetails?.cities
                  ? [
                      {
                        name: values?.foreignTripDetails?.cities?.[0]?.name,
                      },
                    ]
                  : [],
              },
            ]
          : "",
        tripStartDate:
          edit_state?.tripStartDate === values?.tripStartDate
            ? ""
            : moment(values?.tripStartDate).format("MM/DD/YYYY"),
        tripEndDate:
          edit_state?.tripEndDate === values?.tripEndDate
            ? ""
            : moment(values?.tripEndDate).format("MM/DD/YYYY"),
        returnToWorkDate:
          edit_state?.returnToWorkDate === values?.returnToWorkDate
            ? ""
            : moment(values?.returnToWorkDate).format("MM/DD/YYYY"),
        tripDuration:
          edit_state?.tripDuration === values?.tripDuration
            ? ""
            : Number(values?.tripDuration),
      };
      const result = removeEmptyStrings(modifiedValues);
      await updateBusinessTrip(data?._id, result);
      toast.success("Məlumatlar uğurla yeniləndi!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      getData();
      close(false);
      setDisabledRanges([]);
      setDisabledReasonMessage([]);
    } catch (error) {
      error?.response?.data?.errors.map((item) => {
        return toast.error(item?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    } finally {
      setSubmitting(false);
    }
  };
  const shouldDisableDate = (date) => {
    const targetDate = new Date(date);
    const targetUTCDate = Date.UTC(
      targetDate.getUTCFullYear(),
      targetDate.getUTCMonth(),
      targetDate.getUTCDate()
    );

    return disabledRanges.some((range) => {
      // Handle ranges with both start and end dates
      if (range.start && range.end) {
        const startDate = new Date(range.start);
        const endDate = new Date(range.end);

        startDate.setUTCDate(startDate.getUTCDate() - 1); // Adjust start date (optional)
        endDate.setUTCDate(endDate.getUTCDate() - 1); // Adjust end date (optional)

        const startUTCDate = Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate()
        );
        const endUTCDate = Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate()
        );

        return targetUTCDate >= startUTCDate && targetUTCDate <= endUTCDate;
      }
      if (range.start) {
        const startDate = new Date(range.start);
        startDate.setUTCDate(startDate.getUTCDate() - 1);

        const startUTCDate = Date.UTC(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate()
        );

        return targetUTCDate >= startUTCDate;
      }
      if (range.end) {
        const endDate = new Date(range.end);
        endDate.setUTCDate(endDate.getUTCDate() - 1);

        const endUTCDate = Date.UTC(
          endDate.getUTCFullYear(),
          endDate.getUTCMonth(),
          endDate.getUTCDate()
        );

        return targetUTCDate <= endUTCDate;
      }

      return false;
    });
  };
  return {
    loading,
    edit_state,
    getDateList,
    handleSubmit,
    calcTripDuration,
    shouldDisableDate,
    disabledReasonMessage,
  };
}
