import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { deleteEmployeeById } from "../../api/api";
import { useNavigate } from "react-router-dom";

const DeleteEmployeeDialog = ({
  id,
  deleteDialog,
  setDeleteDialog,
  setSubmitted,
  submitted,
}) => {
  const navigate = useNavigate();
  const handleDelete = async (id) => {
    await deleteEmployeeById(id);
    setSubmitted(!submitted);
  };
  return (
    <Dialog
      open={deleteDialog}
      onClose={() => setDeleteDialog(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Silmək istədiyinizdən əminsiniz ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Bu əməliyyata davam etsəniz əməkdaş silinəcək!
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setDeleteDialog(false)} color="primary">
          Ləğv et
        </Button>
        <Button
          onClick={() => {
            handleDelete(id);
            navigate("/employees", { replace: true });
            setDeleteDialog(false);
          }}
          color="primary"
        >
          Bəli
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteEmployeeDialog;
