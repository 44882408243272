import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { updateCompanyPartsById } from "../../api/api";

const EditDataDialog = ({
  openEditDialog,
  setOpenEditDialog,
  rowId,
  setRowId,
  row,
  submitted,
  setSubmitted,
}) => {
  const [newName, setNewName] = useState(row.name);
  const [newDescription, setNewDescription] = useState(row.description);

  //   update company by id
  const handleUpdateCompanyById = async (rowId, newName, newDescription) => {
    await updateCompanyPartsById(rowId, newName, newDescription);
    setSubmitted(!submitted);
  };

  return (
    <>
      {rowId === row.id ? (
        <Dialog
          fullWidth
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            setRowId();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Məlumatları dəyişdir</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Adı"
              type="text"
              placeholder={row.name}
              defaultValue={row.name}
              fullWidth
              onChange={(e) => setNewName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenEditDialog(false);
                setRowId();
              }}
              color="primary"
            >
              Ləğv et
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleUpdateCompanyById(rowId, newName, newDescription);
                setOpenEditDialog(false);
              }}
            >
              Redaktə et
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default EditDataDialog;
