import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";

const AddList = ({ openAddList, setOpenAddList, handleCreateList }) => {
  const [newListName, setNewListName] = useState("");
  return (
    <>
      <Dialog
        fullWidth
        open={openAddList}
        onClose={() => {
          setOpenAddList(false);
          setNewListName("");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Yeni list əlavə et</Typography>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
      To subscribe to this website, please enter your email address
      here. We will send updates occasionally.
    </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Adı"
            type="text"
            onChange={(e) => setNewListName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAddList(false);
              setNewListName("");
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setOpenAddList(false);
              handleCreateList(newListName.trim());
            }}
            color="primary"
            disabled={newListName.trim() === ""}
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddList;
