import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  useFormikContext,
} from "formik";
import React, { useEffect, useState } from "react";
import { getCommonData, updateEmployee } from "../../api/api";
import moment from "moment";
import { certificationsValidation } from "../employees/Validations";
import CustomAccordion from "../ui/Accordion/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EditCertification = ({
  employeeById,
  setSelectedType,
  setOpenAddDialog,
  setSubmitted,
  submitted,
}) => {
  const [certification, setCertification] = useState([]);
  const [pastData, setPastData] = useState([]);

  useEffect(() => {
    const handleCertification = async () => {
      try {
        const response = await getCommonData("certificates");
        const certification = response.data;
        const combinedData = [];
        setCertification(certification);
        if (certification.length) {
          combinedData.push({ certification: [...certification] });
        }
        handlePastData(combinedData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    handleCertification();
  }, [submitted]);
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    // Handle form submission
    const modifiedValues = {
      ...values,
      certifications:
        values?.certifications &&
        values?.certifications.map((item) => ({
          ...item,
          issueDate: item.issueDate
            ? moment(item.issueDate).format("MM/DD/yyyy")
            : null,
          expireDate: item.expireDate
            ? moment(item.expireDate).format("MM/DD/yyyy")
            : null,
        })),
    };
    await updateEmployee(employeeById._id, modifiedValues);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };
  const initialValues = {
    certifications: employeeById.certifications || [],
    isChecked: false,
  };
  function handlePastData(data) {
    setPastData(data);
  }
  function handlePush(index) {
    certification &&
      certification.push(employeeById?.certifications[index]?.qualification);
  }
  function handleHidden(name, data) {
    return pastData.every((past) => !past[name]?.includes(data));
  }

  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Sertifikasiyaları</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              certifications: initialValues.certifications || [],
              isChecked: initialValues.isChecked,
            }}
            onSubmit={handleSubmit}
            validationSchema={certificationsValidation}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              setErrors,
              dirty,
            }) => (
              <>
                {console.log(errors)}
                <Form>
                  <FieldArray name="certifications">
                    {({ push, remove }) => (
                      <div>
                        {values?.certifications &&
                          values?.certifications.map((form, index) => (
                            <div key={index}>
                              <>
                                {handlePush(index)}
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1})Sertifikasiya
                                </Typography>

                                {/* Rewards/ Reprimands and  description */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        errors?.certifications?.[index]
                                          ?.qualification &&
                                        touched?.certifications?.[index]
                                          ?.qualification &&
                                        Boolean(
                                          errors?.certifications?.[index]
                                            ?.qualification
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`certifications[${index}].qualification`}
                                      >
                                        Sertifikatın adı
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`certifications[${index}].qualification`}
                                        labelId={`certifications[${index}].qualification`}
                                        label="Sertifikatın adı"
                                        id={`certifications[${index}].qualification`}
                                        defaultValue=""
                                        fullWidth
                                        error={
                                          errors?.certifications?.[index]
                                            ?.qualification &&
                                          touched?.certifications?.[index]
                                            ?.qualification &&
                                          Boolean(
                                            errors?.certifications?.[index]
                                              ?.qualification
                                          )
                                        }
                                        onChange={async (e) => {
                                          setFieldValue(
                                            `certifications[${index}].qualification`,
                                            e.target.value
                                          );
                                          if (
                                            errors?.certifications?.[index]
                                              ?.qualification
                                          ) {
                                            setErrors({
                                              ...errors,
                                              certifications: [
                                                ...errors.certifications.slice(
                                                  0,
                                                  index
                                                ),
                                                {
                                                  ...errors.certifications[
                                                    index
                                                  ],
                                                  qualification: "",
                                                },
                                                ...errors.certifications.slice(
                                                  index + 1
                                                ),
                                              ],
                                            });
                                          }
                                        }}
                                      >
                                        {certification.length > 0 &&
                                          [...new Set(certification)]
                                            .sort((a, b) =>
                                              a.localeCompare(b, "az")
                                            )
                                            .map((item, index) => (
                                              <MenuItem
                                                hidden={handleHidden(
                                                  "certification",
                                                  item
                                                )}
                                                value={item}
                                                key={index}
                                              >
                                                {item}
                                              </MenuItem>
                                            ))}
                                        <Button
                                          sx={{
                                            mt: 3,
                                            ml: 3,
                                          }}
                                          variant="outlined"
                                          onClick={() => {
                                            setOpenAddDialog(true);
                                            setSelectedType([
                                              "certificates",
                                              { name: "İxtisaslar" },
                                            ]);
                                          }}
                                        >
                                          Yeni ixtisas əlavə et
                                        </Button>
                                      </Field>
                                      {errors?.certifications?.[index]
                                        ?.qualification &&
                                        touched?.certifications?.[index]
                                          ?.qualification &&
                                        errors?.certifications?.[index]
                                          ?.qualification && (
                                          <FormHelperText error>
                                            {
                                              errors?.certifications?.[index]
                                                ?.qualification
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                  <Grid
                                    sx={{
                                      width: "100%",
                                    }}
                                  >
                                    <Field
                                      type="text"
                                      name={`certifications[${index}].number`}
                                      as={TextField}
                                      fullWidth
                                      error={
                                        touched?.certifications?.[index]
                                          ?.number &&
                                        errors?.certifications?.[index]
                                          ?.number &&
                                        Boolean(
                                          errors?.certifications?.[index]
                                            ?.number
                                        )
                                      }
                                      helperText={
                                        touched?.certifications?.[index]
                                          ?.number &&
                                        errors?.certifications?.[index]
                                          ?.number &&
                                        errors?.certifications?.[index]?.number
                                      }
                                      label="Sertifikat nömrəsi *"
                                      onChange={async (e) => {
                                        setFieldValue(
                                          `certifications[${index}].number`,
                                          e.target.value
                                        );
                                        if (
                                          errors?.certifications?.[index]
                                            ?.number
                                        ) {
                                          setErrors({
                                            ...errors,
                                            certifications: [
                                              ...errors.certifications.slice(
                                                0,
                                                index
                                              ),
                                              {
                                                ...errors.certifications[index],
                                                number: "",
                                              },
                                              ...errors.certifications.slice(
                                                index + 1
                                              ),
                                            ],
                                          });
                                        }
                                      }}
                                    />
                                  </Grid>
                                </Grid>

                                {/* start date and company */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      name={`certifications[${index}].issueDate`}
                                    >
                                      {({ field, form }) => (
                                        <DatePicker
                                          {...field}
                                          label="Sertifikatın verilmə tarixi *"
                                          inputFormat="dd/MM/yyyy"
                                          value={field.value || null}
                                          onChange={(date) => {
                                            if (
                                              date instanceof Date &&
                                              !isNaN(date)
                                            ) {
                                              form.setFieldValue(
                                                `certifications[${index}].issueDate`,
                                                date
                                              );

                                              // Calculate endDate as 5 years after date
                                              const expireDate = new Date(date);
                                              expireDate.setFullYear(
                                                expireDate.getFullYear() + 5
                                              );
                                              form.setFieldValue(
                                                `certifications[${index}].expireDate`,
                                                expireDate
                                              );

                                              if (
                                                errors?.certifications?.[index]
                                                  ?.issueDate
                                              ) {
                                                setErrors({
                                                  ...errors,
                                                  certifications: [
                                                    ...errors.certifications.slice(
                                                      0,
                                                      index
                                                    ),
                                                    {
                                                      ...errors.certifications[
                                                        index
                                                      ],
                                                      issueDate: "",
                                                    },
                                                    ...errors.certifications.slice(
                                                      index + 1
                                                    ),
                                                  ],
                                                });
                                              }
                                            } else {
                                              form.setFieldValue(
                                                `certifications[${index}].issueDate`,
                                                ""
                                              );
                                              form.setFieldValue(
                                                `certifications[${index}].expireDate`,
                                                ""
                                              );
                                            }
                                          }}
                                          maxDate={new Date()}
                                          minDate={new Date(1940, 0, 1)}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={
                                                form.errors?.certifications?.[
                                                  index
                                                ]?.issueDate
                                              }
                                              error={Boolean(
                                                form.errors?.certifications?.[
                                                  index
                                                ]?.issueDate
                                              )}
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid
                                    sx={{
                                      width: "100%",
                                    }}
                                  >
                                    <Field
                                      name={`certifications[${index}].expireDate`}
                                    >
                                      {({ field, form }) => (
                                        <DatePicker
                                          {...field}
                                          label="Sertifikatın etibarlıq müddəti"
                                          inputFormat="dd/MM/yyyy"
                                          value={
                                            field.value && !values?.isChecked
                                              ? new Date(field.value)
                                              : ""
                                          }
                                          onChange={(date) => {
                                            if (
                                              date instanceof Date &&
                                              !isNaN(date)
                                            ) {
                                              form.setFieldValue(
                                                `certifications[${index}].expireDate`,
                                                date
                                              );
                                            }
                                          }}
                                          // maxDate={new Date()}
                                          minDate={new Date()}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={
                                                form.touched?.certifications?.[
                                                  index
                                                ]?.expireDate &&
                                                !values.isChecked &&
                                                form.errors?.certifications?.[
                                                  index
                                                ]?.expireDate
                                              }
                                              error={
                                                form.touched?.certifications?.[
                                                  index
                                                ]?.expireDate &&
                                                !values.isChecked &&
                                                Boolean(
                                                  form.errors?.certifications?.[
                                                    index
                                                  ]?.expireDate
                                                )
                                              }
                                              fullWidth
                                            />
                                          )}
                                          fullWidth
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                </Grid>
                                <Grid
                                  sx={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "center",
                                  }}
                                >
                                  <InputLabel>
                                    Sertifikatın etibarlıq müddəti mövcud deyil{" "}
                                  </InputLabel>
                                  <Checkbox
                                    checked={values?.isChecked}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "isChecked",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => remove(index)}
                              >
                                Sertifikasiya sil
                              </Button>
                            </div>
                          ))}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() => push({})}
                        >
                          Sertifikasiya əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditCertification;
