import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay, isAfter, isBefore } from "date-fns";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { getCommonData, getFileById, updateEmployee } from "../../api/api";
import { BASE_URL } from "../../constants";
import { documentsValidation } from "../employees/Validations";
import { removeEmptyStrings } from "../../utils/removeEmptyString";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomAccordion from "../ui/Accordion/Accordion";

const today = endOfDay(new Date());
const EditDocuments = ({
  submitted,
  employeeById,
  setSubmitted,
  setSelectedType,
  setOpenAddDialog,
}) => {
  const baseURL = BASE_URL;

  const [documentsType, setDocumentsType] = useState([]);
  const [showPicture, setShowPicture] = useState(false);
  const [isDate, setIsDate] = useState(false);
  const [image, setImage] = useState({ src: "", name: "" });
  const [pastData, setPastData] = useState([]);
  const [fileBase64, setFileBase64] = useState({ src: "", name: "" });

  const handleFileUpload = useCallback((event, index, setFieldValue) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = reader.result;
      const fieldName = `documents[${index}].fileBase64`;
      setFieldValue(fieldName, base64Image); // Use setFieldValue from Formik
      setFieldValue(`documents[${index}].filename`, "");
    };
    reader.readAsDataURL(file);
  }, []);

  useEffect(() => {
    const handleDocumentsType = async () => {
      try {
        const response = await getCommonData("documentTypes");
        const documentsType = response?.data || [];
        setDocumentsType(documentsType);
        const combinedData = [];
        if (documentsType.length) {
          combinedData.push({ documentsType: [...documentsType] });
        }
        handlePastData(combinedData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    handleDocumentsType();
  }, [submitted]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    // Handle form submission
    const { user, ...modifiedValues } = {
      ...values,
      documents:
        values?.documents &&
        values?.documents.map((item) => {
          const documentItem = {
            docType: item.docType,
            issuanceDate: item.issuanceDate
              ? moment(item.issuanceDate).format("MM/DD/yyyy")
              : null,
            expirationDate: item.expirationDate
              ? moment(item.expirationDate).format("MM/DD/yyyy")
              : "",
          };

          // Check if both filename and fileBase64 exist in the item
          if (item.filename && item.fileBase64) {
            // Create a new object with fileBase64 and exclude filename
            return {
              ...documentItem,
              fileBase64: item.fileBase64,
            };
          }

          // Check if only filename exists
          if (item.filename) {
            // Create a new object with filename and exclude fileBase64
            return {
              ...documentItem,
              filename: item.filename._id,
            };
          }

          // Check if only fileBase64 exists
          if (item.fileBase64) {
            // Create a new object with fileBase64 and exclude filename
            return {
              ...documentItem,
              fileBase64: item.fileBase64,
            };
          }

          // If neither filename nor fileBase64 is present, return the item without modification
          return item;
        }),
    };
    // Handle form submission
    const result = removeEmptyStrings(modifiedValues);
    await updateEmployee(employeeById._id, result);
    setIsDate(false);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };

  const initialValues = {
    documents: employeeById.documents || [],
  };

  const handleShowImage = async (values, index) => {
    let imgSrc = "";
    if (values.documents[index].filename) {
      const res = await getFileById(
        `${values.documents[index].filename._id}?fileFormat=base64`
      );
      imgSrc = res?.data || "";
    } else {
      imgSrc = values.documents[index].fileBase64 || "";
    }
    if (imgSrc) {
      setImage({
        src: imgSrc,
        name: values.documents[index].docType,
      });
      setShowPicture(true);
    }
  };

  const handleCloseImage = () => {
    setImage({ src: "", name: "" });
    setFileBase64({ src: "", name: "" });
    setShowPicture(false);
  };
  function handlePastData(data) {
    setPastData(data);
  }
  function handlePush(index) {
    documentsType &&
      documentsType.push(employeeById?.documents[index]?.docType);
  }
  function handleHidden(name, data) {
    return pastData.every((past) => !past[name]?.includes(data));
  }

  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Şəxsi işi - Sənədlər və şəkilləri</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            validationSchema={documentsValidation}
            initialValues={{
              documents: initialValues.documents || [],
            }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="documents">
                    {({ push, remove }) => (
                      <div>
                        {values?.documents &&
                          values?.documents.map((form, index) => (
                            <div key={index}>
                              <>
                                {handlePush(index)}
                                <Typography mt={5} variant="h6">
                                  {index + 1}) Şəxsi işi - Sənədlər və şəkillər
                                </Typography>

                                {/* documents catogry and itself*/}
                                <Grid
                                  sx={{
                                    display: "flex",
                                    gap: 3,
                                    mb: 3,
                                    alignItems: "center",
                                    mt: 5,
                                  }}
                                >
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.documents?.[index]?.docType &&
                                        errors?.documents &&
                                        errors?.documents[index]?.docType
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`documents[${index}].docType`}
                                      >
                                        Sənədin növü
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`documents[${index}].docType`}
                                        labelId={`documents[${index}].docType`}
                                        label="Sənədin növü"
                                        id={`documents[${index}].docType`}
                                        defaultValue=""
                                        fullWidth
                                        error={
                                          touched?.documents?.[index]
                                            ?.docType &&
                                          errors?.documents?.[index]?.docType &&
                                          Boolean(
                                            errors?.documents?.[index]?.docType
                                          )
                                        }
                                      >
                                        {documentsType &&
                                          [...new Set(documentsType)]
                                            .sort((a, b) =>
                                              a.localeCompare(b, "az")
                                            )
                                            .map((item, index) => (
                                              <MenuItem
                                                hidden={handleHidden(
                                                  "documentsType",
                                                  item
                                                )}
                                                value={item}
                                              >
                                                {item}
                                              </MenuItem>
                                            ))}
                                        <Button
                                          sx={{
                                            mt: 3,
                                            ml: 3,
                                          }}
                                          variant="outlined"
                                          onClick={() => {
                                            setOpenAddDialog(true);
                                            setSelectedType([
                                              "documentTypes",
                                              { name: "Sənəd növləri" },
                                            ]);
                                          }}
                                        >
                                          Yeni sənəd növü əlavə et
                                        </Button>
                                      </Field>
                                    </FormControl>
                                    {/* <ErrorMessage
                                  name={`documents[${index}].docType`}
                                  component="div"
                                /> */}
                                    {touched?.documents?.[index]?.docType &&
                                      errors?.documents?.[index]?.docType && (
                                        <FormHelperText error>
                                          {errors.documents?.[index]?.docType}
                                        </FormHelperText>
                                      )}
                                  </Grid>

                                  {values.documents[index].filename ||
                                  values.documents[index].fileBase64 ? (
                                    <Grid sx={{ width: "100%" }}>
                                      <Grid
                                        sx={{
                                          display: "flex",
                                          gap: 3,
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          sx={{ height: "50px" }}
                                          fullWidth
                                          variant="contained"
                                          onClick={() => {
                                            handleShowImage(values, index);
                                          }}
                                        >
                                          Şəkil yüklə (png, jpeg)
                                        </Button>
                                        <Grid sx={{ width: "100%" }}>
                                          <Button
                                            sx={{ height: "50px" }}
                                            fullWidth
                                            variant="contained"
                                            component="label"
                                          >
                                            Şəkli dəyişdir
                                            <input
                                              type="file"
                                              accept="image/png, image/jpeg"
                                              onChange={(event) =>
                                                handleFileUpload(
                                                  event,
                                                  index,
                                                  setFieldValue
                                                )
                                              }
                                              fullWidth
                                              hidden
                                            />
                                          </Button>
                                          {/* { (
                                        <ErrorMessage
                                          name={`documents[${index}].image`}
                                          component="div"
                                        />
                                      )} */}
                                          {touched?.documents?.[index]
                                            ?.docType &&
                                            errors?.documents?.[index]
                                              ?.docType && (
                                              <FormHelperText error>
                                                {
                                                  errors.documents?.[index]
                                                    ?.fileBase64
                                                }
                                              </FormHelperText>
                                            )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <Grid sx={{ width: "100%" }}>
                                      <Button
                                        sx={{ height: "50px" }}
                                        fullWidth
                                        variant="contained"
                                        component="label"
                                      >
                                        Şəkil yüklə
                                        <input
                                          type="file"
                                          accept="image/*"
                                          onChange={(event) =>
                                            handleFileUpload(
                                              event,
                                              index,
                                              setFieldValue
                                            )
                                          }
                                          fullWidth
                                          hidden
                                        />
                                      </Button>
                                      {/* {isSubmitting && (
                                    <ErrorMessage
                                      name={`documents[${index}].image`}
                                      component="div"
                                    />
                                  )} */}
                                      {touched?.documents?.[index]?.docType &&
                                        errors?.documents?.[index]?.docType && (
                                          <FormHelperText error>
                                            {
                                              errors.documents?.[index]
                                                ?.fileBase64
                                            }
                                          </FormHelperText>
                                        )}
                                    </Grid>
                                  )}
                                </Grid>

                                {/* Picture dialog */}
                                <Dialog
                                  fullWidth
                                  open={showPicture}
                                  onClose={handleCloseImage}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {image.name ? image.name : fileBase64.name}
                                  </DialogTitle>
                                  <DialogContent>
                                    {image.src ? (
                                      <Box
                                        component="img"
                                        sx={{
                                          height: 350,
                                          width: "100%",
                                        }}
                                        src={image.src} // This will display the base64 encoded image
                                      />
                                    ) : null}
                                    {fileBase64.src ? (
                                      <Box
                                        component="img"
                                        sx={{
                                          height: 350,
                                          width: "100%",
                                        }}
                                        src={fileBase64.src}
                                      />
                                    ) : null}
                                  </DialogContent>
                                  <DialogActions>
                                    <Button
                                      onClick={handleCloseImage}
                                      color="primary"
                                    >
                                      Çıxış et
                                    </Button>
                                  </DialogActions>
                                </Dialog>

                                {/* start date and end date */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid
                                    onClick={() => setIsDate(true)}
                                    sx={{ width: "100%" }}
                                  >
                                    <Field
                                      name={`documents[${index}].issuanceDate`}
                                    >
                                      {({ field, form }) => (
                                        <DatePicker
                                          {...field}
                                          label="Sənədin verilmə tarixi"
                                          inputFormat="dd/MM/yyyy"
                                          value={
                                            form?.values?.documents[index]
                                              ?.issuanceDate
                                              ? field?.value
                                              : ""
                                          }
                                          onChange={(date) => {
                                            if (date) {
                                              form.setFieldValue(
                                                `documents[${index}].issuanceDate`,
                                                date
                                              );
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={
                                                isDate &&
                                                form.errors?.documents?.[index]
                                                  ?.issuanceDate
                                              }
                                              error={
                                                isDate &&
                                                Boolean(
                                                  form.errors.documents?.[index]
                                                    ?.issuanceDate
                                                )
                                              }
                                              fullWidth
                                            />
                                          )}
                                          maxDate={today}
                                          minDate={new Date(1940, 0, 1)}
                                          disableFuture
                                          clearable
                                          clearText="Clear"
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid
                                    onClick={() => setIsDate(true)}
                                    sx={{ width: "100%" }}
                                  >
                                    <Field
                                      name={`documents[${index}].expirationDate`}
                                    >
                                      {({ field, form }) => (
                                        <DatePicker
                                          {...field}
                                          label="Sənədin qüvvədən düşmə tarixi (müddətsiz ola bilər)"
                                          inputFormat="dd/MM/yyyy"
                                          value={
                                            form?.values?.documents[index]
                                              ?.expirationDate
                                              ? field?.value
                                              : ""
                                          }
                                          onChange={(date) => {
                                            if (date) {
                                              form.setFieldValue(
                                                `documents[${index}].expirationDate`,
                                                date
                                              );
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={
                                                isDate &&
                                                form.errors?.documents?.[index]
                                                  ?.expirationDate
                                              }
                                              error={
                                                isDate &&
                                                Boolean(
                                                  form.errors.documents?.[index]
                                                    ?.expirationDate
                                                )
                                              }
                                              fullWidth
                                            />
                                          )}
                                          minDate={today}
                                          disablePast
                                          clearable
                                          clearText="Clear"
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => {
                                  remove(index);
                                  setIsDate(false);
                                }}
                              >
                                Sənədi sil
                              </Button>
                            </div>
                          ))}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() => {
                            push({
                              docType: "",
                              issuanceDate: null,
                              expirationDate: null,
                            });
                            setIsDate(false);
                          }}
                        >
                          Sənəd əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditDocuments;
