import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Field } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCommonData } from "../../../../api/api";
import NewCommonDataDialog from "../../../../components/employees/NewCommonDataDialog";
const Wage = ({ banks, setFieldValue, selectedValue, values, weekDays }) => {
  const [supplementalWageLabourItems, setSupplementalWageLabourItems] =
    useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const normal = {
    name: "Normal",
    parent: "Normal",
    vacationDays: 0,
    value: 0,
  };
  const handleInventory = async () => {
    const supplementalWageLabourItemsResponse = await getCommonData(
      "supplementalWageLabourItems"
    );
    const combinedItems = [normal, ...supplementalWageLabourItemsResponse.data];
    setSupplementalWageLabourItems(combinedItems);
  };

  useEffect(() => {
    handleInventory();
  }, [submitted]);
  const groupedItems = supplementalWageLabourItems.reduce((acc, item) => {
    let parent = item?.parent.toLocaleUpperCase();
    if (!acc[parent]) {
      acc[parent] = [];
    }
    acc[parent].push(item);
    return acc;
  }, {});
  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          // sx={{ border: "1px solid #56606F", borderRadius: "4px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Əmək haqqı üzrə</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>Əməyin ödənilməsi</InputLabel>
                <Field
                  as={Select}
                  label="Əməyin ödənilməsi"
                  defaultValue=""
                  fullWidth
                  name="wage.paymentOfWork"
                  onChange={(event) => {
                    setFieldValue("wage.paymentOfWork", event.target.value);
                  }}
                >
                  {selectedValue?.paymentOfWork &&
                    selectedValue?.paymentOfWork.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id={`wage.workingCondition`}>
                  Əmək şəraiti
                </InputLabel>
                <Select
                  label="Əmək şəraiti"
                  name="wage.workingCondition"
                  value={values?.wage?.workingCondition || ""}
                  onChange={(e) => {
                    const selectedItem = e.target.value;
                    setFieldValue("wage.workingCondition", selectedItem);
                  }}
                  renderValue={(selected) =>
                    selected?.name || "Əmək şəraiti seçin"
                  }
                  defaultValue=""
                  fullWidth
                >
                  {Object.entries(groupedItems).flatMap(
                    ([parent, items], index) => [
                      <MenuItem key={`parent-${index}`} disabled>
                        {parent}
                      </MenuItem>,
                      ...items.map((item, subIndex) => (
                        <MenuItem key={`item-${subIndex}`} value={item}>
                          {item.name}
                        </MenuItem>
                      )),
                    ]
                  )}
                  <Button
                    sx={{
                      mt: 3,
                      ml: 3,
                    }}
                    variant="outlined"
                    onClick={() => {
                      setOpenAddDialog(true);
                      setSelectedType([
                        "supplementalWageLabourItems",
                        { name: "İş şəraiti əlavə et" },
                      ]);
                    }}
                  >
                    Əlavə et
                  </Button>
                </Select>
              </FormControl>
            </Grid>

            <TextField
              type="number"
              label="Vəzifə/tarif maaşı"
              value={values?.wage?.wage}
              fullWidth
              name="wage.wage"
              onChange={(event) => {
                setFieldValue("wage.wage", event.target.value);
              }}
            />
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>Verilən mükafat və dövrü</InputLabel>
                <Field
                  as={Select}
                  label="Verilən mükafat və dövrü"
                  defaultValue=""
                  fullWidth
                  name="wage.bonusPeriod"
                  onChange={(event) => {
                    setFieldValue("wage.bonusPeriod", event.target.value);
                  }}
                >
                  {selectedValue?.bonusPeriod &&
                    selectedValue?.bonusPeriod.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
            <TextField
              type="number"
              label="Verilən mükafat (məbləğ)"
              value={values?.wage?.givenBonus}
              fullWidth
              name="wage.givenBonus"
              onChange={(event) => {
                setFieldValue("wage.givenBonus", event.target.value);
              }}
            />
            <TextField
              type="number"
              label="Natamam iş günü (saat)"
              fullWidth
              name="wage.partTimeWorkHour"
              value={values?.wage?.partTimeWorkHour || ""}
              onChange={(event) => {
                const value = event.target.value;
                if (value <= 7 && value >= 0) {
                  setFieldValue("wage.partTimeWorkHour", value);
                } else {
                  setFieldValue("wage.partTimeWorkHour", "");
                }
              }}
            />

            <TextField
              type="number"
              label="İş vaxtından artıq işlədiyinə görə"
              value={values?.wage?.overtime}
              fullWidth
              name="wage.overtime"
              onChange={(event) => {
                setFieldValue("wage.overtime", event.target.value);
              }}
            />
            {banks?.length > 0 && (
              <FormControl fullWidth>
                <InputLabel>Əmək haqqının köçürüldüyü bankın adı</InputLabel>
                <Field
                  as={Select}
                  label="Əmək haqqının köçürüldüyü bankın adı"
                  fullWidth
                  name="wage.selectedBank"
                  onChange={(event) => {
                    setFieldValue("wage.selectedBank", event.target.value);
                  }}
                  defaultValue=""
                >
                  {banks.map((bank, index) => (
                    <MenuItem key={`${bank.code}${index}`} value={index}>
                      {bank.bankName}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            )}
            <TextField
              type="text"
              label="Əmək haqqının ödənilməsi barədə digər şərtlər"
              value={values?.wage?.conditionsOfSalaryPayment}
              fullWidth
              name="wage.conditionsOfSalaryPayment"
              onChange={(event) => {
                setFieldValue(
                  "wage.conditionsOfSalaryPayment",
                  event.target.value
                );
              }}
            />
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel>Ödənilmə müddətini seçin</InputLabel>
                <Field
                  as={Select}
                  label="Ödənilmə müddətini seçin"
                  defaultValue=""
                  fullWidth
                  name="wage.paymentPeriod"
                  onChange={(event) => {
                    setFieldValue("wage.paymentPeriod", event.target.value);
                  }}
                >
                  {selectedValue?.paymentPeriod &&
                    selectedValue?.paymentPeriod.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
              {values?.wage?.paymentPeriod === 2 ? (
                <FormControl fullWidth>
                  <InputLabel>1-ci ödənişin tarixi</InputLabel>
                  <Field
                    as={Select}
                    label="1-ci ödənişin tarixi"
                    defaultValue=""
                    fullWidth
                    name="wage.paymentPeriodDate"
                    onChange={(event) => {
                      setFieldValue(
                        "wage.paymentPeriodDate",
                        event.target.value
                      );
                    }}
                  >
                    {weekDays &&
                      weekDays.map((item, i) => (
                        <MenuItem key={`${item.key}${i}`} value={item.key}>
                          {item?.name}
                        </MenuItem>
                      ))}
                  </Field>
                </FormControl>
              ) : (
                <TextField
                  type="number"
                  label="1-ci ödənişin tarixi"
                  value={values?.wage?.paymentPeriodDate}
                  fullWidth
                  name="wage.paymentPeriodDate"
                  onChange={(event) => {
                    setFieldValue("wage.paymentPeriodDate", event.target.value);
                  }}
                />
              )}
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel>Qeyri-iş günlərində işlədikdə</InputLabel>
                <Field
                  as={Select}
                  label="Qeyri-iş günlərində işlədikdə"
                  defaultValue=""
                  fullWidth
                  name="wage.freelanceWorkIncomeType"
                  onChange={(event) => {
                    setFieldValue(
                      "wage.freelanceWorkIncomeType",
                      event.target.value
                    );
                  }}
                >
                  {selectedValue?.freelanceWorkIncomeType &&
                    selectedValue?.freelanceWorkIncomeType.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
              <TextField
                type="number"
                label="Qeyri-iş günlərində işlədikdə əlavə gəlir"
                value={values?.wage?.freelanceWorkIncome}
                fullWidth
                name="wage.freelanceWorkIncome"
                onChange={(event) => {
                  setFieldValue("wage.freelanceWorkIncome", event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
      {openAddDialog && (
        <NewCommonDataDialog
          submitted={submitted}
          selectedType={selectedType}
          setSubmitted={setSubmitted}
          openAddDialog={openAddDialog}
          setOpenAddDialog={setOpenAddDialog}
        />
      )}
    </>
  );
};

export default Wage;
