import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { getCommonData, updateEmployee } from "../../api/api";
import { checkUniqueness } from "../../utils/checkUniquenessForEmployee";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomAccordion from "../ui/Accordion/Accordion";

const EditDeductionsWages = ({
  submitted,
  employeeById,
  setSubmitted,
  selectedType,
  setSelectedType,
  setOpenAddDialog,
}) => {
  const [deductionsName, setDeductionsName] = useState([]);
  const [pastData, setPastData] = useState([]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    // Handle form submission
    const modifiedValues = {
      ...values,
      deductionWages:
        values?.deductionWages &&
        values?.deductionWages.map((deduction) => {
          const { ...rest } = deduction;
          return {
            ...rest,
          };
        }),
    };
    await updateEmployee(employeeById._id, modifiedValues);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };

  useEffect(() => {
    const handleSupplements = async () => {
      try {
        const response = await getCommonData("deductionWageGeneral");
        const deductions = response?.data || [];
        setDeductionsName(deductions);
        const combinedData = [];
        if (deductions.length) {
          combinedData.push({ deductions: [...deductions] });
        }

        handlePastData(combinedData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    handleSupplements();
  }, [submitted]);

  const handleWageSelect = (item) => {
    const selectWage = deductionsName.find((wage) => {
      return wage?.name === item;
    });
    return selectWage;
  };
  function handlePastData(data) {
    setPastData(data);
  }
  function handlePush(index) {
    deductionsName &&
      deductionsName.push(employeeById?.deductionsWage[index]?.name);
  }
  function handleHidden(name, data) {
    return pastData.every((past) => !past[name]?.includes(data));
  }

  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Əməkhaqqından tutulmalar</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              deductionWages: employeeById.deductionWages || [],
            }}
            onSubmit={handleSubmit}
            // validationSchema={supplementalWagesValidation}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="deductionWages">
                    {({ push, remove }) => (
                      <div>
                        {values?.deductionWages &&
                          values?.deductionWages.map((form, index) => (
                            <div key={index}>
                              <>
                                {/* {handlePush(index)} */}
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1})Əməkhaqqından tutulmalar
                                </Typography>

                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.deductionWages?.[index]
                                          ?.name &&
                                        errors?.deductionWages?.[index]?.name &&
                                        Boolean(
                                          errors?.deductionWages?.[index]?.name
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`deductionWages[${index}].name`}
                                      >
                                        Əməkhaqqından tutulmaların səbəbi *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`deductionWages[${index}].name`}
                                        labelId={`deductionWages[${index}].name`}
                                        label="Əməkhaqqından tutulmaların səbəbi *"
                                        id={`deductionWages[${index}].name`}
                                        onChange={(event) => {
                                          checkUniqueness(
                                            values.deductionWages,
                                            "name",
                                            event,
                                            setFieldValue,
                                            `deductionWages[${index}]`,
                                            "əməkhaqqına tutulma səbəbi",
                                            handleWageSelect
                                          );
                                        }}
                                        error={
                                          touched?.deductionWages?.[index]
                                            ?.name &&
                                          errors?.deductionWages?.[index]
                                            ?.name &&
                                          Boolean(
                                            errors?.deductionWages?.[index]
                                              ?.name
                                          )
                                        }
                                        fullWidth
                                      >
                                        {deductionsName &&
                                          [...new Set(deductionsName)]
                                            .sort((a, b) =>
                                              a.localeCompare(b, "az")
                                            )
                                            .map((item, index) => (
                                              <MenuItem
                                                hidden={handleHidden(
                                                  "deductions",
                                                  item
                                                )}
                                                key={index}
                                                value={item}
                                              >
                                                {item}
                                              </MenuItem>
                                            ))}
                                        <Button
                                          sx={{
                                            mt: 3,
                                            ml: 3,
                                          }}
                                          variant="outlined"
                                          onClick={() => {
                                            setOpenAddDialog(true);
                                            setSelectedType([
                                              "deductionWageGeneral",
                                              { name: "Ümumi tutulmalar" },
                                            ]);
                                          }}
                                        >
                                          Yeni əməkhaqqına tutulma səbəbi əlavə
                                          et
                                        </Button>
                                      </Field>
                                    </FormControl>
                                    {touched?.deductionWages?.[index]?.name &&
                                      errors?.deductionWages?.[index]?.name &&
                                      errors?.deductionWages?.[index]?.name && (
                                        <FormHelperText error>
                                          {
                                            errors?.deductionWages?.[index]
                                              ?.name
                                          }
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="number"
                                      onChange={(e) => {
                                        setFieldValue(
                                          `deductionWages[${index}].value`,
                                          e.target.value
                                        );
                                      }}
                                      value={values.deductionWages[index].value}
                                      as={TextField}
                                      name={`deductionWages[${index}].value`}
                                      label="Əməkhaqqından tutulmaların dəyəri"
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => remove(index)}
                              >
                                Əməkhaqqına tutulmanı sil
                              </Button>
                            </div>
                          ))}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() => push({ name: "", value: "" })}
                        >
                          Əməkhaqqına tutulmanı əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>

                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditDeductionsWages;
