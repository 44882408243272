/* eslint-disable prettier/prettier */
import { Edit, RestartAlt, CompareArrows } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as companyStructureServices from "../../../services/structure/StructureServices";

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../../components/layouts/header/Header";
import OperatorGenerator from "../../../components/table/OperatorGenerator";
import Table from "../../../components/table/Table";
import SelectInput from "../../../components/ui/Select/SelectInput";
import {
  COLORS_STATUS,
  GRAPHIC_TYPES,
  OPERATORS_CONTAINING,
  bgColorHandler,
  titleHandler,
} from "../../../constants";
import Column from "../../../models/table/Column";
import Operator from "../../../models/table/Operator";
import { GENERAL_BLOCK } from "../../../styled";
import {
  getAllStructuresForFilter,
  getPartsForFilter,
  getTopPartsForFilter,
} from "../../../utils/forFilterDropdown/getStructureUtils";
import {
  changeStartOrderGraphic,
  getWorkTimeGraphics,
  resetAllWorkTimeGraphics,
  resetWorkTimeGraphics,
  updateWorkTimeGraphic,
} from "../../../services/labour-organization/work-time-graphics";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ResetGraphicDialog from "./ResetWorkingHours";

const title = "İş vaxtının qrafiki";

const HeaderContainer = styled.div`
  margin-bottom: 10px;
`;

const SelectDateContainer = styled.div`
  display: flex;
  justify-content: end;
  position: sticky;
  top: 64px;
  background-color: #1b2635;
  padding: 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  // width: max-content;
  z-index: 999;
`;

const months = [
  {
    name: "Yanvar",
    key: 0,
  },
  {
    name: "Fevral",
    key: 1,
  },
  {
    name: "Mart",
    key: 2,
  },
  {
    name: "Aprel",
    key: 3,
  },
  {
    name: "May",
    key: 4,
  },
  {
    name: "İyun",
    key: 5,
  },
  {
    name: "İyul",
    key: 6,
  },
  {
    name: "Avqust",
    key: 7,
  },
  {
    name: "Sentyabr",
    key: 8,
  },
  {
    name: "Oktyabr",
    key: 9,
  },
  {
    name: "Noyabr",
    key: 10,
  },
  {
    name: "Dekabr",
    key: 11,
  },
];

export default function WorkingHoursGraphic() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [showReset, setShowReset] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const years = Array.from({ length: 27 }, (_, index) => 2024 + index);
  const [openDialog, set_open_dialog] = useState(false);
  const [columns, set_columns] = useState([]);
  const [edited_hours, set_edited_hours] = useState({
    jobStartHour: null,
    jobEndHour: null,
    lunchEndHour: null,
    lunchStartHour: null,
    offDayType: null,
  });
  const [currYear, setCurrYear] = useState(new Date().getFullYear());
  const [currMonth, setCurrMonth] = useState(new Date().getMonth());
  const [resetDialog, setResetDialog] = useState(false);
  const [resetId, setResetId] = useState("");
  const [_days, setDays] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [graphic_order, set_graphic_order] = useState(0);
  const [editSaveBtn, seteditSaveBtn] = useState(false);
  const [selectedRow, set_selectedRow] = useState({
    workHourMode: null,
    workDayMode: null,
    scheduleType: null,
    graphic_id: null,
    day: null,
    schedule_for_order: null,
  });
  const [structureDatas, setStructureDatas] = useState({
    total: 0,
    structures: [],
  });
  const headerData = {
    title: title,
    variant: "h3",
  };

  useEffect(() => {
    const currMonth = new Date().getMonth();
    const currYear = new Date().getFullYear();
    setCurrMonth(currMonth);
    setCurrYear(currYear);
    fetchCompanyStructures();
  }, [offset, limit]);

  useEffect(() => {
    const firstDayOfMonth = new Date(currYear, currMonth, 1);
    const lastDayOfMonth = new Date(currYear, currMonth + 1, 0);
    const days = [];
    // Render days of current month
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      days.push({
        day: i,
        isCurrentMonth: true,
      });
    }
    setDays(days);
  }, [currMonth, currYear]);

  useEffect(() => {
    let validation;
    if (selectedRow?.scheduleType === "standard") {
      validation =
        !edited_hours?.offDayType &&
        (!edited_hours?.jobStartHour ||
          !edited_hours?.jobEndHour ||
          !edited_hours?.lunchStartHour ||
          !edited_hours?.lunchEndHour);
    } else {
      validation =
        !edited_hours?.offDayType &&
        (!edited_hours?.jobStartHour || !edited_hours?.jobEndHour);
    }
    seteditSaveBtn(validation);
  }, [edited_hours]);

  useEffect(() => {
    const static_columns = [
      new Column({
        headerName: "Operator",
        path: "#",
        isStaticColumn: false,
        disableColSearch: false,
        renderCell: (row, column) => {
          const operators = [
            new Operator({
              icon: (
                <RestartAlt
                  style={{
                    opacity: showReset ? 0.5 : 1,
                    pointerEvents: showReset ? "none" : "auto",
                  }}
                />
              ),
              onClick: () => {
                setResetId(row?._id);
                setResetDialog(true);
              },
              tooltip: "Bu sıranın qrafikini yenilə",
            }),
            // row?.schedule?.scheduleType !== "standard" &&
            //   new Operator({
            //     icon: <CompareArrows />,
            //     onClick: () => {
            //       set_open_dialog(true);
            //       set_selectedRow({
            //         ...selectedRow,
            //         schedule_for_order: row?.schedule?.schedules,
            //         workDayMode: row?.structure?.workDayMode,
            //         workHourMode: row?.structure?.workHourMode,
            //         graphic_id: row?._id,
            //       });
            //     },
            //     tooltip: "Qrafiklərin yerini dəyiş",
            //   }),
          ];
          return <OperatorGenerator operators={operators} />;
        },
      }),
      new Column({
        path: "staff?.employee[0]?.tableNumber",
        headerName: "Tabel No",
        filterType: OPERATORS_CONTAINING.OPTION,
        fetchFilterValues: getAllStructuresForFilter,
        getValue: (row) => <div>{row?.staff?.employee[0]?.tableNumber}</div>,
      }),
      new Column({
        // path: "staff?.employee[0]",
        headerName: "Əməkdaş",
        filterType: OPERATORS_CONTAINING.OPTION,
        fetchFilterValues: getTopPartsForFilter,
        filterColumnKey: "staff",
        getValue: (row) => (
          <div>
            {row?.staff?.employee[0]?.firstname}{" "}
            {row?.staff?.employee[0]?.lastname}{" "}
            {row?.staff?.employee[0]?.fathername}
          </div>
        ),
      }),
      new Column({
        path: "structure.organization.name",
        headerName: "Təşkilat",
        filterType: OPERATORS_CONTAINING.OPTION,
        fetchFilterValues: getAllStructuresForFilter,
        filterColumnKey: "structure",
      }),
      new Column({
        path: "parentPart?.source?.name",
        headerName: "Yuxarı Struktur",
        isShow: true,
        filterType: OPERATORS_CONTAINING.OPTION,
        fetchFilterValues: getPartsForFilter,
        getValue: (row) => <div>{row?.parentPart?.source?.name}</div>,
      }),
      new Column({
        path: "staff?.position?.name",
        headerName: "Vəzifə",
        isShow: true,
        filterType: OPERATORS_CONTAINING.OPTION,
        fetchFilterValues: getPartsForFilter,
        getValue: (row) => <div>{row?.staff?.position?.name}</div>,
      }),
      new Column({
        path: "schedule?.scheduleType",
        headerName: "Qrafik tipi",
        isShow: true,
        filterType: OPERATORS_CONTAINING.OPTION,
        fetchFilterValues: getPartsForFilter,
        getValue: (row) => (
          <div>{GRAPHIC_TYPES[row?.schedule?.scheduleType]}</div>
        ),
      }),
      new Column({
        path: "monthDetails.totalWorkHours",
        headerName: "Norma saatlar",
        filterType: OPERATORS_CONTAINING.OPTION,
        fetchFilterValues: getAllStructuresForFilter,
        getValue: (row) => <div>{row?.monthDetails?.totalWorkHours?.toFixed(2)}</div>
      }),
      new Column({
        path: "monthDetails.workHours",
        headerName: "Faktiki saatlar",
        filterType: OPERATORS_CONTAINING.OPTION,
        fetchFilterValues: getAllStructuresForFilter,
        getValue: (row) => (
          <div>{row?.monthDetails?.workHours?.toFixed(2)}</div>
        ),
      }),
    ];

    let days = Array.from({ length: 31 }, (_, index) => {
      const dayNumber = index + 1;
      return new Column({
        path: `Day ${dayNumber}`,
        headerName: `${dayNumber}`,
        disableColSearch: true,
        widthOfColumn: 150,
        renderCell: (row) => {
          return (
            <div
              className="position-relative"
              style={{
                borderRadius: "5px",
                marginTop: "5px",
                width: "100%",
                textAlign: "center",
                border: "1px solid white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px",
                height: "25px",
              }}
            ></div>
          );
        },
      });
    });
    
    data.forEach((item) => {
      if (item?.graphics?.length) {
        for (let i = 0; i < days.length; i++) {
          const columnDay = parseInt(days[i].headerName);
          days[i] = new Column({
            ...days[i],
            renderCell: (row) => {
              const firstTableItem = row?.graphics?.[0];
              const startDay = firstTableItem?.day;

              if (!startDay || columnDay < startDay) {
                return (
                  <div
                    className="position-relative"
                    style={{
                      borderRadius: "5px",
                      marginTop: "5px",
                      width: "100%",
                      textAlign: "center",
                      border: "1px solid white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px",
                      height: "25px",
                    }}
                  >
                    X
                  </div>
                );
              }

              const dayData = row?.graphics?.find(
                (graphic) => graphic?.day === columnDay
              );

              if (!dayData) {
                return (
                  <div
                    className="position-relative"
                    style={{
                      borderRadius: "5px",
                      marginTop: "5px",
                      width: "100%",
                      textAlign: "center",
                      border: "1px solid white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px",
                      height: "25px",
                    }}
                  >
                    X
                  </div>
                );
              }

              return (
                <div style={{ border: "1px solid transparent" }}>
                  {!dayData?.offDayType ? (
                    <div className="position-relative text-center">
                      {row?.edit_show && (
                        <div
                          className="position-absolute"
                          style={{
                            right: "-15px",
                            top: "-10px",
                            cursor: "pointer",
                          }}
                          onClick={() => renderEditDialog(dayData, row)}
                        >
                          {
                            !dayData?.isLocked &&
                            <Edit style={{ width: "20px", height: "20px" }} />
                          }
                        </div>
                      )}
                      <GENERAL_BLOCK
                        bg_color={COLORS_STATUS.GREEN}
                        brad="5px"
                        className="mb-2"
                      >
                        {dayData?.jobStartHour}-{dayData?.jobEndHour}
                      </GENERAL_BLOCK>
                      <GENERAL_BLOCK
                        bg_color={COLORS_STATUS.RED}
                        brad="5px"
                        className="mb-2"
                      >
                        {dayData?.lunchStartHour}-{dayData?.lunchEndHour}
                      </GENERAL_BLOCK>
                    </div>
                  ) : (
                    <div
                      className="position-relative"
                      style={{
                        borderRadius: "5px",
                        marginTop: "5px",
                        backgroundColor: bgColorHandler(dayData?.offDayType),
                        width: "100%",
                        textAlign: "center",
                        border: "1px solid white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "5px",
                        height: "25px",
                      }}
                    >
                      {row?.edit_show && (
                        <div
                          className="position-absolute"
                          style={{
                            right: "-15px",
                            top: "-30px",
                            cursor: "pointer",
                          }}
                          onClick={() => renderEditDialog(dayData, row)}
                        >
                          {
                            !dayData?.isLocked &&
                            <Edit style={{ width: "20px", height: "20px" }} />
                          }
                        </div>
                      )}
                      <span>{titleHandler(dayData?.offDayType)}</span>
                    </div>
                  )}
                </div>
              );
            },
          });
        }
      } 
    })

    static_columns?.push(...days);
    set_columns([...static_columns]);
  }, [data]);

  useEffect(() => {
    if (openDialog && selectedRow?.day) {
      const jobStartTime = convertTimeToToday(selectedRow?.day?.jobStartHour);
      const jobEndTime = convertTimeToToday(selectedRow?.day?.jobEndHour);
      const lunchStartTime = convertTimeToToday(
        selectedRow?.day?.lunchStartHour
      );
      const lunchEndTime = convertTimeToToday(selectedRow?.day?.lunchEndHour);

      set_edited_hours({
        jobStartHour: jobStartTime,
        jobEndHour: jobEndTime,
        lunchStartHour: lunchStartTime,
        lunchEndHour: lunchEndTime,
        index: selectedRow?.day?.index,
        offDayType: selectedRow?.day?.offDayType,
        jobStartHour_ref: jobStartTime,
        jobEndHour_ref: jobEndTime,
        lunchStartHour_ref: lunchStartTime,
        lunchEndHour_ref: lunchEndTime,
        offDayType_ref: selectedRow?.day?.offDayType,
      });
    }
  }, [selectedRow, openDialog]);

  async function fetchCompanyStructures() {
    try {
      const data = await companyStructureServices.getCompanyStructures({
        limit: 100,
        skip: 0,
      });
      if (data && data.structures && data.structures.length > 0) {
        setStructureDatas(data);
        setFilters({
          ...filters,
          structure: data?.structures[0]?.organization?.name,
        });
        // setCurrStructure(data?.structures[0]?.organization?.name);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  function convertTimeToToday(timeString) {
    const now = new Date();
    if (timeString) {
      const [hours, minutes] = timeString?.split(":");
      now.setHours(hours);
      now.setMinutes(minutes);
      return now;
    }
  }

  function handleScheduleEdit(value, field) {
    set_edited_hours((prev) => ({ ...prev, [field]: value?.$d }));
  }

  async function handleScheduleOrder() {
    try {
      let response = await changeStartOrderGraphic(
        selectedRow.graphic_id,
        graphic_order
      );
      if (response) {
        enqueueSnackbar("Qrafikin sıralaması uğurla dəyişdirildi!", {
          variant: "success",
        });
        fetchData();
        handleCloseEditDialog();
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi!", { variant: "error" });
    }
  }

  async function saveEditSchedule() {
    let hours = edited_hours?.offDayType
      ? { offDayType: edited_hours?.offDayType }
      : selectedRow?.scheduleType !== "standard"
        ? {
          jobStartHour: moment(edited_hours?.jobStartHour).format("HH:mm"),
          jobEndHour: moment(edited_hours?.jobEndHour).format("HH:mm"),
        }
        : {
          jobStartHour: moment(edited_hours?.jobStartHour).format("HH:mm"),
          jobEndHour: moment(edited_hours?.jobEndHour).format("HH:mm"),
          lunchStartHour: moment(edited_hours?.lunchStartHour).format("HH:mm"),
          lunchEndHour: moment(edited_hours?.lunchEndHour).format("HH:mm"),
        };
    let payload = {
      graphicId: selectedRow.graphic_id,
      updates: {
        [edited_hours?.index]: hours,
      },
    };
    try {
      let response = await updateWorkTimeGraphic(payload);
      if (response) {
        enqueueSnackbar("Gün uğurla yeniləndi!", { variant: "success" });
        fetchData();
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi!", { variant: "error" });
    }
    set_open_dialog(false);
  }

  function setEditMode() {
    let data_ = [...data];
    if (!data_[data_?.length - 1]?.hasOwnProperty("edit_show")) {
      data_ = data_.map((d) => ({ ...d, edit_show: true }));
    } else {
      data_ = data_.map((d) => {
        if (d?.hasOwnProperty("edit_show")) {
          delete d?.edit_show;
        }
        return d;
      });
    }
    setData(data_);
  }

  const fetchData = async (filter = {}) => {
    let filters = encodeURIComponent(JSON.stringify(filter));
    try {
      setIsLoading(true);
      const payload = {
        offset: offset,
        limit: limit,
        year: currYear,
        month: currMonth,
        filters,
      };
      const response = await getWorkTimeGraphics(payload);
      if (response && response?.data) {
        setData(response?.data[0]?.data);
        setTotal(response?.data[0]?.metadata?.total);
        setIsLoading(false);
      }
    } catch (e) {
      if (
        e &&
        e?.response?.data?.errors?.[0]?.message.includes(
          "Prod calendar not found"
        )
      ) {
        let message = e?.response?.data?.errors?.[0]?.message;
        let searchWord = "for";
        let result = message
          .substring(message.indexOf(searchWord) + searchWord.length)
          .trim();
        enqueueSnackbar(
          `${result} strukturu üçün istehsalat təqvimi mövcud deyil`,
          { variant: "error" }
        );
        setIsLoading(false);
      }
    }
  };

  const resetRow = async (id) => {
    try {
      let resp = await resetWorkTimeGraphics(id);
      if (resp) {
        fetchData();
        enqueueSnackbar("Uğurla yeniləndi!", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi!", { variant: "error" });
    }
  };

  const resetAll = async (id) => {
    setShowReset(true);
    let filters_ = encodeURIComponent(
      JSON.stringify({
        structure: structureDatas?.structures?.find(
          (s) => s?.organization?.name === filters.structure
        )?._id,
      })
    );
    try {
      let resp = await resetAllWorkTimeGraphics(filters_, currYear, currMonth);
      if (resp) {
        fetchData();
        enqueueSnackbar(
          "Cari struktur üzrə bütün qrafiklər uğurla ilkin yeniləndi!",
          { variant: "success" }
        );
      }
    } catch (error) {
      enqueueSnackbar("Xəta baş verdi!", { variant: "error" });
    }
    setShowReset(false);
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  const handleYearChange = (value) => {
    setCurrYear(value);
  };

  const handleMonthChange = (value) => {
    const selectedMonth = months.find((month) => month.name === value);
    if (selectedMonth) {
      setCurrMonth(selectedMonth.key);
    }
  };

  function renderEditDialog(day, row) {
    set_open_dialog(true);
    set_selectedRow({
      ...selectedRow,
      scheduleType: row?.schedule?.scheduleType,
      name: `${row?.staff?.employee[0]?.firstname}
      ${row?.staff?.employee[0]?.lastname} ${row?.staff?.employee[0]?.fathername}`,
      graphic_id: row?._id,
      day,
      workDayMode: row?.structure?.workDayMode,
      workHourMode: row?.structure?.workHourMode,
    });
  }

  function handleCloseEditDialog() {
    set_open_dialog(false);
    set_selectedRow({});
    set_graphic_order(0);
  }

  const handleLabel = (label) => {
    let hours = {};
    if (label !== null) {
      hours = {
        jobStartHour: null,
        jobEndHour: null,
        lunchEndHour: null,
        lunchStartHour: null,
        offDayType: label,
      };
    } else {
      hours = {
        offDayType: label,
        jobStartHour: edited_hours?.jobStartHour_ref,
        jobEndHour: edited_hours?.jobEndHour_ref,
        lunchEndHour: edited_hours?.lunchEndHour_ref,
        lunchStartHour: edited_hours?.lunchStartHour_ref,
      };
    }
    set_edited_hours((prev) => ({ ...prev, ...hours }));
  };

  const handleFilterRender = (label) => {
    setShowFilter(!showFilter);
    if (Object.keys(filters).length) {
      setFilters({});
    }
  };

  useEffect(() => {
    if (Object.keys(filters)?.length);
    let structure = structureDatas?.structures?.find(
      (s) => s?.organization?.name === filters.structure
    )?._id;

    let filt = { ...filters };
    filt = {
      ...filt,
      structure,
    };
    fetchData(filt);
  }, [filters, currYear, currMonth]);

  return (
    <div>
      <style>{`.hour-edit .css-2y9tha-MuiPaper-root-MuiDialog-paper {width: 40%; height: 40%}`}</style>
      <HeaderContainer>
        <Header data={headerData} />
      </HeaderContainer>
      {/* <Calendar /> */}
      <SelectDateContainer>
        <GENERAL_BLOCK className="text-center" f_size="23px" wid="80%">
          {months[currMonth].name} {currYear}
        </GENERAL_BLOCK>
        <div className="d-flex" style={{ width: "40%" }}>
          <SelectInput
            label={"Struktur: "}
            value={filters.structure || null}
            onChange={(value) => setFilters({ ...filters, structure: value })}
          >
            {structureDatas?.structures.map((structure) => (
              <option
                key={structure?.organization?.name}
                value={structure?.organization?.name}
              >
                {structure?.organization?.name}
              </option>
            ))}
          </SelectInput>
          <SelectInput
            styleOuterContainer={{ width: "130px" }}
            label={"İl: "}
            value={currYear}
            onChange={handleYearChange}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </SelectInput>

          <SelectInput
            styleOuterContainer={{ width: "130px" }}
            label={`Ay: `}
            value={months[currMonth].name}
            data={months}
            itemName={"name"}
            itemValue={"name"}
            onChange={handleMonthChange}
          >
            <option value={months[currMonth].key}>
              {months[currMonth].name}
            </option>
          </SelectInput>
        </div>
      </SelectDateContainer>
      {showFilter && (
        <GENERAL_BLOCK className="d-flex mb-4 mt-3 aic">
          <GENERAL_BLOCK wid="15%" className="ms-2">
            <InputLabel>Struktur/bölmə</InputLabel>
            <TextField
              className="mt-1"
              size="small"
              value={filters?.parentPart || ""}
              onChange={(e) =>
                setFilters({ ...filters, parentPart: e.target.value })
              }
              fullWidth
            />
          </GENERAL_BLOCK>
          <GENERAL_BLOCK wid="15%" className="ms-2">
            <InputLabel>Əməkdaş</InputLabel>
            <TextField
              className="mt-1"
              size="small"
              value={filters?.employee || ""}
              onChange={(e) =>
                setFilters({ ...filters, employee: e.target.value })
              }
              fullWidth
            />
          </GENERAL_BLOCK>
          <GENERAL_BLOCK wid="15%" className="ms-2">
            <InputLabel>Vəzifə</InputLabel>
            <TextField
              className="mt-1"
              size="small"
              value={filters?.position || ""}
              onChange={(e) =>
                setFilters({ ...filters, position: e.target.value })
              }
              fullWidth
            />
          </GENERAL_BLOCK>
        </GENERAL_BLOCK>
      )}
      {columns?.length ? (
        <Table
          columns={columns}
          data={data}
          totalRows={total}
          offset={offset}
          setOffset={(value) => setOffset(value)}
          limit={limit}
          setLimit={(value) => setLimit(value)}
          isLoading={isLoading}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
          editMode={setEditMode}
          setShowFilter={handleFilterRender}
          resetAll={resetAll}
          hideColumnVisibility={true}
          hideFilter={true}
          hideDownload={true}
          showReset={showReset}
        />
      ) : (
        <div style={{ textAlign: "center", padding: "30px" }}>
          Məlumat yoxdur
        </div>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseEditDialog}
        aria-labelledby="form-dialog-title"
        className="hour-edit"
      >
        <DialogContent>
          <GENERAL_BLOCK f_size="20px" className="text-center mb-3">
            {selectedRow?.schedule_for_order?.length
              ? "Qrafiklərin sırasını dəyişin"
              : "Qrafikləri redaktə et"}
          </GENERAL_BLOCK>
          <GENERAL_BLOCK className="mb-3">
            Günlük iş rejimi: {selectedRow?.workDayMode} saat
            <br />
            Həftəlik norma saat: {selectedRow?.workHourMode} saat
          </GENERAL_BLOCK>
          <DialogContentText id="alert-dialog-description">
            {selectedRow?.schedule_for_order?.length ? (
              <GENERAL_BLOCK>
                <p className="mb-1">Qrafiklər</p>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={graphic_order}
                    onChange={(e) => set_graphic_order(e.target.value)}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {selectedRow?.schedule_for_order?.map((h, i) => (
                      <MenuItem value={i}>
                        Növbə {i + 1} -{" "}
                        {h?.jobStartTime?.hour?.toString().padStart(2, "0")}:
                        {h?.jobStartTime?.minute?.toString().padStart(2, "0")} -{" "}
                        {h?.jobEndTime?.hour?.toString().padStart(2, "0")}:
                        {h?.jobEndTime?.minute?.toString().padStart(2, "0")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GENERAL_BLOCK>
            ) : (
              <GENERAL_BLOCK
                d_play="grid"
                gtc="1fr 1fr"
                gap="10px"
                className="mb-2"
              >
                <div>
                  <p className="mb-1">İşin başlama saatı</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      disabled={edited_hours?.offDayType}
                      value={edited_hours?.jobStartHour}
                      ampm={false}
                      maxTime={dayjs(edited_hours?.jobEndHour).subtract(
                        2,
                        "hour"
                      )}
                      onChange={(newValue) =>
                        handleScheduleEdit(newValue, "jobStartHour")
                      }
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <p className="mb-1">İş bitmə saatı</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      disabled={edited_hours?.offDayType}
                      value={edited_hours?.jobEndHour}
                      ampm={false}
                      minTime={dayjs(edited_hours?.jobStartHour).subtract(
                        2,
                        "hour"
                      )}
                      onChange={(newValue) =>
                        handleScheduleEdit(newValue, "jobEndHour")
                      }
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                {selectedRow?.scheduleType === "standard" && (
                  <>
                    <div>
                      <p className="mb-1 mt-2">Naharın başlama saatı</p>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                          disabled={edited_hours?.offDayType}
                          value={edited_hours?.lunchStartHour}
                          ampm={false}
                          minTime={dayjs(edited_hours?.jobStartHour)}
                          maxTime={dayjs(edited_hours?.lunchEndHour)}
                          onError={(e, g, f) => console.log(g)}
                          onChange={(newValue) =>
                            handleScheduleEdit(newValue, "lunchStartHour")
                          }
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div>
                      <p className="mb-1 mt-2">Naharın bitmə saatı</p>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                          disabled={edited_hours?.offDayType}
                          value={edited_hours?.lunchEndHour}
                          ampm={false}
                          minTime={dayjs(edited_hours?.lunchStartHour)}
                          maxTime={dayjs(edited_hours?.jobEndHour)}
                          onChange={(newValue) =>
                            handleScheduleEdit(newValue, "lunchEndHour")
                          }
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </>
                )}
                <div className="d-flex">
                  <FormGroup>
                    <FormControlLabel
                      name="label"
                      control={
                        <Checkbox
                          checked={edited_hours?.offDayType === "nonWorkingDay"}
                          onChange={() =>
                            handleLabel(
                              edited_hours?.offDayType === "nonWorkingDay"
                                ? null
                                : "nonWorkingDay"
                            )
                          }
                        />
                      }
                      label="Qeyri-iş günü"
                    />
                  </FormGroup>
                  {/* <FormGroup>
                      <FormControlLabel name="label" control={<Checkbox
                        checked={edited_hours?.offDayType === "mourningDay"}
                        onChange={() => handleLabel(edited_hours?.offDayType === "mourningDay" ? "mourningDay" : null)} />}
                        label="Hüzn günü" />
                    </FormGroup> */}
                </div>
              </GENERAL_BLOCK>
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Ləğv et
          </Button>
          {selectedRow?.schedule_for_order?.length ? (
            <Button onClick={handleScheduleOrder} color="primary">
              Yadda saxla
            </Button>
          ) : (
            <Button
              onClick={saveEditSchedule}
              disabled={editSaveBtn}
              color="primary"
            >
              Yadda saxla
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ResetGraphicDialog
        id={resetId}
        reset={resetRow}
        resetDialog={resetDialog}
        title={"Bu sıranın qrafikini"}
        setResetDialog={setResetDialog}
      />
    </div>
  );
}
