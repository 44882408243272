// OperatorGenerator.js
import React, { useState } from "react";
import styled from "styled-components";
import ButtonCustom from "../ui/Button/ButtonCustom";
import ButtonDropdown from "../ui/ButtonDropdown/ButtonDropdown";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
  gap: 8px;
  justify-content: center;
  &:hover {
    overflow: visible;
  }
`;

export default function OperatorGenerator({ operators }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorId, setAnchorId] = useState("");

  const handleClosePopover = () => {
    setAnchorEl(null);
    setAnchorId("");
  };
  const handleOptionClick = (option) => {
    // Add your logic here for handling the selected option
    handleClosePopover();
  };
  const buttonTypeRender = (button, index) => {
    if (button.hideCondition) return;

    if (button.type === 0) {
      return (
        <div
          key={`${button} ${index}`}
          style={{ marginRight: "1px", position: "relative", width: "33%" }}
        >
          <ButtonCustom
            onClick={button.onClick}
            children={button.icon && button.icon}
            title={button.title && button.title}
            tooltip={button?.tooltip}
            style={
              button.styleBtn
                ? button.styleBtn
                : {
                    height: "100%",
                    background: "none",
                    borderRadius: "6px",
                    padding: "0 8px",
                  }
            }
            width="100%"
            styleContainer={button.styleBtnContainer}
          />
        </div>
      );
    } else if (button.type === 1) {
      return (
        <div
          key={`${button} ${index}`}
          style={{ marginRight: "1px", position: "relative" }}
        >
          <ButtonDropdown
            dropdownComponent={
              <Grid sx={{ flexDirection: "column", display: "flex" }}>
                <Button
                  width={"100%"}
                  onClick={() => handleOptionClick("Option 1")}
                >
                  <Typography color={"white"}>Xitam verilmiş ƏM</Typography>
                </Button>
                <Button
                  width={"100%"}
                  onClick={() => handleOptionClick("Option 2")}
                >
                  <Typography color={"white"}>Xitam əmri</Typography>
                </Button>
              </Grid>
            }
            styleContainer={{ height: "auto", width: "30px" }}
            title={<Close />}
            styleButton={{ background: "none" }}
            // styleBtnContainer={{ width: "33px" }}
            right={0}
          />
        </div>
      );
    }
  };

  return (
    <Container>
      {operators.map((button, index) => buttonTypeRender(button, index))}
    </Container>
  );
}
