import React from "react";
import styled from "@emotion/styled";

import { Button as MuiButton, Menu, MenuItem } from "@mui/material";
import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Button = styled(MuiButton)(spacing);

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

function Actions() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const today = new Date();
  const month = new Date().getMonth();
  const day = today.getDate();

  const translateMonthName = (month) => {
    switch (month) {
      case 0:
        return "Yanvar";
      case 1:
        return "Fevral";
      case 2:
        return "Mart";
      case 3:
        return "Aprel";
      case 4:
        return "May";
      case 5:
        return "İyun";
      case 6:
        return "İyul";
      case 7:
        return "Avqust";
      case 8:
        return "Sentyabr";
      case 9:
        return "Oktyabr";
      case 10:
        return "Noyabr";
      case 11:
        return "Dekabr";
      default:
        return "";
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        {/* <SmallButton size="small" mr={2}>
          <LoopIcon />
        </SmallButton>
        <SmallButton size="small" mr={2}>
          <FilterListIcon />
        </SmallButton> */}
        <Button
          variant="contained"
          color="secondary"
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          Tarix: {day} {translateMonthName(month)}
        </Button>
        {/* <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Today</MenuItem>
          <MenuItem onClick={handleClose}>Yesterday</MenuItem>
          <MenuItem onClick={handleClose}>Last 7 days</MenuItem>
          <MenuItem onClick={handleClose}>Last 30 days</MenuItem>
          <MenuItem onClick={handleClose}>This month</MenuItem>
          <MenuItem onClick={handleClose}>Last month</MenuItem>
        </Menu> */}
      </div>
    </React.Fragment>
  );
}

export default Actions;
