import { Tooltip } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 100%;
  cursor: ${({ $isDisabled }) => !$isDisabled && "pointer"};
  width: ${({ $width }) => ($width ? $width : "")};
  overflow: hidden;
  border-radius: 5px;
  position: relative;

  &:hover a {
    border: ${({ $isDisabled }) => !$isDisabled && "1px solid #fff"};
  }
`;
const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  padding: 0px 15px;
  height: 100%;
  width: 100%;
  background: ${({ $bgColor, $isDisabled }) =>
    $isDisabled ? "grey" : $bgColor ? $bgColor : "#2c5595"};
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(250, 52, 52, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1;
  color: ${({ $color }) => ($color ? $color : "#fff")};
  border-radius: 5px;
  font-weight: 400;
  text-decoration: none;
  transition: 0.3s;
  overflow: hidden;
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : "13px")};
  text-align: center;
  position: relative;
  ${({ $isDisabled }) => $isDisabled && "pointer-event: none;"}
  cursor: ${({ $isDisabled }) => ($isDisabled ? "default" : "pointer")};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.149),
      transparent
    );
    transform: skewX(45deg) translateX(-150%);
    transition: 0.5s;
    transition-delay: 0.5s;
  }
  &:hover::before {
    transform: skewX(45deg) translateX(200%);
    transition-delay: 0s;
  }
`;

export default function ButtonCustom({
  children,
  buttonRef,
  onClick,
  title,
  width,
  fontSize,
  style,
  styleContainer,
  backgroundColor,
  color,
  disabled,
  tooltip,
  ...props
}) {
  return (
    <Tooltip title={tooltip ?? ""}>
      <Container
        $isDisabled={disabled}
        style={styleContainer}
        onClick={!disabled ? onClick : () => {}}
        $width={width}
      >
        <Button
          $color={color}
          $bgColor={backgroundColor}
          style={style}
          $fontSize={fontSize}
          ref={buttonRef}
          $isDisabled={disabled}
          {...props}
        >
          {children ? children : title}
        </Button>
      </Container>
    </Tooltip>
  );
}
