import React, { useEffect, useState } from "react";
import DocumentDialog from "./OldDocumentDialog";
import { getEmployeeRequestManager } from "../../../services/vacation/VacationRequest";
import { contractGeneratorHandler, requestGeneratorHandler } from "./handlers";
import { useSelector } from "react-redux";

/*
    idList = [
        {
            id: string,
            handler: function for get(fetch) data
            type: "contract" | "request",
            isGroup: true // default value is true
            label: "Məzuniyyət sorğusu" // for navigation tab name
        } 
    ]
*/

export default function ContReqDialog({
  open,
  onClose,
  idList = [],
  idListProps = {
    defaultIndex: 0, // first data show on open
  },
  disableSigning,
  fromWhichPage = undefined,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [generatedData, setGeneratedData] = useState({
    originalData: undefined,
    fields: [],
    comments: [],
    status: "",
    relatedDocList: [],
    bgColor: "",
    headerText: "",
    recordNo: "",
  });
  const [navList, setNavList] = useState([]);
  const [navIndex, setNavIndex] = useState();

  const { currentEmployee } = useSelector((state) => state.commonThings);

  useEffect(() => {
    idList.length > 0 && startFetching();
  }, []);

  const startFetching = async () => {
    const { id, handler, type, isGroup } = idList[idListProps.defaultIndex];
    await getData(id, handler, type, isGroup);
  };

  const getData = async (id, fetchHandler, type, isGroup = true) => {
    try {
      setIsLoading(true);
      const { data } = await fetchHandler(id, isGroup);
      //generate function
      data && generateDataHandler(data, type);
    } catch (error) {
      console.log("err", error);
    } finally {
      setIsLoading(false);
    }
  };

  const generateDataHandler = (data, type) => {
    const originalData = data;

    let fields;
    let comments;
    let status;
    let headerText;
    let bgColor;
    let recordNo;

    if (type === "contract") {
      const obj = contractGeneratorHandler(
        data,
        fromWhichPage,
        currentEmployee && currentEmployee
      );

      fields = obj?.fields;
      comments = obj?.comments;
      // relatedDocs = obj?.relatedDocs;
      status = obj?.status;
      headerText = obj?.headerText;
      bgColor = obj?.bgColor;
      recordNo = obj?.recordNo;
    } else if (type === "request") {
      const obj = requestGeneratorHandler(
        data,
        fromWhichPage,
        currentEmployee && currentEmployee
      );

      fields = obj?.fields;
      comments = obj?.comments;
      status = obj?.status;
      headerText = obj?.headerText;
      bgColor = obj?.bgColor;
      recordNo = obj?.recordNo;
    }

    const list = generateCustomNavigation(idList);
    navList.length === 0 && setNavList(list);
    navList.length === 0 && setNavIndex(idListProps.defaultIndex);

    setGeneratedData({
      comments: comments,
      fields: fields,
      originalData: originalData,
      status: status,
      bgColor: bgColor,
      headerText: headerText,
      recordNo: recordNo,
    });
  };

  const generateCustomNavigation = (idList) => {
    const list = [];

    idList.map((item) => {
      const obj = {
        label: item.label,
        value: item.id,
      };
      list.push(obj);
    });

    return list;
  };

  const switchTabHandler = async (value) => {
    setIsLoading(true);
    setGeneratedData({
      originalData: undefined,
      fields: [],
      comments: [],
      status: "",
      relatedDocList: [],
      bgColor: "",
      headerText: "",
      recordNo: "",
    });
    const findedItem = idList.find((item, index) => {
      if (item.id === value) {
        setNavIndex(index);
        return item;
      }
    });

    if (!findedItem) return;

    await getData(
      findedItem.id,
      findedItem.handler,
      findedItem.type,
      findedItem.isGroup
    );
  };

  return (
    <DocumentDialog
      open={open}
      onClose={onClose}
      loading={isLoading}
      commentsData={generatedData.comments}
      fieldsData={generatedData.fields}
      navbarData={navList.length > 1 ? navList : []}
      signingProps={{
        hide: disableSigning,
      }}
      isGroup
      options={{
        bgColor: generatedData.bgColor,
        title: generatedData.headerText,
      }}
      navbarProps={{
        currTabValue: switchTabHandler,
        index: navIndex,
      }}
      contractNo={generatedData.recordNo}
      typeDoc={
        generatedData?.originalData?.contractType?.name ||
        generatedData?.originalData?.contract.contractType?.name
      }
    />
  );
}
