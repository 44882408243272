import {
  Typography,
  Grid,
  Button,
  Dialog,
  Box,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/layouts/header/Header";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";
import StatisticDiagramCircle from "../../components/statisticDiagram/StatisticDiagramCircle";
import StatisticDiagramSquare from "../../components/statisticDiagram/StatisticDiagramSquare";
import { MailOutline, Notifications, TaskSharp } from "@mui/icons-material";
import ContractsDialog from "../../layouts/employment-contracts/dialog/ContractsDialog";
import { EMPLOYMENT_CONTRACT_TYPE } from "../../constants";
import {
  getExpiringContractsFilter as getContractsFilter,
  getContractsStatistics,
} from "../../services/documentations/EmploymentContractsService";
import LabourContractsTable from "./LabourContractsTable";
import ExpiringLabourContractsTable from "./ExpiringLabourContractsTable";

export default function EmploymentContract() {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState();

  const [currMonthStat, setCurrMonthStat] = useState([]);
  const [generalStat, setGeneralStat] = useState({
    pending: 0,
    approved: 0,
    rejected: 0,
  });
  const [isLoadingCS, setIsLoadingCS] = useState(false);
  const [isLoadingGS, setIsLoadingGS] = useState(false);

  useEffect(() => {
    //general statistic
    getGenerlalStatistics();
    getCurrMonthStatistics();
  }, []);

  //fetch data
  const getGenerlalStatistics = async () => {
    try {
      setIsLoadingGS(true);
      const { data } = await getContractsStatistics();
      data &&
        setGeneralStat({
          approved: data.approved,
          pending: data.pending,
          rejected: data.rejected + data.expiringContracts,
        });
      setIsLoadingGS(false);
    } catch (error) {
      console.log("general statistics", error);
      setIsLoadingGS(false);
    }
  };
  const getCurrMonthStatistics = async () => {
    try {
      setIsLoadingCS(true);
      const startDate = new Date();
      startDate.setDate(1);

      const endDate = new Date();

      const startDateFormatted = `${
        startDate.getMonth() + 1
      }/${startDate.getDate()}/${startDate.getFullYear()}`;
      const endDateFormatted = `${
        endDate.getMonth() + 1
      }/${endDate.getDate()}/${endDate.getFullYear()}`;

      const { data } = await getContractsStatistics({
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      });
      data &&
        setCurrMonthStat({
          approved: data.approved,
          pending: data.pending,
          rejected: data.rejected + data.expiringContracts,
        });
      setIsLoadingCS(false);
    } catch (error) {
      console.log("current statistics", error);
      setIsLoadingCS(false);
    }
  };

  const showDialogHandler = (mode) => {
    mode === "notification"
      ? setDialogMode("notification")
      : setDialogMode(mode);
    setShowDialog(true);
  };

  const unshowDialogHandler = () => {
    setShowDialog(false);
  };

  // page consstant params
  const headerData = {
    title: "Əmək müqavilələri",
    variant: "h3",
  };

  return (
    <React.Fragment>
      <Box>
        <Grid>
          <Grid>
            <Grid>
              <Header data={headerData} />
            </Grid>
          </Grid>

          <Grid key={"statistic-container"} sx={styles.statisticContainer}>
            <Grid width={"50%"} sx={{ marginRight: 2 }}>
              {/* {isLoadingCS ? (
                <Grid sx={styles.loadingContainer}>
                  <CircularProgress color="inherit" />
                </Grid>
              ) : ( */}
              <StatisticDiagramCircle
                data={currMonthStat}
                headerText={"Cari ay üzrə statistika"}
                height={"100%"}
                keys={["pending", "approved", "rejected"]}
                colors={{
                  pending: "rgba(95,154,226, 0.95)",
                  approved: "rgba(141,181,160,0.95)",
                  rejected: "rgba(208, 130, 130, 0.95)",
                }}
                titles={{
                  pending: (
                    <React.Fragment key={"aa"}>
                      Tərtib olunmuş və imza <br /> gözləyən əmək müqavilələri
                    </React.Fragment>
                  ),
                  approved: `İmzalanmış əmək müqavilələri`,
                  rejected: `Xitam verilən əmək müqavilələri`,
                }}
                loading={isLoadingCS}
              />
              {/* )} */}
            </Grid>

            <Grid width={"50%"}>
              {/* {isLoadingGS ? (
                <Grid sx={styles.loadingContainer}>
                  <CircularProgress color="inherit" />
                </Grid>
              ) : ( */}
              <StatisticDiagramSquare
                headerText={"Ümumi statistika"}
                data={generalStat}
                height={"100%"}
                keys={["pending", "approved", "rejected"]}
                colors={{
                  pending: "rgba(95,154,226, 0.95)",
                  approved: "rgba(141,181,160,0.95)",
                  rejected: "rgba(208, 130, 130, 0.95)",
                }}
                titles={{
                  pending: "İmza gözlənilən əmək müqavilələri",
                  approved: "Qüvvədə olan əmək müqavilələri",
                  rejected: "Xitam verilən əmək müqavilələri",
                }}
                loading={isLoadingGS}
              />
              {/* )} */}
            </Grid>
          </Grid>

          <Grid sx={styles.endContractsContainer}>
            <Grid sx={styles.contractsSettingsCont}>
              <Typography
                width={"100%"}
                textAlign={"center"}
                sx={styles.settingsHeader}
              >
                Müqavilə Müddətinin Bitmə Haqqında Məlumatlandırma Sazlamaları
              </Typography>

              <Grid marginTop={5}>
                <Grid sx={styles.settingsLabelCont}>
                  <TaskSharp sx={{ marginRight: 1 }} />
                  <Typography>Bildiriş</Typography>
                </Grid>
                <Button
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={() => showDialogHandler("notification")}
                >
                  <Notifications sx={{ marginRight: 1 }} />
                  <Typography>Bildiriş alacaq Əməkdaşlar</Typography>
                </Button>
              </Grid>
              <Grid marginTop={5}>
                <Grid sx={styles.settingsLabelCont}>
                  <TaskSharp sx={{ marginRight: 1 }} />
                  <Typography>Mail</Typography>
                </Grid>
                <Button
                  variant="contained"
                  sx={{ width: "100%" }}
                  onClick={() => showDialogHandler("mail")}
                >
                  <MailOutline sx={{ marginRight: 1 }} />
                  <Typography>Mail alacaq Əməkdaşlar</Typography>
                </Button>
              </Grid>
            </Grid>

            {/* Müddəti bitmək üzrə olanlar */}
            <Grid width={"80%"}>
              <ExpiringLabourContractsTable />
            </Grid>
          </Grid>

          {/* Bütün əmək müqavilələri */}
          <Grid maxWidth={"100%"}>
            <LabourContractsTable />
          </Grid>
        </Grid>
        <Dialog
          open={showDialog}
          PaperProps={{ style: { width: "800px", maxWidth: "800px" } }}
          onClose={unshowDialogHandler}
        >
          <ContractsDialog onClose={unshowDialogHandler} mode={dialogMode} />
        </Dialog>
      </Box>
    </React.Fragment>
  );
}

const styles = {
  statisticContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
  },
  endContractsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "auto",
    height: "auto",
    marginTop: "10px",
  },
  contractsSettingsCont: {
    width: "20%",
    borderRadius: 2,
    padding: 2.5,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    marginRight: 2,
  },
  settingsHeader: { fontSize: 16 },
  settingsLabelCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 2,
    marginLeft: 2,
  },
  allContractsContainer: {
    marginTop: 5,
    width: "100%",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
