import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, FieldArray, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { getCommonData } from "../../api/api";
import NewCommonDataDialog from "./NewCommonDataDialog";

const Inventories = ({
  values,
  submitted,
  setSubmitted,
  selectedType,
  setSelectedType,
}) => {
  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([[]]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [inventarName, setInventarName] = useState("");
  const { errors, setErrors, setFieldValue } = useFormikContext();

  const handleItems = async (value, index) => {
    try {
      const response = await getCommonData("inventoryItems", "", value);

      const updatedInventoryItems = [...inventoryItems];

      updatedInventoryItems[index] = response.data;

      setInventoryItems(updatedInventoryItems);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateInventarItems = async () => {
    try {
      const response = await getCommonData("inventoryItems", "", inventarName);
      setInventoryItems((prevCarItems) => [...prevCarItems, response.data]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const handleInventory = async () => {
      const inventoryTypesResponse = await getCommonData("inventoryTypes");

      setInventoryTypes(inventoryTypesResponse.data);

      if (values.inventories) {
        for (let index = 0; index < values.inventories.length; index++) {
          if (values.inventories[index].itemType) {
            try {
              const value = values.inventories[index].itemType;

              const response = await getCommonData("inventoryItems", "", value);
              // Update the state with the response data for the specific item
              setInventoryItems((prevItems) => {
                const updatedItems = [...prevItems];
                updatedItems[index] = response.data;
                return updatedItems;
              });
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    };

    handleInventory();
  }, [submitted, values.inventories]);
  return (
    <>
      <FieldArray name="inventories">
        {({ push, remove }) => (
          <div>
            {values?.inventories &&
              values?.inventories.map((form, index) => (
                <div key={index}>
                  <>
                    <Typography mt={3} mb={3} variant="h6">
                      {index + 1})İnventarları
                    </Typography>

                    {/* Inventory model and category */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.inventories?.[index]?.itemType &&
                            Boolean(errors?.inventories?.[index]?.itemType)
                          }
                          fullWidth
                        >
                          <InputLabel id={`inventories[${index}].itemType`}>
                            İnventar növü *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`inventories[${index}].itemType`}
                            labelId={`inventories[${index}].itemType`}
                            label="İnventar növü *"
                            id={`inventories[${index}].itemType`}
                            onChange={(e) => {
                              if (e.target.value) {
                                setFieldValue(
                                  `inventories[${index}].itemType`,
                                  e.target.value
                                );
                                setFieldValue(`inventories[${index}].name`, "");
                                handleItems(e.target.value, index);
                                setInventarName(e.target.value);
                              }
                              if (errors?.inventories?.[index]?.itemType) {
                                setErrors({
                                  ...errors,
                                  inventories: [
                                    ...errors.inventories.slice(0, index),
                                    {
                                      ...errors.inventories[index],
                                      itemType: "",
                                    },
                                    ...errors.inventories.slice(index + 1),
                                  ],
                                });
                              }
                            }}
                            defaultValue=""
                            error={
                              errors?.inventories?.[index]?.itemType &&
                              Boolean(errors?.inventories?.[index]?.itemType)
                            }
                            fullWidth
                          >
                            {inventoryTypes &&
                              inventoryTypes
                                .sort((a, b) => a.localeCompare(b, "az"))
                                .map((item, index) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            <Button
                              sx={{
                                mt: 3,
                                ml: 3,
                              }}
                              variant="outlined"
                              onClick={() => {
                                setOpenAddDialog(true);
                                setSelectedType([
                                  "inventoryTypes",
                                  { name: "İnventar tipləri" },
                                ]);
                              }}
                            >
                              Yeni inventar növü əlavə et
                            </Button>
                          </Field>
                          {errors?.inventories?.[index]?.itemType &&
                            errors?.inventories?.[index]?.itemType && (
                              <FormHelperText error>
                                {errors?.inventories?.[index]?.itemType}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.inventories?.[index]?.name &&
                            Boolean(errors?.inventories?.[index]?.name)
                          }
                          fullWidth
                        >
                          <InputLabel id={`inventories[${index}].name`}>
                            İnventarın modeli *
                          </InputLabel>
                          <Field
                            as={Select}
                            name={`inventories[${index}].name`}
                            labelId={`inventories[${index}].name`}
                            label="İnventarın modeli *"
                            id={`inventories[${index}].name`}
                            disabled={!values.inventories[index].itemType}
                            defaultValue=""
                            error={
                              errors?.inventories?.[index]?.name &&
                              Boolean(errors?.inventories?.[index]?.name)
                            }
                            fullWidth
                            onChange={async (e) => {
                              setFieldValue(
                                `inventories[${index}].name`,
                                e.target.value
                              );
                              if (errors?.inventories?.[index]?.name) {
                                setErrors({
                                  ...errors,
                                  inventories: [
                                    ...errors.inventories.slice(0, index),
                                    {
                                      ...errors.inventories[index],
                                      name: "",
                                    },
                                    ...errors.inventories.slice(index + 1),
                                  ],
                                });
                              }
                            }}
                          >
                            {inventoryItems[index] &&
                              inventoryItems[index]
                                .sort((a, b) =>
                                  a?.name.localeCompare(b?.name, "az")
                                )
                                .map((item, index) => (
                                  <MenuItem key={index} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                            <Button
                              sx={{
                                mt: 3,
                                ml: 3,
                              }}
                              variant="outlined"
                              onClick={() => {
                                setOpenAddDialog(true);
                                setSelectedType([
                                  "inventoryItems",
                                  { name: "İnventar siyahısı" },
                                ]);
                              }}
                            >
                              Yeni inventarın modeli növü əlavə et
                            </Button>
                          </Field>
                          {errors?.inventories?.[index]?.name &&
                            errors?.inventories?.[index]?.name && (
                              <FormHelperText error>
                                {errors?.inventories?.[index]?.name}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Inventory number and date */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name={`inventories[${index}].number`}
                          as={TextField}
                          fullWidth
                          label="İnventar nömrəsi *"
                          error={
                            errors?.inventories?.[index]?.number &&
                            Boolean(errors?.inventories?.[index]?.number)
                          }
                          helperText={
                            errors?.inventories?.[index]?.number &&
                            errors?.inventories?.[index]?.number
                          }
                          onChange={async (e) => {
                            setFieldValue(
                              `inventories[${index}].number`,
                              e.target.value
                            );
                            if (errors?.inventories?.[index]?.number) {
                              setErrors({
                                ...errors,
                                inventories: [
                                  ...errors.inventories.slice(0, index),
                                  {
                                    ...errors.inventories[index],
                                    number: "",
                                  },
                                  ...errors.inventories.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <Field name={`inventories[${index}].date`}>
                          {({ field, form }) => (
                            <DatePicker
                              {...field}
                              label="İnventarın təhkim edilmə tarixi *"
                              inputFormat="dd/MM/yyyy"
                              value={field.value || null}
                              minDate={new Date(1940, 0, 1)}
                              // maxDate={new Date()}
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date)) {
                                  form.setFieldValue(
                                    `inventories[${index}].date`,
                                    date
                                  );
                                  if (errors?.inventories?.[index]?.date) {
                                    setErrors({
                                      ...errors,
                                      inventories: [
                                        ...errors.inventories.slice(0, index),
                                        {
                                          ...errors.inventories[index],
                                          date: "",
                                        },
                                        ...errors.inventories.slice(index + 1),
                                      ],
                                    });
                                  }
                                } else {
                                  form.setFieldValue(
                                    `inventories[${index}].date`,
                                    ""
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={
                                    form.errors?.inventories?.[index]?.date
                                  }
                                  error={Boolean(
                                    form.errors?.inventories?.[index]?.date
                                  )}
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>

                    <Grid sx={{ width: "50%" }}>
                      <Field
                        type="text"
                        name={`inventories[${index}].serialNumber`}
                        as={TextField}
                        fullWidth
                        label="İnventar seriya nömrəsi"
                        error={
                          errors?.inventories?.[index]?.serialNumber &&
                          Boolean(errors?.inventories?.[index]?.serialNumber)
                        }
                        helperText={
                          errors?.inventories?.[index]?.serialNumber &&
                          errors?.inventories?.[index]?.serialNumber
                        }
                      />
                    </Grid>
                  </>

                  <Button
                    sx={{ mt: 3 }}
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    İnventar sil
                  </Button>
                </div>
              ))}
            <Button
              sx={{ mt: 3 }}
              variant="contained"
              type="button"
              onClick={() => push({ serialNumber: "" })}
            >
              İnventar əlavə et
            </Button>
          </div>
        )}
      </FieldArray>
      {openAddDialog && (
        <NewCommonDataDialog
          submitted={submitted}
          selectedType={selectedType}
          setSubmitted={setSubmitted}
          openAddDialog={openAddDialog}
          setOpenAddDialog={setOpenAddDialog}
          setInventoryItems={handleUpdateInventarItems}
        />
      )}
    </>
  );
};

export default Inventories;
