import React, { useEffect, useReducer, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Api import
import {
  deleteManagerById,
  deleteManagers,
  getAllEmployees,
  getAllStructures,
  getManagers,
  postManager,
  putManager,
} from "../../api/api";

// Dialogs import
import ManagementDeleteDialog from "../../components/operations/management/ManagementDeleteDialog";
import ManageEditDialog from "../../components/operations/management/ManagementEditDialog";
import useAuth from "../../hooks/useAuth";
import { ManagementReducer, initialState } from "../../redux/ManagementReducer";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Management({
  dense,
  state,
  dispatch,
  handleDeleteManager,
  handlePutManager,
}) {
  const INITIALIZE = useAuth();
  const { managers } = state;
  return (
    <TableBody>
      {managers.map((item, index) => (
        <>
          {!managers ? (
            <div>Loading...</div>
          ) : (
            <>
              <TableRow key={index} mb={6}>
                <TableCell
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography variant="h6" gutterBottom>
                      {item.employee
                        ? item.employee.firstname + " " + item.employee.lastname
                        : null}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {item.structure?.organization.name} - {item.title}
                    </Typography>
                  </div>
                  <div>
                    <IconButton
                      onClick={() => {
                        dispatch({
                          type: "SET_MAN_EDIT_DIALOG",
                          payload: true,
                        });
                        dispatch({
                          type: "SET_STRUCTURE",
                          payload: item.structure,
                        });
                        dispatch({
                          type: "SET_EMPLOYEE",
                          payload: item.employee,
                        });
                        dispatch({
                          type: "SET_MANAGER",
                          payload: item,
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <ManageEditDialog
                      managerItem={item}
                      state={state}
                      dispatch={dispatch}
                      handlePutManager={handlePutManager}
                    />
                    <IconButton
                      onClick={() => {
                        dispatch({
                          type: "SET_MANAGER",
                          payload: item,
                        });
                        dispatch({
                          type: "SET_MAN_DELETE_DIALOG",
                          payload: true,
                        });
                      }}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                    <ManagementDeleteDialog
                      state={state}
                      dispatch={dispatch}
                      managerItem={item}
                      handleDeleteManager={handleDeleteManager}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </>
          )}
        </>
      ))}
    </TableBody>
  );
}

function Blank() {
  const [state, dispatch] = useReducer(ManagementReducer, initialState);
  const {
    managers,
    managerDialog,
    name,
    title,
    structure,
    structures,
    employees,
    employee,
  } = state;
  // Management edit dialog
  const [manEditDialog, setManEditDialog] = useState(false);
  const [dense, setDense] = useState(true);
  // Use effect handle
  const [submitted, setSubmitted] = useState(false);

  // Get all managers
  useEffect(() => {
    const getAllManagers = async () => {
      try {
        const response = await getManagers();
        const structureResponse = await getAllStructures();
        const employeesResponse = await getAllEmployees(0, 9999);

        if (response && response.data) {
          dispatch({
            type: "SET_MANAGERS",
            payload: response.data.managements,
          });
        }

        if (structureResponse && structureResponse.data) {
          dispatch({
            type: "SET_STRUCTURES",
            payload: structureResponse.data.structures,
          });
        }

        if (employeesResponse && employeesResponse.data) {
          dispatch({
            type: "SET_EMPLOYEES",
            payload: employeesResponse.data.employees,
          });
        }
      } catch (err) {
        console.log("error: ", err);
      }
    };
    getAllManagers();
  }, [submitted]);

  // Add manager
  const handleAddManager = async (name, structureId, title) => {
    await postManager(name, structureId, title);
    dispatch({
      type: "SET_MANAGER_DIALOG",
      payload: false,
    });
    setSubmitted(!submitted);
  };

  // Delete manager
  const handleDeleteManager = async (id) => {
    await deleteManagerById(id);
    dispatch({
      type: "SET_MANAGER",
      payload: "",
    });
    setSubmitted(!submitted);
  };

  // Update Manager
  const handlePutManager = async (
    managementId,
    structureId,
    employeeId,
    title
  ) => {
    await putManager(managementId, structureId, employeeId, title);
    setSubmitted(!submitted);
    dispatch({
      type: "SET_MANAGER",
      payload: "",
    });
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  return (
    <React.Fragment>
      <Helmet title="Management" />
      <ToastContainer />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Rəhbərlik
          </Typography>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant="contained"
            endIcon={<AddCircleIcon />}
            onClick={() =>
              dispatch({
                type: "SET_MANAGER_DIALOG",
                payload: true,
              })
            }
          >
            <Typography noWrap>Əlavə et</Typography>
          </Button>

          {/* Managers Dialog */}
          <Dialog
            open={managerDialog}
            fullWidth
            onClose={() => {
              dispatch({
                type: "SET_MANAGER_DIALOG",
                payload: false,
              });
              dispatch({
                type: "SET_STRUCTURE",
                payload: null,
              });
              dispatch({
                type: "SET_TITLE",
                payload: null,
              });
              dispatch({
                type: "SET_EMPLOYEE",
                payload: null,
              });
            }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <Typography variant="h4">Rəhbər əlavə et</Typography>
            </DialogTitle>
            <DialogContent>
              {/* Employee */}
              <FormControl sx={{ mt: 3 }} fullWidth>
                <InputLabel id="name">Əməkdaşlar</InputLabel>
                <Select
                  labelId="name"
                  id="name"
                  value={employee}
                  label="name"
                  onChange={(e) => {
                    dispatch({
                      type: "SET_EMPLOYEE",
                      payload: e.target.value,
                    });
                  }}
                >
                  {employees.map((item, index) => (
                    <MenuItem value={item}>
                      {`${item.firstname} ${item.lastname}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Structure */}
              <FormControl sx={{ mt: 3 }} fullWidth>
                <InputLabel id="structure">Şirkət</InputLabel>
                <Select
                  required
                  labelId="structure"
                  id="structure"
                  value={structure}
                  label="structure"
                  onChange={(e) => {
                    dispatch({
                      type: "SET_STRUCTURE",
                      payload: e.target.value,
                    });
                  }}
                >
                  {structures.map((item, index) => {
                    if (item.organization) {
                      return (
                        <MenuItem value={item}>
                          {item.organization?.name}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>

              {/* Title */}
              <TextField
                required
                sx={{ mt: 3 }}
                autoFocus
                margin="dense"
                id="title"
                label="Vəzifə"
                type="text"
                fullWidth
                onChange={(e) =>
                  dispatch({
                    type: "SET_TITLE",
                    payload: e.target.value,
                  })
                }
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  dispatch({
                    type: "SET_MANAGER_DIALOG",
                    payload: false,
                  })
                }
                color="primary"
              >
                Ləğv et
              </Button>
              <Button
                disabled={!structure || !title}
                color="primary"
                onClick={() =>
                  handleAddManager(employee._id, structure._id, title)
                }
              >
                Əlavə et
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <Divider my={6} />
      <TableContainer component={Paper} container spacing={6}>
        <Table
          item
          xs={12}
          sx={{ minWidth: 650 }}
          size={dense ? "small" : "medium"}
          aria-label="simple table"
        >
          <Management
            state={state}
            dense={dense}
            dispatch={dispatch}
            handlePutManager={handlePutManager}
            handleChangeDense={handleChangeDense}
            handleDeleteManager={handleDeleteManager}
          />
          <FormControlLabel
            style={{ marginLeft: "0" }}
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Yığcam görünüm"
          />
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

export default Blank;
