import { api } from "../../api/axiosInterceptor";

// GET: /employee/m -> Get Managements
export const getManagements = async (payload) => {
  try {
    const response = await api.get(`/employee/m`, {
      params: {
        limit: payload.limit,
        offset: payload.skip,
        title: payload.title || "",
        onlyUnused: payload.notUsed || false,
        parentRelId: payload.parentRel || undefined,
      },
    });
    return await response.data;
  } catch (error) {
    console.log("error: ", error);
  }
};
