import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import Header from "../../components/layouts/header/Header";
import Table from "../../components/table/Table";
import { useState } from "react";
import Column from "../../models/table/Column";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";

const header = {
  title: "Əməkhaqqına əlavələr",
  variant: "h1",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const column = [
  new Column({
    path: "#",
    headerName: "Operatorlar",
    disableColSearch: true,
    // isStaticColumn: true,
    widthOfColumn: 200,
    // renderCell: operatorGeneratorHandler,
  }),
  new Column({
    path: "",
    headerName: "Təşkilat",
  }),
  new Column({
    path: "",
    headerName: "Struktur/bölmə",
  }),
  new Column({
    path: "",
    headerName: "Şöbə",
  }),
  new Column({
    path: "",
    headerName: "Sektor",
  }),
  new Column({
    path: "",
    headerName: "Komponent",
  }),
  new Column({
    path: "",
    headerName: "Komponentin kodu",
  }),
  new Column({
    path: "",
    headerName: "Başlama tarixi",
  }),
  new Column({
    path: "",
    headerName: "Bitmə tarixi",
  }),
  new Column({
    path: "",
    headerName: "Qeyd",
  }),
  new Column({
    path: "",
    headerName: "Sıra nömrəsi",
  }),
];

export default function AdditionsToSalary() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // swipe datas
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const handleChangeTab = (e, value) => {
    setValue(value);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Grid sx={{ width: "100%", textAlign: "center", marginBottom: "50px" }}>
        <Header data={header} />
      </Grid>
      <Box>
        <Box>
          <Tabs value={value} onChange={handleChangeTab}>
            <Tab label="Daimi əlavələr" value={0} {...a11yProps(0)} />
            <Tab label="Birdəfəlik əlavələr" value={1} {...a11yProps(1)} />
          </Tabs>
        </Box>
      </Box>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          {/* table */}
          <Grid className="mt-4">
            <Table
              columns={column}
              data={data}
              totalRows={total}
              offset={offset}
              setOffset={(value) => setOffset(value)}
              limit={limit}
              setLimit={(value) => setLimit(value)}
              isLoading={isLoading}
              applyFiltersHandler={(filters) => setFilters(filters)}
              cancelAppliedFilters={() => setFilters([])}
              //   downloadHandler={(cols) => downlaodHandler(cols)}
            />
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          {/* table */}
          <Grid className="mt-4">
            <Table
              columns={column}
              data={data}
              totalRows={total}
              offset={offset}
              setOffset={(value) => setOffset(value)}
              limit={limit}
              setLimit={(value) => setLimit(value)}
              isLoading={isLoading}
              applyFiltersHandler={(filters) => setFilters(filters)}
              cancelAppliedFilters={() => setFilters([])}
              //   downloadHandler={(cols) => downlaodHandler(cols)}
            />
          </Grid>
        </TabPanel>
      </SwipeableViews>
    </>
  );
}
