import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Field } from "formik";
import { getAllStructuresEmptyStaff } from "../../../../api/api";
import NestedSelect from "../../../../utils/NestedSelect";

const JobRelated = ({
  values,
  oldData,
  positionIds,
  selectedEmp,
  selectedCont,
  setFieldValue,
  setShowCreateOrder,
}) => {
  const [nestedChildren, setNestedChildren] = useState([]);
  const [positionId, setPositionId] = useState("");
  const [contractPosition, setContractPosition] = useState();
  const [staffId, setStaffId] = useState();
  async function getPositionsData() {
    try {
      const res = await getAllStructuresEmptyStaff({
        structureId: positionIds,
        employeeId: selectedEmp,
        oldStaffId: selectedCont,
      });
      setNestedChildren(res?.data?.children);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getPositionsData();
  }, [selectedCont]);
  useEffect(() => {
    if (staffId && nestedChildren) {
      setFieldValue("jobRelated.staffId", staffId);
    } else {
      setFieldValue("jobRelated.staffId", oldData.staffId);
    }
  }, [nestedChildren, oldData.staffId, setFieldValue, staffId]);

  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Əməkdaşın vəzifə məlumatları</h6>
        </AccordionSummary>
        <AccordionDetails>
          {oldData?.isVerified ? (
            <NestedSelect
              setStaffId={setStaffId}
              setPositionId={setPositionId}
              setFieldValue={setFieldValue}
              nestedChildren={nestedChildren}
              setContractPosition={setContractPosition}
            />
          ) : (
            <Grid
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <Grid sx={{ display: "flex", gap: "10px" }}>
                <TextField
                  type="text"
                  label="Köhnə struktur"
                  // value={values?.jobRelated?.oldStructureAsStr}
                  fullWidth
                  name="jobRelated.oldStructureAsStr"
                  onChange={(event) => {
                    const value = event.target.value;
                    setTimeout(() => {
                      setFieldValue("jobRelated.oldStructureAsStr", value);
                    }, 0);
                  }}
                />
                <TextField
                  type="text"
                  label="Yeni struktur"
                  // value={values?.jobRelated?.newStructureAsStr}
                  fullWidth
                  name="jobRelated.newStructureAsStr"
                  onChange={(event) => {
                    const value = event.target.value;
                    setTimeout(() => {
                      setFieldValue("jobRelated.newStructureAsStr", value);
                    }, 0);
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", gap: "10px" }}>
                <TextField
                  type="text"
                  label="Köhnə vəzifə"
                  // value={values?.jobRelated?.oldStructureAsStr}
                  fullWidth
                  name="jobRelated.oldPositionAsStr"
                  onChange={(event) => {
                    const value = event.target.value;
                    setTimeout(() => {
                      setFieldValue("jobRelated.oldPositionAsStr", value);
                    }, 0);
                  }}
                />
                <TextField
                  type="text"
                  label="Yeni vəzifə"
                  // value={values?.jobRelated?.newStructureAsStr}
                  fullWidth
                  name="jobRelated.newPositionAsStr"
                  onChange={(event) => {
                    const value = event.target.value;
                    setTimeout(() => {
                      setFieldValue("jobRelated.newPositionAsStr", value);
                    }, 0);
                  }}
                />
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </CustomAccordion>
    </>
  );
};

export default JobRelated;
