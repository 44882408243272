import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Checkbox,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  Button,
  DialogTitle,
  Dialog,
  DialogContentText,
  TextField,
  DialogActions,
  DialogContent,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { Box, spacing } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { updateCompanyParts } from "../../api/api";
import DeleteDataDialog from "./DeleteDataDialog";
import EditDataDialog from "./EditDataDialog";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

function createData(name, description, id) {
  return { name, description, id };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Adı",
  },
  {},
  {
    id: "editDelete",
    disablePadding: false,
    label: "Redaktə et",
  },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={
              headCell.id === "editDelete"
                ? {
                    px: 3,
                    textAlign: "end",
                  }
                : {
                    px: 3,
                  }
            }
          >
            {headCell.id === "name" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              <>{headCell.label}</>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

// Organization name and add new data
const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { tableHeader, selectedType, handleAddNewData } = props;
  const [open, setOpen] = useState(false);
  const [name, setName] = useState();
  const [description, setDescription] = useState();

  const normalizeName = (key) => {
    switch (key) {
      case "organization":
        return "Təşkilat";
      case "position":
        return "Vəzifə";

      default:
        return key;
    }
  };

  return (
    <Toolbar>
      <div>
        {/* {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : ( */}
        <Typography variant="h4" id="tableTitle" noWrap>
          {selectedType.name === "organization"
            ? "Təşkilat"
            : selectedType.name === "position"
            ? "Vəzifə"
            : selectedType.name}{" "}
          {tableHeader.structure + " " + tableHeader.description}
        </Typography>
        {/* )} */}
      </div>
      <Spacer />
      <div>
        {/* {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : ( */}
        <Tooltip title="Əlavə et">
          <Button
            variant="contained"
            endIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}
          >
            <Typography noWrap>Əlavə et</Typography>
          </Button>
        </Tooltip>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Typography variant="h4">
              {normalizeName(selectedType.name)} adı əlavə et
            </Typography>
          </DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
              To subscribe to this website, please enter your email address
              here. We will send updates occasionally.
            </DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Adı"
              type="text"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Ləğv et
            </Button>
            <Button
              onClick={() => {
                handleAddNewData(name, description, selectedType._id);
                setOpen(false);
                setName("");
                setDescription("");
              }}
              color="primary"
            >
              Əlavə et
            </Button>
          </DialogActions>
        </Dialog>
        {/* )} */}
      </div>
    </Toolbar>
  );
};

function EnhancedTable({
  selectedType,
  tableHeader,
  companyData,
  handleAddNewData,
  handleDeleteData,
  submitted,
  setSubmitted,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [rowId, setRowId] = useState();

  // Table Rows
  let rows = [];

  const createRow = () => {
    companyData.map((item, index) => {
      return (rows = [
        ...rows,
        createData(item.name, item.description, item._id),
      ]);
    });
  };

  createRow();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedType={selectedType}
          tableHeader={tableHeader}
          handleAddNewData={handleAddNewData}
        />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              //   onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      //   onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      {/* Checkbox */}
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell> */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ px: 3 }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell>
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Box xs={6}>
                            <IconButton size="small">
                              <EditIcon
                                fontSize="14px"
                                onClick={() => {
                                  setOpenEditDialog(true);
                                  setRowId(row.id);
                                }}
                              />
                            </IconButton>

                            <EditDataDialog
                              openEditDialog={openEditDialog}
                              setOpenEditDialog={setOpenEditDialog}
                              rowId={rowId}
                              setRowId={setRowId}
                              row={row}
                              submitted={submitted}
                              setSubmitted={setSubmitted}
                            />
                          </Box>
                          <Box xs={6}>
                            <IconButton size="small">
                              <DeleteIcon
                                fontSize="14px"
                                color="error"
                                onClick={() => {
                                  setOpenDeleteDialog(true);
                                  setRowId(row.id);
                                }}
                              />
                            </IconButton>

                            <DeleteDataDialog
                              handleDeleteData={handleDeleteData}
                              selectedType={selectedType}
                              openDeleteDialog={openDeleteDialog}
                              setOpenDeleteDialog={setOpenDeleteDialog}
                              rowId={rowId}
                              setRowId={setRowId}
                              row={row}
                            />
                          </Box>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={`Səhifə başına say`}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Yığcam görünüm"
      />
    </div>
  );
}

export default EnhancedTable;
