import React from "react";
import styled from "styled-components";
import ButtonCustom from "../../../../../../ui/Button/ButtonCustom";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 3px 0px;
  display: flex;
  justify-content: end;
`;

const Toolbar = ({ addBtnHandler = (e) => {}, resetHandler = (e) => {} }) => {
  return (
    <Container>
      {/* <ButtonCustom
        styleContainer={{ width: "max-content", height: "100%" }}
        fontSize={"11px"}
        title={"Sıfırla"}
        backgroundColor={"#710c04"}
        onClick={resetHandler}
      /> */}
      <ButtonCustom
        styleContainer={{ width: "80px", height: "100%" }}
        fontSize={"11px"}
        title={"Əlavə et"}
        onClick={addBtnHandler}
      />
    </Container>
  );
};

export default Toolbar;
