import React, { useEffect, useState } from "react";
import TransferAnotherJob from "./transfer-another-job/TransferAnotherJob";
import EmptyPosition from "./empty-position/EmptyPosition";
import Compensation from "./compensation/compensation";
import AwardRecognition from "./award-recognition/AwardRecognition";
import { useSearchParams } from "react-router-dom";
import RecallWork from "./recall-work/RecallWork";
import TemporaryReplacement from "./temporary-replacement/temporary-replacement";
import SpecialityDegree from "./speciality-degree/speciality-degree";
import ExtensionOrder from "./extension-order/extension-order";

const StaticOrder = () => {
  const [activeComponent, setActiveComponent] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const name = searchParams.get("name");
    setActiveComponent(name);
  }, [searchParams]);

  const renderComponent = (name) => {
    switch (name) {
      case "transfer-order":
        return <TransferAnotherJob />;
      case "filling-vacant-position-order":
        return <EmptyPosition />;
      case "recall-to-work-order":
        return <RecallWork />;
      case "compensation-order":
        return <Compensation />;
      case "reward-and-award-order":
        return <AwardRecognition />;
      case "temporary-replacement-order":
        return <TemporaryReplacement />;
      case "granting-profession-order":
        return <SpecialityDegree />;
      case "vacation-extension-order":
        return <ExtensionOrder />;
      default:
        return null;
    }
  };
  return activeComponent ? renderComponent(activeComponent) : null;
};

export default StaticOrder;
