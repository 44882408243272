import React from "react";
import { OPERATORS_CONTAINING } from "../../../constants";
import Column from "../../../models/table/Column";
import styled from "styled-components";
import { getStaticFilterValues } from "../../../utils/forFilterDropdown/getStructureUtils";
import moment from "moment";
const Td = styled.td`
  padding: 10px;
  margin: 0;
  font-weight: 100;
`;
const capitalizeFirstWord = (str) => {
  const words = str?.split(" ");
  if (words?.length > 0) {
    words[0] =
      words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
  }
  return words?.join(" ");
};
export const columnsVacation = [
  new Column({
    path: "employee.tableNumber",
    headerName: "Tabel nömrəsi",
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "employeeFirstName",
    headerName: "Əməkdaşın adı",
    filterType: OPERATORS_CONTAINING.TEXT,
    getValue: (row, col) => {
      return capitalizeFirstWord(`${row?.employee?.firstname}`);
    },
  }),
  new Column({
    path: "employeeLastName",
    headerName: "Əməkdaşın soyadı",
    filterType: OPERATORS_CONTAINING.TEXT,
    getValue: (row, col) => {
      return capitalizeFirstWord(` ${row?.employee?.lastname}`);
    },
  }),
  new Column({
    path: "employeeFatherName",
    headerName: "Əməkdaşın ata adı",
    filterType: OPERATORS_CONTAINING.TEXT,
    getValue: (row, col) => {
      return capitalizeFirstWord(`${row?.employee?.fathername}`);
    },
  }),
  new Column({
    path: "baseStructure",
    headerName: "Təşkilat",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const baseStructureName =
                vacation?.baseStructure?.organization?.name;
              return (
                <React.Fragment key={index}>
                  <Td>
                    <div>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {baseStructureName ? baseStructureName : "-"}
                        </p>
                      </div>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              const baseStructureName =
                vacation?.baseStructure?.organization?.name;
              return (
                <React.Fragment key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "20px",
                    }}
                  >
                    <>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          padding: "10px",
                        }}
                        className="subHeaderVacation"
                      >
                        <p title={baseStructureName} className="specialText">
                          {baseStructureName ? baseStructureName : "-"}
                        </p>
                      </div>
                    </>
                  </div>
                  <hr
                    style={{
                      display:
                        row?.vacationTrackings?.length - 1 === index
                          ? "none"
                          : "block",
                    }}
                    className="vacationDivide"
                  />
                </React.Fragment>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    filterType: OPERATORS_CONTAINING.IN,
    filterColumnKey: "baseStructure",
    fetchFilterValuesV2: getStaticFilterValues,
    dataGridName: "vacation-tracking",
    keyForResponseDataFromFilter: "baseStructures",
    rowSpan: "2",
  }),
  new Column({
    path: "baseStructureAsStr",
    headerName: "Köhnə Təşkilat",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              gap: "20px",
            }}
          >
            {row?.vacationTrackings.map((item, index) => (
              <div key={index}>
                {item.baseStructureAsStr ? item.baseStructureAsStr : "-"}
              </div>
            ))}
          </div>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <>
            {row?.vacationTrackings.map((item, index) => (
              <React.Fragment key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    gap: "20px",
                  }}
                >
                  <div
                    class="subHeaderVacation"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "10px",
                    }}
                  >
                    <p title={item.baseStructureAsStr} className="specialText">
                      {item.baseStructureAsStr ? item.baseStructureAsStr : "-"}
                    </p>
                  </div>
                </div>
                <hr
                  style={{
                    display:
                      row?.vacationTrackings?.length - 1 === index
                        ? "none"
                        : "block",
                  }}
                  className="vacationDivide"
                />
              </React.Fragment>
            ))}
          </>
        );
      }
    },
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "topPartOfStructure",
    headerName: "Department",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const topPartOfStructureName =
                vacation?.topPartOfStructure?.source?.name;
              return (
                <React.Fragment key={index}>
                  <Td>
                    <div>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {topPartOfStructureName
                            ? topPartOfStructureName
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              const topPartOfStructureName =
                vacation?.topPartOfStructure?.source?.name;
              return (
                <React.Fragment key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "20px",
                    }}
                  >
                    <>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          padding: "10px",
                        }}
                        className="subHeaderVacation"
                      >
                        <p
                          title={topPartOfStructureName}
                          className="specialText"
                        >
                          {" "}
                          {topPartOfStructureName
                            ? topPartOfStructureName
                            : "-"}
                        </p>
                      </div>
                    </>
                  </div>
                  <hr
                    style={{
                      display:
                        row?.vacationTrackings?.length - 1 === index
                          ? "none"
                          : "block",
                    }}
                    className="vacationDivide"
                  />
                </React.Fragment>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    filterColumnKey: "topPartOfStructure",
    fetchFilterValuesV2: getStaticFilterValues,
    dataGridName: "vacation-tracking",
    dependencieKeysOfFilter: {
      structures: ["baseStructure._id"],
    },
    keyForResponseDataFromFilter: "topPartOfStructures",
    filterType: OPERATORS_CONTAINING.IN,
    rowSpan: "2",
  }),
  new Column({
    path: "topPartOfStructureAsStr",
    headerName: "Köhnə Department",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              gap: "20px",
            }}
          >
            {row?.vacationTrackings.map((item, index) => (
              <div key={index}>
                {item.topPartOfStructureAsStr
                  ? item.topPartOfStructureAsStr
                  : "-"}
              </div>
            ))}
          </div>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <>
            {row?.vacationTrackings.map((item, index) => (
              <React.Fragment key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    gap: "20px",
                  }}
                >
                  <div
                    class="subHeaderVacation"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "10px",
                    }}
                  >
                    <p
                      title={item.topPartOfStructureAsStr}
                      className="specialText"
                    >
                      {item.topPartOfStructureAsStr
                        ? item.topPartOfStructureAsStr
                        : "-"}
                    </p>
                  </div>
                </div>
                <hr
                  style={{
                    display:
                      row?.vacationTrackings?.length - 1 === index
                        ? "none"
                        : "block",
                  }}
                  className="vacationDivide"
                />
              </React.Fragment>
            ))}
          </>
        );
      }
    },
    filterType: OPERATORS_CONTAINING.TEXT,
  }),

  new Column({
    path: "subStructure",
    headerName: "Sub-struktur",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const subStructureName = vacation?.subStructure?.source?.name;
              return (
                <React.Fragment key={index}>
                  <Td>
                    <div>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {subStructureName ? subStructureName : "-"}
                        </p>
                      </div>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              const subStructureName = vacation?.subStructure?.source?.name;
              return (
                <React.Fragment key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "20px",
                    }}
                  >
                    <>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          padding: "10px",
                        }}
                        className="subHeaderVacation"
                      >
                        <p title={subStructureName} className="specialText">
                          {" "}
                          {subStructureName ? subStructureName : "-"}
                        </p>
                      </div>
                    </>
                  </div>
                  <hr
                    style={{
                      display:
                        row?.vacationTrackings?.length - 1 === index
                          ? "none"
                          : "block",
                    }}
                    className="vacationDivide"
                  />
                </React.Fragment>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    rowSpan: "2",
    filterType: OPERATORS_CONTAINING.IN,
    filterColumnKey: "subStructure",
    fetchFilterValuesV2: getStaticFilterValues,
    dataGridName: "vacation-tracking",
    dependencieKeysOfFilter: {
      structures: ["baseStructure._id"],
      topPartOfStructures: ["topPartOfStructure._id"],
    },
    keyForResponseDataFromFilter: "subStructures",
  }),
  new Column({
    path: "subStructureAsStr",
    headerName: "Köhnə Sub-struktur",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              gap: "20px",
            }}
          >
            {row?.vacationTrackings.map((item, index) => (
              <div key={index}>
                {item.subStructureAsStr ? item.subStructureAsStr : "-"}
              </div>
            ))}
          </div>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <>
            {row?.vacationTrackings.map((item, index) => (
              <React.Fragment key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    gap: "20px",
                  }}
                >
                  <div
                    class="subHeaderVacation"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "10px",
                    }}
                  >
                    <p title={item.subStructureAsStr} className="specialText">
                      {item.subStructureAsStr ? item.subStructureAsStr : "-"}
                    </p>
                  </div>
                </div>
                <hr
                  style={{
                    display:
                      row?.vacationTrackings?.length - 1 === index
                        ? "none"
                        : "block",
                  }}
                  className="vacationDivide"
                />
              </React.Fragment>
            ))}
          </>
        );
      }
    },
    filterType: OPERATORS_CONTAINING.TEXT,
  }),
  new Column({
    path: "position",
    headerName: "Vəzifə",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              const positionName = vacation?.position?.source?.name;
              return (
                <React.Fragment key={index}>
                  <Td>
                    <div>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {positionName ? positionName : "-"}
                        </p>
                      </div>
                    </div>
                  </Td>
                </React.Fragment>
              );
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              const positionName = vacation?.position?.source?.name;
              return (
                <React.Fragment key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      gap: "20px",
                    }}
                  >
                    <>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          padding: "10px",
                        }}
                        className="subHeaderVacation"
                      >
                        <p title={positionName} className="specialText">
                          {" "}
                          {positionName ? positionName : "-"}
                        </p>
                      </div>
                    </>
                  </div>
                  <hr
                    style={{
                      display:
                        row?.vacationTrackings?.length - 1 === index
                          ? "none"
                          : "block",
                    }}
                    className="vacationDivide"
                  />
                </React.Fragment>
              );
            })}
          </Td>
        );
      }
      return null;
    },
    rowSpan: "2",
    filterType: OPERATORS_CONTAINING.IN,
    filterColumnKey: "position",
    fetchFilterValuesV2: getStaticFilterValues,
    dataGridName: "vacation-tracking",
    dependencieKeysOfFilter: {
      structures: ["baseStructure._id"],
      topPartOfStructures: ["topPartOfStructure._id"],
    },
    keyForResponseDataFromFilter: "positions",
  }),
  new Column({
    path: "positionAsStr",
    headerName: "Köhnə Vəzifə",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              gap: "20px",
            }}
          >
            {row?.vacationTrackings.map((item, index) => (
              <div key={index}>
                {item.positionAsStr ? item.positionAsStr : "-"}
              </div>
            ))}
          </div>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <>
            {row?.vacationTrackings.map((item, index) => (
              <React.Fragment key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    gap: "20px",
                  }}
                >
                  <div
                    class="subHeaderVacation"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "10px",
                    }}
                  >
                    <p title={item.positionAsStr} className="specialText">
                      {item.positionAsStr ? item.positionAsStr : "-"}
                    </p>
                  </div>
                </div>
                <hr
                  style={{
                    display:
                      row?.vacationTrackings?.length - 1 === index
                        ? "none"
                        : "block",
                  }}
                  className="vacationDivide"
                />
              </React.Fragment>
            ))}
          </>
        );
      }
    },
    filterType: OPERATORS_CONTAINING.TEXT,
  }),

  new Column({
    path: "vacationReportStartDate",
    headerName: "İş ilinin başlaması",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const startWorkYear =
                  vacation?.vacationReports?.[0]?.startWorkYear;
                return (
                  <React.Fragment key={index}>
                    <Td>
                      <div>
                        <div className="subHeaderVacation">
                          <p style={{ textAlign: "center" }}>
                            {" "}
                            {moment(startWorkYear).format("DD/MM/YYYY")}
                          </p>
                        </div>
                      </div>
                    </Td>
                  </React.Fragment>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <Td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      {vacation?.vacationReports.map((vacation, index) => {
                        return (
                          <div>
                            <div className="subHeaderVacation">
                              <p style={{ textAlign: "center" }}>
                                {" "}
                                {moment(vacation?.startWorkYear).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Td>
                );
              }
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const startWorkYear =
                  vacation?.vacationReports?.[0]?.startWorkYear;
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {moment(startWorkYear).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </Td>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </div>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      {vacation?.vacationReports.map((vacation, index) => {
                        return (
                          <div>
                            <div className="subHeaderVacation">
                              <p style={{ textAlign: "center" }}>
                                {" "}
                                {moment(vacation?.startWorkYear).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </>
                );
              }
            })}
          </Td>
        );
      }
      return null;
    },
    filterType: OPERATORS_CONTAINING.DATE,
    rowSpan: "2",
  }),
  new Column({
    path: "vacationReportEndDate",
    headerName: "İş ilinin bitməsi",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const endWorkYear = vacation?.vacationReports?.[0]?.endWorkYear;
                return (
                  <React.Fragment key={index}>
                    <Td>
                      <div>
                        <div className="subHeaderVacation">
                          <p style={{ textAlign: "center" }}>
                            {" "}
                            {moment(endWorkYear).format("DD/MM/YYYY")}
                          </p>
                        </div>
                      </div>
                    </Td>
                  </React.Fragment>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <Td>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      {vacation?.vacationReports.map((vacation, index) => {
                        return (
                          <div>
                            <div className="subHeaderVacation">
                              <p style={{ textAlign: "center" }}>
                                {" "}
                                {moment(vacation?.endWorkYear).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Td>
                );
              }
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const endWorkYear = vacation?.vacationReports?.[0]?.endWorkYear;
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {moment(endWorkYear).format("DD/MM/YYYY")}
                        </p>
                      </div>
                    </Td>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </div>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      {vacation?.vacationReports.map((vacation, index) => {
                        return (
                          <div>
                            <div className="subHeaderVacation">
                              <p style={{ textAlign: "center" }}>
                                {" "}
                                {moment(vacation?.endWorkYear).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </>
                );
              }
            })}
          </Td>
        );
      }
      return null;
    },
    filterType: OPERATORS_CONTAINING.DATE,
    rowSpan: "2",
  }),
  new Column({
    // path: "position.name",
    headerName: "Yaranmış məzuniyyət hüququ",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const reservedName = vacation?.vacationReports?.[0]?.reserved;

                return (
                  <React.Fragment key={index}>
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {reservedName?.main}
                        </p>
                      </div>
                    </Td>
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {reservedName?.additional}
                        </p>
                      </div>
                    </Td>
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {reservedName?.total}
                        </p>
                      </div>
                    </Td>
                  </React.Fragment>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <Td colSpan={"3"}>
                    {vacation?.vacationReports.map((vacation, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              gap: "20px",
                            }}
                          >
                            <Td>
                              <div className="subHeaderVacation">
                                <p style={{ textAlign: "center" }}>
                                  {vacation?.reserved?.main}
                                </p>
                              </div>
                            </Td>
                            <Td>
                              <div className="subHeaderVacation">
                                <p style={{ textAlign: "center" }}>
                                  {vacation?.reserved?.additional}
                                </p>
                              </div>
                            </Td>
                            <Td>
                              <div className="subHeaderVacation">
                                <p style={{ textAlign: "center" }}>
                                  {vacation?.reserved?.total}
                                </p>
                              </div>
                            </Td>
                          </div>
                          {/* <hr
                              style={{
                                display:
                                  row?.vacationTrackings?.length - 1 === index
                                    ? "none"
                                    : "block",
                              }}
                              className="vacationDivide"
                            /> */}
                        </React.Fragment>
                      );
                    })}
                  </Td>
                );
              }
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td colSpan={"3"}>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const reservedName = vacation?.vacationReports?.[0]?.reserved;

                return (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      <Td>
                        <div className="subHeaderVacation">
                          <p style={{ textAlign: "center" }}>
                            {reservedName?.main}
                          </p>
                        </div>
                      </Td>
                      <Td>
                        <div className="subHeaderVacation">
                          <p style={{ textAlign: "center" }}>
                            {reservedName?.additional}
                          </p>
                        </div>
                      </Td>
                      <Td>
                        <div className="subHeaderVacation">
                          <p style={{ textAlign: "center" }}>
                            {reservedName?.total}
                          </p>
                        </div>
                      </Td>
                    </div>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </React.Fragment>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      {vacation?.vacationReports.map((vacation, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                gap: "20px",
                              }}
                            >
                              <div>
                                <div className="subHeaderVacation">
                                  <p style={{ textAlign: "center" }}>
                                    {vacation?.reserved?.main}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="subHeaderVacation">
                                  <p style={{ textAlign: "center" }}>
                                    {vacation?.reserved?.additional}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="subHeaderVacation">
                                  <p style={{ textAlign: "center" }}>
                                    {vacation?.reserved?.total}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <hr
                              style={{
                                display:
                                  row?.vacationTrackings?.length - 1 === index
                                    ? "none"
                                    : "block",
                              }}
                              className="vacationDivide"
                            /> */}
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </>
                );
              }
            })}
          </Td>
        );
      }
      return null;
    },
    isSubHeader: true,
    colSpan: "3",
    subHeader: [
      {
        name: "Əsas",
        colmun: "vacationReportReservedMain",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Əlavə",
        colmun: "vacationReportReservedAdditional",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Cəmi",
        colmun: "vacationReportReservedTotal",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
    ],
    filterType: OPERATORS_CONTAINING.NUMBER,
  }),
  new Column({
    headerName: "İstifadə edilmiş məzuniyyət",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const usedName = vacation?.vacationReports?.[0]?.used;

                return (
                  <React.Fragment key={index}>
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>{usedName?.main}</p>
                      </div>
                    </Td>
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {usedName?.additional}
                        </p>
                      </div>
                    </Td>
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>{usedName?.total}</p>
                      </div>
                    </Td>
                  </React.Fragment>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <Td colSpan={"3"}>
                    {vacation?.vacationReports.map((vacation, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              gap: "20px",
                            }}
                          >
                            <Td>
                              <div className="subHeaderVacation">
                                <p style={{ textAlign: "center" }}>
                                  {vacation?.used?.main}
                                </p>
                              </div>
                            </Td>
                            <Td>
                              <div className="subHeaderVacation">
                                <p style={{ textAlign: "center" }}>
                                  {vacation?.used?.additional}
                                </p>
                              </div>
                            </Td>
                            <Td>
                              <div className="subHeaderVacation">
                                <p style={{ textAlign: "center" }}>
                                  {vacation?.used?.total}
                                </p>
                              </div>
                            </Td>
                          </div>
                          {/* <hr
                              style={{
                                display:
                                  row?.vacationTrackings?.length - 1 === index
                                    ? "none"
                                    : "block",
                              }}
                              className="vacationDivide"
                            /> */}
                        </React.Fragment>
                      );
                    })}
                  </Td>
                );
              }
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td colSpan={"3"}>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const usedName = vacation?.vacationReports?.[0]?.used;
                return (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      <Td>
                        <div className="subHeaderVacation">
                          <p style={{ textAlign: "center" }}>
                            {usedName?.main}
                          </p>
                        </div>
                      </Td>
                      <Td>
                        <div className="subHeaderVacation">
                          <p style={{ textAlign: "center" }}>
                            {usedName?.additional}
                          </p>
                        </div>
                      </Td>
                      <Td>
                        <div className="subHeaderVacation">
                          <p style={{ textAlign: "center" }}>
                            {usedName?.total}
                          </p>
                        </div>
                      </Td>
                    </div>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </React.Fragment>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      {vacation?.vacationReports.map((vacation, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                gap: "20px",
                              }}
                            >
                              <div>
                                <div className="subHeaderVacation">
                                  <p style={{ textAlign: "center" }}>
                                    {vacation?.used?.main}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="subHeaderVacation">
                                  <p style={{ textAlign: "center" }}>
                                    {vacation?.used?.additional}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="subHeaderVacation">
                                  <p style={{ textAlign: "center" }}>
                                    {vacation?.used?.total}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <hr
                              style={{
                                display:
                                  row?.vacationTrackings?.length - 1 === index
                                    ? "none"
                                    : "block",
                              }}
                              className="vacationDivide"
                            /> */}
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </>
                );
              }
            })}
          </Td>
        );
      }
      return null;
    },
    isSubHeader: true,
    colSpan: "3",
    subHeader: [
      {
        name: "Əsas",
        colmun: "vacationReportUsedMain",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Əlavə",
        colmun: "vacationReportUsedAdditional",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Cəmi",
        colmun: "vacationReportUsedTotal",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
    ],
    filterType: OPERATORS_CONTAINING.NUMBER,
  }),
  new Column({
    headerName: "Qalıq məzuniyyət günləri",
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const unUsedName = vacation?.vacationReports?.[0]?.unUsed;

                return (
                  <React.Fragment key={index}>
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {unUsedName?.main}
                        </p>
                      </div>
                    </Td>
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {unUsedName?.additional}
                        </p>
                      </div>
                    </Td>
                    <Td>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {unUsedName?.total}
                        </p>
                      </div>
                    </Td>
                  </React.Fragment>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <Td colSpan={"3"}>
                    {vacation?.vacationReports.map((vacation, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              gap: "20px",
                            }}
                          >
                            <Td>
                              <div className="subHeaderVacation">
                                <p style={{ textAlign: "center" }}>
                                  {vacation?.unUsed?.main}
                                </p>
                              </div>
                            </Td>
                            <Td>
                              <div className="subHeaderVacation">
                                <p style={{ textAlign: "center" }}>
                                  {vacation?.unUsed?.additional}
                                </p>
                              </div>
                            </Td>
                            <Td>
                              <div className="subHeaderVacation">
                                <p style={{ textAlign: "center" }}>
                                  {vacation?.unUsed?.total}
                                </p>
                              </div>
                            </Td>
                          </div>
                          {/* <hr
                              style={{
                                display:
                                  row?.vacationTrackings?.length - 1 === index
                                    ? "none"
                                    : "block",
                              }}
                              className="vacationDivide"
                            /> */}
                        </React.Fragment>
                      );
                    })}
                  </Td>
                );
              }
            })}
          </>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <Td colSpan={"3"}>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const unUsedName = vacation?.vacationReports?.[0]?.unUsed;
                return (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      <Td>
                        <div className="subHeaderVacation">
                          <p style={{ textAlign: "center" }}>
                            {unUsedName?.main}
                          </p>
                        </div>
                      </Td>
                      <Td>
                        <div className="subHeaderVacation">
                          <p style={{ textAlign: "center" }}>
                            {unUsedName?.additional}
                          </p>
                        </div>
                      </Td>
                      <Td>
                        <div className="subHeaderVacation">
                          <p style={{ textAlign: "center" }}>
                            {unUsedName?.total}
                          </p>
                        </div>
                      </Td>
                    </div>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </React.Fragment>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      {vacation?.vacationReports.map((vacation, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                gap: "20px",
                              }}
                            >
                              <div>
                                <div className="subHeaderVacation">
                                  <p style={{ textAlign: "center" }}>
                                    {vacation?.unUsed?.main}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="subHeaderVacation">
                                  <p style={{ textAlign: "center" }}>
                                    {vacation?.unUsed?.additional}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="subHeaderVacation">
                                  <p style={{ textAlign: "center" }}>
                                    {vacation?.unUsed?.total}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <hr
                              style={{
                                display:
                                  row?.vacationTrackings?.length - 1 === index
                                    ? "none"
                                    : "block",
                              }}
                              className="vacationDivide"
                            /> */}
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </>
                );
              }
            })}
          </Td>
        );
      }
      return null;
    },
    isSubHeader: true,
    colSpan: "3",
    filterType: OPERATORS_CONTAINING.NUMBER,
    subHeader: [
      {
        name: "Əsas",
        colmun: "vacationReportUnUsedMain",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Əlavə",
        colmun: "vacationReportUnUsedAdditional",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
      {
        name: "Cəmi",
        colmun: "vacationReportUnUsedTotal",
        filterType: OPERATORS_CONTAINING.NUMBER,
      },
    ],
  }),
  new Column({
    path: "vacationTrackings",
    headerName: "Əvəzgün",
    filterType: OPERATORS_CONTAINING.NUMBER,
    renderCell: (row, col) => {
      if (row?.vacationTrackings?.length === 1) {
        return (
          <div>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const interrupted = vacation?.vacationReports?.[0]?.interrupted;
                return (
                  <React.Fragment key={index}>
                    <>
                      <div>
                        <div className="subHeaderVacation">
                          <p
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {interrupted ? interrupted : "-"}
                          </p>
                        </div>
                      </div>
                    </>
                  </React.Fragment>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      {vacation?.vacationReports.map((vacation, index) => {
                        return (
                          <div>
                            <div className="subHeaderVacation">
                              <p
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {vacation?.interrupted
                                  ? vacation.interrupted
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                );
              }
            })}
          </div>
        );
      } else if (row?.vacationTrackings?.length > 1) {
        return (
          <>
            {row?.vacationTrackings.map((vacation, index) => {
              if (vacation?.vacationReports?.length === 1) {
                const interrupted = vacation?.vacationReports?.[0]?.interrupted;
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <div className="subHeaderVacation">
                        <p style={{ textAlign: "center" }}>
                          {interrupted ? interrupted : "-"}
                        </p>
                      </div>
                    </>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </div>
                );
              } else if (vacation?.vacationReports?.length > 1) {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        gap: "20px",
                      }}
                    >
                      {vacation?.vacationReports.map((vacation, index) => {
                        return (
                          <div>
                            <div className="subHeaderVacation">
                              <p style={{ textAlign: "center" }}>
                                {vacation?.interrupted
                                  ? vacation.interrupted
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <hr
                      style={{
                        display:
                          row?.vacationTrackings?.length - 1 === index
                            ? "none"
                            : "block",
                      }}
                      className="vacationDivide"
                    />
                  </>
                );
              }
            })}
          </>
        );
      }
      return null;
    },
  }),
];
