import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Divider as MuiDivider,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";
import { Add as AddIcon, RunningWithErrors } from "@mui/icons-material";

// Formik and Yup
import { useFormik } from "formik";
import * as Yup from "yup";

//  Components
import Header from "../../components/layouts/header/Header";
import Breadcrumb from "../../components/layouts/breadcrumb/Breadcrumb";
import CardList from "../../components/companyStructure/structureListCard/CardList";

// Services
import * as companyStructureServices from "../../services/structure/StructureServices";
import * as structureNamingServices from "../../services/structureNaming/NamingServices";

// Store
import { setStructureNames } from "../../redux/features/slices/structureNaming.slice";

// React Toastify
import { ToastContainer, toast } from "react-toastify";
// Helpers
import { findStructureIdByName } from "../../utils/helperFunctions";
import { structureNamingsEnum } from "../../constants";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  mb_2: {
    marginBottom: "15px",
  },
  loadingOptions: {
    textAlign: "center",
    fontSize: "18px",
  },
});

const CompanyStructure = () => {
  const classes = useStyles();
  // Constants
  const initialStructureDatas = {
    total: 0,
    structures: [],
  };
  const paginationOptions = {
    limit: 100,
    skip: 0,
  };

  const dispatch = useDispatch();
  // Selectors
  const { structureNames, selectedStructureId } = useSelector(
    (state) => state.structureName
  );

  // States
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [structureDatas, setStructureDatas] = useState(initialStructureDatas);
  const [structureId, setStructureId] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  const validationSchema = Yup.object({
    count: Yup.number()
      .required("Bu xana məcburidir")
      .min(1, "Minimum dəyər 1 ola bilər"),
  });
  // console.log(structureDatas);
  const formik = useFormik({
    initialValues: {
      count: "",
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    try {
      (async () => {
        const response = await structureNamingServices.getCompanyStructures();
        if (response && response.length > 0) {
          const data = findStructureIdByName(
            response,
            structureNamingsEnum.ORGANIZATION
          );
          setStructureId(data);
          dispatch(setStructureNames(response));
        }
      })();
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchMoreCompanyStructures();
  }, []);

  async function fetchMoreCompanyStructures() {
    try {
      setLoading(true);
      const data = await companyStructureServices.getCompanyStructures({
        limit: paginationOptions.limit,
        skip: paginationOptions.skip,
      });
      if (data) {
        setLoading(false);
        if (Object.keys(data)?.length) {
          setStructureDatas(data);
        }
      } else {
        setLoading(true);
      }
    } catch (error) {
      setLoading(false);
      console.log("error: ", error);
    }
  }
  const handleClose = () => {
    setOpenModal(false);
    formik.resetForm();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { count } = formik.values;
      setOpenModal(false);
      const _response = await companyStructureServices.createCompanyStructure({
        count: +count,
      });
      if (_response) {
        toast.success("Yeni struktur uğurla yaradıldı!");
        setLoading(false);
        fetchMoreCompanyStructures();
        formik.resetForm();
        // setIsUpdated(!isUpdated);
      } else {
        toast.error("Hər hansı bir xəta baş verdi");
      }
    } catch (error) {
      setLoading(false);
      console.log("error: ", error);
    }
  };

  const _saveCompanyPartsToStructure = async (organization, id) => {
    try {
      setLoading(true);
      const response = await companyStructureServices.updateCompanyStructure(
        { organization },
        id
      );
      if (response) {
        setLoading(false);
        toast.success("Yeni təşkilat uğurla əlavə edildi!");
        fetchMoreCompanyStructures();
      } else {
        toast.error("Hər hansı bir xəta baş verdi!");
      }
    } catch (error) {
      setLoading(false);
      console.log("error: ", error);
    }
  };

  const _saveUploadLogo = async (e) => {
    try {
      setLoading(true);
      const _id = selectedStructureId;
      const { target } = e;
      const file = target.files[0];
      const response = await companyStructureServices.saveUpdateLogo(_id, file);
      if (response && response.logo) {
        toast.success("Struktur loqosu uğurla yeniləndi!");
        fetchMoreCompanyStructures();
      } else {
        toast.error("Hər hansı bir xəta baş verdi!");
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  const headerData = {
    title: "Təşkilatı struktur",
    variant: "h3",
  };

  return (
    <>
      <ToastContainer />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid className="ps-4">
          <Header data={headerData} />
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenModal(true)}
          >
            <AddIcon />
            Struktur yarat
          </Button>
          <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Struktur yarat</DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.mb_2}>
                Zəhmət olmasa əlavə etmək istədiyiniz strukturun sayını boş
                xanaya daxil edin
              </DialogContentText>
              <TextField
                margin="dense"
                id="count"
                name="count"
                label={"Struktur sayı"}
                type="number"
                className={classes.input}
                onBlur={formik.handleBlur}
                onChange={(e) =>
                  formik.setFieldValue(
                    "count",
                    /^[^.,]*$/.test(e.target.value) && e.target.value
                  )
                }
                value={formik.values.count}
                error={Boolean(formik.touched?.count && formik.errors?.count)}
                helperText={formik.touched?.count && formik.errors?.count}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button type="button" onClick={handleClose} color="primary">
                Ləğv et
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={!formik.dirty || !formik.isValid}
                onClick={handleSubmit}
              >
                Əlavə et
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>

      {/* <Divider my={6} /> */}

      <Grid container spacing={3} className="mt-3">
        <Grid
          xs={12}
          mb={6}
          // style={{
          //   height: structureDatas.structures.length > 8 ? "625px" : "unset",
          //   overflowY:
          //     structureDatas.structures.length > 8 ? "scroll" : "unset",
          // }}
        >
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              my={6}
              style={{ height: "45vh" }}
            >
              <CircularProgress />
            </Box>
          ) : structureDatas.structures.length > 0 ? (
            <CardList
              setLoading={setLoading}
              data={structureDatas.structures}
              totalCount={structureDatas.total}
              isDeleted={isUpdated}
              structureId={structureId}
              setIsDeleted={setIsUpdated}
              saveCompanyPartsToStructure={_saveCompanyPartsToStructure}
              handleUploadLogo={_saveUploadLogo}
              fetchMoreCompanyStructures={fetchMoreCompanyStructures}
            />
          ) : (
            <Card>
              <CardContent>
                <Typography variant="h3" textAlign="center">
                  Hər hansı bir məlumat tapılmadı və ya xəta baş verdi
                </Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyStructure;
