import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCommonData } from "../../../../api/api";
import { Field } from "formik";

const Administrative = ({ values, oldContract, setFieldValue }) => {
  const [staffs, setStaffs] = useState([]);

  async function getStaffsData() {
    try {
      const res = await getCommonData("recruiterList");
      setStaffs(res?.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getStaffsData();
  }, []);

  return (
    <>
      {oldContract ? (
        <CustomAccordion sx={{ width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h6>Vəzifəli şəxsin məlumatları</h6>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel>Vəzifəli şəxsin tam adı</InputLabel>
                <Field
                  as={Select}
                  label="Vəzifəli şəxsin tam adı"
                  defaultValue=""
                  fullWidth
                  name="administrative.oldRecruiterId"
                  onChange={(event) => {
                    setFieldValue(
                      "administrative.oldRecruiterId",
                      event.target.value
                    );
                  }}
                >
                  {staffs &&
                    staffs.map((staff, index) => {
                      return (
                        <MenuItem
                          key={`${staff?.employee}${index}`}
                          value={staff?.employee}
                        >{`${staff?.name} ${staff?.lastname}`}</MenuItem>
                      );
                    })}
                </Field>
              </FormControl>
            </Grid>
          </AccordionDetails>
        </CustomAccordion>
      ) : null}
    </>
  );
};

export default Administrative;
