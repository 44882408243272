import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import DataTableComponent from "../../components/operations/DataTable";
import {
  deleteCompanyPartsById,
  deleteStructureNaming,
  getCompanyParts,
  getStructureNaming,
  postStructureNaming,
  updateCompanyParts,
} from "../../api/api";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import StructureEditDialog from "../../components/operations/structureNaming/StructureEditDialog";
import StructureDeleteDialog from "../../components/operations/structureNaming/StructureDeleteDialog";
import StructureAddDialog from "../../components/operations/structureNaming/StructureAddDialog";

import EditIcon from "@mui/icons-material/Edit";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { getDesigns } from "../../api/tsDesignRequests";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function DataTable() {
  const [selectedButton, setSelectedButton] = useState(0);
  const [companyData, setCompanyData] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [submitted, setSubmitted] = useState(false);

  // Structure details
  const [openStructureEdit, setOpenStructureEdit] = useState(false);
  const [openStructureAdd, setOpenStructureAdd] = useState(false);
  const [openStructureDelete, setOpenStructureDelete] = useState(false);
  const [structureId, setStructureId] = useState("");
  const [loadingModal, setLoadingModal] = useState(true);
  const [designs, setDesigns] = useState([]);

  // First structure
  useEffect(() => {
    const getFirstStructureName = async () => {
      try {
        const response = await getStructureNaming();
        if (response && response.data) {
          setSelectedType(response.data[0]);
        }
      } catch (err) {
        console.log("error: ", err);
      }
    };
    getFirstStructureName();
  }, []);

  //Get Structure Naming
  useEffect(() => {
    const getStructureNames = async () => {
      try {
        const response = await getStructureNaming();
        if (response && response.data) {
          const filteredData = response.data.filter(
            (item) => item.name !== "management"
          );
          setDataTable(filteredData);
        }
      } catch (err) {
        console.log("error: ", err);
      }
    };
    getStructureNames();
  }, [submitted]);

  //   Selected company part
  useEffect(() => {
    const selectedItem = dataTable.find((item, index) => {
      if (index === selectedButton) {
        return item;
      }
    });
    if (selectedItem) {
      setSelectedType(selectedItem);
    }
  }, [submitted, selectedButton]);

  //   Get all company parts for type
  useEffect(() => {
    const getCompany = async () => {
      if (selectedType) {
        setLoadingModal(true);
        try {
          const response = await getCompanyParts(selectedType._id);
          if (response && response.data) {
            setCompanyData(response.data.parts);
          }
        } catch (err) {
          console.log("error: ", err);
        } finally {
          setLoadingModal(false);
        }
      }
    };
    getCompany();
  }, [submitted, selectedType]);

  //   Get all structure levels for type
  async function fetchDesigns() {
    let response = await getDesigns();
    let records = response?.data?.records?.map((r) => {
      if (!r.style) {
        r.style = {
          border: {
            width: "",
            color: "",
          },
          backgroundColor: "",
          font: {
            name: "",
            size: "",
          },
        };
      }
      return r;
    });
    setDesigns(records);
  }
  useEffect(() => {
    fetchDesigns();
  }, []);

  const handleChange = (e, index) => {
    setSelectedButton(index);
  };

  //   Add new element to company parts
  const handleAddNewData = async (name, description, type) => {
    await updateCompanyParts(name, description, type)
      .then((response) => {
        setSubmitted(!submitted);
        return response;
      })
      .catch((err) => console.log(err));
  };

  //   Delete company parts by id
  const handleDeleteData = async (id) => {
    await deleteCompanyPartsById(id);
    setSubmitted(!submitted);
  };

  // Add structure name
  const handlePostStructure = async (name, level) => {
    await postStructureNaming(name, level)
      .then((response) => {
        setSubmitted(!submitted);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Handle Structure name delete
  const handleStructureDelete = async (id) => {
    await deleteStructureNaming(id);
    setSubmitted(!submitted);
    setSelectedButton(0);
    setSelectedType(dataTable[0]);
  };
  return (
    <>
      {/* Naming button group */}
      <Card mb={6}>
        <ButtonGroup
          aria-label="outlined primary button group"
          fullWidth
          sx={{ mb: 6 }}
        >
          <Grid container>
            {dataTable.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <Button
                  onClick={(e) => handleChange(e, index)}
                  variant={selectedButton === index ? "contained" : "text"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "40px",
                    paddingInline: "16px",
                  }}
                >
                  {item.name === "organization"
                    ? "Təşkilatlar"
                    : item.name === "position"
                    ? "Vəzifələr"
                    : item.name === "management"
                    ? null
                    : `${item.name} ${
                        item?.level?.label ? ` - ${item?.level?.label}` : ""
                      }`}
                  {item.name === "organization" ||
                  item.name === "position" ||
                  item.name === "management" ? null : (
                    <Box sx={{ display: "flex" }}>
                      <Box>
                        <IconButton size="small">
                          <EditIcon
                            fontSize="14px"
                            onClick={() => {
                              setOpenStructureEdit(true);
                              setStructureId(item._id);
                            }}
                          />
                        </IconButton>
                        <StructureEditDialog
                          structureId={structureId}
                          setStructureId={setStructureId}
                          dataTable={item}
                          openStructureEdit={openStructureEdit}
                          setOpenStructureEdit={setOpenStructureEdit}
                          submitted={submitted}
                          setSubmitted={setSubmitted}
                          designs={designs}
                          fetchDesigns={fetchDesigns}
                        />
                      </Box>
                      <Box>
                        <IconButton size="small">
                          <DeleteIcon
                            fontSize="14px"
                            color="error"
                            onClick={() => {
                              setOpenStructureDelete(true);
                              setStructureId(item._id);
                            }}
                          />
                        </IconButton>
                        <StructureDeleteDialog
                          submitted={submitted}
                          setSubmitted={setSubmitted}
                          openStructureDelete={openStructureDelete}
                          setOpenStructureDelete={setOpenStructureDelete}
                          structureId={structureId}
                          setStructureId={setStructureId}
                          dataTable={item}
                          handleStructureDelete={handleStructureDelete}
                        />
                      </Box>
                    </Box>
                  )}
                </Button>
              </Grid>
            ))}
          </Grid>
        </ButtonGroup>
        <Grid container>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
              endIcon={<AddCircleIcon />}
              onClick={() => setOpenStructureAdd(true)}
            >
              <Typography noWrap>Əlavə et</Typography>
            </Button>
          </Grid>
        </Grid>
        <StructureAddDialog
          setOpenStructureAdd={setOpenStructureAdd}
          openStructureAdd={openStructureAdd}
          handlePostStructure={handlePostStructure}
          designs={designs}
          fetchDesigns={fetchDesigns}
        />
      </Card>

      {/* Data table */}
      <Card>
        <Grid px={5}>
          {selectedType ? (
            <DataTableComponent
              selectedType={selectedType}
              tableHeader={{
                structure: "",
                description: "adlandırmaları",
              }}
              companyData={companyData}
              handleAddNewData={handleAddNewData}
              handleDeleteData={handleDeleteData}
              submitted={submitted}
              setSubmitted={setSubmitted}
            />
          ) : (
            <>Loading...</>
          )}
        </Grid>
      </Card>

      {/* <LoadingWithProps loadingModal={loadingModal} /> */}
    </>
  );
}

const Blank = () => (
  <React.Fragment>
    <ToastContainer />

    <Helmet title="Blank" />
    <Typography variant="h3" gutterBottom display="inline">
      Struktur adlandırmaları
    </Typography>

    <Divider my={6} />

    <Grid container spacing={6}>
      <Grid item xs={12}>
        <DataTable />
      </Grid>
    </Grid>
  </React.Fragment>
);

export default Blank;
