import React, { createContext, useContext, useEffect, useState } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [openItem, setOpenItem] = useState(() => {
    const savedState = sessionStorage.getItem("openItem");
    return savedState ? JSON.parse(savedState) : [];
  });

  useEffect(() => {
    sessionStorage.setItem("openItem", JSON.stringify(openItem));
  }, [openItem]);

  const toggleItem = (key) => {
    setOpenItem((prev = []) => {
      if (Array.isArray(key)) {
        let updatedItems = [...prev];
        key.forEach((item) => {
          if (updatedItems.includes(item)) {
            updatedItems = updatedItems.filter((i) => i !== item);
          } else {
            updatedItems.push(item);
          }
        });
        return updatedItems;
      } else {
        return prev?.includes(key)
          ? prev?.filter((item) => item !== key)
          : [...prev, key];
      }
    });
  };

  return (
    <SidebarContext.Provider value={{ openItem, toggleItem, setOpenItem }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);
