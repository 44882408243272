import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

import { BASE_URL } from "../constants";
import axios from "../utils/axios";
import {
  getRefreshToken,
  getSession,
  isValidRefreshToken,
  isValidToken,
  setRefreshToken,
  setSession,
} from "../utils/jwt";
import { useSidebar } from "./SidebarContext";
import { api } from "../api/axiosInterceptor";
import {
  deleteTokensStorage,
  getAccessToken,
  getRefreshTokenStorage,
} from "../utils/token";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAccessToken,
  deleteRefreshToken,
} from "../redux/features/slices/Auth.slice";

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.js` file.

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const CHECK_AUTH = "CHECK_AUTH";
const RFR_TOKEN = "RFR_TOKEN";
const AUTHENTICATED = "AUTHENTICATED";
const baseUrl = BASE_URL;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case CHECK_AUTH:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case RFR_TOKEN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const navigation = useNavigate();
  const { setOpenItem } = useSidebar();

  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [token, setToken] = useState({
    accessToken: "",
    refreshToken: "",
  });
  const initialize = useCallback(async () => {
    try {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (
        (accessToken && isValidToken(accessToken)) ||
        (refreshToken && isValidRefreshToken(refreshToken))
      ) {
        setSession(accessToken);
        setRefreshToken(refreshToken);
        setToken({ accessToken, refreshToken });

        const response = await axios.get(baseUrl + "/users/currentUser");

        const { user } = response.data;

        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, []);

  const signIn = async (email, password, remember) => {
    const response = await axios
      .post(baseUrl + "/users/signin", {
        email,
        password,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
    const { refreshToken, token, user } = response.data;

    // token gelibse sorghu at simani setle redux-a

    if (token) {
      const response = await api.get("/employee/common/app-settings");
      const sima = response.data.sima_enabled;
      localStorage.setItem("sima", sima);
    }

    setSession(token, remember);
    setRefreshToken(refreshToken, remember);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
    window.dispatchEvent(new Event("myCustomEvent"));
  };

  const signOut = () => {
    setSession(null);
    setRefreshToken(null);
    dispatch({
      type: SIGN_OUT,
    });

    deleteTokensStorage(() => setOpenItem([]));
    navigation("/auth/sign-in");
    window.dispatchEvent(new Event("myCustomEvent"));
  };

  const signUp = async (email, password, username) => {
    const response = await axios.post(baseUrl + "users/signup", {
      email,
      password,
      username: username,
    });
    const { refreshToken, token, user } = response.data;

    setSession(token);
    setRefreshToken(refreshToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const getOtp = (email) => console.log(email);

  const checkSessionIsValid = async () => {
    const accessToken = getSession();
    const refreshToken = getRefreshToken();
    if (!accessToken || !refreshToken) {
      return false;
    }

    const isValid = isValidToken(accessToken);
    const isValidRefresh = isValidRefreshToken(refreshToken);

    if (!isValid || !isValidRefresh) {
      return false;
    }

    const currentUserRes = await (
      await fetch(baseUrl + "/users/currentUser", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
          RefreshToken: refreshToken,
        },
      })
    ).json();
    if (
      currentUserRes.errors &&
      currentUserRes.errors[0].message.message === "jwt expired"
    ) {
      const refreshTokenRes = await (
        await fetch(baseUrl + "users/refresh", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + accessToken,
            RefreshToken: refreshToken,
          },
        })
      ).json();
      if (refreshTokenRes.errors) {
        return false;
      }
      const newAccessToken = refreshTokenRes.accessToken;
      setSession(newAccessToken);
      return true;
    } else if (currentUserRes.errors) {
      return false;
    }
    dispatch({
      type: AUTHENTICATED,
      payload: {
        isAuthenticated: true,
      },
    });

    return true;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        getOtp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
