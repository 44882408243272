import { CompareArrows, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { formatDateTime } from "../../../../utils/helperFunctions";
import {
  GROUP_NAME,
  LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS,
} from "../dialogConstants";
import FieldItem from "./parts/FieldItem";
import { isArray } from "lodash";
import { translationMap } from "./../helpers";
import moment from "moment";

export default function DialogLeftCont({
  contractType,
  fieldsData,
  isGroup = false,
  mode = "noneditable",
  inputProps,
}) {
  const renderFields = React.useCallback(
    (shouldShowUpdateData = false) => {
      if (!fieldsData) return;

      return isGroup ? handlerOnGroup(shouldShowUpdateData) : handlerOnSingle();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldsData, isGroup]
  );
  const specialFieldTypeObject = (field) => {
    const { value } = field;
    switch (field.key) {
      case "workingCondition":
        return `${value.parent} - ${value.name}`;
      default:
        const type = translationMap[value.type] || value.type;
        return value.value + " " + type;
    }
  };

  const defineValueType = (value) => {
    if (Array.isArray(value)) return "array";
    return typeof value;
  };

  // const updateRenderField = (item, index, dataVersionIdentifier) => {
  //   if (!item || item.isHide) return;
  //   let value;
  //   const { label = "__", type = defineValueType(item.value) } = item;
  //   if (type === "array") {
  //     const isStringArray =
  //       Array.isArray(item.value) &&
  //       item.value.every((val) => typeof val === "string");

  //     if (isStringArray) {
  //       value = item.value.join(", ");
  //     } else {
  //       value = item?.value[index]?.staff?.employee
  //         .map((emp) => `${emp.lastname} ${emp.firstname} ${emp.fathername}`)
  //         .filter(Boolean)
  //         .join(" -> ");
  //     }
  //   } else {
  //     switch (item?.type) {
  //       case "date":
  //         value = moment(item?.value)?.format("DD/MM/YYYY");
  //         // fieldValue = item.value ? formatDateTime(item.value) : "";
  //         break;
  //       case "object":
  //         value =
  //           dataVersionIdentifier === "old"
  //             ? item?.value?.old
  //             : dataVersionIdentifier === "new"
  //             ? item?.value?.new
  //             : item?.value;
  //         if (item?.key === "wageBonus") {
  //           if (dataVersionIdentifier === "old") {
  //             value = `${item?.value?.old?.value} ${
  //               wageBonus[item?.value?.old?.type] // backend_instance = item: {value: {old: {type: "manat": value: "..."}}, new: {type: "manat": value: "..."}}}
  //             }`;
  //           } else if (dataVersionIdentifier === "new") {
  //             value = `${item?.value?.new?.value} ${
  //               wageBonus[item?.value?.new?.type] // backend_instance = item: {value: {old: {type: "manat": value: "..."}}, new: {type: "manat": value: "..."}}}
  //             }`;
  //           } else {
  //             value = `${item?.value?.value} ${
  //               wageBonus[item?.value.type] // backend_instance = item: {value: {type: "manat": value: "..."}}
  //             }`;
  //           }
  //         }
  //         if (item?.key === "probationPeriod") {
  //           if (dataVersionIdentifier === "old") {
  //             value = `${item?.value?.old?.value} ${
  //               probationPeriod[item?.value?.old?.type] // backend_instance = item: {value: {old: {type: "day": value: "..."}}, new: {type: "day": value: "..."}}}
  //             }`;
  //           } else if (dataVersionIdentifier === "new") {
  //             value = `${item?.value?.new?.value} ${
  //               probationPeriod[item?.value?.new?.type] // backend_instance = item: {value: {old: {type: "day": value: "..."}}, new: {type: "day": value: "..."}}}
  //             }`;
  //           } else {
  //             value = `${item?.value?.value} ${
  //               probationPeriod[item?.value.type] // backend_instance = item: {value: {type: "day": value: "..."}}
  //             }`;
  //           }
  //         }
  //         if (item?.key === "workingCondition") {
  //           value =
  //             dataVersionIdentifier === "old"
  //               ? item?.value?.old?.name
  //               : dataVersionIdentifier === "new"
  //               ? item?.value?.new?.name
  //               : item?.value?.name;
  //         }
  //         break;
  //       case "number":
  //         if (item?.key === "probationRefusalTime") {
  //           value = `${item?.value?.value} ${
  //             item?.value.type === "day"
  //               ? "gün"
  //               : item?.value.type === "month"
  //               ? "ay"
  //               : "il"
  //           }`;
  //         } else {
  //           value =
  //             dataVersionIdentifier === "old"
  //               ? item?.value?.old
  //               : dataVersionIdentifier === "new"
  //               ? item?.value?.new
  //               : item?.value;
  //           if (item?.key === "wage") {
  //             value = `${value} azn`;
  //           }
  //         }
  //         break;
  //       default:
  //         value =
  //           // dataVersionIdentifier === "old"
  //           item?.value?.old;
  //         // : dataVersionIdentifier === "new"
  //         // ? item?.value?.new
  //         // : item?.value;
  //         break;
  //     }

  //     // if (type === "number" || type === "string") {
  //     //   let actualValue = item.value;
  //     //   if (typeof actualValue === "object") {
  //     //     const type = translationMap[actualValue.type] || actualValue.type;
  //     //     actualValue = actualValue.value + " " + type;
  //     //   }
  //     //   actualValue = translationMap[actualValue] || actualValue;
  //     //   return (
  //     //     <FieldItem key={index} label={label} value={actualValue} largerText />
  //     //   );
  //     // }
  //     let v = translationMap[value] ? translationMap[value] : value;
  //     return !value || value?.length === 0
  //       ? "---"
  //       : Array.isArray(value)
  //       ? value.join(",")
  //       : v;
  //   }

  //   return <FieldItem key={index} label={label} value={value} />;
  // };

  function renderValue_(item, dataVersionIdentifier) {
    let value;
    if (item?.value === "") return "---";
    if (item?.key === "verifiers") {
      if (item?.value?.some((item) => item?.date)) {
        value = moment(item?.date)?.format("DD/mm/yyyy");
      } else {
        value = "---";
      }
    }

    if (Array.isArray(item?.value)) {
      if (item?.key !== "verifiers") {
        value = item?.value?.map((item) => {
          if (typeof item === "object") {
            return item?.value;
          } else {
            return item;
          }
        });
      }
    } else {
      switch (item?.type) {
        case "date":
          value = moment(item?.value)?.format("DD/MM/YYYY");
          break;
        case "object":
          value =
            dataVersionIdentifier === "old"
              ? item?.value?.old
              : dataVersionIdentifier === "new"
              ? item?.value?.new
              : item?.value;
          if (item?.key === "wageBonus") {
            if (dataVersionIdentifier === "old") {
              value = `${item?.value?.old?.value} ${
                wageBonus[item?.value?.old?.type] // backend_instance = item: {value: {old: {type: "manat": value: "..."}}, new: {type: "manat": value: "..."}}}
              }`;
            } else if (dataVersionIdentifier === "new") {
              value = `${item?.value?.new?.value} ${
                wageBonus[item?.value?.new?.type] // backend_instance = item: {value: {old: {type: "manat": value: "..."}}, new: {type: "manat": value: "..."}}}
              }`;
            } else {
              value = `${item?.value?.value} ${
                wageBonus[item?.value.type] // backend_instance = item: {value: {type: "manat": value: "..."}}
              }`;
            }
          }
          if (item?.key === "probationPeriod") {
            if (dataVersionIdentifier === "old") {
              value = `${item?.value?.old?.value} ${
                probationPeriod[item?.value?.old?.type] // backend_instance = item: {value: {old: {type: "day": value: "..."}}, new: {type: "day": value: "..."}}}
              }`;
            } else if (dataVersionIdentifier === "new") {
              value = `${item?.value?.new?.value} ${
                probationPeriod[item?.value?.new?.type] // backend_instance = item: {value: {old: {type: "day": value: "..."}}, new: {type: "day": value: "..."}}}
              }`;
            } else {
              value = `${item?.value?.value} ${
                probationPeriod[item?.value.type] // backend_instance = item: {value: {type: "day": value: "..."}}
              }`;
            }
          }
          if (item?.key === "workingCondition") {
            value =
              dataVersionIdentifier === "old"
                ? item?.value?.old?.name
                : dataVersionIdentifier === "new"
                ? item?.value?.new?.name
                : item?.value?.name;
          }
          break;
        case "number":
          if (item?.key === "probationRefusalTime") {
            value = `${item?.value?.value} ${
              item?.value.type === "day"
                ? "gün"
                : item?.value.type === "month"
                ? "ay"
                : "il"
            }`;
          } else {
            value =
              dataVersionIdentifier === "old"
                ? item?.value?.old
                : dataVersionIdentifier === "new"
                ? item?.value?.new
                : item?.value;
            if (item?.key === "wage") {
              value = `${value} azn`;
            }
          }
          break;
        default:
          value =
            dataVersionIdentifier === "old"
              ? item?.value?.old
              : dataVersionIdentifier === "new"
              ? item?.value?.new
              : item?.value;
          break;
      }
    }
    let v = translationMap[value] ? translationMap[value] : value;
    return !value || value?.length === 0
      ? "---"
      : Array.isArray(value)
      ? value.join(",")
      : v;
  }

  const handlerOnGroup = (shouldShowUpdateData) => {
    const uiParts = [];
    const data = { ...fieldsData };
    let updateKeys;
    // if (data.updates) {
    //   updateKeys = Object.keys(data.updates);
    //   for (let element of updateKeys) {
    //     data[element] = data.updates[element];
    //   }
    // }
    // for (const key in data) {
    //   const group = data[key];
    //   const utils =
    //     LABOUR_CONTRACTS_CUSTOM_RENDERS_METHODS[contractType]?.[key];
    //   uiParts.push(
    //     <Accordion sx={{ backgroundColor: "rgba(0,0,0,0.1)" }} key={key}>
    //       <AccordionSummary
    //         expandIcon={<ExpandMore />}
    //         aria-controls="panel1a-content"
    //         sx={{ paddingInline: "16px" }}
    //       >
    //         <Typography
    //           sx={{
    //             fontWeight: "bold",
    //             fontSize: "16px",
    //             color: "rgba(200,200,200,1)",
    //           }}
    //         >
    //           {GROUP_NAME[key]}
    //         </Typography>
    //       </AccordionSummary>
    //       <AccordionDetails sx={{ marginX: "10px" }}>
    //         {isArray(group) &&
    //           group.map((item, index) => {
    //             return (
    //               <div>
    //                 <Typography
    //                   gutterBottom
    //                   sx={{
    //                     color: "text.secondary",
    //                     fontSize: 14,
    //                   }}
    //                 >
    //                   {item?.label}
    //                 </Typography>
    //                 <Typography variant="h6" component="div">
    //                   {renderValue_(item, "old")} <CompareArrows />{" "}
    //                   {renderValue_(item, "new")}
    //                 </Typography>
    //                 <hr />
    //               </div>
    //             );
    //           })}
    //         {/* {isArray(group) &&
    //           group.map((item, index) => {
    //             return updateRenderField(
    //               {
    //                 ...item,
    //                 renderValue: utils && utils[item.key],
    //                 group,
    //                 key: item.key,
    //               },
    //               index,
    //               shouldShowUpdateData
    //             );
    //           })} */}
    //       </AccordionDetails>
    //     </Accordion>
    //   );
    // }

    // if (shouldShowUpdateData) {
    //   const renderArray = uiParts.filter((element) =>
    //     updateKeys?.includes(element.key)
    //   );
    //   return [...renderArray];
    // } else {
    //   const renderArray = uiParts
    //     .filter((element) => !updateKeys?.includes(element.key))
    //     .filter((element) => !["updates"].includes(element.key));
    //   console.log(renderArray);
    //   return [...renderArray];
    // }
    return Object.keys(data)?.map((key) => {
      if (key === "updates") {
        return (
          <>
            <div className="text-center mt-4 mb-2" style={{ fontSize: "16px" }}>
              Dəyişikliklər
            </div>
            {Object.keys(data[key])?.map((k) => (
              <Accordion
                sx={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                // key={key}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "rgba(200,200,200,1)",
                    }}
                  >
                    {GROUP_NAME[k]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ marginX: "10px" }}>
                  {data[key][k]?.map((item) => (
                    <div>
                      <Typography
                        gutterBottom
                        sx={{
                          color: "text.secondary",
                          fontSize: 14,
                        }}
                      >
                        {item?.label}
                      </Typography>
                      <Typography variant="h6" component="div">
                        {renderValue_(item, "old")} <CompareArrows />{" "}
                        {renderValue_(item, "new")}
                      </Typography>
                      <hr />
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        );
      } else {
        return (
          <Accordion
            sx={{ backgroundColor: "rgba(0,0,0,0.3)" }}
            // key={key}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: "rgba(200,200,200,1)",
                }}
              >
                {GROUP_NAME[key]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ marginX: "10px" }}>
              {key !== "updates" &&
                data[key]?.map((item) => (
                  <div>
                    <Typography
                      gutterBottom
                      sx={{
                        color: "text.secondary",
                        fontSize: 14,
                      }}
                    >
                      {item?.label}
                    </Typography>
                    <Typography variant="h6" component="div">
                      {renderValue_(item)}
                    </Typography>
                    <hr />
                  </div>
                ))}
            </AccordionDetails>
          </Accordion>
        );
      }
    });
  };
  const handlerOnSingle = () => {
    return fieldsData?.map((item, index) => {
      if (Array.isArray(item)) {
        return item.map((itm) => {
          return renderField(itm, `${itm.label}/${index}`);
        });
      }
      return renderField(item, `${item[index].label}/${index}`);
    });
  };

  const renderField = (field, index) => {
    if (field.isHide) return;
    const { label = "__" } = field;
    const value = renderValue(field);
    return (
      <FieldItem
        key={index}
        label={label}
        value={value}
        mode={mode}
        group={{ [field.key]: field.group }}
        inputProps={inputProps}
      />
    );
  };

  const renderValue = (field) => {
    if (!field.value) return "-";
    if (field.renderValue) {
      const render = field.renderValue;
      return render(field.value);
    }
    switch (field.type) {
      case "string":
        return field.value;
      case "date":
        if (!field.value) return "__";
        return formatDateTime(field.value);
      case "array":
        return "array";
      case "object":
        return "object";
      default:
        return field.value;
    }
  };
  return (
    <Grid sx={styles.container}>
      <Grid>{renderFields()}</Grid>
      {/* {fieldsData?.updates && (
        <>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <p style={{ textAlign: "center", width: "100%", fontSize: 18 }}>
              Dəyişikliklər
            </p>
          </div>
          <Grid>{renderFields(true)}</Grid>
        </>
      )} */}
    </Grid>
  );
}

const styles = {
  container: {
    width: "100%",
    height: "100%",
    paddingX: "10px",
    overflow: "auto",
  },
};

const wageBonus = {
  manat: "azn",
  perctentage: "faiz",
};

const probationPeriod = {
  month: "ay",
  day: "gün",
  year: "il",
  none: "yoxdur",
};
