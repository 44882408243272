import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

const AddChecklist = ({
  checklistDialog,
  setChecklistDialog,
  handleAddChecklist,
  cardId,
}) => {
  const [checklistName, setChecklistName] = useState("");

  return (
    <>
      <Dialog
        fullWidth
        open={checklistDialog}
        onClose={() => {
          setChecklistDialog(false);
          setChecklistName("");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Yoxlama siyahısı əlavə edin</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Yoxlama siyahısı adı"
            type="text"
            value={checklistName}
            onChange={(e) => setChecklistName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setChecklistDialog(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setChecklistDialog(false);
              handleAddChecklist(checklistName, cardId);
            }}
            color="primary"
            disabled={checklistName.trim() !== "" ? false : true}
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddChecklist;
