export const styles = {
  container: {
    // border: 1,
    width: "100%",
    padding: 2,
    position: "relative",
    marginBottom: "10px",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 1,
    padding: 0.5,
    alignItems: "center",
    width: "100%",
  },
  fieldNameText: {
    fontWeight: "600",
    color: "#ddd",
    display: "flex",
    flexDirection: "row",
  },
  fieldValueText: {
    fontWeight: "400",
    color: "#ddd",
    position: "relative",
    left: "5px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: 1,
  },
};
