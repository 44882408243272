import { api } from "../../api/axiosInterceptor";

export const getDownloadTimesheet = async ({
  columns,
  filter = undefined,
  month,
  year,
  structureId,
}) => {
  const url = `employee/time-table/download-excel?filter=${filter}&month=${month}&year=${year}&structure=${structureId}`;

  return await api.get(url, { responseType: "blob" });
};
