import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const DeleteDataDialog = ({
  openDeleteDialog,
  setOpenDeleteDialog,
  selectedType,
  setRowId,
  rowId,
  row,
  handleDeleteData,
}) => {
  return (
    <>
      {rowId === row.id ? (
        <Dialog
          open={openDeleteDialog}
          onClose={() => {
            setOpenDeleteDialog(false);
            setRowId();
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Silmək istədiyinizdən əminsiniz ?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDeleteDialog(false);
                setRowId();
              }}
              color="primary"
            >
              Ləğv et
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setOpenDeleteDialog(false);
                handleDeleteData(rowId);
              }}
            >
              Bəli
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default DeleteDataDialog;
