import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
// React Router Dom
import { NavLink } from "react-router-dom";
// Material UI
import { Link, Breadcrumbs as MuiBreadcrumbs, Typography } from "@mui/material";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Breadcrumb = ({ routesData }) => {
  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      {routesData.length > 0
        ? routesData.map((route, index) => (
            <div key={index + 1}>
              {route?.isLink ? (
                <Link component={NavLink} to={route?.url}>
                  {route?.name}
                </Link>
              ) : (
                <Typography>{route?.name}</Typography>
              )}
            </div>
          ))
        : null}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
