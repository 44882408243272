// React
import { useEffect, useState } from "react";

// Material UI
import { Grid } from "@mui/material";

import Table from "../../../components/table/Table";
import Column from "../../../models/table/Column";
import { useSelector } from "react-redux";

export default function MCTransportationTable() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [rowData, setRowData] = useState([]);
  const [filters, setFilters] = useState([]);
  const { currentEmployee } = useSelector((state) => state.commonThings);

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };

  useEffect(() => {
    if (currentEmployee?.transportVehicles?.length) {
      setRowData(
        currentEmployee?.transportVehicles?.map((item, index) => {
          return {
            id: item.registrationPlate,
            brand: item.brand,
            model: item.name,
            transporttype: item.transportationType,
            registrationcertificate: item.registrationNumber,
            registrationmark: item.registrationPlate,
            year: item.year,
            enginecapacity: item.engineCapacity,
            gearbox: item.transmissionType,
          };
        })
      );
    }
  }, [currentEmployee?.transportVehicles]);

  const columns = [
    new Column({
      path: "brand",
      headerName: "Marka",
    }),
    new Column({
      path: "name",
      headerName: "Model",
    }),
    new Column({
      path: "transportationType",
      headerName: "Nəqliyyat Növü",
    }),
    new Column({
      path: "registrationNumber",
      headerName: "N.V. Qeydiyyat Şəhadətnaməsi No.",
    }),
    new Column({
      path: "registrationPlate",
      headerName: "N.V. Qeydiyyat Nişanı",
    }),
    new Column({
      path: "year",
      headerName: "Buraxılış ili",
    }),
    new Column({
      path: "engineCapacity",
      headerName: "Mühərrik həcmi",
    }),
    new Column({
      path: "transmissionType",
      headerName: "Sürətlər qutusu",
    }),
  ];

  return (
    <>
      {currentEmployee?.transportVehicles?.length !== 0 ? (
        <Grid sx={{ width: "100%" }}>
          <Table
            columns={columns}
            data={currentEmployee?.transportVehicles}
            totalRows={currentEmployee?.transportVehicles?.length}
            offset={offset}
            setOffset={(value) => setOffset(value)}
            limit={limit}
            setLimit={(value) => setLimit(value)}
            applyFiltersHandler={applyFiltersHandler}
            cancelAppliedFilters={cancelAppliedFiltersHandler}
          />
        </Grid>
      ) : (
        <Grid
          sx={{
            width: "100%",
            fontSize: "40px",
            fontWeight: 600,
            textAlign: "center",
            marginTop: "20px",
            backgroundColor: "#233044",
            borderRadius: "10px",
          }}
        >
          Heç bir məlumat tapılmadı
        </Grid>
      )}
    </>
  );
}
