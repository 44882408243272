import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getTopParts } from "../../api/api";
import { getFitlersData } from "../../services/filter/filterService";

const FindSubstructure = (props) => {
  const {
    structureDialog,
    setStructureDialog,
    selectedStructure,
    setSelectedStructure,
    setStructureNames,
    structureNames,
    pageKey,
    submitted,
  } = props;

  const [topParts, setTopParts] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetching = async () => {
    try {
      setIsLoading(true);
      const resp = await getFitlersData({
        dataGridName: pageKey,
        subStructureName: searchInput,
      });
      const modified =
        resp?.data &&
        resp?.data?.subStructures?.map((item) => ({
          ...item,
          id: item.ids[0],
        }));
      resp && setTopParts(modified);
    } catch (error) {
      console.log("err", error);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleTopParts = async () => {
  //   try {
  //     const response = await getTopParts(partRelsPayload);
  //     setTopParts(response.data.parts);
  //     setTotalParts(response.data.total);
  //   } catch (error) {
  //     console.log("err", error);
  //   }
  // };

  useEffect(() => {
    // handleTopParts();
    fetching();
  }, [searchInput]);

  const handleToggle = (item) => () => {
    const name = item.name;
    const value = item.id;
    const currentIndex = selectedStructure.indexOf(value);
    const newChecked = [...selectedStructure];
    const newCheckedName = [...structureNames];

    if (currentIndex === -1) {
      newChecked.push(value);
      newCheckedName.push(name);
    } else {
      newChecked.splice(currentIndex, 1);
      newCheckedName.splice(currentIndex, 1);
    }

    setSelectedStructure(newChecked);
    setStructureNames(newCheckedName);
  };

  return (
    <>
      <Dialog
        fullWidth
        open={structureDialog}
        onClose={() => {
          setStructureDialog(false);
          setSelectedStructure([]);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" mb={-3}>
          <Typography variant="h4" mb={4}>
            Sub-struktur seçin
          </Typography>
        </DialogTitle>
        <>
          <DialogContent>
            {/* User Search */}
            <Box my={3}>
              <TextField
                label="Struktur axtar"
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                variant="outlined"
                fullWidth
              />
            </Box>

            {/* User list */}

            <List
              dense
              fullWidth
              sx={{
                height: "40vh",
                bgcolor: "background.paper",
                overflow: "auto",
                mb: 3,
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                topParts?.map((item, index) => {
                  const labelId = `checkbox-list-secondary-label-${item?.name}`;
                  // if (item._id !== user.id) {
                  return (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          onChange={handleToggle(item)}
                          checked={selectedStructure.indexOf(item.id) !== -1}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemText id={item.id} primary={item?.name} />
                      </ListItemButton>
                    </ListItem>
                  );
                  //
                })
              )}
            </List>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setStructureDialog(false);
                setSelectedStructure([]);
                setStructureNames([]);
              }}
              color="primary"
            >
              Ləğv et
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setStructureDialog(false);
              }}
              disabled={selectedStructure.length === 0}
            >
              Seç
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default FindSubstructure;
