import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { parse } from "date-fns";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { useSelector } from "react-redux";
import {
  deleteVacation,
  updateVacation,
} from "../../../api/vacationRequests/vacationRequests";
import { createStaticDocument } from "../../../api/contractRequests/contractRequests";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { getActiveStaffs } from "../../../services/staff/StaffServices";

const UpdateVacationItem = (props) => {
  const { reasonArray, sourceOfVacationArray, vacation } = props;
  const [minEndDate, setMinEndDate] = useState(null);
  const [maxStartDate, setMaxStartDate] = useState(null);
  const [responseStatus, setResponseStatus] = useState();
  const [contractId, setContractId] = useState();
  const [progress, setProgress] = useState(0);
  const [activeEmps, setActiveEmps] = useState([]);

  const navigate = useNavigate();

  const { currentEmployee, allEmployees } = useSelector(
    (state) => state.commonThings
  );

  useEffect(() => {
    getActiveEmployees();
  }, []);

  const todayDate = new Date();
  const contractName = "vacation-application";

  const getActiveEmployees = async () => {
    try {
      const { data } = await getActiveStaffs();
      data && setActiveEmps(data.records);
    } catch (error) {
      console.log("err", error);
    }
  };

  // Create vacation request
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const modifiedValues = {
      ...values,
      contractId: contractId,
    };

    const response = await updateVacation(vacation._id, modifiedValues);
    setResponseStatus();
    setSubmitting(false);
    if (response && response.data) {
      setTimeout(() => {
        navigate("/emp-vacation-requests");
      }, 2000);
    }
  };

  // Create vacation contract
  const handleVacationContract = async (values) => {
    setProgress(0);

    const vacationContract = {
      docModel: "Employee",
      subjects: [currentEmployee?._id],
      data: {
        // orgName: "Blink BI",
        // directorName: "Emil Taciyev Azsd",
        // directorFatherRelation: "oglu",
        // positionName: currentEmployee?.position?.name || "",
        // employeeName: `${
        //   currentEmployee?.firstname + " " + currentEmployee?.lastname
        // }`,
        // employeeFatherRelation: "oglu",
        sourceOfVacation: values.sourceOfVacation,
        startDate: values.startDate,
        endDate: values.endDate,
        vacationType: values.vacationType,
        // vacationDays: 28,
        vacationCreatedDate: moment(todayDate).format("MM/DD/yyyy"),
      },
    };

    const response = await createStaticDocument(contractName, vacationContract);
    if (response) {
      setResponseStatus(response.status);
      setContractId(response.data._id);
    }
    if (response?.status === 201) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            clearInterval(timer);
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 200);
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      const willDelete = await swal({
        title: "Əminsiniz mi?",
        text: "Bu məlumatı silmək üçün əminsiniz mi?",
        icon: "warning",
        dangerMode: true,
        buttons: ["Ləğv et", "Sil"],
      });

      if (willDelete) {
        const response = await deleteVacation(id);
        navigate("/");

        if (response) {
          swal("Silindi!", "Seçdiyiniz məlumat uğurla silindi!", "success");
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          employeeId:
            vacation?.creator?.firstname + " " + vacation?.creator?.lastname,
          sourceOfVacation: vacation?.originalRequest?.sourceOfVacation,
          vacationType: vacation?.originalRequest?.vacationType,
          replacementStaffId: vacation?.originalRequest?.replacedBy._id,
          startDate: moment(vacation?.originalRequest?.startDate).format(
            "MM/DD/yyyy"
          ),
          endDate: moment(vacation?.originalRequest?.endDate).format(
            "MM/DD/yyyy"
          ),
          jobStartDate: moment(vacation?.originalRequest?.jobStartDate).format(
            "MM/DD/yyyy"
          ),
        }}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          isValid,
          touched,
          isSubmitting,
          setFieldValue,
          values,
          handleChange,
        }) => (
          <Form>
            <>
              {/* EmployeeId and Source of Vacation */}

              <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 3 }}>
                <Grid sx={{ width: "100%" }}>
                  <>
                    <Field
                      type="text"
                      name="employeeId"
                      as={TextField}
                      fullWidth
                      disabled
                      onChange={handleChange}
                      label={
                        currentEmployee.firstname +
                        " " +
                        currentEmployee.lastname
                      }
                      error={errors.firstname && Boolean(errors.firstname)}
                      helperText={errors.firstname && errors.firstname}
                    />
                  </>
                </Grid>
                <Grid sx={{ width: "100%" }}>
                  <FormControl
                    error={errors.sourceOfVacation && errors.sourceOfVacation}
                    fullWidth
                  >
                    <InputLabel id="sourceOfVacation">
                      Məzuniyyətin mənbəyi
                    </InputLabel>
                    <Field
                      as={Select}
                      name="sourceOfVacation"
                      labelId="sourceOfVacation"
                      label="Məzuniyyətin mənbəyi"
                      id="sourceOfVacation-select"
                      onChange={handleChange}
                      fullWidth
                      error={
                        errors.sourceOfVacation &&
                        Boolean(errors.sourceOfVacation)
                      }
                    >
                      {sourceOfVacationArray.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Field>
                    {errors.sourceOfVacation && errors.sourceOfVacation && (
                      <FormHelperText error>
                        {errors.sourceOfVacation}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              {/* Məzuniyyətin növü and Əvəzləyici şəxs */}
              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                <Grid sx={{ width: "100%" }}>
                  <FormControl
                    error={errors.vacationType && errors.vacationType}
                    fullWidth
                  >
                    <InputLabel id="vacationType">Məzuniyyətin növü</InputLabel>
                    <Field
                      as={Select}
                      name="vacationType"
                      labelId="vacationType"
                      label="Məzuniyyətin növü"
                      id="vacationType-select"
                      onChange={handleChange}
                      fullWidth
                      error={
                        errors.vacationType && Boolean(errors.vacationType)
                      }
                    >
                      {reasonArray.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Field>
                    {errors.vacationType && errors.vacationType && (
                      <FormHelperText error>
                        {errors.vacationType}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid sx={{ width: "100%" }}>
                  <FormControl
                    error={errors.employeeId && errors.employeeId}
                    fullWidth
                  >
                    <InputLabel id="replacementEmployeeId">
                      Əvəzləyici şəxs
                    </InputLabel>
                    <Field
                      as={Select}
                      name="replacementEmployeeId"
                      labelId="replacementEmployeeId"
                      label="Əvəzləyici şəxs"
                      id="replacementEmployeeId-select"
                      onChange={handleChange}
                      fullWidth
                      error={
                        errors.replacementEmployeeId &&
                        Boolean(errors.replacementEmployeeId)
                      }
                    >
                      {activeEmps.map((item, index) => {
                        const emp = item.employee[0];

                        if (emp.id === currentEmployee.id) return;
                        return (
                          <MenuItem key={index} value={item._id}>
                            {emp.firstname +
                              " " +
                              emp.lastname +
                              ` ( ${item.position ? item.position.name : ""} )`}
                          </MenuItem>
                        );
                      })}
                    </Field>
                    {errors.replacementEmployeeId &&
                      errors.replacementEmployeeId && (
                        <FormHelperText error>
                          {errors.replacementEmployeeId}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              </Grid>

              {/* Məzuniyyətin başlama tarixi and Məzuniyyət Ərizəsi Yarat */}
              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                <Field name="startDate">
                  {({ field, form }) => {
                    return (
                      <DatePicker
                        {...field}
                        label="Məzuniyyətin başlama tarixi"
                        inputFormat="MM/dd/yyyy"
                        value={
                          field.value instanceof Date
                            ? field.value
                            : parse(field.value, "MM/dd/yyyy", new Date())
                        }
                        onChange={(date) => {
                          if (date instanceof Date && !isNaN(date)) {
                            // Format the date using Moment.js
                            const formattedDate =
                              moment(date).format("MM/DD/yyyy");
                            form.setFieldValue(`startDate`, formattedDate);
                            setMinEndDate(date);
                          } else {
                            form.setFieldValue(`startDate`, "");
                          }
                        }}
                        maxDate={maxStartDate}
                        minDate={new Date(1940, 0, 1)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={form.errors.dob}
                            error={Boolean(form.errors.dob)}
                            fullWidth
                          />
                        )}
                      />
                    );
                  }}
                </Field>

                <Grid sx={{ width: "100%" }}>
                  <Button
                    variant="outlined"
                    color={progress === 100 ? "success" : "primary"}
                    disabled={
                      responseStatus === 201 || vacation.isUpdatable === false
                    }
                    fullWidth
                    sx={{
                      height: "100%",
                    }}
                    startIcon={<CreateNewFolderIcon />}
                    onClick={() => {
                      handleVacationContract(values);
                    }}
                    // disabled={progress !== 0}
                  >
                    Məzuniyyət Ərizəsi Yarat
                  </Button>
                </Grid>
              </Grid>

              {/*  Məzuniyyətin bitme tarixi  and progress */}
              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                <Grid sx={{ width: "100%" }}>
                  <Field name="endDate">
                    {({ field, form }) => (
                      <DatePicker
                        {...field}
                        label="Məzuniyyətin bitmə tarixi"
                        inputFormat="MM/dd/yyyy"
                        value={
                          field.value instanceof Date
                            ? field.value
                            : parse(field.value, "MM/dd/yyyy", new Date())
                        }
                        onChange={(date) => {
                          if (date instanceof Date && !isNaN(date)) {
                            // Format the date using Moment.js
                            const formattedDate =
                              moment(date).format("MM/DD/yyyy");
                            form.setFieldValue(`endDate`, formattedDate);
                            setMaxStartDate(date);
                          } else {
                            form.setFieldValue(`endDate`, "");
                          }
                        }}
                        minDate={minEndDate ? minEndDate : new Date(1940, 0, 1)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={form.errors.dob}
                            error={Boolean(form.errors.dob)}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: 3,
                  }}
                >
                  <LinearProgress
                    sx={{
                      height: "50%",
                      borderRadius: 1,
                      width: "100%",
                    }}
                    variant="determinate"
                    value={progress}
                    color={progress === 100 ? "success" : "primary"}
                  />
                  <Typography mt={0.5}>{Math.round(progress)}%</Typography>
                </Grid>
              </Grid>

              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                <Grid sx={{ width: "50%", pr: 1 }}>
                  <Field name={`jobStartDate`}>
                    {({ field, form }) => {
                      const selectedEndDate = form.values.endDate;

                      return (
                        <DatePicker
                          {...field}
                          label="İşə başlama tarixi *"
                          inputFormat="dd/MM/yyyy"
                          value={field.value || null}
                          minDate={
                            selectedEndDate
                              ? new Date(selectedEndDate)
                              : undefined
                          }
                          onChange={(date) => {
                            if (date instanceof Date && !isNaN(date)) {
                              const formattedDate =
                                moment(date).format("MM/DD/yyyy");
                              form.setFieldValue(`jobStartDate`, formattedDate);
                              setMinEndDate(date);
                            } else {
                              form.setFieldValue(`jobStartDate`, "");
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={form.errors?.jobStartDate}
                              error={Boolean(form.errors?.jobStartDate)}
                              fullWidth
                            />
                          )}
                        />
                      );
                    }}
                  </Field>
                </Grid>
              </Grid>
            </>

            {/* Submit button */}
            {vacation.isUpdatable ? (
              <Grid
                sx={{ display: "flex", justifyContent: "flex-end", gap: 3 }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    handleDelete(vacation._id);
                  }}
                >
                  Sil
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    !isValid ||
                    isSubmitting ||
                    responseStatus !== 201 ||
                    progress !== 100
                  }
                >
                  Məzuniyyət Sorğusunu dəyişdir
                </Button>
              </Grid>
            ) : (
              <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                Məlumatları dəyişdirə bilməzsiniz
              </Grid>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateVacationItem;
