import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay, isAfter, isBefore } from "date-fns";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { getCommonData } from "../../../api/api";
import { BASE_URL } from "../../../constants";
import { sendPersonalInfoChanges } from "../../../services/employeeCabinet/employeeCabinet";
import { documentsValidation } from "./Validations";

const today = endOfDay(new Date());

const MCEditDocuments = ({ employeeById }) => {
  const baseURL = BASE_URL;

  const [documentsType, setDocumentsType] = useState([]);
  const [showPicture, setShowPicture] = useState(false);
  const [image, setImage] = useState({ src: "", name: "" });
  const [fileBase64, setFileBase64] = useState({ src: "", name: "" });
  const [document, setDocument] = useState();
  const [requestButton, setRequestButton] = useState(false);

  const handleFileUpload = useCallback((event, index, setFieldValue) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64Image = reader.result;
      const fieldName = `documents[${index}].fileBase64`;
      setFieldValue(fieldName, base64Image); // Use setFieldValue from Formik
      setFieldValue(`documents[${index}].filename`, "");
    };
    reader.readAsDataURL(file);
  }, []);

  //   setSubmitting(true);
  //   const { user, ...modifiedValues } = {
  //     ...employeeById,
  //     vacationDays: values.vacationDays || 0,
  //     documents: values.documents.map((item) => {
  //       const documentItem = {
  //         docType: item.docType,
  //         issuanceDate: item.issuanceDate
  //           ? moment(item.issuanceDate).format("MM/DD/yyyy")
  //           : null,
  //         expirationDate: item.expirationDate
  //           ? moment(item.expirationDate).format("MM/DD/yyyy")
  //           : null,
  //       };

  //       if (item.filename && item.fileBase64) {
  //         return {
  //           ...documentItem,
  //           fileBase64: item.fileBase64,
  //         };
  //       }

  //       if (item.filename) {
  //         return {
  //           ...documentItem,
  //           filename: item.filename._id,
  //         };
  //       }

  //       if (item.fileBase64) {
  //         return {
  //           ...documentItem,
  //           fileBase64: item.fileBase64,
  //         };
  //       }

  //       return item;
  //     }),
  //   };
  //   // Handle form submission
  //   await updateEmployee(employeeById._id, modifiedValues);
  //   setSubmitting(false);
  //   setSubmitted(!submitted);
  // };

  const initialValues = {
    documents:
      employeeById.documents.map((item, index) => ({
        ...item,
        id: index,
      })) || [],
  };

  const [dataIndex, setDataIndex] = useState(initialValues.documents.at(-1).id);

  useEffect(() => {
    const handleDocumentsType = async () => {
      const response = await getCommonData("documentTypes");
      setDocumentsType(response.data);
    };
    handleDocumentsType();
  }, []);

  const handleShowImage = (values, index) => {
    if (values.documents[index].filename) {
      setImage({
        src: values.documents[index].filename._id,
        name: values.documents[index].docType,
      });
    } else {
      setFileBase64({
        src: values.documents[index].fileBase64,
        name: values.documents[index].docType,
      });
    }
    setShowPicture(true);
  };

  const handleCloseImage = () => {
    setImage({ src: "", name: "" });
    setFileBase64({ src: "", name: "" });
    setShowPicture(false);
  };

  function makeChangesData(newData, oldData) {
    let changesData = {
      section: "document",
      changes: [],
    };

    newData.documents.forEach((obj1) => {
      const obj2 = oldData.documents.find((item) => item.id === obj1.id);

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          data: { ...obj1 },
        });
      }
    });

    oldData.documents.forEach((obj2) => {
      const obj1 = newData.documents.find((item) => item.id === obj2.id);

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      }
    });
    sendPersonalInfoChanges(changesData);
  }

  function handle(data) {
    makeChangesData(data, initialValues);
  }

  // form controller for submitting

  function controlFormForSubmitting(data) {
    controllData(data, initialValues);
  }

  function controllData(newData, oldData) {
    let changesData = {
      section: "document",
      changes: [],
    };

    newData.documents.forEach((obj1) => {
      const obj2 = oldData.documents.find((item) => item.id === obj1.id);

      if (!obj2) {
        changesData.changes.push({
          operation: "create",
          data: { ...obj1 },
        });
      }
    });

    oldData.documents.forEach((obj2) => {
      const obj1 = newData.documents.find((item) => item.id === obj2.id);

      if (!obj1) {
        changesData.changes.push({
          operation: "delete",
          index: obj2.id,
          data: obj2,
        });
      }
    });

    if (!changesData.changes.length) {
      setRequestButton(false);
    }
  }

  return (
    <>
      {employeeById ? (
        <Formik
          validationSchema={documentsValidation}
          onSubmit={handle}
          initialValues={{
            documents: initialValues.documents || [], // Set the initial laborActivity values from employeeById
          }}
        >
          {({
            values,
            isValid,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
          }) => {
            return (
              <Form>
                <FieldArray name="documents">
                  {({ push, remove }) => (
                    <div>
                      {values.documents.map((form, index) => (
                        <div>
                          <div
                            key={index}
                            style={
                              !form.filename ? {} : { pointerEvents: "none" }
                            }
                          >
                            {setDocument(form.filename)}
                            <>
                              <Typography mt={5} variant="h6">
                                {index + 1 + ")"} Şəxsi işi - Sənədlər və
                                şəkillər
                              </Typography>

                              {/* documents catogry and itself*/}
                              <Grid
                                sx={{
                                  display: "flex",
                                  gap: 3,
                                  mb: 3,
                                  alignItems: "center",
                                  mt: 5,
                                }}
                              >
                                <Grid sx={{ width: "100%" }}>
                                  <FormControl fullWidth>
                                    <InputLabel
                                      id={`documents[${index}].docType`}
                                    >
                                      Sənədin növü
                                    </InputLabel>

                                    <Field
                                      style={
                                        !form.filename
                                          ? {}
                                          : { pointerEvents: "none" }
                                      }
                                      as={Select}
                                      name={`documents[${index}].docType`}
                                      labelId={`documents[${index}].docType`}
                                      label="Sənədin növü"
                                      id={`documents[${index}].docType`}
                                      fullWidth
                                      onChange={(e) => {
                                        setRequestButton(true);
                                        setFieldValue(
                                          `documents[${index}].docType`,
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        errors?.documents?.[index]?.docType &&
                                        Boolean(
                                          errors?.documents?.[index]?.docType
                                        )
                                      }
                                      helperText={
                                        errors?.documents?.[index]?.docType &&
                                        errors?.documents?.[index]?.docType
                                      }
                                    >
                                      {documentsType.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                          {item}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </FormControl>
                                  <ErrorMessage
                                    name={`documents[${index}].docType`}
                                    component="div"
                                  />
                                </Grid>

                                <FormControl>
                                  {values.documents[index].filename ||
                                  values.documents[index].fileBase64 ? (
                                    <Grid sx={{ width: "100%" }}>
                                      <Grid
                                        sx={{
                                          display: "flex",
                                          gap: 3,
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          style={
                                            !form.filename
                                              ? {}
                                              : { pointerEvents: "none" }
                                          }
                                          sx={{ height: "50px" }}
                                          fullWidth
                                          variant="contained"
                                          onClick={() => {
                                            handleShowImage(values, index);
                                          }}
                                        >
                                          Şəkli göstər
                                        </Button>
                                        <Grid sx={{ width: "100%" }}>
                                          <Button
                                            style={
                                              !form.filename
                                                ? {}
                                                : { pointerEvents: "none" }
                                            }
                                            sx={{ height: "50px" }}
                                            fullWidth
                                            variant="contained"
                                            component="label"
                                          >
                                            Şəkli dəyişdir
                                            <Input
                                              type="file"
                                              accept="image/*"
                                              fullWidth
                                              hidden
                                              onChange={(event) =>
                                                handleFileUpload(
                                                  event,
                                                  index,
                                                  setFieldValue
                                                )
                                              }
                                            />
                                          </Button>

                                          <ErrorMessage
                                            name={`documents[${index}].image`}
                                            component="div"
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        columnGap: "10px",
                                      }}
                                    >
                                      <Button
                                        sx={{ height: "50px" }}
                                        fullWidth
                                        variant="contained"
                                        component="label"
                                      >
                                        Şəkil yüklə
                                        <Input
                                          type="file"
                                          accept="image/*"
                                          onChange={(event) => {
                                            setRequestButton(true);

                                            handleFileUpload(
                                              event,
                                              index,
                                              setFieldValue
                                            );
                                          }}
                                          fullWidth
                                          hidden
                                        />
                                      </Button>
                                      {errors?.documents?.[index]?.fileBase64 &&
                                        errors?.documents?.[index]
                                          ?.fileBase64 && (
                                          <FormHelperText error>
                                            {
                                              errors?.documents?.[index]
                                                ?.fileBase64
                                            }
                                          </FormHelperText>
                                        )}
                                    </Grid>
                                  )}
                                </FormControl>
                              </Grid>

                              {/* Picture dialog */}
                              <Dialog
                                fullWidth
                                open={showPicture}
                                onClose={handleCloseImage}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {image.name ? image.name : fileBase64.name}
                                </DialogTitle>
                                <DialogContent>
                                  {image.src ? (
                                    <Box
                                      component="img"
                                      sx={{
                                        height: 350,
                                        width: "100%",
                                      }}
                                      src={`${baseURL}file/${image.src}`}
                                    />
                                  ) : null}
                                  {fileBase64.src ? (
                                    <Box
                                      component="img"
                                      sx={{
                                        height: 350,
                                        width: "100%",
                                      }}
                                      src={fileBase64.src}
                                    />
                                  ) : null}
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={handleCloseImage}
                                    color="primary"
                                  >
                                    Çıxış et
                                  </Button>
                                </DialogActions>
                              </Dialog>

                              {/* start date and end date */}
                              <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    name={`documents[${index}].issuanceDate`}
                                  >
                                    {({ field, form }) => (
                                      <DatePicker
                                        InputProps={{ readOnly: true }}
                                        {...field}
                                        label="Sənədin verilmə tarixi"
                                        inputFormat="dd/MM/yyyy"
                                        value={field.value}
                                        onChange={(date) => {
                                          setRequestButton(true);

                                          if (date && !isAfter(date, today)) {
                                            form.setFieldValue(
                                              `documents[${index}].issuanceDate`,
                                              date
                                            );
                                          } else {
                                            form.setFieldValue(
                                              `documents[${index}].issuanceDate`,
                                              ""
                                            );
                                          }
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            helperText={
                                              form.errors?.documents?.[index]
                                                ?.issuanceDate
                                            }
                                            error={Boolean(
                                              form.errors.documents?.[index]
                                                ?.issuanceDate
                                            )}
                                            fullWidth
                                          />
                                        )}
                                        maxDate={today}
                                        minDate={new Date(1940, 0, 1)}
                                        disableFuture
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid sx={{ width: "100%" }}>
                                  <Field
                                    name={`documents[${index}].expirationDate`}
                                  >
                                    {({ field, form }) => {
                                      return (
                                        <DatePicker
                                          {...field}
                                          label="Sənədin qüvvədən düşmə tarixi (müddətsiz ola bilər)"
                                          inputFormat="dd/MM/yyyy"
                                          value={field.value}
                                          onChange={(date) => {
                                            setRequestButton(true);

                                            if (
                                              date &&
                                              !isBefore(date, today)
                                            ) {
                                              form.setFieldValue(
                                                `documents[${index}].expirationDate`,
                                                date
                                              );
                                            } else {
                                              form.setFieldValue(
                                                `documents[${index}].expirationDate`,
                                                ""
                                              );
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={
                                                form.errors?.documents?.[index]
                                                  ?.expirationDate
                                              }
                                              error={Boolean(
                                                form.errors.documents?.[index]
                                                  ?.expirationDate
                                              )}
                                              fullWidth
                                            />
                                          )}
                                          // minDate={today}
                                          // disablePast
                                        />
                                      );
                                    }}
                                  </Field>
                                </Grid>
                              </Grid>
                            </>
                          </div>
                          <Button
                            sx={{ mt: 3 }}
                            variant="contained"
                            color="error"
                            type="button"
                            onClick={() => {
                              setRequestButton(true);
                              remove(index);
                              const updatedArray = values.documents.filter(
                                (item) => item.id !== index
                              );
                              controlFormForSubmitting({
                                documents: updatedArray,
                              });
                            }}
                          >
                            Sənədi sil
                          </Button>
                        </div>
                      ))}
                      <Button
                        sx={{ mt: 3 }}
                        variant="contained"
                        type="button"
                        onClick={() => {
                          let newId = dataIndex + 1;
                          setDataIndex(newId);
                          push({ id: newId });
                        }}
                      >
                        Sənəd əlavə et
                      </Button>
                    </div>
                  )}
                </FieldArray>
                <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="submit"
                    style={
                      !requestButton
                        ? { pointerEvents: "none" }
                        : {
                            backgroundColor: "#28358a",
                            pointerEvents: "auto",
                          }
                    }
                    variant="contained"
                    color="secondary"
                  >
                    Dəyişikliklər sorğusu göndər
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default MCEditDocuments;
