import React, { useEffect, useReducer } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { format } from "date-fns";
import { az } from "date-fns/locale";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link as MuiLink,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import {
  Add as AddIcon,
  CheckOutlined as CheckOutlinedIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  RemoveOutlined as RemoveOutlinedIcon,
  DoneAllOutlined as DoneAllOutlinedIcon,
  RemoveDoneOutlined as RemoveDoneOutlinedIcon,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import { spacing } from "@mui/system";
import * as ticketApi from "../../api/ticket";

import { useTranslation } from "react-i18next";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.sent && green[500]};
  background: ${(props) => props.paid && orange[700]};
  color: ${(props) =>
    (props.sent || props.paid) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "ticketId", alignment: "left", label: "SorğuKodu" },
  { id: "description", alignment: "left", label: "İzah" },
  { id: "status", alignment: "left", label: "Status" },
  { id: "createdDate", alignment: "right", label: "Yaranma Tarixi" },
  { id: "updatedDate", alignment: "right", label: "Yenilənmə Tarixi" },
  { id: "category", alignment: "left", label: "Kategoriyalar" },
  { id: "actions", alignment: "left", label: "Əməliyatlar" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const { t } = useTranslation("pages");

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const {
    onMarkAllReviewedClick,
    onUnMarkAllReviewedClick,
    onToggleReviewedClick,
    selectedList,
  } = props;
  const { t } = useTranslation("pages");

  const numSelected = selectedList.length;

  let reviewedState = false;

  if (numSelected === 1) {
    reviewedState = selectedList[0].status;
  }

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} {t("contact_request.list.table.head.num_selected")}
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Sorğuların siyahısı
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected === 0 && (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
        {numSelected === 1 &&
          (reviewedState ? (
            <Tooltip
              title={t(
                "contact_request.list.table.head.actions.mark_one_unreviewed_btn"
              )}
            >
              <IconButton
                aria-label={t(
                  "contact_request.list.table.head.actions.mark_one_unreviewed_btn"
                )}
                size="large"
                onClick={onToggleReviewedClick}
              >
                <RemoveOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={t(
                "contact_request.list.table.head.actions.mark_one_reviewed_btn"
              )}
            >
              <IconButton
                aria-label={t(
                  "contact_request.list.table.head.actions.mark_one_reviewed_btn"
                )}
                size="large"
                onClick={onToggleReviewedClick}
              >
                <CheckOutlinedIcon />
              </IconButton>
            </Tooltip>
          ))}
        {numSelected > 1 && (
          <Box sx={{ display: "flex" }}>
            <Tooltip
              title={t(
                "contact_request.list.table.head.actions.mark_all_reviewed_btn"
              )}
            >
              <IconButton
                aria-label={t(
                  "contact_request.list.table.head.actions.mark_all_reviewed_btn"
                )}
                size="large"
                onClick={onMarkAllReviewedClick}
              >
                <DoneAllOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(
                "contact_request.list.table.head.actions.mark_all_unreviewed_btn"
              )}
            >
              <IconButton
                aria-label={t(
                  "contact_request.list.table.head.actions.mark_all_unreviewed_btn"
                )}
                size="large"
                onClick={onUnMarkAllReviewedClick}
              >
                <RemoveDoneOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </div>
    </Toolbar>
  );
};

const ticketReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE": {
      return { ...state, reRender: !state.reRender };
    }
    case "TICKET/LOAD": {
      return {
        ...state,
        ticketList: action.payload.list,
        total: action.payload.total,
      };
    }
    case "TICKET/PAGE_CHANGE": {
      return {
        ...state,
        currentPage: action.payload.requestedPage,
      };
    }
    case "TICKET/LIMIT_CHANGE": {
      return {
        ...state,
        limit: action.payload.requestedLimit,
      };
    }
    case "REFRESH_LIST":
      return {
        ...state,
        refresh: !state.refresh,
      };

    default:
      return { ...state };
  }
};

function EnhancedTable() {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const { id } = useParams();
  const [state, dispatch] = useReducer(ticketReducer, {
    ticketList: [],
    limit: 5,
    currentPage: 0,
    total: 0,
    selectedList: [],
    reRender: false,
    refresh: false,
  });
  const { t } = useTranslation("pages");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = state.contactRequests.map((n) => ({
        id: n.id,
        status: n.isReviewed,
      }));

      dispatch({ type: "CONTACT_REQUEST/SELECT_ALL", payload: newSelecteds });
      return;
    }
  };

  const handleClick = (event, id) => {
    const isSelected = state.selectedList.find(
      (selected) => selected.id === id
    );

    if (isSelected) {
      dispatch({ type: "CONTACT_REQUEST/REMOVE_SELECTED", payload: id });
    } else {
      const contactRequest = state.contactRequests.find((cr) => cr.id === id);

      dispatch({
        type: "CONTACT_REQUEST/ADD_NEW_SELECTED",
        payload: { id, status: contactRequest.isReviewed },
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: "TICKET/PAGE_CHANGE",
      payload: {
        requestedPage: newPage,
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const newLimit = event.target.value;
    const newPage = Math.floor((state.currentPage * state.limit) / newLimit);

    dispatch({
      type: "TICKET/LIMIT_CHANGE",
      payload: {
        requestedLimit: newLimit,
      },
    });

    dispatch({
      type: "TICKET/PAGE_CHANGE",
      payload: {
        requestedPage: newPage,
      },
    });
  };

  const closeHandler = async (id) => {
    await ticketApi.closeTicket(id);
    dispatch({
      type: "REFRESH_LIST",
    });
  };

  const openHandler = async (id) => {
    try {
      await ticketApi.openTicket(id);
      dispatch({
        type: "REFRESH_LIST",
      });
    } catch (error) {
      console.log("Error opening ticket:", error);
    }
  };
  const toggleAllReviewedBtnHandler = (isReviewedStatus) => {
    const markAsReviewed = async () => {
      // await contactRequestApi.updateMultipleReviewedState(
      //   state.selectedList
      //     .filter((selected) => {
      //       const contactRequest = state.contactRequests.find(
      //         (cr) => cr.id === selected.id
      //       );
      //       return (
      //         contactRequest && contactRequest.isReviewed === !isReviewedStatus
      //       );
      //     })
      //     .map((selected) => selected.id),
      //   isReviewedStatus
      // );
      // dispatch({
      //   type: "UPDATE",
      // });
    };

    markAsReviewed();
  };

  const toggleReviewedBtnClickHandler = () => {
    // const changeReviewedState = async () => {
    //   const data = await contactRequestApi.updateReviewedState(
    //     state.selectedList[0].id,
    //     !state.selectedList[0].status
    //   );
    //   dispatch({
    //     type: "UPDATE",
    //   });
    // };
    // changeReviewedState();
  };

  const ticketDetailsBtnClickHandler = (ticketId) => {
    navigate(`details/${ticketId}`);
  };

  const { currentPage, limit, refresh } = state;

  useEffect(() => {
    const getTickets = async () => {
      const data = await ticketApi.getTickets(limit, currentPage * limit);

      dispatch({
        type: "TICKET/LOAD",
        payload: {
          list: data.tickets,
          total: data.total,
        },
      });
    };

    getTickets();
  }, [currentPage, limit, refresh]);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={state.selectedList.length}
          onMarkAllReviewedClick={toggleAllReviewedBtnHandler.bind(null, true)}
          onUnMarkAllReviewedClick={toggleAllReviewedBtnHandler.bind(
            null,
            false
          )}
          onToggleReviewedClick={toggleReviewedBtnClickHandler}
          selectedList={state.selectedList}
        />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={state.selectedList.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={state.ticketList.length}
            />
            <TableBody>
              {stableSort(state.ticketList, getComparator(order, orderBy)).map(
                (row, index) => {
                  const selectedItem = state.selectedList.find(
                    (item) => item.id === row.id
                  );
                  const isItemSelected = selectedItem !== undefined;
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell> */}

                      <TableCell align="left">{row.ticketId}</TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                      <TableCell scope="row">
                        {/*                         
                        {row.status}
                         */}

                        {row.status === "open" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Open"
                            sent={+true}
                          />
                        )}

                        {row.status !== "open" && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Close"
                            paid={+true}
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {format(new Date(row.createdAt), "dd MMMM yyyy", {
                          locale: az,
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {format(new Date(row.updatedAt), "dd MMMM yyyy", {
                          locale: az,
                        })}
                      </TableCell>
                      <TableCell align="left">
                        {row.category?.name || "None"}
                      </TableCell>
                      <TableCell padding="none" align="right">
                        <Box display="flex" alignItems="right">
                          <Box mr={2}>
                            <Tooltip title="Detallı bax">
                              <IconButton
                                aria-label="details"
                                size="large"
                                onClick={() =>
                                  ticketDetailsBtnClickHandler(row.id)
                                }
                              >
                                <RemoveRedEyeIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box mr={2}>
                            <Tooltip
                              title={
                                row.status === "closed"
                                  ? "Sorğunu Aç"
                                  : "Sorğunu bağla"
                              }
                            >
                              <IconButton
                                aria-label="details"
                                size="large"
                                onClick={() => {
                                  if (row.status === "closed") {
                                    openHandler(row.id);
                                  } else {
                                    closeHandler(row.id);
                                  }
                                }}
                              >
                                {row.status === "closed" ? (
                                  <ThreeSixtyIcon />
                                ) : (
                                  <CloseIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Digər səhifəyə keç"
          paginationMode="server"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={state.total}
          rowsPerPage={state.limit}
          page={state.currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function TicketList() {
  const { t } = useTranslation("pages");

  return (
    <React.Fragment>
      <Helmet title="Sorğular" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Sorğular
          </Typography>
        </Grid>
        <Grid item>
          <div>
            {" "}
            <MuiLink component={NavLink} to="/ticketing/new">
              <Button variant="contained" color="primary">
                <AddIcon />
                Yeni Sorğu
              </Button>
            </MuiLink>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TicketList;
