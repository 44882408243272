import { toast } from "react-toastify";
import { api } from "../../api/axiosInterceptor";

export const getSalaryHistory = async (payload) => {
  let params = {
    offset: payload?.offset,
    limit: payload?.limit,
    filter: payload?.filters,
    month: payload.month,
    year: payload.year,
    structureId: payload.structureId,
  };

  try {
    const response = await api.get("employee/wages-calc/history", {
      params: params,
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const postSalaryCalculation = async (id, month, year) => {
  try {
    return await api.post(`/employee/wages-calc/`, {
      structure: id,
      month: `${month}`,
      year: `${year}`,
    });
  } catch (err) {
    console.log("postsalarycalculation", err);
  }
};

// download excell

export const getDownloadSalaries = async ({
  columns,
  filter = undefined,
  month,
  year,
  structureId,
}) => {
  const url = `employee/wages-calc/export?filters=${filter}&columns=${columns}&month=${month}&year=${year}&structureId=${structureId}`;

  return await api.get(url, { responseType: "blob" });
};
