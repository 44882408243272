import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

import {
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import CompensationTable from "./compensation-table/compensation-table";
import { vacationTrackingRequest } from "../../../../../api/employeeRequests/employeeRequests";
const Divider = styled(MuiDivider)(spacing);

const Compensation = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [total, setTotal] = useState();

  const getVacation = async (filters) => {
    if (!filters) {
      filters = [];
    }
    try {
      setLoading(true);
      const response = await vacationTrackingRequest(filters, limit, offset);
      if (response && response?.data) {
        console.log(response);
        const vacationTrackingData = response?.data?.records;
        setData(vacationTrackingData);
        setTotal(response?.data?.total);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVacation(filters);
  }, [filters, offset, limit]);

  return (
    <form>
      <Card>
        <CardContent>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => navigate("/documentation/orders")}
              variant="text"
              sx={{ color: "#fff" }}
            >
              {" "}
              <CloseIcon />
            </Button>
          </Grid>
          <Typography variant="h3" textAlign="center" mt={5} mb={7}>
            Kompensassiyanın verilməsi
          </Typography>
          <Divider my={6} />
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Əməkdaş">Əməkdaş (A.S.A)</InputLabel>
              <Select
                name="Əməkdaş (A.S.A)"
                labelId="fieldOfActivity"
                label="Fəaliyyət Sahəsi"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Əməkdaş (A.S.A) 1</MenuItem>
                <MenuItem value="employee2">Əməkdaş (A.S.A) 2</MenuItem>
                <MenuItem value="employee3">Əməkdaş (A.S.A) 3</MenuItem>
              </Select>
            </FormControl>
            <TextField
              name="Gün"
              label="Gün"
              sx={{ width: "50%" }}
              variant="outlined"
              my={2}
            />
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <Grid sx={{ display: "flex", gap: "25px", width: "50%" }}>
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="Müqavilə nömrəsi">Müqavilə nömrəsi</InputLabel>
                <Select
                  name="Müqavilə nömrəsi"
                  labelId="fieldOfActivity"
                  label="Fəaliyyət Sahəsi"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="employee1">Müqavilə nömrəsi 1</MenuItem>
                  <MenuItem value="employee2">Müqavilə nömrəsi 2</MenuItem>
                  <MenuItem value="employee3">Müqavilə nömrəsi 3</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: "50%" }}>
                <DatePicker
                  my={2}
                  inputFormat="dd/MM/yyyy"
                  label="Müqavilənin tarixi"
                  minDate={new Date(new Date().getTime() - 86400000 * 30)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </FormControl>
            </Grid>
            <TextField
              name="Məbləğ"
              label="Məbləğ"
              sx={{ width: "50%" }}
              variant="outlined"
              my={2}
            />
          </Grid>
          <Grid sx={{ display: "flex", gap: "25px" }} mb={4}>
            <TextField
              sx={{ width: "50%" }}
              name="Tabel nömrəsi"
              label="Tabel nömrəsi"
              variant="outlined"
              my={2}
            />
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Valyuta">Valyuta</InputLabel>
              <Select
                name="Valyuta"
                labelId="fieldOfActivity"
                label="Fəaliyyət Sahəsi"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Valyuta 1</MenuItem>
                <MenuItem value="employee2">Valyuta 2</MenuItem>
                <MenuItem value="employee3">Valyuta 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              gap: "25px",
              width: "50%",
              paddingRight: "13px",
            }}
            mb={4}
          >
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Təşkilat">Təşkilat</InputLabel>
              <Select
                name="Təşkilat"
                labelId="fieldOfActivity"
                label="Fəaliyyət Sahəsi"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Təşkilat 1</MenuItem>
                <MenuItem value="employee2">Təşkilat 2</MenuItem>
                <MenuItem value="employee3">Təşkilat 3</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Struktur">Struktur</InputLabel>
              <Select
                name="Struktur"
                labelId="fieldOfActivity"
                label="Fəaliyyət Sahəsi"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Struktur 1</MenuItem>
                <MenuItem value="employee2">Struktur 2</MenuItem>
                <MenuItem value="employee3">Struktur 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              gap: "25px",
              width: "50%",
              paddingRight: "13px",
            }}
            mb={4}
          >
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Substruktur">Substruktur</InputLabel>
              <Select
                name="Substruktur"
                labelId="fieldOfActivity"
                label="Fəaliyyət Sahəsi"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Substruktur 1</MenuItem>
                <MenuItem value="employee2">Substruktur 2</MenuItem>
                <MenuItem value="employee3">Substruktur 3</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="Vəzifə">Vəzifə</InputLabel>
              <Select
                name="Vəzifə"
                labelId="fieldOfActivity"
                label="Fəaliyyət Sahəsi"
                fullWidth
                variant="outlined"
              >
                <MenuItem value="employee1">Vəzifə 1</MenuItem>
                <MenuItem value="employee2">Vəzifə 2</MenuItem>
                <MenuItem value="employee3">Vəzifə 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid mb={10}>
            <TextField name="Qeyd" label="Qeyd" fullWidth variant="outlined" />
          </Grid>
          <CompensationTable data={data} />
          <Divider my={6} />
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "25px",
            }}
          >
            <Button
              title="İmtina et"
              variant="contained"
              sx={{ height: "100%" }}
              color="error"
              onClick={() => navigate("/documentation/orders")}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Geri
              </p>
            </Button>
            <Button
              type="submit"
              title="Kənarlaşdırma yarat"
              variant="contained"
              sx={{ height: "100%" }}
            >
              <p
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Yadda saxla
              </p>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default Compensation;
