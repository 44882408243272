import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
// Material UI
import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const Paper = styled(MuiPaper)(spacing);

const BasicTable = ({ columns, data }) => {
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {columns.length > 0
              ? columns.map((column, index) => (
                  <TableCell
                    key={index + 1}
                    align={index === 0 ? "left" : "right"}
                  >
                    {column}
                  </TableCell>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0
            ? data.map((row, index) => (
                <TableRow key={index + 1}>
                  <TableCell component="th" scope="row">
                    {row?.name || "-"}
                  </TableCell>
                  <TableCell align="right">{row?.type || "-"}</TableCell>
                  <TableCell align="right">{row?.min || "-"}</TableCell>
                  <TableCell align="right">{row?.max || "-"}</TableCell>
                  <TableCell align="right">{row?.label || "-"}</TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default BasicTable;
