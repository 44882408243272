export const banksList = [
  {
    name: "Azərbaycan Beynəlxalq Bankı ASC",
    voen: "9900001881",
    code: 805250,
  },
  { name: "AccessBank QSC", voen: "1400057421", code: 505000 },
  { name: "AFB Bank ASC", voen: "1301703781", code: 503217 },
  { name: "Azərbaycan Sənaye Bankı ASC", voen: "9900007981", code: 509664 },
  { name: "Azər Türk Bank ASC", voen: "9900006111", code: 507699 },
  { name: "Bank Avrasiya ASC", voen: "1700792251", code: 505129 },
  { name: "Bank of Baku ASC", voen: "1700038881", code: 506924 },
  { name: "Bank Respublika ASC", voen: "9900001901", code: 505668 },
  { name: "Bank VTB (Azərbaycan) ASC", voen: "1400117231", code: 506623 },
  { name: "Expressbank ASC", voen: "1500031691", code: 505099 },
  { name: "Kapital Bank ASC", voen: "9900003611", code: 200004 },
  { name: "Yelo Bank ASC", voen: "9900014901", code: 507064 },
  { name: "PAŞA Bank ASC", voen: "1700767721", code: 505141 },
  { name: "Premium Bank ASC", voen: "9900006241", code: 507473 },
  { name: "Rabitəbank ASC", voen: "9900001061", code: 506399 },
  { name: "Turanbank ASC", voen: "1300016391", code: 507462 },
  { name: "Unibank KB ASC", voen: "1300017201", code: 505754 },
  { name: "Xalq Bank ASC", voen: "2000296061", code: 505055 },
  {
    name: "Yapı Kredi Bank Azərbaycan QSC",
    voen: "9900009021",
    code: 509987,
  },
  { name: "Ziraat Bank Azərbaycan ASC", voen: "1303953611", code: 512372 },
];

export const sortedBanksList = banksList.sort((a, b) =>
  a.name.localeCompare(b.name)
);
