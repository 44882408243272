import React, { useEffect, useState } from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Field } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCommonData } from "../../../../api/api";
import NewCommonDataDialog from "../../../../components/employees/NewCommonDataDialog";
const Protection = ({
  values,
  setFieldValue,
  selectedValue,
  inventoryTypes,
}) => {
  const [specialFoodSupplements, setSpecialFoodSupplements] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedType, setSelectedType] = useState("");

  const handleInventory = async () => {
    const specialFoodSupplementsResponse = await getCommonData(
      "specialFoodSupplements"
    );
    setSpecialFoodSupplements(specialFoodSupplementsResponse.data);
  };

  useEffect(() => {
    handleInventory();
  }, [submitted]);
  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Əməyin mühafizəsi üzrə</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {inventoryTypes.length > 0 && (
              <Grid sx={{ width: "100%" }}>
                <FormControl fullWidth>
                  <InputLabel id={`protection.providedProtectiveEquipment`}>
                    Verilən fərdi mühafizə vasitələri
                  </InputLabel>
                  <Field
                    as={Select}
                    name={`protection.providedProtectiveEquipment`}
                    label="Verilən fərdi mühafizə vasitələri"
                    // value={
                    //   values?.protection?.providedProtectiveEquipment[0] || ""
                    // }
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      setFieldValue(
                        "protection.providedProtectiveEquipment",
                        selectedValue
                      );
                    }}
                    fullWidth
                    defaultValue=""
                  >
                    {inventoryTypes &&
                      inventoryTypes
                        .sort((a, b) => a?.name.localeCompare(b?.name, "az"))
                        .map((item, index) => (
                          <MenuItem key={index} value={item?.name}>
                            {item?.name}
                          </MenuItem>
                        ))}
                  </Field>
                </FormControl>
              </Grid>
            )}
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id={`protection.providedFood`}>
                  Verilən xüsusi qida məhsulları
                </InputLabel>
                <Field
                  as={Select}
                  name={`protection.providedFood`}
                  label=" Verilən xüsusi qida məhsulları"
                  onChange={(e) => {
                    setFieldValue("protection.providedFood", e.target.value);
                  }}
                  defaultValue=""
                  fullWidth
                >
                  {specialFoodSupplements &&
                    specialFoodSupplements
                      .sort((a, b) => a.localeCompare(b, "az"))
                      .map((item, index) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                  <Button
                    sx={{
                      mt: 3,
                      ml: 3,
                    }}
                    variant="outlined"
                    onClick={() => {
                      setOpenAddDialog(true);
                      setSelectedType([
                        "specialFoodSupplements",
                        { name: "Xüsusi qida məhsulları" },
                      ]);
                    }}
                  >
                    Əlavə et
                  </Button>
                </Field>
              </FormControl>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel>Təlimatlandırma aralığı</InputLabel>
                <Field
                  as={Select}
                  label="Təlimatlandırma aralığı"
                  defaultValue=""
                  fullWidth
                  name="protection.instructionalPeriod"
                  onChange={(event) => {
                    setFieldValue(
                      "protection.instructionalPeriod",
                      event.target.value
                    );
                  }}
                >
                  {selectedValue?.instructionalPeriod &&
                    selectedValue?.instructionalPeriod.map((item, i) => (
                      <MenuItem key={`${item.value}${i}`} value={item.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                </Field>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
      {openAddDialog && (
        <NewCommonDataDialog
          submitted={submitted}
          selectedType={selectedType}
          setSubmitted={setSubmitted}
          openAddDialog={openAddDialog}
          setOpenAddDialog={setOpenAddDialog}
        />
      )}
    </>
  );
};

export default Protection;
