import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";

const LoadingWithProps = ({ loadingModal }) => {
  return (
    <Backdrop open={loadingModal} sx={{ zIndex: 9999 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingWithProps;
