import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoWithoutBorder from "./../../assets/logo/logoWithoutBorder.svg";

import { Box, Drawer as MuiDrawer, Grid, IconButton } from "@mui/material";

// import Logo from "../../vendor/blink-logo-female.png";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
  }
`;

const Sidebar = ({
  anchorEl,
  handleOpen,
  items,
  showFooter = true,
  ...rest
}) => {
  return (
    <Drawer variant="permanent" {...rest}>
      {/* <Brand component={NavLink} to="/">
        <BrandIcon />{" "}
        <Box ml={1}>
          Blink-Bi <BrandChip label="PRO" />
        </Box>
      </Brand> */}
      {anchorEl === false && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton aria-label="close">
            <ChevronLeftIcon onClick={handleOpen} />
          </IconButton>
        </Box>
      )}

      <Link to="/private">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: "20px", mb: "20px" }}
        >
          <Box
            component="img"
            src={LogoWithoutBorder}
            sx={{
              width: "70%",
              objectFit: "contain",
            }}
          />
        </Grid>
      </Link>

      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
