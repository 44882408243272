import { api } from "../../api/axiosInterceptor";

// Explanations and Applications

export const getExplanationsApplicationsRequests = async (payload) => {
  return await api.get("/employee/employee-application/pending", {
    params: { ...payload },
  });
};

export const getExplanationsApplications = async (payload) => {
  return await api.get("employee/employee-application/explained", {
    params: { ...payload },
  });
};

export const getApplications = async (payload) => {
  return await api.get("employee/employee-application/explained", {
    params: {
      ...payload,
    },
  });
};

export const putExplanations = async (payload) => {
  return await api.put(
    `employee/employee-application/${payload.id}/sendExplanation`,
    {
      explanation: payload.explanation,
    }
  );
};

// Orders and Contracts

export const getOrdersContracts = async (payload) => {
  return await api.get("employee/cabinet/documents", {
    params: {
      limit: payload.limit,
      offset: payload.offset,
      contractType: payload.contractType,
    },
  });
};
