import CreateIcon from "@mui/icons-material/Create";
import style from "../myProfileStyle.module.css";
import picture from "../profilpic.png";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { BASE_URL } from "../../../constants";
import { sendPersonalInfoChanges } from "../../../services/employeeCabinet/employeeCabinet";
import { personalInformationValidation } from "./Validations";

function MCEditPersonalInformation({ employeeById }) {
  const [oldNameCheckbox, setOldNameCheckbox] = useState(false);
  const [formCorrection, setFormCorrection] = useState(false);
  const [requestButton, setRequestButton] = useState(false);
  const [profilPicture, setProfilPicture] = useState(false);
  // const [changeReqData, setChangeReqData] = useState({});
  const onClickHandler = () => setFormCorrection(!formCorrection);

  const countries = [
    "Albaniya",
    "Almaniya",
    "Anqola",
    "Antiqua və Barbuda",
    "Argentina",
    "Avstraliya",
    "Avstriya",
    "Azərbaycan",
    "Bahamalar",
    "Bahrayn",
    "Banqladeş",
    "Barbados",
    "Belarus",
    "Belçika",
    "Boliviya",
    "Bosniya və Herseqovina",
    "Botswana",
    "Braziliya",
    "Böyük Britaniya",
    "Büyükdəniz adası",
    "Bəhreyn",
    "Bəruney",
    "Cənubi Afrika",
    "Cənubi Sudan",
    "Cənubi Koreya",
    "Çad",
    "Çexiya",
    "Çili",
    "Çin",
    "Çernoqoriya",
    "Çexoslavakiya",
    "Danemarka",
    "Dominika",
    "Dominikan Respublikası",
    "Ekvador",
    "Ekvatorial Qvineya",
    "Eritreya",
    "Estoniya",
    "Əfqanıstan",
    "Fici",
    "Filippin",
    "Finlandiya",
    "Fransa",
    "Fransız Qvineyası",
    "Fələstin",
    "Gana",
    "Qabon",
    "Qambiya",
    "Qazaxıstan",
    "Qatar",
    "Qrenada",
    "Qvineya",
    "Qvineya-Bisau",
    "Gürcüstan",
    "Haiti",
    "Hollandiya",
    "Honduras",
    "Honkonq",
    "Hindistan",
    "Horvatiya",
    "Indoneziya",
    "İngiltərə",
    "İordaniya",
    "İraq",
    "İran",
    "İrlandiya",
    "İslandiya",
    "İspaniya",
    "İsrail",
    "İsveç",
    "İsveçrə",
    "İtaliya",
    "Kamboca",
    "Kamerun",
    "Kanada",
    "Keniya",
    "Kipr",
    "Kiribati",
    "Kolumbiya",
    "Komor Adaları",
    "Kongo",
    "Kosta Rika",
    "Kotd’İvor",
    "Kuba",
    "Kuveyt",
    "Kırqızıstan",
    "Latviya",
    "Lesoto",
    "Livan",
    "Liberiya",
    "Litva",
    "Lixtenşteyn",
    "Liviya",
    "Lüksemburq",
    "Makedoniya",
    "Malavi",
    "Malayziya",
    "Maldiv adaları",
    "Malta",
    "Maroq",
    "Marşal adaları",
    "Mauritaniya",
    "Mauritius",
    "Meksika",
    "Moldova",
    "Monako",
    "Monqolustan",
    "Monteneqro",
    "Morokko",
    "Mozambik",
    "Myanma",
    "Mərakeş",
    "Nambiya",
    "Nauru",
    "Nepal",
    "Niderland",
    "Niger",
    "Nigeriya",
    "Nikaraqua",
    "Norveç",
    "Pakistan",
    "Palau",
    "Panama",
    "Paraqvay",
    "Portuqaliya",
    "Polşa",
    "Rumıniya",
    "Rusiya",
    "Ruanda",
    "San-Marino",
    "Svazilend",
    "Salvador",
    "Samoa",
    "San-Tome və Prinsip",
    "Seneqal",
    "Seyşel adaları",
    "Sinhapur",
    "Slovakiya",
    "Sloveniya",
    "Solomon adaları",
    "Somali",
    "Sudan",
    "Surinam",
    "Suriya",
    "Svədistan",
    "Səudiyyə Ərəbistanı",
    "Sərbiya",
    "Tacikistan",
    "Tanzaniya",
    "Tailand",
    "Tayvan",
    "Toga",
    "Tonqa",
    "Trinidad və Tobaqo",
    "Tunis",
    "Turkmənistan",
    "Tuvalu",
    "Türkiyə",
    "Ukrayna",
    "Urugvay",
    "Vanuatu",
    "Vatikan",
    "Venesuela",
    "Vyetnam",
    "Yamayka",
    "Yaponiya",
    "Yeni Zelandiya",
    "Yunanıstan",
    "Yəmən",
    "Zambiya",
    "Zimbabve",
  ];

  const initialValues = {
    isForeigner: employeeById.isForeigner ? true : false,
    firstname: employeeById?.firstname ? employeeById.firstname : "",
    lastname:
      employeeById.lastname +
        (employeeById.oldLastName ? ` ( ${employeeById?.oldLastName} )` : "") ||
      "",
    fathername: employeeById.fathername || "",
    gender: employeeById.gender || "",
    dob: employeeById.dob || null,
    placeOfBirth: employeeById.placeOfBirth || "",
    pin: employeeById.pin || "",
    tRPNumber: employeeById.tRPNumber || "",
    idCardIssuanceDate: employeeById.idCardIssuanceDate || null,
    nationality: employeeById.nationality || "",
    idCardAuthority: employeeById.idCardAuthority || "",
    idCardNumber: employeeById.isForeigner
      ? { number: employeeById.idCardNumber }
      : {
          code: employeeById?.idCardNumber?.startsWith("AA")
            ? employeeById.idCardNumber.slice(0, 2)
            : employeeById?.idCardNumber?.startsWith("AZE")
            ? employeeById?.idCardNumber.slice(0, 3)
            : "",
          number: employeeById?.idCardNumber?.startsWith("AA")
            ? employeeById.idCardNumber.slice(2)
            : employeeById?.idCardNumber?.startsWith("AZE")
            ? employeeById?.idCardNumber.slice(3)
            : "",
        },
    idCardValidityPeriod: employeeById.idCardValidityPeriod || null,
    ssn: employeeById.ssn || "",
    citizenship: employeeById.citizenship || "",
    workPermitStartDate: employeeById.workPermitStartDate || null,
    workPermitEndDate: employeeById.workPermitEndDate || null,
    residencePermitStartDate: employeeById.residencePermitStartDate || null,
    residencePermitEndDate: employeeById.residencePermitEndDate || null,
    employeePhotoAsBase64: `${BASE_URL}/file/employeeById.profileImage`,
    profileImage: employeeById.profileImage,
  };

  function getChanges(newData, oldData) {
    let changesData = {
      section: "personal-info",

      changes: [
        {
          operation: "update",
          data: {},
        },
      ],
    };

    const keys = Object.keys(newData);
    for (const key of keys) {
      if (
        typeof oldData[key] !== "object" &&
        oldData[key] !== newData[key] &&
        key === "employeePhotoAsBase64"
      ) {
        changesData.changes[0].data[key] = {
          old: oldData.profileImage,
          new: newData[key],
        };
      } else if (
        typeof oldData[key] !== "object" &&
        oldData[key] !== newData[key]
      ) {
        changesData.changes[0].data[key] = {
          old: oldData[key],
          new: newData[key],
        };
      } else if (
        oldData[key]?.code &&
        (oldData[key]?.code !== newData[key]?.code ||
          oldData[key]?.number !== newData[key]?.number)
      ) {
        changesData.changes[0].data[key] = {
          old: oldData[key].code + oldData[key].number,
          new: newData[key].code + newData[key].number,
        };
      }
    }
    sendPersonalInfoChanges(changesData);
  }

  const submitForm = (newData) => {
    getChanges(newData, initialValues);
  };
  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={personalInformationValidation}
          onSubmit={submitForm}
        >
          {({
            errors,
            isValid,
            touched,
            isSubmitting,
            handleSubmit,
            setFieldValue,
            setErrors,
            values,
          }) => {
            return (
              <Form
                style={
                  !formCorrection
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <Grid
                  sx={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Grid sx={{ width: "80%" }}>
                    <Typography variant="h6">Şəxsi məlumatlar</Typography>

                    {/* Name and surname */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name="firstname"
                          as={TextField}
                          fullWidth
                          label="Ad"
                          error={
                            errors?.firstname && Boolean(errors?.firstname)
                          }
                          helperText={errors?.firstname && errors?.firstname}
                          // InputProps={{ readOnly: true }}
                          onChange={(e) => {
                            setRequestButton(true);
                            setFieldValue("firstname", e.target.value);
                            if (errors.firstname) {
                              setErrors({ ...errors, firstname: "" });
                            }
                          }}
                        />
                      </Grid>
                      <Grid
                        sx={{
                          width: "100%",
                          display: "flex",
                          gap: 3,
                          alignItems: "center",
                        }}
                      >
                        <Field
                          type="text"
                          name="lastname"
                          as={TextField}
                          label="Soyad"
                          error={errors.lastname && Boolean(errors.lastname)}
                          helperText={errors.lastname && errors.lastname}
                          // InputProps={{ readOnly: true }}
                          fullWidth
                          onChange={(e) => {
                            setRequestButton(true);
                            setFieldValue("lastname", e.target.value);
                            // if (errors.lastname) {
                            //   setErrors({ ...errors, lastname: "" });
                            // }
                          }}
                        />
                        <FormControlLabel
                          style={!formCorrection ? { display: "none" } : {}}
                          control={
                            <Checkbox
                              onChange={(e) => {
                                setOldNameCheckbox(!oldNameCheckbox);
                              }}
                              name="Old Name Checkbox"
                              color="primary"
                            />
                          }
                          label="Yeniləmədən sonra köhnə soyadı göstər"
                        />
                      </Grid>
                    </Grid>

                    {/* Father name and gender */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name="fathername"
                          as={TextField}
                          fullWidth
                          label="Ata adı"
                          onChange={(e) => {
                            setRequestButton(true);
                            setFieldValue("fathername", e.target.value);
                          }}
                          // InputProps={{ readOnly: true }}
                          error={
                            errors.fathername && Boolean(errors.fathername)
                          }
                          helperText={errors.fathername && errors.fathername}
                        />
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={errors.gender && errors.gender}
                          fullWidth
                        >
                          <InputLabel id="gender">Cinsi</InputLabel>
                          <Field
                            as={Select}
                            name="gender"
                            labelId="gender"
                            label="Cinsi"
                            id="gender-select"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            onChange={(e) => {
                              setRequestButton(true);
                              setFieldValue("gender", e.target.value);
                            }}
                            error={errors.gender && Boolean(errors.gender)}
                          >
                            <MenuItem value="male">Kişi</MenuItem>
                            <MenuItem value="female">Qadın</MenuItem>
                          </Field>
                          {errors.gender && errors.gender && (
                            <FormHelperText error>
                              {errors.gender}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/* Birthday and place of birth */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Field name="dob">
                        {({ field, form }) => (
                          <DatePicker
                            {...field}
                            label="Doğum tarixi"
                            inputFormat="dd/MM/yyyy"
                            value={field.value}
                            InputProps={{ readOnly: true }}
                            onChange={(date) => {
                              setRequestButton(true);
                              if (date instanceof Date && !isNaN(date)) {
                                form.setFieldValue("dob", date);
                              } else {
                                form.setFieldValue("dob", "");
                              }
                            }}
                            maxDate={new Date()}
                            minDate={new Date(1940, 0, 1)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={form.errors.dob}
                                error={Boolean(form.errors.dob)}
                                fullWidth
                              />
                            )}
                          />
                        )}
                      </Field>

                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name="placeOfBirth"
                          as={TextField}
                          fullWidth
                          label="Doğulduğu yer"
                          // InputProps={{ readOnly: true }}
                          onChange={(e) => {
                            setRequestButton(true);
                            setFieldValue("placeOfBirth", e.target.value);
                          }}
                          error={
                            errors.placeOfBirth && Boolean(errors.placeOfBirth)
                          }
                          helperText={
                            errors.placeOfBirth && errors.placeOfBirth
                          }
                        />
                      </Grid>
                    </Grid>

                    {/* Nationality and idCardAuthority */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                        <FormControl
                          error={errors.nationality && errors.nationality}
                          fullWidth
                        >
                          <InputLabel id="nationality">Milliyyət</InputLabel>
                          <Field
                            as={Select}
                            name="nationality"
                            labelId="nationality"
                            label="nationality"
                            id="nationality-select"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            onChange={(e) => {
                              setRequestButton(true);
                              setFieldValue("nationality", e.target.value);
                            }}
                            error={
                              errors.nationality && Boolean(errors.nationality)
                            }
                          >
                            {countries.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </Field>
                          {errors.nationality && errors.nationality && (
                            <FormHelperText error>
                              {errors.nationality}
                            </FormHelperText>
                          )}
                        </FormControl>
                        {employeeById.isForeigner === true ? (
                          <FormControl
                            error={errors.citizenship && errors.citizenship}
                            fullWidth
                          >
                            <InputLabel id="citizenship">
                              Vətəndaşlığı
                            </InputLabel>
                            <Field
                              as={Select}
                              name="citizenship"
                              labelId="citizenship"
                              label="citizenship"
                              id="citizenship-select"
                              fullWidth
                              InputProps={{ readOnly: true }}
                              onChange={(e) => {
                                setRequestButton(true);
                                setFieldValue("citizenship", e.target.value);
                              }}
                              error={
                                errors.citizenship &&
                                Boolean(errors.citizenship)
                              }
                              required
                            >
                              {countries.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Field>
                            {errors.nationality && errors.nationality && (
                              <FormHelperText error>
                                {errors.nationality}
                              </FormHelperText>
                            )}
                          </FormControl>
                        ) : null}
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name="idCardAuthority"
                          as={TextField}
                          // InputProps={{ readOnly: true }}
                          fullWidth
                          onChange={(e) => {
                            setRequestButton(true);
                            setFieldValue("idCardAuthority", e.target.value);
                          }}
                          label={
                            employeeById.isForeigner === true
                              ? "MYİ-ni verən təşkilat"
                              : "Şəxsiyyət vəsiqəsini verən təşkilat"
                          }
                          error={
                            errors.idCardAuthority &&
                            Boolean(errors.idCardAuthority)
                          }
                          helperText={
                            errors.idCardAuthority && errors.idCardAuthority
                          }
                        />
                      </Grid>
                    </Grid>

                    {/* National ID and PIN */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                        {employeeById.isForeigner === true ? null : (
                          <Grid sx={{ width: 1 / 5 }}>
                            <Field
                              as={Select}
                              name={`idCardNumber.code`}
                              labelId={`idCardNumber.code`}
                              label="*"
                              id={`idCardNumber.code`}
                              InputProps={{ readOnly: true }}
                              defaultValue=""
                              fullWidth
                              onChange={(e) => {
                                setRequestButton(true);
                                setFieldValue(
                                  "idCardNumber.code",
                                  e.target.value
                                );
                              }}
                              error={
                                errors.idCardNumber?.code &&
                                Boolean(errors.idCardNumber?.code)
                              }
                              helpertext={
                                errors.idCardNumber?.code &&
                                errors.idCardNumber?.code
                              }
                              required
                            >
                              <MenuItem value="AA">AA</MenuItem>
                              <MenuItem value="AZE">AZE</MenuItem>
                            </Field>
                          </Grid>
                        )}

                        <Grid
                          sx={{
                            width:
                              employeeById.isForeigner === true
                                ? "100%"
                                : 4 / 5,
                          }}
                        >
                          <Field
                            type="text"
                            name="idCardNumber.number"
                            as={TextField}
                            fullWidth
                            // InputProps={{ readOnly: true }}
                            onChange={(e) => {
                              setRequestButton(true);
                              setFieldValue(
                                "idCardNumber.number",
                                e.target.value
                              );
                            }}
                            label={
                              employeeById.isForeigner === true
                                ? "Passport №"
                                : "Ş.V/Seriya №"
                            }
                            error={
                              errors.idCardNumber?.number &&
                              Boolean(errors.idCardNumber?.number)
                            }
                            helperText={
                              errors.idCardNumber?.number &&
                              errors.idCardNumber?.number
                            }
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid sx={{ width: "100%", display: "flex", gap: 3 }}>
                        <Field
                          type="text"
                          name="pin"
                          as={TextField}
                          fullWidth
                          label="Fərdi identifikasiya Nömrəsi (FİN)"
                          // InputProps={{ readOnly: true }}
                          onChange={(event) => {
                            setRequestButton(true);
                            const uppercaseValue =
                              event.target.value.toUpperCase();
                            setFieldValue("pin", uppercaseValue);
                          }}
                          error={errors.pin && Boolean(errors.pin)}
                          helperText={errors.pin && errors.pin}
                        />
                        {employeeById.isForeigner === true ? (
                          <Field
                            type="text"
                            name="tRPNumber"
                            as={TextField}
                            fullWidth
                            label="MYİ seriya Nömrəsi"
                            error={
                              errors.tRPNumber && Boolean(errors.tRPNumber)
                            }
                            helperText={errors.tRPNumber && errors.tRPNumber}
                            required
                          />
                        ) : null}
                      </Grid>
                    </Grid>

                    {/* National id given date and validity period */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Field name="idCardIssuanceDate">
                        {({ field, form }) => (
                          <DatePicker
                            // readOnly={true}
                            {...field}
                            label={
                              employeeById.isForeigner === true
                                ? "Passport verilmə tarixi"
                                : "Ş.V. verilmə tarixi"
                            }
                            inputFormat="dd/MM/yyyy"
                            value={field.value}
                            maxDate={new Date()} // Disable future dates
                            minDate={new Date(1940, 0, 1)}
                            onChange={(date) => {
                              setRequestButton(true);
                              if (date instanceof Date && !isNaN(date)) {
                                // Set the correctly formatted Date object using setFieldValue
                                form.setFieldValue("idCardIssuanceDate", date);
                              } else {
                                // If the date is invalid, set the field value to an empty string
                                form.setFieldValue("idCardIssuanceDate", "");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={form.errors.idCardIssuanceDate} // Display the validation error message
                                error={Boolean(form.errors.idCardIssuanceDate)} // Set error state based on the validation error
                                fullWidth
                              />
                            )}
                          />
                        )}
                      </Field>

                      <Field name="idCardValidityPeriod">
                        {({ field, form }) => (
                          <DatePicker
                            // style={{ pointerEvents: "none" }}
                            {...field}
                            label={
                              employeeById.isForeigner === true
                                ? "Passport etibarlılıq müddəti"
                                : "Ş.V. etibarlılıq Müddəti"
                            }
                            inputFormat="dd/MM/yyyy"
                            value={field.value}
                            // readOnly={true}
                            minDate={new Date()} // Disable past dates
                            onChange={(date) => {
                              setRequestButton(true);
                              if (date instanceof Date && !isNaN(date)) {
                                form.setFieldValue(
                                  "idCardValidityPeriod",
                                  date
                                );
                              } else {
                                form.setFieldValue("idCardValidityPeriod", "");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // InputProps={{ readOnly: true }}
                                helperText={form.errors.idCardValidityPeriod} // Display the validation error message
                                error={Boolean(
                                  form.errors.idCardValidityPeriod
                                )} // Set error state based on the validation error
                                fullWidth
                              />
                            )}
                          />
                        )}
                      </Field>
                    </Grid>

                    {/* For foreign employeers */}
                    {employeeById.isForeigner === true ? (
                      <>
                        {/* Living permission start and end date */}
                        <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                          <Field name="residencePermitStartDate">
                            {({ field, form }) => (
                              <DatePicker
                                {...field}
                                label="Yaşamaq icazəsi başlama tarixi *"
                                inputFormat="dd/MM/yyyy"
                                value={field.value || null}
                                maxDate={new Date()} // Disable future dates
                                minDate={new Date(1940, 0, 1)}
                                onChange={(date) => {
                                  setRequestButton(true);
                                  if (date instanceof Date && !isNaN(date)) {
                                    form.setFieldValue(
                                      "residencePermitStartDate",
                                      date
                                    );
                                  } else {
                                    form.setFieldValue(
                                      "residencePermitStartDate",
                                      ""
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    helperText={
                                      form.errors.residencePermitStartDate
                                    } // Display the validation error message
                                    error={Boolean(
                                      form.errors.residencePermitStartDate
                                    )} // Set error state based on the validation error
                                    fullWidth
                                  />
                                )}
                                required
                              />
                            )}
                          </Field>
                          <Field name="residencePermitEndDate">
                            {({ field, form }) => (
                              <DatePicker
                                {...field}
                                label="Yaşamaq icazəsi bitmə tarixi *"
                                inputFormat="dd/MM/yyyy"
                                value={field.value || null}
                                minDate={new Date()} // Disable past dates
                                onChange={(date) => {
                                  setRequestButton(true);
                                  if (date instanceof Date && !isNaN(date)) {
                                    form.setFieldValue(
                                      "residencePermitEndDate",
                                      date
                                    );
                                  } else {
                                    form.setFieldValue(
                                      "residencePermitEndDate",
                                      ""
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    helperText={
                                      form.errors.residencePermitEndDate
                                    } // Display the validation error message
                                    error={Boolean(
                                      form.errors.residencePermitEndDate
                                    )} // Set error state based on the validation error
                                    fullWidth
                                  />
                                )}
                                required
                              />
                            )}
                          </Field>
                        </Grid>

                        {/* Work permission start and end date */}
                        <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                          <Field name="workPermitStartDate">
                            {({ field, form }) => (
                              <DatePicker
                                {...field}
                                label="İş icazəsi başlama tarixi *"
                                inputFormat="dd/MM/yyyy"
                                value={field.value || null}
                                maxDate={new Date()} // Disable future dates
                                minDate={new Date(1940, 0, 1)}
                                onChange={(date) => {
                                  setRequestButton(true);
                                  if (date instanceof Date && !isNaN(date)) {
                                    form.setFieldValue(
                                      "workPermitStartDate",
                                      date
                                    );
                                  } else {
                                    form.setFieldValue(
                                      "workPermitStartDate",
                                      ""
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    helperText={form.errors.workPermitStartDate}
                                    error={Boolean(
                                      form.errors.workPermitStartDate
                                    )}
                                    fullWidth
                                  />
                                )}
                                required
                              />
                            )}
                          </Field>
                          <Field name="workPermitEndDate">
                            {({ field, form }) => (
                              <DatePicker
                                {...field}
                                label="İş icazəsi bitmə tarixi *"
                                inputFormat="dd/MM/yyyy"
                                value={field.value || null}
                                minDate={new Date()} // Disable past dates
                                onChange={(date) => {
                                  setRequestButton(true);
                                  if (date instanceof Date && !isNaN(date)) {
                                    form.setFieldValue(
                                      "workPermitEndDate",
                                      date
                                    );
                                  } else {
                                    form.setFieldValue("workPermitEndDate", "");
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    helperText={form.errors.workPermitEndDate}
                                    error={Boolean(
                                      form.errors.workPermitEndDate
                                    )}
                                    fullWidth
                                  />
                                )}
                                required
                              />
                            )}
                          </Field>
                        </Grid>
                      </>
                    ) : null}

                    {/* SSN */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "50%" }}>
                        <Field
                          // InputProps={{ readOnly: true }}
                          type="text"
                          name="ssn"
                          as={TextField}
                          fullWidth
                          label="SSN"
                          error={errors.ssn && Boolean(errors.ssn)}
                          helperText={errors.ssn && errors.ssn}
                          onChange={(e) => {
                            setRequestButton(true);
                            setFieldValue("ssn", e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className={style.imageSection}>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        height: 1 / 7,
                        boxSizing: "border-box",
                      }}
                    >
                      <Button
                        style={
                          formCorrection
                            ? {
                                backgroundColor: "rgba(121,46,46, 0.9)",
                                pointerEvents: "auto",
                              }
                            : {
                                pointerEvents: "auto",
                              }
                        }
                        onClick={onClickHandler}
                        variant="contained"
                        color="secondary"
                        className={style.reqButton}
                      >
                        {formCorrection ? "Ləğv et" : "Dəyişiklik et"}
                        <CreateIcon sx={{ marginLeft: 5 }} />
                      </Button>
                    </Grid>
                    <Grid
                      sx={{
                        height: 5 / 7,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          height: "60%",
                          width: "75%",
                          borderRadius: "50%",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="_image"
                          style={{
                            width: "220px",
                            height: "220px",
                          }}
                          src={
                            profilPicture
                              ? profilPicture
                              : values.profileImage
                              ? values.profileImage
                              : picture
                          }
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "5px",
                          width: "70%",
                          paddingTop: "20px",
                        }}
                      >
                        <div
                          style={
                            formCorrection
                              ? { visibility: "visible" }
                              : { visibility: "hidden" }
                          }
                        >
                          <label htmlFor="image">Şəkli dəyiş</label>
                          <Field
                            type="file"
                            id="image"
                            name="image"
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              const reader = new FileReader();
                              reader.onloadend = () => {
                                const base64String = reader.result;
                                setProfilPicture(base64String);

                                setFieldValue(
                                  "employeePhotoAsBase64",
                                  base64String
                                );
                              };
                              reader.readAsDataURL(file);
                              setRequestButton(true);
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      style={
                        formCorrection
                          ? { visibility: "visible", marginTop: "10px" }
                          : { visibility: "hidden" }
                      }
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        height: 1 / 7,
                        boxSizing: "border-box",
                      }}
                    >
                      <Button
                        type="submit"
                        style={
                          !requestButton
                            ? { pointerEvents: "none" }
                            : {
                                backgroundColor: "#28358a",
                                pointerEvents: "auto",
                              }
                        }
                        variant="contained"
                        color="secondary"
                        className={style.reqButton}
                      >
                        Dəyişikliklər sorğusu göndər
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      ) : (
        // </Grid>
        <div>Loading...</div>
      )}
    </>
  );
}

export default MCEditPersonalInformation;
