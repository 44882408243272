import { api } from "./axiosInterceptor";

export const getTickets = async (limit, offset) => {
  const response = await api.get("/support/client/ticket", {
    params: {
      limit,
      offset,
    },
  });

  return response.data;
};
export const getTicketById = async (id) => {
  const response = await api.get(`/support/client/ticket/${id}`);
  return response.data;
};

export const createTicket = async (createTicketData) => {
  const response = await api.post("/support/client/ticket", createTicketData);

  return response.data;
};

export const closeTicket = async (id) => {
  const response = await api.patch(`/support/client/ticket/${id}/close`);
  return response.data;
};

export const openTicket = async (id) => {
  const response = await api.patch(`/support/client/ticket/${id}/open`);
  return response.data;
};
export const addReply = async (id, replyData) => {
  const response = await api.post(
    `/support/client/ticket/${id}/reply`,
    replyData
  );
  return response.data;
};

export const getCategory = async () => {
  const response = await api.get("/support/client/category");
  return response.data;
};

export const getFile = async (fileName) => {
  const response = await api.get(`/support/client/files/${fileName}`);

  return response.data;
};
