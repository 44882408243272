import React, { useState } from "react";
import Table from "../../../components/table/Table";
import Column from "../../../models/table/Column";
import { GENERAL_BLOCK } from "../../../styled";

export default function HoursTable({ shift }) {
  const [data, setData] = useState([
    {
      workHours: "09:00 - 13:00",
      workDays: 20,
      dayOffs: 11,
      monthlyHours: 80,
    },
    {
      workHours: "08:00 - 14:00",
      workDays: 20,
      dayOffs: 11,
      monthlyHours: 80,
    },
    {
      workHours: "10:00 - 18:00",
      workDays: 20,
      dayOffs: 11,
      monthlyHours: 80,
    },
  ]);
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  const columns = [
    new Column({
      headerName: "Qrafik tipi",
      path: "data.scheduleType",
      disableColSearch: true,
      // filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) => {
        return row.scheduleType === "shift"
          ? "Növbəli"
          : row?.scheduleType === "flexible"
          ? "Sürüşkən"
          : "Standart";
      },
    }),
    new Column({
      headerName: "Qrafik təsnifatı",
      path: "data.details?.totalHours",
      disableColSearch: true,
      // filterType: OPERATORS_CONTAINING.DATE,
      getValue: (row, column) => {
        return row?.scheduleType === "standard" ? (
          <>
            {row?.schedules?.map((s) => (
              <div className="d-flex justify-content-evenly">
                <div>
                  <b>İş saatı</b>
                  <GENERAL_BLOCK
                    // bg_color={COLORS_STATUS.GREEN}
                    brad="5px"
                    className="mb-2"
                  >
                    {s?.jobStartTime?.hour?.toString().padStart(2, "0")}:
                    {s?.jobStartTime?.minute?.toString().padStart(2, "0")}-
                    {s?.jobEndTime?.hour?.toString().padStart(2, "0")}:
                    {s?.jobEndTime?.minute?.toString().padStart(2, "0")}
                  </GENERAL_BLOCK>
                </div>
                <div>
                  <b>Nahar saatı</b>
                  <GENERAL_BLOCK
                    // bg_color={COLORS_STATUS.RED}
                    brad="5px"
                    className="mb-2"
                  >
                    {s?.restStartTime?.hour?.toString().padStart(2, "0")}-
                    {s?.restStartTime?.minute?.toString().padStart(2, "0")}:
                    {s?.restEndTime?.hour?.toString().padStart(2, "0")}:
                    {s?.restEndTime?.minute?.toString().padStart(2, "0")}
                  </GENERAL_BLOCK>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <b>İş saatı</b>
            <GENERAL_BLOCK
              // bg_color={COLORS_STATUS.GREEN}
              brad="5px"
              className="mb-2"
            >
              {row?.schedules?.map((s) => (
                <div>
                  {s?.jobStartTime?.hour?.toString().padStart(1, "0")}:
                  {s?.jobStartTime?.minute?.toString().padStart(2, "0")}
                  {" - "}
                  {s?.jobEndTime?.hour?.toString().padStart(1, "0")}:
                  {s?.jobEndTime?.minute?.toString().padStart(2, "0")}
                </div>
              ))}
              {/* {row?.schedules?.[0]?.jobStartTime?.hour}:
              {row?.schedules?.[0]?.jobStartTime?.minute}-
              {row?.schedules?.[0]?.jobEndTime?.hour}:
              {row?.schedules?.[0]?.jobEndTime?.minute} */}
            </GENERAL_BLOCK>
            {/* <GENERAL_BLOCK
              // bg_color={COLORS_STATUS.RED}
              brad="5px"
              className="mb-2"
            >
              {row?.schedules?.[0]?.restStartTime?.hour}-
              {row?.schedules?.[0]?.restEndTime?.minute}:
              {row?.schedules?.[0]?.restStartTime?.hour}-
              {row?.schedules?.[0]?.restEndTime?.minute}
            </GENERAL_BLOCK> */}
          </>
        );
      },
    }),
    new Column({
      headerName: "İş saatları",
      path: "data.details?.totalHours",
      disableColSearch: true,
      getValue: (row, column) => {
        let key;
        let arr = [];
        for (let i = 0; i < Object.keys(row?.schedules).length; i++) {
          key = i + 1;
          arr.push(row?.details[key]);
        }
        return (
          <>
            {arr?.map((s) => (
              <div className="d-flex justify-content-evenly">
                {s?.toFixed(2)}
              </div>
            ))}
          </>
        );
      },
    }),
    new Column({
      headerName: "İş günləri",
      path: "data.details?.totalDays",
      disableColSearch: true,
      getValue: (row, column) => {
        return row.details?.totalDays;
      },
    }),
    new Column({
      path: "data.details?.totalOffDays",
      disableColSearch: true,
      headerName: "Qeyri-iş günləri",
      getValue: (row, column) => {
        let len = row?.schedules?.length;
        return row?.details?.totalOffDays;
      },
    }),
  ];

  return (
    <div>
      <Table
        columns={columns}
        data={shift}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        isLoading={loading}
        applyFiltersHandler={() => {}}
      />
    </div>
  );
}
