import { Delete, EditSharp } from "@mui/icons-material";
import { Button, Dialog, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import HeaderDialog from "../../../components/dialogs/HeaderDialog";
import ExportCSVButton from "../../../components/layouts/button/ExportCSVButton";
import Table from "../../../components/table/Table";
import { OPERATORS_CONTAINING } from "../../../constants";
import Column from "../../../models/table/Column";
import {
  downloadEmpNotificationList,
  getEmployeeNotificationList,
  removeEmployeeNotificationList,
} from "../../../services/documentations/EmploymentContractsService";
import { downloadFile } from "../../../utils/downloadCSV";
import AddEmploymentDialog from "./AddEmploymentDialog";

const expColumn = [
  {
    fieldName: "notificationType",
    path: "notificationType",
  },
];

//mode - "notification"  "mail"
export default function ContractsDialog({ onClose, mode }) {
  const modeOfDialog = mode === "mail" ? "mail" : "system";

  //add new employment dialog
  const [isShow, setIsShow] = useState(false);
  const [exportData, setExportData] = useState({
    header: [],
    data: [],
  });
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState(null);

  const [data, setData] = useState();
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    getEmployeesList();
  }, []);

  const getEmployeesList = async () => {
    try {
      setIsLoading(true);
      const { data } = await getEmployeeNotificationList();

      const dataModified = data[modeOfDialog].reduce((result, item) => {
        if (item && item.employee && item.employee.id !== null) {
          result.push({ ...item, id: item.employee.id });
        }
        return result;
      }, []);

      // setList([...dataModified]);
      setData(dataModified);
      setTotal(data[modeOfDialog].length);
      setIsLoading(false);
    } catch (error) {
      console.log("err", error);
      setIsLoading(false);
    }
  };

  const onCloseHandler = () => {
    setIsShow(false);
    setEditData();
  };
  const onShowHandler = () => {
    setIsShow(true);
  };

  const handleExport = async (columns) => {
    try {
      const forExport = [];
      columns.forEach((column) => {
        switch (column.field) {
          case "fullName":
            forExport.push({
              fieldName: "Əməkdaşın adı, soyadı, ata adı",
              path: "employee",
              combineFields: ["firstname", "lastname", "fathername"],
            });
            break;
          case "notificationDate":
            forExport.push(
              {
                fieldName: "Müddət tipi",
                path: "timeUnit",
              },
              {
                fieldName: "Müddət",
                path: "timeValue",
              }
            );
            break;
          case "email":
            forExport.push({
              fieldName: "Email",
              path: "employee.email",
            });
            break;
          default:
            break;
        }
      });

      // columns.forEach((item) => {
      //   if (item.isStaticColumn || !item.isShow) return;
      //   forExport.push({
      //     fieldName: item.headerName,
      //     path: item.path,
      //     isArr: item.forExportIsArr,
      //     combineFields:
      //       item.combineFields?.length > 0 ? item.combineFields : undefined,
      //   });
      // });

      const encodedFilter = encodeURIComponent(JSON.stringify(forExport));
      const resp = await downloadEmpNotificationList(
        modeOfDialog,
        encodedFilter
      );
      resp && downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };

  const columns =
    mode === "mail"
      ? [
          new Column({
            path: "fullName",
            headerName: "Əməklaş A.S.A.",
            operatorType: OPERATORS_CONTAINING.TEXT,
            hasCellButton: true,
            // cellButtonProps: {
            //   icon: <Person />,
            //   onClick: (value) =>
            //     window.open(`${BASE_UR}/employees/${value}/view`),
            //   value: (row) => {
            //     return getDescendantProp(row, "originalRequest.employee.id");
            //   },
            // },
            getValue: (row, column) => {
              const item = row.employee;
              return `${
                item?.firstname + " " + item?.lastname + " " + item?.fathername
              }`;
            },
          }),
          new Column({
            path: "employee.email",
            headerName: "Email",
            operatorType: OPERATORS_CONTAINING.TEXT,
            getValue: (row) => {
              const item = row.employee;
              return item?.email;
            },
          }),
          new Column({
            path: "notificationDate",
            headerName: "Bildiriş alacağı vaxt",
            operatorType: OPERATORS_CONTAINING.TIME,
            getValue: (row) => {
              const date = row;
              return notificationDateHandler({
                type: date.timeUnit,
                value: date.timeValue,
              });
            },
          }),
          new Column({
            path: "#", // Add a custom column for the button
            isStaticColumn: true,
            headerName: "Operatorlar",
            renderCell: (row, col) => (
              <>
                <IconButton
                  onClick={() => {
                    handleRowBtnClick(row.id);
                  }}
                  width={1}
                >
                  <Delete />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setEditData(row);
                    onShowHandler();
                  }}
                  width={1}
                >
                  <EditSharp />
                </IconButton>
              </>
            ),
          }),
        ]
      : [
          new Column({
            path: "fullName",
            headerName: "Əməklaş A.S.A.",
            // hasCellButton: true,
            // cellButtonProps: {
            //   icon: <Person />,
            //   onClick: (value) =>
            //     window.open(`${BASE_URL}/employees/${value}/view`),
            //   value: (row) => {
            //     return getDescendantProp(row, "originalRequest.employee.id");
            //   },
            // },
            operatorType: OPERATORS_CONTAINING.TEXT,
            getValue: (row) => {
              const item = row.employee;
              return `${
                item?.firstname + " " + item?.lastname + " " + item?.fathername
              }`;
            },
          }),
          new Column({
            path: "notificationDate",
            headerName: "Bildiriş alacağı vaxt",
            operatorType: OPERATORS_CONTAINING.TIME,
            getValue: (row) => {
              const date = row;
              return notificationDateHandler({
                type: date.timeUnit,
                value: date.timeValue,
              });
            },
          }),
          new Column({
            path: "#", // Add a custom column for the button
            isStaticColumn: true,
            headerName: "Operatorlar",
            renderCell: (row) => (
              <Grid sx={{ margin: "auto" }}>
                <IconButton onClick={() => handleRowBtnClick(row.id)} width={1}>
                  <Delete />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setEditData(row);
                    onShowHandler();
                  }}
                  width={1}
                >
                  <EditSharp />
                </IconButton>
              </Grid>
            ),
          }),
        ];

  const notificationDateHandler = (data) => {
    switch (data.type) {
      case "day":
        return `${data.value} gün əvvəl`;
      case "week":
        return `${data.value} həftə əvvəl`;
      case "month":
        return `${data.value} ay əvvəl`;
      case "year":
        return `${data.value} il əvvəl`;
      default:
        break;
    }
  };

  const headerTitle =
    mode === "notification"
      ? "Bildiriş alacaq əməkdaşlar"
      : "Mail alacaq əməkdaşlar";

  const handleRowBtnClick = async (id) => {
    try {
      const { data } = await removeEmployeeNotificationList({
        employeeId: id,
        notificationType: modeOfDialog,
      });
      toast.info("Silindi", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      await getEmployeesList();
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <Grid sx={styles.container}>
      <ToastContainer />
      <HeaderDialog title={headerTitle} onClose={onClose} />
      <Typography
        width={"100%"}
        textAlign={"center"}
        sx={{ marginY: "10px", color: "red" }}
      >
        Rəhbər şəxsə avtomatik olaraq bildiriş göndərilir
      </Typography>

      <Grid>
        <Grid sx={styles.buttonContainer}>
          <ExportCSVButton />
          <Button variant="contained" onClick={onShowHandler}>
            <Typography>Əlavə et</Typography>
          </Button>
        </Grid>

        <Table
          data={data}
          columns={columns}
          totalRows={total}
          limit={limit}
          setLimit={setLimit}
          offset={offset}
          setOffset={setOffset}
          hideFilter={true}
          isLoading={isLoading}
          downloadHandler={handleExport}
        />
      </Grid>
      <Dialog open={isShow} onClose={onCloseHandler}>
        <AddEmploymentDialog
          onClose={onCloseHandler}
          mode={mode}
          refresh={getEmployeesList}
          currList={data}
          editData={editData}
        />
      </Dialog>
    </Grid>
  );
}

const styles = {
  container: { padding: 5, paddingTop: 10, width: "100%" },
  closeContainer: {
    right: 2.5,
    position: "absolute",
    top: 2.5,
  },
  headerContainer: {
    width: "100%",
    margin: "auto",
    marginBottom: 5,
  },
  buttonContainer: {
    width: "100%",
    marginBottom: "4px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
