import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";

const AddBoard = ({ openAddBoard, setOpenAddBoard, handleCreateBoard }) => {
  const [boardName, setBoardName] = useState("");

  return (
    <>
      <Dialog
        fullWidth
        open={openAddBoard}
        onClose={() => {
          setOpenAddBoard(false);
          setBoardName("");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Yeni lövhə əlavə et</Typography>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
        To subscribe to this website, please enter your email address
        here. We will send updates occasionally.
      </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Adı"
            type="text"
            onChange={(e) => setBoardName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAddBoard(false);
              setBoardName("");
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setOpenAddBoard(false);
              handleCreateBoard(boardName);
            }}
            color="primary"
            disabled={boardName.trim() === "" ? true : false}
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddBoard;
