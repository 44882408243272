import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Box, Paper, TextField, Typography } from "@mui/material";
import { updateCard } from "../../../api/tasks/taskRequests";
import { removeEmptyStrings } from "../../../utils/removeEmptyString";
import { Error } from "@mui/icons-material";

const SelectDate = ({ taskDetails, submitted, setSubmitted }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDateError(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    if (taskDetails?.startDate) {
      setStartDate(taskDetails?.startDate);
    }
    if (taskDetails?.dueDate) {
      setEndDate(taskDetails?.dueDate);
    }
  }, [anchorEl]);
  useEffect(() => {
    if (startDate && endDate && startDate > endDate) {
      setDateError(true);
    } else if (startDate && endDate && startDate < endDate) {
      setDateError(false);
    }
  }, [endDate, startDate]);
  const handleAddDateToCard = async () => {
    let data = {
      startDate: startDate,
      dueDate: endDate,
    };

    await updateCard(taskDetails._id, data);
    setSubmitted(!submitted);
  };
  return (
    <div className="basicMenu">
      <Button
        variant="contained"
        startIcon={<WatchLaterIcon />}
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          mb: 3,
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Tarixlər
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          width: "100%",
          "&:.MuiMenu-paper": {
            backgroundColor: "white",
          },
          "&:.MuiMenu-root": {
            backgroundColor: "white",
          },
        }}
      >
        <MenuItem
          sx={{
            display: "flex",
            gap: "10px",
            py: 5,
            px: 2,
            cursor: "default",
            "&:hover": {
              //   pointerEvents: "none",
              backgroundColor: "inherit",
            },
            "&.Mui-selected": {
              backgroundColor: "inherit",
            },
            "&.MuiMenuItem-root": {
              backgroundColor: "inherit",
            },
          }}
        >
          <Typography>Başlama tarixi</Typography>
          <div className="dateBox">
            <MobileDateTimePicker
              value={startDate || null}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              label="Başlama tarixi"
              onError={console.log}
              inputFormat="yyyy/MM/dd hh:mm a"
              mask="____/__/__ __:__ _M"
              renderInput={(params) => <TextField {...params} />}
            />
            {startDate && endDate && startDate > endDate ? (
              <span>Başlama tarixi bitmə tarixindən sonra ola bilməz</span>
            ) : null}
          </div>
        </MenuItem>
        <MenuItem
          sx={{
            display: "flex",
            gap: "25px",
            py: 5,
            px: 2,
            "&:hover": {
              //   pointerEvents: "none",
              backgroundColor: "inherit",
            },
            "&.Mui-selected": {
              backgroundColor: "inherit",
            },
            "&.MuiMenuItem-root": {
              backgroundColor: "inherit",
            },
          }}
        >
          <Typography>Bitmə tarixi</Typography>
          <div className="dateBox">
            <MobileDateTimePicker
              value={endDate || null}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              label="Bitmə tarixi"
              inputFormat="yyyy/MM/dd hh:mm a"
              mask="____/__/__ __:__ _M"
              renderInput={(params) => <TextField {...params} />}
            />
            {startDate && endDate && startDate > endDate ? (
              <span>Başlama tarixi bitmə tarixindən sonra ola bilməz</span>
            ) : null}
          </div>
        </MenuItem>

        <Button
          onClick={() => {
            handleAddDateToCard();
            handleClose();
          }}
          sx={{ marginLeft: "8px" }}
          variant="contained"
          color="primary"
          disabled={dateError ? true : false}
        >
          Yadda saxla
        </Button>
      </Menu>
    </div>
  );
};

export default SelectDate;
