import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
  refreshToken: "",
};

export const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    deleteAccessToken: (state, action) => {
      state.accessToken = "";
    },
    deleteRefreshToken: (state, action) => {
      state.refreshToken = "";
    },
  },
});

export const {
  setAccessToken,
  setRefreshToken,
  deleteAccessToken,
  deleteRefreshToken,
} = AuthSlice.actions;

export default AuthSlice.reducer;
