import { getAccountingTable_API } from "../api/accountingTableRequests";

export const getAccountingTable_SERVICE = async ({
  year,
  month,
  structure,
  filter,
  limit,
  skip,
}) => {
  try {
    const response = await getAccountingTable_API(
      year,
      month,
      structure,
      filter,
      limit,
      skip
    );
    return response;
  } catch (error) {
    console.error("Service Error:", error);
    throw error;
  }
};
