import { Close, Done, DoneAllRounded, Send } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { postCommentSignContract } from "../../../services/documentations/signingContractServices";
import { styled } from "@mui/system";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setTriggingRefresh } from "../../../redux/features/slices/commonThings.slice";

const RotatingIconWrapper = styled("Grid")({
  animation: "rotate360 1s linear ", // Adjust the duration as needed
  display: "inline-block",
  "@keyframes rotate360": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});
export default function CommentDialog({
  type,
  onClose,
  id,
  closeSigningDialog,
  onSubmitHandler = false,
}) {
  const [value, setValue] = useState("");
  const [submited, setSubmited] = useState(false);
  const [isSuccessSign, setIsSuccessSign] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [typeDialog, setTypeDialog] = useState();

  useEffect(() => {
    setTypeDialog(type);
  }, []);

  const dispatch = useDispatch();
  const refreshTriggerState = useSelector(
    (state) => state.commonThings.refreshTrigger
  );

  const bkColor =
    typeDialog === "approved"
      ? "rgba(61,101,80,0.8)"
      : "rgba(198, 40, 40, 0.5)";

  const onCloseHandler = () => {
    onClose();
  };

  const onSubmit = async () => {
    const status = typeDialog === "approved" ? "approve" : "reject";
    setIsLoading(true);

    if (onSubmitHandler) {
      onSubmitHandler(status, value);
    } else {
      try {
        const resp = await postCommentSignContract(
          { status: status, description: value },
          id
        );
        setIsLoading(false);
        if (resp.status === 200 || resp.status === 201) {
          setIsSuccessSign(true);
          setSubmited(true);
          dispatch(setTriggingRefresh(!refreshTriggerState));
          setTimeout(() => {
            onClose();
            closeSigningDialog();
          }, 2000);
        }
      } catch (error) {
        console.log("comment", error);
        const message =
          error.response.status === 401
            ? "Bu əməliyyat üçün səlahiyyətiniz yoxdur"
            : "Xəta baş verdi";
        setIsLoading(false);
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }

    setIsLoading(false);
  };
  const afterSubmit = (
    <Grid sx={styles.spinnerContainer}>
      <RotatingIconWrapper>
        {isSuccessSign &&
          (typeDialog === "approved" ? (
            <Done sx={styles.spinnerIcon} />
          ) : (
            <Close sx={styles.spinnerIcon} />
          ))}
      </RotatingIconWrapper>
      <Typography textAlign={"center"} fontSize={32} sx={{ marginY: "10px" }}>
        {typeDialog === "approved"
          ? "Müvəffəqiyyətlə təsdiq edildi"
          : "Sənəd Təsdiqlənmədi"}
      </Typography>
    </Grid>
  );

  return (
    <>
      <IconButton
        onClick={onCloseHandler}
        sx={styles.IconButton}
        hidden={submited === true}
      >
        <Close />
      </IconButton>
      <Grid
        sx={[
          styles.container,
          {
            backgroundColor: typeDialog ? bkColor : "#fff",
          },
        ]}
      >
        {submited ? (
          afterSubmit
        ) : (
          <>
            <Typography variant={"h1"} color={"white"} className="mb-4">
              {typeDialog === "approved" ? "Təsdiq" : "İmtina"}
            </Typography>

            <Typography width={"100%"}>
              Xahiş edirik {typeDialog === "approved" ? "təsdiq" : "imtina"}{" "}
              səbəbini aşağıda qeyd edəsiniz.
            </Typography>
            <textarea
              onChange={(e) => {
                setValue(e.target.value);
              }}
              rows={7}
              placeholder={"Şərhinizi yazın..."}
              className={`w-100`}
              style={styles.textarea}
            ></textarea>

            <Grid width={"100%"} sx={styles.buttonContainer}>
              <Button
                title="Imtina"
                variant={"contained"}
                color={typeDialog === "approved" ? "success" : "error"}
                style={styles.button}
                onClick={onSubmit}
                disabled={isLoading || value.length === 0}
              >
                {!isLoading ? (
                  <>
                    <Send sx={{ marginRight: 1.5 }} />
                    <Typography>
                      {typeDialog === "approved" ? "Təsdiq" : "İmtina"}
                    </Typography>
                  </>
                ) : (
                  <CircularProgress color="inherit" size={24} />
                )}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

const styles = {
  container: {
    width: 1000,
    height: "auto",
    display: "flex",
    // justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "10px",
    paddingX: "28px",
    borderRadius: "10px",
  },
  header: {
    marginBottom: "20px",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "27px",
    marginBottom: "27px",
  },
  buttonContainer: { justifyContent: "flex-end", display: "flex" },
  textarea: { borderRadius: "10px", padding: "5px" },
  IconButton: { position: "absolute", right: "5px" },
  spinnerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px",
  },
  spinnerIcon: {
    width: "200px",
    height: "200px",
    border: "3px solid #fff",
    borderRadius: 100,
  },
};
