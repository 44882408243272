import React, { useEffect } from "react";
import useGraphicForm from "../../hooks/useGraphicForm";
import styles from "./GraphicSettings.module.css";
import GraphicModal from "./components/GraphicModal/GraphicModal";
import GraphicTabel from "./components/GraphicTabel/GraphicTabel";
import { Typography } from "@mui/material";
const GraphicSettings = () => {
  const {
    data,
    setData,
    getData,
    scheduleType,
    handleOpen,
    handleClose,
    limit,
    offset,
    filters,
    setFilters,
    setLimit,
    setOffset,
    open,
    setFormData,
    setInputSets,
    setNumGroups,
  } = useGraphicForm();

  useEffect(() => {
    getData();
  }, []);

  return (
    <section className={styles.graphicSettings}>
      <Typography variant="h3" gutterBottom display="inline">
        Qrafik sazlamaları
      </Typography>

      <GraphicModal
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        getData={getData}
        setData={setData}
      />
      <GraphicTabel
        setFormData={setFormData}
        handleOpen={handleOpen}
        data={data}
        setData={setData}
        limit={limit}
        offset={offset}
        filters={filters}
        setLimit={setLimit}
        setFilters={setFilters}
        setOffset={setOffset}
        getData={getData}
        setInputSets={setInputSets}
        setNumGroups={setNumGroups}
        scheduleType={scheduleType}
      />
    </section>
  );
};

export default GraphicSettings;
