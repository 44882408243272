export function removeEmptyStrings(obj) {
  if (typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    let data = obj
      .map(removeEmptyStrings)
      .filter((value) => value !== "" && Object.keys(value).length !== 0);
    return data;
  }

  const result = {};
  for (const key in obj) {
    const value = obj[key];
    if (key === "idCardValidityPeriod" && value === null) {
      // Only keep idCardValidityPeriod as null
      result[key] = null;
    } else if (value !== "" && value !== null && value !== undefined) {
      if (typeof value === "object") {
        const cleanedValue = removeEmptyStrings(value);
        if (cleanedValue !== null && cleanedValue !== undefined) {
          result[key] = cleanedValue;
        }
      } else {
        result[key] = value;
      }
    }
  }

  return result;
}
