import { api } from "../axiosInterceptor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { removeEmptyStrings } from "../../utils/removeEmptyString";

// Vacation Requests for employees
export const createVacationRequest = async (data) => {
  try {
    const response = await api.post(
      "employee/employee-request/user/vacationAppRequest",
      data
    );
    toast.success("Successfully created", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      const message = () => {
        switch (item.message) {
          case "Your remaining vacation days does not enough":
            return "Məzuniyyət günləriniz kifayət deyil";
          case "You already have ongoing vacation request":
            return "Qeyd olunan tarix aralığına dair məzuniyyət sorğusu mövcuddur";
          case "Employee must be part of active staff":
            return "Əməkdaş ştatda olmalıdır";
          case "Vacation end date can not be greater than contractEndDate":
            return "Məzuniyyətin bitmə tarixi müqavilənin bitmə tarixindən sonra ola bilməz";
          default:
            return item.message;
        }
      };
      return toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getVacationRequests = async (limit, offset) => {
  let params = {
    limit: limit,
    offset: offset,
  };
  try {
    const response = await api.get("employee/vacation-request", {
      params: params,
    });

    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getEmployeeVacationReport = async () => {
  try {
    const response = await api.get(`employee/cabinet/vacationReport`);
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      if (item?.message === "Employee not found") {
        return toast.error("Əməkdaş tapılmadı", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }
};
export const getEmployeeVacationDays = async (filters) => {
  try {
    const response = await api.get(
      `employee/employee-request/user/vacation-days?filters=${filters}`
    );
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getVacationsWithFilter = async (limit, offset, status) => {
  let params = {
    limit: limit,
    offset: offset,
    status: status,
  };
  try {
    const response = await api.get("employee/vacation-request/filter", {
      params: params,
    });

    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getVacationById = async (id) => {
  try {
    const response = await api.get(`employee/vacation-request/${id}`);

    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const updateVacation = async (id, data) => {
  try {
    const response = await api.put(`employee/vacation-request/${id}`, data);
    toast.success("Successfully updated", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const deleteVacation = async (id) => {
  try {
    const response = await api.delete(`employee/vacation-request/${id}`);

    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Vacation statistics
export const getVacationRequestsCount = async (
  id,
  totalStatus,
  requestType
) => {
  try {
    const params = {
      structureId: id,
      status: totalStatus,
      requestType: requestType,
    };
    const result = removeEmptyStrings(params);

    const response = await api.get(
      "employee/employee-request/statistics/total",
      { params: result }
    );
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

export const getPeriodicVacationRequests = async (
  id,
  startYear,
  periodType,
  requestType
) => {
  try {
    const params = {
      structureId: id,
      startYear: startYear,
      periodType: periodType,
      requestType: requestType,
    };
    const response = await api.get(
      "employee/employee-request/statistics/total/periodic",
      { params: params }
    );
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Get Employee At Work for Departments
export const getEmployeesAtWorkDep = async (id, limit, offset) => {
  try {
    let params = {
      limit: limit,
      offset: offset,
      structureId: id,
    };
    const response = await api.get(`employee/e/at-work/departments`, {
      params: params,
    });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Get Employees at work
export const getEmployeesAtWork = async (id) => {
  let params = {
    structureId: id,
  };
  try {
    const response = await api.get(`employee/e/at-work`, {
      params: params,
    });
    return response;
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};

// Download vacation request report for Employee
export const downloadVacationRequestEmployee = async (params) => {
  try {
    const response = await api.get(`employee/vacation-request/download`, {
      params,
    });
    // toast.success("Downloaded Successfully", {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    // });
    return response; // Return the response data
  } catch (err) {
    err.response.data.errors.map((item) => {
      return toast.error(item.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  }
};
