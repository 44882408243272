import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  structureById: null,
  selectedTypeIds: [],
  willChangeStructureName: false,
  checkedItems: [],
  isAllChecked: false,
  isClickChangeApproveRequest: false,
  isClickExportRequest: false,
};

export const structureSlice = createSlice({
  name: "structure",
  initialState,
  reducers: {
    setStructureDataById: (state, action) => {
      state.structureById = action.payload;
    },
    setSelectedTypeIds: (state, action) => {
      state.selectedTypeIds = action.payload;
    },
    setWillChangeStructureName: (state, action) => {
      state.willChangeStructureName = action.payload;
    },
    setCheckedItems: (state, action) => {
      state.checkedItems = action.payload;
    },
    setIsAllChecked: (state, action) => {
      state.isAllChecked = action.payload;
    },
    setIsClickChangeApproveRequest: (state, action) => {
      state.isClickChangeApproveRequest = action.payload;
    },
    setIsClickExportRequest: (state, action) => {
      state.isClickExportRequest = action.payload;
    },
  },
});

export const {
  setStructureDataById,
  setSelectedTypeIds,
  setWillChangeStructureName,
  setCheckedItems,
  setIsAllChecked,
  setIsClickChangeApproveRequest,
  setIsClickExportRequest,
} = structureSlice.actions;

export default structureSlice.reducer;
