import { Delete as DeleteIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from "react";
import {
  getGraphicSettings,
  getGraphicSettingsDownloadCSV,
  updateGraphicSetting,
} from "../../../../api/graphicSettings";
import OperatorGenerator from "../../../../components/table/OperatorGenerator";
import Table from "../../../../components/table/Table";
import { OPERATORS_CONTAINING } from "../../../../constants";
import Column from "../../../../models/table/Column";
import Operator from "../../../../models/table/Operator";
import { getDownloadStaffCSV } from "../../../../services/staffTracking/StaffTrackingServices";
import { downloadFile } from "../../../../utils/downloadCSV";
import GraphicEditModal from "./GraphicEditModal";
import DeleteGraphicDialog from "./DeleteGraphicDialog";
import { removeEmptyStrings } from "../../../../utils/removeEmptyString";
const GraphicTabel = ({
  data,
  getData,
  setData,
  scheduleType,
  limit,
  offset,
  filters,
  setLimit,
  setOffset,
  setFilters,
}) => {
  const [total, setTotal] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [graphicId, setGraphicId] = useState(null);
  const [editData, setEditData] = useState({
    scheduleType: "",
    workType: "",
    turnCount: 1,
    schedules: [],
  });
  const [inputCount, setInputCount] = useState(editData.turnCount);
  const [numGroups, setNumGroups] = useState(2);

  useEffect(() => {
    paginationHandler();
  }, [filters, offset, limit]);

  const paginationHandler = async () => {
    if (!filters.length > 0) {
      await getFilterGraphic();
      return;
    } else {
      const encode = encodeURIComponent(JSON.stringify(filters));
      await getFilterGraphic(encode);
    }
  };

  const getFilterGraphic = async (filters = undefined) => {
    try {
      setLoading(true);
      const payload = {
        offset: offset,
        limit: limit,
        filter: filters,
      };
      const { data } = await getGraphicSettings({
        limit: payload.limit,
        offset: payload.offset,
        filter: payload.filter,
      });
      setData(data?.records);
      setTotal(data?.total);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const allContExportHandler = async (columnsForExport) => {
    try {
      const forExport = [];
      const isWithFilter =
        filters.length > 0 && filters[0]?.column?.length > 0 ? true : false;
      columnsForExport.forEach((item) => {
        if (item.isStaticColumn || !item.isShow) return;

        forExport.push({
          fieldName: item.headerName,
          path: item.path,
          isArr: item.forExportIsArr,
          combineFields:
            item.combineFields?.length > 0 ? item.combineFields : undefined,
        });
      });

      const encodedColumns = encodeURIComponent(JSON.stringify(forExport));
      const encodedFilter = encodeURIComponent(JSON.stringify(filters));

      const resp = await getGraphicSettingsDownloadCSV({
        isWithFilter: isWithFilter,
        columns: encodedColumns,
        filter: encodedFilter,
      });

      resp && downloadFile(resp);
    } catch (error) {
      console.log("err", error);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setInputCount(editData.turnCount);
  };

  const handleCountInput = () => {
    const newSchedule = {};
    setEditData((prevEditData) => ({
      ...prevEditData,
      schedules: [...prevEditData.schedules, newSchedule],
    }));
    setInputCount(inputCount + 1);
  };

  const handleChange = (event, index, fieldName) => {
    const { name, value, checked } = event.target;
    if (name === "workType") {
      setEditData((prevEditData) => ({
        ...prevEditData,
        [name]: value,
      }));
    } else {
      const updatedSchedules = [...editData.schedules];
      if (fieldName === "isHoliday") {
        updatedSchedules[index][fieldName] = checked;
        if (checked) {
          updatedSchedules[index].jobStartTime = "";
          updatedSchedules[index].jobEndTime = "";
        }
      } else {
        updatedSchedules[index][fieldName] = value;
      }
      setEditData((prevEditData) => ({
        ...prevEditData,
        schedules: updatedSchedules,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let newData = {};
    if (editData.scheduleType === "flexible") {
      newData = {
        scheduleType: editData.scheduleType,
        turnCount: editData.turnCount,
        schedules: editData.schedules.map((schedule) => ({
          isHoliday: schedule.isHoliday,
          ...(schedule.isHoliday
            ? {}
            : {
                jobStartTime: schedule.jobStartTime,
                jobEndTime: schedule.jobEndTime,
              }),
        })),
      };
    } else if (editData.scheduleType === "shift") {
      newData = {
        scheduleType: editData.scheduleType,
        turnCount: editData.turnCount,
        schedules: editData.schedules,
        workType: parseInt(editData.workType),
      };
    } else if (editData.scheduleType === "standard") {
      newData = {
        scheduleType: editData.scheduleType,
        turnCount: editData.turnCount,
        schedules: editData.schedules,
        workType: parseInt(editData.workType),
      };
    }
    const result = removeEmptyStrings(newData);
    try {
      const res = await updateGraphicSetting(graphicId, result);
      handleClose();
      getData();
      return res;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const handleChangeType = (event) => {
    const { value } = event.target;
    let newSchedules = [];
    setNumGroups(2);
    if (value === "flexible") {
      newSchedules = Array.from({ length: inputCount }, () => ({}));
      setEditData({
        scheduleType: value,
        turnCount: 1,
        schedules: newSchedules,
      });
    } else if (value === "shift") {
      setEditData({
        scheduleType: value,
        workType: "",
        turnCount: 0,
        schedules: [{}, {}, {}, {}],
      });
    } else {
      setEditData({
        scheduleType: value,
        workType: "",
        turnCount: 1,
        schedules: [{}],
      });
    }
  };

  const removeInputGroup = (indexToRemove) => {
    const updatedSchedules = editData.schedules.filter(
      (_, index) => index !== indexToRemove
    );
    setEditData((prevEditData) => ({
      ...prevEditData,
      schedules: updatedSchedules,
    }));
    setInputCount(inputCount - 1);
  };

  const applyFiltersHandler = (filters) => {
    setOffset(0);
    setFilters(filters);
  };

  async function handleEdit(rowId) {
    try {
      const graphicSettings = await getGraphicSettings({ id: rowId });
      setEditData(graphicSettings.data);
      handleOpen();
      setGraphicId(rowId);
      setNumGroups(graphicSettings.data.turnCount);
      setInputCount(editData.turnCount);
    } catch (error) {
      console.log(error);
    }
  }

  const cancelAppliedFiltersHandler = () => {
    setOffset(0);
    setFilters([]);
  };
  async function handleDelet(rowId) {
    setDeleteDialog(true);
    setDeleteRowId(rowId);
  }

  const handleIconClick = (row, action) => {
    const rowId = row._id ? row._id : row.id;
    switch (action) {
      case "visibility":
        break;
      case "edit":
        handleEdit(rowId);
        break;
      case "delete":
        handleDelet(rowId);
        break;
      default:
        break;
    }
  };

  const operatorGeneratorHandler = (row, column) => {
    const operators = [
      new Operator({
        icon: <EditIcon />,
        onClick: () => handleIconClick(row, "edit"),
        type: 0,
      }),
      new Operator({
        icon: <DeleteIcon />,
        onClick: () => handleIconClick(row, "delete"),
        hideCondition: row.isUsedInStaff,
        type: 0,
      }),
    ];

    return <OperatorGenerator operators={operators} />;
  };

  const columns = [
    new Column({
      path: "#",
      headerName: "Operatorlar",
      disableColSearch: true,
      renderCell: operatorGeneratorHandler,
    }),
    new Column({
      path: "name",
      headerName: "Qrafikin adı",
      filterColumnKey: "name",
      filterType: OPERATORS_CONTAINING.TEXT,
    }),
    new Column({
      path: "scheduleType",
      headerName: "Qrafikin növləri",
      filterColumnKey: "scheduleType",
      filterType: OPERATORS_CONTAINING.OPTION,
      filterValues: [
        {
          key: "flexible",
          name: "Sürüşkən",
        },
        {
          key: "shift",
          name: "Növbəli",
        },
        {
          key: "standard",
          name: "standard",
        },
      ],
      getValue: (data) =>
        data?.scheduleType === "flexible"
          ? "Sürüşkən"
          : data?.scheduleType === "shift"
          ? "Növbəli"
          : "Standard",
    }),
  ];

  return (
    <>
      {data && (
        <Table
          data={data}
          limit={limit}
          offset={offset}
          columns={columns}
          totalRows={total}
          isLoading={loading}
          graphicName="graphicName"
          setLimit={(value) => setLimit(value)}
          setOffset={(value) => setOffset(value)}
          applyFiltersHandler={applyFiltersHandler}
          cancelAppliedFilters={cancelAppliedFiltersHandler}
          downloadHandler={(columns) => allContExportHandler(columns)}
        />
      )}
      {open && (
        <GraphicEditModal
          handleClose={handleClose}
          setEditData={setEditData}
          handleOpen={handleOpen}
          open={open}
          editData={editData}
          handleChange={handleChange}
          removeInputGroup={removeInputGroup}
          handleSubmit={handleSubmit}
          handleChangeType={handleChangeType}
          numGroups={numGroups}
          setNumGroups={setNumGroups}
          handleCountInput={handleCountInput}
          inputCount={inputCount}
        />
      )}
      <DeleteGraphicDialog
        id={deleteRowId}
        setDeleteDialog={setDeleteDialog}
        deleteDialog={deleteDialog}
        getData={getData}
        // submitted={submitted}
        // setSubmitted={setSubmitted}
      />
    </>
  );
};

export default GraphicTabel;
