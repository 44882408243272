import {
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, FieldArray, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getCommonData, updateEmployee } from "../../api/api";
import { checkUniquenessForNext } from "../../utils/checkUniquenessForEmployee";
import { inventoriesValidation } from "../employees/Validations";
import NewCommonDataDialog from "../employees/NewCommonDataDialog";
import CustomAccordion from "../ui/Accordion/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EditInventories = ({
  employeeById,
  submitted,
  setSubmitted,
  selectedType,
  // setOpenAddDialog,
  setSelectedType,
}) => {
  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([[]]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [pastData, setPastData] = useState([]);
  const [pastDatas, setPastDatas] = useState([]);
  const [inventarName, setInventarName] = useState("");
  const [isDate, setIsDate] = useState(false);
  const [initials, setInitials] = useState(true);
  const handleUpdateInventarItems = async () => {
    try {
      const response = await getCommonData("inventoryItems", "", inventarName);
      setInventoryItems((prevCarItems) => [...prevCarItems, response.data]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const handleInventory = async () => {
      const updatedInventoryItems = [];
      const inventoryTypesResponse = await getCommonData("inventoryTypes");
      const inventoryTypes = inventoryTypesResponse?.data || [];
      setInventoryTypes(inventoryTypes);
      const combinedData = [];
      if (inventoryTypes.length) {
        combinedData.push({ inventoryTypes: [...inventoryTypes] });
      }

      for (let index = 0; index < employeeById.inventories.length; index++) {
        const item = employeeById.inventories[index];
        try {
          // Make the API request using the item's "parent" as a parameter
          const response = await getCommonData(
            "inventoryItems",
            "",
            item.parent
          );
          const combinedDatas = [];
          if (response?.data.length) {
            combinedDatas.push({ inventoryName: [...response?.data] });
          }
          updatedInventoryItems.push(response.data);
          handlePastData(combinedDatas);
        } catch (error) {
          console.log("Error fetching data for item at index", index, error);
        }
      }

      setInventoryItems(updatedInventoryItems);
      handlePastData(combinedData);
    };
    handleInventory();
  }, [employeeById.inventories, submitted]);

  useEffect(() => {}, [submitted]);

  const handleItems = async (value, index) => {
    try {
      const response = await getCommonData("inventoryItems", "", value);

      const updatedInventoryItems = [...inventoryItems];

      updatedInventoryItems[index] = response.data;

      setInventoryItems(updatedInventoryItems);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    // Handle form submission

    const hasDuplicate = checkUniquenessForNext(
      values.inventories,
      "serialNumber",
      "inventar seriya nömrəsi"
    );

    if (!hasDuplicate) {
      const modifiedValues = {
        ...values,
        inventories:
          values?.inventories &&
          values?.inventories.map((item) => ({
            ...item,
            date: item.date ? moment(item.date).format("MM/DD/yyyy") : null,
            number: item.number.trim(),
            serialNumber: item.serialNumber.trim(),
          })),
      };
      // Handle form submission
      const resp = await updateEmployee(employeeById._id, modifiedValues);
      setIsDate(false);
      setSubmitting(false);
      setInitials(true);
      setSubmitted(!submitted);
      resetForm({ values });
    }
  };

  const initialValues = {
    inventories: employeeById.inventories || [],
  };
  function handlePastData(data) {
    if (data[0]?.inventoryName) {
      setPastDatas(data);
    }
    if (data[0]?.inventoryTypes) {
      setPastData(data);
    }
  }
  function handlePush(index) {
    inventoryTypes &&
      inventoryTypes.push(employeeById?.inventories[index]?.itemType);
    inventoryItems[index] &&
      inventoryItems[index].push({
        name: employeeById?.inventories[index]?.name,
        parent: employeeById?.inventories[index]?.itemType,
      });
  }
  function handleHidden(name, data, index) {
    if (name === "inventoryName") {
      return pastDatas.every((past) => {
        return !past[name]?.some((item) => item.name === data);
      });
    } else {
      return pastData.every((past) => {
        return !past[name]?.includes(data);
      });
    }
  }
  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>İnventarları</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={{
              inventories: initialValues.inventories || [],
            }}
            onSubmit={handleSubmit}
            validationSchema={inventoriesValidation(employeeById)}
          >
            {({
              values,
              isValid,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              dirty,
            }) => (
              <>
                <Form>
                  <FieldArray name="inventories">
                    {({ push, remove }) => (
                      <div>
                        {values?.inventories &&
                          values?.inventories.map((form, index) => (
                            <div key={index}>
                              <>
                                {handlePush(index)}
                                <Typography mt={3} mb={3} variant="h6">
                                  {index + 1})İnventarları
                                </Typography>

                                {/* Inventory model and category */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.inventories?.[index]
                                          ?.itemType &&
                                        errors?.inventories?.[index]
                                          ?.itemType &&
                                        Boolean(
                                          errors?.inventories?.[index]?.itemType
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`inventories[${index}].itemType`}
                                      >
                                        İnventar növü *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`inventories[${index}].itemType`}
                                        labelId={`inventories[${index}].itemType`}
                                        label="İnventar növü *"
                                        id={`inventories[${index}].itemType`}
                                        onChange={async (e) => {
                                          await handleItems(
                                            e.target.value,
                                            index
                                          );
                                          setInitials(false);
                                          setFieldValue(
                                            `inventories[${index}].itemType`,
                                            e.target.value
                                          );
                                          setFieldValue(
                                            `inventories[${index}].name`,
                                            ""
                                          );
                                          setInventarName(e.target.value);
                                        }}
                                        defaultValue=""
                                        error={
                                          touched?.inventories?.[index]
                                            ?.itemType &&
                                          errors?.inventories?.[index]
                                            ?.itemType &&
                                          Boolean(
                                            errors?.inventories?.[index]
                                              ?.itemType
                                          )
                                        }
                                        fullWidth
                                      >
                                        {inventoryTypes &&
                                          [...new Set(inventoryTypes)]
                                            .sort((a, b) =>
                                              a.localeCompare(b, "az")
                                            )
                                            .map((item, index) => (
                                              <MenuItem
                                                hidden={handleHidden(
                                                  "inventoryTypes",
                                                  item,
                                                  index
                                                )}
                                                value={item}
                                              >
                                                {item}
                                              </MenuItem>
                                            ))}
                                        <Button
                                          sx={{
                                            mt: 3,
                                            ml: 3,
                                          }}
                                          variant="outlined"
                                          onClick={() => {
                                            setOpenAddDialog(true);
                                            setSelectedType([
                                              "inventoryTypes",
                                              { name: "İnventar tipləri" },
                                            ]);
                                          }}
                                        >
                                          Yeni inventar növü əlavə et
                                        </Button>
                                      </Field>
                                      {touched?.inventories?.[index]
                                        ?.itemType &&
                                        errors?.inventories?.[index]
                                          ?.itemType &&
                                        errors?.inventories?.[index]
                                          ?.itemType && (
                                          <FormHelperText error>
                                            {
                                              errors?.inventories?.[index]
                                                ?.itemType
                                            }
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                  <Grid sx={{ width: "100%" }}>
                                    <FormControl
                                      error={
                                        touched?.inventories?.[index]?.name &&
                                        errors?.inventories?.[index]?.name &&
                                        Boolean(
                                          errors?.inventories?.[index]?.name
                                        )
                                      }
                                      fullWidth
                                    >
                                      <InputLabel
                                        id={`inventories[${index}].name`}
                                      >
                                        İnventarın modeli *
                                      </InputLabel>
                                      <Field
                                        as={Select}
                                        name={`inventories[${index}].name`}
                                        labelId={`inventories[${index}].name`}
                                        label="İnventarın modeli *"
                                        id={`inventories[${index}].name`}
                                        disabled={
                                          initials ||
                                          !values.inventories[index].itemType
                                        }
                                        defaultValue={`values.inventories[${index}].name`}
                                        error={
                                          touched?.inventories?.[index]?.name &&
                                          errors?.inventories?.[index]?.name &&
                                          Boolean(
                                            errors?.inventories?.[index]?.name
                                          )
                                        }
                                        fullWidth
                                      >
                                        {initials &&
                                        employeeById.inventories.length > 0 ? (
                                          <MenuItem
                                            selected
                                            key={index}
                                            value={
                                              employeeById?.inventories[index]
                                                ?.name
                                            }
                                          >
                                            {
                                              employeeById?.inventories[index]
                                                ?.name
                                            }
                                          </MenuItem>
                                        ) : (
                                          inventoryItems[index] &&
                                          inventoryItems[index]
                                            .filter(
                                              (item, index, self) =>
                                                index ===
                                                self.findIndex(
                                                  (t) => t.name === item.name
                                                )
                                            )
                                            .filter(
                                              (inventar) =>
                                                inventar.parent === inventarName
                                            )
                                            .sort((a, b) =>
                                              (a?.name || "").localeCompare(
                                                b?.name || "",
                                                "az"
                                              )
                                            )
                                            .map((item, idx) => (
                                              <MenuItem
                                                hidden={
                                                  employeeById.inventories
                                                    .length === 0
                                                    ? false
                                                    : handleHidden(
                                                        "inventoryName",
                                                        item.name,
                                                        index
                                                      )
                                                }
                                                key={idx}
                                                value={item.name}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            ))
                                        )}

                                        <Button
                                          sx={{
                                            mt: 3,
                                            ml: 3,
                                          }}
                                          variant="outlined"
                                          onClick={() => {
                                            setOpenAddDialog(true);
                                            setSelectedType([
                                              "inventoryItems",
                                              { name: "İnventar siyahısı" },
                                            ]);
                                          }}
                                        >
                                          Yeni inventarın modeli növü əlavə et
                                        </Button>
                                      </Field>
                                      {touched?.inventories?.[index]?.name &&
                                        errors?.inventories?.[index]?.name &&
                                        errors?.inventories?.[index]?.name && (
                                          <FormHelperText error>
                                            {errors?.inventories?.[index]?.name}
                                          </FormHelperText>
                                        )}
                                    </FormControl>
                                  </Grid>
                                </Grid>

                                {/* Inventory number and date */}
                                <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                                  <Grid sx={{ width: "100%" }}>
                                    <Field
                                      type="text"
                                      name={`inventories[${index}].number`}
                                      as={TextField}
                                      fullWidth
                                      label="İnventar nömrəsi"
                                      error={
                                        touched?.inventories?.[index]?.number &&
                                        errors?.inventories?.[index]?.number &&
                                        Boolean(
                                          errors?.inventories?.[index]?.number
                                        )
                                      }
                                      helperText={
                                        touched?.inventories?.[index]?.number &&
                                        errors?.inventories?.[index]?.number &&
                                        errors?.inventories?.[index]?.number
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    onClick={() => setIsDate(true)}
                                    sx={{ width: "100%" }}
                                  >
                                    <Field name={`inventories[${index}].date`}>
                                      {({ field, form }) => (
                                        <DatePicker
                                          {...field}
                                          label="İnventarın təhkim edilmə tarixi"
                                          inputFormat="dd/MM/yyyy"
                                          value={field.value || null}
                                          minDate={new Date(1940, 0, 1)}
                                          onChange={(date) => {
                                            if (
                                              date instanceof Date &&
                                              !isNaN(date)
                                            ) {
                                              form.setFieldValue(
                                                `inventories[${index}].date`,
                                                date
                                              );
                                            } else {
                                              form.setFieldValue(
                                                `inventories[${index}].date`,
                                                ""
                                              );
                                            }
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              helperText={
                                                isDate &&
                                                form.errors?.inventories?.[
                                                  index
                                                ]?.date
                                              }
                                              error={
                                                isDate &&
                                                Boolean(
                                                  form.errors?.inventories?.[
                                                    index
                                                  ]?.date
                                                )
                                              }
                                              fullWidth
                                            />
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                </Grid>

                                <Grid sx={{ width: "50%" }}>
                                  <Field
                                    type="text"
                                    name={`inventories[${index}].serialNumber`}
                                    as={TextField}
                                    fullWidth
                                    label="İnventar seriya nömrəsi"
                                    error={
                                      touched?.inventories?.[index]
                                        ?.serialNumber &&
                                      errors?.inventories?.[index]
                                        ?.serialNumber &&
                                      Boolean(
                                        errors?.inventories?.[index]
                                          ?.serialNumber
                                      )
                                    }
                                    helperText={
                                      touched?.inventories?.[index]
                                        ?.serialNumber &&
                                      errors?.inventories?.[index]
                                        ?.serialNumber &&
                                      errors?.inventories?.[index]?.serialNumber
                                    }
                                  />
                                </Grid>
                              </>

                              <Button
                                sx={{ mt: 3 }}
                                variant="contained"
                                color="error"
                                type="button"
                                onClick={() => {
                                  remove(index);
                                  setIsDate(false);
                                }}
                              >
                                İnventar sil
                              </Button>
                            </div>
                          ))}
                        <Button
                          sx={{ mt: 3 }}
                          variant="contained"
                          type="button"
                          onClick={() => {
                            push({ serialNumber: "" });
                            setIsDate(false);
                          }}
                        >
                          İnventar əlavə et
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
        {openAddDialog && (
          <NewCommonDataDialog
            openAddDialog={openAddDialog}
            selectedType={selectedType}
            setOpenAddDialog={setOpenAddDialog}
            submitted={submitted}
            setSubmitted={setSubmitted}
            setInventoryItems={handleUpdateInventarItems}
          />
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditInventories;
