import React, { useEffect, useState } from "react";
import {
  Field,
  FieldArray,
  ErrorMessage,
  useFormikContext,
  validateYupSchema,
  Formik,
  Form,
} from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  Checkbox,
  InputAdornment,
  FormHelperText,
  IconButton,
  OutlinedInput,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  ContactEditInfoValidation,
  ContactInfoValidation,
} from "../employees/Validations";
import { updateEmployee } from "../../api/api";
import { removeEmptyStrings } from "../../utils/removeEmptyString";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomAccordion from "../ui/Accordion/Accordion";

const cities = [
  { value: "baku", name: "Bakı" },
  { value: "ganja", name: "Gəncə" },
  { value: "nakhchivan", name: "Naxçıvan" },
  { value: "khankendi", name: "Xankəndi" },
  { value: "lankaran", name: "Lənkəran" },
  { value: "mingachevir", name: "Mingəçevir" },
  { value: "naftalan", name: "Naftalan" },
  { value: "Sumgait", name: "Sumqayıt" },
  { value: "shaki", name: "Şəki" },
  { value: "shirvan", name: "Şirvan" },
  { value: "yevlax", name: "Yevlax" },
  { value: "shusha", name: "Şuşa" },
  { value: "culfa", name: "Culfa" },
  { value: "ordubad", name: "Ordubad" },
];

const bakuDistricts = [
  { value: "binaqadi", name: "Binəqədi" },
  { value: "qaradag", name: "Qaradağ" },
  { value: "narimanov", name: "Nərimanov" },
  { value: "nasimi", name: "Nəsimi" },
  { value: "nizami", name: "Nizami" },
  { value: "sabuncu", name: "Sabunçu" },
  { value: "sabail", name: "Səbail" },
  { value: "suraxani", name: "Suraxanı" },
  { value: "pirallahi", name: "Pirallahı" },
  { value: "xatai", name: "Xətai" },
  { value: "khazar", name: "Xəzər" },
  { value: "yasamal", name: "Yasamal" },
];

const sumqayitDistricts = [
  "Sumqayıt şəhəri",
  "Corat qəsəbəsi",
  "Hacı Zeynalabdin qəsəbəsi",
];

const districts = [
  { value: "absheron", name: "Abşeron rayonu" },
  { value: "aghjabadi", name: "Ağcabədi rayonu" },
  { value: "aghdam", name: "Ağdam rayonu" },
  { value: "agdash", name: "Ağdaş rayonu" },
  { value: "agstafa", name: "Ağstafa rayonu" },
  { value: "agsu", name: "Ağsu rayonu" },
  { value: "astara", name: "Astara rayonu" },
  { value: "babak", name: "Babək rayonu" },
  { value: "balakan", name: "Balakən rayonu" },
  { value: "beylaqan", name: "Beyləqan rayonu" },
  { value: "barda", name: "Bərdə rayonu" },
  { value: "bilasuvar", name: "Biləsuvar rayonu" },
  { value: "jabrayil", name: "Cəbrayıl rayonu" },
  { value: "jalilabad", name: "Cəlilabad rayonu" },
  { value: "julfa", name: "Culfa rayonu" },
  { value: "dashkasan", name: "Daşkəsən rayonu" },
  { value: "fuzuli", name: "Füzuli rayonu" },
  { value: "gadabay", name: "Gədəbəy rayonu" },
  { value: "goranboy", name: "Goranboy rayonu" },
  { value: "goychay", name: "Göyçay rayonu" },
  { value: "goygol", name: "Göygöl rayonu" },
  { value: "hajigabul", name: "Hacıqabul rayonu" },
  { value: "khachmaz", name: "Xaçmaz rayonu" },
  { value: "khizi", name: "Xızı rayonu" },
  { value: "khojali", name: "Xocalı rayonu" },
  { value: "", name: "Xocavənd rayonu" },
  { value: "imishli", name: "İmişli rayonu" },
  { value: "ismayilli", name: "İsmayıllı rayonu" },
  { value: "kelbecer", name: "Kəlbəcər rayonu" },
  { value: "kengerli", name: "Kəngərli rayonu" },
  { value: "kurdemir", name: "Kürdəmir rayonu" },
  { value: "qax", name: "Qax rayonu" },
  { value: "qazax", name: "Qazax rayonu" },
  { value: "qebele", name: "Qəbələ rayonu" },
  { value: "qobustan", name: "Qobustan rayonu" },
  { value: "quba", name: "Quba rayonu" },
  { value: "qubadli", name: "Qubadlı rayonu" },
  { value: "qusar", name: "Qusar rayonu" },
  { value: "lacin", name: "Laçın rayonu" },
  { value: "lerik", name: "Lerik rayonu" },
  { value: "lenkeran", name: "Lənkəran rayonu" },
  { value: "masalli", name: "Masallı rayonu" },
  { value: "neftchala", name: "Neftçala rayonu" },
  { value: "oguz", name: "Oğuz rayonu" },
  { value: "ordubad", name: "Ordubad rayonu" },
  { value: "saatli", name: "Saatlı rayonu" },
  { value: "sabirabad", name: "Sabirabad rayonu" },
  { value: "salyan", name: "Salyan rayonu" },
  { value: "samukh", name: "Samux rayonu" },
  { value: "sederak", name: "Sədərək rayonu" },
  { value: "siyazan", name: "Siyəzən rayonu" },
  { value: "shabran", name: "Şabran rayonu" },
  { value: "shahbuz", name: "Şahbuz rayonu*" },
  { value: "shamakhi", name: "Şamaxı rayonu" },
  { value: "sheki", name: "Şəki rayonu" },
  { value: "shemkir", name: "Şəmkir rayonu" },
  { value: "sharur", name: "Şərur rayonu*" },
  { value: "shusha", name: "Şuşa rayonu" },
  { value: "terter", name: "Tərtər rayonu" },
  { value: "tovuz", name: "Tovuz rayonu" },
  { value: "ucar", name: "Ucar rayonu" },
  { value: "yardimli", name: "Yardımlı rayonu" },
  { value: "yevlakh", name: "Yevlax rayonu" },
  { value: "zaqatala", name: "Zaqatala rayonu" },
  { value: "zangilan", name: "Zəngilan rayonu" },
  { value: "zardab", name: "Zərdab rayonu" },
];

const operators = [
  { code: "055", name: "Bakcell" },
  { code: "099", name: "Bakcell" },
  { code: "010", name: "Azercell" },
  { code: "050", name: "Azercell" },
  { code: "051", name: "Sim" },
  { code: "070", name: "Nar" },
  { code: "077", name: "Nar" },
];

const EditContactInfo = ({ employeeById, submitted, setSubmitted }) => {
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const modifiedValues = {
      ...values,
      mobile:
        values?.mobile &&
        values?.mobile.map((mobile) => mobile.code + mobile.number),
      email: values?.email && values?.email.map((item) => item.trim()),
      actualAddress: {
        ...values.actualAddress,
        address: values.actualAddress.address.trim(),
      },
      registeredAddress: {
        ...values.registeredAddress,
        address: values.registeredAddress.address.trim(),
      },
    };
    // Handle form submission
    const result = removeEmptyStrings(modifiedValues);
    await updateEmployee(employeeById._id, result);
    setSubmitting(false);
    setSubmitted(!submitted);
    resetForm({ values });
  };
  const [mobilLength, setMobilLength] = useState([]);
  const initialValues = {
    actualAddress: {
      ...employeeById.actualAddress,
      homeNumber: employeeById?.actualAddress?.homeNumber?.length
        ? employeeById?.actualAddress?.homeNumber
        : [""],
    },
    registeredAddress: {
      ...employeeById.registeredAddress,
      homeNumber: employeeById?.actualAddress?.homeNumber?.length
        ? employeeById?.actualAddress?.homeNumber
        : [""],
    },
    email: employeeById.email || [],
    mobile: employeeById.mobile
      ? employeeById.mobile.map((number) => ({
          code: number.substring(0, 3),
          number: number.substring(3),
        }))
      : [""],
    sameAddress:
      employeeById.actualAddress.address ===
      employeeById.registeredAddress.address
        ? true
        : false,
  };
  return (
    <CustomAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h6>Əlaqə məlumatları</h6>
      </AccordionSummary>
      <AccordionDetails>
        {employeeById ? (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={ContactEditInfoValidation(employeeById._id)}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              isValid,
              dirty,
            }) => (
              <>
                <Form>
                  <>
                    {/* Actual address and registered address*/}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3, mt: 5 }}>
                      <Grid sx={{ width: "100%" }}>
                        <Field
                          type="text"
                          name="actualAddress.address"
                          as={TextField}
                          fullWidth
                          label="Ünvanı (faktiki)"
                          onChange={(event) => {
                            setFieldValue(
                              "actualAddress.address",
                              event.target.value
                            );
                            if (values.sameAddress) {
                              setFieldValue(
                                "registeredAddress.address",
                                event.target.value
                              );
                              setFieldValue(
                                "actualAddress.address",
                                event.target.value
                              );
                            }
                          }}
                          error={
                            errors.actualAddress?.address &&
                            Boolean(errors?.actualAddress?.address)
                          }
                          helperText={
                            errors.actualAddress?.address &&
                            errors.actualAddress?.address
                          }
                        />
                      </Grid>
                      {!values.sameAddress ? (
                        <Grid sx={{ width: "100%" }}>
                          <Field
                            type="text"
                            name="registeredAddress.address"
                            value={
                              values?.sameAddress
                                ? values?.actualAddress?.address
                                : values?.registeredAddress?.address
                            }
                            as={TextField}
                            fullWidth
                            disabled={values?.sameAddress}
                            label="Ünvanı (qeydiyyat)"
                            error={
                              errors.registeredAddress?.address &&
                              Boolean(errors?.registeredAddress?.address)
                            }
                            helperText={
                              errors.registeredAddress?.address &&
                              errors.registeredAddress?.address
                            }
                          />
                        </Grid>
                      ) : null}
                    </Grid>

                    {/* Actual address city name and registered city */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors?.actualAddress?.city &&
                            Boolean(errors?.actualAddress?.city)
                          }
                          fullWidth
                        >
                          <InputLabel id={`actualAddress.city`}>
                            Şəhər (faktiki)
                          </InputLabel>
                          <Select
                            name={`actualAddress.city`}
                            labelId={`actualAddress.city`}
                            label="Şəhər (faktiki)"
                            id={`actualAddressCity-select`}
                            onChange={(event) => {
                              setFieldValue(
                                "actualAddress.city",
                                event.target.value
                              );
                              if (values?.sameAddress) {
                                setFieldValue(
                                  "registeredAddress.city",
                                  event.target.value
                                );
                                setFieldValue(
                                  "actualAddress.city",
                                  event.target.value
                                );
                              }
                            }}
                            defaultValue={values?.actualAddress?.city}
                            value={values?.actualAddress?.city}
                            fullWidth
                            error={
                              errors.actualAddress?.city &&
                              Boolean(errors?.actualAddress?.city)
                            }
                            input={
                              <OutlinedInput
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      sx={{ mr: 3, size: "12px" }}
                                      size="small"
                                      onClick={() => {
                                        setFieldValue("actualAddress.city", "");
                                      }}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Rayon (faktiki)"
                              />
                            }
                          >
                            {cities &&
                              cities
                                .sort((a, b) =>
                                  a?.name.localeCompare(b?.name, "az")
                                )
                                .map((item, index) => (
                                  <MenuItem key={index} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                          </Select>
                          {errors.actualAddress?.city &&
                            errors.actualAddress?.city && (
                              <FormHelperText error>
                                {errors.actualAddress?.city}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      {!values.sameAddress ? (
                        <Grid sx={{ width: "100%" }}>
                          <FormControl
                            error={
                              errors.registeredAddress?.city &&
                              Boolean(errors?.registeredAddress?.city)
                            }
                            fullWidth
                          >
                            <InputLabel id={`registeredAddress.city`}>
                              Şəhər (qeydiyyat)
                            </InputLabel>
                            <Select
                              name={`registeredAddress.city`}
                              labelId={`registeredAddress.city`}
                              label="Şəhər (qeydiyyat)"
                              id={`registeredAddress.city`}
                              defaultValue={
                                values.sameAddress
                                  ? values.actualAddress.city
                                  : values.registeredAddress.city
                              }
                              value={
                                values.sameAddress
                                  ? values.actualAddress.city
                                  : values.registeredAddress.city
                              }
                              disabled={values.sameAddress}
                              fullWidth
                              onChange={handleChange}
                              input={
                                <OutlinedInput
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        sx={{ mr: 3, size: "12px" }}
                                        size="small"
                                        disabled={values.sameAddress}
                                        onClick={() => {
                                          setFieldValue(
                                            "registeredAddress.city",
                                            ""
                                          );
                                        }}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Şəhər (qeydiyyat)"
                                />
                              }
                              error={
                                errors.registeredAddress?.city &&
                                Boolean(errors?.registeredAddress?.city)
                              }
                            >
                              {cities &&
                                cities
                                  .sort((a, b) =>
                                    a?.name.localeCompare(b?.name, "az")
                                  )
                                  .map((item, index) => (
                                    <MenuItem key={index} value={item.name}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                            </Select>
                            {errors.registeredAddress?.city &&
                              errors.registeredAddress?.city && (
                                <FormHelperText error>
                                  {errors.registeredAddress?.city}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>
                      ) : null}
                    </Grid>

                    {/* actual distric and registered district */}
                    <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <FormControl
                          error={
                            errors.actualAddress?.district &&
                            Boolean(errors?.actualAddress?.district)
                          }
                          fullWidth
                        >
                          <InputLabel id={`actualAddress.district`}>
                            Rayon (faktiki)
                          </InputLabel>
                          <Select
                            disabled={values.actualAddress.city === "Gəncə"}
                            name={`actualAddress.district`}
                            labelId={`actualAddress.district`}
                            label="Rayon (faktiki)"
                            id={`actualAddressDistrict-select`}
                            defaultValue={values.actualAddress.district}
                            value={values.actualAddress.district}
                            onChange={(event) => {
                              setFieldValue(
                                "actualAddress.district",
                                event.target.value
                              );
                              if (values.sameAddress) {
                                setFieldValue(
                                  "registeredAddress.district",
                                  event.target.value
                                );
                              }
                            }}
                            fullWidth
                            error={
                              errors.actualAddress?.district &&
                              Boolean(errors?.actualAddress?.district)
                            }
                            input={
                              <OutlinedInput
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      sx={{ mr: 3, size: "12px" }}
                                      size="small"
                                      onClick={() => {
                                        setFieldValue(
                                          "actualAddress.district",
                                          ""
                                        );
                                      }}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Rayon (faktiki)"
                              />
                            }
                          >
                            {values.actualAddress.city === "Bakı"
                              ? bakuDistricts &&
                                bakuDistricts
                                  .sort((a, b) =>
                                    a?.name.localeCompare(b?.name, "az")
                                  )
                                  .map((item, index) => (
                                    <MenuItem key={index} value={item.name}>
                                      {item.name}
                                    </MenuItem>
                                  ))
                              : values.actualAddress.city === "Sumqayıt"
                              ? sumqayitDistricts &&
                                sumqayitDistricts
                                  .sort((a, b) => a.localeCompare(b, "az"))
                                  .map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))
                              : districts &&
                                districts
                                  .sort((a, b) =>
                                    a?.name.localeCompare(b?.name, "az")
                                  )
                                  .map((item, index) => (
                                    <MenuItem key={index} value={item.name}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                          </Select>
                          {errors.actualAddress?.district && (
                            <FormHelperText error>
                              {errors.actualAddress?.district}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      {!values.sameAddress ? (
                        <Grid sx={{ width: "100%" }}>
                          <FormControl
                            error={
                              errors.registeredAddress?.district &&
                              Boolean(errors?.registeredAddress?.district)
                            }
                            fullWidth
                          >
                            <InputLabel id={`registeredAddress.district`}>
                              Rayon (qeydiyyat)
                            </InputLabel>
                            <Select
                              disabled={
                                values.registeredAddress.city === "Gəncə"
                              }
                              name={`registeredAddress.district`}
                              labelId={`registeredAddress.district`}
                              label="Rayon (qeydiyyat)"
                              id={`registeredAddress.district-select`}
                              defaultValue={
                                values.sameAddress
                                  ? values.actualAddress.district
                                  : values.registeredAddress.district
                              }
                              value={
                                values.sameAddress
                                  ? values.actualAddress.district
                                  : values.registeredAddress.district
                              }
                              fullWidth
                              onChange={handleChange}
                              error={
                                errors.registeredAddress?.district &&
                                Boolean(errors?.registeredAddress?.district)
                              }
                              input={
                                <OutlinedInput
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        sx={{ mr: 3, size: "12px" }}
                                        size="small"
                                        disabled={values.sameAddress}
                                        onClick={() => {
                                          setFieldValue(
                                            "registeredAddress.district",
                                            ""
                                          );
                                        }}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Rayon (faktiki)"
                                />
                              }
                            >
                              {values.registeredAddress.city === "Bakı"
                                ? bakuDistricts &&
                                  bakuDistricts
                                    .sort((a, b) =>
                                      a?.name.localeCompare(b?.name, "az")
                                    )
                                    .map((item, index) => (
                                      <MenuItem key={index} value={item.name}>
                                        {item.name}
                                      </MenuItem>
                                    ))
                                : values.registeredAddress.city === "Sumqayıt"
                                ? sumqayitDistricts &&
                                  sumqayitDistricts
                                    .sort((a, b) => a.localeCompare(b, "az"))
                                    .map((item, index) => (
                                      <MenuItem key={index} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))
                                : districts &&
                                  districts
                                    .sort((a, b) =>
                                      a?.name.localeCompare(b?.name, "az")
                                    )
                                    .map((item, index) => (
                                      <MenuItem key={index} value={item.name}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                            </Select>
                            {errors.registeredAddress?.district &&
                              errors.registeredAddress?.district && (
                                <FormHelperText error>
                                  {errors.registeredAddress?.district}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </Grid>
                      ) : null}
                    </Grid>

                    {/* Home number and mobile number */}
                    <Grid sx={{ display: "flex", gap: 3 }}>
                      <Grid sx={{ width: "100%" }}>
                        <FieldArray name="actualAddress.homeNumber">
                          {({ push, remove }) => (
                            <>
                              {values?.actualAddress?.homeNumber &&
                                values?.actualAddress?.homeNumber.map(
                                  (address, index) => (
                                    <Grid
                                      mb={3}
                                      key={index}
                                      container
                                      spacing={2}
                                      alignItems="center"
                                    >
                                      <Grid item xs={9}>
                                        <Field
                                          type="tel"
                                          name={`actualAddress.homeNumber[${index}]`}
                                          as={TextField}
                                          fullWidth
                                          label={`Ev telefonu ${index + 1}`}
                                          error={
                                            errors?.actualAddress?.homeNumber &&
                                            errors?.actualAddress?.homeNumber?.[
                                              index
                                            ] &&
                                            errors?.actualAddress?.homeNumber?.[
                                              index
                                            ] &&
                                            Boolean(
                                              errors?.actualAddress
                                                ?.homeNumber?.[index]
                                            )
                                          }
                                        />
                                        {errors?.actualAddress?.homeNumber?.[
                                          index
                                        ] &&
                                          errors?.actualAddress?.homeNumber?.[
                                            index
                                          ] && (
                                            <FormHelperText error>
                                              {
                                                errors.actualAddress
                                                  ?.homeNumber?.[index]
                                              }
                                            </FormHelperText>
                                          )}
                                      </Grid>
                                      <Grid item xs={3}>
                                        {index ===
                                          values?.actualAddress?.homeNumber
                                            ?.length -
                                            1 && (
                                          <Button
                                            type="button"
                                            onClick={() => push("")}
                                          >
                                            + Ev telefonu əlavə et
                                          </Button>
                                        )}
                                        {index > 0 && (
                                          <Button
                                            type="button"
                                            onClick={() => remove(index)}
                                          >
                                            - Telefonu sil
                                          </Button>
                                        )}
                                      </Grid>
                                    </Grid>
                                  )
                                )}
                            </>
                          )}
                        </FieldArray>
                      </Grid>
                      <Grid sx={{ width: "100%" }}>
                        <FieldArray name="mobile">
                          {({ push, remove }) => (
                            <>
                              {values.mobile.map((mobile, index) => (
                                <Grid
                                  mb={3}
                                  key={index}
                                  container
                                  spacing={2}
                                  alignItems="center"
                                >
                                  <Grid
                                    item
                                    xs={9}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 4,
                                    }}
                                  >
                                    <Grid sx={{ width: 1 / 3 }}>
                                      <Field
                                        as={Select}
                                        name={`mobile[${index}].code`}
                                        labelId={`mobile[${index}].code`}
                                        label="Operator"
                                        id={`mobile[${index}].code`}
                                        defaultValue=""
                                        fullWidth
                                        error={
                                          errors?.mobile?.[index]?.code &&
                                          Boolean(errors?.mobile?.[index]?.code)
                                        }
                                      >
                                        {operators &&
                                          operators.map((item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.code}
                                            >
                                              {item.code}
                                            </MenuItem>
                                          ))}
                                      </Field>
                                    </Grid>
                                    <Grid sx={{ width: "100%" }}>
                                      <Field
                                        type="number"
                                        name={`mobile[${index}].number`}
                                        as={TextField}
                                        fullWidth
                                        label={`Mobil ${index + 1}`}
                                        error={
                                          errors?.mobile?.[index]?.number &&
                                          Boolean(
                                            errors?.mobile?.[index]?.number
                                          )
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={3}>
                                    {index === values.mobile.length - 1 && (
                                      <Button
                                        type="button"
                                        onClick={() => push("")}
                                      >
                                        + Mobil əlavə et
                                      </Button>
                                    )}
                                    {index > 0 && (
                                      <Button
                                        type="button"
                                        onClick={() => remove(index)}
                                      >
                                        - Telefonu sil
                                      </Button>
                                    )}
                                  </Grid>
                                  <Grid sx={{ ml: 2 }}>
                                    {errors.mobile?.[index]?.number &&
                                      errors.mobile?.[index]?.number && (
                                        <FormHelperText error>
                                          {errors.mobile?.[index]?.number}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                </Grid>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </Grid>
                    </Grid>

                    {/* Email and same address */}
                    <Grid
                      sx={{ display: "flex", alignItems: "center", gap: 3 }}
                    >
                      <Grid sx={{ width: "100%" }}>
                        <FieldArray name="email">
                          {({ push, remove }) => (
                            <>
                              {values?.email &&
                                values?.email.map((email, index) => (
                                  <Grid
                                    mb={3}
                                    key={index}
                                    container
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Grid item xs={9}>
                                      <Field
                                        type="email"
                                        name={`email[${index}]`}
                                        as={TextField}
                                        fullWidth
                                        label={`Email ${index + 1}`}
                                        error={
                                          errors?.email?.[index] &&
                                          Boolean(errors?.email?.[index])
                                        }
                                        helperText={
                                          errors?.email?.[index] &&
                                          errors?.email?.[index]
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      {index === values.email.length - 1 && (
                                        <Button
                                          type="button"
                                          onClick={() => push("")}
                                        >
                                          + Email əlavə et
                                        </Button>
                                      )}
                                      {index > 0 && (
                                        <Button
                                          type="button"
                                          onClick={() => remove(index)}
                                        >
                                          - Emaili sil
                                        </Button>
                                      )}
                                    </Grid>
                                  </Grid>
                                ))}
                            </>
                          )}
                        </FieldArray>
                      </Grid>
                      <Grid
                        sx={{
                          width: "100%",
                          mb: 4,
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.sameAddress}
                              name="sameAddress"
                              onChange={(event) => {
                                setFieldValue(
                                  "sameAddress",
                                  !values.sameAddress
                                );
                              }}
                              color="primary"
                            />
                          }
                          label="Faktiki və qeydiyyat ünvanı eynidir"
                        />
                      </Grid>
                    </Grid>
                  </>
                  {/* Submit button */}
                  <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !dirty}
                    >
                      Dəyişiklikləri yadda saxla
                    </Button>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <div>Loading...</div>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default EditContactInfo;
