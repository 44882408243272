import { api } from "../../api/axiosInterceptor";

export const getStaffTracking = async ({ offset, limit, filter }) => {
  const str = filter
    ? `/employee/staff-tracking/?offset=${offset}&limit=${limit}&filter=${filter}`
    : `/employee/staff-tracking/?offset=${offset}&limit=${limit}`;
  return await api.get(str);
};

export const getCurrStaffTracking = async (id) => {
  return await api.get(`/employee/staff-tracking/${id}`);
};

export const getStaffStatistics = async () => {
  return await api.get(`/employee/staff-tracking/statistics`);
};

export const getDownloadStaffCSV = async ({
  filter,
  columns,
  isWithFilter,
}) => {
  const str = isWithFilter
    ? `/employee/staff-tracking/download?filter=${filter}&columns=${columns}`
    : `/employee/staff-tracking/download?columns=${columns}`;

  return await api.get(str, { responseType: "blob" });
};
