import { useEffect, useState } from "react";
import { getFileById } from "../../api/api";

export default function EmployeePicture({ url = false, datas }) {
  const [picLink, setPicLink] = useState(url);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState(null);
  useEffect(() => {
    if (!url) {
      setLoading(true);
    }

    setPicLink(url);
  }, [url]);
  useEffect(() => {
    fetchImage();
  }, []);
  async function fetchImage() {
    if (datas !== undefined) {
      try {
        const res = await getFileById(`${datas}?fileFormat=base64`);
        if (res.data) {
          setImages(res.data);
        } else {
          return;
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      return;
    }
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "56px",
            width: "56px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {loading ? (
            <img
              loading="lazy"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              alt=""
              src={`${
                images === null
                  ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  : images
              }`}
            />
          ) : (
            <CircleLoader />
          )}
        </div>
      </div>
    </>
  );
}

function CircleLoader() {
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <style jsx>{`
        .loader-container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
        }

        .loader {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 4px solid #36d7b7;
          border-top: 4px solid transparent;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
}
