/* eslint-disable prettier/prettier */
import styled from "@emotion/styled";
import { Clear, Create } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import {
  deleteDesign,
  getDesigns,
  postDesign,
  putDesign,
} from "../../api/tsDesignRequests";
import { GENERAL_BLOCK, GENERAL_BUTTON, GENERAL_INPUT } from "../../styled";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const DesignTable = () => {
  const new_row = {
    label: "",
    rank: 0,
    style: {
      border: {
        width: "1",
        color: "#ffffff",
      },
      backgroundColor: "#000",
      font: {
        name: "Arial",
        size: "23",
        color: "#ffffff",
      },
    },
  };
  const [designs, set_designs] = useState([]);
  const [loading, set_loading] = useState([]);
  const [one_design, set_one_design] = useState(new_row);
  const [show_dialog, set_show_dialog] = useState(false);
  const [show_dialog_add, set_show_dialog_add] = useState(false);

  let indexRef = useRef();

  useEffect(() => {
    fetchDesigns();
  }, []);

  async function fetchDesigns() {
    set_loading(true);
    let response = await getDesigns();
    if (response) {
      let records = response?.data?.records?.map((r) => {
        if (!r.style) {
          r.style = {
            border: {
              width: "",
              color: "",
            },
            backgroundColor: "",
            font: {
              name: "",
              size: "",
              color: "#fff",
            },
          };
        }
        return r;
      });
      set_designs(records);
      set_loading(false);
    }
  }
  async function editDesign() {
    one_design.style.border.width = one_design.style.border.width.toString();
    one_design.style.font.size = one_design.style.font.size.toString();
    try {
      let res = await putDesign(one_design, designs[indexRef.current]?._id);
      if (res) {
        await fetchDesigns();
        set_one_design(new_row);
        set_show_dialog_add(false);
        enqueueSnackbar("Dizayn səviyyəsi uğurla redaktə edildi", {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
  }

  async function addRow() {
    let designs_ = JSON.parse(JSON.stringify(designs));
    one_design.style.border.width = one_design.style.border.width.toString();
    one_design.style.font.size = one_design.style.font.size.toString();
    designs_.push(one_design);
    // set_designs(designs_);
    try {
      let res = await postDesign(one_design);
      if (res) {
        await fetchDesigns();
        set_one_design(new_row);
        set_show_dialog_add(false);
        enqueueSnackbar("Dizayn səviyyəsi uğurla əlavə edildi", {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
  }

  function handleRowState(value, prop, i) {
    let deepCopy = JSON.parse(JSON.stringify(one_design));

    switch (prop) {
      case "name":
        deepCopy.label = value;
        break;
      case "width":
        deepCopy.style.border.width = value;
        break;
      case "color":
        deepCopy.style.border.color = value;
        break;
      case "backgroundColor":
        deepCopy.style.backgroundColor = value;
        break;

      case "fontName":
        deepCopy.style.font.name = value;
        break;

      case "fontSize":
        deepCopy.style.font.size = value;
        break;
      case "fontColor":
        deepCopy.style.font.color = value;
        break;

      default:
        break;
    }
    set_one_design(deepCopy);
  }

  async function handleDeleteDesign() {
    let res;
    if (designs[indexRef.current]?._id) {
      await deleteDesign(designs[indexRef.current]?._id);
      fetchDesigns();
    } else {
      res = designs.filter((f) => f !== designs[indexRef.current]);
      set_designs(res);
    }
    set_show_dialog(false);
  }

  function handleOpenDialog(i, type) {
    indexRef.current = i;
    if (type === "edit") {
      set_one_design(designs[i]);
      set_show_dialog_add(true);
    } else {
      set_show_dialog(true);
    }
  }

  function handle_add_design(val) {
    set_show_dialog_add(true);
    indexRef.current = undefined;
  }

  return (
    <div>
      <div className="d-flex">
        <GENERAL_BLOCK
          wid="15%"
          bord="1px solid #8080806e"
          pad="10px"
          className="d-flex jcc"
        >
          Səviyyə Adı
        </GENERAL_BLOCK>
        <GENERAL_BLOCK
          wid="8%"
          bord="1px solid #8080806e"
          pad="10px"
          className="d-flex jcc"
        >
          Struktur səviyyələri
        </GENERAL_BLOCK>
        <GENERAL_BLOCK wid="8%" bord="1px solid #8080806e" pad="10px">
          Border qalınlığı
        </GENERAL_BLOCK>
        <GENERAL_BLOCK wid="8%" bord="1px solid #8080806e" pad="10px">
          Border rəngi
        </GENERAL_BLOCK>
        <GENERAL_BLOCK wid="8%" bord="1px solid #8080806e" pad="10px">
          Arxa fon rəngi
        </GENERAL_BLOCK>
        <GENERAL_BLOCK wid="8%" bord="1px solid #8080806e" pad="10px">
          Font
        </GENERAL_BLOCK>
        <GENERAL_BLOCK wid="8%" bord="1px solid #8080806e" pad="10px">
          Font ölçüsü
        </GENERAL_BLOCK>
        <GENERAL_BLOCK wid="8%" bord="1px solid #8080806e" pad="10px">
          Font rəngi
        </GENERAL_BLOCK>
        {
          <GENERAL_BLOCK wid="10%" bord="1px solid #8080806e" pad="10px">
            Operatorlar
          </GENERAL_BLOCK>
        }
      </div>
      <style>{`.add_state .MuiDialog-paperScrollPaper { width: 25% }`}</style>
      {loading ? (
        <div>Zəhmət olmasa gözləyin,,,</div>
      ) : !designs.length ? (
        <div>Data yoxdur</div>
      ) : (
        designs?.map((r, i) => (
          <div className="d-flex">
            <GENERAL_BLOCK
              wid="15%"
              bord="1px solid #8080806e"
              pad="10px"
              className="d-flex aic jcc"
            >
              {r?.label}
            </GENERAL_BLOCK>
            <GENERAL_BLOCK
              wid="8%"
              bord="1px solid #8080806e"
              pad="10px"
              className="d-flex aic jcc"
            >
              {r?.rank}
            </GENERAL_BLOCK>
            <GENERAL_BLOCK
              wid="8%"
              bord="1px solid #8080806e"
              pad="10px"
              className="d-flex aic jcc"
            >
              {r?.style.border.width ? `${r?.style.border.width}px` : ""}
            </GENERAL_BLOCK>
            <GENERAL_BLOCK
              wid="8%"
              bord="1px solid #8080806e"
              pad="10px"
              className="d-flex aic"
            >
              <GENERAL_INPUT
                type="color"
                bg_color="transparent"
                bord="0"
                clr="white"
                wid="100%"
                value={r?.style.border.color}
                disabled
              />
            </GENERAL_BLOCK>
            <GENERAL_BLOCK
              wid="8%"
              bord="1px solid #8080806e"
              pad="10px"
              className="d-flex aic"
            >
              <GENERAL_INPUT
                type="color"
                bg_color="transparent"
                bord="0"
                clr="white"
                wid="100%"
                disabled
                value={r?.style.backgroundColor}
              />
            </GENERAL_BLOCK>
            <GENERAL_BLOCK
              wid="8%"
              bord="1px solid #8080806e"
              pad="10px"
              className="d-flex aic jcc"
            >
              {r?.style?.font?.name}
            </GENERAL_BLOCK>
            <GENERAL_BLOCK
              wid="8%"
              bord="1px solid #8080806e"
              pad="10px"
              className="d-flex aic jcc"
            >
              {r?.style?.font?.size ? `${r?.style?.font?.size}px` : ""}
            </GENERAL_BLOCK>
            <GENERAL_BLOCK
              wid="8%"
              bord="1px solid #8080806e"
              pad="10px"
              className="d-flex aic jcc"
            >
              <GENERAL_INPUT
                type="color"
                bg_color="transparent"
                bord="0"
                clr="white"
                wid="100%"
                disabled
                value={r?.style?.font?.color ? r?.style?.font?.color : ""}
              />
            </GENERAL_BLOCK>
            <GENERAL_BLOCK
              style={{ display: "flex" }}
              wid="10%"
              bord="1px solid #8080806e"
              pad="10px"
            >
              <>
                <GENERAL_BUTTON
                  bg_color="transparent"
                  bord="0"
                  onClick={() => handleOpenDialog(i, "edit")}
                >
                  <Create color="action" />
                </GENERAL_BUTTON>
                {r?.isDeletable && (
                  <GENERAL_BUTTON
                    bg_color="transparent"
                    bord="0"
                    onClick={() => handleOpenDialog(i, "delete")}
                  >
                    <Clear color="action" />
                  </GENERAL_BUTTON>
                )}
              </>
            </GENERAL_BLOCK>
          </div>
        ))
      )}
      <GENERAL_BLOCK className="d-flex jce" wid="81%">
        <Button
          variant="outlined"
          onClick={handle_add_design}
          className="mt-2 text-right"
        >
          Əlavə et
        </Button>
      </GENERAL_BLOCK>
      <Dialog
        open={show_dialog}
        onClose={() => {
          set_show_dialog(false);
          indexRef.current = undefined;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Bu struktur səviyyəsini silmək istəyirsiniz?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bu dəyişiklik təsir edəcək
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => set_show_dialog(false)}>İmtina</Button>
          <Button onClick={handleDeleteDesign}>Sil</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={show_dialog_add}
        className="add_state"
        onClose={() => {
          set_show_dialog_add(false);
          set_one_design(new_row);
          indexRef.current = undefined;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {indexRef.current !== undefined
            ? "Bu struktur səviyyəsinə dəyişiklik etmək istəyirsiniz?"
            : "Struktur səviyyəsi əlavə edin"}
        </DialogTitle>
        <DialogContent>
          {indexRef.current === undefined ? (
            <>
              <p className="mt-2 mb-1">
                <b>Səviyyə adı</b>
              </p>
              <TextField
                fullWidth
                value={one_design?.label}
                onChange={(e) =>
                  handleRowState(e.target.value, "name", indexRef.current)
                }
              />
            </>
          ) : one_design?.isUpdatable ? (
            <>
              <p className="mt-2 mb-1">
                <b>Səviyyə adı</b>
              </p>
              <TextField
                fullWidth
                value={one_design?.label}
                onChange={(e) =>
                  handleRowState(e.target.value, "name", indexRef.current)
                }
              />
            </>
          ) : null}
          <div>
            <p className="mt-2 mb-1">
              <b>Border qalınlığı</b>
            </p>
            <TextField
              fullWidth
              value={one_design?.style?.border?.width}
              onChange={(e) =>
                handleRowState(
                  !isNaN(+e.target.value)
                    ? +e.target.value
                    : one_design?.style?.border.width,
                  "width",
                  indexRef.current
                )
              }
            />
          </div>
          <div className="d-flex">
            <div className="w-50">
              <p className="mt-2 mb-1">
                <b>Border rəngi</b>
              </p>
              <TextField
                type="color"
                className="w-100"
                value={one_design?.style?.border?.color}
                onChange={(e) => handleRowState(e.target.value, "color")}
              />
            </div>
            <div className="ms-3 w-50">
              <p className="mt-2 mb-1">
                <b>Arxa font rəngi</b>
              </p>
              <TextField
                fullWidth
                type="color"
                className="w-100"
                value={one_design?.style?.backgroundColor}
                onChange={(e) =>
                  handleRowState(e.target.value, "backgroundColor")
                }
              />
            </div>
          </div>
          <p className="mt-2 mb-1">
            <b>Font</b>
          </p>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label"></InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={one_design?.style?.font?.name}
              onChange={(e) => handleRowState(e.target.value, "fontName")}
            >
              <MenuItem value="Arial">Arial</MenuItem>
              <MenuItem value="Cascadia Code">Cascadia Code</MenuItem>
              <MenuItem value="'Times New Roman', Times, serif">
                New times roman
              </MenuItem>
              <MenuItem value="Trebuchet MS">Trebuchet MS</MenuItem>
              <MenuItem value="Ebrima">Ebrima</MenuItem>
            </Select>
          </FormControl>
          <p className="mt-2 mb-1">
            <b>Font ölçüsü</b>
          </p>
          <div>
          <TextField
            fullWidth
            value={one_design?.style?.font?.size}
            onChange={(e) => {
              const inputValue = +e.target.value;
              if (!isNaN(inputValue) && inputValue <= 80) {
                handleRowState(inputValue, "fontSize", indexRef.current);
              } else if (inputValue > 80) {
                handleRowState(80, "fontSize", indexRef.current);
              }
            }}
          />
          <p
            style={{
              fontSize: "12px",
              opacity: ".7",
            }}
          >
            Maksimum 80px
          </p>
          </div>
          <div>
            <p className="mt-2 mb-1">
              <b>Font rəngi</b>
            </p>
            <TextField
              fullWidth
              type="color"
              className="w-100"
              value={one_design?.style?.font?.color}
              onChange={(e) =>
                handleRowState(e.target.value, "fontColor", indexRef.current)
              }
            />
          </div>
          <GENERAL_BLOCK
            className="mt-5"
            bord={`${one_design?.style?.border?.width}px solid ${one_design?.style?.border?.color}`}
            bg_color={one_design?.style?.backgroundColor}
            clr={one_design?.style?.font?.color}
            pad="15px 10px"
            brad="5px"
          >
            <GENERAL_BLOCK
              f_size={`${one_design?.style?.font.size}px`}
              f_family={one_design?.style?.font.name}
            >
              Başlıq
            </GENERAL_BLOCK>
            <GENERAL_BLOCK>Alt Başlıq</GENERAL_BLOCK>
          </GENERAL_BLOCK>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={indexRef.current !== undefined ? editDesign : addRow}
          >
            {indexRef.current === undefined ? "Yadda saxla" : "Redaktə et"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function TSDesignSettings() {
  return (
    <React.Fragment>
      <ToastContainer />

      <Helmet title="Təşkilat məlumatları" />
      <Typography variant="h3" gutterBottom display="inline">
        Təşkilatı struktur dizayn sazlamaları
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DesignTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TSDesignSettings;
