import React, { useEffect, useState } from "react";
import VacationReporting from "../../../layouts/vacations/reporting/VacationReporting";
import { Field, Form, Formik } from "formik";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import MultiDatePicker from "../../../layouts/vacations/multiDatePicker/MultiDatePicker";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FindTopPartRels from "../../dialogs/FindTopPartRels";
import { removeEmptyStrings } from "../../../utils/removeEmptyString";
import { downloadVacationRequestManager } from "../../../api/employeeRequests/employeeRequests";
import { downloadFile } from "../../../utils/downloadCSV";
import FindSubstructure from "../../dialogs/FindSubstructure";
import styled from "styled-components";
const ErrorContainer = styled.div`
  height: 18px; /* Adjust this height based on your error message height */
`;
const ApproverReportingForm = () => {
  const [status, setStatus] = useState([]);
  const [selectedStatusNames, setSelectedStatusNames] = useState([]);
  const [requestTypeStatus, setRequestTypeStatus] = useState([]);
  const [selectedRequestTypeNames, setSelectedRequestTypeNames] = useState([]);
  // Structure dialog
  const [selectedStructure, setSelectedStructure] = useState([]);
  const [structureNames, setStructureNames] = useState([]);
  const [structureDialog, setStructureDialog] = useState(false);
  const [substructureDialog, setSubstructureDialog] = useState(false);
  const [substructureNames, setSubstructureNames] = useState([]);
  const [selectedSubstructure, setSelectedSubstructure] = useState([]);

  // Date ranges
  const [creationDate, setCreationDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const creationDateError =
    creationDate.toDate === "undefined/undefined/undefined";
  const [updateDate, setUpdateDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const [decisionDate, setDecisionDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const decisionDateError =
    decisionDate.toDate === "undefined/undefined/undefined";

  // Titles,request status and reporting format
  const reportingTitle = "Hesabat formalaşdırılması";

  const requestStatus = [
    {
      name: "Təsdiqləmə gözlənilir",
      value: "pendingChainApprove",
      az: "Təsdiqləmə gözlənilir",
    },
    { name: "Qəbul olundu", value: "approved", az: "Qəbul olundu" },
    {
      name: "Sorğuya imtina edilmişdir",
      value: "rejectedByChainUser",
      az: "Sorğuya imtina edilmişdir",
    },
    {
      name: "Əmr tərtibi gözlənilir",
      value: "pendingOrder",
      az: "Əmr tərtibi gözlənilir",
    },
    {
      name: "Əmr imzası ləğv edilmişdir",
      value: "rejectedOrderApprove",
      az: "Əmr imzası ləğv edilmişdir",
    },
    {
      name: "Əmr imzası gözlənilir",
      value: "pendingOrderApprove",
      az: "Əmr imzası gözlənilir",
    },
    {
      name: "Əməkdaş müvafiq dəyişiklik etmişdir",
      value: "updatedByCreator",
      az: "Əməkdaş müvafiq dəyişiklik etmişdir",
    },
    {
      name: "Rəhbərə yenidən yönləndirilmişdir",
      value: "reRequestedToManager",
      az: "Rəhbərə yenidən yönləndirilmişdir",
    },
    {
      name: "Əməkdaşa düzəliş üçün yönləndirilmişdir",
      value: "reRequestedToCreator",
      az: "Əməkdaşa düzəliş üçün yönləndirilmişdir",
    },
    {
      name: "HR tərəfindən ləğv edilmişdir",
      value: "cancelledByHr",
      az: "HR tərəfindən ləğv edilmişdir",
    },
  ];
  const reportingFormat = ["CSV"];

  const requestType = [
    { name: "Məzuniyyət sorğusu", value: "VacationRequest" },
    { name: "Təyinat sorğusu", value: "EmploymentRequest" },
    { name: "Struktur dəyişiklik sorğusu", value: "StructureRequest" },
  ];

  const handleMultipleChoice = (event) => {
    const {
      target: { value },
    } = event;

    // Map the selected values to their corresponding names
    if (event?.target?.name === "contactDetails") {
      const selectedNames = requestType
        .filter((item) => value.includes(item.value))
        .map((item) => item.name);
      setRequestTypeStatus(value);
      setSelectedRequestTypeNames(selectedNames);
    } else {
      const selectedNames = requestStatus
        .filter((item) => value.includes(item.value))
        .map((item) => item.name);
      setStatus(value);
      setSelectedStatusNames(selectedNames);
    }
  };

  const handleReportDownload = async (values) => {
    const apiParams = {
      requestType: requestTypeStatus.join(", "),
      status: status.join(", "),
      creationDateFrom: creationDate.fromDate,
      creationDateTo: creationDate.toDate,
      updateDateFrom: updateDate.fromDate,
      updateDateTo: updateDate.toDate,
      decisionDateFrom: decisionDate.fromDate,
      decisionDateTo: decisionDate.toDate,
      structures: selectedStructure.join(", "),
      columns: encodeURIComponent(
        JSON.stringify([
          {
            fieldName: "Sorğu nömrəsi",
            path: "requestNO",
          },
          {
            fieldName: "Sorğunun yaradılma tarixi",
            path: "createdAt",
            type: "date",
          },
          {
            fieldName: "Subyekt",
            path: "creator",
            combineFields: ["firstname", "lastname", "fathername"],
          },
          {
            fieldName: "Təşkilat",
            path: "decisionChain.structure.organization.name",
          },
          {
            fieldName: "Struktur",
            path: "decisionChain.topCompanyPartRel.source.name",
          },
          {
            fieldName: "Sub-struktur",
            path: "decisionChain.companyPartRel.source.name",
          },
          {
            fieldName: "Vəzifə",
            path: "decisionChain.positionPartRel.source.name",
          },
          {
            fieldName: "Başlama tarixi",
            path: "originalRequest.startDate",
            type: "date",
          },
          {
            fieldName: "Bitmə tarixi",
            path: "originalRequest.endDate",
            type: "date",
          },
          {
            fieldName: "Qərar tarixi",
            path: "decisionDate",
            type: "date",
          },
          {
            fieldName: "Status",
            path: "status",
          },
        ])
      ),
    };
    const modifiedParams = removeEmptyStrings(apiParams);
    const response = await downloadVacationRequestManager(modifiedParams);
    downloadFile(response);
  };
  return (
    <VacationReporting reportingTitle={reportingTitle}>
      <Formik
        initialValues={{
          reportFormat: "",
          structure: "",
        }}
      >
        {({ values }) => (
          <Form>
            <Grid
              sx={{
                gap: 5,
                alignItems: "center",
              }}
            >
              <Grid
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: 3,
                }}
              >
                <div>
                  <Grid sx={{ width: "100%" }}>
                    <ErrorContainer></ErrorContainer>
                    <FormControl fullWidth>
                      <InputLabel id="contactDetails">Sorğu növü</InputLabel>
                      <Field
                        as={Select}
                        name="contactDetails"
                        label="Sorğu növü"
                        value={requestTypeStatus}
                        fullWidth
                        variant="outlined"
                        my={2}
                        sx={{
                          maxWidth: "501px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        onChange={handleMultipleChoice}
                        multiple
                        renderValue={() => selectedRequestTypeNames.join(", ")}
                      >
                        {requestType &&
                          requestType.map((type, index) => (
                            <MenuItem key={index} value={type.value}>
                              <Checkbox
                                checked={
                                  requestTypeStatus.indexOf(type.value) > -1
                                }
                              />
                              <ListItemText primary={type.name} />
                            </MenuItem>
                          ))}
                      </Field>
                    </FormControl>
                    <ErrorContainer></ErrorContainer>
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel id="status">Sorğu Statusu</InputLabel>
                      <Select
                        labelId="status"
                        label="Sorğu Statusu"
                        id="status"
                        multiple
                        sx={{
                          maxWidth: "501px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        value={status}
                        onChange={handleMultipleChoice}
                        // input={<OutlinedInput label="status" />}
                        renderValue={() => selectedStatusNames.join(", ")}
                      >
                        {requestStatus.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            <Checkbox
                              checked={status.indexOf(item.value) > -1}
                            />
                            <ListItemText primary={item.az} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <ErrorContainer></ErrorContainer>
                  </Grid>
                </div>
                <div>
                  <Grid sx={{ width: "100%" }}>
                    <ErrorContainer></ErrorContainer>
                    <MultiDatePicker
                      range={creationDate}
                      setRange={setCreationDate}
                      title={"Sorğu yaradılma tarixi"}
                      maxDate={new Date()}
                    />
                    <ErrorContainer>
                      {creationDateError && (
                        <FormHelperText sx={{ color: "#f44336" }}>
                          Bitmə tarixini seçin
                        </FormHelperText>
                      )}
                    </ErrorContainer>
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <MultiDatePicker
                      range={decisionDate}
                      setRange={setDecisionDate}
                      title={"Sorğu qərarı tarixi"}
                      maxDate={new Date()}
                    />
                    <ErrorContainer>
                      {decisionDateError && (
                        <FormHelperText sx={{ color: "#f44336" }}>
                          Bitmə tarixini seçin
                        </FormHelperText>
                      )}
                    </ErrorContainer>
                  </Grid>
                </div>
                <Grid sx={{ gap: 3 }}>
                  <Grid sx={{ width: "100%" }}>
                    <ErrorContainer></ErrorContainer>
                    <FormControl fullWidth>
                      <InputLabel id="reportFormat">Hesabat formatı</InputLabel>
                      <Field
                        as={Select}
                        name="reportFormat"
                        labelId="reportFormat"
                        label="Hesabat formatı"
                        id="reportFormat-select"
                        fullWidth
                      >
                        {reportingFormat.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                    <ErrorContainer></ErrorContainer>
                  </Grid>
                  {/* Structure input */}
                  <Grid sx={{ display: "flex", gap: "8px" }}>
                    <FormControl
                      onClick={() => {
                        setStructureDialog(true);
                      }}
                      fullWidth
                    >
                      <TextField
                        name="contactDetails"
                        label="Struktur"
                        fullWidth
                        variant="outlined"
                        value={structureNames.join(", ") || ""}
                        my={2}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                    <FormControl
                      onClick={() => {
                        setSubstructureDialog(true);
                      }}
                      fullWidth
                    >
                      <TextField
                        name="contactDetails"
                        label="Sub-struktur"
                        fullWidth
                        variant="outlined"
                        value={substructureNames.join(", ") || ""}
                        my={2}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <ErrorContainer></ErrorContainer>
                  {/* Structure Dialog */}
                  {structureDialog && (
                    <FindTopPartRels
                      selectedStructure={selectedStructure}
                      setSelectedStructure={setSelectedStructure}
                      structureDialog={structureDialog}
                      setStructureDialog={setStructureDialog}
                      structureNames={structureNames}
                      setStructureNames={setStructureNames}
                      pageKey="employee-request"
                    />
                  )}
                  {substructureDialog && (
                    <FindSubstructure
                      selectedStructure={selectedSubstructure}
                      setSelectedStructure={setSelectedSubstructure}
                      structureDialog={substructureDialog}
                      setStructureDialog={setSubstructureDialog}
                      structureNames={substructureNames}
                      setStructureNames={setSubstructureNames}
                      pageKey="employee-request"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  borderRadius: 2,
                  width: "16%",
                  backgroundColor: "rgba(50,109,181, 0.9)",
                }}
                onClick={() => {
                  handleReportDownload(values);
                }}
              >
                <CloudDownloadIcon sx={{ width: 20, height: 20 }} />
                <Typography>Yüklə</Typography>
              </IconButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </VacationReporting>
  );
};

export default ApproverReportingForm;
