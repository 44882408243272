import { Grid, InputBase, Typography } from "@mui/material";
import React from "react";

export default function FooterComment({ commentValue, onChange }) {
  const inputHandler = (e) => {
    onChange(e);
  };

  return (
    <Grid sx={styles.container}>
      <Typography color={"black"}>
        Şərh (Təsdiqləyici -{" "}
        {commentValue.employee?.firstname +
          " " +
          commentValue.employee?.lastname}
        )
      </Typography>
      <InputBase
        value={commentValue.description}
        minRows={3}
        maxRows={3}
        multiline={true}
        placeholder="Şərh..."
        onChange={inputHandler}
        contentEditable={false}
        // disabled={true}
        sx={styles.input}
      />
    </Grid>
  );
}

const styles = {
  container: { width: "100%", marginTop: 5 },
  input: {
    backgroundColor: "#FFF",
    color: "#000",
    paddingY: 5,
    paddingX: "40px",
    width: "100%",
    borderRadius: 3,
    lineHeight: "24px",
  },
};
