import { api } from "../../api/axiosInterceptor";

export const getWorkTimeGraphics = async ({
  year,
  month,
  offset,
  limit,
  filters,
}) => {
  return await api.get(
    `/employee/graphics?year=${year}&month=${month}&limit=${limit}&offset=${offset}&filters=${filters}`
  );
};

export const updateWorkTimeGraphic = async (payload) => {
  return await api.patch(`/employee/graphics`, { ...payload });
};

export const resetWorkTimeGraphics = async (id) => {
  return await api.put(`/employee/graphics/reset?graphicId=${id}`);
};

export const resetAllWorkTimeGraphics = async (id, year, month) => {
  return await api.put(
    `/employee/graphics/resetAll?year=${year}&month=${month}&filters=${id}`
  );
};
export const changeStartOrderGraphic = async (id, order) => {
  return await api.put(
    `/employee/graphics/order?graphicId=${id}&shiftOrder=${order}`
  );
};
