import React from "react";
import CustomAccordion from "../../../../components/ui/Accordion/Accordion";
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const PhysicalEducationSport = ({ values, setFieldValue }) => {
  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          // sx={{ border: "1px solid #56606F", borderRadius: "4px" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Bədən tərbiyəsi və idman üzrə</h6>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <TextField
              type="text"
              label="Əməkdaşın bədən tərbiyəsi və idman şəraiti məsələləri üzrə şərtlər"
              value={
                values?.physicalEducationSport
                  ?.physicalEducationAndSportsConditions
              }
              fullWidth
              name="physicalEducationSport.physicalEducationAndSportsConditions"
              onChange={(event) => {
                setFieldValue(
                  "physicalEducationSport.physicalEducationAndSportsConditions",
                  event.target.value
                );
              }}
            />
            <TextField
              type="text"
              label="Nəzərdə tutulan digər əlavə şərtlər"
              value={values?.physicalEducationSport?.otherAdditionalConditions}
              fullWidth
              name="physicalEducationSport.otherAdditionalConditions"
              onChange={(event) => {
                setFieldValue(
                  "physicalEducationSport.otherAdditionalConditions",
                  event.target.value
                );
              }}
            />
          </Grid>
        </AccordionDetails>
      </CustomAccordion>
    </>
  );
};

export default PhysicalEducationSport;
