export const workDayModes = [5, 6];
export const workHourModes = [40, 36, 24];

export const RelationshipInfoEnum = {
  father: "father",
  mother: "mother",
  brother: "brother",
  sister: "sister",
  grandmother: "grandmother",
  grandfather: "grandfather",
  husband: "husband",
  wife: "wife",
  "father-in-law": "father-in-law",
  "mother-in-law": "mother-in-law",
  child: "child",
  grandchild: "grandchild",
};
