import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

const EditList = ({
  openEditList,
  setOpenEditList,
  handleEditList,
  columnId,
  columnTitle,
}) => {
  const [listName, setListName] = useState(columnTitle);

  useEffect(() => {
    setListName(columnTitle);
  }, [columnTitle]);

  return (
    <>
      <Dialog
        fullWidth
        open={openEditList}
        onClose={() => {
          setOpenEditList(false);
          //   setListName("");
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">List adını dəyişdirin</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Adı"
            type="text"
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEditList(false);
              setListName("");
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setOpenEditList(false);
              handleEditList(columnId, listName);
            }}
            color="primary"
            disabled={listName.trim() === ""}
          >
            Dəyişdir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditList;
