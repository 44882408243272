import React, { useEffect, useState } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getAllStructuresEmptyStaff } from "../../../../../../api/api";
import CustomAccordion from "../../../../../../components/ui/Accordion/Accordion";
import NestedSelect from "../../../../../../utils/NestedSelect";

const OrderJobRelated = ({
  values,
  oldData,
  detailData,
  positionIds,
  selectedEmp,
  selectedCont,
  setFieldValue,
  setShowCreateOrder,
}) => {
  const [nestedChildren, setNestedChildren] = useState([]);
  const [positionId, setPositionId] = useState("");
  const [contractPosition, setContractPosition] = useState();
  const [staffId, setStaffId] = useState();
  async function getPositionsData() {
    try {
      const res = await getAllStructuresEmptyStaff({
        structureId: positionIds,
        employeeId: selectedEmp,
        oldStaffId: selectedCont,
      });
      setNestedChildren(res?.data?.children);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getPositionsData();
  }, [selectedCont]);
  useEffect(() => {
    if (staffId && nestedChildren) {
      setFieldValue("jobRelated.staffId", staffId);
    } else {
      setFieldValue("jobRelated.staffId", oldData?.staffId?._id);
    }
  }, [nestedChildren, oldData?.staffId?._id, setFieldValue, staffId]);

  return (
    <>
      <CustomAccordion sx={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <h6>Əməkdaşın vəzifə məlumatları</h6>
        </AccordionSummary>
        <AccordionDetails>
          {oldData?.isVerified ? (
            <NestedSelect
              setStaffId={setStaffId}
              setPositionId={setPositionId}
              setFieldValue={setFieldValue}
              nestedChildren={nestedChildren}
              setContractPosition={setContractPosition}
            />
          ) : (
            <Grid
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <Grid
                sx={{ display: "flex", gap: "10px", pointerEvents: "none" }}
              >
                <TextField
                  type="text"
                  label="Köhnə struktur"
                  fullWidth
                  variant="outlined"
                  name="Köhnə struktur"
                  value={
                    detailData?.data?.staffId?.structure?.organization?.name
                  }
                  // onChange={(event) => {
                  //   const value = event.target.value;
                  //   setTimeout(() => {
                  //     setFieldValue("jobRelated.oldStructureAsStr", value);
                  //   }, 0);
                  // }}
                />
                <TextField
                  type="text"
                  label="Yeni struktur"
                  value={
                    detailData?.data?.vacantStaffId?.structure?.organization
                      ?.name
                  }
                  fullWidth
                  name="Yeni struktur"
                />
              </Grid>
              <Grid
                sx={{ display: "flex", gap: "10px", pointerEvents: "none" }}
              >
                <TextField
                  type="text"
                  label="Köhnə vəzifə"
                  value={detailData?.data?.staffId?.position?.name}
                  fullWidth
                  name="Köhnə vəzifə"
                />
                <TextField
                  type="text"
                  label="Yeni vəzifə"
                  value={detailData?.data?.vacantStaffId?.position?.name}
                  fullWidth
                  name="jobRelated.newPositionAsStr"
                />
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </CustomAccordion>
    </>
  );
};

export default OrderJobRelated;
