import React, { useEffect, useState } from "react";
import { Field, FieldArray, Formik, Form } from "formik";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  FormHelperText,
} from "@mui/material";
import { getCommonData } from "../../api/api";

import { inventoriesValidation } from "../employees/Validations";

const ViewVehicles = ({ employeeById, submitted, setSubmitted }) => {
  const [carTypes, setCarTypes] = useState([]);
  const [carItems, setCarItems] = useState([[]]);

  useEffect(() => {
    const handleInventory = async () => {
      try {
        const updateCarItems = [];

        const response = await getCommonData("carManufacturers");

        setCarTypes(response.data);

        for (
          let index = 0;
          index < employeeById.transportVehicles.length;
          index++
        ) {
          const item = employeeById.transportVehicles[index];
          try {
            // Make the API request using the item's "parent" as a parameter
            const response = await getCommonData("carModels", "", item.parent);
            updateCarItems.push(response.data);
          } catch (error) {
            console.log("Error fetching data for item at index", index, error);
          }
        }

        setCarItems(updateCarItems);
      } catch (error) {
        console.log(error);
      }
    };
    handleInventory();
  }, []);

  const initialValues = {
    transportVehicles: employeeById.transportVehicles || [],
  };

  return (
    <>
      {employeeById ? (
        <Formik
          initialValues={{
            transportVehicles: initialValues.transportVehicles || [], // Set the initial laborActivity values from employeeById
          }}
          validationSchema={inventoriesValidation(employeeById)}
        >
          {({
            values,
            isValid,
            errors,
            isSubmitting,
            handleChange,
            setFieldValue,
          }) => (
            <Form style={{ pointerEvents: "none" }}>
              <FieldArray name="transportVehicles">
                {({ push, remove }) => (
                  <div>
                    {values.transportVehicles.map((form, index) => (
                      <div key={index}>
                        <>
                          <Typography mt={3} mb={3} variant="h6">
                            {index + 1})Nəqliyyat Vasitələri
                          </Typography>

                          {/* Vehicle type and model */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                error={
                                  errors?.transportVehicles?.[index]?.brand &&
                                  Boolean(
                                    errors?.transportVehicles?.[index]?.brand
                                  )
                                }
                                fullWidth
                              >
                                <InputLabel
                                  id={`transportVehicles[${index}].brand`}
                                >
                                  Marka *
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`transportVehicles[${index}].brand`}
                                  labelId={`transportVehicles[${index}].brand`}
                                  label="Marka *"
                                  id={`transportVehicles[${index}].brand`}
                                  defaultValue=""
                                  error={
                                    errors?.transportVehicles?.[index]?.brand &&
                                    Boolean(
                                      errors?.transportVehicles?.[index]?.brand
                                    )
                                  }
                                  fullWidth
                                >
                                  <MenuItem
                                    selected
                                    key={index}
                                    value={
                                      employeeById?.transportVehicles[index]
                                        ?.brand
                                    }
                                  >
                                    {
                                      employeeById?.transportVehicles[index]
                                        ?.brand
                                    }
                                  </MenuItem>
                                  {/* {carTypes.map((item, index) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))} */}
                                </Field>
                              </FormControl>
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                error={
                                  errors?.transportVehicles?.[index]?.name &&
                                  Boolean(
                                    errors?.transportVehicles?.[index]?.name
                                  )
                                }
                                fullWidth
                              >
                                <InputLabel
                                  id={`transportVehicles[${index}].name`}
                                >
                                  Model *
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`transportVehicles[${index}].name`}
                                  labelId={`transportVehicles[${index}].name`}
                                  label="Model *"
                                  id={`transportVehicles[${index}].name`}
                                  fullWidth
                                >
                                  <MenuItem
                                    selected
                                    key={index}
                                    value={
                                      employeeById?.transportVehicles[index]
                                        ?.name
                                    }
                                  >
                                    {
                                      employeeById?.transportVehicles[index]
                                        ?.name
                                    }
                                  </MenuItem>
                                  {/* {carItems[index] &&
                                    carItems[index].map((item, index) => (
                                      <MenuItem key={index} value={item.name}>
                                        {item.name}
                                      </MenuItem>
                                    ))} */}
                                </Field>
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* Model and registrationPlate */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                error={
                                  errors?.transportVehicles?.[index]
                                    ?.transportationType &&
                                  Boolean(
                                    errors?.transportVehicles?.[index]
                                      ?.transportationType
                                  )
                                }
                                fullWidth
                              >
                                <InputLabel
                                  id={`transportVehicles[${index}].transportationType`}
                                >
                                  Nəqliyyat Növü *
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`transportVehicles[${index}].transportationType`}
                                  labelId={`transportVehicles[${index}].transportationType`}
                                  label="Nəqliyyat Növü *"
                                  id={`transportVehicles[${index}].transportationType`}
                                  defaultValue=""
                                  fullWidth
                                >
                                  <MenuItem
                                    value={
                                      employeeById?.transportVehicles?.[index]
                                        ?.transportationType
                                    }
                                  >
                                    {
                                      employeeById?.transportVehicles?.[index]
                                        ?.transportationType
                                    }
                                  </MenuItem>
                                </Field>
                              </FormControl>
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                name={`transportVehicles[${index}].registrationNumber`}
                                as={TextField}
                                fullWidth
                                label="N.V. Qeydiyyat Şəhadətnaməsi No. *"
                                value={
                                  employeeById?.transportVehicles?.[index]
                                    ?.registrationNumber
                                }
                              />
                            </Grid>
                          </Grid>

                          {/* Number and date */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                name={`transportVehicles[${index}].registrationPlate`}
                                as={TextField}
                                fullWidth
                                label="N.V. Qeydiyyat Nişanı *"
                                value={
                                  employeeById?.transportVehicles?.[index]
                                    ?.registrationPlate
                                }
                              />
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                name={`transportVehicles[${index}].year`}
                                as={TextField}
                                fullWidth
                                label="Buraxılış ili *"
                                value={
                                  employeeById?.transportVehicles?.[index]?.year
                                }
                              />
                            </Grid>
                          </Grid>

                          {/* Volume and Transmission */}
                          <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                            <Grid sx={{ width: "100%" }}>
                              <Field
                                type="text"
                                name={`transportVehicles[${index}].engineCapacity`}
                                as={TextField}
                                fullWidth
                                label="Mühərrik Həcmi (sm3) *"
                                value={
                                  employeeById?.transportVehicles?.[index]
                                    ?.engineCapacity
                                }
                              />
                            </Grid>
                            <Grid sx={{ width: "100%" }}>
                              <FormControl
                                error={
                                  errors?.transportVehicles?.[index]
                                    ?.transmissionType &&
                                  Boolean(
                                    errors?.transportVehicles?.[index]
                                      ?.transmissionType
                                  )
                                }
                                fullWidth
                              >
                                <InputLabel
                                  id={`transportVehicles[${index}].transmissionType`}
                                >
                                  Sürət Qutusu *
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={`transportVehicles[${index}].transmissionType`}
                                  labelId={`transportVehicles[${index}].transmissionType`}
                                  label="Sürət Qutusu *"
                                  id={`transportVehicles[${index}].transmissionType`}
                                  defaultValue=""
                                  fullWidth
                                >
                                  <MenuItem
                                    value={
                                      employeeById?.transportVehicles?.[index]
                                        ?.transmissionType
                                    }
                                  >
                                    {
                                      employeeById?.transportVehicles?.[index]
                                        ?.transmissionType
                                    }
                                  </MenuItem>
                                </Field>
                                {errors?.transportVehicles?.[index]
                                  ?.transmissionType &&
                                  errors?.transportVehicles?.[index]
                                    ?.transmissionType && (
                                    <FormHelperText error>
                                      {
                                        errors?.transportVehicles?.[index]
                                          ?.transmissionType
                                      }
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default ViewVehicles;
