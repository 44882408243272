import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Field, FieldArray, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { getCommonData } from "../../api/api";

const Certification = ({
  values,
  submitted,
  setSelectedType,
  setOpenAddDialog,
}) => {
  const [certification, setCertification] = useState([]);
  const { errors, setErrors, setFieldValue } = useFormikContext();

  useEffect(() => {
    const handleCertification = async () => {
      const response = await getCommonData("certificates");
      setCertification(response.data);
    };
    handleCertification();
  }, [submitted]);
  return (
    <FieldArray name="certifications">
      {({ push, remove }) => (
        <div>
          {values?.certifications &&
            values?.certifications.map((form, index) => (
              <div key={index}>
                <>
                  <Typography mt={3} mb={3} variant="h6">
                    {index + 1})Sertifikasiya
                  </Typography>

                  {/* Rewards/ Reprimands and  description */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl
                        error={
                          errors?.certifications?.[index]?.qualification &&
                          Boolean(
                            errors?.certifications?.[index]?.qualification
                          )
                        }
                        fullWidth
                      >
                        <InputLabel
                          id={`certifications[${index}].qualification`}
                        >
                          İxtisas *
                        </InputLabel>
                        <Field
                          as={Select}
                          name={`certifications[${index}].qualification`}
                          labelId={`certifications[${index}].qualification`}
                          label="İxtisas *"
                          id={`certifications[${index}].qualification`}
                          defaultValue=""
                          fullWidth
                          error={
                            errors?.certifications?.[index]?.qualification &&
                            Boolean(
                              errors?.certifications?.[index]?.qualification
                            )
                          }
                          onChange={async (e) => {
                            setFieldValue(
                              `certifications[${index}].qualification`,
                              e.target.value
                            );
                            if (
                              errors?.certifications?.[index]?.qualification
                            ) {
                              setErrors({
                                ...errors,
                                certifications: [
                                  ...errors.certifications.slice(0, index),
                                  {
                                    ...errors.certifications[index],
                                    qualification: "",
                                  },
                                  ...errors.certifications.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        >
                          {certification?.length > 0 &&
                            certification
                              ?.sort((a, b) => a.localeCompare(b, "az"))
                              .map((item, index) => (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              ))}
                          <Button
                            sx={{
                              mt: 3,
                              ml: 3,
                            }}
                            variant="outlined"
                            onClick={() => {
                              setOpenAddDialog(true);
                              setSelectedType([
                                "certificates",
                                { name: "İxtisaslar" },
                              ]);
                            }}
                          >
                            Yeni ixtisas əlavə et
                          </Button>
                        </Field>
                        {errors?.certifications?.[index]?.qualification &&
                          errors?.certifications?.[index]?.qualification && (
                            <FormHelperText error>
                              {errors?.certifications?.[index]?.qualification}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <Field
                        type="text"
                        name={`certifications[${index}].number`}
                        as={TextField}
                        fullWidth
                        error={
                          errors?.certifications?.[index]?.number &&
                          Boolean(errors?.certifications?.[index]?.number)
                        }
                        helperText={
                          errors?.certifications?.[index]?.number &&
                          errors?.certifications?.[index]?.number
                        }
                        label="Sertifikat nömrəsi *"
                        onChange={async (e) => {
                          setFieldValue(
                            `certifications[${index}].number`,
                            e.target.value
                          );
                          if (errors?.certifications?.[index]?.number) {
                            setErrors({
                              ...errors,
                              certifications: [
                                ...errors.certifications.slice(0, index),
                                {
                                  ...errors.certifications[index],
                                  number: "",
                                },
                                ...errors.certifications.slice(index + 1),
                              ],
                            });
                          }
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* start date and company */}
                  <Grid sx={{ display: "flex", gap: 3, mb: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <Field name={`certifications[${index}].issueDate`}>
                        {({ field, form }) => (
                          <DatePicker
                            {...field}
                            label="Sertifikatın verilmə tarixi *"
                            inputFormat="dd/MM/yyyy"
                            value={field.value || null}
                            onChange={(date) => {
                              if (date instanceof Date && !isNaN(date)) {
                                form.setFieldValue(
                                  `certifications[${index}].issueDate`,
                                  date
                                );

                                // Calculate endDate as 5 years after date
                                const expireDate = new Date(date);
                                expireDate.setFullYear(
                                  expireDate.getFullYear() + 5
                                );
                                form.setFieldValue(
                                  `certifications[${index}].expireDate`,
                                  expireDate
                                );

                                if (
                                  errors?.certifications?.[index]?.issueDate
                                ) {
                                  setErrors({
                                    ...errors,
                                    certifications: [
                                      ...errors.certifications.slice(0, index),
                                      {
                                        ...errors.certifications[index],
                                        issueDate: "",
                                      },
                                      ...errors.certifications.slice(index + 1),
                                    ],
                                  });
                                }
                              } else {
                                form.setFieldValue(
                                  `certifications[${index}].issueDate`,
                                  ""
                                );
                                form.setFieldValue(
                                  `certifications[${index}].expireDate`,
                                  ""
                                );
                              }
                            }}
                            maxDate={new Date()}
                            minDate={new Date(1940, 0, 1)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={
                                  form.errors?.certifications?.[index]
                                    ?.issueDate
                                }
                                error={Boolean(
                                  form.errors?.certifications?.[index]
                                    ?.issueDate
                                )}
                                fullWidth
                              />
                            )}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <Field name={`certifications[${index}].expireDate`}>
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            label="Sertifikatın etibarlıq müddəti"
                            inputFormat="dd/MM/yyyy"
                            value={
                              field.value
                                ? new Intl.DateTimeFormat("en-GB").format(
                                    new Date(field.value)
                                  )
                                : ""
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                            helperText={
                              form.errors?.certifications?.[index]?.expireDate
                            }
                            error={Boolean(
                              form.errors?.certifications?.[index]?.expireDate
                            )}
                            fullWidth
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </>

                <Button
                  sx={{ mt: 3 }}
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={() => remove(index)}
                >
                  Sertifikasiya sil
                </Button>
              </div>
            ))}
          <Button
            sx={{ mt: 3 }}
            variant="contained"
            type="button"
            onClick={() => push({})}
          >
            Sertifikasiya əlavə et
          </Button>
        </div>
      )}
    </FieldArray>
  );
};

export default Certification;
