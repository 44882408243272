import React, { useEffect, useState } from "react";
import VacationReporting from "../../../layouts/vacations/reporting/VacationReporting";
import { Field, Form, Formik } from "formik";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import { getDecisionChainEmployees } from "../../../api/api";
import MultiDatePicker from "../../../layouts/vacations/multiDatePicker/MultiDatePicker";
import { downloadVacationRequestEmployee } from "../../../api/vacationRequests/vacationRequests";
import { removeEmptyStrings } from "../../../utils/removeEmptyString";
import { downloadFile } from "../../../utils/downloadCSV";

const EmpReportingForm = () => {
  const [status, setStatus] = useState([]);
  const [selectedStatusNames, setSelectedStatusNames] = useState([]);
  const [chainEmployees, setChainEmployees] = useState([]);
  const [chainEmployeesId, setChainEmployeesId] = useState([]);
  const [chainEmployeesName, setChainEmployeesName] = useState([]);
  const [creationDate, setCreationDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const [updateDate, setUpdateDate] = useState({
    fromDate: "",
    toDate: "",
  });
  const [decisionDate, setDecisionDate] = useState({
    fromDate: "",
    toDate: "",
  });

  const reportingTitle = "Hesabat formalaşdırılması";

  const requestStatus = [
    {
      name: "Təsdiqləmə matrisindən təsdiq gözləyən sorğular",
      value: "pendingChainApprove",
    },
    {
      name: "Əmr gözlənilir",
      value: "pendingOrder",
    },
    {
      name: "Əmr imzası gözlənilir",
      value: "pendingOrderApprove",
    },
    {
      name: "Əmr imzasına imtina edilmişdi",
      value: "rejectedOrderApprove",
    },
    { name: "Qəbul olunmuş sorğular", value: "approved" },
    {
      name: "Təsdiqləmə matrisindən imtina edilmiş sorğular",
      value: "rejectedByChainUser",
    },
    {
      name: "Sorğunu yaradanın yenilədiyi sorğular",
      value: "updatedByCreator",
    },
    {
      name: "Menecerə yenidən yaradılan sorğular",
      value: "reRequestedToManager",
    },
    {
      name: "Sorğunu yaradana yenilənən sorğular",
      value: "reRequestedToCreator",
    },
    { name: "HR tərəfindən imtina edilmiş sorğular", value: "cancelledByHr" },
  ];

  const reportingFormat = ["CSV", "Excel"];

  const handleMultipleChoice = (event, type) => {
    const {
      target: { value },
    } = event;

    if (type === "requestStatus") {
      const selectedNames = requestStatus
        .filter((item) => value.includes(item.value))
        .map((item) => item.name);

      setStatus(value);
      setSelectedStatusNames(selectedNames);
    } else {
      const selectedNames = chainEmployees
        .filter((item) => value.includes(item._id))
        .map((item) => item.firstname + " " + item.lastname);

      setChainEmployeesId(value);
      setChainEmployeesName(selectedNames);
    }
  };

  const handleReportDownload = async (values) => {
    const apiParams = {
      statuses: status.join(", "),
      creationDateFrom: creationDate.fromDate,
      creationDateTo: creationDate.toDate,
      updateDateFrom: updateDate.fromDate,
      updateDateTo: updateDate.toDate,
      decisionDateFrom: decisionDate.fromDate,
      decisionDateTo: decisionDate.toDate,
      approvers: chainEmployeesId.join(", "),
      columns: encodeURIComponent(
        JSON.stringify([
          {
            fieldName: "Sorğu nömrəsi",
            path: "requestNO",
          },
          {
            fieldName: "Sorğunun yaradılma tarixi",
            path: "createdAt",
            type: "date",
          },
          {
            fieldName: "Subyekt",
            path: "creator",
            combineFields: ["firstname", "lastname", "fathername"],
          },
          {
            fieldName: "Təşkilat",
            path: "decisionChain.structure.organization.name",
          },
          {
            fieldName: "Struktur",
            path: "decisionChain.topCompanyPartRel.source.name",
          },
          {
            fieldName: "Sub-struktur",
            path: "decisionChain.companyPartRel.source.name",
          },
          {
            fieldName: "Vəzifə",
            path: "decisionChain.positionPartRel.source.name",
          },
          {
            fieldName: "Başlama tarixi",
            path: "originalRequest.startDate",
            type: "date",
          },
          {
            fieldName: "Bitmə tarixi",
            path: "originalRequest.endDate",
            type: "date",
          },
          {
            fieldName: "Qərar tarixi",
            path: "decisionDate",
            type: "date",
          },
          {
            fieldName: "Status",
            path: "status",
          },
        ])
      ),
    };

    const modifiedParams = removeEmptyStrings(apiParams);
    const response = await downloadVacationRequestEmployee(modifiedParams);
    downloadFile(response);
  };

  useEffect(() => {
    const getChainEmployees = async () => {
      try {
        const response = await getDecisionChainEmployees();
        if (response && response.data) {
          setChainEmployees(response.data);
        }
      } catch (err) {
        console.log("error: ", err);
      }
    };
    getChainEmployees();
  }, []);

  return (
    <VacationReporting reportingTitle={reportingTitle}>
      <Formik
        initialValues={{
          reportFormat: "",
          approver: "",
        }}
      >
        {({ values }) => {
          return (
            <Form>
              <Grid sx={{ display: "flex", gap: 5, alignItems: "center" }}>
                <Grid sx={{ width: "100%" }}>
                  <Grid
                    sx={{
                      display: "flex",
                      gap: 3,
                      mb: 5,
                      alignItems: "center",
                    }}
                  >
                    <Grid sx={{ width: "100%" }}>
                      <FormControl>
                        <InputLabel id="status">Sorğu Statusu</InputLabel>
                        <Select
                          labelId="status"
                          label="Sorğu Statusu"
                          id="status"
                          multiple
                          sx={{
                            width: "21.3vw",
                          }}
                          value={status}
                          onChange={(event) => {
                            handleMultipleChoice(event, "requestStatus");
                          }}
                          input={<OutlinedInput label="status" />}
                          renderValue={() => selectedStatusNames.join(", ")} // Display the mapped names
                        >
                          {requestStatus.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              <Checkbox
                                checked={status.indexOf(item.value) > -1}
                              />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid sx={{ width: "100%" }}>
                      <MultiDatePicker
                        range={creationDate}
                        setRange={setCreationDate}
                        title={"Sorğu yaradılma tarixi"}
                      />
                    </Grid>

                    <Grid sx={{ width: "100%" }}>
                      <MultiDatePicker
                        range={updateDate}
                        setRange={setUpdateDate}
                        title={"Sorğuya düzəliş tarixi"}
                      />
                    </Grid>
                  </Grid>

                  <Grid sx={{ display: "flex", gap: 3 }}>
                    <Grid sx={{ width: "100%" }}>
                      <FormControl fullWidth>
                        <InputLabel id="reportFormat">
                          Hesabat formatı
                        </InputLabel>
                        <Field
                          as={Select}
                          name="reportFormat"
                          labelId="reportFormat"
                          label="Hesabat formatı"
                          id="reportFormat-select"
                          sx={{
                            width: "21.3vw",
                          }}
                        >
                          {reportingFormat.map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>

                    <Grid sx={{ width: "100%" }}>
                      <MultiDatePicker
                        range={decisionDate}
                        setRange={setDecisionDate}
                        title={"Sorğu qərarı tarixi"}
                      />
                    </Grid>

                    <Grid sx={{ width: "100%" }}>
                      <FormControl fullWidth>
                        <InputLabel id="approver">Sorğu təsdiq edən</InputLabel>
                        <Select
                          labelId="status"
                          label="Sorğu təsdiq edən"
                          id="status"
                          multiple
                          sx={{
                            width: "21.3vw",
                          }}
                          value={chainEmployeesId}
                          onChange={(event) => {
                            handleMultipleChoice(event, "approvers");
                          }}
                          input={<OutlinedInput label="Sorğu təsdiq edən" />}
                          renderValue={() => chainEmployeesName.join(", ")} // Display the mapped names
                        >
                          {chainEmployees.map((item, index) => (
                            <MenuItem key={index} value={item._id}>
                              <Checkbox
                                checked={
                                  chainEmployeesId.indexOf(item._id) > -1
                                }
                              />
                              <ListItemText
                                primary={item.firstname + " " + item.lastname}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                    px: 3,
                    py: 2,
                    borderRadius: 2,
                    backgroundColor: "#0F4C75",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleReportDownload(values);
                  }}
                >
                  <CloudDownloadIcon sx={{ width: 30, height: 30 }} />
                  <Typography variant="h4">Yüklə</Typography>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </VacationReporting>
  );
};

export default EmpReportingForm;
