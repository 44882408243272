import React from "react";
import Calendar from "../../../components/calendar/Calendar";

export default function CalendarPart({
  date,
  calendarDatas,
  days,
  updatedCalendar,
  deletedCalendar,
  delProductionCalendar,
  editable,
  setStartDate,
  setEndDate,
  dayIsDeleted,
  handleIsDeletedDay,
  handleDayIsEdited,
}) {
  return (
    <div>
      <Calendar
        date={date}
        calendarDatas={calendarDatas}
        days={days}
        editable={editable}
        updatedCalendar={updatedCalendar}
        deletedCalendar={deletedCalendar}
        delProductionCalendar={delProductionCalendar}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        dayIsDeleted={dayIsDeleted}
        handleIsDeletedDay={handleIsDeletedDay}
        handleDayIsEdited={handleDayIsEdited}
      />
    </div>
  );
}
