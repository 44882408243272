import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { MessageCircle } from "react-feather";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ScheduleIcon from "@mui/icons-material/Schedule";

import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Link,
  Typography as MuiTypography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
} from "@mui/material";
import { spacing, width } from "@mui/system";
import { orange, green, blue } from "@mui/material/colors";
import { Add as AddIcon } from "@mui/icons-material";
import {
  addChecklist,
  addCommentToCard,
  assignMembers,
  createBoard,
  createLabel,
  createList,
  createListCard,
  deleteBoard,
  deleteList,
  editBoard,
  editCardComment,
  editList,
  getAllBoards,
  getAllLabels,
  getBoardData,
  moveBoardData,
  moveList,
  removeComment,
  toggleLabel,
  updateCard,
} from "../api/tasks/taskRequests";
import AddBoard from "../components/tasks/dialogs/board/AddBoard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddList from "../components/tasks/dialogs/list/AddList";
import AddCard from "../components/tasks/dialogs/card/AddCard";
import EditBoard from "../components/tasks/dialogs/board/EditBoard";
import DeleteBoard from "../components/tasks/dialogs/board/DeleteBoard";
import EditList from "../components/tasks/dialogs/list/EditList";
import DeleteList from "../components/tasks/dialogs/list/DeleteList";
import LoadingWithProps from "../components/loading/LoadingWithProps";
import EditTask from "../components/tasks/dialogs/task/EditTask";
import AddMember from "../components/tasks/dialogs/task/AddMember";
import moment from "moment";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  display: inline-flex;
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

// border: 1px solid ${(props) => props.theme.palette.grey[300]};
// margin-bottom: ${(props) => props.theme.spacing(4)};
const TaskWrapper = styled(Card)`
  cursor: grab;
  background: #1b2635;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const TaskAvatars = styled.div`
  margin-top: ${(props) => props.theme.spacing(1)};
`;

const MessageCircleIcon = styled(MessageCircle)`
  color: ${(props) => props.theme.palette.grey[500]};
  vertical-align: middle;
`;

const TaskBadge = styled.div`
  background: ${(props) => props.color};
  width: 40px;
  height: 6px;
  margin-bottom: 4px;
  border-radius: 6px;
  display: inline-block;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)};
  right: ${(props) => props.theme.spacing(4)};
`;

const TaskNotificationsAmount = styled.div`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  margin-right: ${(props) => props.theme.spacing(1)};
  line-height: 1.75;
`;

const DateWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(2)};
  right: ${(props) => props.theme.spacing(2)};
`;

const DateWrapperItem = styled.div`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  margin-right: ${(props) => props.theme.spacing(1)};
  line-height: 1.75;
`;

const Typography = styled(MuiTypography)(spacing);

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 15px;
  margin-right: ${(props) => props.theme.spacing(10)};
`;

// ** Manual set state before api
const moveCardOrReorder = (
  cloneList,
  setCloneList,
  sourceListId,
  destListId,
  cardId,
  destIndex
) => {
  if (sourceListId === destListId) {
    // Reorder within the same list
    reorderCardInList(cloneList, setCloneList, sourceListId, cardId, destIndex);
  } else {
    // Move card between lists
    moveCard(
      cloneList,
      setCloneList,
      sourceListId,
      destListId,
      cardId,
      destIndex
    );
  }
};

// ** Function to move a card between lists
const moveCard = (
  cloneList,
  setCloneList,
  sourceListId,
  destListId,
  cardId,
  destIndex
) => {
  const sourceList = cloneList.find((list) => list._id === sourceListId);
  const destList = cloneList.find((list) => list._id === destListId);

  if (sourceList && destList) {
    const cardToMove = sourceList.cards.find((card) => card._id === cardId);

    if (cardToMove) {
      const updatedSourceCards = sourceList.cards.filter(
        (card) => card._id !== cardId
      );
      const updatedDestCards = [
        ...destList.cards.slice(0, destIndex),
        cardToMove,
        ...destList.cards.slice(destIndex),
      ];

      const updatedCloneList = cloneList.map((list) => {
        if (list._id === sourceListId) {
          return { ...list, cards: updatedSourceCards };
        }
        if (list._id === destListId) {
          return { ...list, cards: updatedDestCards };
        }
        return list;
      });

      setCloneList(updatedCloneList);
    }
  }
};

// ** Function to reorder a card within the same list
const reorderCardInList = (
  cloneList,
  setCloneList,
  listId,
  cardId,
  newIndex
) => {
  const listToUpdate = cloneList.find((list) => list._id === listId);

  if (listToUpdate) {
    const cardIndex = listToUpdate.cards.findIndex(
      (card) => card._id === cardId
    );

    if (cardIndex !== -1) {
      const updatedCards = Array.from(listToUpdate.cards);
      const [cardToMove] = updatedCards.splice(cardIndex, 1);
      updatedCards.splice(newIndex, 0, cardToMove);

      const updatedLists = cloneList.map((list) => {
        if (list._id === listId) {
          return { ...list, cards: updatedCards };
        }
        return list;
      });

      setCloneList(updatedLists);
    }
  }
};

// ** Move lists
function rearrangeListById(cloneList, setCloneList, itemId, destinationIndex) {
  const itemIndex = cloneList.findIndex((item) => item._id === itemId);

  if (
    itemIndex === -1 ||
    destinationIndex < 0 ||
    destinationIndex >= cloneList?.length
  ) {
    return; // If the item is not found or the destination index is out of bounds, do nothing
  }

  const clonedList = [...cloneList]; // Create a copy of the cloneList array

  const [removedItem] = clonedList.splice(itemIndex, 1);
  clonedList.splice(destinationIndex, 0, removedItem);

  setCloneList(clonedList); // Update the state with the rearranged list
}

const onDragEnd = async (
  result,
  cloneList,
  setCloneList,
  handleDragBoardData,
  handleMoveList
) => {
  if (!result.destination) return;

  const { source, destination } = result;

  if (result.type === "DEFAULT") {
    const selectedList = cloneList.find(
      (item) => item._id === source.droppableId
    );

    if (
      source.index !== destination.index ||
      source.droppableId !== destination.droppableId
    ) {
      const destColumn = destination.droppableId;
      const destIndex = destination.index + 1;

      moveCardOrReorder(
        cloneList,
        setCloneList,
        source.droppableId,
        destColumn,
        result.draggableId,
        destIndex - 1
      );

      handleDragBoardData(result.draggableId, destColumn, destIndex);
    }
  } else if (result.type === "COLUMN") {
    if (destination.index !== source.index) {
      const selectedList = result.draggableId;
      const destIndex = destination.index + 1;

      rearrangeListById(cloneList, setCloneList, selectedList, destIndex - 1);
      handleMoveList(selectedList, destIndex);
    }
  }
};

const Lane = ({
  column,
  children,
  handleAddList,
  handleEditList,
  handleDeleteList,
}) => {
  const [openAddCard, setOpenAddCard] = useState(false);
  const [openEditList, setOpenEditList] = useState(false);
  const [openDeleteList, setOpenDeleteList] = useState(false);

  const [columnId, setColumnId] = useState();
  const [columnTitle, setColumnTitle] = useState();

  return (
    <Box pb={4}>
      <Box
        sx={{
          backgroundColor: "#233044",
          borderRadius: "4px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" pt={4} px={4} gutterBottom>
            {column.title}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => {
                setOpenEditList(true);
                setColumnId(column._id);
                setColumnTitle(column.title);
              }}
              size="small"
              color="info"
            >
              <EditIcon sx={{ fontSize: "16px" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setOpenDeleteList(true);
                setColumnId(column._id);
              }}
              size="small"
              color="error"
            >
              <DeleteIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Box>
        </Box>

        <Divider />
        <Box
          sx={{
            width: "250px",
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            maxHeight: "400px",
          }}
        >
          <Box p={2}>
            <Typography variant="body2" mb={4}>
              {column.description}
            </Typography>
            {children}
          </Box>
        </Box>

        <Button
          onClick={() => {
            setOpenAddCard(true);
            setColumnId(column._id);
          }}
          color="primary"
          variant="contained"
          fullWidth
        >
          <AddIcon />
          Yeni tapşırıq əlavə et
        </Button>
      </Box>

      {/* List dialogs */}
      {openAddCard && (
        <AddCard
          openAddCard={openAddCard}
          setOpenAddCard={setOpenAddCard}
          handleAddList={handleAddList}
          columnId={columnId}
          setColumnId={setColumnId}
        />
      )}

      {openEditList && (
        <EditList
          setOpenEditList={setOpenEditList}
          openEditList={openEditList}
          handleEditList={handleEditList}
          columnId={columnId}
          columnTitle={columnTitle}
        />
      )}

      {openDeleteList && (
        <DeleteList
          openDeleteList={openDeleteList}
          setOpenDeleteList={setOpenDeleteList}
          columnId={columnId}
          handleDeleteList={handleDeleteList}
        />
      )}
    </Box>
  );
};

const Task = ({ item, taskDetails, setTaskDetails, setOpenEditTask }) => {
  // useEffect(() => {
  //   if (taskDetails && taskDetails._id === item._id) setTaskDetails(item);
  // }, [item]);

  return (
    <TaskWrapper
      mb={4}
      onClick={() => {
        setTaskDetails(item);
        setOpenEditTask(true);
      }}
    >
      <TaskWrapperContent>
        {item?.labels &&
          item?.labels?.length > 0 &&
          item?.labels?.map((label, i) => (
            <TaskBadge color={label.color} key={i} />
          ))}

        <TaskTitle variant="body1" gutterBottom>
          {item.title}
        </TaskTitle>

        <TaskAvatars>
          <AvatarGroup max={3}>
            {!!item?.cardMembers &&
              item.cardMembers.map((avatar, i) => (
                <Avatar
                  sx={{
                    fontWeight: "600",
                    width: 32,
                    height: 32,
                    fontSize: "12px",
                  }}
                  key={i}
                >
                  {avatar.employee?._id &&
                    avatar.employee.firstname[0] + avatar.employee.lastname[0]}
                </Avatar>
              ))}
          </AvatarGroup>
        </TaskAvatars>

        {item?.dueDate && (
          <DateWrapper>
            <DateWrapperItem>
              {moment(item.dueDate).format("MMM DD")}
            </DateWrapperItem>
          </DateWrapper>
        )}

        {!!item?.notifications && item.notifications > 0 && (
          <TaskNotifications>
            <TaskNotificationsAmount>
              {item.notifications}
            </TaskNotificationsAmount>
            <MessageCircleIcon />
          </TaskNotifications>
        )}
      </TaskWrapperContent>
    </TaskWrapper>
  );
};

function TaskManagement() {
  const [documentReady, setDocumentReady] = useState(false);
  const [allBoards, setAllBoards] = useState([""]);
  const [boardData, setBoardData] = useState();

  // **  Board Dialogs
  const [openAddBoard, setOpenAddBoard] = useState(false);
  const [openEditBoard, setOpenEditBoard] = useState(false);
  const [openDeleteBoard, setOpenDeleteBoard] = useState(false);

  // ** Board data lists and card states for flicker problem
  const [cloneList, setCloneList] = useState([]);

  // **  Open list dialogs
  const [openAddList, setOpenAddList] = useState(false);

  // ** Global state for refresh useEffect
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // ** Edit card
  const [openEditTask, setOpenEditTask] = useState(false);
  const [taskDetails, setTaskDetails] = useState();

  // ** Labels
  const [allLabels, setAllLabels] = useState([]);

  const getAllBoardsFunc = async () => {
    const response = await getAllBoards();
    setAllBoards(response?.data);
  };

  const getBoardDataFunc = async (e) => {
    const response = await getBoardData(e.target.value);
    // getListAndCards(response);
    const labelResponse = await getAllLabels(e.target.value);

    setAllLabels(labelResponse.data);

    setBoardData(response.data);
    setCloneList(response.data.lists);
  };

  // **  Board functions
  const handleCreateBoard = async (boardName) => {
    let data = {
      title: boardName,
    };
    await createBoard(data);
    setSubmitted(!submitted);
  };

  const handleEditBoard = async (boardTitle) => {
    let data = {
      title: boardTitle,
    };

    await editBoard(boardData._id, data);
    setSubmitted(!submitted);
  };

  const handleDeleteBoard = async () => {
    await deleteBoard(boardData._id);
    setBoardData();
    setCloneList();
    setSubmitted(!submitted);
  };

  // ** List functions
  const handleCreateList = async (listName) => {
    let data = {
      title: listName,
      boardId: boardData._id,
    };
    await createList(data);
    setSubmitted(!submitted);
  };

  const handleEditList = async (id, listTitle) => {
    let data = {
      title: listTitle,
    };

    await editList(id, data);
    setSubmitted(!submitted);
  };

  const handleDeleteList = async (id) => {
    await deleteList(id);
    setSubmitted(!submitted);
  };

  const handleDragBoardData = async (cardId, listId, order) => {
    // setIsSubmitting(true);
    let data = {
      listId: listId,
      order: order,
    };
    await moveBoardData(cardId, data);
    setSubmitted(!submitted);
    setIsSubmitting(false);
  };

  const handleMoveList = async (listId, order) => {
    let data = {
      order: order,
    };
    await moveList(listId, data);
    setSubmitted(!submitted);
  };

  //   ** Card functions
  const handleCreateListCard = async (title, listId) => {
    let data = {
      title: title,
      listId: listId,
    };
    await createListCard(data);
    setSubmitted(!submitted);
  };

  const assignMembersToCard = async (cardId, members) => {
    const data = {
      members: members,
    };
    const response = await assignMembers(cardId, data);
    setSubmitted(!submitted);
  };

  const handleUpdateCard = async (cardId, data) => {
    await updateCard(cardId, data);
    setSubmitted(!submitted);
  };

  const handleAddLabel = async (title, color) => {
    let data = {
      boardId: boardData._id,
      title: title,
      color: color,
    };

    await createLabel(data);
    setSubmitted(!submitted);
  };

  const handleAssignLabel = async (cardId, labelId) => {
    let data = {
      id: labelId,
    };

    await toggleLabel(cardId, data);
    setSubmitted(!submitted);
  };

  // ** Card comments
  const handleAddCommentToCard = async (cardId, data) => {
    await addCommentToCard(cardId, data);
    setSubmitted(!submitted);
  };

  const handleEditComment = async (commentId, data) => {
    await editCardComment(taskDetails._id, commentId, data);
    setSubmitted(!submitted);
  };

  const handleRemoveComment = async (cardId, commentId) => {
    await removeComment(cardId, commentId);
    setSubmitted(!submitted);
  };

  // ** Card checklists
  const handleAddChecklist = async (title, cardId) => {
    let data = {
      title: title,
      cardId: cardId,
    };
    await addChecklist(data);
    setSubmitted(!submitted);
  };

  useEffect(() => {
    setDocumentReady(true);
  }, []);

  useEffect(() => {
    getAllBoardsFunc();
    if (boardData) {
      const getSingleBoard = async () => {
        const response = await getBoardData(boardData._id);
        const labelResponse = await getAllLabels(boardData._id);
        // getListAndCards(response);
        setAllLabels(labelResponse.data);
        setCloneList(response.data.lists);
        setBoardData(response.data);
      };
      getSingleBoard();
    }
  }, [submitted]);
  return (
    <React.Fragment>
      <Helmet title="Tasks" />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h3" gutterBottom display="inline">
            Tapşırıqlar
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <FormControl sx={{ minWidth: "140px" }} fullWidth>
            <InputLabel id="boards">Lövhələr</InputLabel>
            <Select
              fullWidth
              labelId="boards"
              id="boards-select"
              label="Lövhələr"
              defaultValue={""}
              onChange={(e) => getBoardDataFunc(e)}
            >
              {allBoards?.map((item, index) => (
                <MenuItem key={index} value={item._id}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            sx={{ width: "100%", height: "51px" }}
            startIcon={<AddIcon />}
            onClick={() => {
              setOpenAddBoard(true);
            }}
          >
            Lövhə yarat
          </Button>
        </Box>
      </Box>

      <Divider mt={6} />
      <Box
        sx={{
          p: 6,
          mb: 6,
          display: "flex",
          justifyContent: "space-between",
          alignItem: "center",
          backgroundColor: "#233043",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          {/* Board title */}
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", m: 0, p: 0 }}
          >
            {boardData ? boardData.title : "Zəhmət olmasa lövhə seçin"}
          </Typography>

          {/* Board edit and delete icons */}
          {boardData && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                size="small"
                color="info"
                onClick={() => {
                  setOpenEditBoard(true);
                }}
              >
                <EditIcon sx={{ fontSize: "16px" }} />
              </IconButton>
              <IconButton
                onClick={() => {
                  setOpenDeleteBoard(true);
                }}
                size="small"
                color="error"
              >
                <DeleteIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box>
          {boardData && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setOpenAddList(true);
              }}
            >
              List yarat
            </Button>
          )}
        </Box>
      </Box>

      {cloneList?.length > 0 && (
        <Box
        //  sx={{ maxWidth: "calc(100vw - 258px)" }}
        >
          {!!documentReady && (
            <DragDropContext
              onDragEnd={(result) =>
                onDragEnd(
                  result,
                  cloneList,
                  setCloneList,
                  handleDragBoardData,
                  handleMoveList
                )
              }
            >
              <Droppable
                droppableId="board"
                type="COLUMN"
                direction="horizontal"
                mode="virtual"
                ignoreContainerClipping={true}
                isCombineEnabled={true}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      display: "inline-flex",
                      overflow: "scroll",
                      gap: 10,
                      maxWidth: "100%",
                    }}
                  >
                    {cloneList.map((column, index) => (
                      <Draggable
                        key={column._id}
                        draggableId={column._id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // style={{
                            //   width: "250px",
                            // }}
                          >
                            <Lane
                              key={column._id}
                              column={column}
                              handleAddList={handleCreateListCard}
                              handleEditList={handleEditList}
                              handleDeleteList={handleDeleteList}
                            >
                              <Droppable
                                // direction="vertical"
                                droppableId={column._id}
                                key={column._id}
                                mode="virtual"
                                renderClone={(provided, snapshot, rubric) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Task
                                      item={column.cards[rubric.source.index]}
                                      setTaskDetails={setTaskDetails}
                                      setOpenEditTask={setOpenEditTask}
                                    />
                                  </div>
                                )}
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={{
                                        minHeight: 50,
                                      }}
                                    >
                                      {column.cards.map((item, index) => {
                                        return (
                                          <Draggable
                                            key={item._id}
                                            draggableId={item._id}
                                            index={index}
                                          >
                                            {(provided) => {
                                              return (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    marginBottom: "8px",
                                                  }}
                                                >
                                                  <Task
                                                    item={item}
                                                    setTaskDetails={
                                                      setTaskDetails
                                                    }
                                                    taskDetails={taskDetails}
                                                    setOpenEditTask={
                                                      setOpenEditTask
                                                    }
                                                  />
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                      {provided.placeholder}
                                    </div>
                                  );
                                }}
                              </Droppable>
                            </Lane>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Box>
      )}

      {/* Dialogs */}

      {openAddBoard && (
        <AddBoard
          openAddBoard={openAddBoard}
          setOpenAddBoard={setOpenAddBoard}
          handleCreateBoard={handleCreateBoard}
        />
      )}

      {openAddList && (
        <AddList
          openAddList={openAddList}
          setOpenAddList={setOpenAddList}
          handleCreateList={handleCreateList}
        />
      )}

      {openEditBoard && (
        <EditBoard
          openEditBoard={openEditBoard}
          setOpenEditBoard={setOpenEditBoard}
          boardTitle={boardData?.title}
          handleEditBoard={handleEditBoard}
        />
      )}

      {openDeleteBoard && (
        <DeleteBoard
          setOpenDeleteBoard={setOpenDeleteBoard}
          openDeleteBoard={openDeleteBoard}
          handleDeleteBoard={handleDeleteBoard}
        />
      )}

      {/* Task Dialogs */}
      {openEditTask && (
        <EditTask
          boardData={boardData}
          openEditTask={openEditTask}
          setOpenEditTask={setOpenEditTask}
          taskDetails={taskDetails}
          setTaskDetails={setTaskDetails}
          allLabels={allLabels}
          assignMembersToCard={assignMembersToCard}
          handleAddLabel={handleAddLabel}
          handleAssignLabel={handleAssignLabel}
          handleUpdateCard={handleUpdateCard}
          handleAddCommentToCard={handleAddCommentToCard}
          handleRemoveComment={handleRemoveComment}
          handleEditComment={handleEditComment}
          handleAddChecklist={handleAddChecklist}
          setSubmitted={setSubmitted}
          submitted={submitted}
          setIsSubmitting={setIsSubmitting}
        />
      )}

      <ToastContainer />

      {setIsSubmitting && <LoadingWithProps loadingModal={isSubmitting} />}
    </React.Fragment>
  );
}

export default TaskManagement;
