import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddAttachment from "./AddAttachment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  removeAttachment,
  updateAttachment,
  uploadAttachment,
} from "../../../../api/tasks/taskRequests";
import EditAttachment from "./EditAttachment";

const SelectAttachment = ({
  openAttachments,
  setOpenAttachments,
  setIsSubmitting,
  cardId,
  attachments,
  setSubmitted,
  submitted,
}) => {
  const [openAddAttachment, setOpenAddAttachment] = useState(false);
  const [openEditAttachment, setOpenEditAttachment] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState();

  // ** Attachment handlers
  const handleAddAttachment = async (name, base64) => {
    let data = {
      attachments: [{ name: name, base64: base64 }],
    };
    await uploadAttachment(cardId, data);
    setSubmitted(!submitted);
  };

  const handleRemoveAttachment = async (attachmentId) => {
    await removeAttachment(cardId, attachmentId);
    setSubmitted(!submitted);
  };

  const handleEditAttachment = async (changedName) => {
    let data = {
      name: changedName,
    };
    await updateAttachment(cardId, selectedAttachment._id, data);
    setSubmitted(!submitted);
  };

  const handleDownloadClick = (attachment, fileName) => {
    if (attachment) {
      const downloadLink = document.createElement("a");
      downloadLink.href = attachment;
      downloadLink.download = fileName || "uploaded_file"; // Set a default filename if not available
      downloadLink.click();
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        open={openAttachments}
        onClose={() => {
          setOpenAttachments(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Təhkimlər</Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            {attachments &&
              attachments.length > 0 &&
              attachments.map((item, index) => (
                <Box
                  sx={{
                    backgroundColor: "#1B2635",
                    p: 2,
                    borderRadius: "6px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                      <Typography>
                        {index + 1}) {item.name}
                      </Typography>
                      <Box>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setOpenEditAttachment(true);
                            setSelectedAttachment({
                              name: item.name,
                              _id: item._id,
                            });
                          }}
                        >
                          <EditIcon sx={{ fontSize: "14px" }} />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleRemoveAttachment(item._id);
                          }}
                        >
                          <DeleteIcon sx={{ fontSize: "14px" }} />
                        </IconButton>
                      </Box>
                    </Box>

                    <Button
                      onClick={() => {
                        handleDownloadClick(item.base64, item.name);
                      }}
                      variant="contained"
                    >
                      Yüklə
                    </Button>
                  </Box>
                </Box>
              ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAttachments(false);
            }}
            color="primary"
          >
            Ləğv et
          </Button>
          <Button
            onClick={() => {
              setOpenAddAttachment(true);
            }}
            color="primary"
          >
            Əlavə et
          </Button>
        </DialogActions>
      </Dialog>

      {openAddAttachment && (
        <AddAttachment
          openAddAttachment={openAddAttachment}
          setOpenAddAttachment={setOpenAddAttachment}
          setIsSubmitting={setIsSubmitting}
          handleAddAttachment={handleAddAttachment}
        />
      )}

      {openEditAttachment && (
        <EditAttachment
          openEditAttachment={openEditAttachment}
          setOpenEditAttachment={setOpenEditAttachment}
          selectedAttachment={selectedAttachment}
          handleEditAttachment={handleEditAttachment}
        />
      )}
    </>
  );
};

export default SelectAttachment;
