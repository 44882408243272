import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddLabels from "./AddLabels";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteLabel,
  getAllBoards,
  getAllLabels,
  getBoardData,
  getCardDetails,
} from "../../../../api/tasks/taskRequests";

const SelectLabel = ({
  setOpenSelectLabel,
  openSelectLabel,
  handleAddLabel,
  taskDetails,
  allLabels,
  handleAssignLabel,
  boardData,
  setSubmitted,
  submitted,
}) => {
  const [addLabelDialog, setAddLabelDialog] = useState(false);
  async function handleDeleteLable(id) {
    try {
      const res = await deleteLabel(id);
      const response = await getAllLabels(boardData._id);
      setSubmitted(!submitted);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openSelectLabel}
        onClose={() => {
          setOpenSelectLabel(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Etiketlər</Typography>
          <Button
            onClick={() => {
              setAddLabelDialog(true);
            }}
            color="info"
            variant="outlined"
          >
            Yeni etiket əlavə et
          </Button>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {allLabels.map((item, index) => {
              const isChecked = taskDetails.labels.some(
                (label) => label._id === item._id
              );

              return (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Box
                    key={item._id}
                    sx={{
                      backgroundColor: item.color,
                      py: 2,
                      pl: 2,
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "95%",
                    }}
                  >
                    <Typography sx={{ fontSize: "16px" }}>
                      {item.title}
                    </Typography>
                    <Checkbox
                      onChange={(e) => {
                        handleAssignLabel(taskDetails._id, item._id);
                      }}
                      defaultChecked={isChecked}
                    />
                  </Box>
                  <IconButton
                    onClick={(e) => {
                      handleDeleteLable(item._id);
                    }}
                    size="small"
                  >
                    <DeleteIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                </div>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSelectLabel(false);
            }}
            color="primary"
          >
            Çıxış
          </Button>
          {/* <Button
            onClick={() => {
              setOpenSelectLabel(false);
            }}
            variant="contained"
          >
            Karta təyin et
          </Button> */}
        </DialogActions>
      </Dialog>

      <AddLabels
        addLabelDialog={addLabelDialog}
        setAddLabelDialog={setAddLabelDialog}
        taskDetails={taskDetails}
        handleAddLabel={handleAddLabel}
      />
    </>
  );
};

export default SelectLabel;
